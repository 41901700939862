import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import RoleAdd from "./role_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.jsx";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class Roles extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
        super(props)
var columnDefs=[
    {
        headerName: this.props.t('Name'),
        //headerName: '<i className="nav-icon fas  fa-briefcase"></i>',
       // headerComponentParams: { template: '<i class="fa fa-star"></i>' },
        field: 'name',
        pinned:'left',
        editable: true,

        filter: 'agTextColumnFilter',
        sort: 'asc',
        comparator: customComparator
    }];

        JSON.parse(localStorage.getItem('roles_tables')).map((t)=> {
            var childrenColumns=[];

            // Classic permissions
            JSON.parse(localStorage.getItem('permissions')).map((p)=> {
                if ( p.name.indexOf(t.name)===0) {
                    var obj = {};
                    var icon='';
                    if (p['name_en']=='View') icon='eye';
                    if (p['name_en']=='Edit') icon='edit';
                    if (p['name_en']=='Create') icon='plus';
                    if (p['name_en']=='Delete') icon='trash';
                    if (p['name_en']=='Excel') icon='file-excel';
                    if (p['name_en']=='Audit') icon='info';
                    if (p['name_en']=='Import') icon='upload';
                    if (p['name_en']=='Mass SMS') icon='mobile';
                    if (p['name_en']=='Mass emails') icon='envelope';
                    if (p['name_en']=='Document templates') icon='file';
                    if (p['name_en']=='Move to storage') icon='boxes';
                    if (p['name_en']=='Spedition') icon='truck';
                    if (p['name_en']=='Trips') icon='route';
                    if (p['name_en']=='Inputs') icon='sign-in-alt';
                    if (p['name_en']=='Outputs') icon='sign-out-alt';
                    if (p['name_en']=='Production') icon='cogs';
                    if (p['name_en']=='Pay') icon='credit-card';
                    if (p['name_en']=='Payment batches history') icon='history';
                    if (p['name_en']=='Delete payment batches') icon='ban';
                    if (p['name_en']=='Invoices for material') icon='boxes';
                    if (p['name_en']=='Invoices for transportation') icon='route';
                    if (p['name_en']=='Bank accounts') icon='piggy-bank';
                    if (p['name_en']=='Galvanisation') icon='industry';
                    if (p['name_en']=='Invoices report') icon='file-invoice-dollar';
                    if (p['name_en']=='Contracts') icon='file-signature';
                    if (p['name_en']=='Invoices - additional items') icon='list';
					if (p['name_en']=='Print word') icon= 'file-word';
					if (p['name_en']=='Cashier\'s report') icon= 'file-signature';
					if (p['name_en']=='Final calculation') icon= 'calculator';
					if (p['name_en']=='Print') icon= 'print';
                    obj.headerName=p['name_'+locale];
                    if (icon!='') {
                        obj.headerComponentParams={ template: '<i class="fa fa-'+icon+'"></i>' };
                        obj.minWidth=30;
                    }
                    obj.field=p['name'].replace('.','-');
obj.headerTooltip=p['name_'+locale];
                    obj.cellRenderer="CheckboxRenderer";

                        if (p.name!=t.name+'.view') obj.columnGroupShow='open';

                    childrenColumns.push(obj);

                }
            });

            // Roles meta
            if (t.main) ['visible', 'editable'].map((c)=>{
                var obj = {};
                obj.headerName=this.props.t(c);
                obj.field=t['name']+'-'+c;
                obj.cellEditor='MultiSelectSimple';
                obj.editable=true;
                obj.columnGroupShow='open';

                obj.valueGetter=function(params) {
                    if (params.data[t['name']+'-'+c]==null) return '';
					
                    var res='';
                    params.data[t['name']+'-'+c].split('//').map((e)=>{
                        try {
                            res+= JSON.parse(localStorage.getItem('columns')).find((refData) => {if(refData.field == e && refData.model===t['name']) {return true;} else {return false;}})['name_'+locale]+'//';
                        } catch(err) {
                            console.log(err);
                        }
                    });

                    return res.slice(0, -2);
                }

                childrenColumns.push(obj);
            });

            columnDefs.push( {headerName:t['name_'+locale],
                children:childrenColumns});
        });




        var settingsColumnDefs=[];
        JSON.parse(localStorage.getItem('permissions')).map((c)=> {
            if ( c.name.indexOf("settings")!=-1) {
                var obj = {};
                obj.headerName = c['name_' + locale];
                obj.field = c['name'].replace('.', '-');
                obj.minWidth = 120;
                obj.cellRenderer = "CheckboxRenderer";

                    if (c.name != 'settings.view') obj.columnGroupShow = 'open';
                    settingsColumnDefs.push(obj);
                }

        });

        var reportsColumnDefs=[];
        JSON.parse(localStorage.getItem('permissions')).map((c)=> {
            if ( c.name.indexOf("reports")!=-1) {
                var obj = {};
                obj.headerName = c['name_' + locale];
                obj.field = c['name'].replace('.', '-');
                obj.minWidth = 120;
                obj.cellRenderer = "CheckboxRenderer";

                if (c.name != 'reports.salary') obj.columnGroupShow = 'open';
                reportsColumnDefs.push(obj);
            }

        });

        columnDefs.push( {headerName:this.props.t('Settings'),
           children:settingsColumnDefs});

        columnDefs.push( {headerName:this.props.t('Reports'),
            children:reportsColumnDefs});

        columnDefs.push( {headerName:this.props.t('Delete'),
            cellRenderer:"DeleteRenderer",
            minWidth: 90,
            cellRendererParams: {
                gridType: "roles"
            },
            suppressMenu: true,});

        this.state = {
            showModal: false,
            popupParent: document.querySelector('body'),
            localeText: loctext,
            modules: AllCommunityModules,
            rowdata: null,
            columnDefs: columnDefs,
            defaultColDef: {
                flex: 1,
                minWidth: 170,
                editable: false,
                resizable: true,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                MultiSelectSimple: MultiSelectSimple,

            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/roles')
            .then(res => {
                console.log(res);
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/roles/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('roles', res.data.entry);
                SessionStorageService.remove('my_permissions');
                SessionStorageService.remove('my_roles_meta');
                localStorage.setItem('my_permissions', JSON.stringify(res.data.my_permissions));
                localStorage.setItem('my_roles_meta', JSON.stringify(res.data.my_roles_meta));
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        HttpService.post(UrlService.apiDomain()+'api/roles', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                    SessionStorageService.add('roles', res.data.entry);


                }

            })
            .catch((error) => {
                alert('EE'+error);
            });


    }


    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message:  this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label:this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/roles' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                    SessionStorageService.remove_by_id('roles', e.data.id);
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t("Roles")}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t("Add new")} <i className="fa fa-plus"></i>
                            </button>


                        </div>
                        <Modal
                            title={this.props.t("Add new")}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <RoleAdd onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}


                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                popupParent={this.state.popupParent}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Roles.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Roles);

