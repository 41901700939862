"use strict";


const ComponentAbstractConfigT = class ComponentAbstractConfigT {
	
	constructor(
		
	){
		
	}
				
};


export default ComponentAbstractConfigT;