import React, { Component } from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import SessionStorageService from "../services/SessionStorageService";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class ManufacturingRenderer extends Component {

    row() {
        var row = null;
        if (this.props.node.data && this.props.node.data.id) {
            row = this.props.node.data;
        } else if (this.props.node.allLeafChildren && this.props.node.allLeafChildren[0] && this.props.node.allLeafChildren[0].data) {
            row = this.props.node.allLeafChildren[0].data;
        }

        return row;
    }


    deleteClick = (e) => {

        if (this.props.gridType==='loadings') {
            var title='Confirm to delete';
            var message='Are you sure to delete this item and all other items associated with it?';

        }

        confirmAlert({
            title: this.props.t(title),
            message: this.props.t(message),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({
                            visible: true
                        });

                        var row=this.row();

                        if (this.props.gridType === 'loadings') {
                            var url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + row.loading_number;

                            HttpService.delete(url, row)
                                .then(res => {
                                    var rownodes=[];
                                    res.data.ids.map((i)=>{
                                        if (rownodes.length===0) rownodes.push(e.gridApi.getRowNode(i).parent);
                                        rownodes.push(e.gridApi.getRowNode(i));
                                        return true;
                                    });

                                    e.gridApi.updateRowData({remove: rownodes})

                                    e.gridApi.refreshClientSideRowModel('aggregate')


                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });
    };

    archiveClick = (e) => {


            var title='Confirm archiving';
            var message='Are you sure to archive this loading?';



        confirmAlert({
            title: this.props.t(title),
            message: this.props.t(message),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({
                            visible: true
                        });

                        var row=this.row();


                            var url = UrlService.apiDomain() + 'api/loadings/archive/' + row.id;

                            HttpService.get(url)
                                .then(res => {




                                })
                                .catch((error) => {
                                    console.log(error);
                                })


                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });
    };

    render() {
        var row=this.row();
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        return (

            <>
            {row.loading_number}

            <div className="btn-group" style={{'marginLeft':'9px','marginRight':'9px'}}>
                {(permissions.includes('storage.production')) &&
                    <>
                <button
                    className="btn btn-default btn-sm delete-ingrid-but"
                    onClick={() => this.props.showcard(row)}
                ><i className="fa fa-cogs"></i>
                </button>
                <button
                    className="btn btn-default btn-sm delete-ingrid-but"
                    onClick={() => this.archiveClick(this.props.node)}
                    ><i className="fa fa-archive"></i>
                    </button>
                    </>
                }
                {(permissions.includes('loadings.delete') && true===false) &&
                <button
                    className="btn btn-default btn-sm delete-ingrid-but"
                    onClick={() => this.deleteClick(this.props.node)}
                ><i className="fa fa-trash"></i>
                </button>
                }


            </div>
            </>
        );
    }
}

ManufacturingRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ManufacturingRenderer);