import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class DriversAdd extends Component<Props> {
    state = { name: "", address: "",passport: "",};
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name, address, passport} = this.state;
        this.props.onAdd({name, address, passport});
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
            this.setState({name: "", address: "",passport: "",});
        }

    }

    render() {

        const {name, address, passport} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Address')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={address}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ address: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Passport number')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={passport}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ passport: event.target.value })
                                    }
                                />
                            </div>
                        </div>



                    </div>



                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
DriversAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(DriversAdd);

