"use strict";


import UIElementAbstractT from "../../../../Interface/UI/Element/UIElementAbstractT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";


import UIComponentLabelConfigT from "../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


const UIComponentLabelT = class UIComponentLabelT extends UIComponentBaseT {
	
	constructor(
		UIElement,
		UIComponentLabelConfig
	){
		
		if( ( UIElement instanceof UIElementAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIComponentLabelConfig instanceof UIComponentLabelConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		super(
			UIElement,
			UIComponentLabelConfig
		);
		
		
		let UIStateMap = UIComponentLabelConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		
		super.ClassNameSet( UIState.ClassNameGet( ) );
		
		super.StyleMapSet( UIState.StyleMapGet( ) );
		
		
		this.LabelSet( UIComponentLabelConfig.LabelGet( ) );
		
	}
	
	LabelSet(
		Label
	){
		
		if( typeof( Label ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super.InnerTextSet( Label );
		
	}
	
	LabelGet(
	
	){
		
		return super.InnerTextGet( );
		
	}
	
};


export default UIComponentLabelT;