"use strict";


import UIComponentLabelConfigT from "../Label/UIComponentLabelConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentPlaceholderConfigT = class UIComponentPlaceholderConfigT extends UIComponentLabelConfigT {
	
	constructor( 
		Label = "",
		
		UIStateMap = null
	){
		
		super( 
			Label,
			UIStateMap
		);
		
	}
	
	Copy(
	
	){
		
		return new UIComponentPlaceholderConfigT(
		
			super.LabelGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
	
};


export default UIComponentPlaceholderConfigT;