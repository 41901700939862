import UrlService from "./UrlService";
import HttpService from "./HttpService";
import SessionStorageService from "./SessionStorageService";

class UserService {
  user = null;

  getDataLists() {
    HttpService.get(UrlService.apiDomain() + 'api/users/get_data_lists')
        .then(res => {


          localStorage.setItem('my_permissions', JSON.stringify(res.data.my_permissions));
          res.data.my_roles_meta.map((e) => {
            localStorage.setItem('my_roles_meta_' + e.name, e.value);
          });
          try {
            SessionStorageService.initializeLocalDB(res.data);
          } catch (er) {
            console.log(er);
          }

          var roles = [];
          JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
            roles.push(r.name);
          });

          if (roles.includes('Spedice')) {

            window.location.replace("/loadings");

          }

          else if (roles.includes('Vyroba')) {

            window.location.replace("/storage");
          }

          else if (roles.includes('Galvanovna')) {
            window.location.replace("/galvanisation");

          }

          else {

            window.location.replace("/home");

          }
        })
        .catch((error) => {
          console.log(error);
        });

  }

  async getCurrentUserProfile() {
    const url = UrlService.currentUserProfileUrl();
    try {
      const response = await HttpService.get(url);
      this.user = response.data;
      return response.data;
    } catch (error) {
      console.error("Not able to fetch the user");
    }
  }
}

export default new UserService();
