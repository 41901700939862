import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select'
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";



interface Props {
    onAdd: (data) => void;
}

class ProductionTab extends React.Component {


    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return dd+'.'+mm + '.' +  yyyy;
    }



    constructor(props) {
        super(props)







        this.state = {
			show:this.props.show,
            materials:this.materials(),
            no_salary:0,
            id: this.props.id,
            production_date: this.today(),
            processed_qty:"",
			processed_pcs: "",
            work_type:'',
            work_note:'',
            work_type_value:{value: '', label:this.props.t('Choose')},
            additional_hours: "",
            manufacturer: this.usersArray()[0]['value'],
            manufacturer_value:this.usersArray()[0],
            allow_submit: false,
        };


    }
    componentDidMount() {
        console.log("production componentDidMount");
        this.get_my_hours();
    }

    materials() {
        console.log(this.props);
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var materials=[];
        var show_types=['Tungsten carbide', 'Iron', 'Trash', 'Other material'];
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            if (this.props.type!='galvanisation' && e.product_type==2 && (e.id==10 || e.id==17) ) {
                materials.push({qty:0, note:'', product_type:e.id, product_type_value:{value: e.id, label:e['name_'+locale]}});
            }
            if (this.props.type=='galvanisation' && e.product_type==3) materials.push({qty:0, note:'', product_type:e.id, product_type_value:{value: e.id, label:e['name_'+locale]}});
        });
        console.log(materials);
        return materials;
    }


    get_my_hours() {
        HttpService.get(UrlService.apiDomain() + 'api/production/get_my_hours/' + this.state.production_date + '/' + this.state.manufacturer)
            .then(res => {

                var locale = 'cs';
                if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                var wt = this.props.t('Choose');
                if (res.data.work_type!=='') wt= JSON.parse(localStorage.getItem('work_types')).find(refData => refData['id'] == res.data.work_type)['name_' + locale];
                this.setState({
                    work_type: res.data.work_type,
                    work_note: res.data.work_note,
                    work_type_value: {value: res.data.work_type, label: wt},
                    additional_hours: res.data.additional_hours,
                }, () => {
                });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
console.log('compdidupdate');
console.log(prevState);
console.log(this.state);
        console.log(prevProps);
        console.log(this.props);
        if (prevState.manufacturer!==this.state.manufacturer || prevState.production_date!==this.state.production_date || prevState.id!==this.state.id ||
            prevState.show!==this.state.show) {

this.get_my_hours();
        }

        if (prevProps.id!==this.props.id ||
            prevProps.show!==this.props.show) {
console.log('reopening')

            this.setState({
                materials:this.materials(),
                no_salary:0,
                id: (this.props.id) ? this.props.id : '',
                production_date: this.today(),
                processed_qty:"",
				processed_pcs: "",
                show:this.props.show,
                manufacturer: this.usersArray()[0]['value'],
                manufacturer_value:this.usersArray()[0],
                allow_submit: false,
            });


        }
    }


    handleFormSubmit(event) {
        event.preventDefault();

        const fileInput = document.querySelector('#attachment') ;
        var files=fileInput.files;
        /*
         if( files.length===0) {
         alert(this.props.t('Prosím, nahrajte sou'));
         return false;
         }
         */
        let files_ok=true;
        let allowed_exts=['jpg','jpeg','png','gif','bmp','tiff', 'pdf', 'avi', 'mov','mpg','mpeg'];


        Array.from(files).forEach(e => {
            if(!allowed_exts.includes(e.name.split('.')[e.name.split('.').length - 1].toLowerCase())) files_ok=false;
        });


        if (!files_ok) {
            alert(this.props.t('Špatný formát skenu'));
            return false;
        }


        const {no_salary, production_date, processed_qty, processed_pcs, additional_hours, manufacturer, materials, work_type, work_note} = this.state;


const id=this.props.id;
        this.props.onAdd({ no_salary, id, production_date, processed_qty, processed_pcs, additional_hours, manufacturer, materials, work_type, work_note, files });


    }

    allow_submit() {

        var allow_submit=true;


        if (!this.state.production_date) allow_submit=false;

        if (this.state.manufacturer_value.value=='') allow_submit=false;
       if (this.state.work_type_value.value=='' && parseFloat(this.state.additional_hours)>=0.1) allow_submit=false;

        var sum_mat=0;
        this.state.materials.map((e)=>{
            sum_mat=sum_mat+parseInt(e.qty);
            if (!e.product_type) allow_submit=false;
            return true;
        });

        if (sum_mat===0) allow_submit=false;

       // if (sum_mat>this.state.processed_qty)  allow_submit=false;
        this.setState({allow_submit:allow_submit});
    }

    handleMaterialOnChange(event, i, type) {

        if (type==='product_type') {
            var materials = this.state.materials;
            materials[i][type]=event.value;
            materials[i][type+'_value']=event;
            this.setState({materials:materials});
            this.allow_submit();
        }
        else  if (type==='manufacturer') {
            this.setState({manufacturer:event.value, manufacturer_value:event}, () => {
                this.allow_submit()
            });

        }
        else  if (type==='work_type') {
            this.setState({work_type:event.value, work_type_value:event}, () => {
                this.allow_submit()
            });

        }

        else if (type==='qty'){
            var materials = this.state.materials;
            materials[i][type]=event.target.value;
            this.setState({materials:materials});

            // recalculating processed qty
            var processed_qty=0;
            materials.forEach((e)=>{
                processed_qty=processed_qty+parseFloat(e['qty']);
            })
            this.setState({processed_qty: processed_qty});

            this.allow_submit();
        }
        else if (type==='note'){
            var materials = this.state.materials;
            materials[i][type]=event.target.value;
            this.setState({materials:materials});
            this.allow_submit();
        }
        else if (type==='production_date') {

            if (typeof event==="object") {
                this.setState({production_date: event.format("DD.MM.YYYY")});

                this.allow_submit();
            } else {
                this.setState({production_date: event});
                this.setState({allow_submit:false});

            }
        }

    }

    usersArray() {
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        const my_user=JSON.parse(localStorage.getItem('my_user'));
        var usersArray=[];
        if (permissions.includes('storage.edit')) {

            JSON.parse(localStorage.getItem('users')).map((e)=>{
                if ((e.role==='Vyroba' && e.active) || (e.role==='Vedoucí výroby' && e.active) || (e.role==='Galvanovna' && e.active)) usersArray.push({value: e.id, label:e.name});
            });
        } else {
            usersArray= [{value: my_user.id, label:my_user.name}];
        }

        usersArray=usersArray.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        usersArray.unshift([{value: "", label:this.props.t('Choose')}]);

        return usersArray;
    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        const { production_date} = this.state;
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));

        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            if (this.props.type!='galvanisation' && parseInt(e.product_type)===2) optionsArray.push({value: e.id, label:e['name_'+locale]});
            if (this.props.type=='galvanisation' && parseInt(e.product_type)===3) optionsArray.push({value: e.id, label:e['name_'+locale]});

        });

        const workTypes = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('work_types')).map((e)=>{
            workTypes.push({value: e.id, label:e['name_'+locale]});
        });


        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });

        var displ={};
        var disabled=false;
        if (roles.includes('Vyroba')) {
            displ={display:'none'};
            disabled=true;
        }


        const my_user=JSON.parse(localStorage.getItem('my_user'));

        var usersArray=this.usersArray();


        console.log(this.state);
// inputProps={{disabled:!permissions.includes('storage.edit')}}
        return (
            <div style={{'height':'500px', 'overflowY':this.state.overflowY, 'overflowX':'hidden'}}>
                <form onSubmit={(event) => this.handleFormSubmit(event)}>

                    <div className="container">
                        <div className="row">


                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Production date')}</label>
                                    <Datetime

                                        timeFormat={false}
                                        locale={locale}
                                        dateFormat="DD.MM.YYYY"
                                        value={production_date}

                                        onChange={(event) => this.handleMaterialOnChange(event, null, 'production_date')}
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Worker')}</label>

                                    <Select key={'manufacturer'}
                                            name="manufacturer"
                                            onChange={(event) => this.handleMaterialOnChange(event, 0, 'manufacturer')}
                                            value={this.state.manufacturer_value}
                                            required
                                            placeholder={this.props.t('Select')}
                                            classNamePrefix="select"
                                            options={usersArray} />
                                </div>
                            </div>

							<div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Processed pcs.')}</label>
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        className="form-control"
                                        value={this.state.processed_pcs}
                                        onChange={(event) => {
                                            
											let V = 0;
											
											if( event.target.value != "" ){
												
												V = parseInt( event.target.value );
												
											}
											
                                            this.setState({processed_pcs: V});
                                        
                                        } }
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="date">{this.props.t('Bez mzdy')}</label>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        value="1"
                                        checked={this.state.no_salary}
                                        onChange={(event) => this.setState({ no_salary: event.target.value })}
                                    />
                                </div>
                            </div>

                            <div className="col-3" style={{display:'none'}}>
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Processed qty.')}</label>
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        className="form-control"
                                        value={this.state.processed_qty}
                                        onChange={(event) => {
                                            if (event.target.value==='' || parseInt(event.target.value)<=parseInt(this.props.rownode.remaining_qty))
                                            this.setState({processed_qty: event.target.value});
                                        }
                                        }
                                    />
                                </div>
                            </div>



                            <div className="col-3" style={{display:'none'}}>
                                <label htmlFor="description">{this.props.t('Work note')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={this.state.work_note}
                                    onChange={(event) =>
                                        this.setState({ work_note: event.target.value }, () => {

                                        })
                                    }
                                />
                            </div>


                        </div>

                        {
                            this.state.materials.map((material, i) => (
                                <div className="row" key={i}>
                                    <div className="col-3">
                                        <div className="form-group">

                                            <Select key={'product_type_'+i}
                                                    name="product_type"

                                                    onChange={(event) => this.handleMaterialOnChange(event, i, 'product_type')}
                                                    value={material.product_type_value}
                                                    required
                                                    placeholder={this.props.t('Select')}
                                                    classNamePrefix="select"
                                                    options={optionsArray} />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group">

                                            <input key={'qty_'+i}
                                                   type="text"
                                                   pattern="[0-9]*"
                                                   className="form-control"
                                                   value={material.qty}
                                                   placeholder={this.props.t('Enter the value')}
                                                   onChange={(event) => this.handleMaterialOnChange(event, i, 'qty')}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group">

                                            <input key={'note_'+i}
                                                   type="text"
                                                   className="form-control"
                                                   value={material.note}
                                                   placeholder={this.props.t('Note')}
                                                   onChange={(event) => this.handleMaterialOnChange(event, i, 'note')}
                                            />
                                        </div>
                                    </div>


                                </div>
                            ))
                        }



                    </div>
                    <div className="row">

                    <div className="col-12">
                        <div className="form-group">

                            <input id="attachment" name="attachment" type="file" />
                        </div>
                    </div>
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <div className="form-group">
                                <button className="btn btn-success" disabled={this.state.allow_submit ? false : true}>{this.props.t('Save')}</button>
                            </div>
                        </div>

                        <div className="col-2"  >
                            <div className="form-group">
                                <div className="btn-group">
                                    <button className="btn btn-primary"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                var materials=this.state.materials;
                                                materials.push({qty:'', product_type:'', product_type_value:{value: "", label:this.props.t('Choose')}})
                                                this.setState({materials:materials});
                                                this.allow_submit();
                                            }}
                                    ><i className="fa fa-plus"></i></button>
                                    <button className="btn btn-primary"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                var materials=this.state.materials;
                                                materials.pop();
                                                this.setState({materials:materials});
                                                this.allow_submit();
                                            }}
                                            disabled={this.state.materials.length===1 ? true : false}><i className="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
ProductionTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ProductionTab);

