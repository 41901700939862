import React, {Component} from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import {confirmAlert} from 'react-confirm-alert'; // Import
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import SessionStorageService from "../services/SessionStorageService";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class FileRenderer extends Component {


    downloadAttachment(X) {
        HttpService.postblob(UrlService.apiDomain() + 'api/attachments/download_attachment', {attachment: X})
            .then((response) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                let tmp = X.split('/');
                let filename = tmp[tmp.length - 1];
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    row() {
        var row = null;
        if (this.props.node.data && this.props.node.data.id) {
            row = this.props.node.data;
        } else if (this.props.node.allLeafChildren && this.props.node.allLeafChildren[0] && this.props.node.allLeafChildren[0].data) {
            row = this.props.node.allLeafChildren[0].data;
        }

        return row;
    }

    handleUpload() {
        const fileInput = document.querySelector('#file_' + this.props.node.data.id);
        var files = fileInput.files;

        if (files.length === 0) {
            alert(this.props.t('Please choose the file.'));
        } else {

            this.uploadFile(files[0], this.props.node.data.id);
        }
    }
	
	handleUploadMultiple( ){
		
		const formData = new FormData();
		
		
		
		const fileInput = document.querySelector( '#file_' + this.props.node.data.id );
		
        let files = fileInput.files;

        for(
			let I = 0;
			I < files.length;
			I++
		) {
			
			let file = files[ I ];
			
			formData.append( 'file_' + I, file );
			
			formData.append( 'filename_' + I, file.name );
			
        }
		
		formData.append( 'filecount', files.length );
		
		HttpService.post(UrlService.apiDomain() + 'api/upload/' + this.props.type + '/' + this.props.node.data.id, formData)
            .then(res => {
                alert(this.props.t('Successfully uploaded'));
				this.props.on_update( this.props.node.data.id, res.data.entry );
            })
            .catch((error) => {
                console.log(error);
            })
		
	}

    uploadFile(file, id) {

        const formData = new FormData();
        formData.append('file', file);
        HttpService.post(UrlService.apiDomain() + 'api/upload/' + this.props.type + '/' + id, formData)
            .then(res => {
                alert(this.props.t('Successfully uploaded'));
            })
            .catch((error) => {
                console.log(error);
            })
    }


    render() {
        var row = this.row();

        var attachment = '';
        var ext = 'word';
        if (this.props.type === 'document_template') attachment = row.file_path;
        if (this.props.type === 'stamp_image') {
            ext = 'image';
            attachment = row.stamp_image;
        }
        if (row.filetype === 'docx') ext = 'word';
        if (row.filetype === 'pdf') ext = 'pdf';
        if (row.filetype === 'xlsx') ext = 'excel';
        if (this.props.type === 'generated_documents' || this.props.type === 'contracts') {
            attachment = row.attachment;

        }

        if (this.props.type === 'contracts' || this.props.type === 'productions') {
            ext = 'pdf';
            attachment = row.attachment;

        }

        if (this.props.type === 'invoice_file') {
            attachment = row.invoice_file;
            ext = 'pdf';
        }

        if (this.props.type === 'wiretransfer') {
            //console.log(row);
            attachment = row.multicash_file;
            ext = 'pdf';
        }


		let elements = [ ];

        if (this.props.gridType == 'loadings') {
            var files = [];
            var invoice_numbers = []
            if (row.invoice_file != null && row.invoice_file != '') files = row.invoice_file.split(',');
            if (row.invoice_number != null && row.invoice_number != '') invoice_numbers = row.invoice_number.split(',');

            invoice_numbers.map((e, i) => {
                elements.push(<>{e}
                    <button key={i}
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.downloadAttachment(files[i])}
                            title={this.props.t('Download') + ' ' + e}>
                        <i key={i} className="fa fa-file-pdf"></i>,
                    </button>
                </>);
            });
        }
		
		if( this.props.gridType == 'expenses' ){
			
			console.log( row.files );
			
			for(
				let I = 0;
				I < row.files.length;
				I++
			){
				
				elements.push(
                    <button key={I}
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.downloadAttachment( row.files[ I ])}
                            title={this.props.t( 'Download' ) + ' ' + I }>
                        <i key={I} className="fa fa-file"></i>,
                    </button>
                );
					
			}
			
			console.log( elements );
			
		}


        return (

            <>
                { ( this.props.gridType == 'loadings' ) &&
                    <>
                        {elements}
                    </>
                }
				{ ( this.props.gridType == 'expenses') &&
					<>
						{elements}
						<input style={{lineHeight: '19px'}} id={ "file_" + row.id } name="file" type="file" multiple={true}/>
						<button
							className="btn btn-default btn-sm delete-ingrid-but"
							onClick={() => this.handleUploadMultiple( )}
							title={this.props.t('Upload')}
						>{this.props.t('Upload')}
						</button>
					</>
				}
                {(this.props.gridType !== 'loadings') &&
                    <>
                        {(this.props.type === 'invoice_file') &&
                            <>{row.invoice_number}</>
                        }

                        {(this.props.type === 'wiretransfer') &&
                            <>{row.total_amount}</>
                        }

                        {(this.props.type === 'document_template' || this.props.type === 'stamp_image' || this.props.type === 'contracts' || this.props.type === 'productions') &&
                            <>
                                <input style={{lineHeight: '19px'}} id={"file_" + row.id} name="file" type="file"/>
                                <button
                                    className="btn btn-default btn-sm delete-ingrid-but"
                                    onClick={() => this.handleUpload()}
                                    title={this.props.t('Upload')}
                                >{this.props.t('Upload')}
                                </button>
                            </>
                        }
                        {(attachment && attachment !== '' && attachment !== null) &&
                            <div className="btn-group" style={{'marginLeft': '9px'}}>
                                <button
                                    className="btn btn-default btn-sm delete-ingrid-but"
                                    onClick={() => this.downloadAttachment(attachment)}
                                    title={this.props.t('Download')}
                                ><i className={"fa fa-file-" + ext}/>
                                </button>
                            </div>
                        }

                    </>
                }


            </>
        );
    }
}

FileRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(FileRenderer);