"use strict";


import HTTPHeaderT from "./HTTPHeaderT.mjs";


const HTTPHeadersT = class HTTPHeadersT {
	
	#Map = null;
	
	constructor(
		HTTPHeaderArray
	){
		
		if( ( HTTPHeaderArray instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < HTTPHeaderArray.length;
			I++
		){
			
			if( ( HTTPHeaderArray[ I ] instanceof HTTPHeaderT ) === false ){
			
				throw new Error( "Invalid argument" );
			
			}
			
		}
		
		
		this.#Map = new Map( );
		
		for(
			let I = 0;
			I < HTTPHeaderArray.length;
			I++
		){
			
			this.Set( HTTPHeaderArray[ I ] );
			
		}
		
		
	}
	
	Set( 
		HTTPHeader
	){
		
		if( ( HTTPHeader instanceof HTTPHeaderT ) === false ){
			
			throw new Error( "Invalid argument" );
		
		}
			
		
		this.#Map.set( HTTPHeader.Key( ), HTTPHeader );
		
	}
	
	Get( 
		Key 
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		return this.#Map.get( Key );
		
	}
	
	Remove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#Map.delete( Key );
		
	}
	
	Keys( 
	
	){
		
		return this.#Map.keys( );
		
	}
	
};


export default HTTPHeadersT;