import React, {Component} from "react";
import $ from "jquery";

export default class loadingsStatusCount extends Component {
    constructor(props) {
        super(props);

    }

    render() {


        var findata = {invoice_excvat_kc: 0};
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');


        let selected = this.props.api.getSelectedRows();
        if (selected?.length > 0) {
            for (const selectedElement of selected) {
                var invoice_excvat_kc = parseFloat(selectedElement.invoice_excvat_kc);
                findata['invoice_excvat_kc'] = findata['invoice_excvat_kc'] + invoice_excvat_kc;
            }
        } else {
            this.props.api.forEachNodeAfterFilter((rowNode, index) => {
                if (rowNode.data && rowNode.data.id) {
                    if (rowNode.data.invoice_excvat_kc != null) {
                        var invoice_excvat_kc = parseFloat(rowNode.data.invoice_excvat_kc);

                        findata['invoice_excvat_kc'] = findata['invoice_excvat_kc'] + invoice_excvat_kc;
                    }

                }

            });
        }


        return (

            <div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
                <span
                    style={{marginRight: '10px'}}>Bez DPH kč: <strong>{findata['invoice_excvat_kc'].toFixed(1)}</strong></span>
            </div>
        );
    }
}