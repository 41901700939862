import React, { Component } from "react";
import $ from "jquery";

export default class productionStatusCount extends Component {
    constructor(props) {
        super(props);

    }

    render() {


var findata=[];
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');


        var productTypes={};
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            productTypes['val_'+e.id]=e['name_'+locale];

        });

        this.props.api.forEachNodeAfterFilter((rowNode, index) => {
            if (typeof findata[productTypes['val_'+rowNode.data.product_type]] == 'undefined') {
                findata[productTypes['val_'+rowNode.data.product_type]]=0;
            }
            findata[productTypes['val_'+rowNode.data.product_type]]=findata[productTypes['val_'+rowNode.data.product_type]]+parseFloat(rowNode.data.qty);

        });


        const keys = Object.keys(findata);

/*
        {  Object.keys(findata).forEach(function (key, index) {
            alert(this[key]+": "+key);
            <div>{this[key]}</div>
        }, findata)
        }
*/
var fin_findata=[];
        keys.forEach((key, index) => {
        fin_findata.push(<span style={{marginRight:'10px'}}>{key}: <strong>{findata[key]}</strong></span>);

        });
        return (

            <div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count" style={{display: "flex", flexWrap: "wrap"}}>
                {
                    fin_findata.map((material, i) => ( <span key={i}>{material}</span> ))
                }
            </div>
        );
    }
}