import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import UserAdd from "./user_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.jsx";
import MultiSelectLargeData from "../../../aggrid/MultiSelectLargeData.jsx";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import FileRenderer from "../../../aggrid/FileRenderer.jsx";

import SessionStorageService from "../../../services/SessionStorageService";

class Users extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };
        super(props)

        var columnDefs=[
            {headerName: this.props.t('User'),
            children:[
                {
                    headerName: this.props.t('Name'),
                    field: 'name',
                    pinned:'left',
                    editable: true,
                    checkboxSelection:true,
                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Role'),
                    field: 'role',
                    filter: 'agTextColumnFilter',
                    cellEditor: 'MultiSelectSimple',
                    editable: true,
                },

                {
                    headerName: this.props.t('Team'),
                    field: 'team',
                    filter: 'agTextColumnFilter',
                    cellEditor: 'MultiSelectSimple',
                    editable: true,
                },

                {
                    headerName: this.props.t('Email'),
                    field: 'email',
                    editable: true,
                    columnGroupShow:'open',
                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Phone'),
                    field: 'phone',
                    editable: true,
                    columnGroupShow:'open',
                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Position'),
                    field: 'position',
                    editable: true,
                    columnGroupShow:'open',
                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Company'),
                    field: 'company',
                    editable: true,
                    columnGroupShow:'open',
                    cellEditor: 'agRichSelectCellEditor',

                    cellEditorParams: {values: JSON.parse(localStorage.getItem('companies')).map(e=>e['name'])},

                    valueSetter: function(params) {
                        params.data['company'] = JSON.parse(localStorage.getItem('companies')).find(refData => refData['name'] == params.newValue).id;
                        return true;
                    },
                    valueGetter: function(params) {
                        try {
                            if (typeof params.data == 'undefined' || params.data['company'] == null || params.data['company'] == '' || params.data['company'] == 'none') return '';
                            return JSON.parse(localStorage.getItem('companies')).find(refData => String(refData.id) === String(params.data['company']))['name'];
                        } catch(err) {
                            console.log(err);
                        }
                    }

                },



                {
                    headerName: this.props.t('Stamp image'),
                    field: 'stamp_image',

                    columnGroupShow:'open',

                    cellRenderer: "FileRenderer",
                    minWidth:400,
                    suppressMenu: true,
                    cellRendererParams: {
                        type: 'stamp_image'
                    }
                },

                {
                    headerName: this.props.t('Send notifications'),
                    field: 'send_notifications',
                    minWidth: 90,
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                    columnGroupShow:'open',
                },

                {
                    headerName: this.props.t('Read emails'),
                    field: 'read_email',
                    minWidth: 90,
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                    columnGroupShow:'open',
                },

                {
                    headerName: this.props.t('Password'),
                    field: 'password',
                    editable: true,
                    columnGroupShow:'open',
                    suppressMenu: true,
                },

                {
                    headerName: this.props.t('Active'),
                    field: 'active',
                    minWidth: 90,
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                    columnGroupShow:'open',
                },


            ]
            }
        ];

        JSON.parse(localStorage.getItem('main_tables')).map((t)=> {
            var childrenColumns=[];


            // Roles column data permissions

            var first=true;
            JSON.parse(localStorage.getItem('columns')).map((c)=> {
                if (c.model!==t.name) return false;
                if (c.permissions==1) {
                    if (c.column_type=='boolean') c.data_array='booleans';
                    var obj = {};
                    obj.headerName=c['name_'+locale];
                    obj.field=t['name']+'/columns-'+c.field;

                    obj.editable=true;
                    if (!first) obj.columnGroupShow='open';
                    if (first) first=false;


                    if (c.data_array=='' || c.data_array==null || c.data_array=='none' || c.data_array=='states') {

                        obj.cellEditor='MultiSelectSimple';
                    }
                    else if (c.data_array=='cities' || c.data_array=='zipcodes') {
                        obj.cellEditor='MultiSelectLargeData';
                        obj.cellEditorParams={
                            gridType: "users",
                            multiple: true,
                        };
                    }

                    else {
                        obj.cellEditor='MultiSelectSimple';
                        if (c.data_array=='custom') {
                            var data=JSON.parse(c.data)
                        } else {
                            var data=JSON.parse(localStorage.getItem(c.data_array));
                        }


                        obj.valueGetter=function(params) {
                            if (params.data[t['name']+'/columns-'+c.field]==null) return '';
                            var res='';
                            params.data[t['name']+'/columns-'+c.field].split('//').map((e)=>{
                                if (e!='') {
                                    try {
                                        res += data.find(refData => String(refData.id) === String(e))['name_' + locale] + '//';
                                    } catch (err) {
                                        console.log(params);
                                        console.log(err);
                                    }
                                }
                            });

                            return res.slice(0, -2);
                        }

                    }

                    childrenColumns.push(obj);
                }
            });

            columnDefs.push( {headerName:this.props.t('Filter')+' '+t['name_'+locale],
                children:childrenColumns});
        });


columnDefs.push({headerName:this.props.t('Delete'),
    cellRenderer:"DeleteRenderer",
    minWidth: 90,
    cellRendererParams: {
        gridType: "users"
    },
    suppressMenu: true,});

        this.state = {
            showModal: false,
            popupParent: document.querySelector('body'),
            localeText: loctext,
            modules: AllCommunityModules,
            rowdata: null,
            columnDefs: columnDefs,
            defaultColDef: {
                flex: 1,
                minWidth: 170,
                editable: false,
                resizable: true,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                MultiSelectSimple: MultiSelectSimple,
                MultiSelectLargeData: MultiSelectLargeData,
                FileRenderer: FileRenderer
            }
        };
    }



    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/users')
            .then(res => {

                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';

        var columnState = JSON.parse(localStorage.getItem('users_column_state'));
        if (columnState) {
            params.columnApi.setColumnState(columnState);
        }
    };

    onCellCValueChanged = (event) => {
        event.data.field=event.colDef.field;
        HttpService.put(UrlService.apiDomain()+'api/users/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('users', res.data.entry);
                SessionStorageService.remove('my_permissions');
                SessionStorageService.remove('my_roles_meta');
                SessionStorageService.remove('my_users_meta');
                localStorage.setItem('my_permissions', JSON.stringify(res.data.my_permissions));
                localStorage.setItem('my_roles_meta', JSON.stringify(res.data.my_roles_meta));
                localStorage.setItem('my_users_meta', JSON.stringify(res.data.my_users_meta));

                event.node.setData(res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;
        HttpService.post(UrlService.apiDomain()+'api/users', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                    SessionStorageService.add('users', res.data.entry);


                }

            })
            .catch((error) => {
                alert('EE'+error);
            });


    }

    onDragStopped(params) {

        var columnState = JSON.stringify(params.columnApi.getColumnState());
        localStorage.setItem('users_column_state', columnState);
    }
    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message:  this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label:this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/users/' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                    SessionStorageService.remove_by_id('users', e.data.id);
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t("Users")}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t("Add new")} <i className="fa fa-plus"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRows()}
                            >
                                {this.props.t('Delete')} <i className="fa fa-trash"></i>
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t("Excel")} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t("Add new")}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <UserAdd onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onDragStopped={this.onDragStopped}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                popupParent={this.state.popupParent}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Users.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Users);

