"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentBaseConfigT from "../../../Type/Component/Base/ComponentBaseConfigT.mjs";


import { 
	ComponentT as ComponentT 
} from "../ComponentInternalT.mjs";


const ComponentBaseT = class ComponentBaseT {
	
	#GUIElement = null;
	#ComponentBaseConfig = null;
	
	#Children = null;
	#Events = null;
	
	#EventMap = null;
	
	constructor(
		GUIElement,
		ComponentBaseConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#GUIElement = GUIElement;
		
		this.ConfigSet( ComponentBaseConfig ); //TODO Copy
		
		this.#Children = new Map( );
		
		this.#Events = new Map( );
		
	}
	
	Prepare(
		PrepareMap
	){
		
		
		
	}
	
	Listen(
		EventBucket
	){
		
		/*for(
			let I of this.#Children.keys( )
		){
			
			this.#Children.get( I ).Listen( EventMap );
			
		}
		
		this.#EventMap = EventMap;*/
		
	}
	
	Update(
		
	){
		
		this.#GUIElement.Update( this.#ComponentBaseConfig );

		
	}
	
	DataRowSet(
		RowIndex
	){
		
		this.#GUIElement.DataRowSet( RowIndex );
		
	}
	
	DataRowGet(
		
	){
		
		return this.#GUIElement.DataRowGet(  );
		
	}
	
	Element( ){
		
		return this.#GUIElement;
		
	}
	
	ChildAppend(
		Child
	){
		
		console.log( "ChildAppend", Child );
		
		if( ( Child instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Children.has( Child ) === true ){
			
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Children.set( Child, Child );
		
		this.#GUIElement.ChildAppend( Child.Element( ) );
		
	}
	
	ChildRemove(
		Child
	){
		
		if( ( Child instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Children.has( Child ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		this.#Children.delete( Child );
		
		this.#GUIElement.ChildRemove( Child.Element( ) );
		
	}
	
	ChildGet(
		Child
	){
		
		if( ( Child instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Children.has( Child ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		

		return this.#Children.get( Child );
		
	}
	
	ChildReplace( 
		ChildOld,
		Child
	){
		
		if( ( ChildOld instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Child instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Children.has( ChildOld ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		this.#GUIElement.ChildReplace( Child.Render( ), ChildOld.Render( ) );
		
		this.#Children.set( Child, Child );
		
	}
	
	ChildrenKeys( ){
		
		console.log( "ChilrenKeys", this.#Children );
		
		return this.#Children.keys( );
		
	}
	
	/*ChildrenSet(
		ChildrenConfig
	){
		
		console.log( ChildrenConfig );
		
		for(
			let I of ChildrenConfig.keys( )
		){
			
			let Child = new ComponentT( 
				ChildrenConfig.get( I )
			);
			
			console.log( Child );
			
			this.ChildAppend( Child );
			
		}
		
	}*/
	
	EventAppend(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		//TODO Check if exists
		
		this.#Events.set( Key, Value );
		
		this.#GUIElement.EventAppend( Key, Value );
		
	}
	
	/*EventRemove(
		Key,
		Value
	){
		
		this.#Config
		
		this.#Element.EventRemove( 
			Key, 
			Value 
		);
		
	}*/
	
	ConfigSet(
		ComponentBaseConfig
	){
		
		if( ( ComponentBaseConfig instanceof ComponentBaseConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentBaseConfig = ComponentBaseConfig;
		
		this.#GUIElement.Update( this.#ComponentBaseConfig );
		
	}
	
	ConfigGet(
	
	){
		
		return this.#ComponentBaseConfig;
		
	}
	
	StyleMapSet( StyleMap ){

		for( let I of StyleMap.Keys( ) ){
			
			this.StyleSet( I, StyleMap.Get( I ) );
			
		}

	}
	
	StyleSet( 
		Key,
		Value
	){
		
		console.log( Key, Value );
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentBaseConfig.StyleSet( Key, Value );
		
		this.#GUIElement.StyleSet( Key, Value );
		
	}
	
	ValueGet( ){
		
		throw new Error( "Need overriding" );
		
	}
	
	ValueSet(
		Value
	){
		
		throw new Error( "Need overriding" );
		
	}
	
	StyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#ComponentBaseConfig.StyleGet( Key );
		
	}
	
	NameGet( ){
		
		return this.#ComponentBaseConfig.NameGet( );
		
	}
	
	Focus( ){
	
		this.#GUIElement.Focus( );
	
	}
	
	Blur( ){
	
		this.#GUIElement.Blur( );
	
	}
	
	InnerTextSet(
		InnerText
	){
		
		this.#GUIElement.InnerTextSet( 
			InnerText
		);
		
	}
				
};


export default ComponentBaseT;