import {useEffect, useState} from "react";
import HttpService from "../services/HttpService";
import UrlService from "../services/UrlService";
import $ from 'jquery';

export default function AppraisalsImageRenderer({value}) {
    const [images, setImages] = useState([]);
    const [fullImage, setFullImage] = useState(null);

    useEffect(() => {
        let val = JSON.parse(value);
        setImages(val);
    }, [value]);

    return <>
        {images.map((item) => {
            return <img
                style={{
                    width: '50px',
                    height: '50px',
                    border: '1px #ee solid',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    margin: '6px'
                }}
                onClick={() => {
                    if (!fullImage) {
                        HttpService.post(UrlService.apiDomain() + 'api/attachments/by_path', {
                            path: item.image_path
                        }).then(response => {
                            let image = "data:image/jpg;base64," + response.data.image;
                            setFullImage(image);
                            $('.zevgan_modal').show()
                            $('#zevgan_lightbox_img').attr('src', image);
                        })
                    } else {
                        $('.zevgan_modal').show()
                        $('#zevgan_lightbox_img').attr('src', fullImage);
                    }
                }}
                src={`data:image/png;base64,${item.small_image}`}
                alt={'Image'}
            />
        })
        }
    </>
}