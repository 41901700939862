import React, { Component } from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import DatePicker from "../../aggrid/DatePicker.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import $ from 'jquery';
import ContractAdd from "./add.tsx";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import Modal from "../../components/common/modal";

import SessionStorageService from "../../services/SessionStorageService";

import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import {LoadState, SaveState} from "../../services/GridStateService";

class Contracts extends Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        super(props);
		
		let columnDefs = [


                {
                    headerName: this.props.t('Company'),
                    field: 'company',
                    valueGetter:function(params) {

                        var locale = 'cs';
                        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
                        var value = '';
                        JSON.parse(localStorage.getItem('companies')).map((c) => {


                            if (String(c.id) === String(params.data.company) && String(c.company_type!='1')) {
                                value = c['name'];

                            }
                            return true;

                        });
                        return value;
                    }
                },

                {
                    headerName: this.props.t('Contract number'),
                    field: 'contract_number',
                    editable:true
                },

                {
                    headerName: this.props.t('Ordered quantity'),
                    field: 'qty',
                    editable:true
                },
                {
                    headerName: this.props.t('Supplied quantity'),
                    field: 'supplied',
                    editable:false
                },
                {
                    headerName: this.props.t('Date'),
                    field: 'date',
                    editable:true,

                    cellEditor: 'DatePicker',
                    comparator: function(date1, date2) {

                        function _monthToNum(date) {
                            if (date === undefined || date === null) {
                                return null;
                            }

                            var date_arr=date.split('.');

                            var yearNumber = parseInt(date_arr[2]);
                            var monthNumber = parseInt(date_arr[1]);
                            var dayNumber = parseInt(date_arr[0]);

                            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                            // 29/08/2004 => 20040829
                            //console.log(result);
                            return result;
                        }



                        var date1Number = _monthToNum(date1);
                        var date2Number = _monthToNum(date2);

                        console.log(date1Number+"@"+date2Number);

                        if (date1Number === null && date2Number === null) {
                            return 0;
                        }
                        if (date1Number === null) {
                            return -1;
                        }
                        if (date2Number === null) {
                            return 1;
                        }

                        return date1Number - date2Number;
                    },
                    valueSetter: function(params) {
                        if (params.data && params.data.id) {
                            params.data.date = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0];
                            return true;
                        }
                    },

                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.date===null || params.data.date===0 || params.data.date==='') return '';
                        var  date = new Date(params.data.date);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();

                        month++;
                        return day+'.'+month+'.'+year;

                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        // provide comparator function
                        comparator: (filterLocalDateAtMidnight, cellValue) => {
                            const dateAsString = cellValue;

                            if (dateAsString == null) {
                                return -1;
                            }

                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            const dateParts = dateAsString.split('.');
                            const day = Number(dateParts[0]);
                            const month = Number(dateParts[1]) - 1;
                            const year = Number(dateParts[2]);
                            const cellDate = new Date(year, month, day);

                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            }
                            return 0;
                        }
                    },
                },

                {
                    headerName: this.props.t('Deadline'),
                    field: 'deadline',
                    editable:true,

                    cellEditor: 'DatePicker',
        comparator: function(date1, date2) {

            function _monthToNum(date) {
                if (date === undefined || date === null) {
                    return null;
                }

                var date_arr=date.split('.');

                var yearNumber = parseInt(date_arr[2]);
                var monthNumber = parseInt(date_arr[1]);
                var dayNumber = parseInt(date_arr[0]);

                var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                // 29/08/2004 => 20040829
                //console.log(result);
                return result;
            }



            var date1Number = _monthToNum(date1);
            var date2Number = _monthToNum(date2);

            console.log(date1Number+"@"+date2Number);

            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }

            return date1Number - date2Number;
        },
        valueSetter: function(params) {
            if (params.data && params.data.id) {
                params.data.deadline = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0];
                return true;
            }
        },

                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.deadline===null || params.data.deadline===0 || params.data.deadline==='') return '';
                        var  date = new Date(params.data.deadline);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();

                        month++;
                        return day+'.'+month+'.'+year;

                    },
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        // provide comparator function
                        comparator: (filterLocalDateAtMidnight, cellValue) => {
                            const dateAsString = cellValue;

                            if (dateAsString == null) {
                                return -1;
                            }

                            // In the example application, dates are stored as dd/mm/yyyy
                            // We create a Date object for comparison against the filter date
                            const dateParts = dateAsString.split('.');
                            const day = Number(dateParts[0]);
                            const month = Number(dateParts[1]) - 1;
                            const year = Number(dateParts[2]);
                            const cellDate = new Date(year, month, day);

                            // Now that both parameters are Date objects, we can compare
                            if (cellDate < filterLocalDateAtMidnight) {
                                return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                                return 1;
                            }
                            return 0;
                        }
                    },
                },

                {
                    headerName: this.props.t('Filled'),
                    field: 'filled',
                    cellRenderer: "CheckboxRenderer",
                },

                {
                    headerName: this.props.t('Delivery address'),
                    field: 'address',
                    editable:true
                },

                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                    editable:true
                },

                {
                    headerName: this.props.t('File'),
                    field: 'attachment',
                    cellRenderer: "FileRenderer",
                    minWidth:400,
                    suppressMenu: true,
                    cellRendererParams: {
                        type: 'contracts'
                    }


                }




		];
		
		let permissions = JSON.parse(localStorage.getItem('my_permissions'));
		
		if( permissions.includes( 'contracts.delete' ) ){
			
			columnDefs.push( {
				headerName: this.props.t('Delete'),
				cellRenderer: "DeleteRenderer",

				cellRendererParams: {
					gridType: "contracts",
				}
			} );
			
		}
		
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            now: Date.now(),
            showModal: false,
            showArchive: false,
            modules: AllCommunityModules,
            localeText: loctext,

            rowdata: null,
            columnDefs: columnDefs,
            defaultColDef: {
                flex: 1,
                minWidth:150,
                editable: false,
                resizable: true,
                sortable: true,
                filter:true,

            },
            frameworkComponents: {
                DeleteRenderer: DeleteRenderer,
                CheckboxRenderer: CheckboxRenderer,
                FileRenderer: FileRenderer,
                DatePicker: DatePicker,

            },
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/contracts')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });

                var hardcodedFilter = {
                    filled: {
                        type: 'set',
                        values: [null,"0"],
                    },
                };

                function tt() {
                    this.gridApi.setFilterModel(hardcodedFilter)
                }
                setTimeout(tt.bind(this), 10);
                setTimeout(() => {
                    LoadState('Contracts', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        LoadState('Contracts', params.columnApi, params.api);
    };

    onCellCValueChanged = (event) => {
        event.data.field=event.colDef.field;
        HttpService.put(UrlService.apiDomain()+'api/contracts/'+event.data.id, event.data)
            .then(res => {
                console.log(res);
                console.log('Successfully updated');
                var rownode=this.gridApi.getRowNode(res.data.entry.id);
                if (rownode) {
                    rownode.setData(res.data.entry);
                }

                SessionStorageService.update('contracts', res.data.entry);

            })
            .catch((error) => {
                console.log(error);
            })


    }


    onCellClicked=(e)=> {


    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('Contracts', params.columnApi, params.api);
        }
    }

    async handleAdd(data) {

        var form_data = new FormData();

        for ( var key in data ) {
            if (key!=='files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0]!=='undefined') form_data.append('file1', data.files[0]);

        HttpService.post(UrlService.apiDomain()+'api/contracts', form_data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    SessionStorageService.add('contracts', res.data.entry);

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }

    getRowNodeId=(data) => {
        return data.id;
    };

    render() {
        const rowClassRules = {
            'rag-green': 'data.supplied > data.qty',

        };
        return (
            <div className="wrapper">


                <Layout>
                    <Content title={this.props.t('Contracts')}>

                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <ContractAdd tmp={this.state.now} onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight':'9px'}}>
                                <button type="button"
                                        className="btn btn-success btn-sm"
                                        onClick={() => {

                                            if (!this.state.showArchive) {
                                                var hardcodedFilter = {
                                                    filled: {
                                                        type: 'set',
                                                        values: ["1"],
                                                    },
                                                };
                                                this.gridApi.setFilterModel(hardcodedFilter);
                                            } else {



                                                var hardcodedFilter = {
                                                    filled: {
                                                        type: 'set',
                                                        values: ["0",null],
                                                    },
                                                };
                                                this.gridApi.setFilterModel(hardcodedFilter);

                                            }

                                            this.setState({showArchive: !this.state.showArchive });


                                        }}
                                >{this.props.t('Archive')}  <i className="fa fa-archive"></i>
                                </button>





                            </div>
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true, now: Date.now() })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>
                        </div>

                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">


                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                                components={this.state.components}
                                getRowNodeId = {this.getRowNodeId}
                                rowClassRules={rowClassRules}
                                onCellClicked={this.onCellClicked}
                            />
                        </div>

                    </Content>
                </Layout>
            </div>
        );
    }
}

Contracts.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Contracts);

