import React, { Component } from "react";
import UserInterface from "./../../../../interfaces/UserInterface";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';


interface Props {
  user: UserInterface;
}

class UserProfileCard extends Component<Props> {
  render() {
    const {
      name,
      designation,

    } = this.props.user;
    console.log(this.props.user);
    console.log(this.props.user.role);
    return (
      <div className="card card-primary card-outline">
        <div className="card-body box-profile">
          <div className="text-center">

          </div>
          <h3 className="profile-username text-center">{name}</h3>
          <p className="text-muted text-center">{designation}</p>
          <ul className="list-group list-group-unbordered mb-3">
            <li className="list-group-item">
              <b>{this.props.t('Role')}</b>
              <span
                className="float-right"

              >
                {this.props.user.role}
              </span>
            </li>

            <li className="list-group-item">
              <b>{this.props.t('Email')}</b>
              <span
                  className="float-right"

              >
                {this.props.user.email}
              </span>
            </li>

          </ul>
          <a style={{display:'none'}}
            href="/"
            className="btn btn-primary btn-block"
            onClick={(event) => event.preventDefault()}
          >
            <b>To Do</b>
          </a>
        </div>
      </div>
    );
  }
}

UserProfileCard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate()(UserProfileCard);
