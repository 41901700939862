import React, { Component } from "react";
import { connect } from "react-redux";

import TopNav from "../topnav";
import Aside from "../aside";
import UserStateInterface from "../../../interfaces/UserStateInterface";
import UserService from "../../../services/UserService";
import { setUser } from "./../../../store/actions";
import Pusher from 'react-pusher';
import SessionStorageService from "../../../services/SessionStorageService";

interface Props {
	user: UserStateInterface;
	setUser: typeof setUser;
	onUpdateYear: (data) => void;
	onUpdateMonth: (data) => void;
	onUpdateContinent: (data) => void;
	onUpdateCountry: (data) => void;
};

class Layout extends Component<Props> {

	async componentDidMount() {
		const response = await UserService.getCurrentUserProfile();
		this.props.setUser(response);
		console.log( "Layout props", this.props );
	}

	render( ) {
	
		return (
			<React.Fragment>

				<TopNav 
					onUpdateYear={
						( Ev )=>{ 
						
							console.log( "topnav update year", Ev );
							
							if( this.props.onUpdateYear ){ 
								this.props.onUpdateYear( Ev ); 
							} 
							
						} 
					}
					onUpdateMonth={(e)=>{ console.log("topnav update month", e); if( this.props.onUpdateMonth ){ this.props.onUpdateMonth(e); } } }
					onUpdateContinent={(e)=>{ console.log("topnav update continent", e); if( this.props.onUpdateContinent ){ this.props.onUpdateContinent(e); } } }
					onUpdateCountry={(e)=>{ console.log("topnav update country", e); if( this.props.onUpdateCountry ){ this.props.onUpdateCountry(e); } } }
				/>
				
				<Aside user={this.props.user} />
				
				<Pusher
					channel="my-channel"
					event="my-event"
					onUpdate={(e)=>{ //console.log(e);

					var msg=JSON.parse(e.message);
					if (msg.action==='refresh_settings') {

					SessionStorageService.get_data_lists();
					}



					}}
				/>
				
				{ this.props.children }
			
			</React.Fragment>
		);
		
	}
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { setUser })(Layout);
