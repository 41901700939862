import React from "react";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
class Audit extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('User'),
                    field: 'user',
                },
                {
                    headerName: this.props.t('Column'),
                    field: 'column_'+locale,
                },
                {
                    headerName: this.props.t('Old value'),
                    field: 'old_values',
                },
                {
                    headerName: this.props.t('New value'),
                    field: 'new_values',
                },
                {
                    headerName: this.props.t('Date'),
                    field: 'created_at',

                },
                ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                    resizable: true,

                    sortable: true,
                suppressMovableColumns:true,

                suppressMenu: true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tmp !== this.props.tmp && this.props.id!=null) {

            HttpService.get(UrlService.apiDomain() + 'api/audit/'+this.props.type+"/" + this.props.id)
                .then(res => {
                    this.setState({
                        rowdata: res.data
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    changeName=(e)=>{

    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        /*
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#auditGrid').style.height = '';
        */
    };





    render() {


        return (


                        <div key={this.props.id} style={{ 'height': '400px', width:'100%' }} id="auditGrid" className="ag-theme-alpine">


                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}

                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                            />
                        </div>


        );
    }
}

Audit.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Audit);

