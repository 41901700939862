"use strict";


import UIElementT from "../../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import UIComponentLayoutHT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import TranslatorT from "../../../../../../../../../Interface/Translator/TranslatorT.mjs";

import GTSUIComponentFormSecondaryHeaderElementT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Header/GTSUIComponentFormSecondaryHeaderElementT.mjs";

import GTSUIComponentFormSecondaryHeaderT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Header/GTSUIComponentFormSecondaryHeaderT.mjs";


const GTSUIModelSaleFormAddItemsHeaderT = class GTSUIModelSaleFormAddItemsHeaderT extends GTSUIComponentFormSecondaryHeaderT {
	
	#T = null;
	
	#IsProduct = false;
	#InOutinvoice = false;
	#InSupplylist = false;
	#ProductType = null;
	#Code = null;
	#Description = null;
	#Unit = null;
	#Quantity = null;
	#QuantityBrutto = null;
	#ExcVAT = null;
	#VATRate = null;
	#VAT = null;
	#PaletteNumber = null;
	#Remove = null;
	
	constructor( 
		Locale
	){


		let LabelUIStateMapDefault = new UIStateMapT( [
			
			new UIStateT(
				"Default",
				"UIComponentLabelT", //Class
				new UIStyleMapT( [ //UIStyleMap
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					//new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" ),
					new UIStyleT( "display", "flex" ),
					new UIStyleT( "flex", "0 1 auto" ),
					new UIStyleT( "align-self", "auto" ),
					new UIStyleT( "line-height", "16px" )
				] )
			)
			
		] );
		
		
		let T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		//IsProduct
		
		let IsProduct = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				"M",//T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"40px"
		);
		
		//InOutinvoice
		
		let InOutinvoice = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				"F",//T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"40px"
		);
		
		//InSupplylist
		
		let InSupplylist = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				"DL",//T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"40px"
		);
		
		//ProductType
		
		let ProductType = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Product type" ),
				LabelUIStateMapDefault.Copy( )
			),
			"10%"
		);
		
		//Code
		
		let Code = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"10%"
		);
		
		//Description
		
		let Description = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Description" ),
				LabelUIStateMapDefault.Copy( )
			),
			"10%"
		);
		
		//Unit
		
		let Unit = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Unit" ),
				LabelUIStateMapDefault.Copy( )
			),
			"8%"
		);
		
		//Quantity
		
		let Quantity = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Quantity" ),
				LabelUIStateMapDefault.Copy( )
			),
			"5%"
		);
		
		//QuantityBrutto
		
		let QuantityBrutto = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Brutto" ),
				LabelUIStateMapDefault.Copy( )
			),
			"5%"
		);
		
		//ExcVAT
		
		let ExcVAT = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Exc. VAT per unit" ),
				LabelUIStateMapDefault.Copy( )
			),
			"5%"
		);
		
		//VATRate
		
		let VATRate = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "VAT rate" ),
				LabelUIStateMapDefault.Copy( )
			),
			"8%"
		);
		
		//VAT
		
		let VAT = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "VAT per unit" ),
				LabelUIStateMapDefault.Copy( )
			),
			"5%"
		);
		
		//PaletteNumber
		
		let PaletteNumber = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Palette number" ),
				LabelUIStateMapDefault.Copy( )
			),
			"5%"
		);
		
		//Remove
		
		let Remove = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				"", //T.Translate( "Delete" ),
				LabelUIStateMapDefault.Copy( )
			),
			"40px"
		);
		
		super( [
			IsProduct,
			InOutinvoice,
			InSupplylist,
			ProductType,
			Code,
			Description,
			Unit,
			Quantity,
			QuantityBrutto,
			ExcVAT,
			VATRate,
			VAT,
			PaletteNumber,
			Remove
		] )
		
	}
	
};


export default GTSUIModelSaleFormAddItemsHeaderT;