import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import DocumentAdd from "./document_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import FileRenderer from "../../../aggrid/FileRenderer.jsx";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class Documents extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };

        super(props)

        var columnDefs=[
            {
                headerName: this.props.t('Czech name'),
                field: 'name_cs',
                editable: true,
                checkboxSelection:true,
                filter: 'agTextColumnFilter',
                sort: 'asc',
                comparator: customComparator
            },
            {
                headerName: this.props.t('English name'),
                field: 'name_en',
                editable: true,

                filter: 'agTextColumnFilter',

                comparator: customComparator
            }];


        var obj={};
        obj.headerName=this.props.t('Country');
        obj.field= 'country';
        obj.editable= true;

        obj.cellEditor='MultiSelectSimple';
        obj.cellEditorParams={
            gridType: 'documents'
        };

        var values1=JSON.parse(localStorage.getItem('countries'));

        obj.valueGetter=function(params) {
            if (params.data) {
                if (params.data['country'] == null || params.data['country'] == '') return '';
                var res = '';
                params.data['country'].split('//').map((e) => {
                    try {
                        res += values1.find(refData => String(refData.id) === String(e))['name_' + locale] + '//';
                    } catch (err) {
                        console.log(err);
                    }
                });

                return res.slice(0, -2);
            }
        }

        columnDefs.push(obj);
		
		
		//Jazyk
		
		var obj={};

		obj.headerName=this.props.t('Language');
        obj.field= 'lang';
        obj.editable= true;
		obj.filter = "agSetColumnFilter";

            
		obj.cellEditor = 'agRichSelectCellEditor';
		var values2 = JSON.parse(localStorage.getItem("languages"));


		obj.cellEditorParams = {values: values2.map(e => e['name_' + locale])}
		obj.valueSetter = function (params) {
				
			try {
				params.data['lang'] = values2.find(refData => refData['name_' + locale] == params.newValue).id;
				return true;
			} catch (err) {
				console.log(err);
				return true;
			}
			
		};
		obj.valueGetter = function (params) {

			if (params.data['lang'] === null || params.data['lang'] === '') return '';
			
			try {
				
				let founded = values2.find(refData => String(refData.id) === String(params.data['lang']))
				
				console.log( founded );
				
				return founded ? founded['name_' + locale] : null;
			} catch (err) {
				console.log(params);
				console.log(params.data);
				console.log(params.data['lang']);
				console.log(values2);
				console.log(err);
				return '';
			}
			
		};
		columnDefs.push(obj);

		//




        var obj={};
        obj.headerName=this.props.t('Company');
        obj.field= 'company';
        obj.editable= true;

        obj.cellEditor='MultiSelectSimple';
        obj.cellEditorParams={
            gridType: 'documents_companies',
            model: this.props.opts.model
        };

        var values_companies=JSON.parse(localStorage.getItem('companies'));

        obj.valueGetter=function(params) {
            if (params.data) {
                if (params.data['company'] == null || params.data['company'] == '') return '';
                var res = '';
                params.data['company'].split('//').map((e) => {
                    try {
                        res += values_companies.find(refData => String(refData.id) === String(e))['name'] + '//';
                    } catch (err) {
                        console.log(err);
                    }
                });

                return res.slice(0, -2);
            }
        }
        columnDefs.push(obj);


        columnDefs.push({
            headerName: this.props.t('Template'),
            field: 'file_path',
            cellRenderer: "FileRenderer",
            minWidth:400,
            suppressMenu: true,
            cellRendererParams: {
                type: 'document_template'
            }


        });

        columnDefs.push({
                headerName: this.props.t('Active'),
                field: 'active',
                cellRenderer: "CheckboxRenderer",
                suppressMenu: true,
            });

        columnDefs.push({headerName:this.props.t('Delete'),cellRenderer:"DeleteRenderer",cellRendererParams: {
                gridType: "documents"
            },
                suppressMenu: true,});



        this.state = {
            popupParent: document.querySelector('body'),
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: columnDefs,
            defaultColDef: {
                flex: 1,
                minWidth: 110,
                editable: false,
                resizable: true,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                MultiSelectSimple: MultiSelectSimple,
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                FileRenderer: FileRenderer
            }
        };
    }



    componentDidMount() {

        HttpService.get(UrlService.apiDomain()+'api/documents/'+this.props.opts.model)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#documentsGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/documents/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;

        var form_data = new FormData();

        for ( var key in data ) {
            if (key!=='file') form_data.append(key, data[key]);
        }

        if (typeof data.file!=='undefined') form_data.append('file', data.file);


        HttpService.post(UrlService.apiDomain()+'api/documents/'+this.props.opts.model, form_data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/documents/' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {

        var title=this.props.t('Document templates');
            if (this.props.opts.model=='outinvoices') title=this.props.t('Document templates');
            if (this.props.opts.model=='supplylists') title=this.props.t('Supply and loading lists templates');
            if (this.props.opts.model=='loadinglists') title=this.props.t('Loading lists templates');
        return (
            <div className="wrapper">
                <Layout>
                    <Content title={title}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRows()}
                            >
                                {this.props.t('Delete')} <i className="fa fa-trash"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <DocumentAdd tmp={Date.now()} onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="documentsGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                popupParent={this.state.popupParent}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Documents.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Documents);

