import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select, { components } from 'react-select';
import UrlService from "../../services/UrlService";


import UIElementT from "../../Infrastructure/UI/Element/UIElementT.mjs";

import HTTPClientT from "../../Infrastructure/Network/HTTP/Client/HTTPClientT.mjs";


import APIClientAbstractT from "../../Procedure/API/Client/_/APIClientAbstractT.mjs";


import APIClientT from "../../Interface/API/Client/APIClientT.mjs";

import UIComponentBooleanT from "../../Interface/UI/Component/Boolean/UIComponentBooleanT.mjs";

import UIComponentTextT from "../../Interface/UI/Component/Text/UIComponentTextT.mjs";


import HTTPClientConfigT from "../../Type/HTTP/Client/Config/HTTPClientConfigT.mjs";

import HTTPClientCookiesT from "../../Type/HTTP/Client/Cookie/HTTPClientCookiesT.mjs";

import APIFieldT from "../../Type/API/Field/APIFieldT.mjs";

import APIFieldListT from "../../Type/API/Field/APIFieldListT.mjs";

import APIValueT from "../../Type/API/Value/APIValueT.mjs";

import APIFieldValueT from "../../Type/API/FieldValue/APIFieldValueT.mjs";

import APIFieldValueMapT from "../../Type/API/FieldValue/APIFieldValueMapT.mjs";

import APIModelKeyT from "../../Type/API/Model/Key/APIModelKeyT.mjs";

import APIModelNameT from "../../Type/API/Model/Name/APIModelNameT.mjs";

import APIModelCallRequestElementT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementT.mjs";

import APIModelCallRequestElementMapT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementMapT.mjs";

import APIModelCallRequestT from "../../Type/API/Model/Call/Request/APIModelCallRequestT.mjs";

import APIModelCallResponseT from "../../Type/API/Model/Call/Response/APIModelCallResponseT.mjs";

import APIModelSchemaRequestJoinTypeT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinTypeT.mjs";

import APIModelSchemaRequestJoinDataT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinDataT.mjs";

import APIModelSchemaRequestModelOptionT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionT.mjs";

import APIModelSchemaRequestModelOptionMapT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionMapT.mjs";

import APIModelSchemaRequestCustomOptionT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionT.mjs";

import APIModelSchemaRequestCustomOptionMapT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionMapT.mjs";

import APIModelSchemaRequestChildrenMapT from "../../Type/API/Model/Schema/Request/Children/APIModelSchemaRequestChildrenMapT.mjs";

import APIModelSchemaRequestT from "../../Type/API/Model/Schema/Request/APIModelSchemaRequestT.mjs";

import APIModelSchemaResponseT from "../../Type/API/Model/Schema/Response/APIModelSchemaResponseT.mjs";


import APIModelInsertRequestJoinTypeT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinTypeT.mjs";

import APIModelInsertRequestJoinDataT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinDataT.mjs";

import APIModelInsertRequestChildrenListT from "../../Type/API/Model/Insert/Request/Children/APIModelInsertRequestChildrenListT.mjs";

import APIModelInsertRequestT from "../../Type/API/Model/Insert/Request/APIModelInsertRequestT.mjs";

import APIModelInsertResponseChildrenListT from "../../Type/API/Model/Insert/Response/Children/APIModelInsertResponseChildrenListT.mjs";

import APIModelInsertResponseT from "../../Type/API/Model/Insert/Response/APIModelInsertResponseT.mjs";


import UIComponentBooleanConfigT from "../../Type/UI/Component/Boolean/UIComponentBooleanConfigT.mjs";

import UIComponentTextConfigT from "../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIStyleT from "../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../Type/UI/Style/UIStyleMapT.mjs";

import KeyValueT from "../../Type/KeyValue/KeyValueT.mjs";

import KeyValueMapT from "../../Type/KeyValue/KeyValueMapT.mjs";


import GTSUIModelOutinvoiceFormAddT from "../../Interface/GTS/UI/Model/Outinvoice/Form/Add/GTSUIModelOutinvoiceFormAddT.mjs";

const API_URL = UrlService.API_URL( );

function createCSSSelector (selector, style) {
  if (!document.styleSheets) return;
  if (document.getElementsByTagName('head').length == 0) return;

  var styleSheet,mediaType;

  if (document.styleSheets.length > 0) {
    for (var i = 0, l = document.styleSheets.length; i < l; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
	  }
	  if (document.styleSheets[i].href && !document.styleSheets[i].href.startsWith(window.location.origin)){
		continue;
	  }
	  
      var media = document.styleSheets[i].media;
      mediaType = typeof media;

      if (mediaType === 'string') {
        if (media === '' || (media.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }
      else if (mediaType=='object') {
        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }

      if (typeof styleSheet !== 'undefined') 
        break;
    }
  }

  if (typeof styleSheet === 'undefined') {
    var styleSheetElement = document.createElement('style');
    styleSheetElement.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

    for (i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      styleSheet = document.styleSheets[i];
    }

    mediaType = typeof styleSheet.media;
  }

  if (mediaType === 'string') {
    for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
      if(styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase()==selector.toLowerCase()) {
        styleSheet.rules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.addRule(selector,style);
  }
  else if (mediaType === 'object') {
    var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
    for (var i = 0; i < styleSheetLength; i++) {
      if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
        styleSheet.cssRules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
  }
}


createCSSSelector( ".UIComponentBooleanT", "display: block; height: 38px; width: 38px; background-color: white; background-repeat: no-repeat; background-size: 38px 38px" );

createCSSSelector( ".UIComponentBooleanT.True", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/6/68/Blue_checkbox-checked.svg\" )" );

createCSSSelector( ".UIComponentBooleanT.False", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/8/8b/Blue_checkbox-unchecked.svg\" )" );

//createCSSSelector( ".UIComponentBooleanT:hover",  )





let ValueSale = null;

let IsValidSale = false;



const OutinvoiceAddT = class OutinvoiceAddT {

	#Ref = null;

	#GTSUIOutinvoiceFormAdd = null;
	
	#HTTPClientCookies = null;
	
	#APIClient = null;
	
	#APIModelCallRequestOutinvoicePrepare = null;
	
	#APIModelCallResponseOutinvoicePrepare = null;
	
	#APIModelSchemaRequestOutinvoice = null;
	
	#APIModelSchemaResponseOutinvoice = null;
	
	
	#TempOutinvoiceId = null;
	
	#TempSaleIds = [ ];
	
	#TempSupplylistIds = [ ];
	
	#TempSaleId = [ ];
	
	#OnOutinvoiceClose = null;
	
	
	constructor(
		HTTPClientCookies,
		APIClient,
		UIComponent
	){
		
		if( ( HTTPClientCookies instanceof HTTPClientCookiesT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		if( ( APIClient instanceof APIClientAbstractT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		
		this.#HTTPClientCookies = HTTPClientCookies;
		
		this.#APIClient = APIClient;
	

		this.#APIModelCallRequestOutinvoicePrepare = new APIModelCallRequestT( 
			new APIModelCallRequestElementMapT( 
				[
					new APIModelKeyT( "outinvoice_number" )
				],
				[
					new APIModelCallRequestElementT(
						new APIFieldT( "outinvoices" ),
						new APIFieldT( "outinvoices_prepare" ),
						new APIFieldListT( [ 
							new APIFieldT( "invoice_number" ) 
						] ),
						new APIFieldListT( [ 
						
						] )
					)
				] 
			) 
		);

		this.#APIModelSchemaRequestOutinvoice = new APIModelSchemaRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelSchemaRequestJoinTypeT( "NONE" ),
			new APIModelSchemaRequestJoinDataT( [ ] ),
			new APIModelSchemaRequestModelOptionMapT( 
				[
					//new APIModelKeyT( "users" ),
					new APIModelKeyT( "companies" ),
					new APIModelKeyT( "bank_accounts" ),
					new APIModelKeyT( "contracts" ),
					new APIModelKeyT( "outinvoices" ),
					new APIModelKeyT( "additional_texts" ),
					new APIModelKeyT( "product_types" )
				],
				[
					/*new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "users" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" )
						] )
					),*/
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "companies" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" ),
							new APIFieldT( "active" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "bank_accounts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "account_name" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "contracts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "contract_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "filled" ),
							new APIFieldT( "date" ),
							new APIFieldT( "address" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "invoice_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "pre_invoice" ),
							new APIFieldT( "post_invoice" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "additional_texts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "text" ),
							new APIFieldT( "company_ids" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "product_types" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestCustomOptionMapT( 
				[
					new APIModelKeyT( "outinvoice_type" ),
					new APIModelKeyT( "currency" ),
					new APIModelKeyT( "payment_method" )
				],
				[
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "outinvoice_type" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "currency" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "payment_method" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestChildrenMapT( 
				[
					new APIModelKeyT( "outinvoice_items" )
				], 
				[
					new APIModelSchemaRequestT(
						new APIModelNameT( "outinvoice_items" ),
						
						new APIModelSchemaRequestJoinTypeT( "ONE_TO_MANY" ),
						new APIModelSchemaRequestJoinDataT( [
							new APIFieldValueT(
								new APIFieldT( "ParentField" ),
								new APIValueT( "id" )
							),
							new APIFieldValueT(
								new APIFieldT( "ChildField" ),
								new APIValueT( "outinvoice_id" )
							)
						] ),
						
						new APIModelSchemaRequestModelOptionMapT( 
							[
								//new APIModelKeyT( "users" ),
								new APIModelKeyT( "outinvoices" ),
								new APIModelKeyT( "outinvoice_item_names" )
							],
							[
								/*new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "users" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" )
									] )
								),*/
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoices" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "invoice_number" ),
										new APIFieldT( "company" ),
										new APIFieldT( "pre_invoice" ),
										new APIFieldT( "post_invoice" )
									] )
								),
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoice_item_names" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" ),
										new APIFieldT( "company_ids" ),
										new APIFieldT( "dph" ),
										new APIFieldT( "code" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestCustomOptionMapT( 
							[
								new APIModelKeyT( "unit" ),
								new APIModelKeyT( "vat_rate" )
							],
							[
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "unit" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								),
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "vat_rate" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestChildrenMapT( 
						
							[
							
							], 
							
							[
							
							]
						
						)
					
					)
				]
			)
			
		);
	
	}
	
	#Draw(
	
	){
	
	
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		let CurrentMonth = ( new Date( ) ).toISOString( ).substring( 0, 7 );
		
		let GTSUIOutinvoiceNumberValue = JSONValue[ CurrentMonth ];
		
		let GTSUIOutinvoiceNumberValuePre = JSONValue[ "ZAL-" + CurrentMonth ];
	
	
		let APIModelSchemaResponse = this.#APIModelSchemaResponseOutinvoice;
		
								
		let CustomOptionMap = APIModelSchemaResponse.CustomOptionMap( );
		
		let ModelOptionMap = APIModelSchemaResponse.ModelOptionMap( );
		
		
		let CompanyModelOptionMap = ModelOptionMap.Get( "companies" );
		
		let CompanyModelFilterCb = function(
			APIFieldValueMap
		){
			
			let APIFieldValue = APIFieldValueMap.Get( "active" );
				
			let APIValue = APIFieldValue.Value( );
			
			let Value = APIValue.Value( );
			
			
			let APIFieldValue2 = APIFieldValueMap.Get( "name" );
				
			let APIValue2 = APIFieldValue2.Value( );
			
			let Value2 = APIValue2.Value( );
			
			console.log( "CompanyModelFilterCb", Value2, Value );
			
			if( Boolean( Value ) === false ){
				
				return false;
				
			}
			
			return true;
			
		}
		
		CompanyModelOptionMap = CompanyModelOptionMap.Filter( CompanyModelFilterCb );
		
		let CompanyUIOptionMap = CompanyModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let PaymentMethodCustomOptionMap = CustomOptionMap.Get( "payment_method" );
		
		let PaymentMethodUIOptionMap = PaymentMethodCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let PayToModelOptionMap = ModelOptionMap.Get( "bank_accounts" );
		
		let PayToUIOptionMap = PayToModelOptionMap.ToUIOptionMap( "id", "account_name" );
		
		
		let CurrencyCustomOptionMap = CustomOptionMap.Get( "currency" );
		
		let CurrencyUIOptionMap = CurrencyCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let ContractModelOptionMap = ModelOptionMap.Get( "contracts" );
		
		let ContractUIOptionMap  = ContractModelOptionMap.ToUIOptionMap( "id", "contract_number" );
		
		
		
		let OutinvoiceTypeCustomOptionMap = CustomOptionMap.Get( "outinvoice_type" );
		
		let OutinvoiceTypeUIOptionMap = OutinvoiceTypeCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceModelOptionMap = ModelOptionMap.Get( "outinvoices" );
		
		let OutinvoiceUIOptionMap  = OutinvoiceModelOptionMap.ToUIOptionMap( "id", "invoice_number" );
		
		
		let AdditionalTextModelOptionMap = ModelOptionMap.Get( "additional_texts" );
		
		let AdditionalTextUIOptionMap  = AdditionalTextModelOptionMap.ToUIOptionMap( "id", "text" ); 
		
		
		let ProductTypeModelOptionMap = ModelOptionMap.Get( "product_types" );
		
		let ProductTypeUIOptionSort = function( UIOption1, UIOption2 ) {
		
			console.log( UIOption1, UIOption2 );
		
			if ( UIOption1.ValueGet( ) < UIOption2.ValueGet( ) ) {

				return -1;

			} else if ( UIOption2.ValueGet( ) < UIOption1.ValueGet( ) ) {
    
				return 1;

			}

			return 0;
  
		};
		
		let ProductTypeUIOptionMap = ProductTypeModelOptionMap.ToUIOptionMap( "id", "name_cs" ).Sort( ProductTypeUIOptionSort ); 
		
		
		
		
		
		let CallElementResponseChildrenMap = APIModelSchemaResponse.ChildrenMap( );
		
		let OutinvoiceItem = CallElementResponseChildrenMap.Get( "outinvoice_items" );
		
		
		let OutinvoiceItemModelOptionMap = OutinvoiceItem.ModelOptionMap( );
		
		let OutinvoiceItemCustomOptionMap = OutinvoiceItem.CustomOptionMap( );
		
		
		let OutinvoiceItemNameModelOptionMap = OutinvoiceItemModelOptionMap.Get( "outinvoice_item_names" );
		
		let OutinvoiceItemNameUIOptionMap = OutinvoiceItemNameModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let OutinvoiceItemUnitModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "unit" );
		
		let OutinvoiceItemUnitUIOptionMap = OutinvoiceItemUnitModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceItemVATRateModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "vat_rate" );
		
		let OutinvoiceItemVATRateUIOptionMap = OutinvoiceItemVATRateModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		this.#GTSUIOutinvoiceFormAdd = new GTSUIModelOutinvoiceFormAddT( 
			"cs",
			
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			CompanyUIOptionMap,
			null,
			
			PaymentMethodUIOptionMap,
			null,
			
			PayToUIOptionMap,
			null,
			
			CurrencyUIOptionMap,
			null,

			ContractUIOptionMap, //UIOptionMapContract,
			null,//ContractDefault,
			
			"", //ReceiptNumberDefault
			
			null, //ReceiptDateDefault
			null, //IssueDateDefault
			null, //DueDateDefault
			null, //TaxableDateDefault
			null, //PaidDateDefault
			
			
			"", //ConstantSymbolDefault
			"", //SpecificSymbolDefault
			"", //NoteDefault
			
			OutinvoiceTypeUIOptionMap, //UIOptionMapOutinvoiceType,
			null, //OutinvoiceTypeDefault,
			
			OutinvoiceUIOptionMap,//UIOptionMapPreInvoice,
			null, //PreInvoiceDefault,
			
			OutinvoiceUIOptionMap, //UIOptionMapPostInvoice,
			null, //PostInvoiceDefault,
			
			AdditionalTextUIOptionMap, //UIOptionMapAdditionalText,
			"", //AdditionalTextDefault,
		
			0, //ExcVATDefault,
			0, //VATDefault,
			0, //IncVATDefault,
			
			false,
			
			ProductTypeUIOptionMap, //UIOptionMapProductType
			null, //ProductTypeDefault,
			
			OutinvoiceItemNameUIOptionMap,
			"",
			
			OutinvoiceItemUnitUIOptionMap,
			null,
			
			OutinvoiceItemVATRateUIOptionMap,
			"",
			
			ContractModelOptionMap,
			OutinvoiceModelOptionMap,
			JSONValue,
			AdditionalTextModelOptionMap
		);
		
		this.#GTSUIOutinvoiceFormAdd.Start( );
		
		this.#GTSUIOutinvoiceFormAdd.Listen( 
			this.#OnAccept.bind( this ),
			this.#OnClear.bind( this )
		);
		
		this.Reset(
			
		);
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.appendChild( this.Render( ) );
		
		}
	
	}
	
	Render( ){
	
		if( this.#GTSUIOutinvoiceFormAdd !== null ){
		
			return this.#GTSUIOutinvoiceFormAdd.Render( );
		
		}
	
		return null;
	
	}
	
	RefSet( Ref ){
	
		this.#Ref = Ref;
	
	}
	
	#OnModelSchemaOutinvoiceResponse(
		APIModelSchemaResponseOutinvoice
	){
	
		if( ( APIModelSchemaResponseOutinvoice instanceof APIModelSchemaResponseT ) === false ){
									
			throw new Error( "Invalid response format" );
									
		}
		
		this.#APIModelSchemaResponseOutinvoice = APIModelSchemaResponseOutinvoice;
		
		this.#Draw( );
	
	}
	
	#OnModelCallResponseOutinvoicePrepare( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;
		
		this.#APIClient.ModelSchema(

			this.#APIModelSchemaRequestOutinvoice,
			
			this.#OnModelSchemaOutinvoiceResponse.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	#OnModelError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	Start(
	
	){

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepare.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	Reset(
		
	){
	
		this.#GTSUIOutinvoiceFormAdd.ItemFormReset( 
			
		); 
	
	}

	#OnOutinvoiceInsertSuccess(
		APIModelInsertResponseOutinvoice
	){
	
		if( ( APIModelInsertResponseOutinvoice instanceof APIModelInsertResponseT ) === false ){
			
			throw new Error( "Invalid response format" );
			
		}
		
		let Data = APIModelInsertResponseOutinvoice.Data( );
		
		let FieldValue = Data.Get( "id" );
		
		let Value = FieldValue.Value( );
		
		this.#TempOutinvoiceId = Value.Value( ).toString( );
		
		console.log( APIModelInsertResponseOutinvoice, this.#TempOutinvoiceId );
		
		
		this.ResetPrepare( );
		
		if( this.#OnOutinvoiceClose !== null ){
		
			this.#OnOutinvoiceClose( 
				/*{
					company, qty, excvat, vat, incvat, sale_date, currency, receipt_number, receipt_date, contract, contract_value,
					invoice_issue, invoice_due, invoice_taxable, paid_date, beneficiary_note, note, payment_method, pay_to,
					constant_symbol, specific_symbol, invoice_items, invoice_type, sales_ids
				}*/
			);
		
		}
	
	}
	
	#OnError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	#OutinvoiceRequest(
		
	){
	
		let Values = this.#GTSUIOutinvoiceFormAdd.ValueGet( );
						
		let APIFieldBucketOutinvoiceItems = [ ];
		
		let OutinvoiceItemsOld = [ ];
		
		for(
			let I = 0;
			I < Values.items.length;
			I++
		){
		
			if( Values.items[ I ].in_outinvoice === false ){
			
				continue;
			
			}
		
			let OutinvoiceItem = {
				"description": Values.items[ I ].description,
				"product_type": ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].product_type ),
				"unit": Values.items[ I ].unit,
				"qty": Values.items[ I ].qty,
				"excvat": Values.items[ I ].excvat,
				"vat": Values.items[ I ].vat,
				"code": Values.items[ I ].code,
				"vat_rate": Values.items[ I ].vat_rate
			};
			
			console.log( OutinvoiceItem );
			
			OutinvoiceItemsOld.push( { 
				"description": Values.items[ I ].description,
				"product_type": ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].product_type ),
				"qty": Values.items[ I ].qty.toString( ),
				"unit": parseInt( Values.items[ I ].unit ),
				"unit_excvat": Values.items[ I ].excvat.toString( ),
				"unit_vat": Values.items[ I ].vat.toString( ),
				"unit_incvat": Values.items[ I ].excvat + Values.items[ I ].vat,
				"unit_value":{ "value": Values.items[ I ].unit.toString( ), "label": Values.items[ I ].unit_label }
			} );
			
			console.log( OutinvoiceItemsOld );
		
			APIFieldBucketOutinvoiceItems.push( 
			
				new APIModelInsertRequestT(
					new APIModelNameT( "outinvoice_items" ),
					new APIModelInsertRequestJoinTypeT( "ONE_TO_MANY" ),
					new APIModelInsertRequestJoinDataT( [
						new APIFieldValueT(
							new APIFieldT( "ParentField" ),
							new APIValueT( "id" )
						),
						new APIFieldValueT(
							new APIFieldT( "ChildField" ),
							new APIValueT( "outinvoice_id" )
						)
					] ),
					APIFieldValueMapT.FromJSON( OutinvoiceItem ),
					new APIModelInsertRequestChildrenListT( [ 

					] )
				)
			);
		
		}
		
		console.log( Values );
		
		let Outinvoice = {
			"company": Values.outinvoice.company,
			"currency": Values.outinvoice.currency,
			"contract": Values.outinvoice.contract,
			"paid_date": Values.outinvoice.paid_date,
			"invoice_number": Values.outinvoice.invoice_number,
			"invoice_excvat": Values.outinvoice.invoice_excvat,
			"invoice_vat": Values.outinvoice.invoice_vat,
			"invoice_incvat": Values.outinvoice.invoice_incvat,
			"invoice_rate": 0, // TODO Fixes in all other places where it will be needed
			"invoice_issue": Values.outinvoice.invoice_issue,
			"invoice_due": Values.outinvoice.invoice_due,
			"invoice_taxable": Values.outinvoice.invoice_taxable,
			"invoice_type": null,
			"payment_method": Values.outinvoice.payment_method,
			"pay_to": Values.outinvoice.pay_to,
			"note": Values.outinvoice.note,
			"invoice_file": null,
			"payment_type": null,
			"beneficiary_note": null,
			"variable_symbol": null,
			"constant_symbol": Values.outinvoice.constant_symbol,
			"specific_symbol": Values.outinvoice.specific_symbol,
			"invoice_items": JSON.stringify( OutinvoiceItemsOld ),
			"invoice_excvat_kc": null, //TODO Fix on server side where it will be needed
			"receipt_number": Values.outinvoice.receipt_number,
			"receipt_date": Values.outinvoice.receipt_date,
			"odpad": null,
			"additional_text": Values.outinvoice.additional_text,
			"transferred_vat": Values.outinvoice.transferred_vat,
			"cnb": Values.outinvoice.cnb,
			"pre_invoice": Values.outinvoice.pre_invoice,
			"post_invoice": Values.outinvoice.post_invoice,
			"outinvoice_type": Values.outinvoice.outinvoice_type,
			"sale_ids": this.#TempSaleIds,
			"supplylist_ids": this.#TempSupplylistIds
		};
		
		console.log( Outinvoice );
		
		let APIFieldBucketOutinvoice = APIFieldValueMapT.FromJSON( Outinvoice );
		
		let APIModelInsertRequestOutinvoice = new APIModelInsertRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelInsertRequestJoinTypeT( "NONE" ),
			new APIModelInsertRequestJoinDataT( [ ] ),
			APIFieldBucketOutinvoice,
			new APIModelInsertRequestChildrenListT( [ 
			
				...APIFieldBucketOutinvoiceItems
			
			] )
		);
		
		
		this.#APIClient.ModelInsert(

			APIModelInsertRequestOutinvoice,
			
			this.#OnOutinvoiceInsertSuccess.bind( this ),
			
			this.#OnError.bind( this )
			
		);
	
	}
	
	#OnAccept(
		Values,
		IsValid
	){
	
		console.log( "OutinvoiceAddT.#OnAccept", Values, IsValid );
		
		this.#OutinvoiceRequest( );
	
	}
	
	#OnClear( ){
	
	}
	
	OnOutinvoiceCloseSet(
		OnOutinvoiceClose
	){
	
		this.#OnOutinvoiceClose = OnOutinvoiceClose;
	
	}
	
	
	//Restart
	
	#OnModelCallResponseOutinvoicePrepareReset( 
		APIModelCallResponse 
	){
	
		console.log( "OnModelCallResponseOutinvoicePrepareReset", APIModelCallResponse );
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;

	
		
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		this.#GTSUIOutinvoiceFormAdd.OutinvoicePrepareJSONValueSet( JSONValue );
	
	}
	
	ResetPrepare(
	
	){
	
		//TODO Reset

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepareReset.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	Clear( ){
	
		if( this.#GTSUIOutinvoiceFormAdd === null ){
		
			return;
		
		}
	
		this.#GTSUIOutinvoiceFormAdd.Clear( );
	
	}
	
	LineAdd( ){
	
		if( this.#GTSUIOutinvoiceFormAdd === null ){
		
			return;
		
		}
	
		this.#GTSUIOutinvoiceFormAdd.LineAdd( );
		
	}
	
	SetProduct( Index, Type, Code ){
	
		if( this.#GTSUIOutinvoiceFormAdd === null ){
		
			return;
		
		}
	
		this.#GTSUIOutinvoiceFormAdd.SetProduct( Index, Type, Code );
	
	}
	
	SaleIdsSet( SaleIds ){
	
		this.#TempSaleIds = SaleIds;
	
	}
	
	SupplylistIdsSet( SupplylistIds ){
	
		this.#TempSupplylistIds = SupplylistIds;
	
	}
	
};


interface Props {
    onSell: ( ) => void;
};



class OutinvoiceAdd extends Component<Props> {

	#OutinvoiceAdd = null;

    constructor( 
		props 
	) {
	
        super( props );
		
		
		let HTTPClientCookies = HTTPClientCookiesT.Parse( document.cookie );

		let APIClient = new APIClientT(
			API_URL,
			HTTPClientCookies.Get( "access_token" ) ? HTTPClientCookies.Get( "access_token" ).Value( ) : null,
			new HTTPClientT(
				new HTTPClientConfigT(
				
				)
			)
		);
		
		this.#OutinvoiceAdd = new OutinvoiceAddT(
			HTTPClientCookies,
			APIClient
		);
		
		this.#OutinvoiceAdd.Start( );
		
		this.#OutinvoiceAdd.OnOutinvoiceCloseSet( this.props.onAdd );

    }

    componentDidMount(
	
	) {
	

    }
	
    componentDidUpdate(
		prevProps
	) {

        console.log( "sell_product.componentDidUpdate", this.props, prevProps );
		
		
		if( this.props.product_type !== prevProps.product_type ){
		
			this.#OutinvoiceAdd.ProductTypeSet( this.props.product_type );
		
		}
		
		if( this.props.selectedArray !== prevProps.selectedArray ){
		
			console.log( "New selected", this.props.selectedArray );
			
			this.#OutinvoiceAdd.Clear( );
			
			let SaleIds = [ ];
		
			let SupplylistIds = [ ];
		
			if( this.props.selectedArray.length > 0 ){
			
				if( this.props.from_sales === true ){
			
					console.log( this.props.selectedArray );
				
					let ProductTypes = JSON.parse( localStorage.getItem( "product_types" ) );
					
					console.log( ProductTypes );
					
					let Found = { };
					
					for(
						let I = 0;
						I < this.props.selectedArray.length;
						I++
					){
					
						for(
							let I2 = 0;
							I2 < ProductTypes.length;
							I2++
						){
						
							if( ProductTypes[ I2 ].id == this.props.selectedArray[ I ].data.product_type ) {
							
								SaleIds.push( this.props.selectedArray[ I ].data.id );
							
								Found[ ProductTypes[ I2 ].id ] = ProductTypes[ I2 ];
							
							}
						
						}
					
					
					}
					
					console.log( Found );
					
					let Keys = Object.keys( Found );
					
					for(
						let I2 = 0;
						I2 < Keys.length;
						I2++
					){
					
						let ProductType = Found[ Keys[ I2 ] ];
						
						this.#OutinvoiceAdd.LineAdd( );
					
						this.#OutinvoiceAdd.SetProduct( I2, ProductType.id, ProductType.code );
				
					}
					
				} else if( this.props.from_supplylists === true ){
				
					console.log( this.props.selectedArray );
				
					for(
						let I = 0;
						I < this.props.selectedArray.length;
						I++
					){
					
						SupplylistIds.push( this.props.selectedArray[ I ].data.id );
					
					}
				
				}
			
			}
			
			this.#OutinvoiceAdd.SaleIdsSet( SaleIds );

			this.#OutinvoiceAdd.SupplylistIdsSet( SupplylistIds );
		
			console.log( "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", this.props.selectedArray);
		
		}
		

    }
	

    render(
	
	) {

		return (
			<div style={ { height:"80vh", overflowY : "auto" } }>
				<div 
					ref={ 
						( Ref ) => {
							
							if( Ref !== null ){ 
							
								let Component = this.#OutinvoiceAdd.Render( );
							
								if( Component !== null ){
							
									Ref.appendChild( Component );
									
								} else {
								
									this.#OutinvoiceAdd.RefSet( Ref );
								
								}
								
							} else {
							
								this.#OutinvoiceAdd.RefSet( Ref );
							
							}
							
						} 
				
					} 
					style={ { display:"table", width:"100%" } }
				>
						
				</div>
			</div>
		);

    }
	
};


OutinvoiceAdd.propTypes = {

    t: PropTypes.func.isRequired
	
};


export default translate( )( OutinvoiceAdd );


/*

class SellProduct  extends Component<Props> {

    blankOption={value: "", label:this.props.t('Choose')};

    companiesData=JSON.parse(localStorage.getItem('companies'));
    productTypesData=JSON.parse(localStorage.getItem('product_types'));
    currenciesData=JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);
    unitsData=JSON.parse(localStorage.getItem('units'));
    bankAccountsData=JSON.parse(localStorage.getItem('bank_accounts'));
    paymentMethodsData=JSON.parse(JSON.parse(localStorage.getItem('columns_136')).data);
    contractsData=JSON.parse(localStorage.getItem('contracts'));

    constructor(props) {
        super(props)

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        var company='';
        if (this.props.company_id && this.props.company_id!='' && this.props.company_id!=0) company=this.props.company_id;
        var currency='';
        var company_value={value: "", label:this.props.t('Company')};
        var currency_value={value: "", label:this.props.t('Currency')};
        var invoice_items=[];

        var vat=0;
        var incvat=0;
        var excvat=0;
        var qty_total=0;

        if (this.props.rownodes && this.props.rownodes.length>0) {
            this.props.rownodes.map((e) => {
                company=e.company;
                currency=e.currency;

                var product_type=this.productTypesData.find(refData => refData.id == e.product_type)['name_'+locale];
                var unit_vat=e.unit_vat;
                var unit_incvat=e.unit_incvat;
                var unit_excvat=e.unit_excvat;
                var qty=e.qty;
                if (unit_vat=='' || unit_vat==null) unit_vat=0;
                if (unit_incvat=='' || unit_incvat==null) unit_incvat=0;
                if (unit_excvat=='' || unit_excvat==null) unit_excvat=0;
                if (qty=='' || qty==null) qty=0;

                vat=vat+parseFloat(unit_vat)*qty;
                qty_total=qty_total+parseFloat(qty);
                incvat=incvat+parseFloat(unit_incvat)*qty;
                excvat=excvat+parseFloat(unit_excvat)*qty;
                invoice_items.push({description:product_type, qty:e.qty, unit:1, unit_excvat:e.unit_excvat,unit_vat:e.unit_vat,unit_incvat:e.unit_incvat,unit_value:{value: "1", label:'kg'}})
            });
        }


        if (invoice_items.length==0) invoice_items=[{description:'', qty:'', unit:'', unit_excvat:'',unit_vat:'',unit_incvat:'',unit_value:{value: "", label:this.props.t('Unit')}}];

        for (var i = 0; i < this.companiesData.length; i++) {
            if (this.companiesData[i].id===company) {
                company_value={value: this.companiesData[i].id, label:this.companiesData[i].name};
                break;
            }
        }

        for (var i = 0; i < this.currenciesData.length; i++) {
            if (this.currenciesData[i].id===currency) {
                currency_value={value: this.currenciesData[i].id, label:this.currenciesData[i]['name_'+locale]};
                break;
            }
        }



        this.state = {
            invoice_items: invoice_items,
            company: company,
            currency: currency,
            qty: qty_total,
            allow_submit: false,
            currency_value: currency_value,
            company_value: company_value,
            excvat: excvat ? excvat : "",
            vat:vat,
            incvat:incvat,
            sale_date: '',
            create_invoice: false,
            invoice_issue: '',
            invoice_due: '',
            invoice_taxable: '',
            paid_date: '',
            payment_method: '',
            payment_method_value: {value: "", label:this.props.t('Payment method')},


            receipt_number: '',
            receipt_date: '',
            contract: '',
            contract_value: {value: "", label:this.props.t('Choose')},
            pay_to: '',
            pay_to_value: {value: "", label:this.props.t('Pay to account')},
            constant_symbol: '',
            specific_symbol: '',
            note: '',
            beneficiary_note: '',

        };


    }
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
	
		console.log( "outinvoice_add", "update", prevProps, this.props, ( prevProps.tmp !== this.props.tmp ) );

        if (prevProps.tmp !== this.props.tmp && this.props.row_data == null ) {


            var locale = 'cs';
            if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

            var company='';
            if (this.props.company_id && this.props.company_id!='' && this.props.company_id!=0) company=this.props.company_id;
            var currency='';
            var company_value={value: "", label:this.props.t('Company')};
            var currency_value={value: "", label:this.props.t('Currency')};

            var invoice_items=[];


            var vat=0;
            var incvat=0;
            var excvat=0;
            var qty_total=0;

            if (this.props.rownodes && this.props.rownodes.length>0) {
                this.props.rownodes.map((e) => {
                    company=e.company;
                    currency=e.currency;

                    var product_type=this.productTypesData.find(refData => refData.id == e.product_type)['name_'+locale];

                    var unit_vat=e.unit_vat;
                    var unit_incvat=e.unit_incvat;
                    var unit_excvat=e.unit_excvat;
                    var qty=e.qty;
                    if (unit_vat=='' || unit_vat==null) unit_vat=0;
                    if (unit_incvat=='' || unit_incvat==null) unit_incvat=0;
                    if (unit_excvat=='' || unit_excvat==null) unit_excvat=0;
                    if (qty=='' || qty==null) qty=0;

                    vat=vat+parseFloat(unit_vat)*qty;
                    qty_total=qty_total+parseFloat(qty);
                    incvat=incvat+parseFloat(unit_incvat)*qty;
                    excvat=excvat+parseFloat(unit_excvat)*qty;
                    invoice_items.push({description:product_type, qty:e.qty, unit:1, unit_excvat:e.unit_excvat,unit_vat:e.unit_vat,unit_incvat:e.unit_incvat,unit_value:{value: "1", label:'kg'}})
                });
            }


            if (invoice_items.length==0) invoice_items=[{description:'', qty:'', unit:'', unit_excvat:'',unit_vat:'',unit_incvat:'',unit_value:{value: "", label:this.props.t('Unit')}}];


            for (var i = 0; i < this.companiesData.length; i++) {
                if (this.companiesData[i].id===company) {
                    company_value={value: this.companiesData[i].id, label:this.companiesData[i].name};
                    break;
                }
            }

            for (var i = 0; i < this.currenciesData.length; i++) {
                if (this.currenciesData[i].id===currency) {
                    currency_value={value: this.currenciesData[i].id, label:this.currenciesData[i]['name_'+locale]};
                    break;
                }
            }

			console.log( "onopen" );

            this.setState({
                invoice_items: invoice_items,
                company: company,
                currency: currency,
                qty:qty_total,
                allow_submit:false,
                company_value: company_value,
                currency_value: currency_value,
                excvat:excvat ? excvat : "",
                vat:vat,
                incvat:incvat,
                sale_date:'',
                create_invoice:false,
                invoice_issue:'',
                invoice_due:'',
                invoice_taxable:'',
                paid_date:'',
                payment_method:'',
                payment_method_value: {value: "", label:this.props.t('Payment method')},

                receipt_number: '',
                receipt_date: '',
                contract: '',
                contract_value: {value: "", label:this.props.t('Choose')},

                pay_to: '',
                pay_to_value: {value: "", label:this.props.t('Pay to account')},
                constant_symbol:'',
                specific_symbol:'',
                note:'',
                beneficiary_note:'',
            })

        }
		
		if( this.props.row_data != null && this.props.row_data != prevProps.row_data ){
		
			console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
			
			let locale = 'cs';
            if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
			
			
			let company_value = null;
			
			for (var i = 0; i < this.companiesData.length; i++) {
				if (this.companiesData[ i ].id.toString() === this.props.row_data.data["company"].toString()) {
					company_value={value: this.companiesData[i].id, label:this.companiesData[i].name};
					break;
				}
			}
			
			
			let currency_value = null;
			
			for (var i = 0; i < this.currenciesData.length; i++) {
                if (this.currenciesData[i].id.toString() === this.props.row_data.data["currency"].toString() ) {
                    currency_value={value: this.currenciesData[i].id, label:this.currenciesData[i]['name_'+locale]};
                    break;
                }
            }
			
			
			let payment_method_value = null;
			
			for (var i = 0; i < this.paymentMethodsData.length; i++) {
                if (this.paymentMethodsData[i].id.toString() === this.props.row_data.data["payment_method"].toString() ) {
                    payment_method_value={value: this.paymentMethodsData[i].id, label:this.paymentMethodsData[i]['name_'+locale]};
                    break;
                }
            }
			
			
			let pay_to_value = null;
			
			
			for (var i = 0; i < this.bankAccountsData.length; i++) {
                if (this.bankAccountsData[i].id.toString() === this.props.row_data.data["pay_to"].toString() ) {
                    pay_to_value={value: this.bankAccountsData[i].id, label:this.bankAccountsData[i]['account_name']};
                    break;
                }
            }


			let contract_value = null;
			
			var companies_arr={ };

			this.companiesData.map((e)=>{
				companies_arr['c_' + e.id] = e.name;
			});
			
			for (var i = 0; i < this.contractsData.length; i++) {
                if (this.contractsData[i].company.toString() === this.props.row_data.data["company"].toString() ) {
                    contract_value={value: this.contractsData[i].id, label:companies_arr['c_' + this.contractsData[i].company] + ' ' + this.contractsData[i].contract_number};
                    break;
                }
            }
		
			//sale_date
			
			
			const fix_date = function( dd ){
			
				if(!dd){
				
					return null;
				
				}
				
				return dd.split( " " )[ 0 ].split( "-" ).reverse( ).join( "." );
			
			}
			
			this.setState({
				beneficiary_note: this.props.row_data.data["beneficiary_note"],
				company: this.props.row_data.data["company"],
				company_value: company_value,
				constant_symbol: this.props.row_data.data["constant_symbol"],
				contract: this.props.row_data.data["contract"],
				contract_value: contract_value,
				currency: this.props.row_data.data["currency"],
				currency_value: currency_value,
				invoice_due: fix_date( this.props.row_data.data["invoice_due"] ),
				excvat: this.props.row_data.data["invoice_excvat"],
				//file: this.props.row_data.data["invoice_file"],
				incvat: this.props.row_data.data["invoice_incvat"],
				invoice_issue: fix_date( this.props.row_data.data["invoice_issue"] ),
				invoice_items: JSON.parse( this.props.row_data.data["invoice_items"] ),
				invoice_taxable: fix_date( this.props.row_data.data["invoice_taxable"] ),
				//invoice_type: 
				vat: this.props.row_data.data["invoice_vat"],
				note: this.props.row_data.data["note"],
				paid_date: fix_date( this.props.row_data.data["paid_date"] ),
				pay_to: this.props.row_data.data["pay_to"],
				pay_to_value: pay_to_value,
				payment_method: this.props.row_data.data["payment_method"],
				payment_method_value: payment_method_value,
				//payment_type: this.props.row_data.data["payment_type"],
				//odpad
				receipt_date: fix_date( this.props.row_data.data["receipt_date"] ),
				receipt_number: this.props.row_data.data["receipt_number"],
				specific_symbol: this.props.row_data.data["specific_symbol"],
				variable_symbol: this.props.row_data.data["variable_symbol"]
			});
		
		}

    }
	
    handleFormSubmit(event) {
        event.preventDefault();
        var {company, qty, excvat, vat, incvat, sale_date, currency, receipt_number, receipt_date, contract, contract_value,

            invoice_issue, invoice_due, invoice_taxable, paid_date, beneficiary_note, note, payment_method, pay_to,
            constant_symbol, specific_symbol, invoice_items
        } = this.state;



        var invoice_type=3; // Other

        var sales_ids=[];
        if (this.props.rownodes && this.props.rownodes.length>0) {
            invoice_type=1;

            this.props.rownodes.map((e) => {
                sales_ids.push(e.id);
            });
        }


        invoice_items=JSON.stringify(invoice_items);
        this.props.onAdd({company, qty, excvat, vat, incvat, sale_date, currency, receipt_number, receipt_date, contract, contract_value,

            invoice_issue, invoice_due, invoice_taxable, paid_date, beneficiary_note, note, payment_method, pay_to,
            constant_symbol, specific_symbol, invoice_items, invoice_type, sales_ids
        });
    }

    allow_submit() {

        var allow_submit=true;

        var items = this.state.invoice_items;
        items.forEach((e)=>{
           if (e.unit_excvat=='' || e.unit_excvat==0 || e.description=='' || e.unit=='' || e.qty=='' || e.qty==0) allow_submit=false;
        });

        if (this.state.company=='' ||
            this.state.currency=='' ||

            this.state.excvat==0 ||
            this.state.incvat==0 ||
            this.state.invoice_issue=='' ||
            this.state.invoice_due=='' ||
            this.state.invoice_taxable=='' ||
            this.state.payment_method=='' ||
            (this.state.payment_method==2 && this.state.pay_to=='')

        ) allow_submit=false;




        this.setState({allow_submit:allow_submit});
		
    }



    handleChange(event, type, inputType) {
	
		console.log( "change", event, type, inputType );

        var stateValue={};


        if (inputType==='select') {
            stateValue[type]=event.value;
            stateValue[type+'_value']=event;
        }

        if (inputType==='text') {
            stateValue[type]=event.target.value;

            if (type=='payer_note') {
                stateValue[type]=event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 16).replace(/[^a-z0-9 ]/gi,'');
            }

            if (type=='beneficiary_note') {
                stateValue[type]=event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 32).replace(/[^a-z0-9 ]/gi,'');
            }

            if (type=='specific_symbol' || type=='variable_symbol') {
                stateValue[type]=event.target.value.replace(/[^0-9]/g, "").substring(0, 10);
            }
            if (type=='constant_symbol') {
                stateValue[type]=event.target.value.replace(/[^0-9]/g, "").substring(0, 4);
            }


        }


        if (inputType==='date') {

            if (typeof event==="object") {
                stateValue[type]=event.format("DD.MM.YYYY");
            } else {
                stateValue[type]=event;
            }
        }

        this.setState(stateValue, ()=>{this.allow_submit();});

        if (type==='excvat') {
            if (event.target.value=='') event.target.value=0;
            var sum=parseFloat(event.target.value)+parseFloat(this.state.vat);
            this.setState({incvat:sum}, ()=>{this.allow_submit();});
        }

        if (type==='vat') {
            if (event.target.value=='') event.target.value=0;
            var sum=parseFloat(event.target.value)+parseFloat(this.state.excvat);
            this.setState({incvat:sum}, ()=>{this.allow_submit();});
        }


    }

    handleItemOnChange(event, i, type) {
     //   alert("nnn");

        if (type==='unit') {
            var items = this.state.invoice_items;
            items[i][type]=event.value;
            items[i][type+'_value']=event;

            this.setState({invoice_items:items}, ()=> this.allow_submit());

        } else if (type==='qty' || type==='description'){
            let items = this.state.invoice_items;
			
			let r = /[0-9]+([\.][0-9]{0,8})?/;
			let av = event.target.value.match( r );
			let v = ( av !== null ) ? av[ 0 ] : null;

            if (type==='qty') items[i][type]=v;
            if (type==='description') items[i][type]=event.target.value;

            var vat=0;
            var incvat=0;
            var excvat=0;
            var qty=0;
            items.forEach((e)=>{

                var unit_vat=e.unit_vat;
                var unit_incvat=e.unit_incvat;
                var unit_excvat=e.unit_excvat;

                if (unit_vat=='') unit_vat=0;
                if (unit_incvat=='') unit_incvat=0;
                if (unit_excvat=='') unit_excvat=0;

                vat=vat+parseFloat(unit_vat)*e.qty;
                qty=qty+parseFloat(e.qty);
                incvat=incvat+parseFloat(unit_incvat)*e.qty;
                excvat=excvat+parseFloat(unit_excvat)*e.qty;

            });


           this.setState({invoice_items:items, qty:qty,   vat:vat, incvat:incvat, excvat:excvat}, ()=> this.allow_submit());

        } else if (type==='unit_vat' || type==='unit_excvat') {
            var items = this.state.invoice_items;
			
			let r = /[0-9]+([\.][0-9]{0,8})?/;
			let av = event.target.value.match( r );
			let v = ( av !== null ) ? av[ 0 ] : null;
			
            items[i][type]=v;

            var unit_vat=items[i]['unit_vat'];
            var unit_excvat=items[i]['unit_excvat'];
            if (unit_vat=='') unit_vat=0;
            if (unit_excvat=='') unit_excvat=0;

            items[i]['unit_incvat']=parseFloat(unit_vat)+parseFloat(unit_excvat);

            var vat=0;
            var incvat=0;
            var excvat=0;
            items.forEach((e)=>{
                var unit_vat=e.unit_vat;
                var unit_incvat=e.unit_incvat;
                var unit_excvat=e.unit_excvat;

                if (unit_vat=='') unit_vat=0;
                if (unit_incvat=='') unit_incvat=0;
                if (unit_excvat=='') unit_excvat=0;

                vat=vat+parseFloat(unit_vat)*e.qty;
                incvat=incvat+parseFloat(unit_incvat)*e.qty;
                excvat=excvat+parseFloat(unit_excvat)*e.qty;
            });


            this.setState({invoice_items:items,
            vat:vat, incvat:incvat, excvat:excvat
            }, ()=> this.allow_submit());


        }

    }

    render() {

        const Placeholder = props => {
            return <components.Placeholder {...props} />;
        };



        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        const {company, company_value, qty, excvat, vat, incvat, sale_date,
            invoice_issue, invoice_due, invoice_taxable, paid_date, beneficiary_note, note, payment_method, pay_to,
            constant_symbol, specific_symbol, invoice_items, receipt_number, receipt_date
        } = this.state;
        var companies = [this.blankOption];
        var currencies = [this.blankOption];
        const paymentMethods = [this.blankOption];
        const bankAccounts = [this.blankOption];

       // const units = [{value:'', label:this.props.t('Unit')}];
        const units = [];
        this.unitsData.map((e)=>{
            units.push({value: e.id, label:e['name_'+locale]});
        });



        var companies_arr={};


        this.companiesData.map((e)=>{
            companies_arr['c_'+e.id]=e.name;
            if (e.company_type==1 || e.company_type==null) return false;
            companies.push({value: e.id, label:e['name']});
        });

        this.currenciesData.map((e)=>{
            currencies.push({value: e.id, label:e['name_'+locale]});
        });


        this.bankAccountsData.map((e)=>{
            bankAccounts.push({value: e.id, label:e['account_name']});
        });

        this.paymentMethodsData.map((e)=>{
            paymentMethods.push({value: e.id, label:e['name_'+locale]});
        });

        var show_paid_date={display:'none'};
        if (payment_method==1) {
            show_paid_date={display:'block'};
        }



        var contracts = [this.blankOption];
        this.contractsData.map((e)=>{
            if (e.company==company || company=='') {
                contracts.push({value: e.id, label: companies_arr['c_' + e.company] + ' ' + e.contract_number});
            }
        });

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container" style={{height:'450px',overflow:'auto'}}>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">

                                <Select
                                    isDisabled={this.props.type=='sales' ? true : false}
                                    name="company"
                                    onChange={(event) => this.handleChange(event,'company', 'select')}
                                    value={this.state.company_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={companies} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">

                                <Select
                                    isDisabled={this.props.type=='sales' ? true : false}
                                    name="currency"
                                    onChange={(event) => this.handleChange(event,'currency', 'select')}
                                    value={this.state.currency_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={currencies} />
                            </div>
                        </div>




                    </div>



                    {
                        this.state.invoice_items.map((item, i) => (
                            <div className="row" key={i}>


                                <div className="col-3">
                                    <div className="form-group">
                                        <input key={'description_'+i}
                                               type="text"
                                              required
                                               disabled={(this.props.rownodes && this.props.rownodes.length>0) ? true : false}
                                               className="form-control"
                                               value={item.description}
                                               placeholder={this.props.t('Item description')}
                                               onChange={(event) => this.handleItemOnChange(event, i, 'description')}
                                        />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="form-group">
                                        <Select key={'unit_'+i}
                                                name="unit"
                                                isDisabled={(this.props.rownodes && this.props.rownodes.length>0) ? true : false}
                                                onChange={(event) => this.handleItemOnChange(event, i, 'unit')}
                                                value={item.unit_value}
                                                required
                                                components={{ Placeholder }}
                                                placeholder={'sdsdsd'}
                                                styles={{
                                                    placeholder: base => ({
                                                        ...base,
                                                        fontSize: '1em',
                                                        color: colourOptions[2].color,
                                                        fontWeight: 400,
                                                    }),
                                                }}
                                                classNamePrefix="select"
                                                options={units} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="form-group">

                                        <input key={'qty_'+i}
                                               type="text"
                                               disabled={false}
                                               pattern="[0-9]+([\.][0-9]{1,8})?"
                                               className="form-control"
                                               value={item.qty}
                                               placeholder={this.props.t('Quantity')}
                                               onChange={(event) => this.handleItemOnChange(event, i, 'qty')}
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="form-group">

                                        <input key={'unit_excvat_'+i}
                                               type="text"
                                               disabled={(this.props.rownodes && this.props.rownodes.length>0) ? true : false}
                                               pattern="[0-9]+([\.][0-9]{1,8})?"
                                               className="form-control"
                                               value={item.unit_excvat}
                                               placeholder={this.props.t('Exc. VAT')}
                                               onChange={(event) => this.handleItemOnChange(event, i, 'unit_excvat')}
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className="form-group">

                                        <input key={'unit_vat_'+i}
                                               type="text"
                                               disabled={(this.props.rownodes && this.props.rownodes.length>0) ? true : false}
                                               pattern="[0-9]+([\.][0-9]{1,8})?"
                                               className="form-control"
                                               value={item.unit_vat}
                                               placeholder={this.props.t('VAT')}
                                               onChange={(event) => this.handleItemOnChange(event, i, 'unit_vat')}
                                        />
                                    </div>
                                </div>

                            </div>
                        ))
                    }


                    <div className="row">

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Contract number')}</label>
                                <Select
                                    name="contract"
                                    onChange={(event) => this.handleChange(event,'contract', 'select')}
                                    value={this.state.contract_value}

                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={contracts} />
                            </div>
                        </div>


                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Receipt number')}</label>
                                <input
                                    type="text"


                                    className="form-control"
                                    value={receipt_number}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'receipt_number', 'text')
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Receipt date')}</label>
                                <Datetime
									key={'receipt_date_' + receipt_date}
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={receipt_date}

                                    onChange={(event) => this.handleChange(event, 'receipt_date', 'date')}
                                />
                            </div>
                        </div>






                    </div>

<div className="row" style={{display:(this.props.rownodes && this.props.rownodes.length>0) ? 'none' : 'block'}}>

    <div className="col-2">
        <div className="form-group">
            <div className="btn-group">
                <button className="btn btn-primary"
                        onClick={(e)=>{
                            e.preventDefault();
                            let ib=this.state.invoice_items;

                            ib.push({description:'', qty:'', unit:'', unit_excvat:'',unit_vat:'',unit_incvat:'',unit_value:{value: "", label:this.props.t('Unit')}});


                            this.setState({invoice_items:ib});
                            this.allow_submit();
                        }}
                ><i className="fa fa-plus"></i></button>
                <button className="btn btn-primary"
                        onClick={(e)=>{
                            e.preventDefault();
                            var invoice_items=this.state.invoice_items;
                            invoice_items.pop();
                            this.setState({invoice_items:invoice_items});
                            this.allow_submit();
                        }}
                        disabled={this.state.invoice_items.length===1 ? true : false}><i className="fa fa-minus"></i></button>
            </div>
        </div>
    </div>
</div>

                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Excluding VAT')}</label>
                                <input
                                    type="text"
                                    required
                                    disabled={false}
                                    pattern="[.0-9]*"
                                    className="form-control"
                                    value={excvat}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'excvat', 'text')
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('VAT')}</label>
                                <input
                                    type="text"
                                    disabled={false}
                                    pattern="[.0-9]*"
                                    className="form-control"
                                    value={vat}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'vat', 'text')
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Including VAT')}</label>
                                <input
                                    type="text"

                                    disabled={true}
                                    className="form-control"
                                    value={incvat}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'incvat', 'text')
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">

                        </div>

                    </div>


                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">

                                <Datetime
									key={'invoice_issue_' + invoice_issue}
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={invoice_issue}
                                    inputProps={{required:true, placeholder:this.props.t('Issue date')}}
                                    onChange={(event) => this.handleChange(event, 'invoice_issue', 'date')}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">

                                <Datetime
									key={'invoice_due_' + invoice_due}
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={invoice_due}
                                    inputProps={{required:true, placeholder:this.props.t('Due date')}}
                                    onChange={(event) => this.handleChange(event, 'invoice_due', 'date')}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">

                                <Datetime
									key={'invoice_taxable_' + invoice_taxable}
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={invoice_taxable}
                                    inputProps={{required:true, placeholder:this.props.t('Taxable date')}}
                                    onChange={(event) => this.handleChange(event, 'invoice_taxable', 'date')}
                                />
                            </div>
                        </div>

                        <div className="col-3 rightdate" style={show_paid_date}>
                            <div className="form-group">

                                <Datetime
									key={'show_paid_date_' + show_paid_date}
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={paid_date}
                                    inputProps={{placeholder:this.props.t('Paid date')}}
                                    onChange={(event) => this.handleChange(event, 'paid_date', 'date')}
                                />
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">

                                <Select
                                    name="payment_method"
                                    onChange={(event) => this.handleChange(event,'payment_method', 'select')}
                                    value={this.state.payment_method_value}
                                    required

                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={paymentMethods} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">

                                <Select
                                    name="pay_to"
                                    onChange={(event) => this.handleChange(event,'pay_to', 'select')}
                                    value={this.state.pay_to_value}
                                    required={this.state.payment_method==1 ? false : true}
                                    isDisabled={this.state.payment_method==1 ? true : false}
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={bankAccounts} />
                            </div>
                        </div>


                        <div className="col-3"  style={{display:'none'}}>
                            <div className="form-group">

                                <input
                                    type="text"


                                    className="form-control"
                                    value={constant_symbol}
                                    placeholder={this.props.t('Constant symbol')}
                                    onChange={(event) =>
                                        this.handleChange(event,'constant_symbol', 'text')
                                    }
                                />
                            </div>
                        </div>


                        <div className="col-3" style={{display:'none'}}>
                            <div className="form-group">

                                <input
                                    type="text"


                                    className="form-control"
                                    value={specific_symbol}
                                    placeholder={this.props.t('Specific symbol')}
                                    onChange={(event) =>
                                        this.handleChange(event,'specific_symbol', 'text')
                                    }
                                />
                            </div>
                        </div>

                    </div>


                    <div className="row"  style={{display:'none'}}>

                        <div className="col-6">
                            <div className="form-group">

                                <input
                                    type="text"
                                    className="form-control"
                                    value={beneficiary_note}
                                    placeholder={this.props.t('Beneficiary note')}
                                    onChange={(event) =>
                                        this.handleChange(event,'beneficiary_note', 'text')
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">

                                <input
                                    type="text"


                                    className="form-control"
                                    value={note}
                                    placeholder={this.props.t('Note')}
                                    onChange={(event) =>
                                        this.handleChange(event,'note', 'text')
                                    }
                                />
                            </div>
                        </div>

                    </div>




                </div>
                <button  className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
SellProduct.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SellProduct);*/

