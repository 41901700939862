"use strict";


import APIModelKeyT from "../Model/Key/APIModelKeyT.mjs";

import APIFieldValueMapT from "./APIFieldValueMapT.mjs";


const APIFieldValueBucketT = class APIFieldValueBucketT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIFieldValueMapList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIFieldValueMapList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIFieldValueMapList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIFieldValueMapList.length;
			I++
		){

			if( ( APIFieldValueMapList[ I ] instanceof APIFieldValueMapT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		
		if( APIModelKeyList.length !== APIFieldValueMapList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIFieldValueMapList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIFieldValueMap = APIFieldValueMapList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIFieldValueMap );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIFieldValueMap
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIFieldValueMap instanceof APIFieldValueMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIFieldValueMap
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIFieldValueMapList = [ ];
		
		for(
			let I in JSONData
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIFieldValueMapList.push(
				APIFieldValueMapT.FromJSON(
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIFieldValueBucketT(
			APIModelKeyList,
			APIFieldValueMapList
		);
		
	}
	
	ToJSON( ){
		
		let JSONData = { };
		
		for( 
			let I of this.#Map.keys( )
		){
			
			JSONData[ I ] = ( this.#Map.get( I ) ).ToJSON( );
			
		}
		
		return JSONData;
		
	}
	
};


export default APIFieldValueBucketT;