import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select';
interface Props {
    onAdd: (data) => void;
}

class AddHour  extends Component<Props> {

    blankOption={value: "", label:this.props.t('Choose')};



    constructor(props) {
        super(props)


        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });
        const my_user_id= JSON.parse(localStorage.getItem('my_user')).id;
        var worker_value= this.blankOption;
        var worker= '';
        const workTypes = [{value: "", label:this.props.t('Choose')}];
        if (roles.includes('Vyroba')) {
            worker=my_user_id;


            JSON.parse(localStorage.getItem('work_types')).map((e)=>{
                if (!e.users) return false;
                var users=e.users.split('//');

                if (users.includes(String(worker))) workTypes.push({value: e.id, label:e['name_'+locale]});

            });


        }

        for (var i = 0; i < JSON.parse(localStorage.getItem('users')).length; i++) {

            if (JSON.parse(localStorage.getItem('users'))[i].id==worker) {
                worker_value={value: worker, label:JSON.parse(localStorage.getItem('users'))[i]['name']};
                break;
            }
        }


        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');


        this.state = {
            worker: worker,
            work_date: this.today(),
            work_type:'',
            work_types:workTypes,
            hours:0,
            allow_submit:false,
            worker_value:worker_value,
            work_type_value: this.blankOption,
            note:'',

        };

    }


    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            var locale = 'cs';
            if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
            var roles=[];
            JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
                roles.push(r.name);
            });
            const my_user_id= JSON.parse(localStorage.getItem('my_user')).id;
            var worker_value= this.blankOption;
            var worker= '';

            const workTypes = [{value: "", label:this.props.t('Choose')}];
            if (roles.includes('Vyroba')) {
                worker=my_user_id;


                JSON.parse(localStorage.getItem('work_types')).map((e)=>{
                    if (!e.users) return false;
                    var users=e.users.split('//');

                    if (users.includes(String(worker))) workTypes.push({value: e.id, label:e['name_'+locale]});

                });


            }

            for (var i = 0; i < JSON.parse(localStorage.getItem('users')).length; i++) {

                if (JSON.parse(localStorage.getItem('users'))[i].id==worker) {
                    worker_value={value: worker, label:JSON.parse(localStorage.getItem('users'))[i]['name']};
                    break;
                }
            }
            this.setState({


                worker:worker,
                work_date: this.today(),
                work_type:'',
                work_types:workTypes,
                hours:0,
                allow_submit:false,
                worker_value: worker_value,
                work_type_value: this.blankOption,
                note:'',

            })

        }

    }

    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return dd+'.'+mm + '.' +  yyyy;
    }

    handleFormSubmit(event) {
        event.preventDefault();
        const {worker,work_date, work_type, work_type_value, hours, note
        } = this.state;

        this.props.onAdd({worker,work_date, work_type, hours, note
        });
    }

    allow_submit() {

        var allow_submit=true;


        if (this.state.worker=='' ||
            this.state.work_date=='' ||

            this.state.work_type=='' ||
            this.state.hours==0 ||
            this.state.hours==''

        ) allow_submit=false;


        this.setState({allow_submit:allow_submit});
    }



    handleChange(event, type, inputType) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var stateValue={};


        if (inputType==='select') {
            stateValue[type]=event.value;
            stateValue[type+'_value']=event;
        }

        if (inputType==='text') {
            stateValue[type]=event.target.value;


        }


        if (inputType==='date') {

            if (typeof event==="object") {
                stateValue[type]=event.format("DD.MM.YYYY");
            } else {
                stateValue[type]=event;
            }
        }

        if (type=='worker') {
            const workTypes = [{value: "", label:this.props.t('Choose')}];
            JSON.parse(localStorage.getItem('work_types')).map((e)=>{
                if (!e.users) return false;
                var users=e.users.split('//');

                if (users.includes(String(event.value))) workTypes.push({value: e.id, label:e['name_'+locale]});
                console.log(users);
                console.log(event.value);
            });
            stateValue['work_types']=workTypes;

        }

        this.setState(stateValue, ()=>{this.allow_submit();});


    }

    usersArray() {
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        const my_user=JSON.parse(localStorage.getItem('my_user'));
        var usersArray=[];
        if (permissions.includes('storage.edit')) {
            usersArray= [{value: "", label:this.props.t('Choose')}];
            JSON.parse(localStorage.getItem('users')).map((e)=>{
                if ((e.role==='Vyroba' && e.active) || (e.role==='Vedoucí výroby' && e.active)) usersArray.push({value: e.id, label:e.name});
            });
        } else {
            usersArray= [{value: my_user.id, label:my_user.name}];
        }

        return usersArray;
    }

    render() {
        console.log(this.state);
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        const {worker,worker_value, work_date, work_type, work_type_value, hours, note
        } = this.state;

const my_user_id= JSON.parse(localStorage.getItem('my_user')).id;


        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });

        var usersArray=this.usersArray();


        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Work date')}</label>
                                <Datetime

                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={work_date}

                                    onChange={(event) => this.handleChange(event, 'work_date', 'date')}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Worker')}</label>

                                <Select key={'worker'}
                                        name="worker"
                                        onChange={(event) => this.handleChange(event, 'worker', 'select')}
                                        value={worker_value}
                                        required
                                        isDisabled={roles.includes('Vyroba') ? true : false}
                                        placeholder={this.props.t('Select')}
                                        classNamePrefix="select"
                                        options={usersArray} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Work type')}</label>
                                <Select
                                    name="work_type"

                                    onChange={(event) => this.handleChange(event, 'work_type', 'select')}
                                    value={work_type_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={this.state.work_types} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Hours')}</label>
                                <input
                                    type="text"
                                    required
                                    pattern="[.0-9]*"
                                    className="form-control"
                                    value={hours}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'hours', 'text')
                                    }
                                />
                            </div>
                        </div>







                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Note')}</label>
                                <input
                                    type="text"
                                    required

                                    className="form-control"
                                    value={note}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.handleChange(event,'note', 'text')
                                    }
                                />
                            </div>
                        </div>
                    </div>





                </div>
                <button disabled={!this.state.allow_submit}  className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
AddHour.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(AddHour);

