"use strict";


import UIElementAbstractT from "../../../../Interface/UI/Element/UIElementAbstractT.mjs";

import UIComponentLabelT from "../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentPlaceholderConfigT from "../../../../Type/UI/Component/Placeholder/UIComponentPlaceholderConfigT.mjs";


const UIComponentPlaceholderT = class UIComponentPlaceholderT extends UIComponentLabelT {
	
	constructor(
		UIElement,
		UIComponentPlaceholderConfig
	){
		
		if( ( UIElement instanceof UIElementAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIComponentPlaceholderConfig instanceof UIComponentPlaceholderConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		super(
			UIElement,
			UIComponentPlaceholderConfig
		);
		
		
		this.ConfigSet( UIComponentPlaceholderConfig );
		
	}
	
	ConfigSet(
		UIComponentPlaceholderConfig
	){
		
		if( ( UIComponentPlaceholderConfig instanceof UIComponentPlaceholderConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		let UIStateMap = UIComponentPlaceholderConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		let UIStyleMap = UIState.StyleMapGet( );
		
		
		super.StyleMapSet( UIStyleMap );
		
		super.ConfigSet( UIComponentPlaceholderConfig );
		
	}
	
	ConfigGet(
	
	){
		
		return super.ConfigGet( );
		
	}
	
};


export default UIComponentPlaceholderT;