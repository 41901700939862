"use strict";


import StyleMapT from "../../Style/StyleMapT.mjs";

import ComponentAbstractConfigT from "../_/ComponentAbstractConfigT.mjs";


const ComponentBaseConfigT = class ComponentBaseConfigT {
	
	#ClassName = null;
	#StyleMap = null;
	#Children = null;
	#Name = null;
	#Id = null;
	
	constructor(
		ClassName = null,
		StyleMap = null,
		Children = null,
		Name = null,
		Id = null
	){
	
		this.ClassNameSet( ClassName );
		
		this.StyleMapSet( StyleMap );
		
		this.#Children = new Array( );
		
		this.ChildrenSet( Children );
		
		this.NameSet( Name );
		
		this.IdSet( Id );
		
	}
	
	ClassNameSet( 
		ClassName
	){
	
		if( ClassName !== null ){
		
			if( typeof( ClassName ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		this.#ClassName = ClassName;
		
	}
	
	ClassNameGet( 
	
	){
		
		return this.#ClassName;
		
	}
	
	StyleMapSet(
		StyleMap 
	){
	
		if( StyleMap === null ){
			
			StyleMap = new Map( );
			
		}
		
		
		if( ( StyleMap instanceof StyleMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		this.#StyleMap = new StyleMapT( [ ] );
		
		for(
			let I of StyleMap.Keys( )
		){
			
			this.#StyleMap.Set( I, StyleMap.Get( I ) );
			
		}
		
		
	}
	
	StyleMapGet( 
	
	){
		
		return this.#StyleMap;
		
	}
	
	StyleSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
			
		
		this.#StyleMap.Set( Key, Value );
		
	}
	
	StyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#StyleMap.Get( Key );
		
	}
	
	ChildrenSet(
		Children
	){
		
		if( Children !== null ){

			if( ( Children instanceof Array ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				let Child = Children[ I ];
				
				if( ( Child instanceof ComponentAbstractConfigT ) === false ){
				
					throw new Error( "Invalid argument type" );
				
				}
				
				this.#Children.push( Child.Copy( ) )
				
			}
			
		}
		
	}
	
	ChildrenGet( ){
		
		return this.#Children;
		
	}
	
	NameSet( 
		Name 
	){
		
		console.log( "name", Name );
		
		if( Name !== null ){
		
			if( typeof( Name ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		}
		
		
		this.#Name = Name;
		
	}
	
	NameGet(

	){
		
		return this.#Name;
		
	}
	
	IdSet( 
		Id 
	){
		
		if( Id !== null ){
		
			if( typeof( Id ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		this.#Id = Id;
		
	}
	
	IdGet( 
	
	){
		
		return this.#Id;
		
	}
				
};


export default ComponentBaseConfigT;