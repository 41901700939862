import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';


interface Props {
    onAdd: (data) => void;
}


class ExpenseTypeAdd extends Component<Props> {

	constructor( props ){
	
		super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ) {
		
			locale = localStorage.getItem( 'locale' );
			
		}
	
		this.state = {
			locale: locale,
			types: this.props.types,
			type: 0, 
			name_en: "", 
			name_cs: "", 
			positive: false 
		};
	
	}
	
    componentDidMount( ) {

    }
	
    HandleSubmit( Ev ) {
	
        Ev.preventDefault( );
		
        this.props.onAdd( { 
			type: this.state.type, 
			name_en: this.state.name_en,
			name_cs: this.state.name_cs,
			positive: this.state.positive
		} );
		
    }
	
    MakeItemType( Item ) {

        console.log( "MakeItemType", Item );
		
        return <option key={Item.id} value={Item.id}>{Item[ 'name_' + this.state.locale ]}</option>;
		
    }
	
	HandleChangeNameEN( Ev ){
	
		console.log( "HandleChangeName", Ev );
	
		this.setState({ 
			name_en: Ev.target.value 
		});
	
	}
	
	HandleChangeNameCS( Ev ){
	
		console.log( "HandleChangeName", Ev );
	
		this.setState({ 
			name_cs: Ev.target.value 
		});
	
	}
	
	HandleChangeType( Ev ){
	
		console.log( "HandleChangeType", Ev );
	
		this.setState({ 
			type: Ev.target.value 
		});
	
	}
	
	HandleChangePositive( Ev ){
	
		console.log( "HandleChangePositive", Ev );
	
		this.setState({ 
			positive: Ev.target.checked 
		});
	
	}
	
    render( ) {
		
        return (
            <form onSubmit={
				( Ev ) => this.HandleSubmit( Ev )
			}>
                <div className="container">
                    <div className="row">
					
						<div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={ this.state.name_en }
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={( Ev ) =>
                                        this.HandleChangeNameEN( Ev )
                                    }
                                />
                            </div>
                        </div>
						
						<div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Chech name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={ this.state.name_cs }
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={( Ev ) =>
                                        this.HandleChangeNameCS( Ev )
                                    }
                                />
                            </div>
                        </div>
						
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Type')}</label>
                                <select
                                    value={ this.state.type }
                                    required
                                    className="form-control"
                                    onChange={( Ev ) =>
                                        this.HandleChangeType( Ev )
                                    }
								>
									<option value="">{this.props.t('Choose')}</option>
									{( this.state.types ) ? this.state.types.map( this.MakeItemType.bind( this )) : "" }
								</select>
                            </div>
                        </div>
						
						<div className="col-3">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Positive')}</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={ this.state.positive }
                                    onChange={( Ev ) => 
										this.HandleChangePositive( Ev )
									}
                                />
                            </div>
                        </div>
						
                    </div>

                </div>
                <button className="btn btn-success">
					{ this.props.t('Save') }
				</button>
            </form>
        );
    }
}

ExpenseTypeAdd.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate( )(ExpenseTypeAdd);

