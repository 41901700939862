"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentLabelConfigT from "../../../Type/Component/Label/ComponentLabelConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT
} from "../ComponentInternalT.mjs";


const ComponentLabelT = class ComponentLabelT extends ComponentBaseT {

	#ComponentLabelConfig = null;

	constructor(
		GUIElement,
		ComponentLabelConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ComponentLabelConfig instanceof ComponentLabelConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		/*GUIElementConfig.StyleSet( 
			"display", 
			"inline-block"
		);
		
		GUIElementConfig.StyleSet(
			"textAlign",
			"center"
		);*/
		
	
		super( 
			GUIElement,
			ComponentLabelConfig
		);
		
		this.#ComponentLabelConfig = ComponentLabelConfig;
	
	}
	
	Update( ){
		
		this.InnerTextSet(
			this.#ComponentLabelConfig.TextGet( )
		);
		
	}
	
	Listen( ){
		
	}

};


export default ComponentLabelT;