import { Component } from "react";

import Datetime from 'react-datetime';

import "react-datetime/css/react-datetime.css";

import moment from 'moment';

import 'moment/locale/cs';


const InputDate = class InputDate extends Component { 
	
	#Input = null;
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	
	#Placeholder = null;
	#Value = null;
	#StringValue = null;
	
	#Locale = null;

	#RefCb = null;

	constructor( props ){
		
		super( props );
		
		this.#Input = null;
		
		this.#Field = props.field;
		this.#Disabled = props.disabled;
		this.#OnChangeCb = props.on_change_cb;
		this.#Placeholder = props.placeholder;
		
		this.#Value = null;
		
		this.#StringValue = "";
		
		this.#Locale = props.locale;
		
		this.#RefCb = props.ref_cb;
		
	}
	
	componentDidMount( ) {
		
		if( this.#RefCb ){
		
			this.#RefCb( this.#Input );
		
		}

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InputDate.componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if( this.props.value !== this.#Value ){
			
			this.#Value = this.props.value;
			
			if( this.props.value !== null ){
			
				this.#StringValue = moment( this.props.value, "YYYY-MM-DD" ).format('DD.MM.YYYY');
			
			} else {
				
				this.#StringValue = "";
				
			}
		
			this.#Input.value = this.#StringValue;
			
			this.#OnChangeCb( 
				this.#Field, 
				"date", 
				this.#Value,
				this.#Input
			);
			
		}
		
		if( this.props.disabled !== this.#Disabled ){
			
			this.#Disabled = this.props.disabled;
			
			
			this.#Input.disabled = this.#Disabled;
			
		}
		
	}
	
	componentWillUnmount( ){
		
	}
	
	#DatetimeOnChange( Ev ){
		
		console.log( "DatetimeOnChange", Ev );
		
		if( typeof( Ev ) === "string" ){
							
			this.#StringValue = Ev;
			
			this.#Input.value = this.#StringValue;
			
			this.#Value = null;
				
			this.#OnChangeCb(
				this.#Field,
				"date",
				this.#Value
			);
			
		} else {
			
			this.#StringValue = Ev.format( "DD.MM.YYYY" );
			
			this.#Value = Ev.format( "YYYY-MM-DD" );
			
			this.#Input.value = this.#StringValue;
		
			this.#OnChangeCb(
				this.#Field,
				"date",
				this.#Value
			);
			
		}
		
	}

	
	render( ){
		
		console.log( "InputDate", "render", this.#Value, this.#StringValue );
	
		return (
			<Datetime
				locale={ this.#Locale }
				dateFormat = "DD.MM.YYYY"
				timeFormat = { null }
				value={ this.#Value }
				onChange={ 
					( Ev ) => {
						
						this.#DatetimeOnChange( Ev );
						
					}
				}
				renderInput={ ( props ) => {
					
					return (
						<input 
							{...props} 
							value={ this.#StringValue }
							placeholder = { this.#Placeholder }
							ref = { ( Ref ) => { this.#Input = Ref; } }	
						/>
					);
					
				}}
			/>
								
		);
		
	};
	
};


export default InputDate;