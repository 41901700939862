"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentImageConfigT = class UIComponentImageConfigT extends UIComponentBaseConfigT {
	
	#Src = null;
	
	#Width = null;
	#Height = null;
	
	#Position = null;
	
	#Size = null;
	
	#Repeat = null;
	
	constructor(
		Src = "",
		
		Width = "100%",
		Height = "100%",
	
		Position = "center",
	
		Size = "contain",
		
		Repeat = "no-repeat",
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		this.SrcSet( Src );
		
		this.WidthSet( Width );
		
		this.HeightSet( Height );
		
		
		this.PositionSet( Position );
		
		this.SizeSet( Size );
		
		this.RepeatSet( Repeat );
		
	}
	
	SrcSet(
		Src
	){

		if( typeof( Src ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Src = Src;
		
	}
	
	SrcGet(
	
	){
		
		return this.#Src;
		
	}
	
	WidthSet(
		Width
	){

		if( typeof( Width ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Width = Width;
		
	}
	
	WidthGet(
	
	){
		
		return this.#Width;
		
	}
	
	HeightSet(
		Height
	){

		if( typeof( Height ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Height = Height;
		
	}
	
	HeightGet(
	
	){
		
		return this.#Height;
		
	}
	
	PositionSet(
		Position
	){

		if( typeof( Position ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Position = Position;
		
	}
	
	PositionGet(
	
	){
		
		return this.#Position;
		
	}
	
	SizeSet(
		Size
	){

		if( typeof( Size ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Size = Size;
		
	}
	
	SizeGet(
	
	){
		
		return this.#Size;
		
	}
	
	RepeatSet(
		Repeat
	){

		if( typeof( Repeat ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
		
		this.#Repeat = Repeat;
		
	}
	
	RepeatGet(
	
	){
		
		return this.#Repeat;
		
	}
	
	
	Copy(
	
	){
		
		return new UIComponentImageConfigT(

			this.SrcGet( ),
			
			this.WidthGet( ),
			this.HeightGet( ),
		
			this.PositionGet( ),
			this.SizeGet( ),
			this.RepeatGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentImageConfigT;