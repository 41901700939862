"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStateMapT from "../../State/UIStateMapT.mjs";


const UIComponentButtonConfigT = class UIComponentButtonConfigT extends UIComponentBaseConfigT {
	
	#Active = false;
	
	constructor(
		Active = true,
		UIStateMap = null
	){
		
		super( 
			UIStateMap
		);
		
		this.ActiveSet( Active );
		
	}
	
	ActiveGet(
	
	){
			
		return this.#Active;
			
	}
	
	ActiveSet(
		Active
	){
		
		if( typeof( Active ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
			
		this.#Active = Active;
			
	}
	
	Copy(
	
	){
		
		return new UIComponentButtonConfigT(
			
			this.ActiveGet( ),
		
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentButtonConfigT;