import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import ClearIcon from '@mui/icons-material/Clear';
import BackspaceIcon from '@mui/icons-material/Backspace';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";


export default forwardRef((props, ref) => {
    const getValueFromProps = () => {
        if(props.data && props.data.id) {
            return props.value;
        } else {
            let val = null;
            if(props.node.allLeafChildren.length > 0) {
                val = props.node.allLeafChildren[0].data[props.colDef.field];
            }
            return val;
        }
    }

    const [value, setValue] = useState(getValueFromProps());
    const [valueStr, setValueStr] = useState(null);
    const [lastValid, setLastValid] = useState(getValueFromProps());

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return lastValid;
            },
            isPopup: () => {
                return false;
            }
        };
    });

    console.log("Value", value);
    console.log("ValueStr", valueStr);
    console.log("LastValid", lastValid);

    useEffect(() => {
        dayjs.extend(customParseFormat);

        if (!valueStr) {
            if(!value){
                setLastValid(null);
            } else {
                setLastValid(dayjs(value).format('YYYY-MM-DD'));
            }
            return;
        }
        if (dayjs(valueStr, 'DD.MM.YYYY', true).isValid()) {
            setLastValid(dayjs(value).format('YYYY-MM-DD'));
        }
    }, [valueStr, value]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                clearable
                inputFormat="dd.MM.yyyy"
                value={value}
                onChange={(date, dateStr) => {
                    setValue(date);
                    setValueStr(dateStr);
                }}
                renderInput={(params) => <TextField {...params} size={"small"}/>}
                InputProps={{
                    endAdornment: (
                        <>
                            <IconButton
                                title={'Clear'}
                                onClick={() => {
                                setValue(null);
                                setValueStr(null);
                            }}>
                                <BackspaceIcon/>
                            </IconButton>
                            <IconButton
                                title={'Close'}
                                onClick={() => props.stopEditing()}>
                                <ClearIcon/>
                            </IconButton>
                        </>
                    )
                }}
                InputAdornmentProps={{
                    position: "start"
                }}
            />
        </LocalizationProvider>
    )
})



