"use strict";


import APIFieldValueMapT from "../../../../FieldValue/APIFieldValueMapT.mjs";

import APIFieldValueT from "../../../../FieldValue/APIFieldValueT.mjs";


const APIModelInsertRequestJoinDataT = class APIModelInsertRequestJoinDataT extends APIFieldValueMapT {
	
	constructor(
		APIFieldValueList
	){
		
		if( ( APIFieldValueList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIFieldValueList.length;
			I++
		){

			if( ( APIFieldValueList[ I ] instanceof APIFieldValueT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}

		
		super( 
			APIFieldValueList
		);
		
	}
	
};


export default APIModelInsertRequestJoinDataT;