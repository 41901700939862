import React, {Component} from "react";
import TabPill from "../common/tab/tabpill";
import DetailsTab from "./detailstab.tsx";
import CommunicationTab from "./communicationtab.tsx";
import AppraisalTab from "./appraisaltab.tsx";
import Loadings from "../loadings";
import Invoices from "../invoices";
import OutInvoices from "../outinvoices";
import ProductionHistory from "../manufacturingcard/production_history";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import DragAndDrop from "../common/draganddrop";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

class CompanyCard extends Component {
  getTabs() {

    var tabs = {
      details: {
        active: true,
        name: this.props.t('Details'),
        component: DetailsTab,

      },
      communication: {
        active: false,
        name: this.props.t('Communication'),
        component: CommunicationTab,

      },

    }

    var permissions = JSON.parse(localStorage.getItem('my_permissions'));

    if (permissions.includes('appraisals.view')) tabs.appraisal = {
      active: false,
      name: this.props.t('Appraisals'),
      component: AppraisalTab,

    }


    if (permissions.includes('loadings.view')) {

      tabs.loading = {

        active: false,
        name: this.props.t('Loadings'),
        component: Loadings,

      }
    }

    if (permissions.includes('invoices.view')) {

      tabs.invoice = {

        active: false,
        name: this.props.t('I. invoices'),
        component: Invoices,

      }
    }

    if (permissions.includes('outinvoices.view')) {

      tabs.outinvoice = {

        active: false,
        name: this.props.t('O. invoices'),
        component: OutInvoices,

      }
    }

    if (permissions.includes('storage.outputs')) tabs.production = {
      active: false,
      name: this.props.t('Production'),
      component: ProductionHistory,

    }

    return tabs;
  }

  state = {
    id: null,
    tmp: null,
    files: [],
    rownode: null,
    tabs: this.getTabs(),
  };


  getActiveTab() {
    const {tabs} = this.state;
    const keys = Object.keys(tabs);
    let newTabs = tabs;

    let activeTab = null;
    keys.forEach((key) => {
      if (newTabs[key].active) activeTab = newTabs[key].name
    });
    return activeTab
  }

  componentDidUpdate(prevProps) {

    if (prevProps.tmp !== this.props.tmp) {

      this.setState({id: this.props.id});
      this.setState({tmp: this.props.tmp});
      this.setState({rownode: this.props.rownode});


      if (this.getActiveTab() === null) {

        this.enableTab('details');
      }


    }
  }

  enableTab(tabName: string) {
    const {tabs} = this.state;

    if (tabs[tabName] === undefined) {
      return true;
    }

    const keys = Object.keys(tabs);
    let newTabs = tabs;

    keys.forEach((key) => {
      newTabs[key].active = false;
      newTabs[key].name = tabs[key].name;
    });

    newTabs[tabName].active = true;
    this.setState({tabs: newTabs});
  }


  shouldComponentUpdate(nextProps, nextState) {

    if (this.props.show === true && nextProps.show === false) {
      console.log('closing');
      const {tabs} = this.state;
      const keys = Object.keys(tabs);
      let newTabs = tabs;

      keys.forEach((key) => {
        newTabs[key].active = false;
        newTabs[key].name = tabs[key].name;
      });
    }

    if (
      (nextProps.show === true && this.props.show === false) ||
      (nextProps.show === true && this.props.show === true)
    ) {


      return true
    } else {
      return false
    }
  }

  renderTabContent() {
    const {tabs} = this.state;
    const keys = Object.keys(tabs);
    return keys.map((tabName, index) => {
      const Component = tabs[tabName].component;
      return (
        <div
          className={`tab-pane ${tabs[tabName].active ? "active" : "inactive"}`}
          id={tabName}
          key={index}
        >
          <Component company_id={this.state.id} company_name={this.props.company_name}
                     loading_address={this.props.loading_address} id={this.state.id} fromtab={true}
                     show={this.props.show} tmp={this.state.tmp} rownode={this.state.rownode}/>
        </div>
      );
    });
  }

  handleDrop = (files) => {


    if (files.length !== 1 || files[0].name.split('.')[files[0].name.split('.').length - 1] !== 'eml') {
      alert(this.props.t('Please upload just one EML file'));
    } else {

      const formData = new FormData();
      formData.append('file', files[0]);


      HttpService.post(UrlService.apiDomain() + 'api/communications/import_eml/' + this.props.id + '/', formData)
        .then(res => {
          if (res.data === null) {
            alert("File import error");
            return false;
          }
          if (res.data.result === 'failed') {
            alert(res.data.message);
            console.log(res);

          } else {
            if (res.data.message === 'success') {
              alert(this.props.t('Communication file imported successfully'));
              this.setState({tmp: Date.now()});
              console.log('Successfully updated');

            } else {
              alert("File import error");
            }


          }
        })
        .catch((error) => {
          alert(error);
          console.log(error);
        })


    }


    /*

            let fileList = this.state.files
            for (var i = 0; i < files.length; i++) {
                if (!files[i].name) return
                fileList.push(files[i].name)
            }
            this.setState({files: fileList})
            */
  }

  render() {


    const {tabs} = this.state;

    return (
      <DragAndDrop handleDrop={this.handleDrop}>

        <div className="card" style={{'width': '100%'}}>
          <div className="card-header p-2">
            <TabPill
              tabs={tabs}
              handleEnableTab={(tabName) => this.enableTab(tabName)}
            />
          </div>

          <div className="card-body">
            <div className="tab-content">{this.renderTabContent()}</div>
          </div>
        </div>
      </DragAndDrop>
    );
  }
}

CompanyCard.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(CompanyCard);
