

const Cell = function( Name, Input, ClassName, RefCb, Visible, IsRequiredRefCb ){
		
	return (
					
		<div className={ ClassName } ref={ ( Ref ) => { RefCb( Ref ); } } style={{ display: ( Visible === false ) ? "none" : "block" }}>
		
			<div className="form-group">
			
				<label htmlFor="description" style={{ display: ( ClassName === null ) ? "none" : "block", textAlign: "center" }}> 
					{ Name }
					<i ref={ ( ReqRef ) => { console.log("ReqRef"); IsRequiredRefCb( ReqRef ); } } style = {{ "color":"red", "display":"none" }}>*</i>
				</label>

				{ Input }

			</div>
		</div>			
				
	);
	
};

export default Cell;

