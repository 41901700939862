
import ColumnSingleBoolean from "./Single/ColumnSingleBoolean";
import ColumnSingleInteger from "./Single/ColumnSingleInteger";
import ColumnSingleDouble from "./Single/ColumnSingleDouble";
import ColumnSingleText from "./Single/ColumnSingleText";
import ColumnSingleSelectBase from "./Single/ColumnSingleSelectBase";
import ColumnSingleSelectTextBase from "./Single/ColumnSingleSelectTextBase";
import ColumnSingleMultiselectBase from "./Single/ColumnSingleMultiselectBase";
import ColumnSingleDatetime from "./Single/ColumnSingleDatetime";


const Column = class Column {
	
	static Create( 
		ColumnDef, 
		ModelName, 
		ModelSchema, 
		PinnedLeftFields, 
		PinnedRightFields, 
		CheckboxField, 
		Meta,
		ModalsRefs,
		ModalsRefsBD,
		ModalsRefsProps,
		RenderModals,
		CopyRowFn
	){
					
		let ColumnType = ColumnDef.column_type;
		
		let Field = ColumnDef.field;
		
		let Name = ColumnDef.name;
		
		let Editable = ColumnDef.editable;
	
		
		let Column = null;
		
		if( ColumnType === "boolean" ){
			
			Column = new ColumnSingleBoolean( 
				Name, 
				Field, 
				( Meta && Meta.Placeholder ) ? Meta.Placeholder : "",
				Editable,
				ModelSchema,
				Meta
			);
			
		} else if( ColumnType === "integer" ){
			
			Column = new ColumnSingleInteger( 
				Name, 
				Field,
				( Meta && Meta.Placeholder ) ? Meta.Placeholder : "",
				Editable,
				( Meta && Meta.RegExpMatch ) ? Meta.RegExpMatch : null,
				( Meta && Meta.RegExpValidation ) ? Meta.RegExpValidation : null,
				ModelSchema,
				Meta
			);
			
		} else if( ColumnType === "decimal" ){
			
			Column = new ColumnSingleDouble( 
				Name, 
				Field,
				( Meta && Meta.Placeholder ) ? Meta.Placeholder : "",
				Editable,
				( Meta && Meta.RegExpMatch ) ? Meta.RegExpMatch : null,
				( Meta && Meta.RegExpValidation ) ? Meta.RegExpValidation : null,
				ModelSchema,
				Meta
			);
			
		} else if( ColumnType === "text" ){
			
			let RendererParams = null;
			
			if( Meta && Meta.RendererParams ){
				
				if( Meta.RendererParams.Model === "outinvoices" ){
				
					RendererParams = {
						gridType: "outinvoices",
						showcard: function( Row ) { 
						
							console.log( "ShowCard", Row );
							
							ModalsRefsProps[ "DocGen" ].id = Row.id;
							
							RenderModals( "DocGen" );

						}.bind( this ),
						CopyFn: function( Row ){
							
							console.log( "CopyFn", Row );
							
							if( CopyRowFn !== null ){
								
								CopyRowFn( Row );
								
							}
							
						}.bind( this )
					};
					
				}
				
			}
			
			Column = new ColumnSingleText( 
				Name, 
				Field, 
				( Meta && Meta.Placeholder ) ? Meta.Placeholder : "",
				Editable,
				( Meta && Meta.RegExpMatch ) ? Meta.RegExpMatch : null,
				( Meta && Meta.RegExpValidation ) ? Meta.RegExpValidation : null,
				( Meta && Meta.Renderer ) ? Meta.Renderer : null,
				RendererParams,
				ModelSchema,
				Meta
			);
			
		} else if( ColumnType === "select" ){
			
			console.log( "select", ModelName, ColumnDef.model, ColumnDef.field );
			
			
			let Data = null;
			
			if( ColumnDef.model === "custom" ){
				
				Data = ModelSchema.custom_options_object.get( ModelName ).get( ColumnDef.field );
				
			} else {
				
				Data = ModelSchema.options_object.get( ColumnDef.model );
				
			}
			
			
			Column = new ColumnSingleSelectBase( 
				Name, 
				Field, 
				Data,
				Editable,
				ModelSchema,
				Meta
			);
			
			console.log( Column );
			
		} else if( ColumnType === "select_text" ){
			
			console.log( "select", ModelName, ColumnDef.model, ColumnDef.field );
			
			
			let Data = null;
			
			if( ColumnDef.model === "custom" ){
				
				Data = ModelSchema.custom_options_object.get( ModelName ).get( ColumnDef.field );
				
			} else {
				
				Data = ModelSchema.options_object.get( ColumnDef.model );
				
			}
			
			
			Column = new ColumnSingleSelectTextBase(
				Name, 
				Field, 
				Data,
				Editable,
				( Meta && Meta.RegExpMatch ) ? Meta.RegExpMatch : null,
				( Meta && Meta.RegExpValidation ) ? Meta.RegExpValidation : null,
				ModelSchema,
				Meta
			);
			
			console.log( Column );
			
		} else if( ColumnType === "multiselect" ){
			
			console.log( "multiselect", ModelName, ColumnDef.model, ColumnDef.field );
			
			
			let Data = null;
			
			if( ColumnDef.model === "custom" ){
				
				Data = ModelSchema.custom_options_object.get( ModelName ).get( ColumnDef.field );
				
			} else {
				
				Data = ModelSchema.options_object.get( ColumnDef.model );
				
			}
			
			Column = new ColumnSingleMultiselectBase( 
				Name, 
				Field,
				Data,
				"//", //TODO
				", ", //TODO
				Editable,
				ModelSchema,
				Meta
			);
			
		} else if( ColumnType === "date" ){
			
			Column = ColumnSingleDatetime( //TODO date
				Name, 
				Field, 
				Editable,
				Meta
			);
			
		} else if( ColumnType === "datetime" ){
			
			Column = ColumnSingleDatetime(  //TODO date
				Name, 
				Field, 
				Editable,
				Meta
			);
			
		} else {
			
			console.log( "Invalid column type", ColumnType );
			
			throw new Error( "Invalid column type" );
			
		}
		
		if( PinnedLeftFields.includes( Field ) === true ){
			
			Column.pinned = 'left';
			
		}
		
		if( PinnedRightFields.includes( Field ) === true ){
			
			Column.pinned = 'right';
			
		}
		
		if( Field === CheckboxField ){
			
			Column.checkboxSelection = true;
			
		}
		
		
		return Column;
		
	}
	
};


export default Column;