import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import Address from "../../components/common/address";
import Contact from "../../components/common/contact";
import MultiSelectSimple from "../../aggrid/MultiSelectSimple.jsx";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import DatePicker from "../../aggrid/DatePicker.js";
import SessionStorageService from "../../services/SessionStorageService";

class DetailsTab extends React.Component {

  constructor(props) {

    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
    var loctext;
    if (locale === 'cs') loctext = LocaleCs;
    if (locale === 'en') loctext = LocaleEn;

    var editable = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => String(refData['name']) === 'companies-editable').value.split('//');


    super(props)


    this.state = {
      style: {
        width: '100%',
        height: '100%'
      },
      modules: AllCommunityModules,
      showContact: false,
      showContactNode: null,
      showContactId: null,
      showAddress: false,
      showAddressName: 'Address',
      showAddressNode: null,
      showAddressId: null,
      localeText: loctext,
      rowdata: null,
      columnDefs: [
        {
          headerName: this.props.t('ID'),
          field: 'id',
          hide: true,
          editable: false,


        },

        {
          headerName: this.props.t('Field'),
          field: 'field',
          hide: true,
          editable: false,

        },
        {
          headerName: this.props.t('Field'),
          field: 'name_' + locale,
          cellRenderer: function (params) {


            if (params.data.company_type != 3) var mand_fields = ['currency', 'payment_method', 'approximate_material_qty', 'product_type', 'contact_person', 'contact_person_position', 'phone', 'email'];
            if (params.data.company_type == 3) var mand_fields = ['currency', 'payment_method', 'contact_person', 'contact_person_position', 'phone', 'email'];
            var color = '';
            if (params.data.field === 'active' && params.data.value === 0) color = " style='color:red' ";
            if (params.data.field === 'active' && params.data.value === 1) color = " style='color:green' ";

            var ret = "<strong " + color + ">" + params.data['name_' + locale] + "</strong>";
            if (mand_fields.includes(params.data.field)) ret += " <strong style='color:red'>*</strong>";
            return ret;
          },
          editable: false,

        },
        {
          headerName: this.props.t('Value'),
          field: 'value',
          editable: function (params) {
            //return true;
            var column = '';


            JSON.parse(localStorage.getItem('columns')).map((c) => {
              if (c.model === 'companies' && c.field === params.data.field) {
                column = c;
                return true;
              }
              return true;
            });
            var c = column;
            console.log(editable);
            console.log(c);
            if (c.locked === 1 && c.field !== 'name') return false;


            if (editable.includes(c.field)) {
              return true;
            } else {
              return false;
            }


          },

          valueSetter: function (params) {
            var column = '';
            JSON.parse(localStorage.getItem('columns')).map((c) => {
              if (c.model === 'companies' && c.field === params.data.field) {
                column = c;
                return true;
              }
              return true;
            });
            var c = column;

            if (c.column_type === 'boolean') {
              params.data.value = JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData['name_' + locale]) === String(params.newValue)).id;
              return true;
            } else if (c.column_type === 'select') {

              if (c.data_array === 'zipcodes' || c.data_array === 'cities' || c.data_array === 'states') {

              } else {

                var values = [];
                var tmp_values = [];
                if (c.data_array === 'custom') {
                  if (c.data !== null && c.data !== '') values = JSON.parse(c.data);
                } else if (c.data_array === '' || c.data_array === null || c.data_array === 'none') {
                  //
                } else {
                  values = JSON.parse(localStorage.getItem(c.data_array));
                }

                // checking if values are allowed
                var allowed_options = [];
                JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                  if (e.name === 'companies/columns-' + c.field) {
                    allowed_options = e.value.split('//');
                  }
                  return true;
                });

                values.map((e) => {
                  if (allowed_options.includes(e.id.toString()) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                    tmp_values.push(e);
                  }
                  return true;
                });
                // end of allowed values check

                values = tmp_values;

                try {
                  params.data.value = values.find(refData => String(refData['name_' + locale]) === String(params.newValue)).id;
                  return true;
                } catch (err) {
                  console.log(err);
                  return true;
                }


              }
            } else if (c.column_type === 'date') {

              params.data.value = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0]
              return true;

            } else {
              params.data.value = params.newValue
              return true;
            }
          },


          valueGetter: function (params) {
            var column = '';
            var values = [];
            JSON.parse(localStorage.getItem('columns')).map((c) => {
              if (c.field === params.data.field && c.model === 'companies') {
                column = c;
                return true;
              }
              return true;
            });
            var c = column;
            if (c.column_type === 'boolean') {

              try {
                return JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(params.data.value))['name_' + locale];
              } catch (err) {
                console.log(params.data.value)
                console.log(c.field)
                console.log(err);
                return '';
              }
            } else if (c.column_type === 'select') {

              if (c.data_array === 'zipcodes' || c.data_array === 'cities' || c.data_array === 'states') {
                return params.data.value
              } else {
                values = [];
                var tmp_values = [];
                if (c.data_array === 'custom') {
                  if (c.data !== null && c.data !== '') values = JSON.parse(c.data);
                } else if (c.data_array === '' || c.data_array === null || c.data_array === 'none') {
                  //
                } else {
                  values = JSON.parse(localStorage.getItem(c.data_array));
                }

                // checking if values are allowed
                var allowed_options = [];
                JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                  if (e.name === 'companies/columns-' + c.field) {
                    allowed_options = e.value.split('//');
                  }
                  return true;
                });

                values.map((e) => {
                  if (allowed_options.includes(e.id.toString()) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                    tmp_values.push(e);
                  }
                  return true;
                });
                values = tmp_values;
                // end of allowed values check


                if (params.data.value === null || params.data.value === '') return '';
                try {
                  return values.find(refData => String(refData.id) == String(params.data.value))['name_' + locale];
                } catch (err) {

                  console.log(params.data.value);
                  console.log(values);
                  console.log(c);
                  console.log(err);
                  return '';
                }


              }
            } else if (c.column_type === 'multiselect') {

              values = [];
              if (c.data_array === 'custom') {
                if (c.data !== null && c.data !== '') values = JSON.parse(c.data);
              } else if (c.data_array === '' || c.data_array === null || c.data_array === 'none') {
                //
              } else {
                values = JSON.parse(localStorage.getItem(c.data_array));
              }

              if (params.data.value === null || params.data.value === '') return '';
              var res = '';
              params.data.value.split('//').map((e) => {
                try {
                  res += values.find(refData => String(refData.id) === String(e))['name_' + locale] + '//';
                } catch (err) {
                  console.log(err);
                }
                return true;
              });

              return res.slice(0, -2);

            } else if (c.column_type === 'date') {

              if (params.data.value === null || params.data.value === '') return '';
              var d = new Date(params.data.value);
              var month = d.getMonth() + 1;
              return d.getDate() + '.' + month + '.' + d.getFullYear();

            } else if (c.field === 'iban') {
              return params.data?.value?.toString().replace(' ', '');
            } else {
              return params.data.value;
            }


          },

          cellEditorSelector: function (params) {
            var values = [];
            var column = '';
            JSON.parse(localStorage.getItem('columns')).map((c) => {
              if (c.model === 'companies' && c.field === params.data.field) {
                column = c;
                return true;
              }
              return true;
            });
            var c = column;
            if (c !== '') {
              if (c.column_type === 'boolean') {
                return {
                  component: 'agRichSelectCellEditor',
                  params: {values: JSON.parse(localStorage.getItem('booleans')).map(e => e['name_' + locale])}
                };
              }

              if (c.column_type === 'select') {

                if (c.data_array === 'zipcodes' || c.data_array === 'cities' || c.data_array === 'states') {

                } else {

                  var component = 'agRichSelectCellEditor';
                  values = [];
                  var tmp_values = [];
                  if (c.data_array === 'custom') {
                    if (c.data !== null && c.data !== '') values = JSON.parse(c.data);
                  } else if (c.data_array === '' || c.data_array === null || c.data_array === 'none') {
                    //
                  } else {
                    values = JSON.parse(localStorage.getItem(c.data_array));
                  }

                  // checking if values are allowed
                  var allowed_options = [];
                  JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                    if (e.name === 'companies/columns-' + c.field) {
                      allowed_options = e.value.split('//');
                    }
                    return true;
                  });

                  values.map((e) => {
                    if (allowed_options.includes(e.id.toString()) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                      tmp_values.push(e);
                    }
                    return true;
                  });
                  // end of allowed values check

                  values = tmp_values;

                  var pars = {values: values.map(e => e['name_' + locale])};
                  return {
                    component: component,
                    params: pars
                  };
                }
              }

              if (c.column_type === 'multiselect') {

                return {
                  component: 'MultiSelectSimple',
                  params: {
                    gridType: "companies",
                    field: params.data.field
                  }
                };

              }

              if (c.column_type === 'date') {
                return {
                  component: 'DatePicker',

                };
              }

            }


            return {
              component: 'agTextCellEditor',
            };
          },
          suppressMenu: true,
        },


      ],
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        editable: false,
        resizable: true,

        sortable: false,
        suppressMovableColumns: true,

        suppressMenu: true,
      },
      frameworkComponents: {

        MultiSelectSimple: MultiSelectSimple,
        DatePicker: DatePicker,
      },
    };
  }


  shouldComponentUpdate(nextProps, nextState) {

    if (this.props.show === true && nextProps.show === false) {

      this.setState({
        rowdata: []
      })
    }

    if (
      (nextProps.show === true && this.props.show === false) ||
      (nextProps.show === true && this.props.show === true)
    ) {

      return true
    } else {
      return false
    }
  }


  componentDidUpdate(prevProps) {

    if (prevProps.tmp !== this.props.tmp) {


      if (this.props.id !== null) {
        this.setState({
          rowdata: []
        });

        HttpService.get(UrlService.apiDomain() + 'api/company/company_card/' + this.props.id)
          .then(res => {
            this.setState({
              rowdata: res.data
            });

          })
          .catch((error) => {
            console.log(error);
          });


      }


    }
  }


  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.setDomLayout('autoHeight');
    //document.querySelector('#DetailsGrid').style.height = '';
  };


  onCellCValueChanged = (event) => {

    HttpService.put(UrlService.apiDomain() + 'api/company/company_card/' + this.props.id, event.data)
      .then(res => {
        if (res.data == null) alert("Chyba připojení k serveru");
        if (res.data.alert !== '' && typeof (res.data.alert) !== 'undefined') alert(res.data.alert);
        if (res.data.result == 'failed') {
          alert(res.data.message);

        } else {
          console.log('Successfully updated');
          this.props.rownode.setData(res.data.entry);

          function update_card(res, node, index) {
            var data = node.data;
            data.value = res.data.entry[node.data.field];
            node.setData(data);
          }

          this.gridApi.forEachNode(update_card.bind(null, res));
          if (res.data.made_active == 1) SessionStorageService.add('companies', res.data.entry);
        }
      })
      .catch((error) => {
        console.log(error);
      })


  }

  getRowNodeId = (data) => {
    return data.field;
  };

  onCellClicked = (e) => {
    var that = this;
    var field = e.data.field;

    if (field === "contact_person" || field === "contact_person_position" || field === "contact_person_note" || field === 'phone' || field === 'email') {
      that.setState({
        showContact: true,
        showContactId: e.node.data.id,
        showContactNode: this.props.rownode,
        contact_person_row: this.gridApi.getRowNode('contact_person'),
        contact_person_position_row: this.gridApi.getRowNode('contact_person_position'),
        phone_row: this.gridApi.getRowNode('phone'),
        email_row: this.gridApi.getRowNode('email'),
        contact_person_note_row: this.gridApi.getRowNode('contact_person_note'),

      });

    }


    if (field === "address" || field === "state" || field === "city" || field === 'zip' || field === 'street' || field === 'house' || field === 'lattitude' || field === 'longitude') {
      that.setState({
        showAddress: true,
        showAddressName: e.node.data.name,
        showAddressId: e.node.data.id,
        showAddressType: 'address',
        showAddressNode: this.props.rownode,


      });

    }


  }


  async handleAddressChange(data) {

    let new_data = {};
    for (const [key, value] of Object.entries(data)) {
      if (key !== 'rownode') new_data[key] = value;
    }
    HttpService.put(UrlService.apiDomain() + 'api/' + data.address_model_name + '/' + data.id, new_data)
      .then(res => {
        if (res.data == null) alert("Chyba připojení k serveru");
        if (res.data.alert !== '') alert(res.data.alert);
        if (res.data.result == 'failed') {
          alert(res.data.message);

        } else {
          console.log('Successfully updated');

          this.props.rownode.setData(res.data.entry);
          var data;
          var rownode;

          rownode = this.gridApi.getRowNode('address');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.address;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('state');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.state;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('city');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.city;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('zip');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.zip;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('street');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.street;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('house');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.house;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('lattitude');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.lattitude;
            rownode.setData(data);
          }

          rownode = this.gridApi.getRowNode('longitude');
          if (rownode) {
            data = rownode.data;
            data.value = res.data.entry.longitude;
            rownode.setData(data);
          }


          this.setState({showAddress: false});
        }
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });


  }


  render() {


    return (


      <div style={{'height': '400px', width: '100%'}} id="DetailsGrid" className="ag-theme-alpine">
        <Modal
          title={this.props.t('Contacts')}

          visibility={this.state.showContact}
          size="lg"
          onClose={() => this.setState({showContact: !this.state.showContact})}
        >
          <Contact
            showContact={this.state.showContact}
            contact_person_row={this.state.contact_person_row}
            contact_person_position_row={this.state.contact_person_position_row}
            phone_row={this.state.phone_row}
            email_row={this.state.email_row}
            contact_person_note_row={this.state.contact_person_note_row}
            rownode={this.state.showContactNode} tmp={Date.now()} id={this.state.showContactId} model={"company"}/>
        </Modal>
        <Modal
          title={this.state.showAddressName}

          visibility={this.state.showAddress}
          size="lg"
          onClose={() => this.setState({showAddress: !this.state.showAddress})}
        >
          <Address onAdd={(data) => this.handleAddressChange(data)} show={this.state.showAddress}
                   rownode={this.state.showAddressNode} tmp={Date.now()} id={this.state.showAddressId}
                   type={this.state.showAddressType} address_model_name={"companies"}/>
        </Modal>
        <AgGridReact
          modules={this.state.modules}
          rowData={this.state.rowdata}
          columnDefs={this.state.columnDefs}
          defaultColDef={this.state.defaultColDef}
          onGridReady={this.onGridReady}
          onCellValueChanged={this.onCellCValueChanged}
          onCellClicked={this.onCellClicked}
          frameworkComponents={this.state.frameworkComponents}
          suppressContextMenu={true}
          localeText={this.state.localeText}
          headerHeight={0}
          getRowNodeId={this.getRowNodeId}
        />
      </div>


    );
  }
}

DetailsTab.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(DetailsTab);

