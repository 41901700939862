import React, { Component } from "react";
import { translate } from 'react-polyglot';
import Datetime from 'react-datetime';

import PropTypes from 'prop-types';

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import AppraisalsImageRenderer from "../../aggrid/AppraisalsImageRenderer";

import $ from 'jquery';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import TabPill from "../common/tab/tabpill";

import LoadingsFinalCalculationPlain from "./loadings_final_calculation_plain.tsx";
import LoadingsFinalCalculationTable from "./loadings_final_calculation_table.tsx";

interface Props {
    onAdd: (data) => void;
}

class LoadingsFinalCalculation extends Component<Props> {
	
	constructor( props ) {
	
		//for permission loadings.final_calculation must have permission for view loadings, expense_types, expense_types_types, expenses
	
		console.log( "Constructor props", props );
	
        super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ){
			
			locale = localStorage.getItem( 'locale' );
			
		}
		
		let loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
		
		
		let datetime = new Date( );
		
		let tabs = {
            PlainTable: {
                active: true,
                name: this.props.t('Plain'),
                component: LoadingsFinalCalculationPlain
            },
			AgGridTable: {
                active: false,
                name: this.props.t('Table'),
                component: LoadingsFinalCalculationTable
            }
        };

        this.state = {
			locale: locale,
			datetime: this.FormatDatetime( datetime ),
			datetime_server: this.FormatDatetimeServer( datetime ), 
			provision: 0, 
			manager: this.props.manager,
			top_manager: this.props.top_manager,
			loadingsArray: this.props.loadingsArray,
			loadingsObject: {},
			loadingsObjectByLoadingNumber: {},
			loadingsSelected: this.props.loadingsSelected,
			expense_types_types: JSON.parse( localStorage.expense_types_types ),
			expense_types: JSON.parse( localStorage.expense_types ),
			expenses: [ ],
			appraisals: [ ],
			results: [ ],
			isopen: false,
			disabled: false, 
			spinner: 'none',
			rates: { },
			
			tabs: tabs,
			
			modules: AllCommunityModules,
            localeText: loctext
		};
		
	}
	
	FormatDatetime( date ){
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	FormatDatetimeServer( date ){
	
		return this.FormatDateServer( date ) + ' ' + this.FormatTimeServer( date );
	
	}
	
	FormatDateServer( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FormatTimeServer( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	componentDidMount( ){
	
		this.GetExpenses( );
		
		this.GetRates( );
	
	}
	
	componentDidUpdate( prevProps, prevStates ){
		
		console.log( "component did update", this.props, prevProps, this.state );
		
		/*if( this.props.isopen == false && prevProps.isopen == true ){
		
			console.log( "component closed" );
		
			this.setState( {
				disabled: false, spinner: 'none'
			} );
		
		}*/
		
		if( ( prevProps.loadingsArrayStamp != this.props.loadingsArrayStamp ) || ( JSON.stringify( prevProps.loadingsArray ) != JSON.stringify( this.props.loadingsArray ) ) ){ 
		
			const loadingsArray = [ ];
			const loadingsObject = { };
			const loadingsObjectByLoadingNumber = { };

			this.props.loadingsArray.map( function( loading ){
			
				loadingsArray.push( loading );
				
				loadingsObject[ loading.id ] = loading; 
				
				loadingsObjectByLoadingNumber[ loading.loading_number ] = loading; 
				
			} );
			
			console.log( "updateloadingsObject", loadingsObject );
			
			this.setState( {
				loadingsArray: loadingsArray,
				loadingsObject: loadingsObject,
				loadingsObjectByLoadingNumber: loadingsObjectByLoadingNumber
			}, ( ) => { 
			
				if( ( this.props.isAdmin == false ) && ( this.props.manager == null ) ) { 
					this.HandleChangeManager( { target: { value: JSON.parse( localStorage.my_user ).id } } ); 
				} 
			} );
		
		}
		
		if( 
			//( this.props.top_manager != null ) && 
			( prevProps.top_manager != this.props.top_manager ) 
		){
		
			this.HandleChangeTopManager( { target: { value: this.props.top_manager } } ); 
		
		}
		
		if( 
			//( this.props.manager != null ) && 
			( prevProps.manager != this.props.manager ) 
		){
		
			this.HandleChangeManager( { target: { value: this.props.manager } } ); 
		
		}
		
		if( 
			( JSON.stringify( prevProps.loadingsSelected ) != JSON.stringify( this.props.loadingsSelected ) ) &&
			( this.props.loadingsSelected != null )
		) {
		
			console.log( this.props.loadingsSelected );
		
			this.HandleChangeLoadings( this.props.loadingsSelected ); 
		
		}
		
		if( ( this.props.isopen != prevProps.isopen ) ){
		
			/*if( ( this.props.isopen == false ) ){
		
				this.setState( { isopen: this.props.isopen, manager: null, top_manager: null } );
		
			} else {*/
			
				this.setState( { isopen: this.props.isopen } );
				
			//}
			
		}
		
		if( ( this.props.isopen != prevProps.isopen ) && ( this.props.isopen == true ) ){
		
			setTimeout( function( ){
				
				this.GetResults( );
			
			}.bind( this ), 10 );
		
		}
		
		
	}

	GetRates( ){
	
		let url = UrlService.apiDomain() + 'api/exchange_rate/now';
		
		let Data = {
			datetime: this.state.datetime
		};
			
		HttpService.post( 
			url, Data
		).then( function( res ) {
		
			if( res.data === null ){
					
				alert( "Chyba připojení k serveru" );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
			
			}
			
			if( 
				( res.data.result !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
				
			}
			
			if( 
				( res.data.message !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
				
			}
			
			console.log( res.data );
			
			this.setState( {
				rates: res.data.rates
			} );
			
			

		}.bind( this ) ).catch( function( error ){
			
			console.log( error );
		
		}.bind( this ) );
	
	}
	
	GetResults( ){
	
		console.log( "GetResults", this.state.loadingsSelected );
	
		let url = UrlService.apiDomain() + 'api/production/results';
		
		let Ids = [ ];
		
		for(
			let i in this.state.loadingsSelected
		){
		
			Ids.push( this.state.loadingsSelected[ i ].value );
			
		}
		
		let Data = {
			ids: JSON.stringify( Ids )
		};
			
		HttpService.post( 
			url, Data
		).then( function( res ) {
		
			if( res.data === null ){
					
				alert( "Chyba připojení k serveru" );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
			
			}
			
			if( 
				( res.data.result !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
				
			}
			
			if( 
				( res.data.message !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				this.setState( { showFinalCalculation: false } );
				
				return;
				
			}
			
			this.setState( {
				results: res.data.rows
			} );
			
			this.GetRates( );

		}.bind( this ) ).catch( function( error ){
			
			console.log( error );
		
		}.bind( this ) );
	
	}
	
	GetExpenses( ) {
		
		let url = UrlService.apiDomain() + 'api/expenses';
			
		HttpService.get( 
			url 
		).then( function( res ) {
			
			
			this.setState( {
				expenses: res.data
			} );
			
			this.GetApprisals( );

		}.bind( this ) ).catch( function( error ){
			
			console.log( error );
		
		}.bind( this ) );
			

    }
	
	GetApprisals( ){
	
		let url = UrlService.apiDomain() + 'api/appraisals';
	
		HttpService.get( url )
		.then(res => {
			
			console.log( res.data );
			
			this.setState({
				appraisals: res.data
			});
			
		})
		.catch((error) => {
			console.log(error);
		})
	
	}
	
	HandleChangeTopManager( Ev ){
	
		console.log( "HandleChangeManager", Ev );
		
		let top_manager = Ev.target.value;
		
		let loadingsObject = this.state.loadingsObject;
		
		let loadingsArray = this.state.loadingsArray;
		
		/*let loadingsManager = [ ];
		
		for(
			let i in loadingsArray
		){
		
			if( loadingsObject[ loadingsArray[ i ].value ].manager == manager ){
		
				loadingsManager.push( loadingsArray[ i ] );
		
			}
			
		}*/
	
		this.setState( { 
			top_manager: Ev.target.value,
			loadingsSelected: [ ] //,
			//loadingsManager: loadingsManager
		} );
	
	}
	
	HandleChangeManager( Ev ){
	
		console.log( "HandleChangeManager", Ev );
		
		let manager = Ev.target.value;
		
		let loadingsObject = this.state.loadingsObject;
		
		let loadingsArray = this.state.loadingsArray;
		
		/*let loadingsManager = [ ];
		
		for(
			let i in loadingsArray
		){
		
			if( loadingsObject[ loadingsArray[ i ].value ].manager == manager ){
		
				loadingsManager.push( loadingsArray[ i ] );
		
			}
			
		}*/
	
		this.setState( { 
			manager: Ev.target.value,
			loadingsSelected: [ ] //,
			//loadingsManager: loadingsManager
		} );
	
	}
	
	HandleChangeLoadings( Val, Ev ) {

		console.log( "HandleChangeLoadings", Val, Ev );

		if (typeof Val !== 'undefined') {

			let val = '';
			
			let loadingsSelected = [ ];


			Val.map((i) => {

				if (i.value !== '*') {

					loadingsSelected.push({value: i.value, label: i.label});

				}

				return true;

			});
		  
			this.setState( {
				loadingsSelected: loadingsSelected
			} );
		  
		}
		
	}
	
	HandleChangeDatetime( Ev ){
	
		console.log( "HandleChangeDatetime", Ev );

		this.setState( {
			datetime: Ev.format( "DD.MM.YYYY hh:mm:ss" ),
			datetime_server: Ev.format( "YYYY-MM-DD hh:mm:ss" )
		} );
	
	}
	
	HandleChangeProvision( Ev ){
	
		console.log( "HandleChangeProvision", Ev );
	
		this.setState( {
			provision: parseFloat( Ev.target.value )
		} );
	
	}
	
	onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            var columnSettingsName = 'LoadingsFinalCalculationColumns';
            SaveState(columnSettingsName, params.columnApi, params.api);
        }
    }
	
	onFilterChanged( params ) {
		
		console.log( "onFilterChanged" );
		
        if( typeof( this.gridApi ) != 'undefined' ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
			
            let componentInstance = statusBarComponent;
			
            if( ( typeof( statusBarComponent ) !== 'undefined' ) && statusBarComponent.getFrameworkComponentInstance) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }
	
	enableTab(tabName: string) { //TODO
        const { tabs } = this.state;

        if (tabs[tabName] === undefined) {
            return true;
        }

        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
        });

        newTabs[tabName].active = true;
        this.setState({ tabs: newTabs });
    }

	renderTabContent() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        return keys.map((tabName, index) => {
            const Component = tabs[tabName].component;
            return (
                <div
                    className={`tab-pane ${tabs[tabName].active ? "active" : "inactive"}`}
                    id={tabName}
                    key={index}
                >
                    <Component 
						onSet={
							this.props.onSet
						} 
						isAdmin={
							this.props.isAdmin
						}
						manager={
							this.state.manager
						}
						top_manager={
							this.state.top_manager
						}
						loadingsArray={
							this.state.loadingsArray
						}
						loadingsObjectByLoadingNumber={
							this.state.loadingsObjectByLoadingNumber
						}
						loadingsObject={
							this.state.loadingsObject
						}
						loadingsSelected={
							this.state.loadingsSelected
						}
						isopen={
							this.state.isopen
						}
						expenses={
							this.state.expenses
						}
						appraisals={
							this.state.appraisals
						}
						results={
							this.state.results
						}
						rates={
							this.state.rates
						}
					/>
                </div>
            );
        });
    }
	
	

    render( ) {

        return (
			<>
				<div className="card" style={{'width':'100%'}}>
                    <div className="card-header p-2">
                        <TabPill
                            tabs={this.state.tabs}
                            handleEnableTab={(tabName) => this.enableTab(tabName)}
                        />
                    </div>

                    <div className="card-body">
                        <div className="tab-content">{this.renderTabContent()}</div>
                    </div>
                </div>
			</>
        );
    }
}

LoadingsFinalCalculation.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate()(LoadingsFinalCalculation);

