"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";

import ComponentLayoutElementConfigT from "./ComponentLayoutElementConfigT.mjs";


const ComponentLayoutHConfigT = class ComponentLayoutHConfigT extends ComponentBaseConfigT {

	#ComponentLayoutElementConfigList = null;

	constructor(
		ComponentLayoutElementConfigList,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);
		
		this.ComponentLayoutElementConfigListSet( ComponentLayoutElementConfigList );
	
	}
	
	Copy( ){
		
		let ListCopy = [ ];
		
		let ConfigList = this.ComponentLayoutElementConfigListGet( );
		
		for(
			let I = 0;
			I < ConfigList.length;
			I++
		){
			
			ListCopy.push( ConfigList[ I ].Copy( ) );
			
		}
		
		return new ComponentLayoutHConfigT( 
			ListCopy,
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	ComponentLayoutElementConfigListSet(
		ComponentLayoutElementConfigList
	){
		
		if( ( ComponentLayoutElementConfigList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < ComponentLayoutElementConfigList.length;
			I++
		){
			
			if( ( ComponentLayoutElementConfigList[ I ] instanceof ComponentLayoutElementConfigT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		this.#ComponentLayoutElementConfigList = ComponentLayoutElementConfigList;
		
	}
	
	ComponentLayoutElementConfigListGet(
	
	){
	
		return this.#ComponentLayoutElementConfigList;
	
	}

};


export default ComponentLayoutHConfigT;