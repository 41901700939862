import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import Loading from "../../components/loadings";


import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';


interface Props {
	onUpdateYear: (data) => void;
	onUpdateMonth: (data) => void;
	onUpdateContinent: (data) => void;
	onUpdateCountry: (data) => void;
};


class SpeditionsPage extends Component<Props> {
	
	constructor( props ){
		
		super( props );
		
		this.state = {
			Year: null,
			Month: null,
			Continent: null,
			Country: null
		};
		
	}

    render() {


        return (
            <div className="wrapper">

                <Layout
					onUpdateYear={(e)=>{ console.log("loading update year", e); this.setState( { Year: e.Year } ) } } 
					onUpdateMonth={(e)=>{ console.log("loading update month", e); this.setState( { Month: e.Month } ) } }
					onUpdateContinent={(e)=>{ console.log("loading update continent", e); this.setState( { Continent: e.Continent } ) } }
					onUpdateCountry={(e)=>{ console.log("loading update country", e); this.setState( { Country: e.Country } ) } }
				>
                    <Content hideTitle={true} title="Speditions">
                        <Loading 
							id="0" 
							opts={ this.props.opts } 
							year={ this.state.Year } 
							month={ this.state.Month } 
							continent={ this.state.Continent } 
							country={ this.state.Country } 
							fromSpedition={ true }
						/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

SpeditionsPage.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SpeditionsPage);

