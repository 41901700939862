import { Component } from "react";

import InputBoolean from '../inputs/Boolean/InputBoolean';


const ComponentBoolean = class ComponentBoolean extends Component {
	
	#Field = null;
	#Disabled = null;
	
	#Value = null;
	#Input = null;
	
	constructor( props ){
		
		console.log( props );
		
		super( props );
		
		
		
		this.#Field = props.field;
		
		this.#Disabled = props.disabled;
		
		
		this.#Value = props.value;
		
		this.#Input = null;
		
	}
	
	#OnChange( Field, Type, Value ){
		
		console.log( "#OnChange", Value );
		
		
		this.#Value = Boolean( Value );
		
		
		this.#Input.checked = this.#Value;
		
	}
	
	afterGuiAttached( props ){
		
		console.log( "afterGuiAttached", props, this.#Value );
		
		
		let V = false;
		
		if( this.#Value === null ){
			
			V = false;
			
		} else {
		
			V = this.#Value;
		
		}
		
		this.#Value = !V;
		this.#Input.checked = !V;
		
		//this.#Input.cheched = V;
		
		this.setState( {
			Mounted: true
		} );
		
	}
	
	getValue( ) {
		
		console.log( "getValue", this.#Value );
		
		
		return this.#Value;
	
	}
	
	isPopup( ){
		
		return false;
            
	}
	
	render( ){

		return (
			<div style = { { cursor: "pointer" } }>
				<InputBoolean
					ref_cb = { ( Ref ) => { this.#Input = Ref; } }
					cursor = { "pointer" }
					value = { this.#Value }
					field = { this.#Field }
					disabled = { this.#Disabled }
					on_change_cb = { this.#OnChange.bind( this ) }
				>
					
				</InputBoolean>
			</div>
		);
	
	}


};


export default ComponentBoolean;