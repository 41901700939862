"use strict";


import UIStyleMapT from "../Style/UIStyleMapT.mjs";


const UIStateT = class UIStateT {
	
	#Name = null;
	
	#ClassName = null;
	
	#UIStyleMap = null;
	
	constructor(
		Name,
		ClassName = null,
		UIStyleMap = null
	){
		
		this.NameSet( Name );
		
		this.ClassNameSet( ClassName );
		
		this.StyleMapSet( UIStyleMap );
		
	}
	
	NameGet(
	
	){
		
		return this.#Name;
		
	}
	
	NameSet(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
				
			throw new Error( "Argument" );
			
		}
		
			
		this.#Name = Name;
		
	}
	
	ClassNameGet(
	
	){
			
		return this.#ClassName;
			
	}
	
	ClassNameSet(
		ClassName
	){
		
		if( ClassName !== null ){
		
			if( typeof( ClassName ) !== "string" ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
			
		this.#ClassName = ClassName;
			
	}
	
	StyleMapGet(
	
	){
			
		return ( this.#UIStyleMap === null ) ? null : this.#UIStyleMap.Copy( );
			
	}
	
	StyleMapSet(
		UIStyleMap
	){
		
		if( UIStyleMap !== null ){
		
			if( ( UIStyleMap instanceof UIStyleMapT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
			
		this.#UIStyleMap = ( UIStyleMap === null ) ? null : UIStyleMap.Copy( );
			
	}
	
	Copy(
	
	){
		
		return new UIStateT(
			
			this.NameGet( ),
			this.ClassNameGet( ),
			this.StyleMapGet( )
			
		);
		
	}
				
};


export default UIStateT;