"use strict";


import KeyValueT from "./KeyValueT.mjs";


const KeyValueMapT = class KeyValueMapT {

	#Map = null;
	
	constructor(
		KeyValueList
	){
	
		for(
			let I = 0;
			I < KeyValueList.length;
			I++
		){

			if( ( KeyValueList[ I ] instanceof KeyValueT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < KeyValueList.length;
			I++
		){

			this.#Map.set( KeyValueList[ I ].Key( ), KeyValueList[ I ].Value( ) )
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		Value
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) === "undefined" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.set( 
			Key,
			Value
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
};


export default KeyValueMapT;