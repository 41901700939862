"use strict";


import UIComponentLayoutVConfigT from "../Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentTextDropDownConfigT from "../TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentCheckListConfigT from "../CheckList/UIComponentCheckListConfigT.mjs";


const UIComponentSelectTextConfigT = class UIComponentSelectTextConfigT extends UIComponentLayoutVConfigT {
	
	#UIComponentTextDropDownConfig = null;
	#UIComponentCheckListConfig = null;
	#UIComponentLayoutElementConfigTextDropDown = null;
	#UIComponentLayoutElementConfigCheckList = null;
	
	constructor(
		UIComponentTextDropDownConfig,
		UIComponentCheckListConfig,
		UIComponentLayoutElementConfigTextDropDown,
		UIComponentLayoutElementConfigCheckList,
		
		Wrap = "nowrap",
		JustifyContent = "flex-start",
		AlignItems = "stretch",
		AlignContent = "stretch",
		RowGap = "0",
		
		UIStateMap = null
	){
		
		super( 
			Wrap,
			JustifyContent,
			AlignItems,
			AlignContent,
			RowGap,
			
			UIStateMap
		);
		
		
		this.TextDropDownConfigSet( UIComponentTextDropDownConfig );
		
		this.CheckListConfigSet( UIComponentCheckListConfig );
		
		this.LayoutElementConfigTextDropDownSet( UIComponentLayoutElementConfigTextDropDown );
		
		this.LayoutElementConfigCheckListSet( UIComponentLayoutElementConfigCheckList );
		
	}
	
	LayoutElementConfigTextDropDownSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentLayoutElementConfigTextDropDown = UIComponentLayoutElementConfig.Copy( );
		
	}
	
	LayoutElementConfigTextDropDownGet(
	
	){
		
		return this.#UIComponentLayoutElementConfigTextDropDown.Copy( );
		
	}
	
	LayoutElementConfigCheckListSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentLayoutElementConfigCheckList = UIComponentLayoutElementConfig.Copy( );
		
	}
	
	LayoutElementConfigCheckListGet(
	
	){
		
		return this.#UIComponentLayoutElementConfigCheckList.Copy( );
		
	}
	
	CheckListConfigSet(
		UIComponentCheckListConfig
	){
		
		if( ( UIComponentCheckListConfig instanceof UIComponentCheckListConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentCheckListConfig = UIComponentCheckListConfig.Copy( );
		
	}
	
	CheckListConfigGet(
	
	){
		
		return this.#UIComponentCheckListConfig.Copy( );
		
	}
	
	TextDropDownConfigSet(
		UIComponentTextDropDownConfig
	){
		
		if( ( UIComponentTextDropDownConfig instanceof UIComponentTextDropDownConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentTextDropDownConfig = UIComponentTextDropDownConfig.Copy( );
		
	}
	
	TextDropDownConfigGet(
	
	){
		
		return this.#UIComponentTextDropDownConfig.Copy( );
		
	}
	
	Copy( ){
		
		return new UIComponentSelectTextConfigT( 
			this.TextDropDownConfigGet( ),
			this.CheckListConfigGet( ),
			this.LayoutElementConfigTextDropDownGet( ),
			this.LayoutElementConfigCheckListGet( ),
			
			super.WrapGet( ),
			super.JustifyContentGet( ),
			super.AlignItemsGet( ),
			super.AlignContentGet( ),
			super.RowGapGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentSelectTextConfigT;