import React, { Component } from "react";

export default class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
		console.log( "CheckboxRenderer", this.props );
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);
    }
    render() {
        return (
            <input
                type="checkbox"
                onClick={this.checkedHandler}
                defaultChecked={this.props.value}
            />
        );
    }
}
