import { ColDef } from "ag-grid-community";


import InputTextParser from "../../inputs/Text/InputTextParser";
import InputTextSerializer from "../../inputs/Text/InputTextSerializer";

import Input from "../../inputs/Input";


const ColumnSingleText = class ColumnSingleText implements ColDef {

	headerName = null;
		
	field = null;
		
	options = null;
		
	editable = false;
		
	filter = "agTextColumnFilter";
			
	cellEditor = 'ComponentText';
	
	cellEditorPopup = true;
		
	cellEditorPopupPosition = 'under';
	
	comparator = function( valueA, valueB ) {
		
		if( ( valueA === null ) && ( valueB === null ) ){
				
			return 0;
			
		}
		
		if( valueA === null ){
				
			return -1;
			
		}
		
		if( valueB === null ){
			
			return 1;
			
		}
		
		return valueA.toString( ).toLowerCase( ).localeCompare( valueB.toString( ).toLowerCase( ) );
		
	};
	
	filterParams = {
		
		comparator: function( valueA, valueB ) {
			
			if( ( valueA === null ) && ( valueB === null ) ){
				
				return 0;
				
			}
			
			if( valueA === null ){
				
				return -1;
				
			}
			
			if( valueB === null ){
				
				return 1;
				
			}
			
			return valueA.localeCompare( valueB );
		
		}
		
	};
	

	constructor(
		Name,
		Field,
		Placeholder,
		Editable,
		RegExpMatch,
		RegExpValidation,
		CellRenderer,
		CellRendererParams,
		ModelSchema,
		Meta,
		Values
	){
		
		this.headerName = Name;
		
		this.field = Field;
		
		this.placeholder = Placeholder;
		
		this.editable = Editable;
		
		
		this.cellEditorParams = {
			field: this.field,
			disabled: !this.editable,
			placeholder: this.placeholder,
			regexp_match: RegExpMatch,
			regexp_validation: RegExpValidation
		};
		
		if( CellRenderer ){
		
			this.cellRenderer = CellRenderer;

		}
		
		if( CellRendererParams ){
		
			this.cellRendererParams = CellRendererParams;
			
		}
		
		this.valueGetter = function( params ) {
		
			console.log( "valueGetter", params );
			
			return InputTextParser( params.data[ this.field ] );
			
		}.bind( this );
			
		this.valueFormatter = function( params ){
			
			console.log( "valueFormatter", params );
			
			
			let Data = InputTextParser( params.data[ this.field ] );
			
			let Str = null;
			
			if( Data === null ){
				
				Str = "";
				
			} else {
				
				Str = Data;
				
			}
			
			return Str;
			
		}.bind( this );
			
		this.valueParser = function( params ){
				
			console.log( "valueParser", params );
				
		}.bind( this );
			
		this.valueSetter = function( params ){
			
			console.log( "valueSetter", params );
			
			
			let IsValid = Input.Check( Field, Meta, params.newValue, ModelSchema, params.data );
			
			
			if( IsValid === false ){
				
				params.data[ this.field ] = InputTextSerializer( params.oldValue );
				
			} else {
				
				params.data[ this.field ] = InputTextSerializer( params.newValue );
				
			}
			
			return IsValid;
			
		}.bind( this );
			
	}
	
};


export default ColumnSingleText;