"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";

import ComponentTextConfigT from "../Text/ComponentTextConfigT.mjs";

import ComponentCheckListConfigT from "../CheckList/ComponentCheckListConfigT.mjs";


const ComponentSelectConfigT = class ComponentSelectConfigT extends ComponentBaseConfigT {
	
	#ComponentTextConfig = null;
	#ComponentCheckListConfig = null;
	
	constructor(
		ComponentTextConfig,
		ComponentCheckListConfig,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super( 
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);

		
		this.ComponentTextConfigSet( ComponentTextConfig );
		
		this.ComponentCheckListConfigSet( ComponentCheckListConfig );
		
	}
	
	Copy( ){
		
		return new ComponentSelectConfigT( 
			this.ComponentTextConfigGet( ).Copy( ),
			this.ComponentCheckListConfigGet( ).Copy( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	ComponentCheckListConfigSet(
		Value
	){
		
		if( ( Value instanceof ComponentCheckListConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentCheckListConfig = Value;
		
	}
	
	ComponentCheckListConfigGet(
	
	){
		
		return this.#ComponentCheckListConfig;
		
	}
	
	ComponentTextConfigSet(
		Value
	){
		
		if( ( Value instanceof ComponentTextConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentTextConfig = Value;
		
	}
	
	ComponentTextConfigGet(
	
	){
		
		return this.#ComponentTextConfig;
		
	}
				
};


export default ComponentSelectConfigT;