import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import $ from 'jquery';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import CompanyCard from "../../components/companycard";
import Modal from "../../components/common/modal";
import Pusher from 'react-pusher';
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import {LoadState, SaveState} from "../../services/GridStateService";
import AppraisalsImageRenderer from "../../aggrid/AppraisalsImageRenderer";

class Appraisals extends Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            showArchive: false,
            modules: AllCommunityModules,
            localeText: loctext,
            showCompanyCard: false,
            showCompanyCardName: 'Company card',
            showCompanyCardNode: null,
            showCompanyCardId: null,
            rowdata: null,
            columnDefs: [


                {
                    headerName: this.props.t('Material type'),
                    field: 'material_type',
                    valueGetter: function (params) {

                        var locale = 'cs';
                        if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
                        var value = '';
                        JSON.parse(localStorage.getItem('product_types')).map((c) => {


                            if (String(c.id) === String(params.data.material_type)) {
                                value = c['name_' + locale];

                            }
                            return true;

                        });
                        return value;
                    }
                },

                {
                    headerName: this.props.t('Approx. qty.'),
                    field: 'approx_qty',
                },

                {
                    headerName: this.props.t('Images'),
                    field: 'images',
                    minWidth: 200,
                    cellRenderer: 'AppraisalsImageRenderer'
                },

                {
                    headerName: this.props.t('Price') + ' €/t',
                    field: 'price',
                    filter: "agNumberColumnFilter",
                    editable: function (params) {
                        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
                        if (permissions.includes('appraisals.edit')) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                },


                {
                    headerName: this.props.t('Company name'),
                    field: 'name',
                },
                {
                    headerName: this.props.t('Continent'),
                    field: 'continent',
                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                        var values = JSON.parse(localStorage.getItem('continents'));
                        if (params.data.continent === null || params.data.continent === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.continent))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },
                {
                    headerName: this.props.t('Country'),
                    field: 'country',
                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                        var values = JSON.parse(localStorage.getItem('countries'));
                        if (params.data.country === null || params.data.country === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.country))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },
                {
                    headerName: this.props.t('Loading address'),
                    field: 'loading_address',
                },
                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                },
                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    valueGetter: function (params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }

                        if (params.data.created_at === null || params.data.created_at === 0 || params.data.created_at === '') return '';
                        var date = new Date(params.data.created_at);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        var hour = addZero(date.getHours());
                        var min = addZero(date.getMinutes());
                        month++;
                        return day + '.' + month + '.' + year + ' ' + hour + ':' + min;

                    }
                },
                {
                    headerName: this.props.t('Appraised at'),
                    field: 'appraised_on',
                    valueGetter: function (params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }

                        if (params.data.appraised_on === null || params.data.appraised_on === 0 || params.data.appraised_on === '') return '';
                        var date = new Date(params.data.created_at);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        var hour = addZero(date.getHours());
                        var min = addZero(date.getMinutes());
                        month++;
                        return day + '.' + month + '.' + year + ' ' + hour + ':' + min;

                    }
                },
                {
                    headerName: this.props.t('Appraised by'),
                    field: 'appraised_by',
                    valueGetter: function (params) {
                        var values = JSON.parse(localStorage.getItem('users'));
                        if (params.data.appraised_by === null || params.data.appraised_by === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.appraised_by))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

                {
                    headerName: this.props.t('Requested by'),
                    field: 'requested_by',
                    valueGetter: function (params) {
                        var values = JSON.parse(localStorage.getItem('users'));
                        if (params.data.requested_by === null || params.data.requested_by === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.requested_by))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                sortable: true,

            },
            frameworkComponents: {
                DeleteRenderer: DeleteRenderer,
                AppraisalsImageRenderer: AppraisalsImageRenderer,
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain() + 'api/appraisals')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });

                var hardcodedFilter = {
                    price: {
                        filterType: 'number',
                        type: 'equals',
                        filter: 0
                    },
                };

                function tt() {
                    this.gridApi.setFilterModel(hardcodedFilter)
                }

                setTimeout(tt.bind(this), 10);
                setTimeout(() => {
                    LoadState('Appraisals', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('appraisals.delete')) {
            items.push({
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",

                cellRendererParams: {
                    gridType: "appraisals",
                },
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        LoadState('Appraisals', params.columnApi, params.api);
    };

    onCellCValueChanged = (event) => {
        event.data.field = event.colDef.field;
        HttpService.put(UrlService.apiDomain() + 'api/appraisals/' + event.data.id, event.data)
            .then(res => {
                console.log(res);
                console.log('Successfully updated');

            })
            .catch((error) => {
                console.log(error);
            })


    }


    onCellClicked = (e) => {
        var that = this;
        var field = e.column.colId;
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('companies.view')) {
            if (field === "name") {
                //  console.log( e.node.data.id)
                that.setState({
                    showCompanyCard: true,
                    showCompanyCardName: e.node.data.name,
                    showCompanyCardId: e.node.data.parent_id,
                    showCompanyCardNode: e.node
                });


            }
        }

    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('Appraisals', params.columnApi, params.api);
        }
    }

    getRowNodeId = (data) => {
        return data.id;
    };

    render() {
        const rowClassRules = {
            'rag-green': 'data.price > 0',

        };
        return (
            <div className="wrapper">
                <Pusher
                    channel="my-channel"
                    event="my-event"
                    onUpdate={(e) => { //console.log(e);

                        var msg = JSON.parse(e.message);
                        if (msg.action == 'appraisals_edit') {
                            var rowNode = this.gridApi.getRowNode(msg.id);
                            if (typeof rowNode != 'undefined' && typeof rowNode.data != 'undefined' && typeof rowNode.data[msg.field] != 'undefined') {
                                if (rowNode.data[msg.field] != msg.value) {
                                    rowNode.data[msg.field] = msg.value;
                                    var params = {
                                        force: true,
                                        suppressFlash: false,
                                        rowNodes: [rowNode],
                                        columns: [msg.field]
                                    };
                                    this.gridApi.refreshCells(params);
                                }
                            }
                        }

                        if (msg.action == 'appraisals_create') {
                            HttpService.get(UrlService.apiDomain() + 'api/appraisal/' + msg.id)
                                .then(res => {
                                    let r = this.gridApi.updateRowData({
                                        add: [res.data.entry]
                                    });
                                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }


                    }}
                />
                <div className="zevgan_modal" onClick={() => {
                    $('.zevgan_modal').hide()
                }}>
                    <div className="zevgan_modal-content" onClick={() => {
                        $('.zevgan_modal').hide()
                    }}>
                        <img src="" id="zevgan_lightbox_img" onClick={() => {
                            $('.zevgan_modal').hide()
                        }}/>
                    </div>
                </div>
                <Layout>
                    <Content title={this.props.t('Appraisals')}>
                        <Modal
                            title={this.state.showCompanyCardName}

                            visibility={this.state.showCompanyCard}
                            size="lg"
                            onClose={() => this.setState({showCompanyCard: !this.state.showCompanyCard})}
                        >
                            <CompanyCard show={this.state.showCompanyCard} rownode={this.state.showCompanyCardNode}
                                         tmp={Date.now()} id={this.state.showCompanyCardId} model={"company"}/>
                        </Modal>

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                <button type="button"
                                        className="btn btn-success btn-sm"
                                        onClick={() => {

                                            if (!this.state.showArchive) {
                                                this.gridApi.setFilterModel(null);
                                            } else {
                                                var hardcodedFilter = {
                                                    price: {
                                                        filterType: 'number',
                                                        type: 'equals',
                                                        filter: 0
                                                    },
                                                };
                                                this.gridApi.setFilterModel(hardcodedFilter);
                                            }

                                            this.setState({showArchive: !this.state.showArchive});


                                        }}
                                >{this.props.t('Archive')} <i className="fa fa-archive"></i>
                                </button>
                            </div>
                        </div>

                        <div style={{'height': '100%', width: '100%'}} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                                rowClassRules={rowClassRules}
                                getRowNodeId={this.getRowNodeId}
                                onCellClicked={this.onCellClicked}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Appraisals.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Appraisals);

