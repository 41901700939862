"use strict";


import APIFieldT from "../../../../Field/APIFieldT.mjs";

import APIFieldNameT from "../../../../Field/APIFieldNameT.mjs";

import APIModelNameT from "../../../Name/APIModelNameT.mjs";

import APIModelColumnTypeT from "../../../Column/APIModelColumnTypeT.mjs";


const APIModelSchemaResponseElementT = class APIModelSchemaResponseElementT {
	
	#Field = null;
	#FieldName = null;
	#ColumnType = null;
	#ModelName = null;
	#Visible = false;
	#Editable = false;
	
	constructor( 
		Field,
		FieldName,
		ColumnType,
		ModelName,
		Visible,
		Editable
	){
		
		if( ( Field instanceof APIFieldT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( FieldName instanceof APIFieldNameT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ColumnType instanceof APIModelColumnTypeT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ModelName !== null ){
		
			if( ( ModelName instanceof APIModelNameT ) === false ){
				
				throw new Error( "Invalid argument" );
				
			}
		
		}
		
		if( typeof( Visible ) !== "boolean" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#Field = Field;
		
		this.#FieldName = FieldName;
		
		this.#ColumnType = ColumnType;
		
		this.#ModelName = ModelName;
		
		this.#Visible = Visible;
		
		this.#Editable = Editable;
		
	}
	
	static FromJSON(
		JSONData
	){
		
		console.log( JSONData );
		
		return new APIModelSchemaResponseElementT(
			APIFieldT.FromJSON( JSONData[ "Field" ] ),
			APIFieldNameT.FromJSON( JSONData[ "FieldName" ] ),
			APIModelColumnTypeT.FromJSON( JSONData[ "ColumnType" ] ),
			( JSONData[ "ModelName" ] !== null ) ? APIModelNameT.FromJSON( JSONData[ "ModelName" ] ) : null,
			JSONData[ "Visible" ],
			JSONData[ "Editable" ]
		);
		
	}
	
	Field( 
	
	){
		
		return this.#Field;
		
	}
	
	FieldName( 
	
	){
		
		return this.#FieldName;
		
	}
	
	ColumnType(
	
	){
		
		return this.#ColumnType;
		
	}
	
	ModelName(
	
	){
		
		return this.#ModelName;
		
	}
	
	Visible(
	
	){
		
		return this.#Visible;
		
	}
	
	Editable(
	
	){
		
		return this.#Editable;
		
	}
	
};


export default APIModelSchemaResponseElementT;