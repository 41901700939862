import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import ColumnOptions from "../../../components/common/product_type_options";
import ExpenseTypeAdd from "./expense_type_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import OptionsRenderer from "../../../aggrid/OptionsRenderer.js";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';


class ExpenseTypes extends Component {

    constructor(props) {
		
        let locale = 'cs';
		
        if( localStorage.getItem('locale') != null ) {
			
			locale = localStorage.getItem('locale');
			
        }
		
		let loctext;
		
        let expense_types_types = JSON.parse( localStorage.getItem( 'expense_types_types' ) );
		
		let boolean_types = JSON.parse( localStorage.getItem( 'booleans' ) );
		
        if( locale == 'cs' ) {
			
			loctext = LocaleCs;
			
		} else if( locale=='en' ) {
			
			loctext = LocaleEn;
			
		}
		
        const customComparator = function( valueA, valueB ){
			
            if( 
				( valueA && ( valueA != null ) ) && 
				( valueB && ( valueB != null ) ) && 
				( typeof( valueA.toLowerCase ) == 'function' ) && 
				( typeof( valueB.toLowerCase ) == 'function' ) ){
					
                return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
				
			} else {
				
				return false;
				
			}
			
        };

        super( props );
		
        this.state = {
			
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
			expense_types_types: expense_types_types,
			popupParent: document.querySelector( 'body' ),
            columnDefs: [
				{
                    headerName: this.props.t('Type'),
                    field: 'type',
                    editable: true,
					checkboxSelection: true,

					cellEditor: 'agRichSelectCellEditor',


					cellEditorParams: {
						values: expense_types_types.map( function( e ) { 
							return e[ 'name_' + locale ] 
						} )
					},
					
					valueSetter: function (params) {
						
						let values = expense_types_types;
						
						if ( params.data && params.data.id ) {
							
							try {
								
								let value = null;
								
								for( 
									let i in values
								){
									
									if( values[ i ][ 'name_' + locale ] == params.newValue ){
										
										value = values[ i ].id;
										
										break;
										
									}
									
								}
								
								params.data[ 'type' ] = value;
								
								return true;
								
							} catch (err) {
								
								console.log( err );
								
								return false;
								
							}
							
						} else {
							
							return false;
							
						}
						
					},
					
					valueGetter: function (params) {
						
						let values = expense_types_types;
						
						if( params.data && params.data.id ) {

							if( 
								( params.data[ 'type' ] === null ) || 
								( params.data[ 'type' ] === '' )
							) {
								
								return '';
								
							}
							
							try {

								let value = '';
						
								for( 
									let i in values
								){
									
									if( values[ i ].id == params.data.type ){
										
										value = values[ i ][ 'name_' + locale ];
										
										break;
										
									}
									
								}
								
								return value;
								
							} catch(err) {

								console.log( err );
								
								return '';
								
							}
							
						} else {
							
							return '';
							
						}
						
					}
                },
				{
                    headerName: this.props.t( 'Czech name' ),
                    field: 'name_cs',
                    editable: true,

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },
				{
                    headerName: this.props.t( 'English name' ),
                    field: 'name_en',
                    editable: true,

                    filter: 'agTextColumnFilter',
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Positive'),
                    field: 'positive',
                    editable: true,

                    comparator: customComparator,
					
					cellEditor: 'agRichSelectCellEditor',
					
					cellEditorParams: {
						values: boolean_types.map( function( e ) { 
							return e[ 'name_' + locale ] 
						} )
					},
					
					valueSetter: function (params) {
						
						let values = boolean_types;
						
						if ( params.data && params.data.id ) {
							
							try {
								
								let value = null;
								
								for( 
									let i in values
								){
									
									if( values[ i ][ 'name_' + locale ] == params.newValue ){
										
										value = values[ i ].id;
										
										break;
										
									}
									
								}
								
								params.data[ 'positive' ] = value;
								
								return true;
								
							} catch (err) {
								
								console.log( err );
								
								return false;
								
							}
							
						} else {
							
							return false;
							
						}
						
					},
					
					valueGetter: function (params) {
						
						let values = boolean_types;
						
						
						if( params.data && params.data.id ) {

							if( 
								( params.data[ 'positive' ] === null ) || 
								( params.data[ 'positive' ] === '' )
							) {
								
								return '';
								
							}
							
							try {

								let value = '';
						
								for( 
									let i in values
								){
									
									if( values[ i ].id == params.data.positive ){
										
										value = values[ i ][ 'name_' + locale ];
										
										break;
										
									}
									
								}
								
								return value;
								
							} catch(err) {

								console.log( err );
								
								return '';
								
							}
							
						} else {
							
							return '';
							
						}
						
					}
					
                },
                {
                    headerName: this.props.t('Created by'),
                    field: 'created_by',
                    editable: false,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator,
					
					valueGetter: function( params ) {
						
						let values = JSON.parse( localStorage.getItem('users') );
						
						let value = '';
						
						for( 
							let i in values
						){
							
							if( values[ i ].id == params.data.created_by ){
								
								value = values[ i ].name;
								
								break;
								
							}
							
						}
						
						return value;
						
					}
                },
                {
                    headerName: this.props.t('Updated by'),
                    field: 'updated_by',
                    editable: false,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator,
					
					valueGetter: function( params ) {
						
						let values = JSON.parse( localStorage.getItem('users') );
						
						let value = '';
						
						for( 
							let i in values
						){
							
							if( values[ i ].id == params.data.updated_by ){
								
								value = values[ i ].name;
								
								break;
								
							}
							
						}
						
						return value;
						
					}
                },
                {
					headerName:this.props.t('Delete'),
					cellRenderer:"DeleteRenderer",
					cellRendererParams: {
						gridType: "expense_types"
					},
                    suppressMenu: true,
				},
            ],
			
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
			
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                OptionsRenderer: OptionsRenderer
            }
			
        };
		
    }

    componentDidMount( ) {
		
        HttpService.get(UrlService.apiDomain()+'api/expense_types')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
			
    }


    onGridReady = (params) => {
		
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
		
        document.querySelector('#myGrid').style.height = '';
		
    };

    onCellCValueChanged = (event) => {
		
		event.data.updated_by = JSON.parse( localStorage.my_user ).id;
		
		console.log( event.data );

        HttpService.put( UrlService.apiDomain()+'api/expense_types/' + event.data.id, event.data )
            .then(res => {
                console.log('Successfully updated');
            })
            .catch( (error) => {
                console.log(error);
            } );

    }

    async HandleAdd( Data ) {
		
		Data.created_by = JSON.parse( localStorage.my_user ).id;
			
        HttpService.post( UrlService.apiDomain( ) + 'api/expense_types', Data )
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    async HandleDelete( ) {

        confirmAlert({
			
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
			
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
							
                            HttpService.delete( UrlService.apiDomain() + 'api/expense_types/' + e.data.id )
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData( { remove: [e.data] } ) 
                                })
                                .catch((error) => {
                                    alert(error);
                                });
								

                        })

                    }
                },
				
                {
					
                    label: this.props.t('No'),

                }
				
            ]
			
        });

    }

    render( ) {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t('Expense types')}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={ ( ) => this.HandleDelete( ) }
                            >
                                {this.props.t('Delete')} <i className="fa fa-trash"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <ExpenseTypeAdd onAdd={ ( Data ) => this.HandleAdd( Data ) } types={ this.state.expense_types_types }/>
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
								popupParent={this.state.popupParent}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

ExpenseTypes.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ExpenseTypes);

