"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";


const ComponentTextConfigT = class ComponentTextConfigT extends ComponentBaseConfigT {
	
	#Value = null;
	#Placeholder = null;
	#RegExpMatch = null;
	#RegExpValidation = null;
	
	constructor(
		Value,
		Placeholder,
		RegExpMatch,
		RegExpValidation,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);
		
		this.ValueSet( Value );
		
		this.PlaceholderSet( Placeholder );
		
		this.RegExpMatchSet( RegExpMatch );
		
		this.RegExpValidationSet( RegExpValidation );
		
	}
	
	Copy(
	
	){
		
		return new ComponentTextConfigT(
			this.ValueGet( ),
			this.PlaceholderGet( ),
			this.RegExpMatchGet( ),
			this.RegExpValidationGet( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	ValueSet( 
		Value 
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Value = Value;
		
	}
	
	ValueGet(

	){
		
		return this.#Value;
		
	}
	
	PlaceholderSet( 
		Value 
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Placeholder = Value;
		
	}
	
	PlaceholderGet(

	){
		
		return this.#Placeholder;
		
	}
	
	RegExpMatchSet( 
		Value 
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#RegExpMatch = Value;
		
	}
	
	RegExpMatchGet(

	){
		
		return this.#RegExpMatch;
		
	}
	
	RegExpValidationSet( 
		Value 
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#RegExpValidation = Value;
		
	}
	
	RegExpValidationGet(

	){
		
		return this.#RegExpValidation;
		
	}
				
};


export default ComponentTextConfigT;