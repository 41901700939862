"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentLayoutHConfigT = class UIComponentLayoutHConfigT extends UIComponentBaseConfigT {
	
	#Wrap = null;
	
	#JustifyContent = null;
	
	#AlignItems = null;
	
	#AlignContent = null;

	#ColumnGap = null;
	
	constructor(
		Wrap = "nowrap",
		JustifyContent = "flex-start",
		AlignItems = "stretch",
		AlignContent = "stretch",
		ColumnGap = "0",
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.WrapSet( Wrap );
		
		this.JustifyContentSet( JustifyContent );
		
		this.AlignItemsSet( AlignItems );
		
		this.AlignContentSet( AlignContent );
		
		this.ColumnGapSet( ColumnGap );
		
	}
	
	WrapGet(
	
	){
			
		return this.#Wrap;
			
	}
	
	WrapSet(
		Wrap
	){
		
		if( typeof( Wrap ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#Wrap = Wrap;
			
	}
	
	JustifyContentGet(
	
	){
			
		return this.#JustifyContent;
			
	}
	
	JustifyContentSet(
		JustifyContent
	){
		
		if( typeof( JustifyContent ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#JustifyContent = JustifyContent;
			
	}
	
	AlignItemsGet(
	
	){
			
		return this.#AlignItems;
			
	}
	
	AlignItemsSet(
		AlignItems
	){
		
		if( typeof( AlignItems ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#AlignItems = AlignItems;
			
	}
	
	AlignContentGet(
	
	){
			
		return this.#AlignContent;
			
	}
	
	AlignContentSet(
		AlignContent
	){
		
		if( typeof( AlignContent ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#AlignContent = AlignContent;
			
	}
	
	ColumnGapGet(
	
	){
			
		return this.#ColumnGap;
			
	}
	
	ColumnGapSet(
		ColumnGap
	){
		
		if( typeof( ColumnGap ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#ColumnGap = ColumnGap;
			
	}
	
	Copy(
	
	){
		
		return new UIComponentLayoutHConfigT(
		
			this.WrapGet( ),
			this.JustifyContentGet( ),
			this.AlignItemsGet( ),
			this.AlignContentGet( ),
			this.ColumnGapGet( ),
		
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentLayoutHConfigT;