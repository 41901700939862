"use strict";


import UIElementAbstractT from "../../../../Interface/UI/Element/UIElementAbstractT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentAbstractT from "../../../../Interface/UI/Component/_/UIComponentAbstractT.mjs";


import UIComponentLayoutElementConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIStyleT from "../../../../Type/UI/Style/UIStyleT.mjs";



const UIComponentLayoutElementT = class UIComponentLayoutElementT extends UIComponentBaseT {

	#Data = null;
	
	#UIComponentLayoutElementConfig = null;

	constructor(
		UIElement,
		UIComponentLayoutElementConfig
	){
		
		if( ( UIElement instanceof UIElementAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentLayoutElementConfig
		);


		this.ConfigSet( UIComponentLayoutElementConfig.Copy( ) );
	
	}
	
	ConfigSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#UIComponentLayoutElementConfig = UIComponentLayoutElementConfig.Copy( );
		
		
		let UIStateMap = this.#UIComponentLayoutElementConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		let UIStyleMap = UIState.StyleMapGet( );
		
		UIStyleMap.Set( new UIStyleT( "display", "flex" ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-grow", UIComponentLayoutElementConfig.GrowGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-shrink", UIComponentLayoutElementConfig.ShrinkGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-basis", UIComponentLayoutElementConfig.BasisGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "align-self", UIComponentLayoutElementConfig.AlignSelfGet( ) ) );
		
		
		super.ConfigSet( this.#UIComponentLayoutElementConfig );
		
		super.StyleMapSet( UIStyleMap );
		
	}
	
	ConfigGet(
	
	){
		
		return this.#UIComponentLayoutElementConfig.Copy( );
		
	}
	
	
	DataGet(
	
	){
		
		return this.#Data;
		
	}
	
	DataSet( 
		Data
	){
		
		console.log( "UIComponentLayoutElementT.DataSet", Data );
		
		
		if( Data !== null ){
		
			if( ( Data instanceof UIComponentAbstractT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		this.#Data = Data;
		
		
		if( Data !== null ){
		
			this.ChildSet( 0, Data );
		
		} else {
			
			if( this.ChildExists( 0 ) === true ){
				
				this.ChildRemove( 0, Data );
				
			}
			
		}
		
	}
	
	Start( 
		
	){
		
	}
	
	Stop(
	
	){
		
	}
	
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Change" );
		
	}
	
};


export default UIComponentLayoutElementT;
