import React, {Component} from "react";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Map from "../../components/common/map";

import * as Nominatim from 'nominatim-browser';


interface Props {
  onAdd: (data) => void;
}

class CompanyAdd extends Component<Props> {

  select_cols = ['type', 'country', 'continent'];
  blankOption = {value: "", label: this.props.t('Choose')};

  companiesData = JSON.parse(localStorage.getItem('companies'));
  invoiceSubtypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_230')).data);
  countriesData = JSON.parse(localStorage.getItem('countries'));
  bankAccountsData = JSON.parse(localStorage.getItem('bank_accounts'));
  currenciesData = JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);
  invoiceTypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_155')).data);
  paymentMethodsData = JSON.parse(JSON.parse(localStorage.getItem('columns_136')).data);
  paymentTypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_158')).data);

  constructor(props) {
    super(props)

    var payment_method = '';
    var payment_method_value = this.blankOption;
    var payment_type = '';
    var payment_type_value = this.blankOption;
    var bank_name_1 = '';
    var bank_name_2 = '';
    var bank_address_1 = '';
    var bank_address_2 = '';
    var beneficiary_name_1 = '';
    var beneficiary_name_2 = '';
    var beneficiary_address_1 = '';
    var beneficiary_address_2 = '';
    var currency = '';
    var currency_value = this.blankOption;
    var bank_country_value = this.blankOption;
    var bank_country = '';
    var account_name = '';
    var account_number = '';
    var iban = '';
    var bic_swift = '';
    var bank_code = '';


    var continent = "";
    if (localStorage.getItem('continent') != null && localStorage.getItem('continent') != '') continent = localStorage.getItem('continent');

    var country = "";
    if (localStorage.getItem('country') != null && localStorage.getItem('country') != '') country = localStorage.getItem('country');

    let name = '';
    let address = '';
    if (window.location.href.indexOf('/add/') !== -1) {
      name = window.location.href.split('/')[window.location.href.split('/').length - 2];
      address = window.location.href.split('/')[window.location.href.split('/').length - 1];
    }

    this.state = {
      overflowY: 'hidden',
      type_options: [],
      country_options: [],
      continent_options: [],
      state_options: [],
      city_options: [],
      zip_options: [],
      name: name,
      branch_name: "",
      ico: "",
      dic: "",
      type: "",
      continent: continent,
      country: country,
      state: "",
      city: "",
      zip: "",
      street: "",
      house: "",
      address: address,
      lattitude: "",
      longitude: "",
      allow_submit: false,
      show_map: false,
      show_map_container: false
    };

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

    if (prevProps.id !== this.props.id) {

      this.make_final_options();

      var continent = "";
      if (localStorage.getItem('continent') != null && localStorage.getItem('continent') != '') continent = localStorage.getItem('continent');

      var country = "";
      if (localStorage.getItem('country') != null && localStorage.getItem('country') != '') country = localStorage.getItem('country');

      this.make_final_options('continent', continent);


      let name = '';
      let address = '';
      if (window.location.href.indexOf('/add/') !== -1) {
        name = window.location.href.split('/')[window.location.href.split('/').length - 2];
        address = window.location.href.split('/')[window.location.href.split('/').length - 1];
      }

      this.setState({
        overflowY: 'hidden',
        country_options: [],

        state_options: [],
        city_options: [],
        zip_options: [],
        name: name,
        branch_name: "",
        ico: "",
        dic: "",
        type: "",
        continent: continent,
        country: country,
        state: "",
        city: "",
        zip: "",
        street: "",
        house: "",
        address: address,
        lattitude: "",
        longitude: "",
        allow_submit: false,
        show_map: false,
        show_map_container: false
      });
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const {
      name,
      branch_name,
      ico,
      dic,
      type,
      continent,
      country,
      state,
      city,
      zip,
      street,
      house,
      address,
      lattitude,
      longitude
    } = this.state;
    this.props.onAdd({
      name,
      branch_name,
      ico,
      dic,
      type,
      continent,
      country,
      state,
      city,
      zip,
      street,
      house,
      address,
      lattitude,
      longitude
    });
  }

  MakeItem(X) {

    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
    return <option key={X.id} value={X.id}>{X['name_' + locale]}</option>;
  };


  countriesByContinents(continents) {
    return new Promise(resolve => {
      HttpService.post(UrlService.apiDomain() + 'api/countriesByContinents', {'opts': continents})
        .then(res => {
          resolve(res.data);

        })
        .catch((error) => {
          console.log(error);
        });
    });
  }


  make_final_options(n_type, n_value) {
    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
    var final_options = {};

    this.select_cols.map((c) => {
      var optionsArray = [];
      var column = '';
      JSON.parse(localStorage.getItem('columns')).map((e) => {
        if (e.field == c) column = e;
      });

      var allowed_options = [];
      JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
        if (e.name == 'companies/columns-' + c) {
          allowed_options = e.value.split('//');
        }
      });


      var values = [];

      if (column.data_array == 'custom') {
        values = JSON.parse(column.data);
      } else if (column.data_array == 'countries' && (this.state.continent != '' || (n_type == 'continent' && n_value != ''))) {

        var continents = [this.state.continent];
        if (n_type == 'continent' && n_value != '') continents = [n_value];
        this.countriesByContinents(continents).then(rows => {
          rows.map((r) => {
            if (allowed_options.includes(r.id.toString()) || allowed_options.length == 0 || (allowed_options.length == 1 && allowed_options[0] == '')) {
              optionsArray.push(r);
            }
          });
          this.setState({country_options: optionsArray});
        });
      } else if (column.data_array != '' && column.data_array != null && column.data_array != 'none' && column.data_array != 'zipcodes' && column.data_array != 'cities' && column.data_array != 'states' && column.data_array != 'countries') {
        //console.log(column.data_array);
        values = JSON.parse(localStorage.getItem(column.data_array));
      }

      try {
        values.map((e) => {
          if (allowed_options.includes(e.id.toString()) || allowed_options.length == 0 || (allowed_options.length == 1 && allowed_options[0] == '')) {
            optionsArray.push(e);
          }
        });
      } catch (err) {
        console.log(err);
      }


      if (c == 'type') this.setState({type_options: optionsArray});
      if (c == 'continent') this.setState({continent_options: optionsArray});


    });


  }


  lookup_address() {
    var allowed_state = [];
    var allowed_city = [];
    var allowed_zip = [];
    JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
      if (e.name == 'companies/columns-state') allowed_state = e.value.split('//');
      if (e.name == 'companies/columns-city') allowed_city = e.value.split('//');
      if (e.name == 'companies/columns-zip') allowed_zip = e.value.split('//');

    });


    this.setState({city: ''});
    this.setState({state: ''});
    this.setState({street: ''});
    this.setState({house: ''});
    this.setState({zip: ''});
    this.setState({allow_submit: false});

    Nominatim.geocode({
        q: this.state.address,
        country: this.state.country,
        'accept-language': 'en',
        addressdetails: true
      })
      .then((results: NominatimResponse[]) => {
        var result = results[0];

        if (results.length == 0) {
          alert(this.props.t("Address wasn't found"));
        }


        // result.address is only returned when 'addressdetails: true' is sent in the geocode request
        var city = '';
        var state = '';
        if (typeof result.address.village !== "undefined") city = result.address.village;
        if (typeof result.address.town !== "undefined") city = result.address.town;
        if (typeof result.address.city !== "undefined") city = result.address.city;

        if (typeof result.address.county !== "undefined") state = result.address.county;
        if (typeof result.address.state !== "undefined") state = result.address.state;

        if (
          (state === '' || allowed_state.includes(state) || allowed_state.length == 0 || (allowed_state.length == 1 && allowed_state[0] == '')) &&
          (city === '' || allowed_city.includes(city) || allowed_city.length == 0 || (allowed_city.length == 1 && allowed_city[0] == '')) &&
          (typeof result.address.postcode === "undefined" || result.address.postcode === '' || allowed_zip.includes(result.address.postcode) || allowed_zip.length == 0 || (allowed_zip.length == 1 && allowed_zip[0] == ''))
        ) {
          this.setState({address: result.display_name});
          this.setState({city: city});
          this.setState({state: state});
          this.setState({street: result.address.road});
          this.setState({house: result.address.house_number});
          this.setState({zip: result.address.postcode});
          this.setState({allow_submit: true});
        } else {
          alert(this.props.t("You don't have permissions for this address: ") + result.display_name);
        }


      })
      .catch((error) => {
        console.error(error);
      });

  }

  handleAddressSelect(data) {

    console.log(data);
    var allowed_state = [];
    var allowed_city = [];
    var allowed_zip = [];
    JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
      if (e.name == 'companies/columns-state') allowed_state = e.value.split('//');
      if (e.name == 'companies/columns-city') allowed_city = e.value.split('//');
      if (e.name == 'companies/columns-zip') allowed_zip = e.value.split('//');

    });
    if (
      typeof data.address !== 'undefined' && data.address != null && data.address != '' &&
      typeof data.latValue !== 'undefined' && data.latValue != null && data.latValue != '' &&
      typeof data.lngValue !== 'undefined' && data.lngValue != null && data.v != ''

    ) {
      if (
        (data.state === '' || allowed_state.includes(data.state) || allowed_state.length == 0 || (allowed_state.length == 1 && allowed_state[0] == '')) &&
        (data.city === '' || allowed_city.includes(data.city) || allowed_city.length == 0 || (allowed_city.length == 1 && allowed_city[0] == '')) &&
        (typeof data.zip === "undefined" || data.zip === '' || allowed_zip.includes(data.zip) || allowed_zip.length == 0 || (allowed_zip.length == 1 && allowed_zip[0] == ''))

      ) {
        var name = this.state.name;
        if (name == '') name = data.name;
        this.setState({
          name: name,
          address: data.address,
          state: data.state,
          city: data.city,
          street: data.street,
          house: data.house,
          zip: data.zip,
          lattitude: data.latValue,
          longitude: data.lngValue,
          allow_submit: true
        });
      } else {
        alert(this.props.t("You don't have permissions for this address: ") + data.address);
      }
    }
  }

  render() {
    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');


    const {
      name,
      branch_name,
      ico,
      dic,
      type,
      continent,
      country,
      state,
      city,
      zip,
      street,
      house,
      address,
      lattitude,
      longitude
    } = this.state;

    var display_style = 'none';
    var address_rows = <></>;
    if (true == true) {
      display_style = 'block';
      address_rows =
        <>
          <div className="row">
            <div className="col-12">
              {country !== '' &&
                  <Map
                      search_string={this.state.address}
                      google={this.props.google}
                      center={{lat: 50.079936, lng: 14.4275543}}
                      country_id={this.state.country}
                      show_map_container={this.state.show_map_container}
                      onSelect={(data) => this.handleAddressSelect(data)}
                      height='300px'
                      zoom={15}
                  />
              }


            </div>

          </div>


          <div className="row" style={{paddingTop: "45px"}}>

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('Address')}</label>
                <input
                  type="text"
                  required
                  readOnly="readOnly"
                  className="form-control"
                  value={address}

                  onChange={(event) =>
                    this.setState({address: event.target.value})
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('State')}</label>
                <input
                  type="text"

                  readOnly="readOnly"
                  className="form-control"
                  value={state}

                  onChange={(event) =>
                    this.setState({state: event.target.value})
                  }
                />
              </div>
            </div>


            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('City')}</label>
                <input
                  type="text"

                  readOnly="readOnly"
                  className="form-control"
                  value={city}

                  onChange={(event) =>
                    this.setState({city: event.target.value})
                  }
                />
              </div>
            </div>


            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('ZIP')}</label>
                <input
                  type="text"
                  readOnly="readOnly"
                  className="form-control"
                  value={zip}

                  onChange={(event) =>
                    this.setState({zip: event.target.value})
                  }
                />
              </div>
            </div>


          </div>


          <div className="row">

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('Street')}</label>
                <input
                  type="text"
                  className="form-control"
                  value={street}
                  readOnly="readOnly"

                  onChange={(event) =>
                    this.setState({street: event.target.value})
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('House nr.')}</label>
                <input
                  type="text"

                  readOnly="readOnly"
                  className="form-control"
                  value={house}

                  onChange={(event) =>
                    this.setState({house: event.target.value})
                  }
                />
              </div>
            </div>


            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('Lattitude')}</label>
                <input
                  type="text"
                  required
                  readOnly="readOnly"
                  className="form-control"
                  value={lattitude}

                  onChange={(event) =>
                    this.setState({lattitude: event.target.value})
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <label htmlFor="description">{this.props.t('Longitude')}</label>
                <input
                  type="text"
                  required
                  readOnly="readOnly"
                  className="form-control"
                  value={longitude}

                  onChange={(event) =>
                    this.setState({longitude: event.target.value})
                  }
                />
              </div>
            </div>


          </div>
        </>;
    }
    return (
      <div style={{'height': '500px', 'overflowY': this.state.overflowY, 'overflowX': 'hidden'}}>
        <form onSubmit={(event) => this.handleFormSubmit(event)}>

          <div className="container">
            <div className="row">

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Supplier type')}</label>
                  <select
                    value={type}
                    required
                    className="form-control"
                    onChange={(event) =>
                      this.setState({type: event.target.value})
                    }>
                    <option value="">{this.props.t('Choose')}</option>
                    {this.state.type_options.map(this.MakeItem)}</select>
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Name of company')}</label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    value={name}
                    placeholder={this.props.t("Enter the name")}
                    onChange={(event) =>
                      this.setState({name: event.target.value})
                    }
                  />
                </div>
              </div>


              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Company ID')}</label>
                  <input
                    type="text"

                    className="form-control"
                    value={ico}
                    placeholder={this.props.t("Enter the value")}
                    onChange={(event) =>
                      this.setState({ico: event.target.value})
                    }
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('VAT ID')}</label>
                  <input
                    type="text"

                    className="form-control"
                    value={dic}
                    placeholder={this.props.t("Enter the value")}
                    onChange={(event) =>
                      this.setState({dic: event.target.value})
                    }
                  />
                </div>
              </div>


            </div>

            <div className="row">


              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Continent')}</label>
                  <select
                    value={continent}
                    required
                    className="form-control"
                    onChange={(event) => {
                      this.setState({
                        continent: event.target.value,
                        country: '',
                        state: '',
                        city: '',
                        zip: '',
                        country_options: [],
                        address: ''
                      });

                      this.make_final_options('continent', event.target.value);


                    }
                    }>
                    <option value="">{this.props.t('Choose')}</option>
                    {this.state.continent_options.map(this.MakeItem)}</select>
                </div>
              </div>


              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Country')}</label>
                  <select
                    value={country}
                    disabled={continent == '' ? true : false}
                    required
                    className="form-control"
                    onChange={(event) => {
                      this.setState({country: event.target.value, state: '', city: '', zip: '', address: ''});

                    }
                    }>
                    <option value="">{this.props.t('Choose')}</option>
                    {this.state.country_options.map(this.MakeItem)}</select>
                </div>
              </div>


              <div className="col-3" style={{display: display_style}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Find on map')}</label>
                  <button
                    className="btn btn-primary btn-sm"
                    disabled={country == '' ? true : false}
                    onClick={(event) => {
                      event.preventDefault();

                      if (this.state.show_map_container === true) {
                        this.setState({show_map_container: false, overflowY: 'hidden'});
                      } else {
                        this.setState({show_map_container: true, overflowY: 'auto'});
                      }

                    }
                    }
                  >
                    {this.props.t('Show map')} <i className="fa fa-map-marker"></i>
                  </button>
                </div>
              </div>


              <div className="col-3" style={{display: display_style}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Branch name')}</label>
                  <input
                    type="text"

                    className="form-control"
                    value={branch_name}
                    placeholder={this.props.t("Enter the name")}
                    onChange={(event) =>
                      this.setState({branch_name: event.target.value})
                    }
                  />
                </div>
              </div>


            </div>


            {address_rows}


          </div>
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <button className="btn btn-success">{this.props.t('Save')}</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CompanyAdd.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(CompanyAdd);

