"use strict";


import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";


import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";


import UIOptionT from "../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutHT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";

import UIComponentButtonT from "../../../../../../../Interface/UI/Component/Button/UIComponentButtonT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentBaseConfigT from "../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";

import UIComponentButtonConfigT from "../../../../../../../Type/UI/Component/Button/UIComponentButtonConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import TranslatorT from "../../../../../../../Interface/Translator/TranslatorT.mjs";


import GTSUIComponentSelectT from "../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";


import GTSUIModelSaleFormAddPrimaryGeneralFormT from "./Primary/GTSUIModelSaleFormAddPrimaryGeneralFormT.mjs";

import GTSUIModelSaleFormAddPrimarySaleFormT from "./Primary/GTSUIModelSaleFormAddPrimarySaleFormT.mjs";

import GTSUIModelSaleFormAddPrimaryOutinvoiceFormT from "./Primary/GTSUIModelSaleFormAddPrimaryOutinvoiceFormT.mjs"; 

import GTSUIModelSaleFormAddPrimarySupplylistFormT from "./Primary/GTSUIModelSaleFormAddPrimarySupplylistFormT.mjs";


import GTSUIModelSaleFormAddItemFormT from "./Item/GTSUIModelSaleFormAddItemFormT.mjs"; //TODO SecondaryForm



const GTSUIModelSaleFormAddT = class GTSUIModelSaleFormAddT extends UIComponentLayoutVT {
	
	#T = null;
	
	#UIComponentLabelGeneralForm = null;
	#GTSUIModelSaleFormAddPrimaryGeneralForm = null;
	
	#UIComponentLabelSaleForm = null;
	#GTSUIModelSaleFormAddPrimarySaleForm = null;
	
	#UIComponentLabelOutinvoiceForm = null;
	#GTSUIModelSaleFormAddPrimaryOutinvoiceForm = null;
	
	#UIComponentLabelSupplylistForm = null;
	#GTSUIModelSaleFormAddPrimarySupplylistForm = null;
	
	#UIComponentLabelItemForm = null;
	#GTSUIModelSaleFormAddSecondaryItemButtonAdd = null;
	#GTSUIModelSaleFormAddSecondaryItemForm = null;
	
	#GTSUIModelSaleFormAddSecondaryButtonCreate = null;
	#GTSUIModelSaleFormAddSecondaryButtonReset = null;
	
	constructor( 
		Locale,
		
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre,
	
		UIOptionMapCompany,
		CompanyDefault,
		
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToMethodDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContractNumber,
		ContractNumberDefault,
		
		ReceiptNumberDefault,
		
		ReceiptDateDefault,
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault,
		
		
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault,
		
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault,
		
		UIOptionMapAdditionalText,
		AdditionalTextDefault,
	
		ExcVATDefault,
		VATDefault,
		IncVATDefault,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault,
		
		ContractNumberModelOptionMap,
		OutinvoiceModelOptionMap,
		
		OutinvoicePrepareJSONValue,
		AdditionalTextModelOptionMap,
			
		CompanyModelOptionMap,
		CurrencyCustomOptionMap,
		
		SaleDateDefault,
		
		OrderNumberDefault,
		OrderDateDefault,
		DeliveryAddressDefault
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"30px",
			
			new UIStateMapT( [
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
			] )
		);
		
		super(
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		//General
		
		this.#GeneralFormLabelInit( );
		
		this.#GeneralFormInit(
			Locale
		);
		
		//Sale
		
		this.#SaleFormLabelInit( );
		
		this.#SaleFormInit(
			Locale,
		
			UIOptionMapCompany,
			CompanyDefault,
		
			UIOptionMapCurrency,
			CurrencyDefault,
			
			UIOptionMapContractNumber,
			ContractNumberDefault,
			
			CompanyModelOptionMap,
			CurrencyCustomOptionMap,
			ContractNumberModelOptionMap,
		
			SaleDateDefault,
			OrderNumberDefault,
			OrderDateDefault,
			DeliveryAddressDefault
		);
		
		//Outinvoice
		
		this.#OutinvoiceFormLabelInit( );
		
		this.#OutinvoiceFormInit( //TODO
			Locale,
			
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			//UIOptionMapCompany,
			//CompanyDefault,
		
			UIOptionMapPaymentMethod,
			PaymentMethodDefault,
			
			UIOptionMapPayTo,
			PayToMethodDefault,
		
			//UIOptionMapCurrency,
			//CurrencyDefault,
		
			//UIOptionMapContractNumber,
			//ContractNumberDefault,
			
			ReceiptNumberDefault,
			
			ReceiptDateDefault,
			IssueDateDefault,
			DueDateDefault,
			TaxableDateDefault,
			PaidDateDefault,
			
			ConstantSymbolDefault,
			SpecificSymbolDefault,
			NoteDefault,
			
			UIOptionMapOutinvoiceType,
			OutinvoiceTypeDefault,
			
			UIOptionMapPreInvoice,
			PreInvoiceDefault,
			
			UIOptionMapPostInvoice,
			PostInvoiceDefault,
			
			UIOptionMapAdditionalText,
			AdditionalTextDefault,
		
			ExcVATDefault,
			VATDefault,
			IncVATDefault,
			
			//ContractNumberModelOptionMap,
			OutinvoiceModelOptionMap,
			OutinvoicePrepareJSONValue,
			AdditionalTextModelOptionMap
		);
		
		//Item
		
		this.#ItemFormLabelAndButtonInit( 
			Locale
		);
		
		//this.#ItemFormLabelInit( );
		
		//this.#ItemButtonAddInit(
		//	Locale
		//);
		
		this.#ItemFormInit(
			Locale,
			
			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
			
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		//Supplylist
		
		this.#SupplylistFormLabelInit( );
		
		this.#SupplylistFormInit(
			Locale
		);
		
		//Buttons
		
		this.#ButtonCreateAndResetInit(
			Locale
		);
		
	}
	
	OutinvoicePrepareJSONValueSet(
		PrepareJSONValue
	){
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.PrepareJSONValueSet( PrepareJSONValue );
		
	}
	
	//General
	
	#GeneralFormLabelInit(
		
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelGeneralForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "General" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#UIComponentLabelGeneralForm 
		);
		
		super.ElementSet( 0, UIComponentLayoutElement );
		
	}
	
	#GeneralFormInit(
		Locale
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelSaleFormAddPrimaryGeneralForm = new GTSUIModelSaleFormAddPrimaryGeneralFormT(
			Locale
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddPrimaryGeneralForm 
		);
		
		super.ElementSet( 1, UIComponentLayoutElement );
		
	}
	
	//Sale

	#SaleFormLabelInit(
		
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelSaleForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Sale" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#UIComponentLabelSaleForm 
		);
		
		super.ElementSet( 2, UIComponentLayoutElement );
		
	}
	
	#SaleFormInit(
		Locale,
		
		UIOptionMapCompany,
		CompanyDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContractNumber,
		ContractNumberDefault,
		
		CompanyModelOptionMap,
		CurrencyCustomOptionMap,
		ContractNumberModelOptionMap,
		
		SaleDateDefault,
		OrderNumberDefault,
		OrderDateDefault,
		DeliveryAddressDefault,
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] )
			)
		);
		
		this.#GTSUIModelSaleFormAddPrimarySaleForm = new GTSUIModelSaleFormAddPrimarySaleFormT(
			Locale,
		
			UIOptionMapCompany,
			CompanyDefault,
		
			UIOptionMapCurrency,
			CurrencyDefault,
			
			UIOptionMapContractNumber,
			ContractNumberDefault,
			
			SaleDateDefault,
			
			OrderNumberDefault,
			OrderDateDefault,
			DeliveryAddressDefault,
			
			CompanyModelOptionMap,
			CurrencyCustomOptionMap,
			ContractNumberModelOptionMap,
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddPrimarySaleForm 
		);
		
		super.ElementSet( 3, UIComponentLayoutElement );
		
	}
	
	//Outinvoice
	
	#OutinvoiceFormLabelInit(
		
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelOutinvoiceForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Invoice" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#UIComponentLabelOutinvoiceForm 
		);
		
		super.ElementSet( 4, UIComponentLayoutElement );
		
	}
	
	#OutinvoiceFormInit( 
		Locale,
		
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre,
		
		//UIOptionMapCompany,
		//CompanyDefault,
	
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToMethodDefault,
	
		//UIOptionMapCurrency,
		//CurrencyDefault,
		
		//UIOptionMapContractNumber,
		//ContractNumberDefault,
		
		ReceiptNumberDefault,
		
		ReceiptDateDefault,
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault,
		
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault,
		
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault,
		
		UIOptionMapAdditionalText,
		AdditionalTextDefault,
	
		ExcVATDefault,
		VATDefault,
		IncVATDefault,
		
		//ContractNumberModelOptionMap,
		OutinvoiceModelOptionMap,
		OutinvoicePrepareJSONValue,
		AdditionalTextModelOptionMap
	){
		
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm = new GTSUIModelSaleFormAddPrimaryOutinvoiceFormT(
			Locale,
		
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			//UIOptionMapCompany,
			//CompanyDefault,
		
			UIOptionMapPaymentMethod,
			PaymentMethodDefault,
			
			UIOptionMapPayTo,
			PayToMethodDefault,
		
			//UIOptionMapCurrency,
			//CurrencyDefault,
			
			ReceiptNumberDefault,
			
			ReceiptDateDefault,
			IssueDateDefault,
			DueDateDefault,
			TaxableDateDefault,
			PaidDateDefault,
			
			ConstantSymbolDefault,
			SpecificSymbolDefault,
			NoteDefault,
			
			UIOptionMapOutinvoiceType,
			OutinvoiceTypeDefault,
			
			UIOptionMapPreInvoice,
			PreInvoiceDefault,
			
			UIOptionMapPostInvoice,
			PostInvoiceDefault,
			
			UIOptionMapAdditionalText,
			AdditionalTextDefault,
		
			ExcVATDefault,
			VATDefault,
			IncVATDefault,
			
			OutinvoiceModelOptionMap,
			OutinvoicePrepareJSONValue,
			AdditionalTextModelOptionMap
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm 
		);
		
		super.ElementSet( 5, UIComponentLayoutElement );
		
	}
	
	//Supplylist
	
	#SupplylistFormLabelInit(
		
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelSupplylistForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Supply list" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#UIComponentLabelSupplylistForm 
		);
		
		super.ElementSet( 8, UIComponentLayoutElement );
		
	}
	
	#SupplylistFormInit(
		Locale
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelSaleFormAddPrimarySupplylistForm = new GTSUIModelSaleFormAddPrimarySupplylistFormT(
			Locale
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddPrimarySupplylistForm 
		);
		
		super.ElementSet( 9, UIComponentLayoutElement );
		
	}
	
	//Item
	
	#ItemFormLabelAndButtonInit(
		Locale
	){
		
		//Label
		
		let UIComponentLayoutElementLabel = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
							new UIStyleT( "line-height", "60px" )
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelItemForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Items" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElementLabel.DataSet( 
			this.#UIComponentLabelItemForm 
		);
		
		//Button
		
		let UIComponentLayoutElementButton = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"+",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "27px" )
						] )
					)
				] ),
				null
			)
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd.ChildSet( 0, UIComponentLabel );
		
		UIComponentLayoutElementButton.DataSet( 
			this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd 
		);
		
		//Elements
		
		let UIComponentLayoutHConfig = new UIComponentLayoutHConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutHT",	
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
		);
		
		let UIComponentLayoutH = new UIComponentLayoutHT( 
			new UIElementT( ),
			UIComponentLayoutHConfig
		);
		
		let UIComponentLayoutHElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutHElement.DataSet( 
			UIComponentLayoutH
		);
		
		UIComponentLayoutH.ElementSet( 0, UIComponentLayoutElementLabel );
		
		UIComponentLayoutH.ElementSet( 1, UIComponentLayoutElementButton );
		
		super.ElementSet( 6, UIComponentLayoutHElement );
		
	}
	
	/*#ItemFormLabelInit(
		
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		
		this.#UIComponentLabelItemForm = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Items" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "black" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "24px" ),
							new UIStyleT( "font-weight", "700" )
						] )
					)
				] ),
				null
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#UIComponentLabelItemForm 
		);
		
		super.ElementSet( 8, UIComponentLayoutElement );
		
	}
	
	#ItemButtonAddInit(
		Locale
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"+",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "27px" )
						] )
					)
				] ),
				null
			)
		);
		
		//UIComponentLabel.Update( );
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd.ChildSet( 0, UIComponentLabel );
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd 
		);
		
		super.ElementSet( 9, UIComponentLayoutElement );
		
	}*/
	
	#ItemFormInit(
		Locale,
			
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
				
			)
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm = new GTSUIModelSaleFormAddItemFormT(
			Locale,
			
			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
			
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddSecondaryItemForm 
		);
		
		super.ElementSet( 7, UIComponentLayoutElement );
		
	}
	
	#ButtonCreateAndResetInit(
		Locale
	){
		
		//Create
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Save" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "20px" )
						] )
					)
				] )
			)
		);
		
		//UIComponentLabel.Update( );
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "green" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightgreen" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkgreen" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		//Reset
		
		let UIComponentLayoutElementReset = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] )
			)
		);
		
		let UIComponentLabelReset = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"Reset",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "20px" )
						] )
					)
				] )
			)
		);
		
		this.#GTSUIModelSaleFormAddSecondaryButtonReset = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		//Layout
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate.ChildSet( 0, UIComponentLabel );
		

		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelSaleFormAddSecondaryButtonCreate 
		);
		
		super.ElementSet( 10, UIComponentLayoutElement );
		
	}
	
	Start(
	
	){
		
		this.#UIComponentLabelGeneralForm.Start( );
		
		this.#GTSUIModelSaleFormAddPrimaryGeneralForm.Start( );
		
		
		this.#UIComponentLabelSaleForm.Start( );
		
		this.#GTSUIModelSaleFormAddPrimarySaleForm.Start( );
		
		
		this.#UIComponentLabelOutinvoiceForm.Start( );
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.Start( );
		
		
		this.#UIComponentLabelSupplylistForm.Start( );
		
		this.#GTSUIModelSaleFormAddPrimarySupplylistForm.Start( );
		
		
		this.#UIComponentLabelItemForm.Start( );
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd.Start( );
	
		this.#GTSUIModelSaleFormAddSecondaryItemForm.Start( );
		
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate.Start( );
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate.ActiveSet( false );
		
		
		super.Start( );
	
	}
	
	#OnChange(
		Values,
		IsValid
	){
		
		console.log( 
			"GTSUIModelSaleFormAddT.#OnChange", 
			Values, 
			IsValid 
		);
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate.ActiveSet( this.IsValidTotalGet( ) );
		
	}
	
	#OnSaleFormChange(
		Values,
		IsValid
	){
		
		console.log( 
			"GTSUIModelSaleFormAddT.#OnSaleFormChange", 
			Values, 
			IsValid 
		);
		
		this.#OnChange( 
			Values, 
			IsValid
		);
		
	}
	
	#OnOutinvoiceFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnOutinvoiceFormChange", Values, IsValid );
		
		this.#OnChange( 
			Values, 
			IsValid
		);
		
	}
	
	#OnItemFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnItemFormChange", Values, IsValid );
		
		
		let IsValidMain = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IsValidTotalGet( );
		
		let ValuesMain = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.ValueGet( );
		
		
		let VATMain = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.VATGet( );
		
		let VATValue = this.#GTSUIModelSaleFormAddSecondaryItemForm.VATTotal( );
		
		VATMain.ValueSet( VATValue, false );
		
		
		let ExcVATMain = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.ExcVATGet( );
		
		let ExcVATValue = this.#GTSUIModelSaleFormAddSecondaryItemForm.ExcVATTotal( );
		
		ExcVATMain.ValueSet( ExcVATValue, false );
		
		
		let IncVATMain = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IncVATGet( );
		
		let IncVATValue = this.#GTSUIModelSaleFormAddSecondaryItemForm.IncVATTotal( );
		
		IncVATMain.ValueSet( IncVATValue, false );
		
		
		
		this.#OnChange( 
			Values, 
			IsValid
		);

		
	}
	
	#OnGeneralFormNeedOutinvoiceChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnGeneralFormNeedOutinvoiceChange", Value, IsValid );
		
		if( Value === true ){
			
			this.#UIComponentLabelOutinvoiceForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddSecondaryItemForm.NeedOutinvoiceToggle( Value );
			
			//TODOReset
			
		} else {
			
			this.#UIComponentLabelOutinvoiceForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddSecondaryItemForm.NeedOutinvoiceToggle( Value );
			
			//TODOReset
			
		}
		
	}
	
	#OnGeneralFormNeedSupplylistChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnGeneralFormNeedSupplylistChange", Value, IsValid );

		
		if( Value === true ){
			
			this.#UIComponentLabelSupplylistForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddPrimarySupplylistForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
				
			);
			
			
			this.#GTSUIModelSaleFormAddSecondaryItemForm.NeedSupplylistToggle( Value );
			
			//TODOReset
			
		} else {
			
			this.#UIComponentLabelSupplylistForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddPrimarySupplylistForm.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
				
			);
			
			this.#GTSUIModelSaleFormAddSecondaryItemForm.NeedSupplylistToggle( Value );
			
			//TODOReset
			
		}
		
	}
	
	#OnSaleFormCompanyChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnSaleFormCompanyChange", Value, IsValid );
		
		this.#GTSUIModelSaleFormAddPrimarySaleForm.ContactNumberFilter( Value );
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.PreInvoiceFilter( Value );
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.PostInvoiceFilter( Value );
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.AdditionalTextFilter( Value );
		
	}
	
	#OnSaleFormCurrencyChange(
		Value,
		IsValid
	){
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.CNBByCurrency( Value );
		
	}
	
	#OnSaleFormContractChange(
		Value,
		IsValid
	){
		
		//this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.CNBByCurrency( Value );
		
	}
	
	#OnOutinvoiceFormTransferredVATChange(
		Value
	){
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm.TransferredVATToggle( Value );
		
		if( Value === false ){
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.VATGet( ).ValueSet( this.#GTSUIModelSaleFormAddSecondaryItemForm.VATTotal( ) );
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IncVATGet( ).ValueSet( this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.ExcVATGet( ).ValueGet( ) + this.#GTSUIModelSaleFormAddSecondaryItemForm.VATTotal( ) );
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.VATGet( ).StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		} else {
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.VATGet( ).ValueSet( 0, false );
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IncVATGet( ).ValueSet( this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.ExcVATGet( ).ValueGet( ) );
			
			this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.VATGet( ).StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
	}
	
	#OnSupplylistFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnSupplylistFormChange", Values, IsValid );
		
		this.#OnChange( 
			Values, 
			IsValid
		);
		
	}
	
	#OnGeneralFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnGeneralFormChange", Values, IsValid );
		
		this.#OnChange( 
			Values, 
			IsValid
		);
		
	}
	
	#OnButtonCreateClick(
	
	){
		
		console.log( "GTSUIModelSaleFormAddT.#OnButtonCreateClick" );
		
		
		if( super.EventExists( "Accept" ) === true ){
			
			let IsValidTotal = this.IsValidTotalGet( );
			
			let ValuesTotal = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Accept" );
			
			EventFn( 
				ValuesTotal,
				IsValidTotal
			);
			
		}
		
	}
	
	#OnItemButtonAddClick(  ){
				
		console.log( "GTSUIModelSaleFormAddT.#OnItemButtonAddClick" );
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm.LineAdd(
			false,
			null
		);
				
	}
	
	ItemFormReset(
		IsProductDefault = false,
		ProductType = null
	){
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm.Clear( );
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm.LineAdd(
			IsProductDefault,
			ProductType
		);
		
	}
	
	Listen( 
		OnAcceptFn,
		OnClearFn
	){
		
		super.EventSet( "Accept", OnAcceptFn );
		
		super.EventSet( "Clear", OnClearFn );
		
		this.#GTSUIModelSaleFormAddPrimaryGeneralForm.Listen(
			this.#OnGeneralFormNeedOutinvoiceChange.bind( this ),
			this.#OnGeneralFormNeedSupplylistChange.bind( this ),
			this.#OnGeneralFormChange.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddPrimarySaleForm.Listen(
			this.#OnSaleFormCompanyChange.bind( this ),
			this.#OnSaleFormCurrencyChange.bind( this ),
			this.#OnSaleFormContractChange.bind( this ),
			this.#OnSaleFormChange.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.Listen(
			this.#OnOutinvoiceFormTransferredVATChange.bind( this ),
			this.#OnOutinvoiceFormChange.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddPrimarySupplylistForm.Listen(
			this.#OnSupplylistFormChange.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemButtonAdd.Listen(
			this.#OnItemButtonAddClick.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddSecondaryItemForm.Listen( 
			this.#OnItemFormChange.bind( this )
		);
		
		this.#GTSUIModelSaleFormAddSecondaryButtonCreate.Listen(
			this.#OnButtonCreateClick.bind( this )
		);
		
	}
	
	IsValidTotalGet(
	
	){
		
		let IsValid = true;
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.IsValidTotalGet( ) === false ){
			
			IsValid = false;
			
		}
		
		if( this.#GTSUIModelSaleFormAddPrimarySaleForm.IsValidTotalGet( ) === false ){
			
			IsValid = false;
			
		}
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedOutinvoiceGet( ).ValueGet( ) === true ){
		
			if( this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IsValidTotalGet( ) === false ){
				
				IsValid = false;
				
			}
		
		}
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedSupplylistGet( ).ValueGet( ) === true ){
		
			if( this.#GTSUIModelSaleFormAddPrimarySupplylistForm.IsValidTotalGet( ) === false ){
				
				IsValid = false;
				
			}
			
		}
		
		if( this.#GTSUIModelSaleFormAddSecondaryItemForm.IsValidTotalGet( ) === false ){
			
			IsValid = false;
			
		}
		
		
		return IsValid;
		
	}
	
	IsValidGet( 
	
	){
		
		let JSONData = { };
		
		JSONData[ "general" ] = this.#GTSUIModelSaleFormAddPrimaryGeneralForm.IsValidGet( );
		
		JSONData[ "sale" ] = this.#GTSUIModelSaleFormAddPrimarySaleForm.IsValidGet( );
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedOutinvoiceGet( ).ValueGet( ) === true ){
		
			JSONData[ "outinvoice" ] = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.IsValidGet( );
		
		}
		
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedSupplylistGet( ).ValueGet( ) === true ){
		
			JSONData[ "supplylist" ] = this.#GTSUIModelSaleFormAddPrimarySupplylistForm.IsValidGet( );
		
		}
		
		JSONData[ "items" ] = this.#GTSUIModelSaleFormAddSecondaryItemForm.IsValidGet( );
		
		return JSONData;
		
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		JSONData[ "general" ] = this.#GTSUIModelSaleFormAddPrimaryGeneralForm.ValueGet( );
		
		JSONData[ "sale" ] = this.#GTSUIModelSaleFormAddPrimarySaleForm.ValueGet( );
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedOutinvoiceGet( ).ValueGet( ) === true ){
		
			JSONData[ "outinvoice" ] = this.#GTSUIModelSaleFormAddPrimaryOutinvoiceForm.ValueGet( );
		
		}
		
		if( this.#GTSUIModelSaleFormAddPrimaryGeneralForm.NeedSupplylistGet( ).ValueGet( ) === true ){
		
			JSONData[ "supplylist" ] = this.#GTSUIModelSaleFormAddPrimarySupplylistForm.ValueGet( );
		
		}
		
		JSONData[ "items" ] = this.#GTSUIModelSaleFormAddSecondaryItemForm.ValueGet( );
		
		return JSONData;
		
	}
	
	Render( 
	
	){
		
		return super.Render( );
		
	}
	
	
};


export default GTSUIModelSaleFormAddT;