import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select from 'react-select'
interface Props {
    onAdd: (data) => void;
}

class DocumentAdd extends Component<Props> {
    state = { name_cs: "", name_en: "",country:"", country_value:"", active: false };
    componentDidMount() {

    }


    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            this.setState({ name_cs: "", name_en: "",country:"", country_value:"", active: false });
        }
    }

    handleFormSubmit(event) {
        event.preventDefault();
        const { name_cs, name_en,country,  active } = this.state;
        const fileInput = document.querySelector('#template_document') ;
        var file=fileInput.files;

        if (file.length===0) {
            alert(this.props.t('Please choose the file.'));
        } else {
            file=file[0];
            this.props.onAdd({ name_cs, name_en, country, active, file });
        }

    }

    inputHandler(e) {

        if (e!=null) {
            var val = '';
            e.map((i) => val += i.value + '//');

            this.setState({ country: val.slice(0, -2), country_value:e })

        }
    }

    render() {
        const { name_cs, name_en, country_value, active } = this.state;
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('countries')).map((e)=>{
            optionsArray.push({value: e.id, label:e['name_'+locale]});
        });

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Active')}</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value="1"
                                    checked={active}
                                    onChange={(event) => this.setState({ active: event.target.value })}
                                />
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Country')}</label>
                                <Select

                                    isMulti
                                    name="country"
                                    value={country_value}
                                    onChange={this.inputHandler.bind(this)}
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={optionsArray} />
                            </div>
                        </div>

                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Template')}</label>
                                <input  id="template_document" name="template_document" type="file" />
                            </div>
                        </div>

                    </div>

                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
DocumentAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(DocumentAdd);

