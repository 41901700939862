import cs from "./cs.js";
import en from "./en.js";

class TranslatedMessages {

    static  getMessages(locale) {
        if (locale=='en') return en;
        if (locale=='cs') return cs;
    }
}

export default TranslatedMessages;