"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";


const ComponentLabelConfigT = class ComponentLabelConfigT extends ComponentBaseConfigT {
	
	#Text = null;
	
	constructor(
		Text,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);
		
		this.TextSet( Text );
		
	}
	
	Copy( ){
		
		return new ComponentLabelConfigT(
			this.TextGet( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	TextSet(
		Text
	){
		
		if( typeof( Text ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Text = Text;
		
	}
	
	TextGet(
	
	){

		return this.#Text;
	
	}
	
};


export default ComponentLabelConfigT;
