import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select';
import UrlService from "../../services/UrlService";


import UIElementT from "../../Infrastructure/UI/Element/UIElementT.mjs";

import HTTPClientT from "../../Infrastructure/Network/HTTP/Client/HTTPClientT.mjs";


import APIClientAbstractT from "../../Procedure/API/Client/_/APIClientAbstractT.mjs";


import APIClientT from "../../Interface/API/Client/APIClientT.mjs";

import UIComponentBooleanT from "../../Interface/UI/Component/Boolean/UIComponentBooleanT.mjs";

import UIComponentTextT from "../../Interface/UI/Component/Text/UIComponentTextT.mjs";


import HTTPClientConfigT from "../../Type/HTTP/Client/Config/HTTPClientConfigT.mjs";

import HTTPClientCookiesT from "../../Type/HTTP/Client/Cookie/HTTPClientCookiesT.mjs";

import APIFieldT from "../../Type/API/Field/APIFieldT.mjs";

import APIFieldListT from "../../Type/API/Field/APIFieldListT.mjs";

import APIValueT from "../../Type/API/Value/APIValueT.mjs";

import APIFieldValueT from "../../Type/API/FieldValue/APIFieldValueT.mjs";

import APIFieldValueMapT from "../../Type/API/FieldValue/APIFieldValueMapT.mjs";

import APIModelKeyT from "../../Type/API/Model/Key/APIModelKeyT.mjs";

import APIModelNameT from "../../Type/API/Model/Name/APIModelNameT.mjs";

import APIModelCallRequestElementT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementT.mjs";

import APIModelCallRequestElementMapT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementMapT.mjs";

import APIModelCallRequestT from "../../Type/API/Model/Call/Request/APIModelCallRequestT.mjs";

import APIModelCallResponseT from "../../Type/API/Model/Call/Response/APIModelCallResponseT.mjs";

import APIModelSchemaRequestJoinTypeT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinTypeT.mjs";

import APIModelSchemaRequestJoinDataT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinDataT.mjs";

import APIModelSchemaRequestModelOptionT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionT.mjs";

import APIModelSchemaRequestModelOptionMapT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionMapT.mjs";

import APIModelSchemaRequestCustomOptionT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionT.mjs";

import APIModelSchemaRequestCustomOptionMapT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionMapT.mjs";

import APIModelSchemaRequestChildrenMapT from "../../Type/API/Model/Schema/Request/Children/APIModelSchemaRequestChildrenMapT.mjs";

import APIModelSchemaRequestT from "../../Type/API/Model/Schema/Request/APIModelSchemaRequestT.mjs";

import APIModelSchemaResponseT from "../../Type/API/Model/Schema/Response/APIModelSchemaResponseT.mjs";


import APIModelInsertRequestJoinTypeT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinTypeT.mjs";

import APIModelInsertRequestJoinDataT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinDataT.mjs";

import APIModelInsertRequestChildrenListT from "../../Type/API/Model/Insert/Request/Children/APIModelInsertRequestChildrenListT.mjs";

import APIModelInsertRequestT from "../../Type/API/Model/Insert/Request/APIModelInsertRequestT.mjs";

import APIModelInsertResponseChildrenListT from "../../Type/API/Model/Insert/Response/Children/APIModelInsertResponseChildrenListT.mjs";

import APIModelInsertResponseT from "../../Type/API/Model/Insert/Response/APIModelInsertResponseT.mjs";


import UIComponentBooleanConfigT from "../../Type/UI/Component/Boolean/UIComponentBooleanConfigT.mjs";

import UIComponentTextConfigT from "../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIStyleT from "../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../Type/UI/Style/UIStyleMapT.mjs";

import KeyValueT from "../../Type/KeyValue/KeyValueT.mjs";

import KeyValueMapT from "../../Type/KeyValue/KeyValueMapT.mjs";


import GTSUIModelSaleFormAddT from "../../Interface/GTS/UI/Model/Sale/Form/Add/GTSUIModelSaleFormAddT.mjs";

const API_URL = UrlService.API_URL( );


function createCSSSelector (selector, style) {
  if (!document.styleSheets) return;
  if (document.getElementsByTagName('head').length == 0) return;

  var styleSheet,mediaType;

  if (document.styleSheets.length > 0) {
    for (var i = 0, l = document.styleSheets.length; i < l; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
	  }
	  if (document.styleSheets[i].href && !document.styleSheets[i].href.startsWith(window.location.origin)){
		continue;
	  }
	  
      var media = document.styleSheets[i].media;
      mediaType = typeof media;

      if (mediaType === 'string') {
        if (media === '' || (media.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }
      else if (mediaType=='object') {
        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }

      if (typeof styleSheet !== 'undefined') 
        break;
    }
  }

  if (typeof styleSheet === 'undefined') {
    var styleSheetElement = document.createElement('style');
    styleSheetElement.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

    for (i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      styleSheet = document.styleSheets[i];
    }

    mediaType = typeof styleSheet.media;
  }

  if (mediaType === 'string') {
    for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
      if(styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase()==selector.toLowerCase()) {
        styleSheet.rules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.addRule(selector,style);
  }
  else if (mediaType === 'object') {
    var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
    for (var i = 0; i < styleSheetLength; i++) {
      if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
        styleSheet.cssRules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
  }
}


createCSSSelector( ".UIComponentBooleanT", "display: block; height: 38px; width: 38px; background-color: white; background-repeat: no-repeat; background-size: 38px 38px" );

createCSSSelector( ".UIComponentBooleanT.True", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/6/68/Blue_checkbox-checked.svg\" )" );

createCSSSelector( ".UIComponentBooleanT.False", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/8/8b/Blue_checkbox-unchecked.svg\" )" );

//createCSSSelector( ".UIComponentBooleanT:hover",  )





let ValueSale = null;

let IsValidSale = false;



const ProductSaleT = class ProductSaleT {

	#Ref = null;

	#GTSUISaleFormAdd = null;
	
	#HTTPClientCookies = null;
	
	#APIClient = null;
	
	#APIModelCallRequestOutinvoicePrepare = null;
	
	#APIModelCallRequestSupplylistPrepare = null;
	
	#APIModelCallResponseOutinvoicePrepare = null;
	
	#APIModelCallResponseSupplylistPrepare = null;
	
	#APIModelSchemaRequestSale = null;
	
	#APIModelSchemaResponseSale = null;
	
	#APIModelSchemaRequestOutinvoice = null;
	
	#APIModelSchemaResponseOutinvoice = null;
	
	#APIModelSchemaRequestSupplylist = null;
	
	#APIModelSchemaResponseSupplylist = null;
	
	
	#ProductType = null;
	
	#TempOutinvoiceId = null;
	
	#TempSaleIds = [ ];
	
	#TempSaleId = [ ];
	
	#OnSaleClose = null;
	
	
	constructor(
		HTTPClientCookies,
		APIClient,
		UIComponent
	){
		
		if( ( HTTPClientCookies instanceof HTTPClientCookiesT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		if( ( APIClient instanceof APIClientAbstractT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		
		this.#HTTPClientCookies = HTTPClientCookies;
		
		this.#APIClient = APIClient;
	

		this.#APIModelCallRequestOutinvoicePrepare = new APIModelCallRequestT( 
			new APIModelCallRequestElementMapT( 
				[
					new APIModelKeyT( "outinvoice_number" )
				],
				[
					new APIModelCallRequestElementT(
						new APIFieldT( "outinvoices" ),
						new APIFieldT( "outinvoices_prepare" ),
						new APIFieldListT( [ 
							new APIFieldT( "invoice_number" ) 
						] ),
						new APIFieldListT( [ 
						
						] )
					)
				] 
			) 
		);
		
		this.#APIModelCallRequestSupplylistPrepare = new APIModelCallRequestT( 
			new APIModelCallRequestElementMapT( 
				[
					new APIModelKeyT( "supplylist_number" )
				],
				[
					new APIModelCallRequestElementT(
						new APIFieldT( "supplylists" ),
						new APIFieldT( "supplylists_prepare" ),
						new APIFieldListT( [ 
							new APIFieldT( "supplylist_number" ) 
						] ),
						new APIFieldListT( [ 
						
						] )
					)
				] 
			) 
		);


		this.#APIModelSchemaRequestSale = new APIModelSchemaRequestT(
			new APIModelNameT( "sale_mains" ),
			new APIModelSchemaRequestJoinTypeT( "NONE" ),
			new APIModelSchemaRequestJoinDataT( [ ] ),
			new APIModelSchemaRequestModelOptionMapT( 
				[
					//new APIModelKeyT( "users" ),
					new APIModelKeyT( "companies" ),
					new APIModelKeyT( "bank_accounts" ),
					new APIModelKeyT( "contracts" ),
					new APIModelKeyT( "outinvoices" ),
					new APIModelKeyT( "additional_texts" ),
					new APIModelKeyT( "product_types" )
				],
				[
					/*new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "users" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" )
						] )
					),*/
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "companies" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" ),
							new APIFieldT( "active" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "bank_accounts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "account_name" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "contracts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "contract_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "filled" ),
							new APIFieldT( "date" ),
							new APIFieldT( "address" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "invoice_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "pre_invoice" ),
							new APIFieldT( "post_invoice" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "additional_texts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "text" ),
							new APIFieldT( "company_ids" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "product_types" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestCustomOptionMapT( 
				[
					new APIModelKeyT( "outinvoice_type" ),
					new APIModelKeyT( "currency" ),
					new APIModelKeyT( "payment_method" )
				],
				[
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "outinvoice_type" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "currency" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "payment_method" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestChildrenMapT( 
				[
					new APIModelKeyT( "sales" )
				], 
				[
					new APIModelSchemaRequestT(
						new APIModelNameT( "sales" ),
						
						new APIModelSchemaRequestJoinTypeT( "ONE_TO_MANY" ),
						new APIModelSchemaRequestJoinDataT( [
							new APIFieldValueT(
								new APIFieldT( "ParentField" ),
								new APIValueT( "id" )
							),
							new APIFieldValueT(
								new APIFieldT( "ChildField" ),
								new APIValueT( "sale_id" )
							)
						] ),
						
						new APIModelSchemaRequestModelOptionMapT( 
							[
								//new APIModelKeyT( "users" ),
								new APIModelKeyT( "outinvoices" ),
								new APIModelKeyT( "outinvoice_item_names" )
							],
							[
								/*new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "users" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" )
									] )
								),*/
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoices" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "invoice_number" ),
										new APIFieldT( "company" ),
										new APIFieldT( "pre_invoice" ),
										new APIFieldT( "post_invoice" )
									] )
								),
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoice_item_names" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" ),
										new APIFieldT( "company_ids" ),
										new APIFieldT( "dph" ),
										new APIFieldT( "code" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestCustomOptionMapT( 
							[
								new APIModelKeyT( "unit" ),
								new APIModelKeyT( "vat_rate" )
							],
							[
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "unit" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								),
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "vat_rate" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestChildrenMapT( 
						
							[
							
							], 
							
							[
							
							]
						
						)
					
					)
				]
			)
			
		);

		this.#APIModelSchemaRequestOutinvoice = new APIModelSchemaRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelSchemaRequestJoinTypeT( "NONE" ),
			new APIModelSchemaRequestJoinDataT( [ ] ),
			new APIModelSchemaRequestModelOptionMapT( 
				[
					//new APIModelKeyT( "users" ),
					new APIModelKeyT( "companies" ),
					new APIModelKeyT( "bank_accounts" ),
					new APIModelKeyT( "contracts" ),
					new APIModelKeyT( "outinvoices" ),
					new APIModelKeyT( "additional_texts" ),
					new APIModelKeyT( "product_types" )
				],
				[
					/*new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "users" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" )
						] )
					),*/
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "companies" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" ),
							new APIFieldT( "active" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "bank_accounts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "account_name" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "contracts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "contract_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "filled" ),
							new APIFieldT( "date" ),
							new APIFieldT( "address" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "invoice_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "pre_invoice" ),
							new APIFieldT( "post_invoice" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "additional_texts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "text" ),
							new APIFieldT( "company_ids" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "product_types" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestCustomOptionMapT( 
				[
					new APIModelKeyT( "outinvoice_type" ),
					new APIModelKeyT( "currency" ),
					new APIModelKeyT( "payment_method" )
				],
				[
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "outinvoice_type" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "currency" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "payment_method" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestChildrenMapT( 
				[
					new APIModelKeyT( "outinvoice_items" )
				], 
				[
					new APIModelSchemaRequestT(
						new APIModelNameT( "outinvoice_items" ),
						
						new APIModelSchemaRequestJoinTypeT( "ONE_TO_MANY" ),
						new APIModelSchemaRequestJoinDataT( [
							new APIFieldValueT(
								new APIFieldT( "ParentField" ),
								new APIValueT( "id" )
							),
							new APIFieldValueT(
								new APIFieldT( "ChildField" ),
								new APIValueT( "outinvoice_id" )
							)
						] ),
						
						new APIModelSchemaRequestModelOptionMapT( 
							[
								//new APIModelKeyT( "users" ),
								new APIModelKeyT( "outinvoices" ),
								new APIModelKeyT( "outinvoice_item_names" )
							],
							[
								/*new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "users" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" )
									] )
								),*/
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoices" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "invoice_number" ),
										new APIFieldT( "company" ),
										new APIFieldT( "pre_invoice" ),
										new APIFieldT( "post_invoice" )
									] )
								),
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoice_item_names" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" ),
										new APIFieldT( "company_ids" ),
										new APIFieldT( "dph" ),
										new APIFieldT( "code" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestCustomOptionMapT( 
							[
								new APIModelKeyT( "unit" ),
								new APIModelKeyT( "vat_rate" )
							],
							[
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "unit" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								),
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "vat_rate" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestChildrenMapT( 
						
							[
							
							], 
							
							[
							
							]
						
						)
					
					)
				]
			)
			
		);

		this.#APIModelSchemaRequestSupplylist = new APIModelSchemaRequestT(
			new APIModelNameT( "supplylists" ),
			new APIModelSchemaRequestJoinTypeT( "NONE" ),
			new APIModelSchemaRequestJoinDataT( [ ] ),
			new APIModelSchemaRequestModelOptionMapT( 
				[
					//new APIModelKeyT( "users" ),
					new APIModelKeyT( "companies" ),
					new APIModelKeyT( "bank_accounts" ),
					new APIModelKeyT( "contracts" ),
					new APIModelKeyT( "outinvoices" ),
					new APIModelKeyT( "additional_texts" ),
					new APIModelKeyT( "product_types" )
				],
				[
					/*new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "users" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" )
						] )
					),*/
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "companies" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" ),
							new APIFieldT( "active" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "bank_accounts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "account_name" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "contracts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "contract_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "filled" ),
							new APIFieldT( "date" ),
							new APIFieldT( "address" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "invoice_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "pre_invoice" ),
							new APIFieldT( "post_invoice" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "additional_texts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "text" ),
							new APIFieldT( "company_ids" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "product_types" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestCustomOptionMapT( 
				[
					new APIModelKeyT( "outinvoice_type" ),
					new APIModelKeyT( "currency" ),
					new APIModelKeyT( "payment_method" )
				],
				[
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "outinvoice_type" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "currency" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "payment_method" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestChildrenMapT( 
				[
					new APIModelKeyT( "supplylist_items" )
				], 
				[
					new APIModelSchemaRequestT(
						new APIModelNameT( "supplylist_items" ),
						
						new APIModelSchemaRequestJoinTypeT( "ONE_TO_MANY" ),
						new APIModelSchemaRequestJoinDataT( [
							new APIFieldValueT(
								new APIFieldT( "ParentField" ),
								new APIValueT( "id" )
							),
							new APIFieldValueT(
								new APIFieldT( "ChildField" ),
								new APIValueT( "supplylist_id" )
							)
						] ),
						
						new APIModelSchemaRequestModelOptionMapT( 
							[
								//new APIModelKeyT( "users" ),
								new APIModelKeyT( "outinvoices" ),
								new APIModelKeyT( "outinvoice_item_names" )
							],
							[
								/*new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "users" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" )
									] )
								),*/
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoices" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "invoice_number" ),
										new APIFieldT( "company" ),
										new APIFieldT( "pre_invoice" ),
										new APIFieldT( "post_invoice" )
									] )
								),
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoice_item_names" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" ),
										new APIFieldT( "company_ids" ),
										new APIFieldT( "dph" ),
										new APIFieldT( "code" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestCustomOptionMapT( 
							[
								new APIModelKeyT( "unit" ),
								new APIModelKeyT( "vat_rate" )
							],
							[
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "unit" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								),
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "vat_rate" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestChildrenMapT( 
						
							[
							
							], 
							
							[
							
							]
						
						)
					
					)
				]
			)
			
		);
	
	}
	
	#Draw(
	
	){
	
	
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		let CurrentMonth = ( new Date( ) ).toISOString( ).substring( 0, 7 );
		
		let GTSUIOutinvoiceNumberValue = JSONValue[ CurrentMonth ];
		
		let GTSUIOutinvoiceNumberValuePre = JSONValue[ "ZAL-" + CurrentMonth ];
	
	
		let APIModelSchemaResponse = this.#APIModelSchemaResponseOutinvoice;
		
								
		let CustomOptionMap = APIModelSchemaResponse.CustomOptionMap( );
		
		let ModelOptionMap = APIModelSchemaResponse.ModelOptionMap( );
		
		
		let CompanyModelOptionMap = ModelOptionMap.Get( "companies" );
		
		let CompanyModelFilterCb = function(
			APIFieldValueMap
		){
			
			let APIFieldValue = APIFieldValueMap.Get( "active" );
				
			let APIValue = APIFieldValue.Value( );
			
			let Value = APIValue.Value( );
			
			
			let APIFieldValue2 = APIFieldValueMap.Get( "name" );
				
			let APIValue2 = APIFieldValue2.Value( );
			
			let Value2 = APIValue2.Value( );
			
			console.log( "CompanyModelFilterCb", Value2, Value );
			
			if( Boolean( Value ) === false ){
				
				return false;
				
			}
			
			return true;
			
		}
		
		CompanyModelOptionMap = CompanyModelOptionMap.Filter( CompanyModelFilterCb );
		
		let CompanyUIOptionMap = CompanyModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let PaymentMethodCustomOptionMap = CustomOptionMap.Get( "payment_method" );
		
		let PaymentMethodUIOptionMap = PaymentMethodCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let PayToModelOptionMap = ModelOptionMap.Get( "bank_accounts" );
		
		let PayToUIOptionMap = PayToModelOptionMap.ToUIOptionMap( "id", "account_name" );
		
		
		let CurrencyCustomOptionMap = CustomOptionMap.Get( "currency" );
		
		let CurrencyUIOptionMap = CurrencyCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let ContractModelOptionMap = ModelOptionMap.Get( "contracts" );
		
		let ContractUIOptionMap  = ContractModelOptionMap.ToUIOptionMap( "id", "contract_number" );
		
		
		let OutinvoiceTypeCustomOptionMap = CustomOptionMap.Get( "outinvoice_type" );
		
		let OutinvoiceTypeUIOptionMap = OutinvoiceTypeCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceModelOptionMap = ModelOptionMap.Get( "outinvoices" );
		
		let OutinvoiceUIOptionMap  = OutinvoiceModelOptionMap.ToUIOptionMap( "id", "invoice_number" );
		
		
		let AdditionalTextModelOptionMap = ModelOptionMap.Get( "additional_texts" );
		
		let AdditionalTextUIOptionMap  = AdditionalTextModelOptionMap.ToUIOptionMap( "id", "text" ); 
		
		
		let ProductTypeModelOptionMap = ModelOptionMap.Get( "product_types" );
		
		let ProductTypeUIOptionSort = function( UIOption1, UIOption2 ) {
		
			console.log( UIOption1, UIOption2 );
		
			if ( UIOption1.ValueGet( ) < UIOption2.ValueGet( ) ) {

				return -1;

			} else if ( UIOption2.ValueGet( ) < UIOption1.ValueGet( ) ) {
    
				return 1;

			}

			return 0;
  
		};
		
		let ProductTypeUIOptionMap = ProductTypeModelOptionMap.ToUIOptionMap( "id", "name_cs" ).Sort( ProductTypeUIOptionSort ); 
		
		
		
		
		
		let CallElementResponseChildrenMap = APIModelSchemaResponse.ChildrenMap( );
		
		let OutinvoiceItem = CallElementResponseChildrenMap.Get( "outinvoice_items" );
		
		
		let OutinvoiceItemModelOptionMap = OutinvoiceItem.ModelOptionMap( );
		
		let OutinvoiceItemCustomOptionMap = OutinvoiceItem.CustomOptionMap( );
		
		
		let OutinvoiceItemNameModelOptionMap = OutinvoiceItemModelOptionMap.Get( "outinvoice_item_names" );
		
		let OutinvoiceItemNameUIOptionMap = OutinvoiceItemNameModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let OutinvoiceItemUnitModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "unit" );
		
		let OutinvoiceItemUnitUIOptionMap = OutinvoiceItemUnitModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceItemVATRateModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "vat_rate" );
		
		let OutinvoiceItemVATRateUIOptionMap = OutinvoiceItemVATRateModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		this.#GTSUISaleFormAdd = new GTSUIModelSaleFormAddT( 
			"cs",
			
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			CompanyUIOptionMap,
			null,
			
			PaymentMethodUIOptionMap,
			null,
			
			PayToUIOptionMap,
			null,
			
			CurrencyUIOptionMap,
			null,

			ContractUIOptionMap, //UIOptionMapContract,
			null,//ContractDefault,
			
			"", //ReceiptNumberDefault
			
			null, //ReceiptDateDefault
			null, //IssueDateDefault
			null, //DueDateDefault
			null, //TaxableDateDefault
			null, //PaidDateDefault
			
			
			"", //ConstantSymbolDefault
			"", //SpecificSymbolDefault
			"", //NoteDefault
			
			OutinvoiceTypeUIOptionMap, //UIOptionMapOutinvoiceType,
			null, //OutinvoiceTypeDefault,
			
			OutinvoiceUIOptionMap,//UIOptionMapPreInvoice,
			null, //PreInvoiceDefault,
			
			OutinvoiceUIOptionMap, //UIOptionMapPostInvoice,
			null, //PostInvoiceDefault,
			
			AdditionalTextUIOptionMap, //UIOptionMapAdditionalText,
			"", //AdditionalTextDefault,
		
			0, //ExcVATDefault,
			0, //VATDefault,
			0, //IncVATDefault,
			
			ProductTypeUIOptionMap,
			null,
			
			OutinvoiceItemNameUIOptionMap,
			"",
			
			OutinvoiceItemUnitUIOptionMap,
			null,
			
			OutinvoiceItemVATRateUIOptionMap,
			"",
			
			ContractModelOptionMap,
			OutinvoiceModelOptionMap,
			JSONValue,
			AdditionalTextModelOptionMap,
	
			CompanyModelOptionMap,
			CurrencyCustomOptionMap,
			
			null,
			
			"",
			null,
			""
		);
		
		this.#GTSUISaleFormAdd.Start( );
		
		this.#GTSUISaleFormAdd.Listen( 
			this.#OnAccept.bind( this ),
			this.#OnClear.bind( this )
		);
		
		this.Reset(
			( this.#ProductType !== null ) ? true : false,
			( this.#ProductType !== null ) ? this.#ProductType.toString( ) : null
		);
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.appendChild( this.Render( ) );
		
		}
	
	}
	
	ProductTypeSet( 
		ProductType
	){
	
		this.#ProductType = ProductType;
		
		if( this.#GTSUISaleFormAdd !== null ){
		
			this.Reset(
				( this.#ProductType !== null ) ? true : false,
				( this.#ProductType !== null ) ? this.#ProductType.toString( ) : null
			);
		
		}
	
	}
	
	Render( ){
	
		if( this.#GTSUISaleFormAdd !== null ){
		
			return this.#GTSUISaleFormAdd.Render( );
		
		}
	
		return null;
	
	}
	
	RefSet( Ref ){
	
		this.#Ref = Ref;
	
	}
	
	#OnModelSchemaSupplylistResponse(
		APIModelSchemaResponseSupplylist
	){
	
		if( ( APIModelSchemaResponseSupplylist instanceof APIModelSchemaResponseT ) === false ){
									
			throw new Error( "Invalid response format" );
									
		}
		
		this.#APIModelSchemaResponseSupplylist = APIModelSchemaResponseSupplylist;
		
		
		this.#Draw( );
	
	}
	
	#OnModelSchemaOutinvoiceResponse(
		APIModelSchemaResponseOutinvoice
	){
	
		if( ( APIModelSchemaResponseOutinvoice instanceof APIModelSchemaResponseT ) === false ){
									
			throw new Error( "Invalid response format" );
									
		}
		
		this.#APIModelSchemaResponseOutinvoice = APIModelSchemaResponseOutinvoice;
		
		this.#APIClient.ModelSchema(
			this.#APIModelSchemaRequestSupplylist,
			this.#OnModelSchemaSupplylistResponse.bind( this ),
			this.#OnModelError.bind( this )
		);
	
	}
	
	#OnModelSchemaSaleResponse(
		APIModelSchemaResponseSale
	){
	
		if( ( APIModelSchemaResponseSale instanceof APIModelSchemaResponseT ) === false ){
									
			throw new Error( "Invalid response format" );
									
		}
		
		this.#APIModelSchemaResponseSale = APIModelSchemaResponseSale;
		
		this.#APIClient.ModelSchema(
			this.#APIModelSchemaRequestOutinvoice,
			this.#OnModelSchemaOutinvoiceResponse.bind( this ),
			this.#OnModelError.bind( this )
		);
	
	}
	
	#OnModelCallResponseOutinvoicePrepare( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;
		
		this.#APIClient.ModelCall(

			this.#APIModelCallRequestSupplylistPrepare,
			
			this.#OnModelCallResponseSupplylistPrepare.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	#OnModelCallResponseSupplylistPrepare( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseSupplylistPrepare = APIModelCallResponse;
		
		this.#APIClient.ModelSchema(
			this.#APIModelSchemaRequestSale,
			this.#OnModelSchemaSaleResponse.bind( this ),
			this.#OnModelError.bind( this )
		);
	
	}
	
	#OnModelError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	Start(
	
	){

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepare.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	Reset(
		IsProduct = false,
		ProductType = null
	){
	
		console.log( ProductType );
	
		this.#GTSUISaleFormAdd.ItemFormReset( 
			IsProduct, 
			ProductType 
		); 
	
	}

	#OnOutinvoiceInsertSuccess(
		APIModelInsertResponseOutinvoice
	){
	
		if( ( APIModelInsertResponseOutinvoice instanceof APIModelInsertResponseT ) === false ){
			
			throw new Error( "Invalid response format" );
			
		}
		
		let Data = APIModelInsertResponseOutinvoice.Data( );
		
		let FieldValue = Data.Get( "id" );
		
		let Value = FieldValue.Value( );
		
		this.#TempOutinvoiceId = Value.Value( ).toString( );
		
		console.log( APIModelInsertResponseOutinvoice, this.#TempOutinvoiceId );
		
		
		this.#SaleRequest( );
	
	}
	
	#OnSaleInsertSuccess(
		APIModelInsertResponseSale
	){
	
		let Values = this.#GTSUISaleFormAdd.ValueGet( );
		
		
		let Data = APIModelInsertResponseSale.Data( );
	
		let ChildrenList = APIModelInsertResponseSale.ChildrenList( );
		
		let FieldValue = Data.Get( "id" );
		
		let Value = FieldValue.Value( );
		
		this.#TempSaleId = Value.Value( );
		
		this.#TempSaleIds = [ ];
		
		
		for(
			let I = 0;
			I < ChildrenList.Count( );
			I++
		){
		
			let PreDataI = ChildrenList.Get( I );
			
			let DataI = PreDataI.Data( );
			
			let FieldValueI = DataI.Get( "id" );
			
			let ValueI = FieldValueI.Value( );
			
			this.#TempSaleIds.push( ValueI.Value( ) );
			
			console.log( ValueI );
		
		}
		
		
		console.log( this.#TempSaleIds, this.#TempSaleId );
		
		
		
		
		if( Values.general.need_supplylist === true ){
		
			this.#SupplylistRequest( );
		
		} else {
		
			this.ResetPrepare( );
		
			if( this.#OnSaleClose !== null ){
			
				this.#OnSaleClose( );
			
			}
		
		}
		
	}
	
	#OnSupplylistInsertSuccess(
		APIModelInsertResponseSupplylist
	){
	
		console.log( "onSale", APIModelInsertResponseSupplylist );
	
		this.ResetPrepare( );
	
		if( this.#OnSaleClose !== null ){
			
			this.#OnSaleClose( );
			
		}
		
	}
	
	#OnError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	#OutinvoiceRequest(
		
	){
	
		let Values = this.#GTSUISaleFormAdd.ValueGet( );
						
		let APIFieldBucketOutinvoiceItems = [ ];
		
		let OutinvoiceItemsOld = [ ];
		
		for(
			let I = 0;
			I < Values.items.length;
			I++
		){
		
			if( Values.items[ I ].in_outinvoice === false ){
			
				continue;
			
			}
		
			let OutinvoiceItem = {
				"description": Values.items[ I ].description,
				"product_type": ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].product_type ),
				"unit": Values.items[ I ].unit,
				"qty": Values.items[ I ].qty,
				"excvat": Values.items[ I ].excvat,
				"vat": Values.items[ I ].vat,
				"code": Values.items[ I ].code,
				"vat_rate": Values.items[ I ].vat_rate
			};
			
			
			OutinvoiceItemsOld.push( { 
				"description": Values.items[ I ].description,
				"product_type": ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].product_type ),
				"qty": Values.items[ I ].qty.toString( ),
				"unit": parseInt( Values.items[ I ].unit ),
				"unit_excvat": Values.items[ I ].excvat.toString( ),
				"unit_vat": Values.items[ I ].vat.toString( ),
				"unit_incvat": Values.items[ I ].excvat + Values.items[ I ].vat,
				"unit_value":{ "value": Values.items[ I ].unit.toString( ), "label": Values.items[ I ].unit_label }
			} );
		
			APIFieldBucketOutinvoiceItems.push( 
			
				new APIModelInsertRequestT(
					new APIModelNameT( "outinvoice_items" ),
					new APIModelInsertRequestJoinTypeT( "ONE_TO_MANY" ),
					new APIModelInsertRequestJoinDataT( [
						new APIFieldValueT(
							new APIFieldT( "ParentField" ),
							new APIValueT( "id" )
						),
						new APIFieldValueT(
							new APIFieldT( "ChildField" ),
							new APIValueT( "outinvoice_id" )
						)
					] ),
					APIFieldValueMapT.FromJSON( OutinvoiceItem ),
					new APIModelInsertRequestChildrenListT( [ 

					] )
				)
			);
		
		}
		
		let Outinvoice = {
			"company": Values.sale.company,
			"currency": Values.sale.currency,
			"contract": Values.sale.contract,
			"paid_date": Values.outinvoice.paid_date,
			"invoice_number": Values.outinvoice.invoice_number,
			"invoice_excvat": Values.outinvoice.invoice_excvat,
			"invoice_vat": Values.outinvoice.invoice_vat,
			"invoice_incvat": Values.outinvoice.invoice_incvat,
			"invoice_rate": 0, // TODO Fixes in all other places where it will be needed
			"invoice_issue": Values.outinvoice.invoice_issue,
			"invoice_due": Values.outinvoice.invoice_due,
			"invoice_taxable": Values.outinvoice.invoice_taxable,
			"invoice_type": null,
			"payment_method": Values.outinvoice.payment_method,
			"pay_to": Values.outinvoice.pay_to,
			"note": Values.outinvoice.note,
			"invoice_file": null,
			"payment_type": null,
			"beneficiary_note": null,
			"variable_symbol": Values.outinvoice.invoice_number,
			"constant_symbol": Values.outinvoice.constant_symbol,
			"specific_symbol": Values.outinvoice.specific_symbol,
			"invoice_items": JSON.stringify( OutinvoiceItemsOld ),
			"invoice_excvat_kc": null, //TODO Fix on server side where it will be needed
			"receipt_number": Values.outinvoice.receipt_number,
			"receipt_date": Values.outinvoice.receipt_date,
			"odpad": null,
			"additional_text": Values.outinvoice.additional_text,
			"transferred_vat": Values.outinvoice.transferred_vat,
			"cnb": Values.outinvoice.cnb,
			"pre_invoice": Values.outinvoice.pre_invoice,
			"post_invoice": Values.outinvoice.post_invoice,
			"outinvoice_type": Values.outinvoice.outinvoice_type
		};
		
		console.log( Outinvoice );
		
		let APIFieldBucketOutinvoice = APIFieldValueMapT.FromJSON( Outinvoice );
		
		let APIModelInsertRequestOutinvoice = new APIModelInsertRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelInsertRequestJoinTypeT( "NONE" ),
			new APIModelInsertRequestJoinDataT( [ ] ),
			APIFieldBucketOutinvoice,
			new APIModelInsertRequestChildrenListT( [ 
			
				...APIFieldBucketOutinvoiceItems
			
			] )
		);
		
		
		this.#APIClient.ModelInsert(

			APIModelInsertRequestOutinvoice,
			
			this.#OnOutinvoiceInsertSuccess.bind( this ),
			
			this.#OnError.bind( this )
			
		);
	
	}
	
	#SaleRequest(
	
	){
	
		let Values = this.#GTSUISaleFormAdd.ValueGet( );
		
		console.log( Values );
		
		let APIFieldBucketSaleItems = [ ];
		
		let TotalExcVAT = 0;
		
		let TotalVAT = 0;
		
		for(
			let I = 0;
			I < Values.items.length;
			I++
		){
		
			let ExcVATPerItem = Values.items[ I ].excvat * Values.items[ I ].qty;
			
			let VATPerItem = Values.items[ I ].vat * Values.items[ I ].qty;
			
			let IncVATPerItem = ExcVATPerItem + VATPerItem;
			
		
			let SaleItem = {
				"company": Values.sale.company,
				"product_type": ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].product_type ),
				"qty": Values.items[ I ].qty,
				"qty_gross":  ( ( Values.items[ I ].is_material === false ) ? null : Values.items[ I ].qty_gross ),
				"sale_date": Values.sale.sale_date,
				"currency": Values.sale.currency,
				"unit_excvat": Values.items[ I ].excvat,
				"unit_vat": Values.items[ I ].vat,
				"unit_incvat": Values.items[ I ].excvat + Values.items[ I ].vat,
				"unit": Values.items[ I ].unit,
				"excvat": ExcVATPerItem,
				"vat": VATPerItem,
				"incvat":  IncVATPerItem,
				"invoice": this.#TempOutinvoiceId,
				"order_number": Values.sale.order_number,
				"order_date": Values.sale.order_date,
				"contract_number": Values.sale.contract_number,
				"delivery_address": Values.sale.delivery_address,
				"contract": parseInt( Values.sale.contract )
			};
			
			console.log( SaleItem );
		
			APIFieldBucketSaleItems.push( 
			
				new APIModelInsertRequestT(
					new APIModelNameT( "sales" ),
					new APIModelInsertRequestJoinTypeT( "ONE_TO_MANY" ),
					new APIModelInsertRequestJoinDataT( [
						new APIFieldValueT(
							new APIFieldT( "ParentField" ),
							new APIValueT( "id" )
						),
						new APIFieldValueT(
							new APIFieldT( "ChildField" ),
							new APIValueT( "sale_id" )
						)
					] ),
					APIFieldValueMapT.FromJSON( SaleItem ),
					new APIModelInsertRequestChildrenListT( [ 

					] )
				)
				
			);
			
			TotalExcVAT = TotalExcVAT + ExcVATPerItem;
			
			TotalVAT = TotalVAT + VATPerItem;
		
		}
		
		let Sale = {
			"company": Values.sale.company,
			"sale_date": Values.sale.sale_date,
			"currency": Values.sale.currency,
			"invoice": this.#TempOutinvoiceId,
			"order_number": null,
			"order_date": null,
			"contract_number": Values.sale.contract_number,
			"delivery_address": null,
			"contract": parseInt( Values.sale.contract ),
			"excvat": TotalExcVAT,
			"vat": TotalVAT,
			"incvat": TotalExcVAT + TotalVAT
		};
		
		console.log( Sale );
		
		let APIFieldBucketSale = APIFieldValueMapT.FromJSON( Sale );
		
		let APIModelInsertRequestSale = new APIModelInsertRequestT(
			new APIModelNameT( "sale_mains" ),
			new APIModelInsertRequestJoinTypeT( "NONE" ),
			new APIModelInsertRequestJoinDataT( [ ] ),
			APIFieldBucketSale,
			new APIModelInsertRequestChildrenListT( [ 
			
				...APIFieldBucketSaleItems
			
			] )
		);
		
		
		this.#APIClient.ModelInsert(

			APIModelInsertRequestSale,
			
			this.#OnSaleInsertSuccess.bind( this ),
			
			this.#OnError.bind( this )
			
		);
	
	}
	
	#SupplylistRequest(
	
	){
		
		let Values = this.#GTSUISaleFormAdd.ValueGet( );
	
						
		let APIFieldBucketSupplylistItems = [ ];
		
		let SupplylistItemsOld = [ ];
		
		for(
			let I = 0;
			I < Values.items.length;
			I++
		){
		
			if( Values.items[ I ].in_supplylist === false ){
			
				continue;
			
			}
		
			let SupplylistItem = {
				"description": Values.items[ I ].description,
				"unit": Values.items[ I ].unit,
				"qty": Values.items[ I ].qty,
				"excvat": Values.items[ I ].excvat,
				"vat": Values.items[ I ].vat,
				"code": Values.items[ I ].code,
				"vat_rate": Values.items[ I ].vat_rate,
				
				"product_type": Values.items[ I ].product_type,
				"product_type_label": Values.items[ I ].product_type_label,
				"qty": Values.items[ I ].qty,
				"qty_gross": Values.items[ I ].qty_gross,
				"palette_number": Values.items[ I ].palette_number,
			};
			
			SupplylistItemsOld.push( {
				"palette_number": Values.items[ I ].palette_number.toString( ),
				"qty": Values.items[ I ].qty.toString( ),
				"qty_gross": Values.items[ I ].qty_gross.toString( ),
				"product_type": parseInt( Values.items[ I ].product_type ),
				"product_type_value":{"value": parseInt( Values.items[ I ].product_type ), "label" : Values.items[ I ].product_type_label }
			} );
		
			APIFieldBucketSupplylistItems.push( 
			
				new APIModelInsertRequestT(
					new APIModelNameT( "supplylist_items" ),
					new APIModelInsertRequestJoinTypeT( "ONE_TO_MANY" ),
					new APIModelInsertRequestJoinDataT( [
						new APIFieldValueT(
							new APIFieldT( "ParentField" ),
							new APIValueT( "id" )
						),
						new APIFieldValueT(
							new APIFieldT( "ChildField" ),
							new APIValueT( "supplylist_id" )
						)
					] ),
					APIFieldValueMapT.FromJSON( SupplylistItem ),
					new APIModelInsertRequestChildrenListT( [ 

					] )
				)
			);
		
		}
		
		let CallElementResponseElementMap = this.#APIModelCallResponseSupplylistPrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "supplylist_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "supplylist_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		let CurrentMonth = ( new Date( ) ).toISOString( ).substring( 0, 7 );
		
		let GTSUIOutinvoiceNumberValue = JSONValue[ CurrentMonth ];
		
		
		let Supplylist = {
			"invoice_id": this.#TempOutinvoiceId,
			"sale_ids": this.#TempSaleIds.join( "," ) ,
			"company_id": Values.sale.company,
			"supplylist_number": GTSUIOutinvoiceNumberValue,
			"spz1": Values.supplylist.n_1,
			"spz2": Values.supplylist.n_2,
			"name": Values.supplylist.driver,
			"address": null,
			"passport": null,
			"sale_id": this.#TempSaleId,
			"supplylist_items": JSON.stringify( SupplylistItemsOld )
		};
		
		let APIFieldBucketSupplylist = APIFieldValueMapT.FromJSON( Supplylist );
		
		let APIModelInsertRequestSupplylist = new APIModelInsertRequestT(
			new APIModelNameT( "supplylists" ),
			new APIModelInsertRequestJoinTypeT( "NONE" ),
			new APIModelInsertRequestJoinDataT( [ ] ),
			APIFieldBucketSupplylist,
			new APIModelInsertRequestChildrenListT( [ 
			
				...APIFieldBucketSupplylistItems
			
			] )
		);
		
		
		this.#APIClient.ModelInsert(

			APIModelInsertRequestSupplylist,
			
			this.#OnSupplylistInsertSuccess.bind( this ),
			
			this.#OnError.bind( this )
			
		);
	
	}
	
	#OnAccept(
		Values,
		IsValid
	){
	
		console.log( "ProductSaleT.#OnAccept", Values, IsValid );
		
		if( Values.general.need_outinvoice ){
		
			this.#OutinvoiceRequest( );
		
		} else {
		
			this.#SaleRequest( );
		
		}
	
	}
	
	#OnClear( ){
	
	}
	
	OnSaleCloseSet(
		OnSaleClose
	){
	
		this.#OnSaleClose = OnSaleClose;
	
	}
	
	
	//Restart
	
	#OnModelCallResponseOutinvoicePrepareReset( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;
		
		this.#APIClient.ModelCall(

			this.#APIModelCallRequestSupplylistPrepare,
			
			this.#OnModelCallResponseSupplylistPrepareReset.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
		
		
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		this.#GTSUISaleFormAdd.OutinvoicePrepareJSONValueSet( JSONValue );
	
	}
	
	#OnModelCallResponseSupplylistPrepareReset( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseSupplylistPrepare = APIModelCallResponse;
		
		
		//TODO Set new
	
	}
	
	ResetPrepare(
	
	){
	
		//TODO Reset

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepareReset.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
};


interface Props {
    onSell: () => void;
}


class SellProduct extends Component<Props> {

	#ProductSale = null;

    constructor( 
		props 
	) {
	
        super( props );
		
		
		let HTTPClientCookies = HTTPClientCookiesT.Parse( document.cookie );

		let APIClient = new APIClientT(
			API_URL,
			HTTPClientCookies.Get( "access_token" ) ? HTTPClientCookies.Get( "access_token" ).Value( ) : null,
			new HTTPClientT(
				new HTTPClientConfigT(
				
				)
			)
		);
		
		this.#ProductSale = new ProductSaleT(
			HTTPClientCookies,
			APIClient
		);
		
		this.#ProductSale.Start( );
		
		this.#ProductSale.ProductTypeSet( this.props.product_type );
		
		this.#ProductSale.OnSaleCloseSet( this.props.onSell );

    }

    componentDidMount(
	
	) {
	

    }
	
    componentDidUpdate(
		prevProps
	) {

        console.log( "sell_product.componentDidUpdate", this.props, prevProps );
		
		
		if( this.props.product_type !== prevProps.product_type ){
		
			this.#ProductSale.ProductTypeSet( this.props.product_type );
		
		}
		

    }
	

    render(
	
	) {

		return (
			<div style={ { height:"80vh", overflowY : "auto" } }>
				<div 
					ref={ 
						( Ref ) => {
							
							if( Ref !== null ){ 
							
								let Component = this.#ProductSale.Render( );
							
								if( Component !== null ){
							
									Ref.appendChild( Component );
									
								} else {
								
									this.#ProductSale.RefSet( Ref );
								
								}
								
							} else {
							
								this.#ProductSale.RefSet( Ref );
							
							}
							
						} 
				
					} 
					style={ { display:"table", width:"100%" } }
				>
						
				</div>
			</div>
		);

    }
}
SellProduct.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SellProduct);

