"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentTextDataConfigT = class UIComponentTextDataConfigT extends UIComponentBaseConfigT {
	
	#ValueDefault = null;
	
	#EmptyOnFocus = false;
	
	#RegExpMatch = null;
	#RegExpValidation = null;
	
	constructor(
		ValueDefault = "",
		EmptyOnFocus = false,
		RegExpMatch = ".*",
		RegExpValidation = ".*",
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.ValueDefaultSet( ValueDefault );
		
		this.EmptyOnFocusSet( EmptyOnFocus );
		
		this.RegExpMatchSet( RegExpMatch );
		
		this.RegExpValidationSet( RegExpValidation );
		
	}
	
	EmptyOnFocusGet( 
		
	){
		
		return this.#EmptyOnFocus;
		
	}
	
	EmptyOnFocusSet(
		EmptyOnFocus
	){
		
		if( typeof( EmptyOnFocus ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#EmptyOnFocus = EmptyOnFocus;
		
	}
	
	
	RegExpMatchSet( 
		RegExpMatch
	){
		
		if( RegExpMatch !== null ){
		
			if( typeof( RegExpMatch ) !== "string" ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		
		this.#RegExpMatch = RegExpMatch;
		
	}
	
	RegExpMatchGet( 
	
	){
		
		return this.#RegExpMatch;
		
	}
	
	RegExpValidationSet( 
		RegExpValidation
	){ 
	
		if( RegExpValidation !== null ){
	
			if( typeof( RegExpValidation ) !== "string" ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.#RegExpValidation = RegExpValidation;
		
	}
	
	RegExpValidationGet( 
	
	){
		
		return this.#RegExpValidation;
		
	}
	
	ValueDefaultGet( 
		
	){
		
		return this.#ValueDefault;
		
	}
	
	ValueDefaultSet(
		ValueDefault
	){
		
		if( typeof( ValueDefault ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#ValueDefault = ValueDefault;
		
	}
	
	Copy(
	
	){
		
		return new UIComponentTextDataConfigT(

			this.ValueDefaultGet( ),
			this.EmptyOnFocusGet( ),
			this.RegExpMatchGet( ),
			this.RegExpValidationGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentTextDataConfigT;