import { Component } from "react";


const InputInteger = class InputInteger extends Component { 
	
	#Input = null;
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	
	#Value = null;
	
	#RefCb = null;
	
	#Placeholder = null;
	#RegExpMatch = null;
	#RegExpValidation = null;

	constructor( props ){
		
		super( props );
		
		this.#Input = null;
		
		this.#Field = props.field;
		this.#Disabled = props.disabled;
		this.#OnChangeCb = props.on_change_cb;
		this.#Value = null;
		this.#RefCb = props.ref_cb;
		
		this.#Placeholder = props.placeholder;
		
		this.#RegExpMatch = props.regexp_match;
		
		this.#RegExpValidation = props.regexp_validation;
		
	}
	
	componentDidMount( ) {
		
		if( this.#RefCb ){
		
			this.#RefCb( this.#Input );
		
		}

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InputDouble.componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if( this.props.value !== this.#Value ){
		
			if( this.props.value === 0 ){
				
				this.#InputOnChange( "" );
				
			} else {
		
				this.#InputOnChange( this.props.value.toString( ) );
			
			}
			
		}
		
		if( this.props.disabled !== this.#Disabled ){
			
			this.#Disabled = this.props.disabled;
			
			
			this.#Input.disabled = this.#Disabled;
			
		}
		
	}
	
	componentWillUnmount( ){
		
	}
	
	#InputOnChange( Value ){
		
		console.log( "InputInteger.#InputOnChange", Value );
		
		
		let MatchDefaultValue = this.#MatchDefault( Value );
		
		if( MatchDefaultValue === null ){
			
			this.#Input.value = "";
			
			this.#Value = 0;
			
			return;
			
		}
		
		
		let MatchValue = this.#Match( MatchDefaultValue );
		
		if( MatchValue === null ){
			
			this.#Input.value = "";
			
			this.#Value = 0;
			
			return;
			
		}
		
		
		this.#Input.value = MatchValue;
		
		this.#Value = this.#Parse( MatchValue );
			
			
		this.#OnChangeCb( 
			this.#Field, 
			"integer", 
			this.#Value
		);
		
		
		/*let ValidationValue = this.#Validation( MatchValue );
		
		if( ValidationValue !== null ){
			
			this.#Set( ValidationValue );
			
			this.#OnChangeCb( 
				this.#Field, 
				"integer", 
				this.#Value,
				this.#Input
			);
			
		}*/
		
		
	}
	
	#MatchDefault( ValueString ){
		
		let ValueMatch = ValueString.match( new RegExp( "^\\-?([0-9]{0,8})?", "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Match( ValueString ){
		
		if( this.#RegExpMatch === null ){
			
			return ValueString;
			
		}
		
		let ValueMatch = ValueString.match( new RegExp( this.#RegExpMatch, "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Validation( ValueString ){
		
		if( this.#RegExpValidation === null ){
			
			return ValueString;
			
		}
		
		let ValueMatch = ValueString.match( new RegExp( this.#RegExpValidation, "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Parse( ValueString ){
		
		if( ValueString === "" ){
			
			return 0;
			
		}
		
		let ValueInt = parseInt( ValueString );
		
		if( isNaN( ValueInt ) ){
		
			throw new Error( "Invalid double value" );
		
		}
		
		return ValueInt;
		
	}
	
	#InputOnBlur( ){
		
	}
	
	render( ){
	
		return (
			<input
				type = "text"
				ref = { ( Ref ) => { this.#Input = Ref; } }
				disabled = { this.#Disabled }
				className = "form-control"
				placeholder = { this.#Placeholder }
				onBlur = {
					( ) => {
						
						this.#InputOnBlur( );
						
					}
				}
				onChange = { 
					( Ev ) => {
						
						Ev.preventDefault( );
						
						this.#InputOnChange( Ev.target.value );
					
					}
				}
			/>
								
		);
		
	};
	
};


export default InputInteger;