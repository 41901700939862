"use strict";


const EventT = class EventT {

	#Name = null;
	#EventCb = null;
	
	constructor(
		Name,
		EventCb
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( EventCb ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}

		
		this.#Name = Name;
		
		this.#EventCb = EventCb;
		
		
	}
	
	Name( ){
		
		return this.#Name;
		
	}
	
	EventCb( ){
		
		return this.#EventCb;
		
	}
	
};


export default EventT;