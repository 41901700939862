"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";


const UIComponentBooleanConfigT = class UIComponentBooleanConfigT extends UIComponentBaseConfigT {
	
	#ValueDefault = false;
	#ValueDefaultOnlyValid = null;
	
	constructor(
		ValueDefault = false,
		ValueDefaultOnlyValid = null,
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.ValueDefaultSet( ValueDefault );
		
		this.ValueDefaultOnlyValidSet( ValueDefaultOnlyValid );
		
	}
	
	ValueDefaultGet( 
		
	){
		
		return this.#ValueDefault;
		
	}
	
	ValueDefaultSet(
		ValueDefault
	){
		
		if( typeof( ValueDefault ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#ValueDefault = ValueDefault;
		
	}
	
	ValueDefaultOnlyValidGet( 
		
	){
		
		return this.#ValueDefaultOnlyValid;
		
	}
	
	ValueDefaultOnlyValidSet(
		ValueDefaultOnlyValid
	){
		
		if( ValueDefaultOnlyValid !== null ){
		
			if( typeof( ValueDefaultOnlyValid ) !== "boolean" ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.#ValueDefaultOnlyValid = ValueDefaultOnlyValid;
		
	}
	
	Copy(
	
	){
		
		return new UIComponentBooleanConfigT(
		
			this.ValueDefaultGet( ),
			
			this.ValueDefaultOnlyValidGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentBooleanConfigT;