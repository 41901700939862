import { Component } from "react";


const InputBoolean = class InputBoolean extends Component { 
	
	#Input = null;
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	
	#Value = false;
	
	#RefCb = null;
	
	#Cursor = null;

	constructor( props ){
		
		super( props );
		
		this.#Input = null;
		
		this.#Field = props.field;
		this.#Disabled = props.disabled;
		this.#OnChangeCb = props.on_change_cb;	
		this.#Value = false;
		this.#RefCb = props.ref_cb;
		
		this.#Cursor = props.cursor;
		
	}
	
	componentDidMount( ) {
		
		if( this.#RefCb ){
		
			this.#RefCb( this.#Input );
		
		}
		
		this.#Value = Boolean( this.props.value );
		
		this.#Input.checked = this.#Value;

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InputBoolean.componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if( this.props.value !== this.#Value ){
			
			this.#InputOnChange( this.props.value );
			
		}
		
		if( this.props.disabled !== this.#Disabled ){
			
			this.#Disabled = this.props.disabled;
			
			
			this.#Input.disabled = this.#Disabled;
			
		}
		
	}
	
	componentWillUnmount( ){
		
	}
	
	#InputOnChange( Checked ){
		
		console.log( "InputBoolean.#InputOnChange", Checked );
		
		
		this.#Value = Boolean( Checked );
		
		this.#Input.checked = this.#Value;
		
		
		this.#OnChangeCb(
			this.#Field, 
			"boolean", 
			this.#Value,
			this.#Input
		);
		
	}
	
	#InputOnLeave( ){
		
	}
	
	render( ){
	
		return (
			<input
				style = { { cursor: this.#Cursor, boxShadow: "none" } }
				type = "checkbox"
				ref = { ( Ref ) => { this.#Input = Ref; } }
				disabled = { this.#Disabled }
				className = "form-control"
				onMouseLeave = {
					( ) => {
						
						this.#InputOnLeave( );
						
					}
				}
				onClick = { 
					( Ev ) => {
						
						this.#InputOnChange( Ev.target.checked );
					
					}
				}
			/>
								
		);
		
	};
	
};


export default InputBoolean;
