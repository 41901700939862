
const InputSelectBaseSerializer = function( Value ){
	
	if( Value === null ){
		
		return null;
		
	}
	
	return Value.value.toString( );
		
};


export default InputSelectBaseSerializer;