"use strict";


const APIModelInsertRequestAbstractT = class APIModelInsertRequestAbstractT {
	
	constructor(

	){
		
	}
	
	ModelName( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	JoinType( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	JoinData(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	DataList(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	ChildrenMap(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default APIModelInsertRequestAbstractT;