import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import "react-datetime/css/react-datetime.css";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'moment/locale/cs';
import $ from "jquery";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import * as printParams from "../../pdfExport/PDFExportPanel";

import getDocDefinition from "../../pdfExport/docDefinition";



import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import RouteMap from "../../components/common/routemap";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Props {
    accept_route: (data) => void;
}

class SpeditionCard extends Component<Props> {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;

        super(props)

        this.state = {
            popupParent: document.querySelector('body'),
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: this.props.loadings,
            columnDefs: [
        {
            headerName: this.props.t('Company'),
            field: 'company',
        },
                {
                    headerName: this.props.t('Date'),
                    field: 'loading_date',
                    valueGetter: function(params) {
                        if (params.data && params.data.id) {
                            if (params.data.loading_date == null || params.data.loading_date == '') return '';
                            var d = new Date(params.data.loading_date);
                            return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                                d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                        }
                    }
                },
                {
                    headerName: this.props.t('Address'),
                    field: 'loading_address',
                },
                {
                    headerName: this.props.t('Contact person'),
                    field: 'contact_person',
                },
                {
                    headerName: this.props.t('Phone'),
                    field: 'phone',
                },
                {
                    headerName: this.props.t('Product type'),
                    field: 's_product_type',
                },
                {
                    headerName: this.props.t('Kg'),
                    field: 's_loading_qty',
                },
                {
                    headerName: this.props.t('Tara'),
                    field: 's_loading_container',
                },

           ],
            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

            },




        };

    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#speditionCardGrid').style.height = '';
    };

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            setTimeout(this.forcererender.bind(this), 1000);

                this.setState({
                    rowdata: this.props.loadings
                });


        }
    }
    handleFormSubmit() {

var loading_numbers=[];
this.props.loadings.forEach((e)=>{
    if (!loading_numbers.includes(e.loading_number)) loading_numbers.push(e.loading_number);
});
var data={distance:$('#mapdistance').text(), loading_numbers:loading_numbers}

        this.props.accept_route(data);

    }

    forcererender() {


       this.forceUpdate();
    }


    shouldComponentUpdate( nextProps, nextState ){



        if (this.props.show===false && nextProps.show===true) {

            return true
        } else {
            return false
        }

    }

    render() {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        var places=[];

        this.props.loadings.map((e)=>{
            places.push({latitude:parseFloat(e.loading_lat), longitude:parseFloat(e.loading_lng)});
        });

        /*
        places.push({latitude:parseFloat(50.16221887), longitude:parseFloat(14.0579977)});
        if (places.length===1) places.push({latitude:50.16221887, longitude:14.0579977});
*/


/*
        places=[
            {latitude: 25.8103146,longitude: -80.1751609},
            {latitude: 27.9947147,longitude: -82.5943645},
            {latitude: 28.4813018,longitude: -81.4387899}
        ]
*/


        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });
        return (
            <div style={{'height':'500px', 'overflowY':this.state.overflowY, 'overflowX':'hidden'}}>


                    <div className="container">


                        <div className="row">
                            <div className="col-12">
                                <div className="tool-buttons">

                                        <button type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => {

                                                    const docDefinition = getDocDefinition(
                                                        printParams,
                                                        this.gridApi,
                                                        this.gridColumnApi
                                                    );
                                                    pdfMake.createPdf(docDefinition).download();

                                                }}
                                        >{this.props.t('Download route')} <i className="fa  fa-download"></i>
                                        </button>
                                        <button type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={() => this.props.multiprint()}
                                        >{this.props.t('Print documents')} <i className="fa  fa-file-pdf"></i>
                                        </button>
                                    {roles.includes('Spedice') && !this.props.from_trip &&
                                    <button type="button"
                                            disabled={(JSON.parse(localStorage.getItem('my_user')).company==null || JSON.parse(localStorage.getItem('my_user')).company=='') ? true : false}
                                            className="btn btn-success btn-sm"
                                            onClick={() => this.handleFormSubmit()}
                                    >{this.props.t('Accept route')} <i className="fa  fa-check"></i>
                                    </button>
                                    }



                                    </div>
                                <div style={{ 'height': "100%", width:'100%' }} id="speditionCardGrid" className="ag-theme-alpine">
                        <AgGridReact
                            modules={this.state.modules}
                            rowData={this.state.rowdata}
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            onCellValueChanged={this.onCellCValueChanged}
                            onCellClicked={this.onCellClicked}
                            frameworkComponents={this.state.frameworkComponents}
                            suppressContextMenu={true}
                            localeText={this.state.localeText}
                        />
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{paddingTop:"10px"}}>
                            <RouteMap
                                googleMapURL={
                                    'https://maps.googleapis.com/maps/api/js?language='+locale+'&key=AIzaSyApcfdTTsrNPaQcWWo3T9iLWbUervbxpKE&libraries=geometry,drawing,places'
                                }
                                markers={places}
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: "300px", width: "100%"}}/>}
                                mapElement={<div style={{height: `100%`}}/>}
                                defaultCenter={{lat: 25.798939, lng: -80.291409}}
                                defaultZoom={7}
                            />

                        </div>
                    </div>

            </div>
        );
    }
}
SpeditionCard.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SpeditionCard);

