"use strict";


import UIStyleT from "../../../../Type/UI/Style/UIStyleT.mjs";

import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentLayoutElementT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLayoutVConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";



const UIComponentLayoutVT = class UIComponentLayoutVT extends UIComponentBaseT {

	#Value = false;
	
	#UIComponentLayoutVConfig = null;
	
	#Elements =  null;

	constructor(
		UIElement,
		UIComponentLayoutVConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentLayoutVConfig instanceof UIComponentLayoutVConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentLayoutVConfig
		);
		
		
		this.#Elements = new Map( );
		
		
		this.ConfigSet( UIComponentLayoutVConfig );
		
		
	
	}
	
	ConfigSet(
		UIComponentLayoutVConfig
	){
		
		if( ( UIComponentLayoutVConfig instanceof UIComponentLayoutVConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#UIComponentLayoutVConfig = UIComponentLayoutVConfig.Copy( );
		
		
		let UIStateMap = this.#UIComponentLayoutVConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		let UIStyleMap = UIState.StyleMapGet( );
		
		
		UIStyleMap.Set( new UIStyleT( "display", "flex" ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-direction", "column" ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-wrap", UIComponentLayoutVConfig.WrapGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "justify-content", UIComponentLayoutVConfig.JustifyContentGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "align-items", UIComponentLayoutVConfig.AlignItemsGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "align-content", UIComponentLayoutVConfig.AlignContentGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "row-gap",  UIComponentLayoutVConfig.RowGapGet( ) ) );
		
		
		console.log( UIComponentLayoutVConfig, this.#UIComponentLayoutVConfig );
		
		
		super.ConfigSet( this.#UIComponentLayoutVConfig );
		
		super.StyleMapSet( UIStyleMap );
		
	}
	
	ConfigGet(
	
	){
		
		return this.#UIComponentLayoutVConfig.Copy( );
		
	}
	
	ElementCount(
	
	){
			
		return super.ChildrenCount( );
		//return this.#Elements.size;
			
	}
	
	ElementGet( 
		Index
	){
		
		return super.ChildGet( Index );
		
		//return this.#Elements.get( Index );
		
	}
	
	ElementSet(
		Index,
		Element
	){
		
		console.log( "UIComponentLayoutVT.ElementSet", Element );
		
		
		if( ( Element instanceof UIComponentLayoutElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		//this.#Elements.set( Index, Element );
		
		this.ChildSet( Index, Element );
		
	}
	
	ElementRemove(
		Index
	){
		
		
		//this.#Elements.delete( Index );
		
		this.ChildRemove( Index );
		
	}
	
	Start( 
		
	){
		
	}
	
	Stop(
	
	){
		
	}
	
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Change" );
		
	}
	
};


export default UIComponentLayoutVT;
