"use strict";


import StyleMapT from "../../../Type/Style/StyleMapT.mjs";

import ComponentCheckListConfigT from "../../../Type/Component/CheckList/ComponentCheckListConfigT.mjs";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentBaseT from "../Base/ComponentBaseT.mjs";

import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


import { 
	ComponentT as ComponentT, 
	ComponentLayoutVDynamicT as ComponentLayoutVDynamicT, 
	ComponentLayoutElementT as ComponentLayoutElementT
} from "../ComponentInternalT.mjs";


const ComponentCheckListT = class ComponentCheckListT extends ComponentLayoutVDynamicT {

	#ComponentCheckListConfig = null;
	
	#OptionMap = null;
	
	#OptionFilteredMap = null;
	
	#IsNeedCheck = false;
	
	#Elements = null;
	
	#ChangeCb = null;
	
	#Value = 0;
	
	#FilterFn = null;
	
	constructor(
		GUIElement,
		ComponentCheckListConfig, //TODO Add ComponentLayoutElementSpec and IsNeedSpec
		IsMultiple,
		IsNeedCheck
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( ComponentCheckListConfig instanceof ComponentCheckListConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		if( typeof( IsMultiple ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( IsNeedCheck ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		super(
			GUIElement,
			ComponentCheckListConfig.Copy( )
		);
		
		
		this.#ComponentCheckListConfig = ComponentCheckListConfig.Copy( );
		
		
		this.#OptionMap = new Map( );
		
		this.#OptionFilteredMap = new Map( );
		
		this.#Elements = new Map( );
		
		
		this.#IsNeedCheck = IsNeedCheck;

	}
	
	Update( ){
		
		for( let I of this.#Elements.keys( ) ){
			
			this.#Elements.get( I ).Update( );
			
		}
		
		
		super.Update( );
		
	}
	
	#ElementOnClick( Ev ){
		
	}
	
	ListenParent(
		EventMap
	){
				
		if( EventMap.IsExists( "Change" ) ){
			
			this.#ChangeCb = EventMap.Get( "Change" );
			
		}
		
	}
	
	Listen( 
		EventBucket
	){
		
	}
	
	OptionMapSet( OptionMap ){
		
		console.log( "OptionMapSet", OptionMap );
		
		this.#OptionMap = OptionMap;
		
		this.#OptionFilteredMap = this.Filter( );
		
		
		this.#ElementsCreate( );
		
	}
	
	OptionMapGet( ){
	
		return this.#OptionMap;
	
	}
	
	FilterSet( FilterFn ){
		
		if( FilterFn !== null ){
		
			if( typeof( FilterFn ) !== "function" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		this.#FilterFn = FilterFn;
		
	}
	
	Filter( ){
		
		if( this.#FilterFn === null ){
		
			this.#OptionFilteredMap = this.#OptionMap;
			
		} else {
			
			this.#OptionFilteredMap = this.#FilterFn( this.#OptionMap );
			
		}
		
		this.#UpdateSelectVisible( );
		
	}
	
	#UpdateSelectVisible( 
		
	){
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let ChildValue = Child.ComponentTypedGet( ).IndexGet( );
			
			
			if( ChildValue === 0 ){
				
				continue;
				
			}
			
				
			if( this.#OptionFilteredMap.has( ChildValue.toString( ) ) === false ){
				
				if( Child.StyleGet( "display" ) !== "none" ){
						
					Child.StyleSet( "display", "none" );
				
				}
				
			} else {
		
				if( Child.StyleGet( "display" ) === "none" ){
						
					Child.StyleSet( "display", "block" );
				
				}
			
			}
			
		}
		
	}
	
	#Parse( ValueString ){
		
		if( ValueString === "" ){
			
			return "";
			
		}
		
		return ValueString;
		
	}
	
	Set( ValueString ){
		
		let ValueText = this.#Parse( ValueString );
		
		
		//this.#InputValue = ValueText;
		
		//this.#Input.value = ValueString;
		
	}
	
	//Select(){
	//}
	
	ValueSet( Value ){
		
		this.#Value = Value;
		
		//TODO UpdateStyles
		
	}
	
	ValueGet( ){
		
		return this.#Value;
		
	}
	
	#MakePseudoItem( ){ //TODO
		
	}
	
	#ElementRemove( 
		Key
	){
		
	}
	
	#ElementCreate( Key, Value ){
					
		console.log( "ComponentCheckListT.ElementCreate", Key, Value );
					
		let ComponentLayoutElement = new ComponentLayoutElementT(
			new GUIElementT( "li" ),
			this.#ComponentCheckListConfig.ComponentLayoutElementConfigGet( ).Copy( )
		);
		
		if( Key === 0 ){
		
			ComponentLayoutElement.StyleSet( "fontStyle", "italic" );
		
		}
		
		ComponentLayoutElement.InnerTextSet( Value );
		
		ComponentLayoutElement.IndexSet( Key );//TODO before call this function
		
		let Component = new ComponentT(
			"LayoutElement",
			ComponentLayoutElement
		);
		
		Component.Update( );
		
		ComponentLayoutElement.ListenParent(
			new EventMapT( [
				new EventT(
					"Click",
					function( Index ){
						
						console.log( "ComponentCheckListT.#OnClick", Index, this.#Value, this.#Elements );
						
						let Element = this.#Elements.get( Index );
						
						if( this.#IsNeedCheck === true ){
						
							Element.StyleMapSet( 
								this.#ComponentCheckListConfig.ComponentConfigCheckedGet( ).StyleMapGet( )
							);
							
							this.#Elements.get( this.#Value ).StyleMapSet( 
								this.#ComponentCheckListConfig.ComponentConfigUncheckedGet( ).StyleMapGet( )
							);
						
						}
						
						this.ValueSet( Index );
						
						if( this.#ChangeCb !== null ){
							
							if( Index === 0 ){
								
								this.#ChangeCb( 0, "", true );
								
							} else {
							
								console.log( Index, this.#OptionFilteredMap );
								
								let Key = this.#OptionFilteredMap.get( Index.toString( ) ).value;
			
								let Value = this.#OptionFilteredMap.get( Index.toString( ) ).label;
							
								this.#ChangeCb( Key, Value, true );
							
							}
						
						}
						
					}.bind( this )
				),
				new EventT(
					"Enter",
					function( Index ){
						
						let Element = this.#Elements.get( Index );
						
						if( ( this.#IsNeedCheck === false ) || ( this.#Value !== Index ) ){
							
							Element.StyleMapSet( 
								this.#ComponentCheckListConfig.ComponentConfigHoveredGet( ).StyleMapGet( )
							);
							
						} else {
						
							if( this.#Value !== Index ){
							
								Element.StyleMapSet( 
									this.#ComponentCheckListConfig.ComponentConfigHoveredGet( ).StyleMapGet( )
								);
								
							} else {
								
							}
						
						}
						
					}.bind( this )
				),
				new EventT(
					"Leave",
					function( Index ){
						
						console.log( "Leave", Index );
						
						let Element = this.#Elements.get( Index );
						
						if( ( this.#IsNeedCheck === false ) || ( this.#Value !== Index ) ){
							
							console.log( "Leave", Index, "Unchecked", this.#ComponentCheckListConfig.ComponentConfigUncheckedGet( ).StyleMapGet( ) );
						
							Element.StyleMapSet( 
								this.#ComponentCheckListConfig.ComponentConfigUncheckedGet( ).StyleMapGet( )
							);
						
						} else {
							
							console.log( "Leave", Index, "Unchecked" );
							
							Element.StyleMapSet( 
								this.#ComponentCheckListConfig.ComponentConfigCheckedGet( ).StyleMapGet( )
							);
							
						}
						
					}.bind( this )
				)
			] )
		);
		
		
		this.#Elements.set( Key, Component );
		
		this.ChildAppend( Component );
		
	}
	
	#ElementsClear( ){
		
	}

	#ElementsCreate( ){
		
		let OptionMap = this.#OptionMap;
		
		console.log( OptionMap );
		
		//If single
		let Key = "0";
		
		let Value = "{Prazdno}";
		
		this.#ElementCreate( parseInt( Key ), Value );
		
		
		for(
			let I of OptionMap.keys( )
		){
			
			let Key = OptionMap.get( I ).value;
		
			let Value = OptionMap.get( I ).label;
			
			this.#ElementCreate( parseInt( Key ), Value );
			
		}
		
		this.Filter( );
		
	}
	
	FilterVisible( 
		Value
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let ChildValue = Child.ComponentTypedGet( ).IndexGet( );
			
			
			if( ChildValue === 0 ){
				
				continue;
				
			}
			
		
			if( Value === "" ){
				
				if( this.#OptionFilteredMap.has( ChildValue.toString( ) ) === false ){
					
					if( Child.StyleGet( "display" ) !== "none" ){
						
						Child.StyleSet( "display", "none" );
					
					}
					
				} else {
				
					if( Child.StyleGet( "display" ) === "none" ){
						
						Child.StyleSet( "display", "block" );
					
					}
				
				}
				
			} else {
				
				if( this.#OptionFilteredMap.has( ChildValue.toString( ) ) === false ){
					
					if( Child.StyleGet( "display" ) !== "none" ){
						
						Child.StyleSet( "display", "none" );
				
					}
					
				} else {
			
					if( this.#OptionFilteredMap.get( ChildValue.toString( ) ).label.toLowerCase( ).indexOf( Value ) === -1 ){
					
						if( Child.StyleGet( "display" ) !== "none" ){
						
							Child.StyleSet( "display", "none" );
					
						}
						
					} else {
						
						if( Child.StyleGet( "display" ) === "none" ){
						
							Child.StyleSet( "display", "block" );
						
						}
						
					}
				
				}
			
			}
			
		}
		
	}
	
};


export default ComponentCheckListT;