import React, { Component } from "react";
import TabPill from "../common/tab/tabpill";

import Loadings from "../loadings";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
// import DragAndDrop from "../common/draganddrop";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
class TripCard extends Component {
    getTabs() {

        var tabs= {
            loading: {
                active: true,
                name: this.props.t('Loadings'),
                component: Loadings,

            }
        }

        return tabs;
    }

    state = {
        id:null,
        tmp:null,
        files: [],

        tabs: this.getTabs(),
    };


    getActiveTab() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        let newTabs = tabs;

        let activeTab=null;
        keys.forEach((key) => {
            if (newTabs[key].active) activeTab=newTabs[key].name
        });
        return activeTab
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            this.setState({id:this.props.id});
            this.setState({tmp:this.props.tmp});




            if (this.getActiveTab()===null) {

                this.enableTab('loadings');
            }


        }
    }

    enableTab(tabName: string) {
        const { tabs } = this.state;

        if (tabs[tabName] === undefined) {
            return true;
        }

        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
        });

        newTabs[tabName].active = true;
        this.setState({ tabs: newTabs });
    }

/*
    shouldComponentUpdate( nextProps, nextState ){
        console.log(this.props);
        console.log(nextProps);
        console.log(nextState);
        console.log(nextProps.show);
        console.log(nextProps.show);

        if (this.props.show===false && nextProps.show===true) {

            return true
        } else {
            return false
        }
    }
*/
    renderTabContent() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        return keys.map((tabName, index) => {
            const Component = tabs[tabName].component;
            return (
                <div
                    className={`tab-pane ${tabs[tabName].active ? "active" : "inactive"}`}
                    id={tabName}
                    key={index}
                >
                    <Component from_trip={true} company_id={this.state.id}  id={this.state.id} fromtab={true} show={this.props.show} tmp={this.state.tmp} />
                </div>
            );
        });
    }



    render() {
console.log(this.props);

        const { tabs } = this.state;

        return (


                <div className="card" style={{'width':'100%'}}>
                    <div className="card-header p-2">
                        <TabPill
                            tabs={tabs}
                            handleEnableTab={(tabName) => this.enableTab(tabName)}
                        />
                    </div>

                    <div className="card-body">
                        <div className="tab-content">{this.renderTabContent()}</div>
                    </div>
                </div>

        );
    }
}
TripCard.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(TripCard);
