import React from "react";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import OptionAdd from "./add.tsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import Modal from "../modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import SessionStorageService from "../../../services/SessionStorageService";
class Options  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            id:null,
            showOptionsModal:false,
            localeText: loctext,
            rowdata: null,
            columnDefs: [

                {
                    headerName: this.props.t('Company'),
                    field: 'company',
                    valueGetter:function(params) {

                        var locale = 'cs';
                        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
                        var value = '';
                        JSON.parse(localStorage.getItem('companies')).map((c) => {


                            if (String(c.id) === String(params.data.company) && String(c.company_type!='1')) {
                                value = c['name'];

                            }
                            return true;

                        });
                        return value;
                    }
                },
                {
                    headerName: this.props.t('Code'),
                    field: 'code',
                    editable:true
                },
                {
                    headerName: this.props.t('English name'),
                    field: 'name_en',
                    editable:true
                },
                {
                    headerName: this.props.t('Czech name'),
                    field: 'name_cs',
                    editable:true
                },
                {
                    headerName: this.props.t('German name'),
                    field: 'name_de',
                    editable:true
                },
                {
                    headerName: this.props.t('French name'),
                    field: 'name_fr',
                    editable:true
                },
                {
                    headerName: this.props.t('Italian name'),
                    field: 'name_it',
                    editable:true
                },
                {
                    headerName: this.props.t('Spanish name'),
                    field: 'name_es',
                    editable:true
                },
                {
                    headerName: this.props.t('Russian name'),
                    field: 'name_ru',
                    editable:true
                },
                {
                    headerName: this.props.t('Chinese name'),
                    field: 'name_cn',
                    editable:true
                },




            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,

                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
            }
        };
    }



    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {

            //  this.setDelId();
            if (this.props.id!==null && JSON.parse(localStorage.getItem('product_types_'+this.props.id)).company_names!=='' && JSON.parse(localStorage.getItem('product_types_'+this.props.id)).company_names!==null)  {
                this.setState({
                    rowdata:  JSON.parse(JSON.parse(localStorage.getItem('product_types_'+this.props.id)).company_names)
                });
                this.gridApi.redrawRows();
            }

            if (JSON.parse(localStorage.getItem('product_types_'+this.props.id)).company_names==='' || JSON.parse(localStorage.getItem('product_types_'+this.props.id)).company_names===null) this.setState({
                rowdata:  null
            });
        }
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        let items = this.gridApi.getColumnDefs();


        items[10] ={headerName:this.props.t('Delete'),
            cellRenderer:"DeleteRenderer",

            cellRendererParams: {
                gridType: "product_types_company_name",
                colid: this.props.id
            },
            suppressMenu: true,};


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#optionsGrid').style.height = '';
    };

    async handleAdd(data) {

        HttpService.put(UrlService.apiDomain()+'api/product_types/company_names/'+this.props.id, data)
            .then(res => {

                if (res.data.result==='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showOptionsModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.newoption]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});

                    res.data.entry.map((e)=>{
                        SessionStorageService.update('product_types', e);
                    })

                }

            })
            .catch((error) => {
                console.log(error);
            });


    }

    onCellCValueChanged = (event) => {
//console.log(event);
        HttpService.put(UrlService.apiDomain()+'api/product_types/company_names/'+this.props.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('product_types', res.data.entry);


            })
            .catch((error) => {
                console.log(error);
            })


    }

    render() {


        return (


            <div key={this.props.id} style={{ 'height': '100%', width:'100%' }} id="optionsGrid" className="ag-theme-alpine">
                <Modal
                    title={this.props.t('Add new')}
                    visibility={this.state.showOptionsModal}
                    size="lg"
                    onClose={() => this.setState({ showOptionsModal: !this.state.showOptionsModal })}
                >
                    <OptionAdd id={Date.now()} onAdd={(data) => this.handleAdd(data)} />
                </Modal>
                <div className="tool-buttons">
                    <button
                        className="btn btn-success btn-sm"
                        onClick={() => this.setState({ showOptionsModal: true })}
                    >
                        {this.props.t('Add new')} <i className="fa fa-plus"></i>
                    </button>
                </div>
                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}
                />
            </div>


        );
    }
}

Options.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Options);

