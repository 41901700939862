"use strict";


import EventMapT from "./EventMapT.mjs";


const EventObjectT = class EventObjectT {

	#Name = null;
	#EventMap = null;
	
	constructor(
		Name,
		EventMap
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( EventMap instanceof EventMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}

		
		this.#Name = Name;
		
		this.#EventMap = EventMap;
		
		
	}
	
	Name( ){
		
		return this.#Name;
		
	}
	
	EventMap( ){
		
		return this.#EventMap;
		
	}
	
};


export default EventObjectT;