import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import ColumnOptions from "../../../components/common/product_type_options";
import ProductTypeAdd from "./product_type_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import OptionsRenderer from "../../../aggrid/OptionsRenderer.js";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class ProductTypes extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        var product_types_types=JSON.parse(localStorage.getItem('product_types_types'));
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };



        super(props)
        var that=this;
		
		let columns = JSON.parse( localStorage.columns );
		
		let unit_options = [ ];
		
		for( let I = 0; I < columns.length; I++ ){
			
			if( columns[ I ].model === "product_types" ){
				
				if( columns[ I ].field === "unit" ){
					
					unit_options = JSON.parse( columns[ I ].data );
					
					break;
					
				}
				
			}
			
		}
		
        this.state = {
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            showOptions: false,
            showOptionsData: null,
            showOptionsColId: null,
            columnDefs: [
                {
                    headerName: this.props.t('Type'),
                    field: 'product_type',
                    editable: true,

                    checkboxSelection:true,


            cellEditor: 'agRichSelectCellEditor',


            cellEditorParams: {values: product_types_types.map(e => e['name_' + locale])},
            valueSetter: function (params) {
                        var values=JSON.parse(localStorage.getItem('product_types_types'));
                if (params.data && params.data.id) {
                    try {
                        params.data['product_type'] = values.find(refData => refData['name_' + locale] == params.newValue).id;
                        return true;
                    } catch (err) {
                        console.log(err);
                        return true;
                    }
                }
            },
            valueGetter: function (params) {
                var values=JSON.parse(localStorage.getItem('product_types_types'));
                if (params.data && params.data.id) {

                    if (params.data['product_type'] === null || params.data['product_type'] === '') return '';
                    try {

                        return values.find(refData => String(refData.id) === String(params.data['product_type']))['name_' + locale];
                    } catch (err) {

                        console.log(err);
                        return '';
                    }
                }
            }


                },
                {
                    headerName: this.props.t('Czech name'),
                    field: 'name_cs',
                    editable: true,

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('English name'),
                    field: 'name_en',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Company specific names'),
                    field: 'company_names',
                    cellRenderer:"OptionsRenderer",

                    cellRendererParams: {
                        type:'product_types',
                        openOptions: function(colId)  {

                            that.setState({showOptions: true, showOptionsColId: colId});
                        }
                    },
                    suppressMenu: true,
                },

                {
                    headerName: this.props.t('Czech name official'),
                    field: 'name_cs_official',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('English name official'),
                    field: 'name_en_official',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

                {
                    headerName: this.props.t('German name'),
                    field: 'name_de',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('French name'),
                    field: 'name_fr',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Italian name'),
                    field: 'name_it',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Spanish name'),
                    field: 'name_es',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Russian name'),
                    field: 'name_ru',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Chinese name'),
                    field: 'name_cn',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Code'),
                    field: 'code',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
				{
					headerName: this.props.t('Unit'),
                    field: 'unit',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator,
					
					cellEditor: 'agRichSelectCellEditor',


					cellEditorParams: {values: unit_options.map(e => e['name_' + locale])},
					valueSetter: function (params) {
								var values=unit_options;
						if (params.data && params.data.id) {
							try {
								params.data['unit'] = values.find(refData => refData['name_' + locale] == params.newValue).id;
								return true;
							} catch (err) {
								console.log(err);
								return true;
							}
						}
					},
					valueGetter: function (params) {
						
						console.log( params, unit_options );
						
						var values=unit_options;
						if (params.data && params.data.id) {

							if (params.data['unit'] === null || params.data['unit'] === '') return '';
							try {

								return values.find(refData => String(refData.id) === String(params.data['unit']))['name_' + locale];
							} catch (err) {

								console.log(err);
								return '';
							}
						}
					}
					
				},
                {
                    headerName: this.props.t('Salary'),
                    field: 'production_salary',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Active'),
                    field: 'active',
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                },
                {headerName:this.props.t('Delete'),cellRenderer:"DeleteRenderer",cellRendererParams: {
                    gridType: "product_types"
                },
                    suppressMenu: true,},
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                OptionsRenderer: OptionsRenderer
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/product_types')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/product_types/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('product_types', res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;
        HttpService.post(UrlService.apiDomain()+'api/product_types', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    SessionStorageService.add('product_types', res.data.entry);

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/product_types/' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                    SessionStorageService.remove_by_id('product_types', e.data.id);
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t('Product types')}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRows()}
                            >
                                {this.props.t('Delete')} <i className="fa fa-trash"></i>
                            </button>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>

                        <Modal
                            title={this.props.t('Company specific product types')}

                            visibility={this.state.showOptions}
                            size="lg"
                            onClose={() => this.setState({ showOptions: !this.state.showOptions })}
                        >
                            <ColumnOptions id={this.state.showOptionsColId} open_options={this.openOptions} />
                        </Modal>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <ProductTypeAdd onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

ProductTypes.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ProductTypes);

