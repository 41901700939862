import UrlService from "./UrlService";
import HttpService from "./HttpService";
import { AccessDB } from 'react-indexed-db';





class SessionStorageService {

    static remove(name) {

        const rx = new RegExp(name);
        var n = localStorage.length;
        while(n--) {
            var key = localStorage.key(n);
            if(rx.test(key)) {
                localStorage.removeItem(key);
            }
        }
    }


    static update(type, data) {
        localStorage.removeItem(type+'_'+data.id);
        localStorage.setItem(type+'_'+data.id, JSON.stringify(data));
        var arr=[];
        JSON.parse(localStorage.getItem(type)).map((e)=>{
            if (e.id==data.id) {
                arr.push(data)
            } else {
                arr.push(e);
            }
        });

        localStorage.removeItem(type);
        localStorage.setItem(type, JSON.stringify(arr));


    }

    static add(type, data) {
        localStorage.setItem(type+'_'+data.id, JSON.stringify(data));
        var arr=JSON.parse(localStorage.getItem(type));
        arr.push(data);
        localStorage.removeItem(type);
        localStorage.setItem(type, JSON.stringify(arr));
    }

    static remove_by_id(type, id) {
        localStorage.removeItem(type+"_"+id);
        var arr=[];
        JSON.parse(localStorage.getItem(type)).map((e)=>{
            if (e.id!=id) {
                arr.push(e);
            }
        });

        localStorage.removeItem(type);
        localStorage.setItem(type, JSON.stringify(arr));
    }



    static columns_permissions() {
        localStorage.removeItem('columns_permissions');
        HttpService.get(UrlService.apiDomain()+'api/columns_permissions')
            .then(res => {

                localStorage.setItem('columns_permissions', JSON.stringify(res.data));

            })
            .catch((error) => {
                console.log(error);
            });
    }


    static booleans() {

        localStorage.setItem('booleans',  JSON.stringify([{'name_en':'No', 'name_cs':'Ne', 'id':0}, {'name_en':'Yes', 'name_cs':'Ano', 'id':1}]));
        localStorage.setItem('booleans_active',  JSON.stringify([{'name_en':'No', 'name_cs':'Ne', 'id':0}, {'name_en':'Yes', 'name_cs':'Ano', 'id':1}]));

        localStorage.setItem('booleans_0',  JSON.stringify({'name_en':'No', 'name_cs':'Ne', 'id':0}));
        localStorage.setItem('booleans_1',  JSON.stringify({'name_en':'Yes', 'name_cs':'Ano', 'id':1}));


    }






    static get_data_lists() {
        HttpService.get(UrlService.apiDomain()+'api/users/get_data_lists')
            .then(res => {




                // TEST websql
                /*
                var db = openDatabase("my.db", '1.0', "My WebSQL Database", 2 * 1024 * 1024);


                $.get('./dumps/myDatabase.sql', function(response) {
                    // console.log("got db dump!", response);
                    var db = openDatabase('myDatabase', '1.0', 'myDatabase', 10000000);
                    processQuery(db, 2, response.split(';\n'), 'myDatabase');
                });

                    db.transaction(function (tx) {
                        tx.executeSql("CREATE TABLE IF NOT EXISTS people (id integer primary key, firstname text, lastname text)");
                        tx.executeSql("INSERT INTO people (firstname, lastname) VALUES (?,?)", ['dsd','dddd']);
                    });
*/







/*
                var db = openDatabase('myDatabase', '1.0', 'myDatabase', 200*1024*1024);


                db.transaction( function (tx){
                    tx.executeSql("CREATE TABLE IF NOT EXISTS zipcodes (country integer, continent integer, zip text, city text, state text, state_code text)");

                    tx.executeSql("INSERT INTO zipcodes (country, continent, zip, city, state, state_code) VALUES (7,5,'AD100','Canillo','Canillo','02')");
                    tx.executeSql("INSERT INTO zipcodes (country, continent, zip, city, state, state_code) VALUES (7,5,'AD200','Encamp','Encamp','03')");
                    tx.executeSql("INSERT INTO zipcodes (country, continent, zip, city, state, state_code) VALUES (7,5,'AD400','La Massana','La Massana','04')");

                    console.log("OK");
                }, function(err) {
                    console.log("Query error", err.message);

                });

*/

                /*
                function getBase64(file, cb) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        cb(reader.result)
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                    };
                }



               getBase64(zipfile, (result) => {
                   console.log(result);
                });
*/
                /*
                var jsZip = require('JSZip');
                console.log(zipfile);
                jsZip.loadAsync(zipfile,{base64:true}).then(function (zip) {
                    Object.keys(zip.files).forEach(function (filename) {
                        zip.files[filename].async('string').then(function (fileData) {
                            console.log(fileData) // These are your file contents
                        })
                    })
                })
*/



                //SessionStorageService.remove('my_permissions');
                localStorage.setItem('my_permissions', JSON.stringify(res.data.my_permissions));
                //SessionStorageService.remove('my_roles_meta');
                res.data.my_roles_meta.map((e)=>{
                    localStorage.setItem('my_roles_meta_'+e.name, e.value);
                });
                try {
                    SessionStorageService.initializeLocalDB(res.data);
                } catch (er) {
                    console.log(er);
                }


            })
            .catch((error) => {
                console.log(error);
            });
    }


    static initializeLocalDB(data) {

        SessionStorageService.booleans();

/*
        var db = openDatabase('gtsdb', '1.0', 'gtsdb', 500*1024*1024);
        db.transaction(function (tx) {

            tx.executeSql("DROP table countries");
            tx.executeSql("DROP table continents");
        });
*/

        for (const [key, value] of Object.entries(data)) {

            //SessionStorageService.remove(key);
            localStorage.setItem(key, JSON.stringify(value));
            if (Array.isArray(value)) {
                value.map((e) => {
                    localStorage.setItem(key + '_' + e.id, JSON.stringify(e));


                });
            }

            // Writing countries and continents to websql

                    if (key == 'countries') {



                /*
                        db.transaction(function (tx) {
                            tx.executeSql("CREATE TABLE IF NOT EXISTS countries (id integer, name_cs text, name_en text, code text, code2 text, continent integer, active integer)");
                            var query = 'INSERT INTO countries (id, name_cs, name_en, code, code2, continent, active) VALUES ';
                            value.map((e) => {
                                query += "(" + e.id + ",'" + e.name_cs.replace("'", "''") + "','" + e.name_en.replace("'", "''") + "','" + e.code + "','" + e.code2 + "'," + e.continent + "," + e.active + "),";
                            });
                            query = query.slice(0, -1);
                            tx.executeSql(query);


                        }, function (err) {
                            console.log("Query error", err.message);
                        });
                        */

                    }


                    if (key == 'continents') {

                /*
                        db.transaction(function (tx) {
                            tx.executeSql("CREATE TABLE IF NOT EXISTS continents (id integer, name_cs text, name_en text, code text, active integer)");
                            var query = 'INSERT INTO continents (id, name_cs, name_en, code, active) VALUES ';
                            value.map((e) => {
                                query += "(" + e.id + ",'" + e.name_cs.replace("'", "''") + "','" + e.name_en.replace("'", "''") + "','" + e.code + "'," + e.active + "),";
                            });
                            query = query.slice(0, -1);
                            tx.executeSql(query);


                        }, function (err) {
                            console.log("Query error", err.message);
                        });
                        */

                    }


        }






    }



}

export default SessionStorageService;