import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class ContactAdd extends Component<Props> {
    state = {
        contact_person_note: "", phone: "", email: "", contact_person: "", contact_person_position: "", main_contact: ""

    };

    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {


            this.setState({
                contact_person_note: "", phone: "", email: "", contact_person: "", contact_person_position: "", main_contact: ""

            });
        }
    }

    handleFormSubmit(event) {
        event.preventDefault();
        const { contact_person_note, phone, email, contact_person, contact_person_position, main_contact} = this.state;
        this.props.onAdd({ contact_person_note, phone, email, contact_person, contact_person_position, main_contact});
    }
    render() {
        const { contact_person_note, phone, email, contact_person, contact_person_position, main_contact} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={contact_person}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ contact_person: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Position')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={contact_person_position}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ contact_person_position: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Phone')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={phone}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ phone: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Email')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={email}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Note')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={contact_person_note}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ contact_person_note: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Main')}</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value={main_contact}
                                    checked={main_contact}
                                    onChange={(event) => this.setState({ main_contact: !main_contact })}
                                />
                            </div>
                        </div>

                    </div>

                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
ContactAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ContactAdd);

