"use strict";


import APIModelInsertResponseAbstractT from "../_/APIModelInsertResponseAbstractT.mjs";


const APIModelInsertResponseChildrenListT = class APIModelInsertResponseChildrenListT {
	
	#List = null;
	
	constructor(
		APIModelInsertResponseChildrenList
	){
		
		if( ( APIModelInsertResponseChildrenList instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelInsertResponseChildrenList.length;
			I++
		){

			if( ( APIModelInsertResponseChildrenList[ I ] instanceof APIModelInsertResponseAbstractT ) === false ){
			
				throw new Error( "Argument" );
			
			}
		
		}

		
		this.#List = new Array( );
		
		
		for(
			let I = 0;
			I < APIModelInsertResponseChildrenList.length;
			I++
		){
			
			let APIModelInsertResponseChild = APIModelInsertResponseChildrenList[ I ];
			
			this.Set( I, APIModelInsertResponseChild );
		
		}
		
	}
	
	Count(
	
	){
		
		return this.#List.length;
		
	}
	
	Set( 
		Index,
		APIModelInsertResponseChild
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( APIModelInsertResponseChild instanceof APIModelInsertResponseAbstractT ) === false ){
			
			throw new Error( "Argument" );
		
		}
		
		if( Index > this.#List.length ){
				
			throw new Error( "Index" );
			
		}

		
		this.#List[ Index ] = APIModelInsertResponseChild;
		
	}
	
	Remove(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#List.length ){
				
			throw new Error( "Index" );
			
		}
		
	
		return this.#List.splice( Index, 1 );
	
	}
	
	Get(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( Index >= this.#List.length ){
				
			throw new Error( "Index" );
			
		}
		
	
		return this.#List[ Index ];
	
	}
	
	IsExists(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return ( Index < this.#List.length );
	
	}
	
};


export default APIModelInsertResponseChildrenListT;