import React, { Component } from "react";

import { translate } from 'react-polyglot';

import { confirmAlert } from 'react-confirm-alert';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { AllCommunityModules } from 'ag-grid-community';

import LocaleEn from "../../aggrid/locale.en.js";

import LocaleCs from "../../aggrid/locale.cs.js";

import ActionsRenderer from "../../aggrid/ActionsRenderer.jsx";

import FileRenderer from "../../aggrid/FileRenderer.jsx";

import DatePicker from "../../aggrid/DatePicker.js";

import DateTimePicker from "../../aggrid/DateTimePicker.jsx";

import DateTimePickerSeconds from "../../aggrid/DateTimePickerSeconds.jsx";

import ManufacturingRenderer from "../../aggrid/ManufacturingRenderer.jsx";

import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData.jsx";

import PropTypes from 'prop-types';

import ModelController from "../../model/ModelController";

import OutinvoiceCard from "../loadingcard"; //TODO Replace with new DocGen

import ComponentBoolean from "./components/ComponentBoolean";
import ComponentBooleanRenderer from "./components/ComponentBooleanRenderer";
import ComponentInteger from "./components/ComponentInteger";
import ComponentDouble from "./components/ComponentDouble";
import ComponentText from "./components/ComponentText";
import ComponentMultiselectBase from "./components/ComponentMultiselectBase";
import ComponentSelectBase from "./components/ComponentSelectBase";
import ComponentSelectTextBase from "./components/ComponentSelectTextBase";


import Form from "./form/Form";

import Button from "./button/Button";

import Column from "./columns/Column";

import Model from "./model/Model";


interface Props {
	
    onAdd:( data ) => void;
	
};


class Grid extends Component< Props > {
	
	#RowsSelected = null;
	
	#Model = null;
	
	#ModelName = null;
	
	#ModelSchema = null;
	
	#Title = null;
	
	#Prepare = null;
	
	#ModelRows = null;
	
	#Rows = null;
	
	#ModalOpen = null;
	
	#PinnedLeftFields = null;
	#PinnedRightFields = null;
			
	#CheckboxField = null;
	
	#Locale = null;
	
	#InProcess = null;
	
	#RowSchema = null;
	
	//#ModalsRenderArray = null;
		
	#ModalsRenderObject = null;
	
	#ControlsRenderArray = null;
		
	#ControlsRenderObject = null;
	
	#RowData = null;
	
	
	#FormPreset = null;
	
	#ModalsCanCreate = false;

    constructor( props ) {
		
        super( props );
		
		this.#Locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) ){
			
			this.#Locale = localStorage.getItem( 'locale' );
			
		}
		
		
		this.#Model = new Model( this.props.model_schema, this.props.prepare );
		
		this.#ModelName = this.props.model_name;
		
		this.#ModelSchema = null;
		
		this.#ModalOpen = new Map( );
		
		this.#PinnedLeftFields = this.props.pinned_left_fields;
		
		this.#PinnedRightFields = this.props.pinned_right_fields;
			
		this.#CheckboxField = this.props.checkbox_field;
		
		this.#Title = this.props.title;
		
		this.#InProcess = false;
		
		this.#RowSchema = [ ];
		
		//this.#ModalsRenderArray = [ ];
		
		this.#ModalsRenderObject = new Map( );
		
		this.#ControlsRenderArray = [ ];
		
		this.#ControlsRenderObject = new Map( );
		
		this.ModalsRefs = { };
		
		this.ModalsRefsBD = { };
		
		this.ModalsRefsProps = { };
		
		this.ButtonsRefs = { };
		
		this.#RowsSelected = 0;
		
		this.state = {
			Refresh: 0
		};

    }
	
	componentDidMount( ){
		
		console.log( "componentDidMount" );
		
		this.CheckControls( );
		
		this.Preload( );
		
	}

    componentDidUpdate( prevProps, prevState ) {
		
		console.log( "componentDidUpdate", prevProps, prevState );
		
		if( ( prevProps.year !== this.props.year ) || ( prevProps.month !== this.props.month ) ){
			
			let Rows = this.FilterByDate( );
				
			this.#Rows = Rows;
			
			this.setState( {
				Refresh: this.state.Refresh + 1
			} );
			
		} else if( prevProps.refresh !== this.props.refresh ){
			
			this.CheckControls( );
			
			this.Preload( );
			
			return;
			
		}
		
		this.CheckControls( );
        
    }
	
	CopyRowFn( Row ){
		
		console.log( "CopyRowFn", Row, this.#ModelRows );
		
		this.#FormPreset = Row;
		
		if( this.#ModelRows.children && this.#ModelRows.children.outinvoice_items ){
			
			let ChildrenRecords = this.#ModelRows.children.outinvoice_items.data;
			
			console.log( ChildrenRecords );
			
			let ChildrenItems = [ ];
			
			for(
				let I = 0;
				I < ChildrenRecords.length;
				I++
			){
				
				if( ChildrenRecords[ I ].outinvoice_id == Row.id ){
					
					ChildrenItems.push( ChildrenRecords[ I ] );
					
				}
				
			}
			
			this.#FormPreset.children = ChildrenItems;
			
			
		}
		
		//this.#ModelSchema.children.outinvoice_item.model_schema
		
		
		//this.#ModelSchema.children.outinvoice_item.model_schema;
		//this.#ModelSchema.model_schema
		
		this.ActionModalOpen( { ModalId: "FormAdd" } ); 
		
		this.setState( {
			Refresh: this.state.Refresh + 1
		} );
		
	}
	
	SchemaRow( ModelSchema ){
		
		let RowSchema = [ ];
				
		for(
			let I = 0;
			I < ModelSchema.length;
			I++
		){
			
			let ColumnDef = ModelSchema[ I ];
			
			
			let Index = this.props.fields.indexOf( ColumnDef.field );
			
			if( Index === -1 ){
				
				continue;
				
			}
			
			
			let ColumnV = Column.Create( 
				ColumnDef, 
				this.#ModelName, 
				this.#ModelSchema, 
				this.#PinnedLeftFields, 
				this.#PinnedRightFields, 
				this.#CheckboxField,
				this.props.meta[ ColumnDef.field ],
				this.ModalsRefs,
				this.ModalsRefsBD,
				this.ModalsRefsProps,
				this.RenderModals.bind( this ),
				this.CopyRowFn.bind( this )
			);
			
			ColumnV.useValueFormatterForExport = true;
			
			RowSchema[ Index ] = ColumnV;
			
		}
		
		return RowSchema;
		
	}
	
	CreateRow( ModelData ){
		
		console.log( "CreateRow", ModelData, this.#Rows );
		
		//TODO Children model update
		
		let Rows = this.#Rows;
				
		Rows.push( ModelData );
				
		
		let Result = this.gridApi.applyTransaction( {
			add: [ ModelData ],
			addIndex: 0
		} );

		this.gridApi.flashCells( { rowNodes: Result.add } );
		
		
		return Rows;
		
	}
		
	Create(
		Data
	){
		
		console.log( "Create", Data );
		
		
		this.#InProcess = true;
		
		this.CheckControls( );
	
		this.#Model.Create( 
		
			Data,
			
			function( Response ){
				
				let ModelData = Response.model;
				
				this.#Prepare = Response.prepare;
				
				
				console.log( "Create", "ModelData", ModelData, this.#ModelRows );
				
				let Rows = null;
				
				
				let ModelRows = this.#ModelRows;
				
				for(
					let I = 0;
					I < ModelData.data.length;
					I++
				){
					
					Rows = this.CreateRow( ModelData.data[ I ] );
					
					ModelRows.data.push( ModelData.data[ I ] );
					
				}
				
				for(
					let I in ModelData.children
				){
					
					let ChildModel = ModelData.children[ I ];
					
					for( let I2 = 0; I2 < ChildModel.data.length; I2++){
					
						ModelRows.children[ I ].data.push( ChildModel.data[ I2 ] );
					
					}
					
				}
				
				
				console.log( ModelRows );


				this.#InProcess = false;
				
				this.CheckControls( );
				
				this.CloseAllModals( );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.#InProcess = false;
				
				this.CheckControls( );
		
			}.bind( this )
			
		);
		
	}
	
	UpdateRow( Row ){
		
		let Rows = this.#Rows;
				
		for(
			let Iterator = 0;
			Iterator < Rows.length;
			Iterator++
		){
			
			if( Row.id.toString( ) === Rows[ Iterator ].id.toString( ) ){
				
				Rows[ Iterator ] = Row;
				
				break;
				
			}
			
		}
				
		
		let rownode = this.gridApi.getRowNode( Row.id );
					
		rownode.setData( Row );
			
		this.gridApi.redrawRows( { rowNodes: [ rownode.parent ] } );
		
		
		return Rows;
		
	}
	
	Update(
		Id,
		Field,
		Value
	){
		
		console.log( "Update", Id, Field, Value );
		
		
		this.#InProcess = true;
		
		this.CheckControls( );
	
		this.#Model.Update( 
		
			Id,
			Field,
			Value,
			
			function( Row ){
				
				let Rows = this.UpdateRow( Row );
					
					
				this.#InProcess = false;
		
				this.CheckControls( );
				
			}.bind( this ),
			
			function( ErrorMessage, Row ) {
	
				alert( ErrorMessage );
				
				if( Row ){
					
					let Rows = this.UpdateRow( Row );
					
					this.#InProcess = false;
		
					this.CheckControls( );
				
				} else {
				
					this.#InProcess = false;
		
					this.CheckControls( );
				
				}
		
			}.bind( this )
			
		);
		
	}
	
	DeleteRow( Row ){
		
		let Rows = this.#Rows;
				
		for( 
			let Iterator = 0;
			Iterator < Rows.length;
			Iterator++
		){
			

			if( 
				Row.id === Rows[ Iterator ].id
			){
				
				Rows.splice( Iterator, 1 );
			
				break;
			
			}
			
		}
		
		
		this.gridApi.updateRowData( {
					
			remove: [ Row ] 
					
		} );
		
		
		return Rows;
		
	}
	
	Delete(
		Data
	){
		
		console.log( "Delete", Data );
		
		this.#InProcess = true;
		
		this.CheckControls( );
		
		
		this.#Model.Delete( 
		
			Data.id,
			
			function( Row ){
									
				let Rows = this.DeleteRow( Row );
				
				
				this.#InProcess = false;
		
				this.CheckControls( );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				
				this.#InProcess = false;
		
				this.CheckControls( );
		
			}.bind( this )
			
		);
		
	}
	
	FilterByDate( ){
		
		console.log( "FilterByDate", this.#RowData, this.props.year, this.props.month );
		
		let Rows = [ ];
				
		for( 
			let I = 0;
			I < this.#RowData.data.length;
			I++
		){
		
			if( this.props.year ){
							
				console.log( this.props.year, this.props.month, this.#RowData.data[ I ].invoice_taxable );

				if( this.#RowData.data[ I ].invoice_taxable ){
					
					let IY = this.#RowData.data[ I ].invoice_taxable.substring( 0, 4 );
					
					let IM = this.#RowData.data[ I ].invoice_taxable.substring( 5, 7 );
					
					console.log( IY, IM );
				
					if( IY == this.props.year ){
					
						if( this.props.month ){
							
							if( IM == this.props.month ){
								
								Rows.push( this.#RowData.data[ I ] );
								
							}
							
						} else {
							
							Rows.push( this.#RowData.data[ I ] );
							
						}
						
					}
					
				}

			} else {

				Rows.push( this.#RowData.data[ I ] );

			}
			
		}

		return Rows;
		
	}
	
	Preload( ){
		
		console.log( "Preload" );
		
		this.#InProcess = true;
		
		this.CheckControls( );
		
		
		this.#Model.Preload(
		
			function( Schema, Prepare, Data ){
			
				console.log( "Preload success", Schema, Prepare, Data );
				
				this.#ModelSchema = Schema;
				
				this.#Prepare = Prepare;
				
				this.#ModelRows = Data;
				
				
				let RowSchema = this.SchemaRow( Schema.model_schema );
				
				
				this.#RowData = Data;
				
				let Rows = this.FilterByDate( );
				
				this.#Rows = Rows;
				
				
				
				this.#RowSchema = RowSchema;
				
				this.#InProcess = false;
		
				this.CheckControls( );
				
				
				//CreateModals
				
				this.#ModalsCanCreate = true;
				
				//this.ModalsCreate( this.props.modals );
				
				this.ControlsCreate( this.props.controls );
				
				
				this.setState( {
					Refresh: this.state.Refresh + 1
				} );

			}.bind( this ), 
			
			function( ErrorMessage ){
			
				console.log( "Preload error", ErrorMessage );
				
				alert( ErrorMessage );
				
				this.#InProcess = false;
		
				this.CheckControls( );
			
			}.bind( this )
			
		);
		
	}
	
	ModalOnClose( ModalId ){
		
		console.log( "ModalOnClose", ModalId );
		

		this.#ModalOpen.set( ModalId, false );	
		
		this.ModalsRefs[ ModalId ].style.display = "none";
		
		this.ModalsRefsBD[ ModalId ].style.display = "none";
		
	}
	
	RenderModals( Name ){
		
		//this.ModalsCreate( this.props.modals );
		
		
		if( Name === "DocGen" ){
			
			console.log( "RenderModals", Name, this.ModalsRefsProps[ Name ] );
		
			let ModalShow = false;
			
			let Id = Name;
				
			if( !this.ModalsRefsProps[ Id ] ){
			
				this.ModalsRefsProps[ Id ] = { id: "" };
			
			}
			
			if( this.ModalsRefsProps[ Id ].id !== "" ){
				
				ModalShow = true;
				
				if( this.ModalsRefs[ Id ] ){
				
					this.ModalsRefs[ Id ].style.display = "table";
		
					this.ModalsRefsBD[ Id ].style.display = "block";
				
				}
				
			}
			
			let Content = <OutinvoiceCard 
				type={ "outinvoice_single" } 
				tmp={ Date.now( ) } 
				loading_number={ this.ModalsRefsProps[ Id ].id } 
				show={ ModalShow } 
			/>;
			
			//ModalsRefs[ "DocGen" ].style.display = "block"; //TODO
		 
			//ModalsRefsBD[ "DocGen" ].style.display = "block"; //TODO
			
		
			let Fragment = (
				<React.Fragment>
					<div 
						ref={ ( ModalRef ) => { this.ModalsRefs[ Id ] = ModalRef; } } 
						className={ "modal" }
						style={ { zIndex:1050, position: "absolute", width: "100%", height: "100%", display: ( ModalShow ? "table" : "none" ) }}
					>
						<div className={`modal-dialog modal-lg`}>
							<div className="modal-content">
								<div className="modal-header">
								
									<h4 className="modal-title">{ "Title" }</h4>
									
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={ ( ) => { 
										
											this.ModalsRefsProps[ Id ].id = "";
											
											this.ModalsRefs[ Id ].style.display = "none";
		
											this.ModalsRefsBD[ Id ].style.display = "none";
											
											this.setState( {
												Refresh: this.state.Refresh + 1
											} )
											
										} }
									>
										<span aria-hidden="true">&times;</span>
									</button>
									
								</div>
								<div className="modal-body" >
								
									{ Content }
									
								</div>
							</div>
						</div>
					</div>
					<div
						ref={ ( ModalRefBD ) => { this.ModalsRefsBD[ Id ] = ModalRefBD; }} 
						className={ "modal-backdrop fade" }
						style={ { display: ( ModalShow ? "block" : "none" ), opacity: 0.5 } }
					>
					</div>
				</React.Fragment>
			);
			
			//this.#ModalsRenderArray[] = Fragment;
			this.#ModalsRenderObject.set( Id, Fragment);
			
		}
		
		this.setState( {
			Refresh: this.state.Refresh + 1
		} )
		
	}
	
	ModalCreate( Modal ){

		console.log( "ModalCreate", Modal, this.#ModelName, this.#ModelSchema, this.#Prepare );
		
		
		if( Modal.Type === "Form" ){
			
			this.ModalsRefsProps[ Modal.TypeData.Id ] = { };
			
			return <Form
				preset = {
					this.#FormPreset
				}
				title = {
					Modal.TypeData.Title
				}
				modal_id = { 
					Modal.TypeData.Id 
				}
				grid_add = {
					( Data ) => this.HandleAdd( Data )
				}
				model_name = {
					this.#ModelName
				}
				model_schema = {
					this.#ModelSchema
				}
				data = {
					Modal.TypeData
				}
				on_ref = {
					( ModalRef ) => { this.ModalsRefs[ Modal.TypeData.Id ] = ModalRef; }
				}
				on_ref_bd = {
					( ModalRefBD ) => { this.ModalsRefsBD[ Modal.TypeData.Id ] = ModalRefBD; }
				}
				children = {
					Modal.TypeData.Children
				}
				prepare = {
					this.#Prepare
				}
			/>
			
		} else if( Modal.Type === "OutinvoiceCard" ){
			
			let ModalShow = false;
			
			if( !this.ModalsRefsProps[ Modal.TypeData.Id ] ){
			
				this.ModalsRefsProps[ Modal.TypeData.Id ] = { id: "" };
			
			}
			
			if( this.ModalsRefsProps[ Modal.TypeData.Id ].id !== "" ){
				
				ModalShow = true;
				
			}
			
			/*let Content = <OutinvoiceCard 
				type={ "outinvoice_single" } 
				tmp={ Date.now( ) } 
				loading_number={ this.ModalsRefsProps[ Modal.TypeData.Id ].id } 
				show={ ModalShow } 
			/>;
		
			return (
				<React.Fragment>
					<div 
						ref={ ( ModalRef ) => { this.ModalsRefs[ Modal.TypeData.Id ] = ModalRef; } } 
						className={ "modal" }
						style={ { zIndex:1050, position: "absolute", width: "100%", height: "100%", display: "none" }}
					>
						<div className={`modal-dialog modal-lg`}>
							<div className="modal-content">
								<div className="modal-header">
								
									<h4 className="modal-title">{ "Title" }</h4>
									
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close"
										onClick={ ( ) => { 
											this.ModalsRefs[ Modal.TypeData.Id ].style.display = "none";
		
											this.ModalsRefsBD[ Modal.TypeData.Id ].style.display = "none";
										} }
									>
										<span aria-hidden="true">&times;</span>
									</button>
									
								</div>
								<div className="modal-body" >
								
									{ Content }
									
								</div>
							</div>
						</div>
					</div>
					<div
						ref={ ( ModalRefBD ) => { this.ModalsRefsBD[ Modal.TypeData.Id ] = ModalRefBD; }} 
						className={ "modal-backdrop fade" }
						style={ { display: "none", opacity: 0.5 } }
					>
					</div>
				</React.Fragment>
			);*/
			
			return null;
			
		} else {
			
			throw new Error( "Invalid modal type" );
			
		}
		
	}
	
	ModalsCreate( Modals ){
		
		console.log( "ModalsCreate", Modals );
		
		
		//this.#ModalsRenderArray = [ ];
		
		//this.#ModalsRenderObject = new Map( );
		
		let ModalsRenderObject = new Map( );
		
		for(
			let Iterator = 0;
			Iterator < Modals.length;
			Iterator++
		){
			
			let Modal = Modals[ Iterator ];
			
			let Result = this.ModalCreate(
				Modal
			);
			
			/*this.#ModalsRenderArray.push( 
				Result
			);*/
			
			ModalsRenderObject.set( 
				Modal.TypeData.Id,
				Result 
			);
			
		}
		
		return ModalsRenderObject;
		
	}
	
	ButtonIsDisabled( Id, Action, ActionData ){
		
		console.log( "ButtonIsDisabled", Id, Action, ActionData );
		
		
		if( !this.gridApi ){
			
			return true;
			
		}
		
		if( this.#ModelSchema === null ){
			
			return true;
			
		}
		
		if( this.#InProcess === true ){
			
			return true;
			
		}
		
		
		if( Action === "ModalOpen" ){
			
			return false;
				
		} else if( Action === "DeleteRows" ){
			
			if( ActionData.ToDelete === "Selected" ){
				
				return ( this.#RowsSelected === 0 );
				
			}
			
		} else if( Action === "Custom" ){
			
			if( ActionData.Fields === "Selected" ){
				
				return ActionData.IsDisabled( this.#RowsSelected );
			
			} else if( ActionData.Fields === "All" ){
				
				return false;
				
			}
			
		}
		
		return true;
		
	}
	
	ActionModalOpen( ActionData ){
		
		console.log( "ActionModalOpen", this.#ModalOpen, ActionData );
		
		
		this.#ModalOpen.set( ActionData.ModalId, true );
		
		
		this.ModalsRefs[ ActionData.ModalId ].style.display = "block";
		
		this.ModalsRefsBD[ ActionData.ModalId ].style.display = "block";
		
		this.setState( {
			Refresh: this.state.Refresh + 1
		} );
		
	}
	
	ActionDeleteRows( ActionData ){
		
		if( ActionData.ToDelete === "Selected" ){
				
			this.HandleDelete( );
			
		}
		
	}
	
	ButtonOnClick( Id, Action, ActionData ){
		
		console.log( "ButtonOnClick", Id, Action, ActionData );
		
		
		if( Action === "ModalOpen" ){
			
			this.ActionModalOpen( ActionData );
				
		} else if( Action === "DeleteRows" ){
			
			this.ActionDeleteRows( ActionData );
			
		} else if( Action === "Custom" ){
			
			ActionData.Process( this.gridApi.getSelectedNodes( ), function( ){
				
				this.setState( {
					Refresh: this.state.Refresh + 1
				} );
				
			}.bind( this ), this.gridApi );
			
			this.setState( {
				Refresh: this.state.Refresh + 1
			} );
			
		}
		
	}
	
	ControlCreate( Type, TypeData ){
		
		console.log( "ControlCreate", Type, TypeData );
		
		
		if( Type === "Button" ){
			
			return (
				<Button
					ref_cb = { ( input ) => { this.ButtonsRefs[ TypeData.Id ] = input; } }
					id = { TypeData.Id }
					title = { TypeData.Title }
					class_name = { TypeData.ClassName }
					icon = { TypeData.Icon }
					disabled = { this.ButtonIsDisabled( TypeData.Id, TypeData.Action, TypeData.ActionData ) }
					display = { "inline-block" }
					action = { TypeData.Action }
					action_data = { TypeData.ActionData }
					on_click_cb = { this.ButtonOnClick.bind( this ) }
				>
				
				</Button>
			);
			
		}
		
	}
	
	ControlsCreate( Controls ){
		
		console.log( "ControlsCreate", Controls, this );
		
	
		this.#ControlsRenderArray = [ ];
		
		this.#ControlsRenderObject = new Map( );
		
		for(
			let Iterator = 0;
			Iterator < Controls.length;
			Iterator++
		){
			
			let Control = Controls[ Iterator ];
			
			let Result = this.ControlCreate(
				Control.Type, 
				Control.TypeData
			);
			
			this.#ControlsRenderArray.push( 
				Result
			);
			
			this.#ControlsRenderObject.set( 
				Control.TypeData.Id,
				Result
			);
			
		}
		
	}

	onGridReady(params){
		
        this.gridApi = params.api;
		
        this.gridColumnApi = params.columnApi;
	

        this.gridApi.setDomLayout( 'normal' );
		
    }
	
	onCellValueChanged( Ev ){
		
		console.log( Ev );
		
		if( Ev.newValue == Ev.oldValue ){
			
			return;
			
		}
		
		this.Update( 
			Ev.data.id, 
			Ev.column.colId, 
			Ev.data[ Ev.column.colId ] 
		);

    }

    onCellClicked( Ev ){

    }
	
	CheckControls( ){
		
		let Controls = this.props.controls;
		
		for(
			let Iterator = 0;
			Iterator < Controls.length;
			Iterator++
		){
			
			let Control = Controls[ Iterator ];
			
			let TypeData = Control.TypeData;
			
			if( Control.Type === "Button" ){
				
				let Id = TypeData.Id;
				let Title = TypeData.Title;
				let ClassName = TypeData.ClassName;
				let Icon = TypeData.Icon;
				let Action = TypeData.Action;
				let ActionData = TypeData.ActionData;
				
				if( !this.ButtonsRefs[ Id ] ){
					
					continue;
					
				}
			
				this.ButtonsRefs[ Id ].disabled = this.ButtonIsDisabled( Id, TypeData.Action, TypeData.ActionData );
				
			}
			
		}
		
	}
	
	onSelectionChanged( ) {
		
		let RowsSelected = this.gridApi.getSelectedNodes( ).length;
		
		
		console.log( "RowsSelected", RowsSelected, this.#RowsSelected );
		
		
		if( RowsSelected === 0 && this.#RowsSelected === 1 ) {
			
			this.#RowsSelected = RowsSelected;
			
		} else if( RowsSelected === 1 && this.#RowsSelected === 0 ){
			
			this.#RowsSelected = RowsSelected;
			
		} else {
			
			this.#RowsSelected = RowsSelected;
			
		}
	
		
		this.CheckControls( );

		
    }
	
	getRowNodeId( Data ){
		
        return Data.id;
		
    }
	
	getContextMenuItems( Params ){

        return [
            'copy',
            'paste',
            'export',
        ];
		
    }

	onColumnsStateChanged( params ) {
		
    }
	
	onFilterChanged( params ) {
		
        if ( this.gridApi ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel( 'statusCountKey' );
			
            let componentInstance = statusBarComponent;
			
            if ( typeof( statusBarComponent ) !== 'undefined' && statusBarComponent.getFrameworkComponentInstance ) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }
	
	CloseAllModals( ){

		for(
			let I of this.#ModalOpen.keys( )
		){
			
			this.#ModalOpen.set( I, false );
			
			console.log( this.ModalsRefs, this.ModalsRefsBD );
			
			this.ModalsRefs[ I ].style.display = "none";
			
			this.ModalsRefsBD[ I ].style.display = "none";
			
		}	
		
	}
	
	HandleAdd( Data ) {
	
		console.log( "HandleAdd", Data );
		
		
		this.Create( Data );

    }
	
	HandleDelete( ) {
		
		console.log( "HandleDelete" );
		

        confirmAlert( {
			
            title: this.props.t( "Confirm to delete" ),
            message: this.props.t( "Are you sure to delete this item and all other items associated with it?" ),
            buttons: [
			
                {
                    label: this.props.t( "Yes" ),
                    onClick: function( ) {

                        this.gridApi.getSelectedNodes( ).forEach( function( SelectedNode ) {
							
                            this.Delete( SelectedNode.data );
							
                        }.bind( this ) );

                    }.bind( this )
                },
				
                {
                    label: this.props.t( "No" )
                }
				
            ]
        } );

    }

	render( ) {
		
		console.log( "Grid render" );
		
		
		let rowClassRules = null;
		
		
		let LocaleText = null;
		
        if( this.#Locale === 'cs' ) {
			
			LocaleText = LocaleCs;
			
		} else if( this.#Locale === 'en' ) {
			
			LocaleText = LocaleEn;
			
		}
		
		
		let Modals = new Map( );
		
		if( this.#ModalsCanCreate === true ) {
		
			Modals = this.ModalsCreate( this.props.modals );
		
		}
		
	
        return (
            <>
			
				<div style={{ "fontWeight": "bolder", "fontSize": "18px"}}>
					{ this.#Title }
				</div>
               
                <div className="buttons-companies">
				
                    <div className="tool-buttons">
						
						{ this.#ControlsRenderArray }
						
					</div>
					
					{  [ ...this.#ModalsRenderObject.values( ), ...Modals.values( ) ] }

                </div>

                <div style={{'height': '100%', width: '100%'}} id={ this.#ModelName } className="ag-theme-alpine">
                    <AgGridReact
						zIndex = { 1000 }
                        modules={ AllCommunityModules }
                        rowData={ this.#Rows }
                        columnDefs={ this.#RowSchema }
                        defaultColDef={ {
							editable: false,
							filter: true,

							minWidth: 20,
							resizable: true,

							sortable: true,

							comparator: function( valueA, valueB ){
			
								if( 
									( valueA && ( valueA != null ) ) && 
									( valueB && ( valueB != null ) ) && 
									( typeof( valueA.toLowerCase ) == 'function' ) && 
									( typeof( valueB.toLowerCase ) == 'function' ) ){
										
									return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
									
								} else {
									
									return false;
									
								}
								
							}
							
						} }
                        onGridReady={ this.onGridReady.bind( this ) }
                        onCellValueChanged={ this.onCellValueChanged.bind( this ) }
                        onCellClicked={ this.onCellClicked.bind( this ) }
                        frameworkComponents={ {
							ActionsRenderer: ActionsRenderer, //TODO Delete
							FileRenderer: FileRenderer, //TODO Delete
							ComponentMultiselectBase: ComponentMultiselectBase,
							DateTimePicker: DateTimePicker, //TODO Delete
							DateTimePickerSeconds: DateTimePickerSeconds, //TODO Delete
							MultiSelectLargeData: MultiSelectLargeData, //TODO Delete
							ManufacturingRenderer: ManufacturingRenderer, //TODO Delete
							DatePicker: DatePicker, //TODO Delete
							
							ComponentBoolean: ComponentBoolean,
							ComponentBooleanRenderer: ComponentBooleanRenderer,
							ComponentInteger: ComponentInteger,
							ComponentDouble: ComponentDouble,
							ComponentText: ComponentText,
							ComponentSelectBase: ComponentSelectBase,
							ComponentSelectTextBase: ComponentSelectTextBase
						} }
                        enableCellChangeFlash={ true }
                        rowSelection={ 'multiple' }
                        popupParent={ document.querySelector( 'body' ) }
                        suppressContextMenu={ false }
                        pagination={ true }
                        onSelectionChanged={ this.onSelectionChanged.bind( this ) }
                        paginationPageSize={ 1000 }
                        localeText={ LocaleText }
                        statusBar={ { } }
						rowBuffer = { 1000 }
                        getRowNodeId={ this.getRowNodeId.bind( this ) }
                        getContextMenuItems={ this.getContextMenuItems.bind( this ) }
                        enableGroupEdit={ true }
                        onColumnVisible={ this.onColumnsStateChanged.bind( this ) }
                        onDragStopped={ this.onColumnsStateChanged.bind( this ) }
                        onFilterChanged={ this.onFilterChanged.bind( this ) }
                        suppressAggFuncInHeader={ true }
                        suppressRowClickSelection={ true }
						rowClassRules={ rowClassRules }
                        suppressChangeDetection={ true }
						suppressColumnVirtualisation = { true }
						suppressRowVirtualisation = { true }
						suppressMaxRenderedRowRestriction = { true }
						animateRows = { true }
                    />
                </div>
				
            </>
			
        );
		
    }
	
};


Grid.propTypes = {
	
    t: PropTypes.func.isRequired
	
};


export default translate( )( Grid );

