
const messages = {
	"hello_name": "Hello",
	"num_cars": "%{smart_count} car |||| %{smart_count} cars",
	"dashboard": "Dashboard",
	"companies": "Companies",
	"list": "list",
	"settings": "Settings",
	"Settings": "Settings",
	"continents": "Continents",
	"countries": "Countries",
	"languages": "Languages",
	"product_codes": "Product codes",
	"product_types": "Product types",
	"contact_types": "Contact types",
	"active_companies": "Active companies",
	"Common": "Common",
	"Profile": "Profile",
	"Role": "Role",
	"Name": "Name",
	"Email": "Email",
	"Submit": "Submit",
	"Home": "Home",
	"Logout": "Logout",
	"Loadings": "Loadings",
	"Active companies": "Active companies",
	"Contact success": "Contact success",
	"More info": "More info",
	"Sells": "Sells",
	"Todo list": "Todo list",
	"Add new Todo": "Add new Todo",
	"Description": "Description",
	"Enter the todo description": "Enter the todo description",
	"Date": "Date",
	"Save": "Save",
	"Clear": "Clear",
	"Close": "Close",
	"Continents": "Continents",
	"Active": "Active",
	"Czech name": "Czech name",
	"English name": "English name",
	"Loading data": "Loading data",
	"ISO code 3": "ISO code 3",
	"ISO code 2": "ISO code 2",
	"Countries": "Countries",
	"Languages": "Languages",
	"Product codes": "Product codes",
	"Product types": "Product types",
	"Confirm to delete": "Confirm to delete",
	"Are you sure to delete this item and all other items associated with it?": "Are you sure to delete this item and all other items associated with it?",
	"Add new": "Add new",
	"Delete": "Delete",
	"Excel": "Excel",
	"Yes": "Yes",
	"No": "No",
	"Enter the name": "Enter the name",
	"Contact types": "Contact types",
	"Company columns": "Company columns",
	"ID": "ID",
	"Cell renderer": "Cell renderer",
	"Cell editor": "Cell editor",
	"Data array": "Data array",
	"Loading": "Loading",
	"Audit": "Audit",
	"User": "User",
	"Users": "Users",
	"Users settings": "Users settings",
	"Column": "Column",
	"Old value": "Old value",
	"New value": "New value",
	"Roles": "Roles",
	"Password": "Password",
	"Enter the ": "Enter the ",
	"Phone": "Phone",
	"Select": "Select",
	"Permissions": "Permissions",
	"Permission": "Permission",
	"visible": "Visible",
	"editable": "Editable",
	"Technical info": "Technical info",
	"Names": "Names",
	"Column type": "Column type",
	"Options": "Options",
	"Choose": "Choose",
	"Filter": "Filter",
	"Column options": "Column options",
	"Lowercase letters only": "Lowercase letters only",
	"Branch name": "Branch name",
	"Type": "Type",
	"Company ID": "Company ID",
	"VAT ID": "VAT ID",
	"Enter the value": "Enter the value",
	"Continent": "Continent",
	"Country": "Country",
	"City": "City",
	"ZIP": "ZIP",
	"Street": "Street",
	"House nr.": "House nr.",
	"Name of company": "Name of company",
	"Upload": "Upload",
	"Import from Excel": "Import from Excel",
	"Please choose the file.": "Please choose the file.",
	"File imported successfully": "File imported successfully",
	"Locked": "Locked",
	"Contacts": "Contacts",
	"Position": "Position",
	"Note": "Note",
	"Main": "Main",
	"State": "State",
	"Address": "Address",
	"Field": "Field",
	"Show": "Show",
	"Company card": "Company card",
	"Details": "Details",
	"Communication": "Communication",
	"Value": "Value",
	"From": "From",
	"To": "To",
	"Subject": "Subject",
	"Text": "Text",
	"Attachments": "Attachments",
	"Mass SMS": "Mass SMS",
	"Mass Email": "Mass Email",
	"Your phone": "Your phone",
	"Message": "Message",
	"Your email": "Your email",
	"Reply-to email": "Reply-to email",
	"Drop here": "Drop here",
	"Submit for": "Submit for",
	"numbers": "numbers",
	"addresses": "addresses",
	"Email for check": "Email for check",
	"Copy": "Copy",
	"Confirm to send": "Confirm to send",
	"Are you sure you want to send mass message?": "Are you sure you want to send mass message?",
	"You don't have permissions for this address: ": "You don't have permissions for this address: ",
	"Address wasn't found": "Address wasn't found",
	"Lattitude": "Lattitude",
	"Longitude": "Longitude",
	"Search address": "Search address",
	"Find on map": "Find on map",
	"Show map": "Show map",
	"Fax": "Fax",
	"Appraisals": "Appraisals",
	"Material type": "Material type",
	"Approx. qty.": "Approx. qty.",
	"Created at": "Created at",
	"Appraised at": "Appraised at",
	"Price": "Price",
	"Images": "Images",
	"Please choose material type": "Please choose material type",
	"Please upload only images": "Please upload only images",
	"Please upload images": "Please upload images",
	"Company name": "Company name",
	"Loading city": "Loading city",
	"Appraised by": "Appraised by",
	"Requested by": "Requested by",
	"Archive": "Archive",
	"Loading columns": "Loading columns",
	"Code": "Code",
	"In form": "In form",
	"Required": "Required",
	"Company": "Company",
	"Loading address": "Loading address",
	"Loading date": "Loading date",
	"Loading number": "Loading nr.",
	"Confirm storing": "Confirm storing",
	"Are you sure you want to store this loading?": "Are you sure you want to store this loading?",
	"Document templates": "Loadings document templates",
	"Template": "Template",
	"Successfully uploaded": "Successfully uploaded",
	"Generate documents": "Generate documents",
	"Document": "Document",
	"Send to": "Send to",
	"Send copy": "Send copy",
	"Container type": "Container type",
	"Container number": "Container number",
	"Count": "Count",
	"Print pdf": "Print pdf",
	"Print word": "Print word",
	"Send": "Send",
	"Files uploaded successfully": "Files uploaded successfully",
	"Previously generated": "Previously generated",
	"File": "File",
	"Uploaded": "Uploaded",
	"Please upload max 5 files at a time": "Please upload max 5 files at a time",
	"other": "Other",
	"generated": "Generated",
	"invoice": "Invoice",
	"weighting": "Weighting ticket",
	"Download pdf": "Download pdf",
	"Download zip": "Download zip",
	"Fill weights": "Fill weights",
	"Without weights": "Without weights",
	"Spedition": "Spedition",
	"Contact person": "Contact person",
	"Product type": "Product type",
	"Kg": "Kg",
	"Tara": "Tara",
	"Download route": "Download route",
	"Print documents": "Print documents",
	"Accept route": "Accept route",
	"Trips": "Trips",
	"Speditor": "Speditor",
	"Loading numbers": "Loading numbers",
	"Calculated km": "Calculated km",
	"Real km": "Real km",
	"Finished": "Finished",
	"Invoice number": "Invoice number",
	"Invoice currency": "Invoice currency",
	"Exc. VAT": "Exc. VAT",
	"VAT": "VAT",
	"Inc. VAT": "Inc. VAT",
	"Exch. Rate": "Exch. Rate",
	"Issue date": "Issue date",
	"Due date": "Due date",
	"Taxable": "Taxable",
	"Show archive": "Show archive",
	"Show all": "Show all",
	"Show actual": "Show actual",
	"Chinese name": "Chinese name",
	"Stamp image": "Stamp image",
	"Hourly salary": "Hourly salary",
	"German name": "German name",
	"French name": "French name",
	"Italian name": "Italian name",
	"Spanish name": "Spanish name",
	"Russian name": "Russian name",
	"Storage": "Storage",
	"Quantity": "Quantity",
	"Additional hours": "Additional hours",
	"Processed qty.": "Processed qty.",
	"Worker": "Worker",
	"Production date": "Production date",
	"Production history": "Production history",
	"Production": "Production",
	"Salary per kg": "Salary per kg",
	"Production processes": "Production processes",
	"Products": "Products",
	"Manufacturer": "Manufacturer",
	"Add. work hours": "Add. work hours",
	"Last update": "Last update",
	"Salary volume": "Salary volume",
	"Salary hours": "Salary hours",
	"Salary total": "Salary total",
	"Supplier type": "Supplier type",
	"Work types": "Work types",
	"Work type": "Work type",
	"Work note": "Work note",
	"Invoices columns": "Invoices columns",
	"Finance": "Finance",
	"Incoming invoices": "Incoming invoices",
	"Bank accounts": "Bank accounts",
	"Account name": "Account name",
	"Bank name": "Bank name",
	"Account prefix": "Account prefix",
	"Account number": "Account number",
	"Bank code": "Bank code",
	"IBAN": "IBAN",
	"SWIFT": "SWIFT",
	"Currency": "Currency",
	"Payment method": "Payment method",
	"Pay from account": "Pay from account",
	"Fill in company": "Fill in company",
	"Payment type": "Payment type",
	"Excluding VAT": "Excluding VAT",
	"Including VAT": "Including VAT",
	"Taxable date": "Taxable date",
	"Paid date": "Paid date",
	"Payer note": "Payer note",
	"Beneficiary note": "Beneficiary note",
	"Cannot create an invoice. One of selected items has zero price": "Cannot create an invoice. One of selected items has zero price",
	"Cannot create an invoice. One of selected items already has assigned invoice": "Cannot create an invoice. One of selected items already has assigned invoice",
	"Send notifications": "Send notifications",
	"Create invoice": "Create invoice",
	"BIC/SWIFT": "BIC/SWIFT",
	"Speditor company": "Speditor company",
	"Pay from bank account": "Pay from bank account",
	"Choose bank account": "Choose bank account",
	"Cannot pay because of transfer types are mixed up": "Cannot pay because of transfer types are mixed up",
	"Payer name": "Payer name",
	"Payer address": "Payer address",
	"Bank name 1": "Bank name 1",
	"Bank name 2": "Bank name 2",
	"Bank address 1": "Bank address 1",
	"Bank address 2": "Bank address 2",
	"Beneficiary name 1": "Beneficiary name 1",
	"Beneficiary name 2": "Beneficiary name 2",
	"Beneficiary address 1": "Beneficiary address 1",
	"Beneficiary address 2": "Beneficiary address 2",
	"Beneficiary country": "Beneficiary country",
	"Bank country": "Bank country",
	"Variable symbol": "Variable symbol",
	"Constant symbol": "Constant symbol",
	"Specific symbol": "Specific symbol",
	"Wire transfers": "Wire transfers",
	"Total amount": "Total amount",
	"Paid from": "Paid from",
	"Transfers count": "Transfers count",
	"Sell product": "Sell product",
	"Sell": "Sell",
	"Sale date": "Sale date",
	"Sales": "Sales",
	"Mixed currencies are not allowed": "Mixed currencies are not allowed",
	"Choose filter": "Choose filter",
	"Pay invoices": "Pay invoices",
	"Generator type": "Generator type",
	"Outgoing invoices columns": "Outgoing invoices columns",
	"Outgoing invoices": "Outgoing invoices",
	"Pay to account": "Pay to account",
	"It is impossible to create invoice for sales to different companies": "It is impossible to create invoice for sales to different companies",
	"It is impossible to create invoice for sales with different currencies": "It is impossible to create invoice for sales with different currencies",
	"Excluding VAT per kg": "Excluding VAT per kg",
	"VAT per kg": "VAT per kg",
	"Including VAT per kg": "Including VAT per kg",
	"Unit": "Unit",
	"Exc. VAT per kg": "Exc. VAT per kg",
	"Inc. VAT per kg": "Inc. VAT per kg",
	"Item description": "Item description",
	"Outgoing invoices templates": "Outgoing invoices templates",
	"Generate invoice": "Generate invoice",
	"Net kg": "Net kg",
	"Gross kg": "Gross kg",
	"Work hours": "Work hours",
	"Add work hours": "Add work hours",
	"Work date": "Work date",
	"Hours": "Hours",
	"Salary": "Salary",
	"I. invoices": "I. invoices",
	"O. invoices": "O. invoices",
	"Reports": "Reports",
	"Salary report": "Salary report",
	"Created by": "Created by",
	"Hour salary": "Hour salary",
	"Volume salary": "Volume salary",
	"Total salary": "Total salary",
	"Total": "Total",
	"Confirm archiving": "Confirm archiving",
	"Are you sure to archive this loading?": "Are you sure to archive this loading?",
	"Czech name official": "Czech name official",
	"English name official": "English name official",
	"Please enter an amount in KG to send to galvanisation": "Please enter an amount in KG to send to galvanisation",
	"Actions": "Actions",
	"Galvanisation": "Galvanisation",
	"Company type": "Company type",
	"Invoice type": "Invoice type",
	"Invoice subtype": "Invoice subtype",
	"Send to galvanisation": "Send to galvanisation",
	"Please enter an amount": "Please enter an amount",
	"Team": "Team",
	"Read emails": "Read emails",
	"Supply lists templates": "Supply lists templates",
	"Supply lists": "Supply lists",
	"Supply list number": "Supply list number",
	"Truck plate 1": "Truck plate 1",
	"Truck plate 2": "Truck plate 2",
	"Order number": "Order number",
	"Order date": "Order date",
	"Add supply list": "Add supply list",
	"Invoice": "Invoice",
	"Add item": "Add item",
	"Palette number": "Palette number",
	"Add material": "Add material",
	"Save and create supply list": "Save and create supply list",
	"Mass print": "Mass print",
	"Contract number": "Contract number",
	"Additional text": "Additional text",
	"Store partially kg": "Store partially kg",
	"Partial storing cancelled or invalid value": "Partial storing cancelled or invalid value",
	"Delivery address": "Delivery address",
	"Invoices - additional items": "Invoices - additional items",
	"Contracts": "Contracts",
	"Filled": "Filled",
	"Company specific product types": "Company specific product types",
	"Company specific names": "Company specific names",
	"Deadline": "Deadline",
	"Ordered quantity": "Ordered quantity",
	"Supplied quantity": "Supplied quantity",
	"Drivers": "Drivers",
	"Passport number": "Passport number",
	"Loading lists": "Loading lists",
	"Loading lists templates": "Loading lists templates",
	"Templates supply/loading lists": "Templates supply/loading lists",
	"Supply and loading lists templates": "Supply and loading lists templates",
	"Amount to pay": "Amount to pay",
	"Driver": "Driver",
	"Driver name": "Driver name",
	"Driver address": "Driver address",
	"Driver passport": "Driver passport",
	"Year": "Year",
	"Month": "Month",
	"Receipt number": "Receipt number",
	"Receipt date": "Receipt date",
	"Download": "Download",
	"Uploaded photo": "Uploaded photo",
	"Move to storage": "Move to storage",
	"Mass printing": "Mass printing",
	"Invoice": "Invoice",
	"Weighting": "Weighting",
	"CMR": "CMR",
	"Other": "Other",
	"Loadings for accountants": "Loadings for accountants",
	"Positive": "Positive",
	"Expenses": "Expenses",
	"Cash expenses": "Cash expenses",
	"Expense types": "Expense types",
	"Expenses columns": "Expenses columns",
	"Updated by": "Updated by",
	"Manager": "Manager",
	"Amount": "Amount",
	"Select manager": "Select manager",
	"Expense type": "Expense type",
	"Before period": "Before period",
	"During period": "During period",
	"Difference": "Difference",
	"Add expense": "Add expense",
	"Final calculation": "Final calculation",
	"Provision pay": "Provision pay",
	"Weight netto": "Weight netto",
	"Invoice pay": "Invoice pay",
	"Cash expenses pay": "Cash expenses pay",
	"Expenses total": "Expenses total",
	"Provision date": "Provision date",
	"Provision": "Provision",
	"Not processed material": "Not processed material",
	"Production results": "Production results",
	"Plain": "Plain",
	"Table": "Table",
	"Manager provision": "Manager provision",
	"Top manager provision": "Top manager provision",
	"Top manager": "Top manager",
	"Provise to": "Provise to",
	"Cashier's report": "Cashier's report",
	"Revenues": "Revenues",
	"Cost": "Cost",
	"Balance to": "Balance to",
	"Choose continent": "Choose continent",
	"Choose country": "Choose country",
	"Find the city": "Find the city",
	"Find a company": "Find a company",
	"Invoice report": "Invoice report",
	"Speditions": "Speditions",
	"E-mail templates": "E-mail templates",
	"Weighted": "Weighted",
	"Loadings expenses": "Loadings expenses",
	"Loadings xpenses total": "Loadings xpenses total",
	"Archive": "Archive",
	"All": "All",
	"Actual": "Actual",
	"Transportation pay": "Transportation pay",
	"Outinvoice item names": "Outinvoice item names",
	"Additional texts": "Additional texts",
	"Companies": "Společnosti",
	"Selected": "Selected",
	"Select all": "Select all",
	"Title": "Název",
	"VAT rate %": "VAT rate %",
	"Pre-invoice": "Pre-invoice",
	"Post-invoice": "Post-invoice",
	"Transferred VAT": "Transferred VAT",
	"CNB Rate": "CNB Rate",
	"Contract number": "Contract number",
	"Outinvoice items": "Outinvoice items",
	"Empty": "Empty",
	"Pay to": "Pay to",
	
	
	"Processed pcs.": "Processed pcs.",
	"Create supplylist": "Create supplylist",
	"Sale": "Sale",
	"Supplylist": "Supplylist",
	"Outinvoice number": "Outinvoice number"
};

export default messages;