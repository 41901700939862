import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select from 'react-select'
interface Props {
    onAdd: (data) => void;
}

class UserAdd extends Component<Props> {
    state = { name: "", email:"", password:"",role:"",active: "" };
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name, email, password, role, active } = this.state;
        this.props.onAdd({  name, email, password, role, active });
    }

    inputHandler(e) {

    if (e!=null) {
        var val = '';
        e.map((i) => val += i.value + '//');

        this.setState({ role: val.slice(0, -2) })

    }
}

    render() {
        const {  name, email, password, role, active } = this.state;

        const optionsArray = [];
        JSON.parse(localStorage.getItem('roles')).map((e)=>{
            optionsArray.push({value: e.name, label:e.name});
        });

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Email')}</label>
                                <input
                                    type="email"
                                    required
                                    className="form-control"
                                    value={email}
                                    placeholder={this.props.t('Enter the ')+this.props.t('Email')}
                                    onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Active')}</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value="1"
                                    onChange={(event) => this.setState({ active: event.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Password')}</label>
                                <input
                                    type="password"
                                    required
                                    className="form-control"
                                    value={password}
                                    placeholder={this.props.t('Enter the ')+this.props.t('Password')}
                                    onChange={(event) =>
                                        this.setState({ password: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Role')}</label>


                                <Select

                                    isMulti
                                    name="role"

                                    onChange={this.inputHandler.bind(this)}
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={optionsArray} />


                            </div>
                        </div>

                        <div className="col-2">

                        </div>
                    </div>

                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
UserAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(UserAdd);

