"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentLayoutElementConfigT = class UIComponentLayoutElementConfigT extends UIComponentBaseConfigT {

	#Grow = null;
	
	#Shrink = null;
	
	#Basis = null;
	
	#AlignSelf = null;
	
	constructor(
		Grow = "1",
		Shrink = "1",
		Basis = "auto",
		AlignSelf = "auto",
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.GrowSet( Grow );
		
		this.ShrinkSet( Shrink );
		
		this.BasisSet( Basis );
		
		this.AlignSelfSet( AlignSelf );
		
	}
	
	GrowGet(
	
	){
			
		return this.#Grow;
			
	}
	
	GrowSet(
		Grow
	){
		
		if( typeof( Grow ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#Grow = Grow;
			
	}
	
	ShrinkGet(
	
	){
			
		return this.#Shrink;
			
	}
	
	ShrinkSet(
		Shrink
	){
		
		if( typeof( Shrink ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#Shrink = Shrink;
			
	}
	
	BasisGet(
	
	){
			
		return this.#Basis;
			
	}
	
	BasisSet(
		Basis
	){
		
		if( typeof( Basis ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#Basis = Basis;
			
	}
	
	AlignSelfGet(
	
	){
			
		return this.#AlignSelf;
			
	}
	
	AlignSelfSet(
		AlignSelf
	){
		
		if( typeof( AlignSelf ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#AlignSelf = AlignSelf;
			
	}
	
	StyleMapGet(
	
	){
			
		return super.StyleMapGet( );
			
	}
	
	Copy(
	
	){
		
		return new UIComponentLayoutElementConfigT(
		
			this.GrowGet( ),
			this.ShrinkGet( ),
			this.BasisGet( ),
			this.AlignSelfGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentLayoutElementConfigT;