"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentBaseT from "../Base/ComponentBaseT.mjs";

import ComponentTextConfigT from "../../../Type/Component/Text/ComponentTextConfigT.mjs";

import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentTextT = class ComponentTextT extends ComponentBaseT {

	#ComponentTextConfig = null;
	
	#Value = null;
	
	#ClickCb = null;
	#ChangeCb = null;
	#FocusCb = null;
	#BlurCb = null;
	
	constructor(
		GUIElement,
		ComponentTextConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( ComponentTextConfig instanceof ComponentTextConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentTextConfig
		);
		
		this.PlaceholderSet( ComponentTextConfig.PlaceholderGet( ) );
		
		this.#ComponentTextConfig = ComponentTextConfig;
		
		this.#Value = "";
		
	}
	
	Update( ){
		
		super.Update( );
		
	}
	
	ListenInner(
		EventMap
	){
		
		if( ( EventMap instanceof EventMapT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		if( EventMap.IsExists( "InputFocus" ) ){
						
			this.#FocusCb = EventMap.Get( "InputFocus" );
			
		}
		
		if( EventMap.IsExists( "InputBlur" ) ){
			
			this.#BlurCb = EventMap.Get( "InputBlur" );
			
		}
		
		if( EventMap.IsExists( "InputChange" ) ){
			
			this.#ChangeCb = EventMap.Get( "InputChange" );
			
		}
		
		if( EventMap.IsExists( "InputClick" ) ){
			
			this.#ClickCb = EventMap.Get( "InputClick" );
			
		}
		
	}
	
	ListenParent( EventMap ){
		
		this.ListenInner( EventMap );
		
		this.ListenEvents(  );
		
	}
	
	ListenEvents( ){
		
		this.EventAppend( 
			"click", 
			this.#OnClick.bind( this )
		);
		
		this.EventAppend( 
			"input", 
			this.#OnChange.bind( this )
		);
		
		this.EventAppend( 
			"focus", 
			this.#OnFocus.bind( this )
		);
		
		this.EventAppend( 
			"blur", 
			this.#OnBlur.bind( this )
		);
		
	}
	
	Listen(
		EventBucket
	){
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		
		if( EventBucket !== null ){
		
			let Name = this.#ComponentTextConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
		
				if( EventMap !== null ){
					
					this.ListenInner( EventMap );

				}
				
			}
			
		}
			
			
		
		this.ListenEvents(  );
		
	}
	
	#MatchDefault( ValueString ){
		
		if( typeof( ValueString ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		let ValueMatch = ValueString.match( 
			new RegExp( 
				"^.{0,65535}$",
				"g" 
			) 
		);
		
		return ValueMatch[ 0 ];
		
	}
	
	#Match( ValueString ){
		
		if( typeof( ValueString ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		let ValueMatch = ValueString.match( 
			new RegExp( 
				this.#ComponentTextConfig.RegExpMatchGet( ), 
				"g" 
			) 
		);
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#OnFocus( ){
		
		console.log( "ComponentText.#OnFocus" );
		
		if( this.#FocusCb !== null ){
			
			this.#FocusCb(
				this.#ComponentTextConfig.NameGet( )
			);
			
		}
		
	}
	
	#OnBlur( ){
		
		console.log( "ComponentText.#OnBlur" );
		
		if( this.#BlurCb !== null ){
			
			this.#BlurCb(
				this.#ComponentTextConfig.NameGet( )
			);
			
		}
		
	}
	
	#OnChange( Ev ){
		
		Ev.preventDefault( );
		
		
		let Value = Ev.target.value;
		
		console.log( "ComponentText.#OnChange", Value );
		
		
		let MatchDefaultValue = this.#MatchDefault( Value );
		
		if( MatchDefaultValue === null ){
			
			this.ValueSet( this.#Value );
			
			return;
			
		}
		
		console.log( "Default match success", MatchDefaultValue );
		
		let MatchValue = this.#Match( MatchDefaultValue );
		
		if( MatchValue === null ){
			
			this.ValueSet( this.#Value );
			
			return;
			
		}
		
		console.log( "Match success", MatchValue );
		
		
		this.ValueSet( MatchValue );
			
		
		if( this.#ChangeCb !== null ){
		
			this.#ChangeCb(
				this.#ComponentTextConfig.NameGet( ),
				MatchValue
			);
		
		}
		
	}
	
	#OnClick( ){
		
		console.log( "ComponentTextT.OnClick" );
		
		if( this.#ClickCb !== null ){
		
			this.#ClickCb(
				this.#ComponentTextConfig.NameGet( )
			);
		
		}
		
	}
	
	ValueCheck( ){
		
		console.log( "ComponentTextT.ValueCheck", this.#Value, this.#ComponentTextConfig.RegExpValidationGet( ) );
		
		let ValueMatch = this.#Value.match( 
			new RegExp( 
				this.#ComponentTextConfig.RegExpValidationGet( ),
				"g" 
			)
		);
		
		if( !ValueMatch ){
			
			return false;
			
		}
		
		return true;
		
	}
	
	ValueSet( Value ){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Value = Value;
		
		this.Element( ).Render( ).value = Value;
		
	}
	
	ValueGet( ){
		
		return this.#Value;
		
	}
	
	PlaceholderSet(
		Value
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		//this.#Value = Value;
		
		this.Element( ).Render( ).placeholder = Value; //TODO Fix
		
	}
	
	StyleMapSet( 
		StyleMap
	){
		
		for( let I of StyleMap.Keys( ) ){
			
			this.StyleSet( I, StyleMap.Get( I ) );
			
		}
		
	}
	
	/*#Parse( ValueString ){
		
		if( typeof( ValueString ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ValueString === "" ){
			
			return 0.0;
			
		}
		
		let ValueDouble = parseFloat( ValueString );
		
		if( isNaN( ValueDouble ) ){
		
			throw new Error( "Invalid double value" );
		
		}
		
		return ValueDouble;
		
	}
	
	#Serialize( ValueDouble ){
		
		if( typeof( ValueDouble ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( isNaN( ValueDouble ) ){
		
			throw new Error( "Invalid double value" );
		
		}
		
		
		return ValueDouble.toString( 10 );
		
	}
	
	ValueSet( ValueString ){
		
		let ValueDouble = this.#Parse( ValueString );
		
		
		this.#Value = ValueDouble;
		
		this.Element( ).Render( ).value = ValueString;
		
	}
	
	ValueGet( ){
		
		return this.#Serialize( this.#Value );
		
	}*/
				
};


export default ComponentTextT;