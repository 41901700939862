"use strict";


import APIFieldListT from "../../../../Field/APIFieldListT.mjs";

import APIModelNameT from "../../../Name/APIModelNameT.mjs";


const APIModelSchemaRequestModelOptionT = class APIModelSchemaRequestModelOptionT {
	
	#APIModelName = null;
	
	#APIFieldList = null;
	
	constructor(
		APIModelName,
		APIFieldList
	){
		
		if( ( APIModelName instanceof APIModelNameT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( APIFieldList instanceof APIFieldListT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		
		this.#APIModelName = APIModelName;
		
		this.#APIFieldList = APIFieldList;
		
	}
	
	APIModelName(
	
	){
		
		return this.#APIModelName;
		
	}

	APIFieldList(
	
	){
		
		return this.#APIFieldList;
		
	}
	
	ToJSON( ){
		
		return {
			ModelName: this.#APIModelName.ToJSON( ),
			FieldList: this.#APIFieldList.ToJSON( )
		};
		
	}
	
};


export default APIModelSchemaRequestModelOptionT;