"use strict";


import APIModelCallResponseElementMapT from "./Element/APIModelCallResponseElementMapT.mjs";


const APIModelCallResponseT = class APIModelCallResponseT {
	
	#ElementMap = null;
	
	constructor(
		APIModelCallResponseElementMap
	){
		
		if( ( APIModelCallResponseElementMap instanceof APIModelCallResponseElementMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#ElementMap = APIModelCallResponseElementMap;
		
	}
	
	ElementMap(
		
	){
		
		return this.#ElementMap;
		
	}
	
	ToJSON( ){
			
		let JSONData = { };
		
		
		JSONData[ "CallMap" ] = this.#ElementMap.ToJSON( );
		
			
		return JSONData;
		
	}
	
	static FromJSON( 
		JSONData
	){
		
		console.log( JSONData );
		
		return new APIModelCallResponseT(
			APIModelCallResponseElementMapT.FromJSON( JSONData[ "CallMap" ] )
		);
		
	}
	
};



export default APIModelCallResponseT;