"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIComponentTextConfigT from "../Text/UIComponentTextConfigT.mjs";

import UIComponentLayoutElementConfigT from "../Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLayoutHConfigT from "../Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentImageConfigT from "../Image/UIComponentImageConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentTextDropDownConfigT = class UIComponentTextDropDownConfigT extends UIComponentLayoutHConfigT {
	
	#UIComponentTextConfig = null;
	#UIComponentSeparatorConfig = null;
	#UIComponentImageConfig = null;
	
	#UIComponentLayoutElementConfigText = null;
	#UIComponentLayoutElementConfigSeparator = null;
	#UIComponentLayoutElementConfigImage = null;
	
	constructor(
		UIComponentTextConfig,
		UIComponentSeparatorConfig,
		UIComponentImageConfig,
		
		UIComponentLayoutElementConfigText,
		UIComponentLayoutElementConfigSeparator,
		UIComponentLayoutElementConfigImage,
		
		Wrap = "nowrap",
		JustifyContent = "flex-start",
		AlignItems = "stretch",
		AlignContent = "stretch",
		ColumnGap = "0",
		
		UIStateMap = null
	){
		
		
		super(
			Wrap,
			JustifyContent,
			AlignItems,
			AlignContent,
			ColumnGap,
			
			UIStateMap
		);

	
		this.TextConfigSet( UIComponentTextConfig );
		
		this.SeparatorConfigSet( UIComponentSeparatorConfig );
		
		this.ImageConfigSet( UIComponentImageConfig );
		
		this.LayoutElementConfigTextSet( UIComponentLayoutElementConfigText );
		
		this.LayoutElementConfigSeparatorSet( UIComponentLayoutElementConfigSeparator );
		
		this.LayoutElementConfigImageSet( UIComponentLayoutElementConfigImage );
	
	}
	
	LayoutElementConfigTextSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentLayoutElementConfigText = UIComponentLayoutElementConfig;
		
	}
	
	LayoutElementConfigTextGet(
		UIComponentLayoutElementConfig
	){
		
		return this.#UIComponentLayoutElementConfigText;
		
	}
	
	LayoutElementConfigSeparatorSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentLayoutElementConfigSeparator = UIComponentLayoutElementConfig;
		
	}
	
	LayoutElementConfigSeparatorGet(
		UIComponentLayoutElementConfig
	){
		
		return this.#UIComponentLayoutElementConfigSeparator;
		
	}
	
	LayoutElementConfigImageSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentLayoutElementConfigImage = UIComponentLayoutElementConfig;
		
	}
	
	LayoutElementConfigImageGet(
		UIComponentLayoutElementConfig
	){
		
		return this.#UIComponentLayoutElementConfigImage;
		
	}
	
	TextConfigGet(
	
	){
			
		return this.#UIComponentTextConfig;
			
	}
	
	TextConfigSet(
		UIComponentTextConfig
	){
		
		if( ( UIComponentTextConfig instanceof UIComponentTextConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentTextConfig = UIComponentTextConfig;
		
	}
	
	SeparatorConfigGet(
	
	){
			
		return this.#UIComponentSeparatorConfig;
			
	}
	
	SeparatorConfigSet(
		UIComponentSeparatorConfig
	){
		
		if( ( UIComponentSeparatorConfig instanceof UIComponentBaseConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentSeparatorConfig = UIComponentSeparatorConfig;
		
	}
	
	ImageConfigGet(
	
	){
			
		return this.#UIComponentImageConfig;
			
	}
	
	ImageConfigSet(
		UIComponentImageConfig
	){
		
		if( ( UIComponentImageConfig instanceof UIComponentImageConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIComponentImageConfig = UIComponentImageConfig;
		
	}
	
	Copy(
	
	){
		
		return new UIComponentTextDropDownConfigT(
			
			this.TextConfigGet( ),
			this.SeparatorConfigGet( ),
			this.ImageConfigGet( ),
			
			this.LayoutElementConfigTextGet( ),
			this.LayoutElementConfigSeparatorGet( ),
			this.LayoutElementConfigImageGet( ),
			
			this.WrapGet( ),
			this.JustifyContentGet( ),
			this.AlignItemsGet( ),
			this.AlignContentGet( ),
			this.ColumnGapGet( ),
		
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentTextDropDownConfigT;