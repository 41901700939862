
const InputDoubleParser = function( Value ){
	
	if( Value === "" ){
		
		Value = "0";
		
	}
	
	return parseFloat( Value );
		
};


export default InputDoubleParser;