"use strict";


const APIModelSchemaRequestAbstractT = class APIModelSchemaRequestAbstractT {
	
	constructor(

	){
		
	}
	
	ModelName( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	JoinType( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	JoinData(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	ModelOptionMap(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	CustomOptionMap(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	ChildrenMap(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default APIModelSchemaRequestAbstractT;