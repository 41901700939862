"use strict";


import UIElementT from "../../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIComponentButtonConfigT from "../../../../../../../../../Type/UI/Component/Button/UIComponentButtonConfigT.mjs";


import UIComponentLayoutHT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentButtonT from "../../../../../../../../../Interface/UI/Component/Button/UIComponentButtonT.mjs";

import GTSUIComponentBooleanT from "../../../../../../../../../Interface/GTS/UI/Component/Boolean/GTSUIComponentBooleanT.mjs";

import GTSUIComponentTextT from "../../../../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";

import GTSUIComponentSelectTextT from "../../../../../../../../../Interface/GTS/UI/Component/SelectText/GTSUIComponentSelectTextT.mjs";

import GTSUIComponentSelectT from "../../../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIComponentDoubleT from "../../../../../../../../../Interface/GTS/UI/Component/Double/GTSUIComponentDoubleT.mjs";

import TranslatorT from "../../../../../../../../../Interface/Translator/TranslatorT.mjs";

import GTSUIComponentFormSecondaryLineT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Line/GTSUIComponentFormSecondaryLineT.mjs";

import GTSUIComponentFormSecondaryLineElementT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Line/GTSUIComponentFormSecondaryLineElementT.mjs";


const GTSUIModelSaleFormAddItemFormLineT = class GTSUIModelSaleFormAddItemFormLineT extends GTSUIComponentFormSecondaryLineT {
	
	#T = null;
	
	#IsProduct = null;
	#InOutinvoice = null;
	#InSupplylist = null;
	#ProductType = null;
	#Code = null;
	#Description = null;
	#Unit = null;
	#Quantity = null;
	#QuantityBrutto = null;
	#ExcVAT = null;
	#VATRate = null;
	#VAT = null;
	#PaletteNumber = null;
	#ButtonRemove = null;
	
	constructor(
		Locale,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,

		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault,
		
		TransferredVAT,
		NeedOutinvoice,
		NeedSupplylist,
		
		IsProductDefault
	){
		
		
		let T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		//IsProduct
		
		let IsProduct = new GTSUIComponentBooleanT(
			IsProductDefault,
			null
		);
		
		let LineElementIsProduct = new GTSUIComponentFormSecondaryLineElementT(
			IsProduct,
			"40px"
		);
		
		//InOutinvoice
		
		let InOutinvoice = new GTSUIComponentBooleanT(
			false,
			null
		);
		
		let LineElementInOutinvoice = new GTSUIComponentFormSecondaryLineElementT(
			InOutinvoice,
			"40px"
		);
		
		//InSupplylist
		
		let InSupplylist = new GTSUIComponentBooleanT(
			false,
			null
		);
		
		let LineElementInSupplylist = new GTSUIComponentFormSecondaryLineElementT(
			InSupplylist,
			"40px"
		);
		
		//ProductType
		
		let ProductType = new GTSUIComponentSelectT(
			UIOptionMapProductType, 
			true, //IsEmptyValid
			ProductTypeDefault, //DefaultValue
			T.Translate( "Choose" ) //PlaceholderLabel
		);
		
		let LineElementProductType = new GTSUIComponentFormSecondaryLineElementT(
			ProductType,
			"10%"
		);
		
		//Code
		
		let Code = new GTSUIComponentTextT(
			"",
		
			T.Translate( "Enter the value" ),
			
			"^.{0,512}",
			"^.{0,512}$"
		);
		
		let LineElementCode = new GTSUIComponentFormSecondaryLineElementT(
			Code,
			"10%"
		);
		
		//Description
		
		let Description = new GTSUIComponentSelectTextT(
			UIOptionMapDescription, 
			"", //DefaultValue
			false, //EmptyOnFocus
			".{0,32}",
			".{0,32}",
			T.Translate( "Enter the value" ) //PlaceholderLabel
		);
		
		let LineElementDescription = new GTSUIComponentFormSecondaryLineElementT(
			Description,
			"10%"
		);
		
		//Unit
		
		let Unit = new GTSUIComponentSelectT(
			UIOptionMapUnit, 
			false, //IsEmptyValid
			UnitDefault, //DefaultValue
			T.Translate( "Choose" ) //PlaceholderLabel
		);
		
		let LineElementUnit = new GTSUIComponentFormSecondaryLineElementT(
			Unit,
			"8%"
		);
		
		//Quantity
		
		let Quantity = new GTSUIComponentDoubleT(
			0, //ValueDefault
		
			"0", //PlaceholderLabel
			
			false, //ZeroValid
			
			false //CanBeSigned
		);
		
		let LineElementQuantity = new GTSUIComponentFormSecondaryLineElementT(
			Quantity,
			"5%"
		);
		
		//QuantityBrutto
		
		let QuantityBrutto = new GTSUIComponentDoubleT(
			0, //ValueDefault
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		let LineElementQuantityBrutto = new GTSUIComponentFormSecondaryLineElementT(
			QuantityBrutto,
			"5%"
		);
		
		//ExcVAT
		
		let ExcVAT = new GTSUIComponentDoubleT(
			0, //ValueDefault
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		let LineElementExcVAT = new GTSUIComponentFormSecondaryLineElementT(
			ExcVAT,
			"5%"
		);
		
		//VATRate
		
		let VATRate = new GTSUIComponentSelectTextT(
			UIOptionMapVATRate, 
			"5", //DefaultValue
			true, //EmptyOnFocus
			"^[0-9]{0,2}",
			"^[0-9]{1,2}$",
			T.Translate( "Enter the value" ) //PlaceholderLabel
		);
		
		let LineElementVATRate = new GTSUIComponentFormSecondaryLineElementT(
			VATRate,
			"8%"
		);
		
		//VAT
		
		let VAT = new GTSUIComponentDoubleT(
			0, //ValueDefault
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		VAT.EditableSet( false );
		
		let LineElementVAT = new GTSUIComponentFormSecondaryLineElementT(
			VAT,
			"5%"
		);
		
		//PaletteNumber
		
		let PaletteNumber = new GTSUIComponentTextT(
			"",
		
			T.Translate( "Enter the value" ),
			
			"^[0-9]{0,2}",
			"^[0-9]{0,2}$"
		);
		
		let LineElementPaletteNumber = new GTSUIComponentFormSecondaryLineElementT(
			PaletteNumber,
			"5%"
		);
		
		//Button
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"-",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "25px" )
						] )
					)
				] ),
				null
			)
		);
		
		let ButtonRemove = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "38px" ),
							new UIStyleT( "height", "38px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "38px" ),
							new UIStyleT( "height", "38px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "38px" ),
							new UIStyleT( "height", "38px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "38px" ),
							new UIStyleT( "height", "38px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		ButtonRemove.ChildSet( 0, UIComponentLabel );
		
		let LineElementButtonRemove = new GTSUIComponentFormSecondaryLineElementT(
			ButtonRemove,
			"10%"
		);
		
		
		super( [
			LineElementIsProduct,
			LineElementInOutinvoice,
			LineElementInSupplylist,
			LineElementProductType,
			LineElementCode,
			LineElementDescription,
			LineElementUnit,
			LineElementQuantity,
			LineElementQuantityBrutto,
			LineElementExcVAT,
			LineElementVATRate,
			LineElementVAT,
			LineElementPaletteNumber,
			LineElementButtonRemove
		] );
		
		
		this.#T = T;
		
		
		this.#IsProduct = IsProduct;
		
		this.#InOutinvoice = InOutinvoice;
		
		this.#InSupplylist = InSupplylist;
		
		this.#ProductType = ProductType;
		
		this.#Code = Code;
		
		this.#Description = Description;
		
		this.#Unit = Unit;
		
		this.#Quantity = Quantity;
		
		this.#QuantityBrutto = QuantityBrutto;
		
		this.#ExcVAT = ExcVAT;
		
		this.#VATRate = VATRate;
		
		this.#VAT = VAT;
		
		this.#PaletteNumber = PaletteNumber;
		
		this.#ButtonRemove = ButtonRemove;
		
		
		if( IsProductDefault === false ){
		
			this.#ProductType.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#Description.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
			
			this.#Code.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
		
		} else {
			
			this.#ProductType.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
			
			this.#Description.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#Code.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
		
		if( TransferredVAT === false ){
		
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else {
			
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
		if( NeedOutinvoice === true ){
		
			this.#InOutinvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			if( this.#InOutinvoice.ValueGet( ) === true ){
				
				this.#ExcVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
				this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
				this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
				
			} else {
				
				this.#ExcVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
				this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
				this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
				
			}
		
		} else {
			
			this.#InOutinvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#ExcVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
		
		}
		
		if( NeedSupplylist === true ){
		
			this.#InSupplylist.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			if( this.#InSupplylist.ValueGet( ) === true ){
				
				this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "black" ) ] ) );
				
			} else {
				
				this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
				
			}
		
		} else {
			
			this.#InSupplylist.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
		
		}
		
	}
	
	Start(
	
	){
		
		this.#IsProduct.Start( );
		
		this.#InOutinvoice.Start( );
		
		this.#InSupplylist.Start( );
		
		this.#ProductType.Start( );
		
		this.#Code.Start( );
		
		this.#Description.Start( );
		
		this.#Unit.Start( );
		
		this.#Quantity.Start( );
		
		this.#QuantityBrutto.Start( );
		
		this.#ExcVAT.Start( );
		
		this.#VATRate.Start( );
		
		this.#VAT.Start( );
		
		this.#PaletteNumber.Start( );
		
		this.#ButtonRemove.Start( );
		
		
		super.Start( );
		
	}
	
	#OnValueChangeQuantity(
		Value, 
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddItemT.#OnValueChangeQuantity", Value, IsValid );
		
		if( IsValid === true ){
			
			if( this.#ExcVAT.IsValidGet( ) === true ){
			
				if( this.#VATRate.IsValidGet( ) === true ){
		
					let VATRate = parseFloat( this.#VATRate.ValueGet( ) );
					
					let Result = ( this.#ExcVAT.ValueGet( ) / 100 ) * VATRate * Value;
					
					this.#VAT.ValueSet( Result, false );
				
				}
			
			}
		
		}
		
		this.#OnValueChange( Value, IsValid );
		
	}
	
	#OnValueChangeExcVAT(
		Value, 
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddItemT.#OnValueChangeExcVAT", Value, IsValid );
		
		if( IsValid === true ){
			
			if( this.#Quantity.IsValidGet( ) === true ){
			
				if( this.#VATRate.IsValidGet( ) === true ){
			
					let VATRate = parseFloat( this.#VATRate.ValueGet( ) );
					
					let Result = ( Value / 100 ) * VATRate * this.#Quantity.ValueGet( );
					
					this.#VAT.ValueSet( Result, false );
				
				}
		
			}
			
		}
		
		this.#OnValueChange( Value, IsValid );
		
	}
	
	#OnValueChangeVATRate(
		Value, 
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddItemT.#OnValueChangeVATRate", Value, IsValid );
		
		console.log( IsValid, this.#Quantity.IsValidGet( ), this.#ExcVAT.IsValidGet( ) );
		
		if( IsValid === true ){
			
			if( this.#Quantity.IsValidGet( ) === true ){
			
				if( this.#ExcVAT.IsValidGet( ) === true ){
			
					let VATRate = parseFloat( Value );
					
					let Result = ( this.#ExcVAT.ValueGet( ) / 100 ) * VATRate * this.#Quantity.ValueGet( );
					
					this.#VAT.ValueSet( Result, false );
				
				}
			
			}
		
		}
		
		
		this.#OnValueChange( Value, IsValid );
		
	}
	
	IsValidGet(
	
	){
		
		let ItemIsValid = true;
		
		//IsProduct
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#IsProduct.IsValidGet( );
			
		}
		
		//InOutinvoice
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#InOutinvoice.IsValidGet( );
			
		}
		
		//InSupplylist
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#InSupplylist.IsValidGet( );
			
		}
		
		//ProductType
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#ProductType.IsValidGet( );
			
		}
		
		//Code
		
		if( ItemIsValid === true ){
			
			if( this.#InOutinvoice.ValueGet( ) === true ){
			
				ItemIsValid = this.#Code.IsValidGet( );
			
			} else {
				
				ItemIsValid = true;
				
			}
			
		}
		
		//Description
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#Description.IsValidGet( );
			
		}
		
		//Unit
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#Unit.IsValidGet( );
			
		}
		
		//Quantity
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#Quantity.IsValidGet( );
			
		}
		
		//QuantityBrutto
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#QuantityBrutto.IsValidGet( );
			
		}
		
		//ExcVAT
		
		if( ItemIsValid === true ){
			
			if( this.#InOutinvoice.ValueGet( ) === true ){
			
				ItemIsValid = this.#ExcVAT.IsValidGet( );
			
			} else {
				
				ItemIsValid = true;
				
			}
			
		}
		
		//VATRate
		
		if( ItemIsValid === true ){
			
			if( this.#InOutinvoice.ValueGet( ) === true ){
			
				ItemIsValid = this.#VATRate.IsValidGet( );
			
			} else {
				
				ItemIsValid = true;
				
			}
			
		}
		
		//VAT
		
		if( ItemIsValid === true ){
			
			if( this.#InOutinvoice.ValueGet( ) === true ){
			
				ItemIsValid = this.#VAT.IsValidGet( );
			
			} else {
				
				ItemIsValid = true;
				
			}
			
		}
		
		//PaletteNumber
		
		if( ItemIsValid === true ){
			
			ItemIsValid = this.#PaletteNumber.IsValidGet( );
			
		}
		
		
		return ItemIsValid;
		
	}
	
	JSONDataIsValidGet(
	
	){
		
		let JSONDataIsValid = { };
		
		//IsProduct
		
		JSONDataIsValid[ "is_material" ] = this.#IsProduct.IsValidGet( );
		
		//InOutinvoice
		
		JSONDataIsValid[ "in_outinvoice" ] = this.#InOutinvoice.IsValidGet( );
		
		//InSupplylist
		
		JSONDataIsValid[ "in_supplylist" ] = this.#InSupplylist.IsValidGet( );
		
		//ProductType
		
		JSONDataIsValid[ "product_type" ] = this.#ProductType.IsValidGet( );
		
		//Code
		
		JSONDataIsValid[ "code" ] = this.#Code.IsValidGet( );
		
		//Description
		
		JSONDataIsValid[ "description" ] = this.#Description.IsValidGet( );
		
		//Unit
		
		JSONDataIsValid[ "unit" ] = this.#Unit.IsValidGet( );
		
		//Quantity
		
		JSONDataIsValid[ "qty" ] = this.#Quantity.IsValidGet( );
		
		//QuantityBrutto
		
		JSONDataIsValid[ "qty_gross" ] = this.#QuantityBrutto.IsValidGet( );
		
		//ExcVAT
		
		
		
		JSONDataIsValid[ "excvat" ] = this.#ExcVAT.IsValidGet( );
		
		//VATRate
		
		JSONDataIsValid[ "vat_rate" ] = this.#VATRate.IsValidGet( );
		
		//VAT
		
		JSONDataIsValid[ "vat" ] = this.#VAT.IsValidGet( );
		
		//PaletteNumber
		
		JSONDataIsValid[ "palette_number" ] = this.#PaletteNumber.IsValidGet( );
		
		
		return JSONDataIsValid;
		
	}
	
	ValueGet( ){
		
		let JSONData = { };

		JSONData[ "is_material" ] = this.#IsProduct.ValueGet( );
		
		JSONData[ "in_outinvoice" ] = this.#InOutinvoice.ValueGet( );

		JSONData[ "in_supplylist" ] = this.#InSupplylist.ValueGet( );
		
		JSONData[ "product_type" ] = this.#ProductType.ValueGet( );
		
		JSONData[ "product_type_label" ] = ( this.#ProductType.ValueGet( ) === null ) ? "" : this.#ProductType.LabelGet( );
		
		JSONData[ "code" ] = this.#Code.ValueGet( );
		
		JSONData[ "description" ] = this.#Description.ValueGet( );
		
		JSONData[ "unit" ] = this.#Unit.ValueGet( );
		
		JSONData[ "unit_label" ] = ( this.#Unit.ValueGet( ) === null ) ? "" : this.#Unit.LabelGet( );
		
		JSONData[ "qty" ] = this.#Quantity.ValueGet( );
		
		JSONData[ "qty_gross" ] = this.#QuantityBrutto.ValueGet( );
		
		JSONData[ "excvat" ] = this.#ExcVAT.ValueGet( );
		
		JSONData[ "vat_rate" ] = this.#VATRate.ValueGet( );
		
		JSONData[ "vat" ] = this.#VAT.ValueGet( );
		
		JSONData[ "palette_number" ] = this.#PaletteNumber.ValueGet( );
		
		return JSONData;
		
	}
	
	#OnValueChange( Value, IsValid ){
		
		let ThisIsValid = this.IsValidGet(  );
		
		let JSONData = this.ValueGet( );
		
		let JSONDataIsValid = this.JSONDataIsValidGet( );
		
		let IsValidTotal = this.IsValidGet(  );
		
		
		let EventFn = super.EventGet( "ItemChange" );
		
		EventFn(
			JSONData, IsValidTotal, JSONDataIsValid
		);
		
	}
	
	#OnButtonRemoveClick(  ){
				
		console.log( "Remove", this )
		
		let EventFn = this.EventGet( "Remove" );
		
		EventFn( this );
				
	}
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#VATRate.ValueSet( "0", false );
		
		this.#VAT.ValueSet( 0, false );
		
		if( TransferredVAT === false ){
		
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else {
			
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
	}
	
	NeedOutinvoiceToggle( 
		NeedOutinvoice
	){
		
		this.#InOutinvoice.ValueSet( false );
		
		if( NeedOutinvoice === true ){
		
			this.#InOutinvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else {
			
			//this.#Code.ValueSet( "", false );
			
			this.#InOutinvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			//this.#Code.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
	}
	
	NeedSupplylistToggle( 
		NeedSupplylist
	){
		
		this.#InSupplylist.ValueSet( false );
		
		if( NeedSupplylist === true ){
		
			this.#InSupplylist.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else {
			
			this.#PaletteNumber.ValueSet( "", false );
			
			this.#InSupplylist.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
		
		}
		
	}
	
	#OnIsProductChange(
		Value, 
		IsValid
	){
		
		this.#ProductType.ValueSet( null, false );
		
		this.#Description.ValueSet( "", false );
		
		if( Value === true ){
			
			this.#ProductType.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
				
			);
			
			this.#Code.StyleMapSet( 
				new UIStyleMapT( [ 
					new UIStyleT( "display", "none" ) 
				] ) 
			);
			
			this.#Description.StyleMapSet( 
			
				new UIStyleMapT( [ 
					new UIStyleT( "display", "none" )
				] ) 
					
			);
			
		} else {
			
			this.#ProductType.StyleMapSet(
			
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
				
			);
			
			this.#Code.StyleMapSet( 
				new UIStyleMapT( [ 
					new UIStyleT( "display", "flex" ) 
				] ) 
			);
			
			this.#Description.StyleMapSet( 
			
				new UIStyleMapT( [ 
					new UIStyleT( "display", "flex" )
				] ) 
					
			);
			
		}
		
		this.#OnValueChange(
			Value,
			IsValid
		);
		
	}
	
	#OnInOutinvoiceChange(
		Value, 
		IsValid
	){
		
		//this.#Code.ValueSet( "", false );
		
		//this.#Description.ValueSet( "", false );
		
		if( Value === true ){
			
			//this.#Code.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
			//this.#Description.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#ExcVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
			
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "flex" ) ] ) );
		
		} else {
			
			this.#ExcVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VATRate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#VAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			//this.#Code.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
		
			//this.#Description.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
		this.#OnValueChange(
			Value,
			IsValid
		);
		
	}
	
	#OnInSupplylistChange(
		Value, 
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddItemFormLineT.#OnInSupplylistChange", Value, IsValid );
		
		this.#PaletteNumber.ValueSet( "", false );
		
		if( Value === true ){
			
			this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else {
			
			this.#PaletteNumber.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
		this.#OnValueChange(
			Value,
			IsValid
		);
		
	}
	
	Listen(
		OnRemove,
		OnChange
	){
		
		super.EventSet( "Remove", OnRemove );
		
		super.EventSet( "ItemChange", OnChange );
		
		this.#IsProduct.Listen(
			this.#OnIsProductChange.bind( this )
		);
		
		this.#InOutinvoice.Listen(
			this.#OnInOutinvoiceChange.bind( this )
		);
		
		this.#InSupplylist.Listen(
			this.#OnInSupplylistChange.bind( this )
		);
		
		this.#ProductType.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#Code.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#Description.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#Unit.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#Quantity.Listen(
			this.#OnValueChangeQuantity.bind( this )
		);
		
		this.#QuantityBrutto.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#ExcVAT.Listen(
			this.#OnValueChangeExcVAT.bind( this )
		);
		
		this.#VATRate.Listen(
			this.#OnValueChangeVATRate.bind( this )
		);
		
		this.#VAT.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#PaletteNumber.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#ButtonRemove.Listen(
			this.#OnButtonRemoveClick.bind( this )
		);
		
	}
	
	Render( 
	
	){
		
		return super.Render( );
		
	}
	
};


export default GTSUIModelSaleFormAddItemFormLineT;