import React, {Component} from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import {DateRange} from 'react-date-range';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import en from "date-fns/locale/en-US"; // the locale you want
import cs from "date-fns/locale/cs"; // the locale you want
import SessionStorageService from "../../../services/SessionStorageService";

import CustomPinnedRowRenderer from "../../../aggrid/CustomPinnedRowRenderer.jsx";
import {LoadState, SaveState} from "../../../services/GridStateService";

class InvoicesReport extends Component {

    constructor(props) {
        super(props)
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var loctext;

        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA != null && valueB != null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };


        var date = new Date();
        var startdate = new Date(date.getFullYear(), 0, 1);
        var enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.state = {
            isColumnStateLoaded: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            pinnedTopRowData: [],
            filterRangeDisplay: 'none',
            backgroundOverlayDisplay: 'none',
            filterRange: moment(startdate).format('DD.MM.YYYY') + '-' + moment(enddate).format('DD.MM.YYYY'),
            filterStartDate: startdate,
            filterEndDate: enddate,
            columnDefs: [

                {
                    headerName: this.props.t('Month'),
                    field: 'month',
                    editable: false,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                },
                {
                    headerName: this.props.t('Rozdil'),
                    field: 'difference',
                    editable: false,
                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                {
                    headerName: this.props.t('Vystavené bez DPH'),
                    field: 'outinvoice_exc_vat',
                    editable: false,
                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },

                {
                    headerName: this.props.t('Vystavené DPH'),
                    field: 'outinvoice_vat',
                    editable: false,
                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                {
                    headerName: this.props.t('Vystavené vč. DPH'),
                    field: 'outinvoice_inc_vat',
                    editable: false,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    filter: 'agTextColumnFilter',
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },

                {
                    headerName: this.props.t('Přijaté bez DPH'),
                    field: 'invoice_exc_vat',
                    editable: false,

                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },

                {
                    headerName: this.props.t('Přijaté DPH'),
                    field: 'invoice_vat',
                    editable: false,

                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },
                {
                    headerName: this.props.t('Přijaté vč. DPH'),
                    field: 'invoice_inc_vat',
                    editable: false,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: {'font-weight': 'bold'},
                    },
                    filter: 'agTextColumnFilter',
                    type: 'numericColumn',
                    valueFormatter: function (params) {
                        return parseFloat(params.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }
                },

            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns: true,
                sortable: true,
                menuTabs: ['filterMenuTab', 'columnsMenuTab'],
                suppressContextMenu: true,
            },
            frameworkComponents: {
                customPinnedRowRenderer: CustomPinnedRowRenderer
            }
        };
    }

    getSums(data) {
        const initial = {

            month: this.props.t('Total'),
            invoice_exc_vat: 0,
            invoice_vat: 0,
            invoice_inc_vat: 0,
            outinvoice_exc_vat: 0,
            outinvoice_vat: 0,
            outinvoice_inc_vat: 0,
            difference: 0
        };
        console.log("DATA", data);
        data.forEach((e) => {
            initial.invoice_exc_vat = initial.invoice_exc_vat + parseFloat(e.invoice_exc_vat);
            initial.invoice_vat = initial.invoice_vat + parseFloat(e.invoice_vat);
            initial.invoice_inc_vat = initial.invoice_inc_vat + parseFloat(e.invoice_inc_vat);
            initial.outinvoice_exc_vat = initial.outinvoice_exc_vat + parseFloat(e.outinvoice_exc_vat);
            initial.outinvoice_vat = initial.outinvoice_vat + parseFloat(e.outinvoice_vat);
            initial.outinvoice_inc_vat = initial.outinvoice_inc_vat + parseFloat(e.outinvoice_inc_vat);
            initial.difference += parseFloat(e.difference);
        })

        for (const initialKey in initial) {
            if(initialKey !== 'month'){
                initial[initialKey] = parseFloat(initial[initialKey]).toFixed(1);
            }
        }
        return [initial];
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain() + 'api/reports/invoices_report/' + this.state.filterRange)
            .then(res => {

                this.setState({
                    rowdata: res.data,
                    pinnedTopRowData: this.getSums(res.data)
                });
                setTimeout(() => {
                    LoadState('InvoicesReport', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })

                    let state = [...this.gridColumnApi.getColumnState()];

                    state.find(e => e.colId === 'outinvoice_vat').hide = 'true';
                    state.find(e => e.colId === 'invoice_vat').hide = 'true';

                    this.gridColumnApi.setColumnState(state);
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        LoadState('InvoicesReport', params.columnApi, params.api);
    };


    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return dd + '.' + mm + '.' + yyyy;
    }

    handleFilterDateRangeSelect(ranges) {

        this.setState({
            filterStartDate: ranges.selection.startDate,
            filterEndDate: ranges.selection.endDate,
            filterRange: moment(ranges.selection.startDate).format('DD.MM.YYYY') + '-' + moment(ranges.selection.endDate).format('DD.MM.YYYY'),
        }, () => this.componentDidMount());

        if (ranges.selection.startDate != ranges.selection.endDate) {
            this.setState({

                filterRangeDisplay: 'none'
            });
        }

    }


    handleFilterRangeChange(e) {

        this.setState({filterRange: e.target.value})
        var t = e.target.value.split('-');

        var start = moment(t[0], "DD.MM.YYYY", true);
        var end = moment(t[1], "DD.MM.YYYY", true);
        console.log(start.isValid());
        console.log(end.isValid());
        if (start.isValid() && end.isValid() && end >= start) {
            this.setState({
                filterStartDate: start.toDate(),
                filterEndDate: end.toDate(),

            }, () => this.componentDidMount());
        }
    }

    onColumnsStateChanged(params) {
        if (this.state.isColumnStateLoaded) {
            SaveState('InvoicesReport', params.columnApi, params.api);
        }
    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

        var daterange_locale = en;
        if (locale == 'cs') daterange_locale = cs;
        const selectionRange = {
            startDate: this.state.filterStartDate,
            endDate: this.state.filterEndDate,
            key: 'selection',
        }
        return (
            <div className="wrapper">
                <div id="backgroundOverlay" onClick={() => {
                    this.setState({

                        filterRangeDisplay: 'none',
                        backgroundOverlayDisplay: 'none'
                    });
                }} style={{display: this.state.backgroundOverlayDisplay}}></div>
                <Layout>
                    <Content title={this.props.t('Report faktur - přepočteno na Kč')}>
                        <div style={{float: 'left'}}>
                            <input style={{width: '190px', display: 'inline-block'}}
                                   onChange={this.handleFilterRangeChange.bind(this)}
                                   className="form-control" type="text" value={this.state.filterRange} onClick={() => {

                                this.setState({backgroundOverlayDisplay: 'block', filterRangeDisplay: 'block'});
                            }}/>


                            <div className="daterange_flt" style={{display: this.state.filterRangeDisplay}}>
                                <DateRange

                                    ranges={[selectionRange]}
                                    showMonthAndYearPickers={false}
                                    moveRangeOnFirstSelection={false}
                                    locale={daterange_locale}
                                    onChange={this.handleFilterDateRangeSelect.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="tool-buttons">


                            <button style={{marginLeft: '8px', position: 'relative', top: '3px'}}
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>


                        </div>


                        <div style={{'height': '100%', width: '100%'}} id="invoicesReportGrid"
                             className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                pinnedTopRowData={this.state.pinnedTopRowData}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

InvoicesReport.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(InvoicesReport);

