import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select';
interface Props {
    onSell: (data) => void;
}

class SendToGalv  extends Component<Props> {

    blankOption={value: "", label:this.props.t('Choose')};

    companiesData=JSON.parse(localStorage.getItem('companies'));
    productTypesData=JSON.parse(localStorage.getItem('product_types'));
    currenciesData=JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);

    bankAccountsData=JSON.parse(localStorage.getItem('bank_accounts'));
    paymentMethodsData=JSON.parse(JSON.parse(localStorage.getItem('columns_136')).data);
    unitsData=JSON.parse(localStorage.getItem('units'));


    constructor(props) {
        super(props)


        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');


        this.state = {
            materials:this.materials(),
            company: "",
            company_value: this.blankOption,
            sale_date: "",
            qty: 0,

            allow_submit: false,


        };

    }


    componentDidMount() {

    }

    materials() {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var materials=[];
        if (this.props.product_type) {
            JSON.parse(localStorage.getItem('product_types')).map((e) => {

                if (e.id==this.props.product_type) materials.push({
                    qty: '',
                    qty_gross: '',
                    product_type: e.id,
                    unit_excvat: '',
                    unit_vat: '',
                    product_type_value: {value: e.id, label: e['name_' + locale]}
                });

            });
        } else {
            materials.push({qty:'', qty_gross:'', product_type:'', unit_excvat:'', unit_vat:'', product_type_value:{value: "", label:this.props.t('Choose')}});
        }

        return materials;
    }

    componentDidUpdate(prevProps) {
	
		console.log( "sendtogalv", prevProps.tmp, this.props.tmp );

        if (prevProps.tmp !== this.props.tmp) {

			console.log( "sendtogalv2", this.state.sale_date );

            var locale = 'cs';
            if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');


            this.setState({
                materials:this.materials(),
                company: "",
                sale_date:"",
                qty:0,

                allow_submit:false,
                company_value: this.blankOption,

            })

        }

    }
    handleFormSubmit(event, create_supplylist) {
        event.preventDefault();
        const {company, sale_date, materials
        } = this.state;
        var product_type=this.props.product_type;
        this.props.onSell({company, materials, sale_date, create_supplylist
        });
    }


    handleMaterialOnChange(event, i, type) {

        if (type==='product_type') {
            var materials = this.state.materials;
            materials[i][type]=event.value;
            materials[i][type+'_value']=event;
            this.setState({materials:materials});
            this.allow_submit();
        }


        else if (type==='qty' || type==='qty_gross'){
            if (event.target.value=='') event.target.value=0;
            var materials = this.state.materials;
            materials[i][type]=event.target.value;
            this.setState({materials:materials});
            this.allow_submit();
        }




    }

    allow_submit() {

        var allow_submit=true;



        if (this.state.company==''
        ) allow_submit=false;

        this.setState({allow_submit:allow_submit});
    }



    handleChange(event, type, inputType) {

        var stateValue={};


        if (inputType==='select') {
            stateValue[type]=event.value;
            stateValue[type+'_value']=event;
        }

        if (inputType==='text') {
            stateValue[type]=event.target.value;




        }


        if (inputType==='date') {

            if (typeof event==="object") {
                stateValue[type]=event.format("DD.MM.YYYY");
            } else {
                stateValue[type]=event;
            }
        }

        this.setState(stateValue, ()=>{this.allow_submit();});


    }



    render() {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        const {company, company_value, qty,sale_date
        } = this.state;

        var companies = [];

        this.companiesData.map((e)=>{
            if (e.company_type==1) return false;
            companies.push({value: e.id, label:e['name']});
        });

        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            if ((parseInt(e.product_type)===2 || parseInt(e.product_type)===1) && this.props.available_product_types.includes(e.id)) optionsArray.push({value: e.id, label:e['name_'+locale]});


        });

        companies=companies.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        companies.unshift(this.blankOption);

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Company')}</label>
                                <Select
                                    name="company"
                                    onChange={(event) => this.handleChange(event,'company', 'select')}
                                    value={this.state.company_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={companies} />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Sale date')}</label>
                                <Datetime
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={ sale_date === "" ? null : sale_date }
                                    inputProps={{required:true}}
                                    onChange={(event) => this.handleChange(event, 'sale_date', 'date')}
									renderInput={(props) => {
										return <input {...props} value={(sale_date) ? props.value : ''} />
									}}
                                />
                            </div>
                        </div>

                        <div className="col-3" >
                            <label htmlFor="description">{this.props.t('Add material')}</label>
                            <div className="form-group">

                                <div className="btn-group">
                                    <button className="btn btn-primary"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                var materials=this.state.materials;
                                                materials.push({qty:'', qty_gross:'', product_type:'', unit_excvat:'', unit_vat:'', product_type_value:{value: "", label:this.props.t('Choose')}});
                                                this.setState({materials:materials});
                                                this.allow_submit();
                                            }}><i className="fa fa-plus"></i></button>
                                    <button className="btn btn-primary"
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                var materials=this.state.materials;
                                                materials.pop();
                                                this.setState({materials:materials});
                                                this.allow_submit();
                                            }}
                                            disabled={this.state.materials.length===1 ? true : false}><i className="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>


                        {
                            this.state.materials.map((material, i) => (
                                <div className="row">


                                    <div className="col-4">
                                        <div className="form-group">

                                            <Select key={'product_type_'+i}
                                                    name="product_type"

                                                    onChange={(event) => this.handleMaterialOnChange(event, i, 'product_type')}
                                                    value={material.product_type_value}
                                                    required
                                                    placeholder={this.props.t('Select')}
                                                    classNamePrefix="select"
                                                    options={optionsArray} />
                                        </div>
                                    </div>


                                    <div className="col-4">
                                        <div className="form-group">

                                            <input
                                                type="text"

                                                pattern="[.0-9]*"
                                                className="form-control"
                                                value={material.qty}
                                                placeholder={this.props.t('Net kg')}
                                                onChange={(event) =>
                                                    this.handleMaterialOnChange(event,i,'qty')
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-group">
                                            <input
                                                type="text"

                                                pattern="[.0-9]*"
                                                className="form-control"
                                                value={material.qty_gross}
                                                placeholder={this.props.t('Gross kg')}
                                                onChange={(event) =>
                                                    this.handleMaterialOnChange(event,i,'qty_gross')
                                                }
                                            />
                                        </div>
                                    </div>









                                </div>
                            ))
                        }






                    </div>






                </div>
                <button style={{marginRight:'10px'}} disabled={!this.state.allow_submit}  className="btn btn-success">{this.props.t('Save')}</button>
                <button disabled={!this.state.allow_submit}  onClick={(event) => this.handleFormSubmit(event, 'yes')} className="btn btn-success">{this.props.t('Save and create supply list')}</button>
            </form>
        );
    }
}
SendToGalv.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SendToGalv);

