"use strict";


import UIStateT from "../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIOptionT from "../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../Type/UI/Option/UIOptionMapT.mjs";

import UIElementT from "../../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIComponentLayoutHConfigT from "../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutHT from "../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectTextT from "../../../../../Interface/UI/Component/SelectText/UIComponentSelectTextT.mjs";


import UIComponentBaseConfigT from "../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentTextDataConfigT from "../../../../../Type/UI/Component/TextData/UIComponentTextDataConfigT.mjs";

import UIComponentTextConfigT from "../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectTextConfigT from "../../../../../Type/UI/Component/SelectText/UIComponentSelectTextConfigT.mjs";

import UIComponentPlaceholderConfigT from "../../../../../Type/UI/Component/Placeholder/UIComponentPlaceholderConfigT.mjs";

import TranslatorT from "../../../../../Interface/Translator/TranslatorT.mjs";


const GTSUIComponentSelectTextT = class GTSUIComponentSelectTextT extends UIComponentSelectTextT {
	
	constructor( 
		UIOptionMap,
		ValueDefault,
		EmptyOnFocus,
		RegExpMatch,
		RegExpValidation,
		PlaceholderLabel,
		
		Id = null
	){
		
		let UIComponentTextDataConfig = new UIComponentTextDataConfigT(
			ValueDefault,
			EmptyOnFocus, //EmptyOnFocus
			RegExpMatch,
			RegExpValidation,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentTextT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" )
					] )
				),
				new UIStateT( 
					"ValidFocused",
					"UIComponentTextT Valid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" )
					] )
				),
				new UIStateT( 
					"Valid",
					"UIComponentTextT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" )
					] )
				),
				new UIStateT( 
					"InvalidFocused",
					"UIComponentTextT Invalid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentTextT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" )
					] )
				)
			] ),
		
			null //Id
			
		);
		
		let UIComponentPlaceholderConfig = new UIComponentPlaceholderConfigT(
		
			PlaceholderLabel,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentPlaceholderT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "100" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" )
					] )
				)
			] ),
			
			null
		);
		
		let UIComponentTextConfig = new UIComponentTextConfigT(
		
			UIComponentPlaceholderConfig,
			UIComponentTextDataConfig,
			
			new UIStateMapT( [
				new UIStateT( 
					"Valid", //TODO Default
					"UIComponentTextT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "absolute" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentTextT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "absolute" )
					] )
				),
			] )
			
		);
		
		let UIComponentBaseConfig = new UIComponentBaseConfigT(
			new UIStateMapT( [
				new UIStateT(
					"Default",
					"UIComponentSeparatorVT",
					new UIStyleMapT( [
						new UIStyleT( "background", "linear-gradient( 0deg, rgba(255,255,255,1) 0%, rgba(128,128,128,1) 50%, rgba(255,255,255,1) 100% )" ),
						new UIStyleT( "width", "1px" ),
						new UIStyleT( "height", "100%" )
					] )
				)
			] )
		);
		
		let UIComponentImageConfig = new UIComponentImageConfigT(
			"url(\"https://icons.veryicon.com/png/o/miscellaneous/simple-and-round-line-mark/down-arrow-56.png\")",
		
			"100%",
			"100%",
	
			"center",
	
			"70%",
		
			"no-repeat",
			
			new UIStateMapT( [
			
			] )
		);
		
		let UIComponentLayoutElementConfigText = new UIComponentLayoutElementConfigT(
			"1",
			"1",
			"89%",
			"auto",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					
					"UIComponentLayoutElementT", //ClassName
			
					new UIStyleMapT( [
						new UIStyleT( "position", "relative" )
					] )
					
				)
				
			] )

		);
		
		let UIComponentLayoutElementConfigSeparator = new UIComponentLayoutElementConfigT(
			"1",
			"1",
			"1%",
			"auto",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					
					"UIComponentLayoutElementT", //ClassName
			
					new UIStyleMapT( [
						new UIStyleT( "min-width", "1px" ),
						new UIStyleT( "width", "1px" )
					] )
					
				)
				
			] )
			
		);
		
		let UIComponentLayoutElementConfigImage = new UIComponentLayoutElementConfigT(
			"0",
			"0",
			"0",
			"auto",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					
					"UIComponentLayoutElementT", //ClassName
			
					new UIStyleMapT( [
						new UIStyleT( "min-width", "36px" ),
						new UIStyleT( "min-height", "36px" ),
						new UIStyleT( "width", "36px" ),
						new UIStyleT( "height", "36px" ),
					] )
					
				)
				
			] )
	
		);
		
		let UIComponentTextDropDownConfig = new UIComponentTextDropDownConfigT(
			UIComponentTextConfig,
			UIComponentBaseConfig,
			UIComponentImageConfig,
			
			UIComponentLayoutElementConfigText,
			UIComponentLayoutElementConfigSeparator,
			UIComponentLayoutElementConfigImage,
		
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
		
			new UIStateMapT( [
				new UIStateT(
					"Default",
					"UIComponentTextDropDownT Valid", // ClassName
					new UIStyleMapT( [ //UIStyleMapValid
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "36px" )
					] )
				),
				new UIStateT(
					"Valid",
					"UIComponentTextDropDownT Valid", // ClassName
					new UIStyleMapT( [ //UIStyleMapValid
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "36px" )
					] )
				),
				new UIStateT(
					"Invalid",
					"UIComponentTextDropDownT Invalid", //ClassNameValid
					new UIStyleMapT( [//UIStyleMapInvalid
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "36px" )
					] )
				)
			] )
			
		);
		
		
		let UIComponentCheckListConfig = new UIComponentCheckListConfigT(
			false, //IsMultiple
			true,
			[ ],//ValueDefault
			UIOptionMap, //UIOptionMap
				
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",

				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
					
						new UIStyleMapT( [
							new UIStyleT( "background-color", "white" ),
							new UIStyleT( "font-weight", "400" )
						] )
					),
			
					new UIStateT(
						"Checked",
						"UIComponentLayoutElementT Checked", //ClassName
					
						new UIStyleMapT( [
							new UIStyleT( "background-color", "white" ),
							new UIStyleT( "font-weight", "700" )
						] )
					),
					
					new UIStateT(
						"Unchecked",
						"UIComponentLayoutElementT Unchecked", //ClassName
					
						new UIStyleMapT( [
							new UIStyleT( "background-color", "white" ),
							new UIStyleT( "font-weight", "400" )
						] )
					),
					
					new UIStateT(
						"Hovered",
						"UIComponentLayoutElementT Hovered", //ClassName
					
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" )
						] )
					),
					
					new UIStateT(
						"Unhovered",
						"UIComponentLayoutElementT Unhovered", //ClassName
					
						new UIStyleMapT( [
							new UIStyleT( "background-color", "white" )
						] )
					)
					
				] )
				
			),
			
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [ 
			
				new UIStateT(
					"Default",
					"UIComponentCheckListT",
					new UIStyleMapT( [
						new UIStyleT( "position", "absolute" ),
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "background-color", "white" ),
						new UIStyleT( "z-index", "1" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "border-radius", "0.25rem" ),
						new UIStyleT( "padding", "0.375rem 0.75rem" ),
						new UIStyleT( "font-size", "1rem" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "overflow-x", "hidden" ),
						new UIStyleT( "overflow-y", "auto" )
					] )
				)
			
			] )
			
		);
		
		let UIComponentLayoutElementConfigTextDropDown = new UIComponentLayoutElementConfigT(
			"0",
			"0",
			"auto",
			"auto",
			
			new UIStateMapT( [
			
				new UIStateT( 
					"Default",
					
					"UIComponentLayoutElementT", //ClassName
			
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
				
		);
		
		let UIComponentLayoutElementConfigCheckList = new UIComponentLayoutElementConfigT(
			"0",
			"0",
			"auto",
			"auto",
			
			new UIStateMapT( [
			
				new UIStateT( 
					"Default",
					
					"UIComponentLayoutElementT", //ClassName
			
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "position", "relative" )
					] )
				)
				
			] )
			
		);
		
		let UIComponentSelectTextConfig = new UIComponentSelectTextConfigT(
			UIComponentTextDropDownConfig,
			UIComponentCheckListConfig,
			UIComponentLayoutElementConfigTextDropDown,
			UIComponentLayoutElementConfigCheckList,
			
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentSelectT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"ValidFocused",
					"UIComponentSelectT Valid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Valid",
					"UIComponentSelectT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"InvalidFocused",
					"UIComponentSelectT Invalid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentSelectT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 0px 0px" )
					] )
				)
			] )
		);
		
		
		super(
			new UIElementT( ),
			UIComponentSelectTextConfig
		);
		
		
		this.#OnCheckListHide( );
		
		let TextDropDown = super.TextDropDownGet( );
		
		let Text = TextDropDown.TextGet( );
		
		let TextData = Text.TextDataGet( );
		
		if( TextData.ValueGet( ) === "" ){
			
			this.#OnPlaceholderShow( );
			
		} else {
			
			this.#OnPlaceholderHide( );
			
		}
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextDropDownT.Start" );
		
		
		super.Start( );
		
	}
	
	Stop(
	
	){
		
		console.log( "UIComponentTextDropDownT.Stop" );
		
		
		super.Stop( );
		
	}
	
	#OnPlaceholderHide( 
	
	){
		
		console.log( "GTSUIComponentSelectT.#OnPlaceholderHide" );
		
		
		let UIComponentTextDropDown = super.TextDropDownGet( );
		
		let UIComponentText = UIComponentTextDropDown.TextGet( );
		
		
		let UIComponentTextData = UIComponentText.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "block" )
			] )
		);
		
		let UIComponentPlaceholder = UIComponentText.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "none" )
			] )
		);
		
	}
	
	#OnPlaceholderShow(
	
	){
		
		console.log( "GTSUIComponentSelectT.#OnPlaceholderShow" );
		
		
		let UIComponentTextDropDown = super.TextDropDownGet( );
		
		let UIComponentText = UIComponentTextDropDown.TextGet( );
		
		
		let UIComponentTextData = UIComponentText.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "none" )
			] )
		);
			
		let UIComponentPlaceholder = UIComponentText.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "block" )
			] )
		);
			
	}
	
	#OnCheckListHide(
	
	){
		
		console.log( "GTSUIComponentSelectT.#OnCheckListHide" );
		
		
		let UIComponentCheckList = super.CheckListGet( );
		
		
		UIComponentCheckList.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "hidden" )
			] )
		);
		
	}
	
	#OnCheckListShow(
	
	){
		
		console.log( "GTSUIComponentSelectT.#OnCheckListShow" );
		
		
		let UIComponentCheckList = super.CheckListGet( );
		
			
		UIComponentCheckList.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "visible" )
			] )
		);
			
	}
	
	#OnTextDropDownChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIComponentSelectT.#OnTextDropDownChange", Value, IsValid );
		
		
		let UIComponentCheckList = super.CheckListGet( );
		
		for(
			let I = 0;
			I < UIComponentCheckList.ChildrenCount( );
			I++
		){
			
			console.log( "GTSUIComponentSelectT.#OnTextDropDownChange", I );
			
			let UIComponentCheckListElement = UIComponentCheckList.ChildGet( I );
			
			let UIComponentCheckListElementConfig = UIComponentCheckListElement.ConfigGet( );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( );
			
			let UIOptionValue = UIOption.ValueGet( );
			
			if( UIOptionValue.indexOf( Value ) !== -1 ){
				
				UIComponentCheckListElement.StyleMapSet(
					new UIStyleMapT( [
						new UIStyleT( "display", "flex" )
					] )
				);
				
			} else {
				
				UIComponentCheckListElement.StyleMapSet(
					new UIStyleMapT( [
						new UIStyleT( "display", "none" )
					] )
				);
				
			}
			
		}
		
		//TODO FilterOptions this.#OptionsMap = 
		
	}
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super.Listen( 
			OnChangeFn,
			this.#OnPlaceholderShow.bind( this ),
			this.#OnPlaceholderHide.bind( this ),
			this.#OnCheckListShow.bind( this ),
			this.#OnCheckListHide.bind( this ),
			this.#OnTextDropDownChange.bind( this )
		);
		
	}
	
	Unlisten( 
	
	){
		
		super.Unlisten( );
		
	}
	
};


export default GTSUIComponentSelectTextT;