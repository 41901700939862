"use strict";


import UIStateMapT from "../../State/UIStateMapT.mjs";

import UIComponentConfigAbstractT from "../_/UIComponentConfigAbstractT.mjs";


const UIComponentBaseConfigT = class UIComponentBaseConfigT extends UIComponentConfigAbstractT {
	
	#UIStateMap = null;
	
	constructor(
		UIStateMap
	){
		
		super( );
		
		
		this.StateMapSet( UIStateMap );
		
	}
	
	StateMapSet(
		UIStateMap 
	){
		
		if( UIStateMap !== null ){
		
			if( ( UIStateMap instanceof UIStateMapT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.#UIStateMap = ( UIStateMap !== null ) ? UIStateMap.Copy( ) : null;	
		
	}
	
	StateMapGet( 
	
	){
		
		return ( this.#UIStateMap !== null ) ? this.#UIStateMap.Copy( ) : null;
		
	}
	
	Copy( ){
		
		return new UIComponentBaseConfigT( 
			this.StateMapGet( )
		);
		
	}
				
};


export default UIComponentBaseConfigT;