"use strict";


import APIModelSchemaRequestAbstractT from "./_/APIModelSchemaRequestAbstractT.mjs";

import APIModelKeyT from "../../Key/APIModelKeyT.mjs";

import APIModelNameT from "../../Name/APIModelNameT.mjs";

import APIModelSchemaRequestJoinTypeT from "./Join/APIModelSchemaRequestJoinTypeT.mjs";

import APIModelSchemaRequestJoinDataT from "./Join/APIModelSchemaRequestJoinDataT.mjs";

import APIModelSchemaRequestModelOptionMapT from "./ModelOption/APIModelSchemaRequestModelOptionMapT.mjs";

import APIModelSchemaRequestCustomOptionMapT from "./CustomOption/APIModelSchemaRequestCustomOptionMapT.mjs";

import APIModelSchemaRequestChildrenMapT from "./Children/APIModelSchemaRequestChildrenMapT.mjs";


const APIModelSchemaRequestT = class APIModelSchemaRequestT extends APIModelSchemaRequestAbstractT {
	
	#ModelName = null;
	
	#JoinType = null;
	#JoinData = null;
	
	#ModelOptionMap = null;
	#CustomOptionMap = null;
	
	#ChildrenMap = null;
	
	
	constructor(
		ModelName,
		
		JoinType,
		JoinData,
		
		ModelOptionMap,
		CustomOptionMap,
		
		ChildrenMap
	){
		
		if( ( ModelName instanceof APIModelNameT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( JoinType instanceof APIModelSchemaRequestJoinTypeT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( JoinData instanceof APIModelSchemaRequestJoinDataT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ModelOptionMap instanceof APIModelSchemaRequestModelOptionMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( CustomOptionMap instanceof APIModelSchemaRequestCustomOptionMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ChildrenMap instanceof APIModelSchemaRequestChildrenMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		super( );
		
		
		this.#ModelName = ModelName;
		
		this.#JoinType = JoinType;
		
		this.#JoinData = JoinData;
		
		this.#ModelOptionMap = ModelOptionMap;
		
		this.#CustomOptionMap = CustomOptionMap;
		
		this.#ChildrenMap = ChildrenMap;
		
	}
	
	ModelName( 
	
	){
		
		return this.#ModelName;
		
	}
	
	JoinType( 
	
	){
		
		return this.#JoinType;
		
	}
	
	JoinData(
	
	){
		
		return this.#JoinData;
		
	}
	
	ModelOptionMap(
	
	){
		
		return this.#ModelOptionMap;
		
	}
	
	CustomOptionMap(
	
	){
		
		return this.#CustomOptionMap;
		
	}
	
	ChildrenMap(
	
	){
		
		return this.#ChildrenMap;
		
	}
	
	static FromJSON(
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelSchemaRequestList = [ ];
		
		let JSONChildrenMap = JSONData[ "ChildrenMap" ];
		
		for(
			let I in JSONChildrenMap
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelSchemaRequestList.push(
				APIModelSchemaRequestT.FromJSON(
					JSONChildrenMap[ I ]
				)
			);
			
		}
		
		let ChildrenMap = new APIModelSchemaRequestChildrenMapT(
			APIModelKeyList,
			APIModelSchemaRequestList
		);

		
		return new APIModelSchemaRequestT(
			APIModelNameT.FromJSON( JSONData[ "ModelName" ] ),
			APIModelSchemaRequestJoinTypeT.FromJSON( JSONData[ "JoinType" ] ),
			APIModelSchemaRequestJoinDataT.FromJSON( JSONData[ "JoinData" ] ),
			APIModelSchemaRequestModelOptionMapT.FromJSON( JSONData[ "ModelOptionMap" ] ),
			APIModelSchemaRequestCustomOptionMapT.FromJSON( JSONData[ "CustomOptionMap" ] ),
			ChildrenMap
		);
		
	}
	
	ToJSON(
	
	){
	
		let JSONData = {
			
		};
		
		let ChildrenMap = {
			
		};
		
		for(
			let I of this.#ChildrenMap.Keys( )
		){
			
			ChildrenMap[ I ] = ( this.#ChildrenMap.Get( I ) ).ToJSON( )
			
		}
		
		JSONData[ "ModelName" ] = this.#ModelName.ToJSON( );
		
		JSONData[ "JoinType" ] = this.#JoinType.ToJSON( );
		
		JSONData[ "JoinData" ] = this.#JoinData.ToJSON( );
		
		JSONData[ "ModelOptionMap" ] = this.#ModelOptionMap.ToJSON( );
		
		JSONData[ "CustomOptionMap" ] = this.#CustomOptionMap.ToJSON( );
		
		JSONData[ "ChildrenMap" ] = ChildrenMap;
		
		return JSONData;
	
	}
	
};


export default APIModelSchemaRequestT;