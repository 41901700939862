"use strict";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIOptionT from "../../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";


import UIComponentBaseConfigT from "../../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";


import TranslatorT from "../../../../../../../../Interface/Translator/TranslatorT.mjs";



import GTSUIComponentFormPrimaryLineElementT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineElementT.mjs";

import GTSUIComponentFormPrimaryLineT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineT.mjs";

import GTSUIComponentFormPrimaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/GTSUIComponentFormPrimaryT.mjs";


import GTSUIComponentSelectT from "../../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";

import GTSUIComponentBooleanT from "../../../../../../../../Interface/GTS/UI/Component/Boolean/GTSUIComponentBooleanT.mjs";

import GTSUIComponentTextT from "../../../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";

import GTSUIComponentDoubleT from "../../../../../../../../Interface/GTS/UI/Component/Double/GTSUIComponentDoubleT.mjs";

import GTSUIComponentDateT from "../../../../../../../../Interface/GTS/UI/Component/Date/GTSUIComponentDateT.mjs";

import GTSUIComponentSelectTextT from "../../../../../../../../Interface/GTS/UI/Component/SelectText/GTSUIComponentSelectTextT.mjs";


const GTSUIModelSaleFormAddMainT = class GTSUIModelSaleFormAddMainT extends GTSUIComponentFormPrimaryT {
	
	#T = null;
	
	#UIComponentLabelStateMapDefault = null;
	
	//1
	
	#OutinvoiceNumber = null;
	
	//2
	
	//#Company = null;
	#PaymentMethod = null;
	#PayTo = null;
	//#Currency = null;
	#CNB = null;
	#TransferredVAT = null;
	
	//3
	
	#ReceiptNumber = null;
	#ReceiptDate = null;
	
	//4
	
	#IssueDate = null;
	#DueDate = null;
	#TaxableDate = null;
	#PaidDate = null;
	
	//5
	
	#ConstantSymbol = null;
	#SpecificSymbol = null;
	#Note = null;
	
	//6
	
	#OutinvoiceType = null;
	#PreInvoice = null;
	#PostInvoice = null;
	
	//7
	
	#AdditionalText = null;
	
	//8
	
	#ExcVAT = null; 
	#VAT = null; 
	#IncVAT = null;
	
	
	#Line1 = null;
	#Line2 = null;
	#Line3 = null;
	#Line4 = null;
	#Line5 = null;
	#Line6 = null;
	#Line7 = null;
	#Line8 = null;
	
	//Add
	
	#OutinvoiceModelOptionMap = null;
	#OutinvoicePrepareJSONValue = null;
	#AdditionalTextModelOptionMap = null;
	
	
	#Default = null;
	
	
	constructor( 
		Locale,
		
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre,
		
		//UIOptionMapCompany,
		//CompanyDefault,
	
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToMethodDefault,
	
		//UIOptionMapCurrency,
		//CurrencyDefault,
		
		ReceiptNumberDefault,
		
		ReceiptDateDefault,
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault,
		
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault,
		
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault,
		
		UIOptionMapAdditionalText,
		AdditionalTextDefault,
	
		ExcVATDefault,
		VATDefault,
		IncVATDefault,
		
		OutinvoiceModelOptionMap,
		OutinvoicePrepareJSONValue,
		AdditionalTextModelOptionMap
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"20px",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] ),
		
			null, //Id
		
			"UIComponentLayoutVT", //Class
		
			new UIStyleMapT( [ //UIStyleMap
				new UIStyleT( "width", "95%" )
			] ),
		);
		
		super( [
			//new UIElementT( ),
			//UIComponentLayoutVConfig
		] );
		
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		this.#UIComponentLabelStateMapDefault = new UIStateMapT( [
				
			new UIStateT(
				"Default",
				"UIComponentLabelT",
				new UIStyleMapT( [
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" )
				] )
			)
			
		] );
		
		this.#Line1Add(
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre
		);
		
		this.#Line2Add(
			//UIOptionMapCompany,
			//CompanyDefault,
			
			UIOptionMapPaymentMethod,
			PaymentMethodDefault,
			
			UIOptionMapPayTo,
			PayToMethodDefault,
		
			//UIOptionMapCurrency,
			//CurrencyDefault
		);
		
		this.#Line3Add(
			ReceiptNumberDefault,
			
			ReceiptDateDefault
		);
		
		this.#Line4Add(
			IssueDateDefault,
			DueDateDefault,
			TaxableDateDefault,
			PaidDateDefault
		);
		
		this.#Line5Add(
			ConstantSymbolDefault,
			SpecificSymbolDefault,
			NoteDefault
		);
		
		this.#Line6Add(	
			UIOptionMapOutinvoiceType,
			OutinvoiceTypeDefault,
			
			UIOptionMapPreInvoice,
			PreInvoiceDefault,
			
			UIOptionMapPostInvoice,
			PostInvoiceDefault
		);
		
		this.#Line7Add(
			UIOptionMapAdditionalText,
			AdditionalTextDefault
		);
		
		this.#Line8Add(
			ExcVATDefault,
			VATDefault,
			IncVATDefault
		);
		
		
		this.#OutinvoiceModelOptionMap = OutinvoiceModelOptionMap;
		
		this.#OutinvoicePrepareJSONValue = OutinvoicePrepareJSONValue;
		
		this.#AdditionalTextModelOptionMap = AdditionalTextModelOptionMap;
		
	}
	
	PrepareJSONValueSet(
		OutinvoicePrepareJSONValue
	){
		
		this.#OutinvoicePrepareJSONValue = OutinvoicePrepareJSONValue;
		
		
		let ValueTaxableDate = this.#TaxableDate.ValueGet( );
		
		let ValueOutinvoiceType = this.#OutinvoiceType.ValueGet( );
		
		let Key = null;
		
		if( ValueTaxableDate !== null ){
		
			Key = ( ( ValueOutinvoiceType !== null ) && ( ValueOutinvoiceType === "2" ) ) ? ( "ZAL-" + ValueTaxableDate.substring( 0, 7 ) ) : ValueTaxableDate.substring( 0, 7 );
			
		} else {
			
			Key = ( new Date( ) ).toISOString( ).substring( 0, 7 );
			
		}
		
		
		
		let OutInvoiceNumber = this.#OutinvoicePrepareJSONValue[ Key ];
		
		this.#OutinvoiceNumber.ValueSet( OutInvoiceNumber, false );
		
	}
	
	IsValidTotalGet(
	
	){
	
		let IsValid = true;
		
		//1
		
		if( this.#OutinvoiceNumber.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//2
		
		//if( this.#Company.IsValidGet( ) === false ){

		//	IsValid = false;
		
		//}
		
		if( this.#PaymentMethod.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#PayTo.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//if( this.#Currency.IsValidGet( ) === false ){

		//	IsValid = false;
		
		//}
		
		if( this.#CNB.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#TransferredVAT.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//3
		
		if( this.#ReceiptNumber.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#ReceiptDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//4
		
		if( this.#IssueDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#DueDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#TaxableDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#PaidDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//5
		
		if( this.#ConstantSymbol.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#SpecificSymbol.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#Note.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
	
		//6
		
		if( this.#OutinvoiceType.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#PreInvoice.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#PostInvoice.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		//7
		
		if( this.#AdditionalText.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		//8
		
		if( this.#ExcVAT.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#VAT.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#IncVAT.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		return IsValid;
	
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		//1
		
		JSONData[ "invoice_number" ] = this.#OutinvoiceNumber.ValueGet( );
		
		//2
		
		//JSONData[ "company" ] = this.#Company.ValueGet( );
		
		JSONData[ "payment_method" ] = this.#PaymentMethod.ValueGet( );
		
		JSONData[ "pay_to" ] = this.#PayTo.ValueGet( );
		
		//JSONData[ "currency" ] = this.#Currency.ValueGet( );
		
		JSONData[ "cnb" ] = this.#CNB.ValueGet( );
		
		JSONData[ "transferred_vat" ] = this.#TransferredVAT.ValueGet( );
		
		//3
		
		JSONData[ "receipt_number" ] = this.#ReceiptNumber.ValueGet( );
		
		JSONData[ "receipt_date" ] = this.#ReceiptDate.ValueGet( );
		
		//4
		
		JSONData[ "invoice_issue" ] = this.#IssueDate.ValueGet( );
		
		JSONData[ "invoice_due" ] = this.#DueDate.ValueGet( );
		
		JSONData[ "invoice_taxable" ] = this.#TaxableDate.ValueGet( );
		
		JSONData[ "paid_date" ] = this.#PaidDate.ValueGet( );
		
		//5
		
		JSONData[ "constant_symbol" ] = this.#ConstantSymbol.ValueGet( );
		
		JSONData[ "specific_symbol" ] = this.#SpecificSymbol.ValueGet( );
		
		JSONData[ "note" ] = this.#Note.ValueGet( );
	
		//6
		
		JSONData[ "outinvoice_type" ] = this.#OutinvoiceType.ValueGet( );
		
		JSONData[ "pre_invoice" ] = this.#PreInvoice.ValueGet( );
		
		JSONData[ "post_invoice" ] = this.#PostInvoice.ValueGet( );
	
		//7
	
		JSONData[ "additional_text" ] = this.#AdditionalText.ValueGet( );
	
		//8
		
		JSONData[ "invoice_excvat" ] = this.#ExcVAT.ValueGet( );
		
		JSONData[ "invoice_vat" ] = this.#VAT.ValueGet( );
		
		JSONData[ "invoice_incvat" ] = this.#IncVAT.ValueGet( );
		
		
		return JSONData;
		
	}
	
	#Line1Add( 
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre
	){
		
		let OutinvoiceNumberLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Outinvoice number" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let OutinvoiceNumberComponent = new GTSUIModelOutinvoiceNumberT( 
			this.#T.Translate( "Enter the value" ),
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre
		);
		
		let OutinvoiceNumberLineElement = new GTSUIComponentFormPrimaryLineElementT(
			OutinvoiceNumberLabel,
			OutinvoiceNumberComponent,
			"15%"
		);
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			OutinvoiceNumberLineElement
		] );
		
		
		super.LineSet(
			0,
			GTSUIComponentFormPrimaryLine
		);
		
		
		this.#OutinvoiceNumber = OutinvoiceNumberComponent;
		
	}
	
	#Line2Add( 
		//UIOptionMapCompany,
		//CompanyDefault,
		
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToDefault,
	
		//UIOptionMapCurrency,
		//CurrencyDefault
	){
		
		//Company
		
		/*let CompanyLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Company" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let CompanyComponent = new GTSUIComponentSelectT(
			UIOptionMapCompany,
			false,
			CompanyDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let CompanyLineElement = new GTSUIComponentFormPrimaryLineElementT(
			CompanyLabel,
			CompanyComponent,
			"20%"
		);*/
		
		//PaymentMethod
		
		let PaymentMethodLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Payment method" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let PaymentMethodComponent = new GTSUIComponentSelectT(
			UIOptionMapPaymentMethod,
			false,
			PaymentMethodDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let PaymentMethodLineElement = new GTSUIComponentFormPrimaryLineElementT(
			PaymentMethodLabel,
			PaymentMethodComponent,
			"15%"
		);
		
		//PayTo
		
		let PayToLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Pay to" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let PayToComponent = new GTSUIComponentSelectT(
			UIOptionMapPayTo,
			true,
			PayToDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let PayToLineElement = new GTSUIComponentFormPrimaryLineElementT(
			PayToLabel,
			PayToComponent,
			"15%"
		);
		
		//Currency
		
		/*let CurrencyLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Currency" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let CurrencyComponent = new GTSUIComponentSelectT(
			UIOptionMapCurrency,
			false,
			CurrencyDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let CurrencyLineElement = new GTSUIComponentFormPrimaryLineElementT(
			CurrencyLabel,
			CurrencyComponent,
			"15%"
		);*/
		
		//CNB
		
		let CNBLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "CNB Rate" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let CNBComponent = new GTSUIComponentBooleanT(
			false,
			null
		);
		
		
		let CNBLineElement = new GTSUIComponentFormPrimaryLineElementT(
			CNBLabel,
			CNBComponent,
			"10%"
		);
		
		//TransferredVAT
		
		let TransferredVATLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Transferred VAT" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let TransferredVATComponent = new GTSUIComponentBooleanT(
			false,
			null
		);
		
		
		let TransferredVATLineElement = new GTSUIComponentFormPrimaryLineElementT(
			TransferredVATLabel,
			TransferredVATComponent,
			"10%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			//CompanyLineElement,
			PaymentMethodLineElement,
			PayToLineElement,
			//CurrencyLineElement,
			CNBLineElement,
			TransferredVATLineElement
		] );
		
		super.LineSet(
			1,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		//this.#Company = CompanyComponent;
		
		this.#PaymentMethod = PaymentMethodComponent;
		
		this.#PayTo = PayToComponent;
		
		//this.#Currency = CurrencyComponent;
		
		this.#CNB = CNBComponent;
		
		this.#TransferredVAT = TransferredVATComponent;
		
	}
	
	#Line3Add(
		ReceiptNumberDefault,
		
		ReceiptDateDefault
	){
		
		//ReceiptNumber
		
		let ReceiptNumberLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Receipt number" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let ReceiptNumberComponent = new GTSUIComponentTextT(
			ReceiptNumberDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^.{0,1024}",
			"^.{0,1024}$"
		);
		
		let ReceiptNumberLineElement = new GTSUIComponentFormPrimaryLineElementT(
			ReceiptNumberLabel,
			ReceiptNumberComponent,
			"30%"
		);
		
		//ReceiptDate
		
		let ReceiptDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Receipt date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let ReceiptDateComponent = new GTSUIComponentDateT(

			ReceiptDateDefault,
			true			
			
		);
		
		let ReceiptDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			ReceiptDateLabel,
			ReceiptDateComponent,
			"30%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			ReceiptNumberLineElement,
			ReceiptDateLineElement
		] );
		
		super.LineSet(
			2,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#ReceiptNumber = ReceiptNumberComponent;
		
		this.#ReceiptDate = ReceiptDateComponent;
		
	}
	
	#Line4Add(
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault
	){
		
		//IssueDate
		
		let IssueDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Issue date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let IssueDateComponent = new GTSUIComponentDateT(

			IssueDateDefault,
			false			
			
		);
		
		let IssueDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			IssueDateLabel,
			IssueDateComponent,
			"20%"
		);
		
		//DueDate
		
		let DueDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Due date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let DueDateComponent = new GTSUIComponentDateT(

			DueDateDefault,
			false			
			
		);
		
		let DueDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			DueDateLabel,
			DueDateComponent,
			"20%"
		);
		
		//TaxableDate
		
		let TaxableDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Taxable date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let TaxableDateComponent = new GTSUIComponentDateT(

			TaxableDateDefault,
			false			
			
		);
		
		let TaxableDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			TaxableDateLabel,
			TaxableDateComponent,
			"20%"
		);
		
		//PaidDate
		
		let PaidDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Paid date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let PaidDateComponent = new GTSUIComponentDateT(

			PaidDateDefault,
			true			
			
		);
		
		let PaidDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			PaidDateLabel,
			PaidDateComponent,
			"20%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			IssueDateLineElement,
			DueDateLineElement,
			TaxableDateLineElement,
			PaidDateLineElement
		] );
		
		super.LineSet(
			3,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#IssueDate = IssueDateComponent;
		
		this.#DueDate = DueDateComponent;
		
		this.#TaxableDate = TaxableDateComponent;
		
		this.#PaidDate = PaidDateComponent;
		
	}
	
	#Line5Add(
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault
	){
		
		//ConstantSymbol
		
		let ConstantSymbolLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Constant symbol" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let ConstantSymbolComponent = new GTSUIComponentTextT(

			ConstantSymbolDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^[0-9]{0,4}",
			"^[0-9]{0,4}$"		
			
		);
		
		
		let ConstantSymbolLineElement = new GTSUIComponentFormPrimaryLineElementT(
			ConstantSymbolLabel,
			ConstantSymbolComponent,
			"20%"
		);
		
		//SpecificSymbol
		
		let SpecificSymbolLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Specific symbol" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let SpecificSymbolComponent = new GTSUIComponentTextT(

			SpecificSymbolDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^[0-9]{0,10}",
			"^[0-9]{0,10}$"		
			
		);
		
		let SpecificSymbolLineElement = new GTSUIComponentFormPrimaryLineElementT(
			SpecificSymbolLabel,
			SpecificSymbolComponent,
			"20%"
		);
		
		//Note
		
		let NoteLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Note" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let NoteComponent = new GTSUIComponentTextT(

			NoteDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^.{0,1024}",
			"^.{0,1024}$"	
			
		);
		
		let NoteLineElement = new GTSUIComponentFormPrimaryLineElementT(
			NoteLabel,
			NoteComponent,
			"20%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			ConstantSymbolLineElement,
			SpecificSymbolLineElement,
			NoteLineElement
		] );
		
		super.LineSet(
			4,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#ConstantSymbol = ConstantSymbolComponent;
		
		this.#SpecificSymbol = SpecificSymbolComponent;
		
		this.#Note = NoteComponent;
		
	}
	
	#Line6Add(
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault
	){
		
		//OutinvoiceType
		
		let OutinvoiceTypeLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Invoice type" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let OutinvoiceTypeComponent = new GTSUIComponentSelectT(
			UIOptionMapOutinvoiceType,
			false,
			OutinvoiceTypeDefault,
			this.#T.Translate( "Select" )
		);
		
		let OutinvoiceTypeLineElement = new GTSUIComponentFormPrimaryLineElementT(
			OutinvoiceTypeLabel,
			OutinvoiceTypeComponent,
			"20%"
		);
		
		//PreInvoice
		
		let PreInvoiceLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Pre-invoice" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let PreInvoiceComponent = new GTSUIComponentSelectT(
			UIOptionMapPreInvoice,
			true,
			PreInvoiceDefault,
			this.#T.Translate( "Select" )
		);
		
		let PreInvoiceLineElement = new GTSUIComponentFormPrimaryLineElementT(
			PreInvoiceLabel,
			PreInvoiceComponent,
			"20%"
		);
		
		//PostInvoice
		
		let PostInvoiceLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Post-invoice" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let PostInvoiceComponent = new GTSUIComponentSelectT(
			UIOptionMapPostInvoice,
			true,
			PostInvoiceDefault,
			this.#T.Translate( "Select" )
		);
		
		let PostInvoiceLineElement = new GTSUIComponentFormPrimaryLineElementT(
			PostInvoiceLabel,
			PostInvoiceComponent,
			"20%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			OutinvoiceTypeLineElement,
			PreInvoiceLineElement,
			PostInvoiceLineElement
		] );
		
		super.LineSet(
			5,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#OutinvoiceType = OutinvoiceTypeComponent;
		
		this.#PreInvoice = PreInvoiceComponent;
		
		this.#PostInvoice = PostInvoiceComponent;
		
	}
	
	#Line7Add(
		UIOptionMapAdditionalText,
		AdditionalTextDefault
	){
		
		//AdditionalText
		
		let AdditionalTextLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Additional text" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let AdditionalTextComponent = new GTSUIComponentSelectTextT(
			UIOptionMapAdditionalText, 
			AdditionalTextDefault, //DefaultValue
			false, //EmptyOnFocus
			"^.{0,512}",
			"^.{0,512}$",
			this.#T.Translate( "Enter the value" ) //PlaceholderLabel
		);
		
		let AdditionalTextLineElement = new GTSUIComponentFormPrimaryLineElementT(
			AdditionalTextLabel,
			AdditionalTextComponent,
			"80%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			AdditionalTextLineElement
		] );
		
		super.LineSet(
			6,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#AdditionalText = AdditionalTextComponent;
		
	}
	
	#Line8Add(
		ExcVATDefault,
		VATDefault,
		IncVATDefault
	){
		
		//ExcVAT
		
		let ExcVATLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Excluding VAT" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let ExcVATComponent = new GTSUIComponentDoubleT(
			ExcVATDefault,
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		ExcVATComponent.EditableSet( false );
		
		let ExcVATLineElement = new GTSUIComponentFormPrimaryLineElementT(
			ExcVATLabel,
			ExcVATComponent,
			"20%"
		);
		
		//VAT
		
		let VATLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "VAT" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let VATComponent = new GTSUIComponentDoubleT(
			VATDefault,
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		VATComponent.EditableSet( false );
		
		let VATLineElement = new GTSUIComponentFormPrimaryLineElementT(
			VATLabel,
			VATComponent,
			"20%"
		);
		
		//IncVAT
		
		let IncVATLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Including VAT" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let IncVATComponent = new GTSUIComponentDoubleT(
			IncVATDefault,
		
			"0", //PlaceholderLabel
			
			true, //ZeroValid
			
			true //CanBeSigned
		);
		
		IncVATComponent.EditableSet( false );
		
		let IncVATLineElement = new GTSUIComponentFormPrimaryLineElementT(
			IncVATLabel,
			IncVATComponent,
			"20%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			ExcVATLineElement,
			VATLineElement,
			IncVATLineElement,
		] );
		
		super.LineSet(
			7,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#ExcVAT = ExcVATComponent;
		
		this.#VAT = VATComponent;
		
		this.#IncVAT = IncVATComponent;
		
	}
	
	#OnValueChange(
		Value,
		Invalid
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.OnValueChange",  Value, Invalid );
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let IsValid = this.IsValidTotalGet( );
			
			let Value = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( Value, IsValid );
			
		}
		
	}
	
	Start( ){
		
		//1
		
		this.#OutinvoiceNumber.Start( );
		
		//2
		
		//this.#Company.Start( );
		
		this.#PaymentMethod.Start( );
		
		this.#PayTo.Start( );
		
		//this.#Currency.Start( );
		
		this.#CNB.Start( );
		
		this.#TransferredVAT.Start( );
		
		//3
		
		this.#ReceiptNumber.Start( );
		
		this.#ReceiptDate.Start( );
		
		//4
		
		this.#IssueDate.Start( );
		
		this.#DueDate.Start( );
		
		this.#TaxableDate.Start( );
		
		this.#PaidDate.Start( );
		
		//5
		
		this.#ConstantSymbol.Start( );
		
		this.#SpecificSymbol.Start( );
		
		this.#Note.Start( );
		
		
		//6
		
		this.#OutinvoiceType.Start( );
		
		this.#PreInvoice.Start( );
		
		this.#PostInvoice.Start( );
		
		
		//7
		
		this.#AdditionalText.Start( );
		
		//8
		
		this.#ExcVAT.Start( );
		
		this.#VAT.Start( );
		
		this.#IncVAT.Start( );
		
	}
	
	/*#OnCompanyChange( 
		Value,
		IsValid
	){
		
		this.#ContractNumber.Stop( );
		
		this.#ContactNumberFilter( );
		
		this.#ContractNumber.Start( );
		
		
		this.#PreInvoice.Stop( );
		
		this.#PreInvoiceFilter( );
		
		this.#PreInvoice.Start( );
		
		
		this.#PostInvoice.Stop( );
		
		this.#PostInvoiceFilter( );
		
		this.#PostInvoice.Start( );
		
		
		this.#AdditionalText.Stop( );
		
		this.#AdditionalTextFilter( );
		
		this.#AdditionalText.Start( );
		
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}*/
	
	#OnPaymentMethodChange(
		Value,
		IsValid
	){
		
		if( Value === null ){
			
			this.#PayTo.ValueSet( null, false );
			
			this.#PaidDate.ValueSet( null, false );
			
			
			this.#PayTo.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PaidDate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );

		} else if( Value === "1" ){

			this.#PayTo.ValueSet( null, false );
			
			this.#PaidDate.ValueSet( null, false );
			
			
			this.#PayTo.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PaidDate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );

		} else if( Value === "2" ){

			this.#PayTo.ValueSet( null, false );
			
			this.#PaidDate.ValueSet( null, false );
			
			
			this.#PayTo.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#PaidDate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );

		} else {

			this.#PayTo.ValueSet( null, false );
			
			this.#PaidDate.ValueSet( null, false );
			
			
			this.#PayTo.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PaidDate.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );

		}
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	#OnPayToChange(
		Value,
		IsValid
	){
		
		if( Value === null ){
			
			this.#CNB.ValueSet( false, false );
			
			this.#TransferredVAT.ValueSet( false, false );
			
			
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#TransferredVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		} else {
			
			this.#CNB.ValueSet( false, false );
			
			this.#TransferredVAT.ValueSet( false, false );
			
			
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#TransferredVAT.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		}
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	/*#OnCurrencyChange(
		Value,
		IsValid
	){
		
		if( Value === null ){
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else if( Value === "1" ){
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		} else {
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		}
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}*/
	
	#OnTransferredVATChange(
		Value,
		IsValid
	){
		
		if( super.EventExists( "TransferredVATChange" ) === true ){
			
			let EventFn = super.EventGet( "TransferredVATChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	PreInvoiceFilter( 
		Company
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.#PreInvoiceFilter" );
		
		//let Company = this.#Company.ValueGet( );
		
		let PreInvoiceModelFilterCb = function(
			APIFieldValueMap
		){

			let APIFieldValueCompany = APIFieldValueMap.Get( "company" );
				
			let APIValueCompany = APIFieldValueCompany.Value( );
			
			let ValueCompany = APIValueCompany.Value( );
			
			
			let APIFieldValuePostInvoice = APIFieldValueMap.Get( "post_invoice" );
			
			let APIValuePostInvoice = APIFieldValuePostInvoice.Value( );
			
			let ValuePostInvoice = APIValuePostInvoice.Value( );
			
			
			console.log( Company, ValueCompany );
			
			if( Company === null ){
																	
				return false;
				
			}
			
			if( ValueCompany === null ){
				
				return false;
				
			}
			
			if( Company !== ValueCompany.toString( ) ){
				
				return false;
				
			}
			
			if( ValuePostInvoice !== null ){
																	
				return false;
																	
			}
			
			return true;
			
		}
		
		console.log( this.#OutinvoiceModelOptionMap );
		
		let PreInvoiceModelOptionMap = this.#OutinvoiceModelOptionMap.Filter( PreInvoiceModelFilterCb );
		
		let PreInvoiceUIOptionMap = PreInvoiceModelOptionMap.ToUIOptionMap( "id", "invoice_number" );
		
		console.log( PreInvoiceUIOptionMap, PreInvoiceModelOptionMap );
		
		this.#PreInvoice.Stop( );
	
		this.#PreInvoice.OptionMapSet( PreInvoiceUIOptionMap );
		
		this.#PreInvoice.Start( );
	
	}
	
	CNBByCurrency( 
		Value
	){
		
		if( Value === null ){
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
		
		} else if( Value === "1" ){
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		} else {
			
			this.#CNB.ValueSet( false, false );
	
			this.#CNB.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		}
		
	}
	
	PostInvoiceFilter( 
		Company
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.#PostInvoiceFilter" );
		
		//let Company = this.#Company.ValueGet( );
		
		let PostInvoiceModelFilterCb = function(
			APIFieldValueMap
		){
			
			
			let APIFieldValueCompany = APIFieldValueMap.Get( "company" );
				
			let APIValueCompany = APIFieldValueCompany.Value( );
			
			let ValueCompany = APIValueCompany.Value( );
			
			
			let APIFieldValuePreInvoice = APIFieldValueMap.Get( "pre_invoice" );
			
			let APIValuePreInvoice = APIFieldValuePreInvoice.Value( );
			
			let ValuePreInvoice = APIValuePreInvoice.Value( );
			
			
			console.log( Company, ValueCompany );
			
			if( Company === null ){
																	
				return false;
				
			}
			
			if( ValueCompany === null ){
				
				return false;
				
			}
			
			if( Company !== ValueCompany.toString( ) ){
				
				return false;
				
			}
			
			if( ValuePreInvoice !== null ){
																	
				return false;
																	
			}
			
			return true;
			
		}
		
		console.log( this.#OutinvoiceModelOptionMap );
		
		let PostInvoiceModelOptionMap = this.#OutinvoiceModelOptionMap.Filter( PostInvoiceModelFilterCb );
		
		let PostInvoiceUIOptionMap = PostInvoiceModelOptionMap.ToUIOptionMap( "id", "invoice_number" );
		
		console.log( PostInvoiceUIOptionMap, PostInvoiceModelOptionMap );
		
		this.#PostInvoice.Stop( );
	
		this.#PostInvoice.OptionMapSet( PostInvoiceUIOptionMap );
		
		this.#PostInvoice.Start( );
	
	}
	
	AdditionalTextFilter( 
		Company
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.#AdditionalTextFilter" );
		
		//let Company = this.#Company.ValueGet( );
		
		let AdditionalTextModelFilterCb = function(
			APIFieldValueMap
		){
			
			
			let APIFieldValueCompanyIds = APIFieldValueMap.Get( "company_ids" );
				
			let APIValueCompanyIds = APIFieldValueCompanyIds.Value( );
			
			let ValueCompanyIds = APIValueCompanyIds.Value( );
			
			
			console.log( Company );
			
			if( Company === null ){
																	
				return false;
				
			}
			
			if( ValueCompanyIds === null ){
				
				return false;
				
			}
			
			let CompanyIds =  ValueCompanyIds.split( "//" );
																		
			if( CompanyIds.includes( Company ) === false ){
				
				return false;
				
			}
																
			
			return true;
			
		}
		
		console.log( this.#AdditionalTextModelOptionMap );
		
		let AdditionalTextModelOptionMap = this.#AdditionalTextModelOptionMap.Filter( AdditionalTextModelFilterCb );
		
		let AdditionalTextUIOptionMap = AdditionalTextModelOptionMap.ToUIOptionMap( "id", "text" );
		
		console.log( AdditionalTextUIOptionMap, AdditionalTextModelOptionMap );
		
		
		this.#AdditionalText.Stop( );
	
		this.#AdditionalText.OptionMapSet( AdditionalTextUIOptionMap );
		
		this.#AdditionalText.Start( );
		
		//this.#AdditionalText.ValueSet( "" );
	
	}
	
	#OnTaxableDateChange(
		Value,
		IsValid
	){
		
		let ValueOutinvoiceType = this.#OutinvoiceType.ValueGet( );
															
		
		let Key = null;
		
		if( Value !== null ){
		
			Key = ( ( ValueOutinvoiceType !== null ) && ( ValueOutinvoiceType === "2" ) ) ? ( "ZAL-" + Value.substring( 0, 7 ) ) : Value.substring( 0, 7 );
			
		} else {
			
			Key = ( new Date( ) ).toISOString( ).substring( 0, 7 );
			
		}
		
		
		
		let OutInvoiceNumber = this.#OutinvoicePrepareJSONValue[ Key ];
		
		this.#OutinvoiceNumber.ValueSet( OutInvoiceNumber, false );
		
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	#OnOutinvoiceTypeChange(
		Value,
		IsValid
	){
		
		if( Value === null ){
			
			this.#PreInvoice.ValueSet( null );
			
			this.#PostInvoice.ValueSet( null );
			
			this.#PreInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PostInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		} else if( Value === "1" ){
			
			this.#PreInvoice.ValueSet( null );
			
			this.#PostInvoice.ValueSet( null );
			
			this.#PreInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
			this.#PostInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		} else {
			
			this.#PreInvoice.ValueSet( null );
			
			this.#PostInvoice.ValueSet( null );
			
			this.#PreInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
			this.#PostInvoice.StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
									
															
		let ValueInvoiceTaxable = this.#TaxableDate.ValueGet( );
		
		let Key = null;
		
		if( ValueInvoiceTaxable !== null ){
		
			Key = ( Value === "2" ) ? ( "ZAL-" + ValueInvoiceTaxable.substring( 0, 7 ) ) : ValueInvoiceTaxable.substring( 0, 7 );
		
		} else {
			
			Key = ( Value === "2" ) ? ( "ZAL-" + ( new Date( ) ).toISOString( ).substring( 0, 7 ) ) : ( new Date( ) ).toISOString( ).substring( 0, 7 );
			
		}
		
		
		let OutInvoiceNumber = this.#OutinvoicePrepareJSONValue[ Key ];
		
		this.#OutinvoiceNumber.ValueSet( OutInvoiceNumber, false );
		
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
															
	
	Listen(
		OnTransferredVATChangeFn,
		OnChangeFn
	){
		
		super.EventSet( "TransferredVATChange", OnTransferredVATChangeFn );
		
		super.EventSet( "Change", OnChangeFn );
		
		//1
		
		this.#OutinvoiceNumber.Listen(
			this.#OnValueChange.bind( this )
		);
		
		//2
		
		//this.#Company.Listen( 
		//	this.#OnCompanyChange.bind( this )
		//);
		
		this.#PaymentMethod.Listen( 
			this.#OnPaymentMethodChange.bind( this )
		);
		
		this.#PayTo.Listen( 
			this.#OnPayToChange.bind( this )
		);
		
		//this.#Currency.Listen( 
		//	this.#OnCurrencyChange.bind( this )
		//);
		
		this.#CNB.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#TransferredVAT.Listen( 
			this.#OnTransferredVATChange.bind( this )
		);
		
		//3
		
		this.#ReceiptNumber.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#ReceiptDate.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		//4
		
		this.#IssueDate.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#DueDate.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#TaxableDate.Listen( 
			this.#OnTaxableDateChange.bind( this )
		);
		
		this.#PaidDate.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		//5
		
		this.#ConstantSymbol.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#SpecificSymbol.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#Note.Listen( 
			this.#OnValueChange.bind( this )
		);
	
		//6
		
		this.#OutinvoiceType.Listen( 
			this.#OnOutinvoiceTypeChange.bind( this )
		);
		
		this.#PreInvoice.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#PostInvoice.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		//7
		
		this.#AdditionalText.Listen( 
			this.#OnValueChange.bind( this )
		);
	
		//8
		
		this.#ExcVAT.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#VAT.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#IncVAT.Listen( 
			this.#OnValueChange.bind( this )
		);
		
	}
	
	
	VATGet(){
		
		return this.#VAT;
		
	}
	
	ExcVATGet(){
		
		return this.#ExcVAT;
		
	}
	
	IncVATGet(){
		
		return this.#IncVAT;
		
	}
	
};


export default GTSUIModelSaleFormAddMainT;