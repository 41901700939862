
const InputSelectBaseParser = function( Value, OptionsObject ){
	
	if( Value === null ){
		
		return null;
		
	}
	
	if( OptionsObject.has( Value.toString( ) ) === false ){
		
		console.log( "Invalid select value", Value.toString( ), OptionsObject );
		
		throw new Error( "Invalid select value" );
		
	}
	
	return OptionsObject.get( Value.toString( ) );
		
};


export default InputSelectBaseParser;