"use strict";


import UIStateMapT from "../../State/UIStateMapT.mjs";


const UIComponentConfigAbstractT = class UIComponentConfigAbstractT {
	
	constructor(
		
	){
		
	}
	
	StateMapSet(
		UIStateMap 
	){
		
		if( UIStateMap !== null ){
		
			if( ( UIStateMap instanceof UIStateMapT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	UIStateMapGet( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	Copy(
	
	){
		
		throw new Error( "Abstract" );
		
	}
				
};


export default UIComponentConfigAbstractT;