"use strict";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";


import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import GTSUIModelOutinvoiceFormAddItemFormHeaderT from "./Header/GTSUIModelOutinvoiceFormAddItemFormHeaderT.mjs";

import GTSUIModelOutinvoiceFormAddItemFormLineListT from "./Line/GTSUIModelOutinvoiceFormAddItemFormLineListT.mjs";

import GTSUIComponentFormSecondaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/GTSUIComponentFormSecondaryT.mjs";


const GTSUIModelOutinvoiceFormAddItemFormT = class GTSUIModelOutinvoiceFormAddItemFormT extends GTSUIComponentFormSecondaryT {
	
	#Locale = null;
	
	#IsProductDefault = false;
					
	#UIOptionMapProductType = null;
	#ProductTypeDefault = null;
					
	#UIOptionMapDescription = null;
	#DescriptionDefault = null;
					
	#UIOptionMapUnit = null;
	#UnitDefault = null;
					
	#UIOptionMapVATRate = null;
	#VATRateDefault = null;
	
	#TransferredVAT = false;

	
	constructor( 
		Locale,
		
		IsProductDefault,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormHeader = new GTSUIModelOutinvoiceFormAddItemFormHeaderT(
			Locale
		);
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = new GTSUIModelOutinvoiceFormAddItemFormLineListT(
			Locale
		);
		
		
		super( 
			GTSUIModelOutinvoiceFormAddItemFormHeader,
			GTSUIModelOutinvoiceFormAddItemFormLineList
		);
		
		
		let Values = GTSUIModelOutinvoiceFormAddItemFormLineList.ValueGet( );
		
		if(
			Values.length === 0
		){
			
			GTSUIModelOutinvoiceFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			GTSUIModelOutinvoiceFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
		this.#Locale = Locale;
		
		this.#IsProductDefault = IsProductDefault;
		
		this.#UIOptionMapProductType = UIOptionMapProductType;
		this.#ProductTypeDefault = ProductTypeDefault;
		
		this.#UIOptionMapDescription = UIOptionMapDescription;
		this.#DescriptionDefault = DescriptionDefault;
		
		this.#UIOptionMapUnit = UIOptionMapUnit;
		this.#UnitDefault = UnitDefault;
		
		this.#UIOptionMapVATRate = UIOptionMapVATRate;
		this.#VATRateDefault = VATRateDefault;
		
	}
	
	LineAdd( 

	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
	
		GTSUIModelOutinvoiceFormAddItemFormLineList.LineAdd(
			this.#Locale,
			
			this.#IsProductDefault,
			
			this.#UIOptionMapProductType,
			this.#ProductTypeDefault,
			
			this.#UIOptionMapDescription,
			this.#DescriptionDefault,
		
			this.#UIOptionMapUnit,
			this.#UnitDefault,
			
			this.#UIOptionMapVATRate,
			this.#VATRateDefault
		);
	
	}
	
	LineGet(
		Index
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelOutinvoiceFormAddItemFormLineList.LineGet(
			Index
		);
		
	}
	
	Clear( ){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelSaleFormAddItemFormLineList.Clear( );
		
	}
	
	#ItemListOnChange(
		Values,
		IsValid,
		ValuesIsValid
	){
		
		console.log(
			Values,
			IsValid,
			ValuesIsValid
		);
		
		let GTSUIModelOutinvoiceFormAddItemFormHeader = super.HeaderGet( );
		
		if(
			Values.length === 0
		){
			
			GTSUIModelOutinvoiceFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			GTSUIModelOutinvoiceFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( 
				Values,
				IsValid
			);
			
		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		
		GTSUIModelOutinvoiceFormAddItemFormLineList.Listen(
			this.#ItemListOnChange.bind( this )
		);
		
		super.EventSet(
			"Change",
			OnChangeFn
		);
		
	}
	
	
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#TransferredVAT = TransferredVAT;
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelOutinvoiceFormAddItemFormLineList.TransferredVATToggle(
			TransferredVAT
		);
		
	}
	
	VATTotal(
	
	){
		
		let VAT = 0;
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		let Values = GTSUIModelOutinvoiceFormAddItemFormLineList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			VAT += Values[ I ].vat;
			
		}
		
		return VAT;
		
	}
	
	ExcVATTotal(
	
	){
		
		let ExcVAT = 0;
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		let Values = GTSUIModelOutinvoiceFormAddItemFormLineList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			ExcVAT += Values[ I ].excvat * Values[ I ].qty;
			
		}
		
		return ExcVAT;
		
	}
	
	IncVATTotal(
	
	){
		
		return ( this.ExcVATTotal( ) + this.VATTotal( ) );
		
	}
	
	IsValidTotalGet(
	
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelOutinvoiceFormAddItemFormLineList.IsValidTotalGet( );
		
	}
	
	IsValidGet(
	
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
	
		return GTSUIModelOutinvoiceFormAddItemFormLineList.IsValidGet( );
	
	}
	
	ValueGet(
	
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelOutinvoiceFormAddItemFormLineList.ValueGet( );
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemFormT;


/* "use strict";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";


import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import GTSUIModelOutinvoiceFormAddItemsHeaderT from "./GTSUIModelOutinvoiceFormAddItemsHeaderT.mjs";

import GTSUIModelOutinvoiceFormAddItemListT from "./GTSUIModelOutinvoiceFormAddItemListT.mjs";



const GTSUIModelOutinvoiceFormAddItemsT = class GTSUIModelOutinvoiceFormAddItemsT extends UIComponentLayoutVT {
	
	#GTSUIModelOutinvoiceFormAddItemsHeader = null;
	#GTSUIModelOutinvoiceFormAddItemList = null;
	
	#TransferredVAT = false;
	
	constructor( 
		Locale
	){

		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutHT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] ),
		
			null //Id
		);
		
		
		super( 
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		
		this.#SetHeader( 
			Locale
		);
		
		this.#SetList( );
		
		
		
		let Values = this.#GTSUIModelOutinvoiceFormAddItemList.ValueGet( );
		
		if(
			Values.length === 0
		){
			
			this.#GTSUIModelOutinvoiceFormAddItemsHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			this.#GTSUIModelOutinvoiceFormAddItemsHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
	}
	
	#SetHeader( 
		Locale
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddItemsHeader = new GTSUIModelOutinvoiceFormAddItemsHeaderT(
			Locale
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddItemsHeader 
		);
		
		//UIComponentLayoutElement.Update( );
		
		
		super.ElementSet( 0, UIComponentLayoutElement );
		
	}
	
	#SetList( ){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddItemList = new GTSUIModelOutinvoiceFormAddItemListT(
		
		);
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddItemList 
		);
		
		//UIComponentLayoutElement.Update( );
		
		
		super.ElementSet( 1, UIComponentLayoutElement );
		
	}
	
	Add( 
		Locale,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
	
		this.#GTSUIModelOutinvoiceFormAddItemList.Add(
			Locale,
			
			UIOptionMapDescription,
			DescriptionDefault,
		
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
	
	}
	
	Get(
		Index
	){
		
		return this.#GTSUIModelOutinvoiceFormAddItemList.Get(
			Index
		);
		
	}
	
	Render( 
	
	){
		
		return super.Render( );
		
	}
	
	#ItemListOnChange(
		Values,
		IsValid,
		ValuesIsValid
	){
		
		console.log(
			Values,
			IsValid,
			ValuesIsValid
		);
		
		if(
			Values.length === 0
		){
			
			this.#GTSUIModelOutinvoiceFormAddItemsHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			this.#GTSUIModelOutinvoiceFormAddItemsHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( 
				Values,
				IsValid
			);
			
		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		this.#GTSUIModelOutinvoiceFormAddItemList.Listen(
			this.#ItemListOnChange.bind( this )
		);
		
		super.EventSet(
			"Change",
			OnChangeFn
		);
		
	}
	
	
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#TransferredVAT = TransferredVAT;
		
		this.#GTSUIModelOutinvoiceFormAddItemList.TransferredVATToggle(
			TransferredVAT
		);
		
	}
	
	VATTotal(
	
	){
		
		let VAT = 0;
		
		let Values = this.#GTSUIModelOutinvoiceFormAddItemList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			VAT += Values[ I ].vat;
			
		}
		
		return VAT;
		
	}
	
	ExcVATTotal(
	
	){
		
		let ExcVAT = 0;
		
		let Values = this.#GTSUIModelOutinvoiceFormAddItemList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			ExcVAT += Values[ I ].excvat * Values[ I ].qty;
			
		}
		
		return ExcVAT;
		
	}
	
	IncVATTotal(
	
	){
		
		return ( this.ExcVATTotal( ) + this.VATTotal( ) );
		
	}
	
	IsValidTotalGet(
	
	){
		
		return this.#GTSUIModelOutinvoiceFormAddItemList.IsValidTotalGet( );
		
	}
	
	IsValidGet(
	
	){
	
		return this.#GTSUIModelOutinvoiceFormAddItemList.IsValidGet( );
	
	}
	
	ValueGet(
	
	){
		
		return this.#GTSUIModelOutinvoiceFormAddItemList.ValueGet( );
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemsT; */