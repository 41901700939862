"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";


const ComponentButtonConfigT = class ComponentButtonConfigT extends ComponentBaseConfigT {
	
	constructor(
		ClassName = null,
		StyleMap = null,
		Children = null,
		Name = null,
		Id = null
	){
		
		super( 
			ClassName,
			StyleMap,
			Children,
			Name,
			Id
		);
		
	}
	
	Copy( ){
		
		return new ComponentButtonConfigT( 
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.ChildrenGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
				
};


export default ComponentButtonConfigT;