"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentCheckListElementT from "../../../../Interface/UI/Component/CheckList/UIComponentCheckListElementT.mjs";


import UIStyleMapT from "../../../../Type/Style/StyleMapT.mjs";

import UIComponentCheckListElementConfigT from "../../../../Type/UI/Component/CheckList/UIComponentCheckListElementConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";


const UIComponentCheckListT = class UIComponentCheckListT extends UIComponentLayoutVT {
	
	#UIComponentCheckListElementList = null;
	
	#UIComponentCheckListElementMap = null;

	
	#ValueMap = null;
	
	
	constructor(
		UIElement,
		UIComponentCheckListConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( UIComponentCheckListConfig instanceof UIComponentCheckListConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			UIElement,
			UIComponentCheckListConfig
		);
		
		
		this.#UIComponentCheckListElementList = new Array( );
		
		this.#UIComponentCheckListElementMap = new Map( );
		
		
		this.#ValueMap = new Map( );
		
		
		let UIOptionMap = UIComponentCheckListConfig.OptionMapGet( );
		
		this.OptionMapSet( UIOptionMap );
		
		
		let ValueDefault = UIComponentCheckListConfig.ValueDefaultGet( );
		
		this.ValueSet( ValueDefault );

	}
	
	Update( ){
		
		for( 
			let I = 0;
			I < this.#UIComponentCheckListElementList.length;
			I++
		){
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementList[ I ];
			
			UIComponentCheckListElement.Update( );
			
		}
		
		
		super.Update( );
		
	}
	
	OptionMapClear(
		
	){

		for(
			let I = this.#UIComponentCheckListElementList.length;
			I > 0;
			I--
		){
			
			let Index = I - 1;
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementList[ Index ];
			
			let UIComponentCheckListElementConfig = UIComponentCheckListElement.ConfigGet( );
			
			
			this.#UIComponentCheckListElementList.splice( Index, 1 );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( )
			
			this.#UIComponentCheckListElementMap.delete( UIOption.KeyGet( ) );
			
			
			super.ElementRemove( Index );
			
		}
		
	}
	
	OptionMapSet(
		UIOptionMap
	){
		
		//this.Stop( );
		
		
		this.OptionMapClear( );
		
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		

		for(
			let I of UIOptionMap.Keys( )
		){
			
			let UIOption = UIOptionMap.Get( I );
			
			let Key = UIOption.KeyGet( );
		
			let Value = UIOption.ValueGet( );
			
			
			let UIComponentLayoutElementConfig = UIComponentCheckListConfig.LayoutElementConfigGet( );
		
			let UIComponentCheckListElementConfig = new UIComponentCheckListElementConfigT( 
				UIOption,
				UIComponentLayoutElementConfig.GrowGet( ),
				UIComponentLayoutElementConfig.ShrinkGet( ),
				UIComponentLayoutElementConfig.BasisGet( ),
				UIComponentLayoutElementConfig.AlignSelfGet( ),
				UIComponentLayoutElementConfig.StateMapGet( )
			);
						
			let UIComponentCheckListElement = new UIComponentCheckListElementT(
				new UIElementT( ),
				UIComponentCheckListElementConfig
			);
			
			//UIComponentCheckListElement.Update( );
			
			
			this.#UIComponentCheckListElementList.push( UIComponentCheckListElement );
			
			this.#UIComponentCheckListElementMap.set( Key, UIComponentCheckListElement );
			
			
			let Index = this.#UIComponentCheckListElementList.length - 1;
			
			super.ElementSet( Index, UIComponentCheckListElement );
			
		}
		
		//this.Start( );
		
	}
	
	OptionMapGet(
		
	){
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		return UIComponentCheckListConfig.OptionMapGet( );
		
	}
	
	IsValidGet(
	
	){
	
		if( this.#ValueMap.size !== 0 ){
			
			return true;
			
		} else {
			
			let UIComponentCheckListConfig = this.ConfigGet( );
			
			return UIComponentCheckListConfig.IsEmptyValidGet(  );
			
		}
	
	}
	
	ValueSet(
		Value
	){
		
		//Checks
		
		if( Value !== null ){
		
			if( ( Value instanceof Array ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			for(
				let I = 0;
				I < Value.length;
				I++
			){
				
				if( typeof( Value[ I ] ) !== "string" ){
				
					throw new Error( "Argument" );
				
				}
				
			}
		
		}
		
		//Prepare
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		let UIComponentLayoutElementConfig = UIComponentCheckListConfig.LayoutElementConfigGet( );
		
		let UIStateMap = UIComponentLayoutElementConfig.StateMapGet( );
		
		let UIStateChecked = UIStateMap.Get( "Checked" );
		
		let UIStateUnchecked = UIStateMap.Get( "Unchecked" );
		
		//Checks
		
		if( UIComponentCheckListConfig.IsMultipleGet( ) === false ){
			
			if( Value.length > 1 ){
			
				throw new Error( "Argument" );
			
			}
			
		}
		
		//Create
		
		let KeyMap = new Map( );
		
		if( Value !== null ){
		
			if( ( Value instanceof Array ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			for(
				let I = 0;
				I < Value.length;
				I++
			){
				
				let ValueElement = Value[ I ];
				
				KeyMap.set( ValueElement, ValueElement );
				
			}
		
		}
		
		//Clear
		
		for(
			let ValueElement of this.#ValueMap.keys( )
		){
			
			if( KeyMap.has( ValueElement ) === true ){
				
				continue;
				
			}
			
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementMap.get( ValueElement );
	
	
			UIComponentCheckListElement.ClassNameSet(
				UIStateUnchecked.ClassNameGet( )
			);
			
			UIComponentCheckListElement.StyleMapSet(
				UIStateUnchecked.StyleMapGet( )
			);
			
		}
		
		//Set
		
		let ValueMap = new Map( );
		
		for(
			let ValueKey of KeyMap.keys( )
		){
			
			if( this.#ValueMap.has( ValueKey ) === true ){
				
				ValueMap.set( ValueKey, this.#ValueMap.get( ValueKey ) );
				
				continue;
				
			}
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementMap.get( ValueKey );
			
			let UIComponentCheckListElementConfig = UIComponentCheckListElement.ConfigGet( );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( );
	
	
			UIComponentCheckListElement.ClassNameSet(
				UIStateChecked.ClassNameGet( )
			);
			
			UIComponentCheckListElement.StyleMapSet(
				UIStateChecked.StyleMapGet( )
			);
			
			ValueMap.set( UIOption.KeyGet( ), UIOption.ValueGet( ) );
			
		}
		
		//Commit
			
		this.#ValueMap = ValueMap;
		
	}
	
	ValueSetAll( 
	
	){
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		let UIOptionMap = UIComponentCheckListConfig.OptionMapGet( );
			
			
		this.ValueSet( [ ...UIOptionMap.Keys( ) ] );
		
	}
	
	LabelGet( ){
		
		return [ ...this.#ValueMap.values( ) ];
		
	}
	
	ValueGet( 
	
	){
		
		return [ ...this.#ValueMap.keys( ) ];
		
	}
	
	ValueClear(
	
	){
		
		this.ValueSet( [ ] );
		
	}
	
	#OnClickElement(
		UIOption
	){
		
		console.log( "UIComponentCheckListT.#OnMouseEnterElement", UIOption );
		
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		let UIComponentLayoutElementConfig = UIComponentCheckListConfig.LayoutElementConfigGet( );
		
		let UIStateMap = UIComponentLayoutElementConfig.StateMapGet( );
		
		let UIState = null;
		
		
		
		if( UIComponentCheckListConfig.IsMultipleGet( ) === false ){
			
			this.ValueClear( );
			
		}
		
		
		let UIOptionKey = UIOption.KeyGet( );
		
		let UIOptionValue = UIOption.ValueGet( );
		
		if( this.#ValueMap.has( UIOptionKey ) === true ){
			
			UIState = UIStateMap.Get( "Unchecked" );
			
			this.#ValueMap.delete( UIOptionKey );
			
		} else {
			
			UIState = UIStateMap.Get( "Checked" );
			
			this.#ValueMap.set( UIOptionKey, UIOptionValue );
			
		}
		
		
		let UIComponentCheckListElement = this.#UIComponentCheckListElementMap.get( UIOptionKey );
		
		UIComponentCheckListElement.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		UIComponentCheckListElement.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let ChangeFn = super.EventGet( "Change" );
				
			ChangeFn( [ ...this.#ValueMap.keys( ) ], [ ...this.#ValueMap.values( ) ], this.IsValidGet( ) );
		
		}
		
	}
	
	#OnMouseEnterElement(
		UIOption
	){
		
		console.log( "UIComponentCheckListT.#OnMouseEnterElement", UIOption );
		
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		let UIComponentLayoutElementConfig = UIComponentCheckListConfig.LayoutElementConfigGet( );
		
		let UIStateMap = UIComponentLayoutElementConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Hovered" );
		
		
		let UIOptionKey = UIOption.KeyGet( );
		
		let UIComponentCheckListElement = this.#UIComponentCheckListElementMap.get( UIOptionKey );
		
		
		UIComponentCheckListElement.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		UIComponentCheckListElement.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
	}
	
	#OnMouseLeaveElement(
		UIOption
	){
		
		console.log( "UIComponentCheckListT.#OnMouseEnterElement", UIOption );
		
		
		let UIComponentCheckListConfig = super.ConfigGet( );
		
		let UIComponentLayoutElementConfig = UIComponentCheckListConfig.LayoutElementConfigGet( );
		
		let UIStateMap = UIComponentLayoutElementConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Unhovered" );
	
	
		let UIOptionKey = UIOption.KeyGet( );
		
		let UIComponentCheckListElement = this.#UIComponentCheckListElementMap.get( UIOptionKey );
		
		UIComponentCheckListElement.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		UIComponentCheckListElement.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentCheckListT.Start" );
		
		
		for(
			let I = 0;
			I < this.#UIComponentCheckListElementList.length;
			I++
		){
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementList[ I ];
			
			UIComponentCheckListElement.Listen( 
				this.#OnClickElement.bind( this ),
				this.#OnMouseEnterElement.bind( this ),
				this.#OnMouseLeaveElement.bind( this )
			);
			
			UIComponentCheckListElement.Start( );
			
		}
		
	}
	
	Stop(
	
	){
		
		console.log( "UIComponentCheckListT.Stop" );
		
		
		for(
			let I = 0;
			I < this.#UIComponentCheckListElementList.length;
			I++
		){
			
			let UIComponentCheckListElement = this.#UIComponentCheckListElementList[ I ];
			
			UIComponentCheckListElement.Unlisten( );
			
			UIComponentCheckListElement.Stop( );
			
		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Change" );
		
	}
	
};


export default UIComponentCheckListT;