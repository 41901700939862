"use strict";


import GUIElementT from "../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentConfigT from "../../Type/Component/ComponentConfigT.mjs";


import { 
	ComponentBaseT as ComponentBaseT,
	ComponentButtonT as ComponentButtonT,
	ComponentCheckListT as ComponentCheckListT,
	ComponentLabelT as ComponentLabelT,
	ComponentLayoutElementT as ComponentLayoutElementT,
	ComponentLayoutHT as ComponentLayoutHT,
	ComponentLayoutVDynamicT as ComponentLayoutVDynamicT,
	ComponentLayoutVT as ComponentLayoutVT,
	ComponentSelectT as ComponentSelectT,
	ComponentSelectTextT as ComponentSelectTextT,
	ComponentTableHT as ComponentTableHT,
	ComponentTextT as ComponentTextT,
	ComponentDoubleT as ComponentDoubleT,
	ComponentBooleanT as ComponentBooleanT,
	ComponentDateT as ComponentDateT
} from "./ComponentInternalT.mjs";


import EventBucketT from "../Event/EventBucketT.mjs";


const ComponentT = class ComponentT {
	
	#Type = null;
	#ComponentTyped = null;
	
	constructor(
		Type,
		ComponentTyped
	){
		
		if( typeof( Type ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Type === "Base" ){
			
			if( ( ComponentTyped instanceof ComponentBaseT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Button" ){
			
			if( ( ComponentTyped instanceof ComponentButtonT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "CheckList" ){
			
			if( ( ComponentTyped instanceof ComponentCheckListT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Label" ){
		
			if( ( ComponentTyped instanceof ComponentLabelT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "LayoutElement" ){
			
			if( ( ComponentTyped instanceof ComponentLayoutElementT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "LayoutH" ){
			
			if( ( ComponentTyped instanceof ComponentLayoutHT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "LayoutV" ){
			
			if( ( ComponentTyped instanceof ComponentLayoutVT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Select" ){
			
			if( ( ComponentTyped instanceof ComponentSelectT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "SelectText" ){
			
			if( ( ComponentTyped instanceof ComponentSelectTextT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "TableH" ){
			
			if( ( ComponentTyped instanceof ComponentTableHT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Text" ){
			
			if( ( ComponentTyped instanceof ComponentTextT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Double" ){
			
			if( ( ComponentTyped instanceof ComponentDoubleT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Boolean" ){
			
			if( ( ComponentTyped instanceof ComponentBooleanT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		} else if( Type === "Date" ){
			
			if( ( ComponentTyped instanceof ComponentDateT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
			
		}
		
		
		this.#Type = Type;
		
		this.#ComponentTyped = ComponentTyped;
		
	}
	
	TypeGet(
	
	){
		
		return this.#Type;
		
	}
	
	ComponentTypedGet(
		
	){
		
		return this.#ComponentTyped;
		
	}
	
	Element( ){
		
		return this.#ComponentTyped.Element( );
		
	}
	
	Update( ){
		
		return this.#ComponentTyped.Update( );
		
	}
	
	Listen(
		EventBucket
	){
		
		this.#ComponentTyped.Listen( EventBucket );
		
	}
	
	ChildrenSet( Children ){

		this.#ComponentTyped.ChildrenSet( Children );

	}
	
	ConfigGet( ){
		
		return this.#ComponentTyped.ConfigGet( );
		
	}
	
	ChildrenKeys( ){
		
		return this.#ComponentTyped.ChildrenKeys( );
		
	}
	
	ChildGet( Key ){
		
		return this.#ComponentTyped.ChildGet( Key );
		
	}
	
	NameGet( ){
		
		return this.#ComponentTyped.NameGet( );
		
	}
	
	StyleMapSet(
		StyleMap
	){
		
		return this.#ComponentTyped.StyleMapSet( 
			StyleMap
		);
		
	}
	
	ValueCheck( ){
		
		return this.#ComponentTyped.ValueCheck( );
		
	}
	
	ValueGet( ){
		
		return this.#ComponentTyped.ValueGet( );
		
	}
	
	ValueSet(
		Value
	){
		
		return this.#ComponentTyped.ValueSet( Value );
		
	}
	
	StyleSet( 
		Key,
		Value
	){
		
		console.log( Key, Value );
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentTyped.StyleSet( Key, Value );
		
	}
	
	StyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#ComponentTyped.StyleGet( Key );
		
	}
	
	Prepare( 
		PrepareMap
	){
		
		this.#ComponentTyped.Prepare( PrepareMap );
		
	}
	
	static Prepare(
		Component,
		PrepareMap,
		EventBucket
	){
		
		console.log( "ComponentT.Prepare", Component, PrepareMap );
		
		if( ( Component instanceof ComponentT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		} 
		
		if( PrepareMap !== null ){
		
			if( ( PrepareMap instanceof Map ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		if( EventBucket !== null ){
		
			if( ( EventBucket instanceof EventBucketT ) === false ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		
		let Name = Component.ConfigGet( ).NameGet( ); //TODO
		
		if( Name !== null ){
		
			if( PrepareMap !== null ){
		
				if( PrepareMap.has( Name ) === true ){
					
					let PrepareFn = PrepareMap.get( Name );
					
					PrepareFn( Component );
					
				}
			
			}
			
			/*if( EventBucket !== null ){
		
				if( EventBucket.IsExists( Name ) === true ){
					
					let EventMap = EventBucket.Get( Name );
					
					Component.Listen( EventMap );
					
				}
				
			}*/
		
		}
		
		
			
		let Children = Component.ChildrenKeys( );
		
		console.log( Children );
		
		for(
			let I of Children
		){
			
			let Child = Component.ChildGet( I );
			
			let ChildConfig = Child.ConfigGet( );
			
			let Name = ChildConfig.NameGet( );
			
			console.log( "Children", I, Name );
			
			if( Name !== null ){
			
				if( PrepareMap !== null ){
			
					if( PrepareMap.has( Name ) === true ){
						
						let PrepareFn = PrepareMap.get( Name );
						
						PrepareFn( Child );
						
					}
				
				}
				
				/*if( EventBucket !== null ){
		
					if( EventBucket.IsExists( Name ) === true ){
						
						let EventMap = EventBucket.Get( Name );
						
						Component.Listen( EventMap );
						
					}
					
				}*/
			
			}
			
			//Child.Prepare( PrepareMap );
			
			ComponentT.Prepare( Child, PrepareMap, EventBucket );
			
		}
		
		Component.Prepare( PrepareMap );
		
		Component.Listen( EventBucket );
		
	}
				
	static FromConfig(
		ComponentConfig
	){
		
		if( ( ComponentConfig instanceof ComponentConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		} 
		
		
		let Type = ComponentConfig.TypeGet( );
		
		let ComponentConfigTyped = ComponentConfig.ComponentConfigTypedGet(  );
		
		let ComponentTyped = null;
		
		
		console.log( Type, ComponentConfigTyped.ChildrenGet( ) );
		
		
		if( Type === "Base" ){
			
			ComponentTyped = new ComponentBaseT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Button" ){
			
			ComponentTyped = new ComponentButtonT( 
				new GUIElementT( "button" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Label" ){
		
			ComponentTyped = new ComponentLabelT( 
				new GUIElementT( "label" ), 
				ComponentConfigTyped
			);
			
			ComponentTyped.Update( );
			
		} else if( Type === "LayoutElement" ){
			
			ComponentTyped = new ComponentLayoutElementT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "LayoutH" ){
			
			ComponentTyped = new ComponentLayoutHT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "LayoutV" ){
			
			ComponentTyped = new ComponentLayoutVT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "LayoutVDynamic" ){
			
			ComponentTyped = new ComponentLayoutVDynamicT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Select" ){
			
			ComponentTyped = new ComponentSelectT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "SelectText" ){
			
			ComponentTyped = new ComponentSelectTextT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "TableH" ){
			
			ComponentTyped = new ComponentTableHT( 
				new GUIElementT( "div" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Text" ){
			
			ComponentTyped = new ComponentTextT( 
				new GUIElementT( "input" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Double" ){
			
			ComponentTyped = new ComponentDoubleT( 
				new GUIElementT( "input" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Boolean" ){
			
			ComponentTyped = new ComponentBooleanT( 
				new GUIElementT( "input" ), 
				ComponentConfigTyped
			);
			
		} else if( Type === "Date" ){
			
			ComponentTyped = new ComponentDateT( 
				new GUIElementT( "input" ), 
				ComponentConfigTyped
			);
			
		}
		
		
		let ChildrenMap = new Map( );
			
		let ChildrenConfig = ComponentConfigTyped.ChildrenGet( );
		
		console.log( ChildrenConfig );
		
		if( ChildrenConfig !== null ){
			
			for(
				let I = 0;
				I < ChildrenConfig.length;
				I++
			){
				
				let ChildConfig = ChildrenConfig[ I ];
				
				let Child = ComponentT.FromConfig( ChildConfig );
				
				ComponentTyped.ChildAppend( Child ); //TODO Component.ChildAppend( Child );
				
			}
			
		}
		
		
		let Component = new ComponentT(
			Type,
			ComponentTyped
		);
		
		
		return Component;
		
	}
};


export default ComponentT;