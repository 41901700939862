import { 
	Component 
} from "react";

import { 
	Link 
} from "react-router-dom";

import { 
	translate 
} from 'react-polyglot';

import PropTypes from 'prop-types';

import UserStateInterface from "./.././../../interfaces/UserStateInterface";


interface Props {
	user: UserStateInterface;
};


class Aside extends Component<Props> {
	
	constructor( props ) {
		
		super( props );
		
		this.Refs = [ ];
		
	}

	render( ) {
		
		let permissions = JSON.parse(
			localStorage.getItem( "my_permissions" )
		);
		
		let roles = [ ];
		
		JSON.parse( localStorage.getItem( 'my_user' ) ).roles.forEach((r)=>{
			roles.push(r.name);
		} );
		
		let disableClick = false;
		
		let classNameOnClick = function( e ){
			
			e.preventDefault( );
			
			if( disableClick === true ){
				
				return;
				
			}
			
			//disableClick = true;
			
			if( !e.target.parentElement.pathname ){
				
				return;
				
			}
			
			if( window.location.pathname === e.target.parentElement.pathname ){
				
				return;
				
			}
			
			for(
				let I in this.Refs
			){
				
				if( this.Refs[ I ].pathname === e.target.parentElement.pathname ){
				
					this.Refs[ I ].className = "nav-link active";
					
					this.Refs[ I ].style.cursor = "default";
					
					this.Refs[ I ].style.backgroundColor = "black";
					
					this.Refs[ I ].style.borderTop = "2px solid #b5b5b5";
					
					this.Refs[ I ].style.borderBottom = "2px solid #b5b5b5";
					
					this.Refs[ I ].style.borderRight = "1px solid #b5b5b5";
				
				} else {
				
					this.Refs[ I ].className = "nav-link";
					
					this.Refs[ I ].style.cursor = "default";
					
					this.Refs[ I ].style.backgroundColor = "#343a40";
					
					this.Refs[ I ].style.borderTop = "2px solid #343a40";
					
					this.Refs[ I ].style.borderBottom = "2px solid #343a40";
					
					this.Refs[ I ].style.borderRight = "1px solid #343a40";
				
				}
				
			}
			
			setTimeout( function( ){
				
				window.location = e.target.parentElement.href;
			
			}, 250 );
			
		}.bind( this );
		
		
		
		let classRefFunction = function( Element ){
			
			if( Element == null ){
				
				console.log( "null" );
				
				return;
				
			}
			
			if( Element.pathname === window.location.pathname ){
				
				console.log( "Same" );
				
				Element.style.cursor = 'default';
				
				Element.className = "nav-link active";
				
				Element.style.backgroundColor = "black";
				
				Element.style.borderTop = "2px solid #b5b5b5";
				
				Element.style.borderBottom = "2px solid #b5b5b5";
				
				Element.style.borderRight = "1px solid #b5b5b5";
				
			} else {
				
				console.log( "not same" );
				
				Element.className = "nav-link";
				
				Element.style.backgroundColor = "#343a40";
				
				Element.style.borderTop = "2px solid #343a40";
				
				Element.style.borderBottom = "2px solid #343a40";
				
				Element.style.borderRight = "1px solid #343a40";
				
			}
			
			Element.style.transition = "background-color .25s linear, border-color .25s linear";
			
			this.Refs.push( Element );
			
		}.bind( this );


    const { currentUser } = this.props.user;
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="home" className="brand-link">

          <span className="brand-text font-weight-light">GTSDB</span>
        </Link>

        <div className="sidebar" style={{ overflow: "hidden" }}>
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">

            </div>
            <div className="info">
              <Link className="d-block" to="profile">
                {currentUser.name}
              </Link>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column text-sm nav-legacy"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
                {!roles.includes('Spedice') && !roles.includes('Galvanovna') &&
                <li className="nav-item">
                  <a href="/home" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas fa-tachometer-alt"></i>
                    <p>
                        {this.props.t('dashboard')}
                    </p>
                  </a>
                </li>
                }

              {permissions.includes('companies.view') &&
                !permissions.includes('loadings.spedition') &&
                  <>
              <li className="nav-header">{this.props.t('companies').toUpperCase()}</li>
              <li className="nav-item">
                <a href="/companies" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                  <i className="nav-icon fas  fa-briefcase"></i>
                  <p>
                    {this.props.t('companies')}
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/active_companies" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                  <i className="nav-icon fas  fa-folder"></i>
                  <p>
                    {this.props.t('active_companies')}
                  </p>
                </a>
              </li>
                  </>
              }

                {(permissions.includes('appraisals.view') || permissions.includes('loadings.view') && !roles.includes('Galvanovna')) &&
                <>
                <li className="nav-header">{this.props.t('Loadings').toUpperCase()}</li>
                {permissions.includes('appraisals.view') &&
                <li className="nav-item">
                  <a href="/appraisals" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-money-bill"></i>
                    <p>
                        {this.props.t('Appraisals')}
                    </p>
                  </a>
                </li>
                }
                {((permissions.includes('loadings.view') && !roles.includes('Vyroba')) || roles.includes('Admin')) && !permissions.includes('loadings.spedition') && ( !roles.includes('Účetní') ) &&
                <li className="nav-item">
                  <a href="/loadings" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-truck"></i>
                    <p>
                        {this.props.t('Loadings')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('loadings.trips') &&
                <li className="nav-item">
                  <a href="/trips" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-route"></i>
                    <p>
                        {this.props.t('Trips')}
                    </p>
                  </a>
                </li>
                }
				
				
				{permissions.includes('loadings.spedition') && ( !roles.includes('Účetní') ) &&
                <li className="nav-item">
                  <a href="/speditions" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-truck"></i>
                    <p>
                        {this.props.t('Speditions')}
                    </p>
                  </a>
                </li>
                }
				
				{ ( roles.includes('Účetní') ) &&
                <li className="nav-item">
                  <a href="/loadings_for_accountants" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-truck"></i>
                    <p>
                        {this.props.t('Loadings for accountants')}
                    </p>
                  </a>
                </li>
                }
				
				
                {permissions.includes('storage.outputs') &&
                <li className="nav-item">
                  <a href="/products" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-boxes"></i>
                    <p>

                        {this.props.t('Storage')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('storage.production') &&
                <li className="nav-item">
                  <a href="/productions" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-history"></i>
                    <p>
                        {this.props.t('Production history')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('storage.view') &&
                <li className="nav-item">
                  <a href="/storage" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-cogs"></i>
                    <p>
                        {this.props.t('Production')}

                    </p>
                  </a>
                </li>
                }
                {permissions.includes('storage.view') &&
                <li className="nav-item">
                  <a href="/workhours" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-clock"></i>
                    <p>
                        {this.props.t('Work hours')}

                    </p>
                  </a>
                </li>
                }

                </>
                }

                {permissions.includes('storage.galvanisation') &&
                <li className="nav-item">
                  <a href="/galvanisation" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-industry"></i>
                    <p>
                        {this.props.t('Galvanisation')}

                    </p>
                  </a>
                </li>
                }


                {(permissions.includes('invoices.view') )&&
                <>
                <li className="nav-header">{this.props.t('Finance').toUpperCase()}</li>
                {permissions.includes('invoices.pay') &&
                <li className="nav-item">
                  <a href="/pay_invoices" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-credit-card"></i>
                    <p>
                        {this.props.t('Pay invoices')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('invoices.view') &&
                <li className="nav-item">
                  <a href="/invoices" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-file-invoice"></i>
                    <p>
                        {this.props.t('Incoming invoices')}
                    </p>
                  </a>
                </li>
                }
				{permissions.includes('outinvoices.view') &&
                <li className="nav-item">
                  <a href="/outinvoices" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-file-invoice"></i>
                    <p>
                        {this.props.t('Outgoing invoices')}
                    </p>
                  </a>
                </li>
                }
				{permissions.includes('additional_texts.view') &&
                <li className="nav-item">
                  <a href="/additional_texts" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-file-invoice"></i>
                    <p>
                        {this.props.t('Additional texts')}
                    </p>
                  </a>
                </li>
                }
				{permissions.includes('outinvoice_item_names.view') &&
                <li className="nav-item">
                  <a href="/outinvoice_item_names" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-file-invoice"></i>
                    <p>
                        {this.props.t('Outinvoice item names')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('storage.outputs') &&
                <li className="nav-item">
                  <a href="/supplylists" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-list"></i>
                    <p>

                        {this.props.t('Supply lists')}
                    </p>
                  </a>
                </li>
                }

                {permissions.includes('invoices.pay_history') &&
                <li className="nav-item">
                  <a href="/wiretransfers" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-piggy-bank"></i>
                    <p>
                        {this.props.t('Wire transfers')}
                    </p>
                  </a>
                </li>
                }
                {permissions.includes('sales.view') &&
                <li className="nav-item">
                  <a href="/sales" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="nav-icon fas  fa-shopping-cart"></i>
                    <p>
                        {this.props.t('Sales')}
                    </p>
                  </a>
                </li>
                }

                {permissions.includes('invoices.contracts') &&
                <li className="nav-item">
                  <a href="/contracts" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="fas fa-file-signature nav-icon"></i>
                    <p>{this.props.t('Contracts')}</p>
                  </a>
                </li>
                }
				
				{permissions.includes('expenses.view') &&
                <li className="nav-item">
                  <a href="/expenses" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="fas fa-file-signature nav-icon"></i>
                    <p>{this.props.t('Expenses')}</p>
                  </a>
                </li>
                }

                </>

                }

                {(permissions.includes('reports.salary') || permissions.includes('invoices.report')) &&
                <>
                <li className="nav-header">{this.props.t('Reports').toUpperCase()}</li>
                {permissions.includes('reports.salary') &&

                <li className="nav-item">
                  <a href="/salary_report" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="fas fa-cash-register nav-icon"></i>
                    <p>{this.props.t('Salary report')}</p>
                  </a>
                </li>
                }

                {permissions.includes('invoices.report') &&

                <li className="nav-item">
                  <a href="/invoices_report" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                    <i className="fas fa-file-invoice-dollar nav-icon"></i>
                    <p>{this.props.t("Invoice report")}</p>
                  </a>
                </li>
                }


                </>
                }
                {permissions.includes('settings.view') &&
                <>
              <li className="nav-header">{this.props.t('settings').toUpperCase()}</li>
              <li className="nav-item has-treeview">
                <a
                    href="//"
                    ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}
                    onClick={(event) => {
                      
						event.preventDefault( );
						//$("#dashboard-link").slideToggle();
				
						  
						let container = document.getElementById( "dashboard-link" );
						
						let containerArrow = document.getElementById( "dashboard-link-arrow" );

						if( container.style.height === "0px" ){
							
							container.style.height = container.scrollHeight + "px";
							
							container.style.opacity = "1";
							
							containerArrow.className = "right fas fa-angle-down";

						} else {
							
							container.style.height = "0px";
							
							container.style.opacity = "0";
							
							containerArrow.className = "right fas fa-angle-left";

						}

                    }}
                >
                  <i className="nav-icon fas fa-cogs"></i>
                  <p>
                    {this.props.t('Common')} <i id="dashboard-link-arrow" className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview" id="dashboard-link" style={{ display: "block", height: "0px", visibility: "visible", opacity: "0", transition: "height .5s linear, opacity .5s linear"}}>
                    {permissions.includes('settings.columns') &&
                        <>
                    <li className="nav-item">
                      <a href="/company_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Company columns')}</p>
                      </a>
                    </li>
                        <li className="nav-item">
                          <a href="/loading_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                            <i className="far fa-circle nav-icon"></i>
                            <p>{this.props.t('Loading columns')}</p>
                          </a>
                        </li>


                        </>
                    }
                    {permissions.includes('settings.permissions') &&
                        <>
                    <li className="nav-item">
                      <a href="/invoice_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Invoices columns')}</p>
                      </a>
                    </li>

                    <li className="nav-item">
                        <a href="/outinvoice_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Outgoing invoices columns')}</p>
                        </a>
                        </li>
</>
                    }
					{permissions.includes('settings.permissions') &&
                        <>
							<li className="nav-item">
								<a href="/expense_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
								<i className="far fa-circle nav-icon"></i>
								<p>{this.props.t('Expenses columns')}</p>
								</a>
							</li>
						</>
                    }
					{permissions.includes('settings.permissions') &&
                        <>
							<li className="nav-item">
								<a href="/additional_text_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
								<i className="far fa-circle nav-icon"></i>
								<p>{this.props.t('Additional texts columns')}</p>
								</a>
							</li>
						</>
                    }
					{permissions.includes('settings.permissions') &&
                        <>
							<li className="nav-item">
								<a href="/outinvoice_item_name_columns" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
								<i className="far fa-circle nav-icon"></i>
								<p>{this.props.t('Outinvoice items names columns')}</p>
								</a>
							</li>
						</>
                    }
                    {permissions.includes('settings.continents') &&
                    <li className="nav-item">
                      <a href="/continents" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('continents')}</p>
                      </a>
                    </li>
                    }

                    {permissions.includes('settings.countries') &&
                    <li className="nav-item">
                      <a href="/countries" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('countries')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.languages') &&
                    <li className="nav-item">
                      <a href="/languages" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('languages')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.view') &&
                    <li className="nav-item">
                      <a href="/product_types" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Product types')}</p>
                      </a>
                    </li>
                    }
					{permissions.includes('settings.expenses_types') &&
                    <li className="nav-item">
                      <a href="/expense_types" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Expense types')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.view') &&
                    <li className="nav-item">
                      <a href="/work_types" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Work types')}</p>
                      </a>
                    </li>
                    }
					{permissions.includes('settings.documents') &&
                    <li className="nav-item">
                      <a href="/mail_templates" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('E-mail templates')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.documents') &&
                    <li className="nav-item">
                      <a href="/documents" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Document templates')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.documents') &&
                    <li className="nav-item">
                      <a href="/outinvoices_documents" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Outgoing invoices templates')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.documents') &&
                    <li className="nav-item">
                      <a href="/supplylists_documents" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Templates supply/loading lists')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.bank_accounts') &&
                    <li className="nav-item">
                      <a href="/bank_accounts" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Bank accounts')}</p>
                      </a>
                    </li>
                    }

                    {permissions.includes('settings.invoice_otheritems') &&
                    <li className="nav-item">
                      <a href="/invoice_otheritems" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Invoices - additional items')}</p>
                      </a>
                    </li>
                    }

                    {permissions.includes('settings.drivers') &&
                    <li className="nav-item">
                      <a href="/drivers" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Drivers')}</p>
                      </a>
                    </li>
                    }



                </ul>
              </li>

              <li className="nav-item has-treeview">
                <a
                    href="//"
                    ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}
                    onClick={(event) => {

						event.preventDefault( );
						//$("#dashboard-settings-users-link").slideToggle();
				
						  
						let container = document.getElementById( "dashboard-settings-users-link" );
						
						let containerArrow = document.getElementById( "dashboard-settings-users-link-arrow" );

						if( container.style.height === "0px" ){
							
							container.style.height = container.scrollHeight + "px";
							
							container.style.opacity = "1";
							
							containerArrow.className = "right fas fa-angle-down";

						} else {
							
							container.style.height = "0px";
							
							container.style.opacity = "0";
							
							containerArrow.className = "right fas fa-angle-left";

						}

                    }}
                >
                  <i className="nav-icon fas fa-cogs"></i>
                  <p>
                    {this.props.t('Users settings')} <i id="dashboard-settings-users-link-arrow" className="right fas fa-angle-left"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview" id="dashboard-settings-users-link" style={{ display: "block", height: "0px", visibility: "visible", opacity: "0", transition: "height .5s linear, opacity .5s linear"}}>
                    {permissions.includes('settings.users') &&
                    <li className="nav-item">
                      <a href="/users" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Users')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.roles') &&
                    <li className="nav-item">
                      <a href="/roles" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Roles')}</p>
                      </a>
                    </li>
                    }
                    {permissions.includes('settings.permissions') &&
                    <li className="nav-item">
                      <a href="/permissions" ref={(input) => { classRefFunction( input ); }} onClick={classNameOnClick}>
                        <i className="far fa-circle nav-icon"></i>
                        <p>{this.props.t('Permissions')}</p>
                      </a>
                    </li>
                    }




                </ul>
              </li>
              </>
                }

            </ul>
          </nav>
        </div>
      </aside>
    );
  }
};


Aside.propTypes = {
	t: PropTypes.func.isRequired,
};


export default translate( )( Aside );

