import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import InvoiceAdd from "../../components/invoices/invoice_add.tsx";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import TripCard from "../../components/tripcard";

import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";


import DatePicker from "../../aggrid/DatePicker.js";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';

import 'react-confirm-alert/src/react-confirm-alert.css';
import {LoadState, SaveState} from "../../services/GridStateService"; // Import css

class Trips extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA != null && valueB != null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };


        super(props)
        //console.log(props);
        this.AuditElement = React.createRef();
        this.state = {
            isColumnStateLoaded: false,
            popupParent: document.querySelector('body'),
            showModal: false,
            showArchive: false,
            modules: AllCommunityModules,
            localeText: loctext,
            showInvoiceAdd: false,
            showInvoiceAddRownodes: [],
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Loading numbers'),
                    field: 'loading_numbers',
                    pinned: 'left',
                    checkboxSelection: true
                },

                {
                    headerName: this.props.t('Countries'),
                    field: 'countries',

                    valueGetter: function (params) {
                        if (params.data && params.data.id) {

                            if (params.data['countries'] === null || params.data['countries'] === '') return '';
                            var values = JSON.parse(localStorage.getItem('countries'));
                            try {

                                var fin = '';
                                var countries = params.data['countries'].split(', ');
                                countries.map((e) => {
                                    fin += values.find(refData => String(refData.id) === String(e))['name_' + locale];
                                })


                                return fin;
                            } catch (err) {
                                console.log(params);
                                console.log(params.data);
                                console.log(params.data['speditor']);
                                console.log(values);
                                console.log(err);
                                return '';
                            }
                        }
                    }

                },
                {
                    headerName: this.props.t('Speditor'),
                    field: 'speditor',
                    valueGetter: function (params) {
                        if (params.data && params.data.id) {

                            if (params.data['speditor'] === null || params.data['speditor'] === '') return '';
                            var values = JSON.parse(localStorage.getItem('users'));
                            try {

                                return values.find(refData => String(refData.id) === String(params.data['speditor']))['name'];
                            } catch (err) {
                                console.log(params);
                                console.log(params.data);
                                console.log(params.data['speditor']);
                                console.log(values);
                                console.log(err);
                                return '';
                            }
                        }
                    }


                },

                {
                    headerName: this.props.t('Speditor company'),
                    field: 'speditor_company',
                    valueGetter: function (params) {
                        if (params.data && params.data.id) {

                            return params.data.speditor_company_name;
                        }
                    }


                },

                {
                    headerName: this.props.t('Calculated km'),
                    field: 'calculated_distance',
                    valueGetter: function (params) {
                        if (params.data && params.data.id) {
                            if (params.data['calculated_distance'] === null || params.data['calculated_distance'] === '') return '';
                            let km = parseInt(params.data['calculated_distance']) / 1000
                            return km.toFixed(2);
                        }
                    }
                },
                {
                    headerName: this.props.t('Real km'),
                    field: 'real_distance',
                    editable: true,
                    valueGetter: function (params) {
                        if (params.data && params.data.id) {
                            if (params.data['real_distance'] === null || params.data['real_distance'] === '') return '';
                            let km = parseInt(params.data['real_distance']) / 1000
                            return km.toFixed(2);
                        }
                    },
                    valueSetter: function (params) {
                        if (params.data && params.data.id) {
                            params.data['real_distance'] = params.newValue * 1000;
                        }
                    }
                },
                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                    editable: true
                },
                {
                    headerName: this.props.t('Finished'),
                    field: 'finished',
                    valueGetter: function (params) {
                        if (params.data && params.data.id) {
                            try {
                                return JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(params.data['finished']))['name_' + locale];
                            } catch (err) {
                                console.log(params.data['finished'])
                                console.log(err);
                                return '';
                            }
                        }
                    }
                },

                {
                    headerName: this.props.t('Invoice number'),
                    field: 'invoice_number',
                    cellRenderer: "FileRenderer",

                    cellRendererParams: {
                        type: 'invoice_file'
                    },
                },
                {
                    headerName: this.props.t('Invoice currency'),
                    field: 'invoice_currency',
                },
                {
                    headerName: this.props.t('Exc. VAT'),
                    field: 'invoice_excvat',
                    editable: function (params) {

                        if (params.data && params.data.id) {
                            if (params.data.invoice == null || params.data.invoice == '') {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }

                },
                {
                    headerName: this.props.t('VAT'),
                    field: 'invoice_vat',
                    editable: function (params) {

                        if (params.data && params.data.id) {
                            if (params.data.invoice == null || params.data.invoice == '') {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                },
                {
                    headerName: this.props.t('Inc. VAT'),
                    field: 'invoice_incvat',

                },
                {
                    headerName: this.props.t('Exch. Rate'),
                    field: 'invoice_rate',
                },
                {
                    headerName: this.props.t('Issue date'),
                    field: 'invoice_issue',
                    valueGetter: function (params) {
                        if (!params.data.invoice_issue || params.data.invoice_issue === null || params.data.invoice_issue === 0 || params.data.invoice_issue === '') return '';
                        var date = new Date(params.data.invoice_issue);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        month++;
                        return day + '.' + month + '.' + year;

                    }
                },

                {
                    headerName: this.props.t('Due date'),
                    field: 'invoice_due',
                    valueGetter: function (params) {
                        if (!params.data.invoice_due || params.data.invoice_due === null || params.data.invoice_due === 0 || params.data.invoice_due === '') return '';
                        var date = new Date(params.data.invoice_due);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        month++;
                        return day + '.' + month + '.' + year;

                    }
                },
                {
                    headerName: this.props.t('Paid date'),
                    field: 'paid_date',
                    valueGetter: function (params) {
                        if (!params.data.paid_date || params.data.paid_date === null || params.data.paid_date === 0 || params.data.paid_date === '') return '';
                        var date = new Date(params.data.paid_date);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        month++;
                        return day + '.' + month + '.' + year;

                    }
                },
                {
                    headerName: this.props.t('Taxable'),
                    field: 'invoice_taxable',
                    valueGetter: function (params) {

                        if (!params.data.invoice_taxable || params.data.invoice_taxable === null || params.data.invoice_taxable === 0 || params.data.invoice_taxable === '') return '';
                        var date = new Date(params.data.invoice_taxable);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        month++;
                        return day + '.' + month + '.' + year;

                    }
                },


            ],
            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

                comparator: customComparator
            },
            frameworkComponents: {

                DeleteRenderer: DeleteRenderer,
                FileRenderer: FileRenderer,
                DatePicker: DatePicker,

            },

        };


    }
    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('Trips', params.columnApi, params.api);
        }
    }

    showInvoiceAdd() {
        var mult_lns = [];
        var ok = true;
        var existing_invoice = false;
        this.gridApi.getSelectedNodes().forEach((e) => {
// if (e.data.invoice_incvat==null || parseFloat(e.data.invoice_incvat)==0) ok=false;
            if (e.data.invoice != null && e.data.invoice != '') existing_invoice = true;
            mult_lns.push(e.data);
        });

        if (!ok) {
            alert(this.props.t('Cannot create an invoice. One of selected items has zero price'));
            return false;
        }

        if (existing_invoice) {
            alert(this.props.t('Cannot create an invoice. One of selected items already has assigned invoice'));
            return false;
        }

        this.setState({
            showInvoiceAdd: true,
            now: Date.now(),
            showInvoiceAddRownodes: mult_lns,
        });
    }

    async invoiceAdd(data) {

        var form_data = new FormData();

        for (var key in data) {
            if (key !== 'files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0] !== 'undefined') form_data.append('file1', data.files[0]);

        HttpService.post(UrlService.apiDomain() + 'api/invoices', form_data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');
                    this.setState({showInvoiceAdd: false});


                    console.log('Successfully updated');
                    var rownode;
                    res.data.trips.map((e) => {
                        rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }


    componentDidMount() {

        HttpService.get(UrlService.apiDomain() + 'api/trips')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });


                var hardcodedFilter = {
                    finished: {
                        type: 'set',
                        values: [this.props.t('No')],
                    },
                };


                function tt() {
                    this.gridApi.setFilterModel(hardcodedFilter)
                }


                setTimeout(tt.bind(this), 1);
                setTimeout(() => {
                    LoadState('Trips', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();
        var roles = [];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
            roles.push(r.name);
        });
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('loadings.trips') && !roles.includes('Spedice')) {
            items.push({
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",
                field: 'delete',

                cellRendererParams: {
                    gridType: "trips",
                },
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);


        this.gridApi.setDomLayout('normal');
        LoadState('Trips', params.columnApi, params.api);
    };

    onCellCValueChanged = (event) => {
        console.log(event);

        event.data.field = event.colDef.field;
        HttpService.put(UrlService.apiDomain() + 'api/trips/' + event.data.id, event.data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.alert !== '' && res.data.alert !== null) alert(res.data.alert);
                if (res.data.result == 'failed') {
                    alert(res.data.message);

                } else {
                    console.log('Successfully updated');

                    var rownode = this.gridApi.getRowNode(res.data.entry.id);
                    if (rownode) {
                        rownode.setData(res.data.entry);
                    }


                }
            })
            .catch((error) => {
                alert(error);
                console.log(error);
            })


    }


    onCellClicked = (e) => {

        var field = e.column.colId;

        if (field !== "invoice_number" && field !== "real_distance" && field !== "note" && field !== "delete" && field !== "invoice_incvat" && field !== "invoice_excvat" && field !== "invoice_vat") {
            this.setState({
                showModal: true,
                showModalTripId: e.node.data.id,
            });
        }


    }


    onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected: true});
        } else {
            this.setState({rowSelected: false});
        }
    }


    getRowNodeId = (data) => {
        return data.id;
    };

    render() {

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        return (
            <div className="wrapper">
                <Layout>
                    <Content hideTitle={true} title="Trips">

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight': '9px'}}>

                                {permissions.includes('loadings.spedition') &&
                                    <button type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.gridApi.exportDataAsExcel()}
                                    ><i className="fa  fa-file-excel"></i>
                                    </button>
                                }


                            </div>


                            <button type="button" style={{'marginRight': '9px'}}
                                    className="btn btn-success btn-sm"
                                    onClick={() => {


                                        if (!this.state.showArchive) {
                                            this.gridApi.setFilterModel(null);
                                        } else {
                                            var hardcodedFilter = {
                                                finished: {
                                                    type: 'set',
                                                    values: [this.props.t('No')],
                                                },
                                            };
                                            this.gridApi.setFilterModel(hardcodedFilter);
                                        }
                                        this.setState({showArchive: !this.state.showArchive});


                                    }}
                            >{this.props.t('Archive')} <i className="fa fa-archive"></i>
                            </button>

                            {(permissions.includes('invoices.create') && !this.props.fromtab) &&

                                <button type="button"
                                        disabled={!this.state.rowSelected}
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                            this.showInvoiceAdd();

                                        }}
                                >{this.props.t('Create invoice')} <i className="fa  fa-file-invoice"></i>
                                </button>
                            }
                        </div>

                        <Modal

                            title={this.props.t('Spedition')}

                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({showModal: !this.state.showModal})}
                        >
                            <TripCard id={this.state.showModalTripId} show={this.state.showModal} tmp={Date.now()}
                            />
                        </Modal>

                        {(permissions.includes('invoices.create') && !this.props.fromtab) &&
                            <Modal
                                title={this.props.t('Add new')}
                                visibility={this.state.showInvoiceAdd}
                                size="lg"
                                onClose={() => this.setState({showInvoiceAdd: !this.state.showInvoiceAdd})}
                            >
                                <InvoiceAdd rownodes={this.state.showInvoiceAddRownodes} type='trips' fromtab={false}
                                            tmp={this.state.now}
                                            onAdd={(data) => this.invoiceAdd(data)}/>
                            </Modal>
                        }

                        <div style={{'height': '800', width: '100%'}} id="tripsGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                onCellClicked={this.onCellClicked}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                                frameworkComponents={this.state.frameworkComponents}
                                components={this.state.components}
                                enableCellChangeFlash={true}
                                onRowSelected={this.onRowSelected.bind(this)}
                                popupParent={this.state.popupParent}
                                suppressContextMenu={true}
                                pagination={true}
                                paginationPageSize={1000}
                                localeText={this.state.localeText}
                                getRowNodeId={this.getRowNodeId}
                                rowSelection={'multiple'}
                                suppressRowClickSelection={true}

                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Trips.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Trips);

