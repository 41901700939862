import React, { Component } from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import Home from "./../../components/home";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Loading from "../../components/common/loading";
import SessionStorageService from "../../services/SessionStorageService";
import loadingGif from "../../assets/img/loading.gif";
class HomePage extends Component {
    constructor(props) {

        super(props)
        this.state = {

            showLoading: false,
        }
/*
        const that = this;

        var loadingInterval = setInterval(setLoaded, 100);

        function setLoaded() {
          //  console.log(2);
            if (localStorage.getItem('data_arrays') != null && SessionStorageService.isEverythingLoaded() == true) {
                that.setState({
                    showLoading: false
                });
                stopLoadingInterval();
            }
        }

        function stopLoadingInterval() {
            clearInterval(loadingInterval);
        }
*/

    }


  render() {
    return (
      <div className="wrapper">
        <Layout>
          <Content title={this.props.t('dashboard')}>
              <Loading
                  title={this.props.t('Loading')}
                  visibility={this.state.showLoading}
                  size="lg"

              ><div className="row justify-content-md-center">
                  <img height="150"
                       src={loadingGif}

                       alt="Loading"
                  />
              </div>
              </Loading>
            <Home />
          </Content>
        </Layout>
      </div>
    );
  }
}
HomePage.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(HomePage);

