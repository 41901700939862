"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentLayoutElementT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLayoutHT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentTextT from "../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentImageT from "../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";


import UIComponentLayoutElementConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIStateT from "../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../Type/UI/State/UIStateMapT.mjs";


const UIComponentTextDropDownT = class UIComponentTextDropDownT extends UIComponentLayoutHT {

	#UIComponentText = null;
	#UIComponentSeparator = null;
	#UIComponentImage = null;

	constructor(
		UIElement,
		UIComponentTextDropDownConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIComponentTextDropDownConfig instanceof UIComponentTextDropDownConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
	
	
		super(
			UIElement,
			UIComponentTextDropDownConfig
		);
		
		
		//Text
		
		this.#UIComponentText = new UIComponentTextT(
			new UIElementT( ),
			UIComponentTextDropDownConfig.TextConfigGet( )
		);
		
		let UIComponentLayoutElementText = new UIComponentLayoutElementT( 
			new UIElementT( ),
			UIComponentTextDropDownConfig.LayoutElementConfigTextGet( )
		);
		
		UIComponentLayoutElementText.DataSet( 
			this.#UIComponentText 
		);
		
		super.ElementSet( 
			0, 
			UIComponentLayoutElementText
		);
		
		//Separator


		this.#UIComponentSeparator = new UIComponentBaseT(
			new UIElementT( ),
			UIComponentTextDropDownConfig.SeparatorConfigGet( )
		);
		
		let UIComponentLayoutElementSeparator = new UIComponentLayoutElementT( 
			new UIElementT( ),
			UIComponentTextDropDownConfig.LayoutElementConfigSeparatorGet( )
		);
		
		UIComponentLayoutElementSeparator.DataSet( 
			this.#UIComponentSeparator 
		);
		
		super.ElementSet( 
			1, 
			UIComponentLayoutElementSeparator
		);
		
		//Image
		
		this.#UIComponentImage = new UIComponentImageT(
			new UIElementT( ),
			UIComponentTextDropDownConfig.ImageConfigGet( )
		);
		
		let UIComponentLayoutElementImage = new UIComponentLayoutElementT( 
			new UIElementT( ),
			UIComponentTextDropDownConfig.LayoutElementConfigImageGet( )
		);
		
		UIComponentLayoutElementImage.DataSet( 
			this.#UIComponentImage 
		);
		
		super.ElementSet( 
			2, 
			UIComponentLayoutElementImage
		);
	
	}
	
	TextGet(
	
	){
	
		return this.#UIComponentText;
	
	}
	
	IsValidGet( ){
		
		return this.#UIComponentText.IsValidGet( )
		
	}
	
	ValueGet( 
	
	){
		
		return this.#UIComponentText.ValueGet( );
		
	}
	
	ValueSet( 
		Value,
		NeedSelection
	){
		
		console.log( "UIComponentTextT.ValueSet", Value );
		
		
		this.#UIComponentText.ValueSet( Value, Value.length, Value.length, NeedSelection );
		
	}
	
	#OnChange(
		Value,
		IsValid
	){
		
		if( super.EventExists( "Change" ) === true ){
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( Value, IsValid );
			
		}
		
	}
	
	Update(
	
	){
		
		this.#UIComponentText.Update( );
		
		this.#UIComponentSeparator.Update( );
		
		this.#UIComponentImage.Update( );
		
	
		super.Update( );
	
	}
	
	Start( 
		OnTextFocusFn,
		OnTextBlurFn
	){
		
		console.log( "UIComponentTextDropDownT.Start" );
		
		
		this.#UIComponentText.EventSet( "Focus", OnTextFocusFn );
		
		this.#UIComponentText.EventSet( "Blur", OnTextBlurFn );
		
		
		this.#UIComponentText.Start( );
		
		this.#UIComponentSeparator.Start( );
		
		this.#UIComponentImage.Start( );
		
		
		super.Start( );
		
	}
	
	Stop(
	
	){
		
		console.log( "UIComponentTextDropDownT.Stop" );
		
		
		this.#UIComponentText.EventRemove( "Focus" );
		
		this.#UIComponentText.EventRemove( "Blur" );
		
		
		this.#UIComponentText.Stop( );
		
		this.#UIComponentSeparator.Stop( );
		
		this.#UIComponentImage.Stop( );
		
		
		super.Stop( );
		
	}
	
	
	Listen(
		OnChangeFn,
		OnPlaceholderShow,
		OnPlaceholderHide
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderShow ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderHide ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#UIComponentText.Listen( 
			OnChangeFn,
			OnPlaceholderShow,
			OnPlaceholderHide
		);
		
	}
	
	Focus( ){
		
		console.log( "UIComponentTextDropDownT.Focus" );
		
		this.#UIComponentText.Focus( );
		
	}
	
	Blur( ){
		
		this.#UIComponentText.Blur( );
		
	}
	
	Unlisten( 
	
	){
		
		this.#UIComponentText.Unlisten( );
		
	}
	
	EditableSet(
		Editable
	){
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		this.#UIComponentText.EditableSet( Editable );
		
	}
	
	EditableGet(
		
	){
	
		return this.#UIComponentText.EditableGet( );
		
	}
	
	PlaceholderSet( Show ){
		
		this.#UIComponentText.PlaceholderSet( Show );
		
	}
	
};


export default UIComponentTextDropDownT;
