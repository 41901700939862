import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import Modal from "../../components/common/modal";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Datetime from 'react-datetime';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";


class LoadingsFinalCalculationPlain  extends React.Component {

    constructor(props) {
	
        console.log( "Constructor props", props );
	
        super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ){
			
			locale = localStorage.getItem( 'locale' );
			
		}
		
		let loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
		
		
		let datetime = new Date( );
		
		this.state = {
			locale: locale,
			datetime: this.FormatDatetime( datetime ),
			datetime_server: this.FormatDatetimeServer( datetime ), 
			expense_types_types: JSON.parse( localStorage.expense_types_types ),
			expense_types: JSON.parse( localStorage.expense_types ),
			expenses: this.props.expenses,
			expenses_all: this.props.expenses_all,
			isopen: false,
			rates: this.props.rates,
			date_from: this.props.date_from,
			date_to: this.props.date_to,
			
			modules: AllCommunityModules,
            localeText: loctext
		};
		
    }

    FormatDatetime( date ){
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	FormatDatetimeServer( date ){
	
		return this.FormatDateServer( date ) + ' ' + this.FormatTimeServer( date );
	
	}
	
	FormatDateServer( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FormatTimeServer( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	componentDidMount( ){
	
	}


    componentDidUpdate(prevProps) {

        console.log( "component plain did update", this.props, prevProps, this.state );
		
		
		let StateChange = { };
		
		let NeedFill = false;
		
		if(  ( this.props.isopen != prevProps.isopen ) && ( this.props.isopen == false ) ){
		
			console.log( "component table closed" );
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.expenses ) != JSON.stringify( this.props.expenses ) ) &&
			( this.props.expenses != null )
		) {
		
			StateChange.expenses = this.props.expenses;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.expenses_all ) != JSON.stringify( this.props.expenses_all ) ) &&
			( this.props.expenses_all != null )
		) {
		
			StateChange.expenses_all = this.props.expenses_all;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.rates ) != JSON.stringify( this.props.rates ) ) &&
			( this.props.rates != null )
		) {
		
			StateChange.rates = this.props.rates;
			
			NeedFill = true;
		
		}
		
		let DFPrev = ( prevProps.date_from == null ) ? 0 : prevProps.date_from.getTime( );
		
		let DFCurr = ( this.props.date_from == null ) ? 0 : this.props.date_from.getTime( );
		
		if( 
			( DFPrev != DFCurr )
		) {
		
			StateChange.date_from = this.props.date_from;
			
			NeedFill = true;
		
		}
		
		let DTPrev = ( prevProps.date_to == null ) ? 0 : prevProps.date_to.getTime( );
		
		let DTCurr = ( this.props.date_to == null ) ? 0 : this.props.date_to.getTime( );
		
		if( 
			( DTPrev != DTCurr )
		) {
		
			StateChange.date_to = this.props.date_to;
			
			NeedFill = true;
		
		}
		
		
		if( NeedFill == true ){
		
			this.setState( 
				StateChange
			);
		
		}
		
    }
	
	FormatManager( ){
	
		let users = JSON.parse( localStorage.getItem( 'users' ) );
				
		let manager = "";
				
		if( this.state.manager != null ){
				
			for(
				let i in users
			){
				
				if( users[ i ].id.toString( ) == this.state.manager.toString( ) ){
				
					console.log( users[ i ].id, this.state.manager );
					
					manager = users[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}
		
		}
	
		return manager;
	
	}
	
	FilterFormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + "." + MM + "." + YYYY;
		
	}

    render( ) {
	
		let FormatReportArray = [ ];
		
		let Expenses = this.state.expenses_all;
	
		if( Expenses != null ){
		
			let Results = { };
		
			let users = JSON.parse( localStorage.getItem( 'users' ) );
			
		
			for( let i in Expenses ){
			
				let expense = Expenses[ i ];
				
				let manager_name = "";
				
				for(
					let i in users
				){
					
					if( users[ i ].id.toString( ) == expense.manager.toString( ) ){
						
						manager_name = users[ i ][ 'name_' + this.state.locale ];
						
						break;
						
					}
					
				}
				
				if( !Results[ manager_name ]  ){
				
					Results[ manager_name ] = {
						manager: manager_name,
						positives: 0.00,
						negatives: 0.00,
						balanceto: 0.00
					};
				
				}
				
				let AmountEUR = parseFloat( expense.amount_eur );
				
				if( 
					( this.state.date_from == null || ( ( new Date( expense.datetime ) ).getTime( ) >= this.state.date_from.getTime( ) ) ) &&
					( this.state.date_to == null || ( ( new Date( expense.datetime ) ).getTime( ) <= this.state.date_to.getTime( ) + ( 24 * 60 * 60 * 1000 ) ) )
				){
				
					if( AmountEUR > 0 ){
					
						Results[ manager_name ].positives += AmountEUR;
					
						//Results[ manager_name ].positives += ( ( parseFloat( expense.amount ) * parseFloat( expense.rate ) ) / this.state.rates[ 3 ] );
					
					} else {
					
						Results[ manager_name ].negatives += AmountEUR;
					
						//Results[ manager_name ].negatives += ( ( parseFloat( expense.amount ) * parseFloat( expense.rate ) ) / this.state.rates[ 3 ] );
						
					}
				
				}
				
				if( 
					( ( this.state.date_to == null ) || ( ( new Date( expense.datetime ) ).getTime( ) <= this.state.date_to.getTime( ) + ( 24 * 60 * 60 * 1000 ) ) )
				){
				
					Results[ manager_name ].balanceto += AmountEUR;
					
					//Results[ manager_name ].balanceto += ( ( parseFloat( expense.amount ) * parseFloat( expense.rate ) ) / this.state.rates[ 3 ] );
				
				}
				
				console.log( manager_name, expense.amount, expense.currency, expense.rate );
			
			}
			
			for(
				let i in Results
			){
			
				FormatReportArray.push( Results[ i ] );
			
			}
		
		}
		
		let date_to = this.FilterFormatDate(  
			( ( this.state.date_to ) == null ? 
				( new Date( ( new Date( ) ).getTime( ) + ( 24 * 60 * 60 * 1000 ) ) ) : 
				( new Date( this.state.date_to.getTime( ) + ( 24 * 60 * 60 * 1000 ) ) ) ) 
		);
		

		
		let PositivesTotal = 0.00;
		
		let NegativesTotal = 0.00;
		
		let BalanceToTotal = 0.00;
		
		let FormatReportRows = FormatReportArray.map( function( el ){
		
			PositivesTotal += el.positives;
			
			NegativesTotal += el.negatives;
			
			BalanceToTotal += el.balanceto;
									
			let manager = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.manager );
			
			let positives = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.positives.toFixed( 2 ) + " €" );
			
			let negatives = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.negatives.toFixed( 2 ) + " €" );
			
			let balanceto = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.balanceto.toFixed( 2 ) + " €" );
	
			return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, manager, positives, negatives, balanceto );
		
		} );
			
		let manager = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, this.props.t( "Manager" ) );
				
		let positives = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, this.props.t( "Revenues" ) );
				
		let negatives = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, this.props.t( "Cost" ) );
		
		let balanceto = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, this.props.t( "Balance to" ) + " " + date_to );
		
		let headerRow = React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, manager, positives, negatives, balanceto ) ;
		
		
		let managerTotal = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, this.props.t( "Total" ) );
				
		let positivesTotal = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, PositivesTotal.toFixed( 2 ) + " €" );
				
		let negativesTotal = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, NegativesTotal.toFixed( 2 ) + " €" );
		
		let balancetoTotal = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px", fontWeight: "bold" }}, BalanceToTotal.toFixed( 2 ) + " €" );
		
		let RowTotal = React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, managerTotal, positivesTotal, negativesTotal, balancetoTotal ) ;
		
			
		let FormatReportTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black", width: "100%" }}, headerRow, ...FormatReportRows, RowTotal );
		
		return (
			<>
				<div className="container" style={{width: '100%', maxWidth: '100%', overflowY: 'auto', height: ( window.innerHeight - 100 ) + "px" }}>
					<div className="container" style={{width: '100%', maxWidth: '100%'}}>
						<hr/ >
						<div className="row">
							<div className="col-12" style={{marginRight: '10px'}}>
								{ 
									FormatReportTable
								}
							</div>
							
						</div>
						<hr/ >
					</div>
				</div>
			</>
        );
    }
}

LoadingsFinalCalculationPlain.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingsFinalCalculationPlain);

