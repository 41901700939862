import React, { useState, forwardRef, useImperativeHandle } from "react";

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';

export default forwardRef((props, ref) => {

    const [value, setValue] = useState('');

    function onChange(value, event) {

        setValue(value);

    }

    function getVal(e) {

    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {

                return value;
            },
            afterGuiAttached: async() => {

                setValue(props.value);

                getVal();


            },

            isPopup: () => {
                return true;
            }
        };
    });


    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
    return (
<div style={{"zIndex":"12000"}}>
        <Datetime
            locale={locale}
            dateFormat="DD.MM.YYYY"
            timeFormat="HH:mm"
            value={value}

            onChange={onChange}
        />
</div>
    )


})
