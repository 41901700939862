"use strict";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import UIComponentLayoutHT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import GTSUIComponentFormSecondaryLineElementT from "./GTSUIComponentFormSecondaryLineElementT.mjs";


const GTSUIComponentFormSecondaryLineT = class GTSUIComponentFormSecondaryLineT extends UIComponentLayoutHT {

	constructor( 
		GTSUIComponentFormSecondaryLineElementList
	){
		
		if( ( GTSUIComponentFormSecondaryLineElementList instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < GTSUIComponentFormSecondaryLineElementList.length;
			I++
		){
			
			if( ( GTSUIComponentFormSecondaryLineElementList[ I ] instanceof GTSUIComponentFormSecondaryLineElementT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		

		let UIComponentLayoutHConfig = new UIComponentLayoutHConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"6px",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutHT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
		);
	
	
		super( 
			new UIElementT( ),
			UIComponentLayoutHConfig
		);
		
		
		
		for(
			let I = 0;
			I < GTSUIComponentFormSecondaryLineElementList.length;
			I++
		){
			
			this.ElementSet(
				I,
				GTSUIComponentFormSecondaryLineElementList[ I ]
			);
			
		}
		
	}
	
	ElementCount(
	
	){
		
		return super.ElementCount( );
		
	}
	
	ElementRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.ElementRemove( Index );
		
	}
	
	ElementGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		

		return super.ElementGet( Index );
		
	}
	
	ElementSet(
		Index,
		GTSUIComponentFormSecondaryLineElement
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		if( ( GTSUIComponentFormSecondaryLineElement instanceof GTSUIComponentFormSecondaryLineElementT ) === false ){

			throw new Error( "Argument" );
		
		}
		
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", GTSUIComponentFormSecondaryLineElement.WidthGet( ) )
						] )
					)
					
				] )
			)
		);
		
		
		UIComponentLayoutElement.DataSet( GTSUIComponentFormSecondaryLineElement );
		
		
		super.ElementSet( Index, UIComponentLayoutElement );
		
	}
	
};


export default GTSUIComponentFormSecondaryLineT;