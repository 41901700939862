let apiDomain = "";
//if (process.env.NODE_ENV === "production") {
  apiDomain = process.env.REACT_APP_PRODAPIURL;
//  if (window.location.href.indexOf('test')!=-1) apiDomain = process.env.REACT_APP_TESTAPIURL;
//} else {
//  apiDomain = process.env.REACT_APP_LOCALAPIURL;
//}

let API_URL = "";
//if (process.env.NODE_ENV === "production") {
  API_URL = process.env.REACT_APP_API_URL_PROD;
//  if (window.location.href.indexOf('test')!=-1) API_URL = process.env.REACT_APP_API_URL_TEST;
//} else {
//  API_URL = process.env.REACT_APP_API_URL_LOCAL;
//}


class UrlService {

	static API_URL( ){
		return API_URL;
	}

  static apiDomain() {
    return apiDomain;
  }
  static loginUrl() {
    return apiDomain + "api/login";
  }
  static currentUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static saveUserProfileUrl() {
    return apiDomain + "api/user";
  }
  static getCurrentUserAcitiviesUrl() {
    return apiDomain + "api/activities";
  }
  static getTodoUrl() {
    return apiDomain + "api/todos";
  }
  static markTodoCompleteUrl(id) {
    return apiDomain + "api/todo/complete/" + id;
  }
  static changeTodoOrderUrl() {
    return apiDomain + "api/todo/reorder";
  }
  static saveTodoUrl() {
    return apiDomain + "api/todo/save";
  }
  static removeTodoUrl() {
    return apiDomain + "api/todo/remove";
  }
}

export default UrlService;
