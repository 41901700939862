"use strict";


import UIStateT from "./UIStateT.mjs";


const UIStateMapT = class UIStateMapT {
	
	#Map = null;
	
	constructor(
		UIStateList
	){
		
		
		if( ( UIStateList instanceof Array ) === false ){
				
			throw new Error( "Argument" );
				
		}
		
		for( 
			let I = 0;
			I < UIStateList.length;
			I++
		){
		
			if( ( UIStateList[ I ] instanceof UIStateT ) === false ){
				
				throw new Error( "Argument" );
				
			}
		
		}
		
		
		this.#Map = new Map( );
		
		
		for( 
			let I = 0;
			I < UIStateList.length;
			I++
		){
		
			this.Set( UIStateList[ I ] );
		
		}
		
	}
	
	Get(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
			
			
		return this.#Map.get( Name );
			
	}
	
	Exists(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
			
			
		return this.#Map.has( Name );
			
	}
	
	Remove(
		Name
	){
		
		if( typeof( Name ) !== "string" ){
				
			throw new Error( "Invalid argument type" );
			
		}
			
			
		this.#Map.delete( Name );
			
	}
	
	Set(
		UIState
	){
		
		if( ( UIState instanceof UIStateT ) === false ){
				
			throw new Error( "Argument" );
			
		}
			
		
		this.#Map.set( UIState.NameGet( ), UIState );
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( )
		
	}
	
	Copy(
	
	){
		
		return new UIStateMapT( [
			...this.#Map.values( )
		] );
		
	}
				
};


export default UIStateMapT;