"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";

import ComponentLayoutElementConfigT from "./ComponentLayoutElementConfigT.mjs";


const ComponentLayoutVDynamicConfigT = class ComponentLayoutVDynamicConfigT extends ComponentBaseConfigT {

	#ComponentLayoutElementConfig = null;

	constructor(
		ComponentLayoutElementConfig,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);
		
		this.ComponentLayoutElementConfigSet( ComponentLayoutElementConfig );
	
	}
	
	ComponentLayoutElementConfigSet(
		ComponentLayoutElementConfig
	){
		
		console.log( ComponentLayoutElementConfig );
			
		if( ( ComponentLayoutElementConfig instanceof ComponentLayoutElementConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		this.#ComponentLayoutElementConfig = ComponentLayoutElementConfig;
		
	}
	
	ComponentLayoutElementConfigGet(
	
	){
	
		return this.#ComponentLayoutElementConfig;
	
	}
	
	Copy( ){
		
		return new ComponentLayoutVDynamicConfigT( 
			this.ComponentLayoutElementConfigGet( ).Copy( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}

};


export default ComponentLayoutVDynamicConfigT;