"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentBaseT from "../Base/ComponentBaseT.mjs";

import ComponentTextT from "../Text/ComponentTextT.mjs";

import ComponentDoubleConfigT from "../../../Type/Component/Double/ComponentDoubleConfigT.mjs";

import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentDoubleT = class ComponentDoubleT extends ComponentTextT {

	#ComponentDoubleConfig = null;
	
	#Value = 0;
	
	constructor(
		GUIElement,
		ComponentDoubleConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( ComponentDoubleConfig instanceof ComponentDoubleConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentDoubleConfig
		);
		
		this.PlaceholderSet( ComponentDoubleConfig.PlaceholderGet( ) );
		
		this.#ComponentDoubleConfig = ComponentDoubleConfig;
		
		this.#Value = 0;
		
	}
	
	ValueCheck( ){
		
		console.log( "ComponentDoubleT.ValueCheck" );
		
		return super.ValueCheck( );
		
	}
	
	/*ValueSet( Value ){
		
		console.log( "ComponentDoubleT.ValueSet" );
		
		if( typeof( Value ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Value = Value;
		
		super.ValueSet( this.#Value.toString( ) );
		
	}*/
	
	ValueGet( ){
		
		console.log( "ComponentDoubleT.ValueGet" );
		
		let Value = super.ValueGet( );
		
		if( Value === "" ){
			
			return 0;
			
		}
		
		return parseFloat( Value );//this.#Value;
		
	}
				
};


export default ComponentDoubleT;