"use strict";


const APIModelInsertResponseAbstractT = class APIModelInsertResponseAbstractT {
	
	constructor(

	){
		
	}
	
	ModelName( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	Data(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	ChildrenList(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default APIModelInsertResponseAbstractT;