"use strict";


const UIOptionT = class UIOptionT {
	
	#Key = null;
	#Value = null;
	
	constructor(
		Key,
		Value
	){
		
		this.KeySet( Key );
		
		this.ValueSet( Value );
		
	}
	
	KeySet(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		this.#Key = Key;
		
	}
	
	KeyGet(
		
	){
		
		return this.#Key;
		
	}
	
	ValueSet(
		Value
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		this.#Value = Value;
		
	}
	
	ValueGet(
		Value
	){
		
		return this.#Value;
		
	}
	
	Copy( 
	
	){
		
		return new UIOptionT(
			this.#Key,
			this.#Value
		);
		
	}
				
};


export default UIOptionT;