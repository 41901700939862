"use strict";


import APIModelInsertRequestAbstractT from "./_/APIModelInsertRequestAbstractT.mjs";

import APIFieldValueMapT from "../../../FieldValue/APIFieldValueMapT.mjs";

import APIModelNameT from "../../Name/APIModelNameT.mjs";

import APIModelInsertRequestJoinTypeT from "./Join/APIModelInsertRequestJoinTypeT.mjs";

import APIModelInsertRequestJoinDataT from "./Join/APIModelInsertRequestJoinDataT.mjs";

import APIModelInsertRequestChildrenListT from "./Children/APIModelInsertRequestChildrenListT.mjs";


const APIModelInsertRequestT = class APIModelInsertRequestT extends APIModelInsertRequestAbstractT {
	
	#ModelName = null;
	
	#JoinType = null;
	#JoinData = null;
	
	#Data = null;
	
	#ChildrenList = null;
	
	constructor(
		ModelName,
		
		JoinType,
		JoinData,
		
		Data,
		
		ChildrenList
	){
		
		if( ( ModelName instanceof APIModelNameT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( JoinType instanceof APIModelInsertRequestJoinTypeT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( JoinData instanceof APIModelInsertRequestJoinDataT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Data instanceof APIFieldValueMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ChildrenList instanceof APIModelInsertRequestChildrenListT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		super( );
		
		
		this.#ModelName = ModelName;
		
		this.#JoinType = JoinType;
		
		this.#JoinData = JoinData;
		
		this.#Data = Data;
		
		this.#ChildrenList = ChildrenList;
		
	}
	
	ModelName( 
	
	){
		
		return this.#ModelName;
		
	}
	
	JoinType( 
	
	){
		
		return this.#JoinType;
		
	}
	
	JoinData(
	
	){
		
		return this.#JoinData;
		
	}
	
	Data(
	
	){
		
		return this.#Data;
		
	}
	
	ChildrenList(
	
	){
		
		return this.#ChildrenList;
		
	}
	
	static FromJSON(
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelInsertRequestList = [ ];
		
		let JSONChildrenList = JSONData[ "ChildrenList" ];
		
		for(
			let I = 0;
			I < JSONChildrenList.length;
			I++
		){
			
			APIModelInsertRequestList.push(
				APIModelInsertRequestT.FromJSON(
					JSONChildrenList[ I ]
				)
			);
			
		}
		
		let ChildrenList = new APIModelInsertRequestChildrenListT(
			APIModelInsertRequestList
		);

		
		return new APIModelInsertRequestT(
			APIModelNameT.FromJSON( JSONData[ "ModelName" ] ),
			APIModelInsertRequestJoinTypeT.FromJSON( JSONData[ "JoinType" ] ),
			APIModelInsertRequestJoinDataT.FromJSON( JSONData[ "JoinData" ] ),
			APIFieldValueMapT.FromJSON( JSONData[ "Data" ] ),
			ChildrenList
		);
		
	}
	
	ToJSON(
	
	){
	
		let JSONData = {
			
		};
		
		let ChildrenList = [
			
		];
		
		for(
			let I = 0;
			I < this.#ChildrenList.Count( );
			I++
		){
			
			ChildrenList.push( ( this.#ChildrenList.Get( I ) ).ToJSON( ) );
			
		}
		
		JSONData[ "ModelName" ] = this.#ModelName.ToJSON( );
		
		JSONData[ "JoinType" ] = this.#JoinType.ToJSON( );
		
		JSONData[ "JoinData" ] = this.#JoinData.ToJSON( );
		
		JSONData[ "Data" ] = this.#Data.ToJSON( );
		
		JSONData[ "ChildrenList" ] = ChildrenList;
		
		return JSONData;
	
	}
	
};


export default APIModelInsertRequestT;