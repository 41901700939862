import React, { Component } from "react";


import TabPill from "../common/tab/tabpill";
import ProductionTab from "./production.tsx";
import ProductionHistoryTab from "./production_history";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";


class ManufacturingCard extends Component {
    getTabs() {

        var tabs= {
            production: {
                active: true,
                name: this.props.t('Production'),
                component: ProductionTab,

            },

            production_history: {
                active: false,
                name: this.props.t('Production history'),
                component: ProductionHistoryTab,

            },





        }


        return tabs;
    }

    state = {
        show:this.props.show,
        id:null,
        tmp:null,
        tabs: {},
    };


    getActiveTab() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        let newTabs = tabs;

        let activeTab=null;
        keys.forEach((key) => {
            if (newTabs[key].active) activeTab=newTabs[key].name
        });
        return activeTab
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            this.setState({id:this.props.id});
            this.setState({tmp:this.props.tmp});
            this.setState({show:this.props.show});
            this.setState({tabs:this.getTabs()});




            if (this.getActiveTab()===null) {

                this.enableTab('production');
            }


        }


        if (prevProps.show===true && this.props.show===false) {
            this.setState({tabs:this.getTabs()});
        }

    }

    enableTab(tabName: string) {
        const { tabs } = this.state;

        if (tabs[tabName] === undefined) {
            return true;
        }

        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
        });

        newTabs[tabName].active = true;
        this.setState({ tabs: newTabs });
    }


    shouldComponentUpdate( nextProps, nextState ){

        if (this.props.show===true && nextProps.show===false) {
            console.log('closing');
            const { tabs } = this.state;
            const keys = Object.keys(tabs);
            let newTabs = tabs;

            keys.forEach((key) => {
                newTabs[key].active = false;
                newTabs[key].name = tabs[key].name;
            });
        }



        if (
            (nextProps.show===true && this.props.show===false) ||
            (nextProps.show===true && this.props.show===true)
        ) {


            console.log('upd');

            return true
        } else {
            return true
        }
    }

    renderTabContent() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        return keys.map((tabName, index) => {
            const Component = tabs[tabName].component;


            return (
                <div
                    className={`tab-pane ${tabs[tabName].active ? "active" : "inactive"}`}
                    id={tabName}
                    key={index}
                >
                    <Component type={this.props.type} fromtab={true} onAdd={this.props.onAdd} rownode={this.props.rownode} id={this.props.id} show={this.state.show} tmp={Date.now()} />
                </div>
            );
        });
    }

    render() {

        const { oldtabs } = this.state;
        var tabs=this.getTabs();
        var activetab=this.getActiveTab();

        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
            newTabs[key].component = tabs[key].component;
            if (tabs[key].name===activetab)  newTabs[key].active = true;
        });

        tabs=newTabs;

        return (

                <div className="card" style={{'width':'100%'}}>
                    <div className="card-header p-2">
                        <TabPill
                            tabs={tabs}
                            handleEnableTab={(tabName) => this.enableTab(tabName)}
                        />
                    </div>

                    <div className="card-body">
                        <div className="tab-content">{this.renderTabContent()}</div>
                    </div>
                </div>


        );
    }
}
ManufacturingCard.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ManufacturingCard);
