import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class SellRenderer extends Component {



    handleClick(event) {

    this.props.openSell(this.props.node.data.product_type);
}

    handleGalv(event) {

            this.props.sendToGalv(this.props.node.data.product_type);


    }

    handleMinus(event) {
        let qty = prompt(this.props.t('Please enter an amount'));
        let text;
        if (qty == null || qty == "") {

        } else {
            this.props.handleMinus(this.props.node.data.product_type, qty);
        }

    }


    render() {

var product_types_forsale=[];
var product_types_galv=[];

JSON.parse(localStorage.getItem('product_types')).map((e)=>{
    if (e.product_type!=1) product_types_forsale.push(e.id);
    if (e.product_type==2) product_types_galv.push(e.id);
});
        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });


            return (
<>
{this.props.node.data.id<1000000 &&
    <>
            {product_types_forsale.includes(this.props.node.data.product_type) &&
            <button
                className="btn btn-default btn-sm edit-ingrid-but"
                onClick={(event) => {
                    this.handleClick(event);
                    //  this.props.open_options(this.props.id);
                }}
            ><i className="fa fa-shopping-cart"></i>
            </button>
        }
            {product_types_galv.includes(this.props.node.data.product_type) &&
            <button
                className="btn btn-default btn-sm edit-ingrid-but"
                onClick={(event) => {
                    this.handleGalv(event);
                    //  this.props.open_options(this.props.id);
                }}
            ><i className="fa fa-industry"></i>
            </button>
            }
            {(roles.includes('Admin') || roles.includes('Administrativa')) &&
            <button
                className="btn btn-default btn-sm edit-ingrid-but"
                onClick={(event) => {
                    this.handleMinus(event);
                    //  this.props.open_options(this.props.id);
                }}
            ><i className="fa fa-minus-circle"></i>
            </button>
            }
            </>
}

</>
            );

    }
}

SellRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SellRenderer);