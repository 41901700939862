import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";



export default forwardRef((props, ref) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const [textinput, setTextinput] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsArray, setOptionsArray] = useState([]);

    //var optionsArray = [];

    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
/*
    var db = openDatabase('gtsdb', '1.0', 'gtsdb', 500 * 1024 * 1024);
    function webSQLQuery(query) {
        return new Promise(resolve => {
            db.transaction(function(tx) {
                tx.executeSql(query, [], function(tx, result) {
                    resolve(result.rows);
                }, null);
            });
        });
    }
*/


    function  citiesByCountriesStates(countries, states) {
        return new Promise(resolve => {
            HttpService.post(UrlService.apiDomain() + 'api/citiesByCountriesStates', {'countries':countries, 'states':states})
                .then(res => {
                    resolve(res.data);

                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    function zipsByCountriesStatesCities(countries, states, cities) {
    return new Promise(resolve => {
        HttpService.post(UrlService.apiDomain() + 'api/zipsByCountriesStatesCities', {'countries':countries, 'states':states, 'cities':cities})
            .then(res => {
                resolve(res.data);

            })
            .catch((error) => {
                console.log(error);
            });
    });
}

    function statesByCountries (countries) {
        return new Promise(resolve => {
            HttpService.post(UrlService.apiDomain() + 'api/statesByCountries', {'opts':countries})
                .then(res => {
                    resolve(res.data);

                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    async function getOptions() {

        var none='-- Žádný --';
        if (locale==='en') none='-- None --';

        var err_select='';
        var table='';
        var col='';
        var column='';
        var countries=[];
        var states=[];
        var cities=[];

        if (props.gridType === 'companies') {
            // Multi selects for companies table
            table = 'companies';
            col = props.column.colId;
            column = '';
            JSON.parse(localStorage.getItem('columns')).map((e) => {
                if (e.field === col && e.model===table) {
                    column = e;
                }
                return true;
            });

            var values = [];

            // (checking if user has options allowed)
            var allowed_options = [];
            JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                if (e.name === table + '/columns-' + col) {
                    allowed_options = e.value.split('//');
                }
                return true;
            });


            if (column.data_array === 'custom') {
                if (column.data !== '' && column.data !== null) {
                    values = JSON.parse(column.data);
                }
            }
            else if (column.data_array === 'zipcodes' || column.data_array === 'cities' || column.data_array === 'states' || column.data_array === 'countries') {


                // if selecting state
                if (column.data_array === 'states') {

                    if (props.data===null || props.data['country'] === '' || props.data['country'] === null) {
                        err_select='Nejdříve vyberte zemi';
                        if (locale==='en') err_select='Choose the country first';
                        optionsArray.push({
                            value: '',
                            label: err_select
                        });
                    } else {

                        countries =[props.data['country']];
                        const rows = await statesByCountries(countries);

                        optionsArray.push({
                            value: '',
                            label: none,
                        });

                        rows.map((r)=>{
                            if (allowed_options.includes(r.state) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                                optionsArray.push({
                                    value: r.state,
                                    label: r.state
                                });
                            }
                            return true;
                        });
                        optionsArray.length===1 ? setTextinput(true) :  setTextinput(false);


                    }

                }

                // if selecting city
                else if (column.data_array === 'cities') {


                    if (props.data===null || props.data['country'] === '' || props.data['country'] === null) {


                        err_select='Nejdříve vyberte zemi';
                        if (locale==='en') err_select='Choose the country first';
                        optionsArray.push({
                            value: '',
                            label: err_select
                        });
                    } else {
                        countries =[props.data['country']];
                        states =[props.data['state']];
                        const rows = await citiesByCountriesStates(countries, states);

                        optionsArray.push({
                            value: '',
                            label: none,
                        });

                        rows.map((r)=>{
                            if (allowed_options.includes(r.city) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                                optionsArray.push({
                                    value: r.city,
                                    label: r.city
                                });
                            }
                            return true;
                        });
                        optionsArray.length===1 ? setTextinput(true) :  setTextinput(false);

                    }

                }


                // if selecting zip code
                else if (column.data_array === 'zipcodes') {


                    if (props.data===null || props.data['country'] === '' ||  props.data['country'] === null) {

                         err_select='Nejdříve vyberte zemi';
                        if (locale==='en') err_select='Choose the country first';
                        optionsArray.push({
                            value: '',
                            label: err_select
                        });
                    } else {

                        countries =[props.data['country']];
                        states =[props.data['state']];
                        cities =[props.data['city']];
                        const rows = await zipsByCountriesStatesCities(countries, states, cities);

                        optionsArray.push({
                            value: '',
                            label: none,
                        });

                        rows.map((r)=>{
                            if (allowed_options.includes(r.zip) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                                optionsArray.push({
                                    value: r.zip,
                                    label: r.zip
                                });
                            }
                            return true;
                        });
                        optionsArray.length===1 ? setTextinput(true) :  setTextinput(false);

                    }

                }




            }

            else if (column.data_array !== '' && column.data_array !== null && column.data_array !== 'none') {
                values = JSON.parse(localStorage.getItem(column.data_array));
            }



            values.map((e) => {
                if (allowed_options.includes(e.id.toString()) || allowed_options.length === 0 || (allowed_options.length === 1 && allowed_options[0] === '')) {
                    optionsArray.push({value: e.id, label: e['name_' + locale]});
                }
                return true;
            });

        }
        else {
            // All other multiseletc (roles, users, etc.)

            if (props.column.colId.indexOf('/') !== -1) {


                // permissions for exact data in columns
                table = props.column.colId.split('/')[0];
                col = props.column.colId.split('-')[1];
                column = '';
                JSON.parse(localStorage.getItem('columns')).map((e) => {
                    if (e.field === col && e.model===table) {
                        column = e;
                    }
                    return true;
                });

                if (column.column_type === 'boolean') {
                    // Yes-no column

                    JSON.parse(localStorage.getItem('booleans')).map((e) => {
                        optionsArray.push({value: e.id, label: e['name_' + locale]});
                        return true;
                    });

                } else {
                    // checking data array
                    if (column.data_array === '' || column.data_array === 'none' || column.data_array === null) {
                        // any text column
                        JSON.parse(localStorage.getItem('columns_permissions'))[table][col].map((e) => {
                            optionsArray.push({value: e, label: e});
                            return true;
                        });

                    } else {
                        if (column.data_array === 'custom') {
                            // custom options column
                            if (column.data !== null && column.data !== '') {
                                JSON.parse(column.data).map((e) => {
                                    optionsArray.push({value: e.id, label: e['name_' + locale]});
                                    return true;
                                });
                            }
                        } else {

                            // if selecting city
                            if (props.column.colId.indexOf('columns-city') !== -1) {


                                if (props.data[table + '/columns-country'] === '') {

                                    err_select='Nejdříve vyberte zemi';
                                    if (locale==='en') err_select='Choose the country first';
                                    optionsArray.push({
                                        value: '',
                                        label: err_select
                                    });
                                } else {

                                    countries = props.data[table + '/columns-country'].split('//');
                                    states = props.data[table + '/columns-state'].split('//');
                                    const rows = await citiesByCountriesStates(countries, states);

                                    rows.map((r)=>{
                                        optionsArray.push({
                                            value: r.city,
                                            label: r.city
                                        });
                                        return true;
                                    });

                                }

                            }


                            // if selecting zip code
                            else if (props.column.colId.indexOf('columns-zip') !== -1) {


                                if (props.data[table + '/columns-country'] === '') {

                                     err_select='Nejdříve vyberte zemi';
                                    if (locale==='en') err_select='Choose the country first';
                                    optionsArray.push({
                                        value: '',
                                        label: err_select
                                    });
                                } else {

                                    countries = props.data[table + '/columns-country'].split('//');
                                    states = props.data[table + '/columns-state'].split('//');
                                    cities = props.data[table + '/columns-city'].split('//');
                                    const rows = await zipsByCountriesStatesCities(countries, states, cities);

                                    rows.map((r)=>{
                                        optionsArray.push({
                                            value: r.zip,
                                            label: r.zip
                                        });
                                        return true;
                                    });

                                }

                            }


                            else {

                                JSON.parse(localStorage.getItem(column.data_array)).map((e) => {
                                    optionsArray.push({value: e.id, label: e['name_' + locale]});
                                    return true;
                                });
                            }
                        }
                    }

                }


            } else if (props.column.colId.indexOf('-') !== -1) {
                // visible/editable permissions
                table = props.column.colId.split('-')[0];
                JSON.parse(localStorage.getItem('columns')).map((e) => {
                    if (e.model===table) optionsArray.push({value: e.field, label: e['name_' + locale]});
                    return true;
                });
            }

            else {
                JSON.parse(localStorage.getItem(props.column.colId + 's')).map((e) => {
                    optionsArray.push({value: e.name, label: e.name});
                    return true;
                });
            }

        }

        var clean_options = [];
        optionsArray.map((e) => {
            if (e.label !== '' && e.label !== null) {
                clean_options.push(e);
            }
            return true;
        });

        setOptionsArray(clean_options);

        try {

            optionsArray.sort((a, b) => a.label.localeCompare(b.label));
        } catch (err) {
            console.log(err);
        }

        var no_options='Nic k výběru';
        if (locale==='en') no_options='No data to choose from';

        if (optionsArray.length===0)  optionsArray.push({value:'', label: no_options});

        setOptionsArray(optionsArray);


    }


    function onChange(event ) {

if (!textinput) {
    var result = [];
    var options = event.target.options;
    var opt;

    for (var i = 0, iLen = options.length; i < iLen; i++) {
        opt = options[i];

        if (opt.selected) {
            result.push(opt.value);
        }
    }


    setSelectedOptions(result);
    setValue(result.join('//'));
} else {
    setValue(event.target.value);
}



    }



    function getVal(e) {
        var set_opts=[];

        if (props.value!=='' && props.value!==null) {


            props.value.split('//').map(e=>set_opts.push(e));
         setSelectedOptions(set_opts);
console.log(selectedOptions);
console.log(set_opts);
        }

    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {

                return value;
            },
            afterGuiAttached: async() => {

                setValue(props.value);
                await getOptions();
                getVal();



                 inputRef.current.focus();
              //   inputRef.current.select();


            },

            isPopup: () => {
                return true;
            }
        };
    });

    function MakeItem (X) {


       return <option key={X.value} value={X.value}>{X.label}</option>;
    };

    var height='300px';
    if (props.multiple===false) height='32px';

    return (

       textinput ? <input type="text" ref={inputRef} value={props.multiple ? selectedOptions : selectedOptions[0]} onChange={onChange} />:
    <select style={{height: height}}
            ref={inputRef}
            onChange={onChange}
            value={props.multiple ? selectedOptions : selectedOptions[0]}

            multiple={props.multiple}
    >{optionsArray.map(MakeItem)}</select>





    );


})
