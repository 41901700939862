import { ColDef } from "ag-grid-community";


import InputIntegerParser from "../../inputs/Integer/InputIntegerParser";
import InputIntegerSerializer from "../../inputs/Integer/InputIntegerSerializer";

import Input from "../../inputs/Input";


const ColumnSingleInteger = class ColumnSingleInteger implements ColDef {

	headerName = null;
		
	field = null;
		
	options = null;
		
	editable = false;
		
	filter = "agNumberColumnFilter";
			
	cellEditor = 'ComponentInteger';
	
	cellEditorPopup = true;
		
	cellEditorPopupPosition = 'under';
	

	constructor(
		Name,
		Field,
		Placeholder,
		Editable,
		RegExpMatch,
		RegExpValidation,
		ModelSchema,
		Meta,
		Values
	){
		
		this.headerName = Name;
		
		this.field = Field;
		
		this.placeholder = Placeholder;
		
		this.editable = Editable;
		
		
		this.cellEditorParams = {
			field: this.field,
			disabled: !this.editable,
			placeholder: this.placeholder,
			regexp_match: RegExpMatch,
			regexp_validation: RegExpValidation
		};
		
		this.valueGetter = function( params ) {
		
			console.log( "valueGetter", params );
			
			return InputIntegerParser( params.data[ this.field ] );
			
		}.bind( this );
			
		this.valueFormatter = function( params ){
			
			console.log( "valueFormatter", params );
			
			
			let Data = InputIntegerParser( params.data[ this.field ] );
			
			let Str = null;
			
			if( Data === null ){
				
				Str = "";
				
			} else {
				
				Str = Data;
				
			}
			
			return Str;
			
		}.bind( this );
			
		this.valueParser = function( params ){
				
			console.log( "valueParser", params );
				
		}.bind( this );
			
		this.valueSetter = function( params ){
			
			console.log( "valueSetter", params );
			
			
			let IsValid = Input.Check( Field, Meta, params.newValue, ModelSchema, params.data );
			
			
			if( IsValid === false ){
				
				params.data[ this.field ] = InputIntegerSerializer( params.oldValue );
				
			} else {
				
				params.data[ this.field ] = InputIntegerSerializer( params.newValue );
				
			}
			
			
			return IsValid;
			
		}.bind( this );
			
	}
	
};


export default ColumnSingleInteger;