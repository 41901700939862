import React, {Component} from "react";
import RightDropdown from "./rightdropdown";
import {Link} from "react-router-dom";
import $ from "jquery";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";
import Select from 'react-select'


interface Props {
    onUpdateYear: (data) => void;
	onUpdateMonth: (data) => void;
	onUpdateContinent: (data) => void;
	onUpdateCountry: (data) => void;
};


class TopNav extends Component< Props > {

	#ContinentValue = null;
	
	#CountryValue = null;
	
	#YearValue = null;
	
	#MonthValue = null;

	#NeedLocationFilter = false;

	constructor( props ) {
    
		super( props )


		let locale = 'cs';
		
		if( localStorage.getItem( 'locale' ) ) {
		
			locale = localStorage.getItem( 'locale' );
			
		}
		

		let continents = [ ];
		
		if( localStorage.getItem('continents') ) {
			
			continents = JSON.parse( localStorage.getItem('continents') );

		}
		
		
		let countries = [ ];
		
		if( localStorage.getItem('countries') ){
			
			countries = JSON.parse( localStorage.getItem('countries') );

		}
		
		
		
		let filterYearOptions = [

			{value: null, label: this.props.t('Year')},
			{value: "2020", label: '2020'},
			{value: "2021", label: '2021'},
			{value: "2022", label: '2022'},
			{value: "2023", label: '2023'},
			{value: "2024", label: '2024'},
			{value: "2025", label: '2025'},
			{value: "2026", label: '2026'},
			{value: "2027", label: '2027'},
			{value: "2028", label: '2028'},
			{value: "2029", label: '2029'},
			{value: "2030", label: '2030'}

		];
		
		let filterMonthOptions = [

			{value: null, label: this.props.t('Month')},
			{value: "01", label: '01'},
			{value: "02", label: '02'},
			{value: "03", label: '03'},
			{value: "04", label: '04'},
			{value: "05", label: '05'},
			{value: "06", label: '06'},
			{value: "07", label: '07'},
			{value: "08", label: '08'},
			{value: "09", label: '09'},
			{value: "10", label: '10'},
			{value: "11", label: '11'},
			{value: "12", label: '12'}

		];
		
		let roles = [ ];
		
		JSON.parse( localStorage.getItem( 'my_user' ) ).roles.forEach( ( r ) => {
			roles.push( r.name );
		} );
		
		let permissions = JSON.parse( localStorage.getItem( 'my_permissions' ) );
		
		
		
		
		let my_meta = JSON.parse( localStorage.my_users_meta );
		
		
		
		let model = window.location.pathname.replace( "/", "" );
		
		
		console.log( model );
		
		
		let Continents = [ ];
		
		for( 
			let I = 0;
			I < my_meta.length;
			I++
		){
		
			if( my_meta[ I ].name.indexOf( model + "/columns-continent" ) !== -1 ){
			
				if( my_meta[ I ].value !== '' ){
				
					Continents = my_meta[ I ].value.split( "//" );
				
				}
				
				break;
				
			}
		
		}
		
		console.log( Continents );
		
		
		let Countries = [ ];
		
		for( 
			let I = 0;
			I < my_meta.length;
			I++
		){
		
			if( my_meta[ I ].name.indexOf( model + "/columns-country" ) !== -1 ){
			
				if( my_meta[ I ].value !== '' ){
				
					Countries = my_meta[ I ].value.split( "//" );
				
				}
				
				break;
				
			}
		
		}
		
		console.log( Countries );
		
		if( Countries.length > 0 ){
		
			this.#NeedLocationFilter = true;
		
		}
		
		let continent_options = [ ];
		
		continents.map( ( e ) => {
		
			if( Continents.includes( e.id.toString( ) ) === true ){
          
				continent_options.push( e );
				
			}
			
        } );
		
		let country_options = [ ];
		
		countries.map( ( e ) => {
		
			console.log( e, Countries );
          
			if( Countries.includes( e.id.toString( ) ) === true ){
		  
				country_options.push( e );
				
			}
			
        } );
		
		console.log( continent_options );
		
		console.log( country_options );
		
		
		this.#ContinentValue = null;
		
		if( localStorage.getItem( 'filter_global_continent' ) ){
			
			this.#ContinentValue = JSON.parse( localStorage.getItem( 'filter_global_continent' ) );

		} else {
		
			this.#ContinentValue = { value: "", label: this.props.t( 'Continent' ) };
		
			localStorage.setItem( 'filter_global_continent', JSON.stringify( this.#ContinentValue ) );
		
		}
		
		this.#CountryValue = null;
		
		if( localStorage.getItem( 'filter_global_country' ) ){
			
			this.#CountryValue = JSON.parse( localStorage.getItem( 'filter_global_country' ) );

		} else {
		
			this.#CountryValue = { value: "", label: this.props.t( 'Country' ) };
		
			localStorage.setItem( 'filter_global_country', JSON.stringify( this.#CountryValue ) );
		
		}
		
		
		this.#YearValue = null;
		
		if( localStorage.getItem( 'filter_global_year' ) ){
			
			this.#YearValue = JSON.parse( localStorage.getItem( 'filter_global_year' ) );

		} else {
		
			let Y = ( new Date( ) ).getFullYear( ).toString( );
		
			this.#YearValue = { value: Y, label: Y };
		
			localStorage.setItem( 'filter_global_year', JSON.stringify( this.#YearValue ) );
		
		}
		
		
		this.#MonthValue = null;
		
		if( localStorage.getItem( 'filter_global_month' ) ){
			
			this.#MonthValue = JSON.parse( localStorage.getItem( 'filter_global_month' ) );

		} else {
		
			this.#MonthValue = { value: null, label: this.props.t( 'Month' ) };
		
			localStorage.setItem( 'filter_global_month', JSON.stringify( this.#MonthValue ) );
		
		}
		
		
		this.state = {
			locale: locale,
			continents: continents,
			countries: countries,
			continent_options: continent_options,
			country_options: country_options,
			Countries: Countries,
			Continents: Continents,
			filterYearOptions: filterYearOptions,
			filterMonthOptions: filterMonthOptions,
			filterYear: this.#YearValue,
			filterMonth: this.#MonthValue,
			filterContinent: this.#ContinentValue,
			filterCountry: this.#CountryValue,
			
		  find_company: '',
		  find_city: '',
		  showSpinner: 'none',
		  add_disabled: true,
		  found_company: '',
		  
			roles: roles,
			permissions: permissions
		};
	
		this.Year = null;
		this.Month = null;
		this.Continent = null;
		this.Country = null;
	
	}

	componentDidMount( ) {

	}
  
	MakeOption( X ) {
		
		return { value: X.id, label: X[ 'name_' + this.state.locale ] };
		
	}

  find_companies(city, company) {
    this.setState({
      showSpinner: 'block',
      find_company: company,
      find_city: city,
      add_disabled: true,
      found_company: '',
      found_company_all: ''
    });

    if (company == '') company = 'prazdna_firma1';
    if (city == '') city = 'prazdna_mesto1';

    HttpService.get(UrlService.apiDomain() + 'api/find_company/' + company + '/' + city)
      .then(res => {
        this.setState({showSpinner: 'none', add_disabled: false, found_company: res.data.found, found_company_all: res.data.found_all});
      })
      .catch((error) => {
        console.log(error);
      });


  }

	
	setYear( e ){
		
		this.Year = e.value;
		
		this.#YearValue = e;
		
		localStorage.setItem( 'filter_global_year', JSON.stringify( this.#YearValue ) );
		
		this.setState( { filterYear: e } );
		
		this.props.onUpdateYear({ 
			Year: this.Year
		});
		
	}
	
	setMonth( e ){
		
		this.Month = e.value;
		
		this.#MonthValue = e;
		
		localStorage.setItem( 'filter_global_month', JSON.stringify( this.#MonthValue ) );
		
		this.setState( { filterMonth: e } );
		
		this.props.onUpdateMonth({ 
			Month: this.Month
		} );
		
	}
	
	setContinent( e ){
	
		console.log( e, this.state.countries, this.state.Countries );
	
		this.Continent = e.value;
		
		let OptionsArray = [ ];
		
		for( let i in this.state.countries ){
		
			if( this.state.countries[ i ].continent == e.value ){
			
				if( this.state.Countries.includes( this.state.countries[ i ].id.toString( ) ) ){
				
					OptionsArray.push( this.state.countries[ i ] );
				
				}
				
			}
		
		}
		
		this.#ContinentValue = e;
		
		localStorage.setItem( 'filter_global_continent', JSON.stringify( this.#ContinentValue ) );
		
		this.setState( { 
			filterContinent: e,
			filterCountry: null,
			country_options: OptionsArray 
		} );
		
		this.props.onUpdateContinent({ 
			Continent: this.Continent
		} );
	
	}
	
	setCountry( e ){
	
		this.Country = e.value;
		
		this.#CountryValue = e;
		
		localStorage.setItem( 'filter_global_country', JSON.stringify( this.#CountryValue ) );
		
		this.setState( { filterCountry: e } );
		
		this.props.onUpdateCountry({ 
			Country: this.Country
		} );
	
	}

	render( ) {
	
		const {find_company, find_city} = this.state;

		return (
			<nav className="main-header navbar navbar-expand navbar-white navbar-light">
			
				<ul className="navbar-nav">
				
					<li className="nav-item">
						<a
							className="nav-link"
							data-widget="push-menu"
							href="#"
							role="button"
							data-toggle="push-menu"
							onClick={(event) => {
								event.preventDefault();

								if ($(".content-wrapper").css('margin-left') === '0px') {
								
									if ($(".main-sidebar").css('margin-left') === '-250px') $(".main-sidebar").css('margin-left', '0px');
									$(".main-sidebar").show("slide");
									$(".content-wrapper, .main-header").css('margin-left', '250px');
									
								} else {

									$(".main-sidebar").hide("slide");
									$(".content-wrapper, .main-header").css('margin-left', '0px');
									
								}

							}}
						>
							<i className="fas fa-bars"></i>
						</a>
					</li>
					
					{ this.#NeedLocationFilter && 
					
					<li>
						<Select name="filterContinent"

							onChange={
								(event) => {
									this.setContinent( event );
								}
							}
							
							value={this.state.filterContinent}

							placeholder={this.props.t('Choose continent')}
							classNamePrefix="select"
							options={ this.state.continent_options.map( this.MakeOption.bind( this ) ) }
						/>
					</li>
					
					}
					
					{ this.#NeedLocationFilter &&

					<li style={{marginRight: "5px"}}>
						<Select name="filterCountry"

							onChange={
								(event) => {
									this.setCountry( event );
								}
							}
							
							value={this.state.filterCountry}
							
							disabled={this.state.filterContinent.value == null ? true : false}
							placeholder={this.props.t('Choose country')}
							classNamePrefix="select"
							options={ this.state.country_options.map( this.MakeOption.bind( this ) ) }
						/>
					</li>
					
					}
					
					{ this.#NeedLocationFilter &&
					
					<li>
						<button type="button"
							style={{marginRight: '10px', height: '38px'}}
							className="btn btn-secondary btn-sm"
							onClick={() => {
							
								this.#ContinentValue = { value: null, label: this.props.t('Continent') };
								
								this.#CountryValue = { value: null, label: this.props.t('Country') };
								
								localStorage.setItem( 'filter_global_continent', JSON.stringify( this.#ContinentValue ) );
								
								localStorage.setItem( 'filter_global_country', JSON.stringify( this.#CountryValue ) );
							
								this.setState( {
									filterContinent: { value: null, label: this.props.t('Continent') },
									filterCountry: { value: null, label: this.props.t('Country') }
								} );
								
								this.props.onUpdateCountry({ 
									Country: null
								} );
								
								this.props.onUpdateContinent({ 
									Continent: null
								} );
								
							}}
						>
							<span aria-hidden="true" style={{fontSize:'18px'}}>&times;</span>
						</button>
					</li>
					
					}
		  
					{ this.state.permissions.includes('companies.create') &&
					<li>
						<input
							type="text"
							className="form-control"
							value={find_city}
							placeholder={this.props.t("Find the city")}
							onChange={
								(event) => {
									this.find_companies(event.target.value, find_company);
								}
							}
						/>
					</li>
					}
		  
					{ this.state.permissions.includes('companies.create') &&
					<li>
						<input
							type="text"
							className="form-control"
							value={find_company}
							placeholder={this.props.t("Find a company")}
							onChange={
								(event) => {
									this.find_companies(find_city, event.target.value);
								}
							}
						/>
					</li>
					}
		  
					{ this.state.permissions.includes('companies.create') &&
					<li>
						<a type="button"
							style={{marginLeft: '10px'}}
							disabled={this.state.add_disabled}
							className="btn btn-success btn-sm"
							href={"/companies/add/" + find_company + "/" + find_city}
						>
							<i className="fa fa-plus"></i>
						</a>
					</li>
					}

					{ this.state.permissions.includes('companies.create') &&
					<li>
						<div style={{display: this.state.showSpinner, marginLeft: '10px'}}>
							<i className="fas fa-spinner fa-spin"></i>
						</div>

					</li>
					}

					{ this.state.permissions.includes('companies.create') &&
					<li>
						<div style={{marginLeft: '10px'}}>
							<a 
								type="button" 
								onClick={
									() => {
										alert(this.state.found_company_all);
									}
								}
							>
								{this.state.found_company}
							</a>
						</div>
					</li>
					}

					{( !this.state.roles.includes( 'Spedice' ) && !this.state.roles.includes( 'Vyroba' ) && !this.state.roles.includes( 'Galvanovna' ) ) &&
					<>
						<li className="nav-item d-none d-sm-inline-block">
							<Link to="home" className="nav-link">
								{this.props.t('Home')}
							</Link>
						</li>
						<li className="nav-item d-none d-sm-inline-block">
							<a href="active_companies" className="nav-link">
								{this.props.t('active_companies')}
							</a>
						</li>
					</>
					}
				</ul>

				<ul className="navbar-nav" style={{"marginLeft":'auto', "marginRight": '0' }}>
				
					<li className="nav-item">
						<Select name="filterYear"

							onChange={
								( event ) => {
									this.setYear( event );
								}
							}
							
							value={ this.state.filterYear }

							placeholder={ this.props.t('Year') }
							classNamePrefix="select"
							options={ this.state.filterYearOptions }
						/>
					</li>
					
					<li className="nav-item">
						<Select name="filterMonth"

							onChange={
								( event ) => {
									this.setMonth( event );
								}
							}
							
							value={ this.state.filterMonth }

							placeholder={ this.props.t('Month') }
							classNamePrefix="select"
							options={ this.state.filterMonthOptions }
						/>
					</li>
				</ul>

				<RightDropdown/>
		
			</nav>
		);
	
	}
	
};

TopNav.propTypes = {
	t: PropTypes.func.isRequired,
};

export default translate( )( TopNav );
