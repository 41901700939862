import React, { Component } from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import SupplyList from "../../components/supplylists";



import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';


class SupplyListPage extends Component {


    render() {


        return (
            <div className="wrapper">

                <Layout>
                    <Content hideTitle={true} title="Invoices">

                        <SupplyList id="0" opts={this.props.opts} />
                    </Content>
                </Layout>
            </div>
        );
    }
}

SupplyListPage.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SupplyListPage);

