import React from "react";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ContactAdd from "./contact_add.tsx";
import RadioRenderer from "../../../aggrid/RadioRenderer.jsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import Modal from "../modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
class Contact  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            id:null,
            showContactsModal:false,

            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Name'),
                    field: 'contact_person',
                    editable:true,

                },
                {
                    headerName: this.props.t('Position'),
                    field: 'contact_person_position',
                    editable:true
                },
                {
                    headerName: this.props.t('Phone'),
                    field: 'phone',
                    editable:true
                },

                {
                    headerName: this.props.t('Email'),
                    field: 'email',
                    editable:true
                },
                {
                    headerName: this.props.t('Main'),
                    field: 'main_contact',
                    cellRenderer: "RadioRenderer",
                    suppressMenu: true,
                },

                {
                    headerName: this.props.t('Note'),
                    field: 'contact_person_note',
                    editable:true
                },



            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,

                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: true,
            },
            frameworkComponents: {
                RadioRenderer: RadioRenderer,
                DeleteRenderer: DeleteRenderer,
            }
        };
    }


    shouldComponentUpdate( nextProps, nextState ){

        if (this.props.showContact===true && nextProps.showContact===false) {
            // cleaning aggrid
            this.setState({
                rowdata: []
            })
        }

        if (
            (nextProps.showContact===true && this.props.showContact===false) ||
            (nextProps.showContact===true && this.props.showContact===true)
        ) {

            return true
        } else {
            return false
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.id!==null)  {

                HttpService.get(UrlService.apiDomain() + 'api/contacts/companies/' + this.props.id)
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        });
                        this.gridApi.redrawRows();
                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('companies.delete')) {
            let items = this.gridApi.getColumnDefs();
            items[6] = {
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",

                cellRendererParams: {
                    gridType: "contacts",
                },
                suppressMenu: true,
            };


            // this.setState({items});
            this.gridApi.setColumnDefs([]);
            this.gridApi.setColumnDefs(items);
        }
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#contactsGrid').style.height = '';
    };

    async handleAdd(data) {

        HttpService.post(UrlService.apiDomain()+'api/contacts/companies/'+this.props.id, data)
            .then(res => {

                if (res.data.result==='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showContactsModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.props.rownode.setData(res.data.company);


                    // setting data for company card
                    var data;
                    if (this.props.contact_person_row) {
                        data = this.props.contact_person_row.data;
                        data.value = res.data.company.contact_person;
                        this.props.contact_person_row.setData(data);
                    }

                    if (this.props.contact_person_position_row) {
                        data = this.props.contact_person_position_row.data;
                        data.value = res.data.company.contact_person_position;
                        this.props.contact_person_position_row.setData(data);
                    }

                    if (this.props.phone_row) {
                        data = this.props.phone_row.data;
                        data.value = res.data.company.phone;
                        this.props.phone_row.setData(data);
                    }

                    if (this.props.email_row) {
                        data = this.props.email_row.data;
                        data.value = res.data.company.email;
                        this.props.email_row.setData(data);
                    }

                    if (this.props.contact_person_note_row) {
                        data = this.props.contact_person_note_row.data;
                        data.value = res.data.company.contact_person_note;
                        this.props.contact_person_note_row.setData(data);
                    }




                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                }

            })
            .catch((error) => {
                console.log(error);
            });


    }

    onCellCValueChanged = (event) => {
//console.log(event);
        HttpService.put(UrlService.apiDomain()+'api/contacts/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                this.props.rownode.setData(res.data.company);


                // setting data for company card
                if (typeof this.props.contact_person_row!=='undefined') {
                    var data;
                    data = this.props.contact_person_row.data;
                    data.value = res.data.company.contact_person;
                    this.props.contact_person_row.setData(data);

                    data = this.props.contact_person_position_row.data;
                    data.value = res.data.company.contact_person_position;
                    this.props.contact_person_position_row.setData(data);

                    data = this.props.phone_row.data;
                    data.value = res.data.company.phone;
                    this.props.phone_row.setData(data);

                    data = this.props.email_row.data;
                    data.value = res.data.company.email;
                    this.props.email_row.setData(data);

                    data = this.props.contact_person_note_row.data;
                    data.value = res.data.company.contact_person_note;
                    this.props.contact_person_note_row.setData(data);
                }


            })
            .catch((error) => {
                console.log(error);
            })


    }

    render() {


        return (


            <div key={this.props.id} style={{ 'height': '100%', width:'100%' }} id="contactsGrid" className="ag-theme-alpine">
                <Modal
                    title={this.props.t('Add new')}
                    visibility={this.state.showContactsModal}
                    size="lg"
                    onClose={() => this.setState({ showContactsModal: !this.state.showContactsModal })}
                >
                    <ContactAdd id={Date.now()} onAdd={(data) => this.handleAdd(data)} />
                </Modal>
                <div className="tool-buttons">
                    <button
                        className="btn btn-success btn-sm"
                        onClick={() => this.setState({ showContactsModal: true })}
                    >
                        {this.props.t('Add new')} <i className="fa fa-plus"></i>
                    </button>
                </div>
                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}
                />
            </div>


        );
    }
}

Contact.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Contact);

