"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentButtonConfigT from "../../../../Type/UI/Component/Button/UIComponentButtonConfigT.mjs";


const UIComponentButtonT = class UIComponentButtonT extends UIComponentBaseT {

	#Active = false;

	constructor(
		UIElement,
		UIComponentButtonConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentButtonConfig instanceof UIComponentButtonConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentButtonConfig
		);
		
		
		this.ActiveSet( UIComponentButtonConfig.ActiveGet( ) );
	
	}
	
	ActiveSet(
		Active
	){
		
		if( typeof( Active ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
			
		this.#Active = Active;
		
		
		let UIComponentButtonConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentButtonConfig.StateMapGet( );
		
		
		if( this.#Active === true ){
		
			if( UIStateMap.Exists( "Active" ) === true ){
			
				let UIState = UIStateMap.Get( "Active" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
		
		} else {
			
			if( UIStateMap.Exists( "Inactive" ) === true ){
			
				let UIState = UIStateMap.Get( "Inactive" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
		
		//super.Update( );
		
	}
	
	ActiveGet(
	
	){
	
		return this.#Active;
	
	}
	
	#OnMouseDown( 
		Ev 
	){
		
		if( this.#Active === false ){
			
			return;
			
		}
		
		
		let UIComponentButtonConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentButtonConfig.StateMapGet( );
		
		
		if( UIStateMap.Exists( "Pressed" ) === true ){
		
			let UIState = UIStateMap.Get( "Pressed" );
			
			super.ClassNameSet( UIState.ClassNameGet( ) );
			
			super.StyleMapSet( UIState.StyleMapGet( ) );
			
			//super.Update( );
		
		}
		
	}
	
	#OnMouseUp( 
		Ev 
	){
		
		if( this.#Active === false ){
			
			return;
			
		}
		
		
		let UIComponentButtonConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentButtonConfig.StateMapGet( );
		
		
		if( UIStateMap.Exists( "Active" ) === true ){
		
			let UIState = UIStateMap.Get( "Active" );
			
			super.ClassNameSet( UIState.ClassNameGet( ) );
			
			super.StyleMapSet( UIState.StyleMapGet( ) );
			
			//super.Update( );
		
		}
		
	}
	
	#OnMouseEnter(
		Ev
	){
		
		if( this.#Active === false ){
			
			return;
			
		}
		
		
		let UIComponentButtonConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentButtonConfig.StateMapGet( );
		
		
		if( UIStateMap.Exists( "Hovered" ) === true ){
		
			let UIState = UIStateMap.Get( "Hovered" );
			
			super.ClassNameSet( UIState.ClassNameGet( ) );
			
			super.StyleMapSet( UIState.StyleMapGet( ) );
			
			//super.Update( );
		
		}
		
	}
	
	#OnMouseLeave(
		Ev
	){
		
		if( this.#Active === false ){
			
			return;
			
		}
		
		
		let UIComponentButtonConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentButtonConfig.StateMapGet( );
		
		
		if( UIStateMap.Exists( "Active" ) === true ){
		
			let UIState = UIStateMap.Get( "Active" );
			
			super.ClassNameSet( UIState.ClassNameGet( ) );
			
			super.StyleMapSet( UIState.StyleMapGet( ) );
		
		}
		
	}
	
	#OnClick( Ev ){
		
		console.log( "UIComponentButtonT.#OnClick", Ev );
		
		if( this.#Active === false ){
			
			return;
			
		}
		
		
		if( this.EventExists( "Click" ) === true ){
		
			let EventFn = this.EventGet( "Click" );
		
			EventFn( );
		
		}
		
	}
	
	Start( 
		
	){
		
		let UIElement = this.UIElement( );
		
		
		UIElement.EventSet( "click", this.#OnClick.bind( this ) );
		
		UIElement.EventSet( "mousedown", this.#OnMouseDown.bind( this ) );
		
		UIElement.EventSet( "mouseup", this.#OnMouseUp.bind( this ) );
		
		UIElement.EventSet( "mouseenter", this.#OnMouseEnter.bind( this ) );
		
		UIElement.EventSet( "mouseleave", this.#OnMouseLeave.bind( this ) );
		
	}
	
	Stop(
	
	){
		
		let UIElement = this.UIElement( );
		
		
		UIElement.EventRemove( "click" );
		
		UIElement.EventRemove( "mousedown" );
		
		UIElement.EventRemove( "mouseup" );
		
		UIElement.EventRemove( "mouseenter" );
		
		UIElement.EventRemove( "mouseleave" );
		
	}
	
	
	Listen(
		OnClickFn
	){
		
		if( typeof( OnClickFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.EventSet( "Click", OnClickFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Click" );
		
	}
	
};


export default UIComponentButtonT;
