"use strict";


import KeyValueMapT from "./KeyValueMapT.mjs";


const KeyValueElementT = class KeyValueElementT {

	#Name = null;
	#KeyValueMap = null;
	
	constructor(
		Name,
		KeyValueMap
	){
	
		if( typeof( Name ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( KeyValueMap instanceof KeyValueMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}

		
		this.#Name = Name;
		
		this.#KeyValueMap = KeyValueMap;
		
		
	}
	
	Name( ){
		
		return this.#Name;
		
	}
	
	KeyValueMap( ){
		
		return this.#KeyValueMap;
		
	}
	
};


export default KeyValueElementT;