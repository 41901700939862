"use strict";


import APIFieldValueBucketT from "../../../../FieldValue/APIFieldValueBucketT.mjs";

import UIOptionT from "../../../../../UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../UI/Option/UIOptionMapT.mjs";


const APIModelSchemaResponseCustomOptionT = class APIModelSchemaResponseCustomOptionT {
	
	#APIFieldValueBucket = null;
	
	constructor(
		APIFieldValueBucket
	){
		
		if( ( APIFieldValueBucket instanceof APIFieldValueBucketT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#APIFieldValueBucket = APIFieldValueBucket;
		
	}
	
	static FromJSON(
		JSONData
	){
		
		return new APIModelSchemaResponseCustomOptionT(
			APIFieldValueBucketT.FromJSON( JSONData )
		);
		
	}
	
	FieldValueBucket( ){
		
		return this.#APIFieldValueBucket;
		
	}
	
	ToJSON( ){
		
		return this.#APIFieldValueBucket.ToJSON( );
		
	}
	
	ToUIOptionMap(
		KeyFieldName,
		ValueFieldName
	){
		
		let UIOptionArray = [ ];
		
		for(
			let I of this.#APIFieldValueBucket.Keys( )
		){
			
			let KeyValueMap = this.#APIFieldValueBucket.Get( I );
			
			let APIFieldValueKey = KeyValueMap.Get( KeyFieldName );
			
			let APIFieldValueValue = KeyValueMap.Get( ValueFieldName );
			
			let KeyValue = APIFieldValueKey.Value( ).Value( ).toString( );
			
			let ValueValue =  APIFieldValueValue.Value( ).Value( ).toString( );
			
			UIOptionArray.push(
				new UIOptionT(
					KeyValue,
					ValueValue
				)
			);
			
		}
		
	
		return new UIOptionMapT( UIOptionArray );
	
	}
	
};


export default APIModelSchemaResponseCustomOptionT;