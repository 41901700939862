"use strict";


const UIElementAbstractT = class UIElementAbstractT {
	
	constructor(
		
	){
		
	}
	
	IdSet( 
		Id 
	){
		
		if( Id !== null ){
			
			if( typeof( Id ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	IdGet( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	ClassNameSet( 
		ClassName
	){
	
		if( ClassName !== null ){
			
			if( typeof( ClassName ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
	
		throw new Error( "Abstract" );
		
	}
	
	ClassNameGet( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	UIStyleSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	UIStyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	UIStyleRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	UIStyleKeys( ){
		
		throw new Error( "Abstract" );
		
	}
	
	ChildSet(
		Index,
		Child
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
		if( ( Child instanceof UIElementAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
	
	}
	
	ChildGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ChildRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ChildrenCount( ){
		
		throw new Error( "Abstract" );
		
	}
	
	EventSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventKeys( ){
		
		throw new Error( "Abstract" );
		
	}
	
	Focus( ){
	
		throw new Error( "Abstract" );
	
	}
	
	Blur( ){
	
		throw new Error( "Abstract" );
	
	}
	
	EditableSet(
		Value
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EditableGet(
	
	){
			
		throw new Error( "Abstract" );
			
	}
	
	InnerTextSet(
		InnerText
	){
	
		if( typeof( InnerText ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		throw new Error( "Abstract" );
	
	}
	
	InnerTextGet(
		
	){
	
		throw new Error( "Abstract" );
	
	}
	
	
	Render( ){
	
		throw new Error( "Abstract" );
		
	}
	
};


export default UIElementAbstractT;