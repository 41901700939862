import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import productionStatusCount from "../../aggrid/productionStatusCount.jsx";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import {LoadState, SaveState} from "../../services/GridStateService";

class ProductionHistoryTab extends React.Component {

  constructor(props) {

    var locale = 'cs';
    if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
    var loctext;
    if (locale === 'cs') loctext = LocaleCs;
    if (locale === 'en') loctext = LocaleEn;
    const customComparator = (valueA, valueB) => {
      if (valueA != null && valueB != null)
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    };

    function dateComparator(date1, date2) {
      var date1Number = _monthToNum(date1);
      var date2Number = _monthToNum(date2);

      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }

      return date1Number - date2Number;
    }

// HELPER FOR DATE COMPARISON
    function _monthToNum(date) {
      if (date === undefined || date === null || date.length !== 10 || date === '') {
        return null;
      }

      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);

      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      // 29/08/2004 => 20040829
      return result;
    }

    super(props)
    var date = new Date();
    var df = new Date(date.getFullYear(), date.getMonth(), 1);

    var dt = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.state = {
      isColumnStateLoaded: false,
      style: {
        width: '100%',
        height: '100%'
      },
      modules: AllCommunityModules,
      date_from: df,
      date_to: dt,

      localeText: loctext,
      rowdata: null,
      columnDefs: [

        {
          headerName: this.props.t('Loading number'),
          field: 'loading_number',
          editable: false,
		  filter: 'agTextColumnFilter',
          pinned: 'left'
        },
        {
          headerName: this.props.t('Date'),
          field: 'production_date',
          filter: 'agDateColumnFilter',
          filterParams: {
            // provide comparator function
            comparator: (filterLocalDateAtMidnight, cellValue) => {
              const dateAsString = cellValue;

              if (dateAsString == null) {
                return -1;
              }

              // In the example application, dates are stored as dd/mm/yyyy
              // We create a Date object for comparison against the filter date
              const dateParts = dateAsString.split('.');
              const day = Number(dateParts[0]);
              const month = Number(dateParts[1]) - 1;
              const year = Number(dateParts[2]);
              const cellDate = new Date(year, month, day);

              // Now that both parameters are Date objects, we can compare
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
              return 0;
            }
          },
          sort: 'desc',
          editable: false,
          comparator: dateComparator,
          valueGetter: function (params) {

            function addZero(i) {
              if (i < 10) {
                i = "0" + i;
              }
              return i;
            }

            if (params.data.production_date === null || params.data.production_date === 0 || params.data.production_date === '') return '';
            var date = new Date(params.data.production_date);
            var month = date.getMonth();
            var year = date.getFullYear();
            var day = date.getDate();

            month++;
            return addZero(day) + '.' + addZero(month) + '.' + year;

          }

        },
        {
          headerName: this.props.t('Manufacturer'),
          field: 'manufacturer',
          editable: false,
          valueGetter: function (params) {
            var values = JSON.parse(localStorage.getItem('users'));
            if (params.data.manufacturer === null || params.data.manufacturer === '') return '';
            try {
              return values.find(refData => String(refData.id) === String(params.data.manufacturer))['name'];
            } catch (err) {
              console.log(values);
              console.log(err);
              return '';
            }
          }


        },
        {
          headerName: this.props.t('Product type'),
          field: 'product_type',
          editable: false,
          suppressMenu: true,
          valueGetter: function (params) {
            var values = JSON.parse(localStorage.getItem('product_types'));
            if (params.data.product_type === null || params.data.product_type === '') return '';
            try {
              return values.find(refData => String(refData.id) === String(params.data.product_type))['name_' + locale];
            } catch (err) {
              console.log(values);
              console.log(err);
              return '';
            }
          }


        },

        {
          headerName: this.props.t('Quantity'),
          field: 'qty',
          editable: false,
          suppressMenu: true,
        },


        {
          headerName: this.props.t('Salary volume'),
          field: 'salary_volume',
          editable: false,
          suppressMenu: true,

        },


        {
          headerName: this.props.t('Country'),
          field: 'country',
          editable: false,
          suppressMenu: true,
          valueGetter: function (params) {
            var values = JSON.parse(localStorage.getItem('countries'));
            if (params.data.country === null || params.data.country === '') return '';
            try {
              return values.find(refData => String(refData.id) === String(params.data.country))['name_' + locale];
            } catch (err) {
              console.log(values);
              console.log(err);
              return '';
            }
          }

        },

        {
          headerName: this.props.t('Company'),
          field: 'company',
          editable: false,
          suppressMenu: true,

        },


        {
          headerName: this.props.t('Note'),
          field: 'note',
          editable: function (params) {
            var permissions = JSON.parse(localStorage.getItem('my_permissions'));
            if (permissions.includes('storage.edit')) {
              return true;
            } else {
              return false;
            }
          },
          suppressMenu: true,
        },

        {
          headerName: this.props.t('File'),
          field: 'attachment',
          cellRenderer: "FileRenderer",
          minWidth: 400,
          suppressMenu: true,
          cellRendererParams: {
            type: 'productions'
          }


        },


      ],
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'productionStatusCount',
            align: 'left',
            key: 'statusCountKey'
          },

        ],
      },
      defaultColDef: {
        flex: 1,
        minWidth: 150,
        editable: false,
        resizable: true,
        filter: true,
        sortable: true,
        suppressMovableColumns: true,
        comparator: customComparator,
        suppressMenu: false,
      },
      frameworkComponents: {
        CheckboxRenderer: CheckboxRenderer,
        DeleteRenderer: DeleteRenderer,
        productionStatusCount: productionStatusCount,
        FileRenderer: FileRenderer

      }
    };
  }

  componentDidMount() {

    if (this.props.id != null || this.props.company_id != null) {
      this.setState({
        rowdata: []
      });

      var url = UrlService.apiDomain() + 'api/production/loading/' + this.props.id;
      if (this.props.company_id >= 0) url = UrlService.apiDomain() + 'api/production/company/' + this.props.company_id;
      HttpService.get(url)
        .then(res => {
          this.setState({
            rowdata: res.data
          }, () => {


            function tt() {


              function filter_date_to_string(date) {
                var day = date.getDate();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                return year + '-' + month + '-' + day + ' 00:00:00';
              }

              var hardcodedFilter = {
                production_date: {
                  filterType: 'date',
                  type: 'inRange',
                  dateFrom: this.filter_date_to_string(this.state.date_from),
                  dateTo: this.filter_date_to_string(this.state.date_to),
                },
              };

              console.log(hardcodedFilter)
              this.gridApi.setFilterModel(hardcodedFilter);

              const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
              let componentInstance = statusBarComponent;
              if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
                componentInstance = statusBarComponent.getFrameworkComponentInstance();
              }

              if (componentInstance) {
                console.log(componentInstance);
                componentInstance.forceUpdate();
              }


            }


            setTimeout(tt.bind(this), 1000);
            setTimeout(() => {
              LoadState('ProductionHistory', this.gridColumnApi, this.gridApi);
              this.setState({
                isColumnStateLoaded: true
              })
            }, 1500);

          });


        })
        .catch((error) => {
          console.log(error);
        });


    }
  }


  filter_date_to_string(date) {

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    return year + '-' + month + '-' + day + ' 00:00:00';
  }


  componentDidUpdate(prevProps) {

    if (prevProps.tmp !== this.props.tmp) {


      this.componentDidMount();


    }
  }


  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;


    let items = this.gridApi.getColumnDefs();

    var permissions = JSON.parse(localStorage.getItem('my_permissions'));
    if (permissions.includes('storage.delete')) {
      items.push({
        headerName: this.props.t('Delete'),
        cellRenderer: "DeleteRenderer",

        cellRendererParams: {
          gridType: "production",
        },
      });
    }


    // this.setState({items});
    this.gridApi.setColumnDefs([]);
    this.gridApi.setColumnDefs(items);


    var columnState = JSON.parse(localStorage.getItem('ProductionsColumns'));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }


  };

  onColumnVisible(params) {

    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('ProductionsColumns', columnState);

  }

  onDragStopped(params) {

    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem('ProductionsColumns', columnState);
  }


  onCellCValueChanged = (event) => {
    HttpService.put(UrlService.apiDomain() + 'api/production/' + event.data.id, event.data)
      .then(res => {
        console.log('Successfully updated');


      })
      .catch((error) => {
        console.log(error);
      })


  }

  onColumnsStateChanged(params) {
    if(this.state.isColumnStateLoaded) {
      SaveState('ProductionHistory', params.columnApi, params.api);
    }
  }

  render() {

    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
    var height = '80vh';
    if (this.props.fromtab) height = '450px';

    return (
      <>
        <div className="buttons-companies">

          <div className="container">
            <div className="row">


              <div className="col-1">
                <div className="btn-group" sx={{float: 'left'}} style={{'marginRight': '9px'}}>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.gridApi.exportDataAsExcel()}
                  >
                    {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                  </button>
                </div>

              </div>
              <div className="col-2">
                <Datetime
                  timeFormat={false}
                  locale={locale}
                  dateFormat="DD.MM.YYYY"

                  value={this.state.date_from}

                  onChange={(event) => {
				  
					if( typeof( event ) === "string" ){
					
						return;
						
					}
				  
                    this.setState({date_from: event.toDate()});

                    var hardcodedFilter = {
                      production_date: {
                        filterType: 'date',
                        type: 'inRange',
                        dateFrom: this.filter_date_to_string(event.toDate()),
                        dateTo: this.filter_date_to_string(this.state.date_to),
                      },
                    };

                    console.log(hardcodedFilter);
                    this.gridApi.setFilterModel(hardcodedFilter);

                    const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
                    let componentInstance = statusBarComponent;
                    if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
                      componentInstance = statusBarComponent.getFrameworkComponentInstance();
                    }

                    if (componentInstance) {
                      console.log(componentInstance);
                      componentInstance.forceUpdate();
                    }


                  }}
                />
              </div>
              <div className="col-2">

                <Datetime
                  timeFormat={false}
                  locale={locale}
                  dateFormat="DD.MM.YYYY"

                  value={this.state.date_to}

                  onChange={(event) => {
				  
					if( typeof( event ) === "string" ){
					
						return;
						
					}
				  
                    this.setState({date_to: event.toDate()});

                    var hardcodedFilter = {
                      production_date: {
                        filterType: 'date',
                        type: 'inRange',
                        dateFrom: this.filter_date_to_string(this.state.date_from),
                        dateTo: this.filter_date_to_string(event.toDate()),
                      },
                    };
                    this.gridApi.setFilterModel(hardcodedFilter);

                    const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
                    let componentInstance = statusBarComponent;
                    if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
                      componentInstance = statusBarComponent.getFrameworkComponentInstance();
                    }

                    if (componentInstance) {
                      console.log(componentInstance);
                      componentInstance.forceUpdate();
                    }


                  }}
                />
              </div>

              <div className="col-7">

              </div>

            </div>
          </div>
        </div>
        <div style={{'height': height, width: '100%'}} id="productionHistoryGrid" className="ag-theme-alpine">

          <AgGridReact
            modules={this.state.modules}
            rowData={this.state.rowdata}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            onCellValueChanged={this.onCellCValueChanged}
            onCellClicked={this.onCellClicked}
            frameworkComponents={this.state.frameworkComponents}
            suppressContextMenu={true}
            localeText={this.state.localeText}
            statusBar={this.state.statusBar}
            onColumnVisible={this.onColumnsStateChanged.bind(this)}
            onDragStopped={this.onColumnsStateChanged.bind(this)}
            onFilterChanged={this.onColumnsStateChanged.bind(this)}
            enableCharts={true}
          />
        </div>
      </>

    );
  }
}

ProductionHistoryTab.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(ProductionHistoryTab);

