"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentBooleanConfigT from "../../../../Type/UI/Component/Boolean/UIComponentBooleanConfigT.mjs";


const UIComponentBooleanT = class UIComponentBooleanT extends UIComponentBaseT {

	#Value = false;

	constructor(
		UIElement,
		UIComponentBooleanConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentBooleanConfig instanceof UIComponentBooleanConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentBooleanConfig
		);
		
		
		this.ValueSet( UIComponentBooleanConfig.ValueDefaultGet( ) );
	
	}
	
	IsValidGet(
	
	){
			
		return true; //TODO
			
	}
	
	ValueGet( 
	
	){
		
		return this.#Value;
		
	}
	
	ValueSet( 
		Value
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Value = Value;
		
		
		let UIComponentBooleanConfig = super.ConfigGet( );
			
		let UIStateMap = UIComponentBooleanConfig.StateMapGet( );
		
		let UIState = null;
		
		
		if( this.#Value === false ){
			
			UIState = UIStateMap.Get( "False" );
		
		} else {
			
			UIState = UIStateMap.Get( "True" );
			
		}
		
		super.ClassNameSet( UIState.ClassNameGet( ) );
		
		super.StyleMapSet( UIState.StyleMapGet( ) );
		
	}
	
	#OnClick( Ev ){
		
		this.ValueSet( !this.#Value );
		
		
		if( this.EventExists( "Change" ) === true ){
		
			let EventFn = this.EventGet( "Change" );
		
			EventFn( this.#Value, true ); //TODO IsValid
		
		}
		
	}
	
	Start( 
		
	){
		
		let UIElement = this.UIElement( );
		
		
		UIElement.EventSet( "click", this.#OnClick.bind( this ) );
		
	}
	
	Stop(
	
	){
		
		let UIElement = this.UIElement( );
		
		
		UIElement.EventRemove( "click" );
		
	}
	
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Change" );
		
	}
	
};


export default UIComponentBooleanT;
