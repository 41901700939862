import React, { Component } from "react";
import { translate } from 'react-polyglot';
import Datetime from 'react-datetime';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import PropTypes from 'prop-types';

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";


interface Props {
    onAdd: (data) => void;
}


class ExpenseAdd extends Component<Props> {

	constructor( props ){
	
		super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ) {
		
			locale = localStorage.getItem( 'locale' );
			
		}
		
		const loadingsArray = [ ];
		const loadingsObject = { };

		props.loadingsArray.map( function( loading ){
		
			loadingsArray.push( {
				value: loading.id, 
				label: loading.loading_number + " " + loading.company 
			} );
			
			loadingsObject[ loading.id ] = loading; 
			
		});
		
		let currencies = JSON.parse( JSON.parse( localStorage.getItem('columns_108') ).data );
	
		this.state = {
			currencies: currencies,
			currency: null,
			locale: locale,
			expense_types: [],
			files: [ ],
			expense_type: null, 
			amount: "0.0", 
			note: null,
			datetime: this.CurrentDatetime( ),
			datetime_server: this.CurrentDatetimeServer( ),
			managers: JSON.parse( localStorage.getItem( 'users' ) ),
			manager: null,
			loadingsArray: loadingsArray,
			loadingsObject: loadingsObject,
			loadingsManager: [ ],
			loadingsSelected: [ ]
		};
		
		this.files = [ ];
	
	}
	
	CurrentDatetime( ){
	
		let date = new Date( );
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	CurrentDatetimeServer( ){
	
		let date = new Date( );
		
		return this.FormatDateServer( date ) + ' ' + this.FormatTimeServer( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	FormatDateServer( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FormatTimeServer( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	OnMount( ){
		
	}
	
    componentDidMount( ) {
	
		this.OnMount( );

    }
	
	componentDidUpdate( prevProps ) {
		
		console.log( "expenses_add update", this.props );
		
		if( prevProps.loadingsArray.length != this.props.loadingsArray.length ){
		
			const loadingsArray = [ ];
			const loadingsObject = { };

			this.props.loadingsArray.map( function( loading ){
			
				loadingsArray.push( {
					value: loading.id, 
					label: loading.loading_number + " " + loading.company 
				} );
				
				loadingsObject[ loading.id ] = loading; 
				
			} );
			
			this.setState( {
				loadingsArray: loadingsArray,
				loadingsObject: loadingsObject
			}, ( ) => { 
				if( ( this.props.isAdmin == false ) && ( this.props.manager == null ) ) { 
					this.HandleChangeManager( { target: { value: JSON.parse( localStorage.my_user ).id } } ); 
				} 
			} );
		
		}
		
		if( ( this.props.manager != null ) && ( prevProps.manager != this.props.manager ) ){
		
			this.HandleChangeManager( { target: { value: this.props.manager } } ); 
		
		}
		
		if( 
			( JSON.stringify( prevProps.loadingsSelected ) != JSON.stringify( this.props.loadingsSelected ) ) &&
			( this.props.loadingsSelected != null )
		) {
		
			console.log( this.props.loadingsSelected );
		
			this.HandleChangeLoadings( this.props.loadingsSelected ); 
		
		}
		
	}
	
    HandleSubmit( event ) {
	
        event.preventDefault( );
		
		
		if( this.props.isAdmin === false ){
		
			let DT = ( new Date( this.state.datetime_server ) ).getTime( );
			
			let CT = ( new Date( ) ).getTime( );
			
			if( ( DT > CT ) || ( ( CT - DT ) > ( 5 * 24 * 60 * 60 * 1000 ) ) ){
			
				alert( "Nelze zadat vydaje vice nez 5 dnu zpatky" );
				
				return;
			
			}
		
		}
		
		
		let expense_types = JSON.parse( localStorage.expense_types );
		
		let expense_type = this.state.expense_type;
		
		let positive = false;
		
		for(
			let i in expense_types
		){
		
			if( expense_types[ i ].id == expense_type ){
				
				if( expense_types[ i ].positive ){
				
					positive = true;
				
				}
				
			}
		
		}
		
		let currency = this.state.currency;
		
		let amount = parseFloat( this.state.amount );
		
		if( isNaN( amount ) == true ){
		
			amount = 0.0;
		
		}
		
		let datetime = this.state.datetime_server;
		
		let manager = this.state.manager;
		
        let loadingsSelected = this.state.loadingsSelected;
		
		let loadingsValues = [ ];
		
		for(
			let i in loadingsSelected
		){
		
			loadingsValues.push( loadingsSelected[ i ].value );
		
		}
		
		
		console.log( ( this.files ) ? this.files : [ ] );
		
        this.props.onAdd( { 
			expense_type: expense_type, 
			currency: currency,
			amount: ( positive === true ) ? amount : -amount,
			datetime: datetime,
			manager: manager,
			loadings: loadingsValues.join( "//" ),
			note: this.state.note,
			files: ( this.files ) ? this.files : [ ]
		} );
		
    }
	
	MakeItemCurrency( Item ) {
	
		console.log( "MakeItemCurrency", Item );
		
        return <option key={Item.id} value={Item.id}>{Item['name_' + this.state.locale ]}</option>;
		
    }
	
    MakeItemType( Item ) {
	
		console.log( "MakeItemType", Item );
		
        return <option key={Item.id} value={Item.id}>{Item['name_' + this.state.locale ]}</option>;
		
    }
	
	SortManagers( V1, V2 ) {

		return V1[ 'name_' + this.state.locale ].toLowerCase( ).localeCompare( V2[ 'name_' + this.state.locale ].toLowerCase( ) );
		
	}
	
	MakeItemManager( Item ) {
	
		console.log( "MakeItemManager", Item );
		
        return <option key={Item.id} value={Item.id}>{Item[ 'name_' + this.state.locale ]}</option>;
		
    }
	
	HandleChangeExpenseType( Ev ){
	
		console.log( "HandleChangeExpenseType", Ev );
	
		this.setState( { 
			expense_type: Ev.target.value 
		} );
	
	}
	
	HandleChangeCurrency( Ev ){
	
		console.log( "HandleChangeCurrency", Ev );
		
		this.setState( { 
			currency: Ev.target.value.toString( )
		} );
	
	}
	
	HandleChangeAmount( Ev ){
	
		console.log( "HandleChangeAmount", Ev );
		
		/*let Value = parseFloat( Ev.target.value );
		
		if( isNaN( Value ) == true ){
		
			Value = 0.0;
		
		}
	
		this.setState( {
			amount: Value
		} );*/
		
		this.setState( {
			amount: Ev.target.value
		} );
	
	}
	
	HandleChangeNote( Ev ){
	
		console.log( "HandleChangeNote", Ev );
	
		this.setState( {
			note: Ev.target.value
		} );
	
	}
	
	HandleChangeDatetime( Ev ){
	
		console.log( "HandleChangeDatetime", Ev );

		this.setState( {
			datetime: Ev.format( "DD.MM.YYYY hh:mm:ss" ),
			datetime_server: Ev.format( "YYYY-MM-DD hh:mm:ss" )
		} );
	
	}
	
	HandleChangeManager( Ev ){
	
		console.log( "HandleChangeManager", Ev );
		
		let manager = Ev.target.value;
		
		let loadingsObject = this.state.loadingsObject;
		
		let loadingsArray = this.state.loadingsArray;
		
		let loadingsManager = [ ];
		
		for(
			let i in loadingsArray
		){
		
			let loading = loadingsObject[ loadingsArray[ i ].value ];
			
			if( manager == "66" ){
			
				//Kostyl neobhodimiy dlya svyazivania pevnogo(66) s dotu(81)
			
				if( ( loading.manager != "66" ) && 
					( loading.manager != "81" ) &&
					( loading.manager != "72" ) ){
				
					continue;
				
				} else {
					
					if( this.props.country ){
					
						if( this.props.country == loading.country ){
						
							console.log( this.props.country, loading.country );
						
							loadingsManager.push( loadingsArray[ i ] );
						
						}
					
					} else {
				
						loadingsManager.push( loadingsArray[ i ] );
					
					}
				
				}
			
			} else if( manager == "29" ){
		
				//Kostyl neobhodimiy dlya svyazivania podhory(29) s sicho(14)
			
				if( ( loading.manager != "14" ) && 
					( loading.manager != "29" ) ){
				
					continue;
				
				} else {
					
					if( this.props.country ){
					
						if( this.props.country == loading.country ){
						
							console.log( this.props.country, loading.country );
						
							loadingsManager.push( loadingsArray[ i ] );
						
						}
					
					} else {
				
						loadingsManager.push( loadingsArray[ i ] );
					
					}
				
				}
			
			} else {
			
				if( loading.manager != manager ){
				
					continue;
				
				} else {
					
					if( this.props.country ){
					
						console.log( this.props.country, loading.country );
					
						if( this.props.country == loading.country ){
						
							loadingsManager.push( loadingsArray[ i ] );
						
						}
					
					} else {
				
						loadingsManager.push( loadingsArray[ i ] );
					
					}
				
				}
			
			}
		
			//if( loadingsObject[ loadingsArray[ i ].value ].manager == manager ){
		
				//loadingsManager.push( loadingsArray[ i ] );
		
			//}
			
		}
	
		this.setState( { 
			manager: Ev.target.value,
			loadingsSelected: [ ],
			loadingsManager: loadingsManager
		} );
	
	}
	
	HandleChangeLoadings( Val, Ev ) {

		console.log( "HandleChangeLoadings", Val, Ev );

		if (typeof Val !== 'undefined') {

			let val = '';
			
			let loadingsSelected = [ ];


			Val.map((i) => {

				if (i.value !== '*') {

					loadingsSelected.push({value: i.value, label: i.label});

				}

				return true;

			});
		  
			this.setState( {
				loadingsSelected: loadingsSelected
			} );
		  
		}
		
	}
	
	HandleChangeFiles( Ev ){
	
		console.log( "HandleChangeFiles", Ev, Ev.target.files );
	
		this.files = Ev.target.files;
	
	}
	
    render( ) {
	
		let ExpenseTypes = JSON.parse( localStorage.expense_types );
	
		let FilteredExpenseTypes = [];
	
		for(
			let i in ExpenseTypes
		){
		
			if( this.props.isAdmin == true ){
			
				FilteredExpenseTypes.push( ExpenseTypes[ i ] );
			
			} else {
			
				if( ExpenseTypes[ i ].positive != 1 ){
			
					FilteredExpenseTypes.push( ExpenseTypes[ i ] );
				
				}
			
			}
		
		}
		
        return (
            <form onSubmit={
				( Ev ) => this.HandleSubmit( Ev )
			}>
			
                <div className="container">
				
                    <div className="row">
						
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Expense type')}</label>
                                <select
                                    value={this.state.expense_type}
                                    required
                                    className="form-control"
                                    onChange={(Ev) =>
                                        this.HandleChangeExpenseType( Ev )
                                    }>
									<option value="">{this.props.t('Choose')}</option>
									{ FilteredExpenseTypes.map( this.MakeItemType.bind( this ) ) }
								</select>
                            </div>
                        </div>
						
						<div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Currency')}</label>
                                <select
                                    value={this.state.currency}
                                    required
                                    className="form-control"
                                    onChange={(Ev) =>
                                        this.HandleChangeCurrency( Ev )
                                    }>
									<option value="">{this.props.t('Choose')}</option>
									{ this.state.currencies.map( this.MakeItemCurrency.bind( this ) ) }
								</select>
                            </div>
                        </div>
						
						<div className="col-3">
							<div className="form-group">
								<label htmlFor="description">{this.props.t('Amount')}</label>
								<input
									type="text" 
									disabled={false}
									pattern="^[0-9]+([\.][0-9]+)?$"
									//value={this.state.amount}
									className="form-control"
									placeholder={this.props.t('Enter the value')}
									onChange={(Ev) =>
										this.HandleChangeAmount( Ev )
									}
								/>
							</div>
						</div>
						
						<div className="col-3">
							<div className="form-group">
								<label htmlFor="description">{this.props.t('Date')}</label>
								<Datetime
									timeFormat="hh:mm:ss"
									locale={this.state.locale}
									dateFormat="DD.MM.YYYY"
									value={this.state.datetime}
									inputProps={{required: true}}
									onChange={(Ev) => 
										this.HandleChangeDatetime( Ev )
									}
								/>
							</div>
						</div>
						
						{ ( this.props.isAdmin == true ) &&
							<div className="col-3">
								<div className="form-group">
									<label htmlFor="description">{this.props.t('Manager')}</label>
									<select
										value={this.state.manager}
										required
										className="form-control"
										onChange={(Ev) =>
											this.HandleChangeManager( Ev )
										}>
										<option value="">{this.props.t('Choose')}</option>
										{
											( this.state.managers ) ? 
												this.state.managers.sort(
													this.SortManagers.bind( this )
												).map( 
													this.MakeItemManager.bind( this ) 
												) : 
												""
										}
									</select>
								</div>
							</div>
						}
						
						<div className="col-3">
							<div className="form-group">
								<label htmlFor="description">{this.props.t('Loadings')}</label>


								<ReactMultiSelectCheckboxes

									options={this.state.loadingsManager}
									placeholderButtonLabel={this.props.t('Select')}
									value={this.state.loadingsSelected}

									onChange={(Val, Ev) => 
										this.HandleChangeLoadings( Val, Ev )
									}

								/>


							</div>
						</div>
						
						<div className="col-3">
							<div className="form-group">
								<label htmlFor="description">{this.props.t('Note')}</label>


								<input
									type="text"
									disabled={false}
									className="form-control"
									value={this.state.note}
									placeholder={this.props.t('Enter the value')}
									onChange={(Ev) =>
										this.HandleChangeNote( Ev )
									}
								/>


							</div>
						</div>
						
						<div className="col-3">
							<div className="form-group">
								<label htmlFor="description">{this.props.t('File')}</label>


								<input
									type="file"
									multiple={true}
									disabled={false}
									className="form-control"
									placeholder={this.props.t('Enter the value')}
									onChange={(Ev) =>
										this.HandleChangeFiles( Ev )
									}
								/>


							</div>
						</div>
						
                    </div>

                </div>
				
                <button className="btn btn-success">{this.props.t('Save')}</button>
				
            </form>
        );
    }
}

ExpenseAdd.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate( )(ExpenseAdd);

