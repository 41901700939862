import { Component } from "react";

import InputBoolean from '../inputs/Boolean/InputBoolean';


const ComponentBooleanRenderer = class ComponentBooleanRenderer extends Component {
	
	#Field = null;
	#Disabled = null;
	
	#Value = null;
	
	constructor( props ){
		
		console.log( "ComponentBooleanRenderer", props, props.getValue( ) );
		
		super( props );
		
		
		
		this.#Field = props.field;
		
		this.#Disabled = props.disabled;
		
		
		this.#Value = props.value;
		
	}
	
	#OnChange( Field, Type, Value ){
		
		console.log( "#OnChange", Value );
		
		
		this.#Value = Value;
		
	}
	
	afterGuiAttached( props ){
		
		console.log( "afterGuiAttached", props, this.#Value );

		
		this.setState( {
			Mounted: true
		} );
		
	}
	
	isPopup( ){
		
		return false;
            
	}
	
	render( ){
		
		console.log( "ComponentBooleanRenderer.render", this.props.getValue( ) );

		//onClick = { ( Ev ) => { Ev.preventDefault( ); Ev.stopPropagation( ); } }

		return (
			<div>
				<InputBoolean
					cursor = { "default" }
					disabled = { this.#Disabled }
					value = { this.props.getValue( ) }
					on_change_cb = { this.#OnChange.bind( this ) }
				>
					
				</InputBoolean>
			</div>

		);
	
	}


};


export default ComponentBooleanRenderer;