"use strict";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";



import GTSUIComponentFormSecondaryLineT from "./GTSUIComponentFormSecondaryLineT.mjs";



const GTSUIComponentSecondaryLineListT = class GTSUIComponentSecondaryLineListT extends UIComponentLayoutVT {
	
	constructor( 
		GTSUIComponentFormSecondaryLineList
	){
		
		if( ( GTSUIComponentFormSecondaryLineList instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < GTSUIComponentFormSecondaryLineList.length;
			I++
		){
			
			if( ( GTSUIComponentFormSecondaryLineList[ I ] instanceof GTSUIComponentFormSecondaryLineT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"3px",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
		);
		
		
		super( 
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		
		for(
			let I = 0;
			I < GTSUIComponentFormSecondaryLineList.length;
			I++
		){
			
			this.LineSet(
				I,
				GTSUIComponentFormSecondaryLineList[ I ]
			);
			
		}
		
	}
	
	LineCount(
	
	){
		
		return super.ElementCount( );
		
	}
	
	LineGet(
		Index
	){
		
		console.log( "GTSUIComponentSecondaryLineListT.LineGet", Index );
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		return super.ElementGet( Index ).DataGet( );
		
	}
	
	LineRemove(
		Index
	){
		
		console.log( "GTSUIComponentSecondaryLineListT.LineRemove", Index );
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.ElementRemove( Index );
		
	}
	
	LineSet( 
		Index,
		GTSUIComponentFormSecondaryLine
	){
		
		console.log( "GTSUIComponentSecondaryLineListT.LineSet", Index );
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		if( ( GTSUIComponentFormSecondaryLine instanceof GTSUIComponentFormSecondaryLineT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
					
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT",
						new UIStyleMapT( [
						
						] )
					)
					
				] )
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			GTSUIComponentFormSecondaryLine
		);
		
		super.ElementSet( 
			Index, 
			UIComponentLayoutElement 
		);
		
	}
	
};


export default GTSUIComponentSecondaryLineListT;