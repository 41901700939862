import React, { Component } from "react";
import "./_style.scss";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
  title: string;
  visibility: boolean;
  size: string;
  onClose: () => void;
}

class Modal extends Component<Props> {
  render() {
    var mmm=this.props.fff
    var zindex=1050;
    if (this.props.zindex) zindex=this.props.zindex;
    const { title, visibility, onClose, children, size } = this.props;
    return (
      <React.Fragment>
        <div
          className={`modal fade ${visibility ? "show" : "hide"}`}
          style={{zIndex:zindex}}
          id="modal-default"
        >
          <div className={`modal-dialog modal-${size}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{title}</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => onClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{children}</div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ${visibility ? "show" : "hide"}`}
        ></div>
      </React.Fragment>
    );
  }
}
Modal.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(Modal);

