"use strict";


import UIElementT from "../../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";



import GTSUIModelOutinvoiceFormAddItemFormLineT from "./GTSUIModelOutinvoiceFormAddItemFormLineT.mjs";

import GTSUIComponentFormSecondaryLineListT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Line/GTSUIComponentFormSecondaryLineListT.mjs";



const GTSUIModelOutinvoiceFormAddItemLineListT = class GTSUIModelOutinvoiceFormAddItemLineListT extends GTSUIComponentFormSecondaryLineListT {
	
	#TransferredVAT = false;
	
	constructor( 
		Locale
	){
		
		super( [
			
		] );
		
	}
	
	#OnRemove(
		RemoveElement
	){

		console.log( "GTSUIModelOutinvoiceFormAddItemListT.#OnRemove", RemoveElement );
		
		
		let Index = -1;
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
		
			if( super.LineGet( I ) === RemoveElement ){
				
				Index = I;
				
				break;
				
			}

		}
		
		this.LineRemove( Index );
		
	}
	
	#OnChange(
		JSONValue,
		ItemIsValid,
		JSONValueIsValid
	){
		
		console.log(
			JSONValue,
			ItemIsValid,
			JSONValueIsValid
		);
		
		this.#OnChangeInner( );
		
	}
	
	IsValidTotalGet(
	
	){
		
		let IsValidTotal = true;

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			if( IsValidElement === false ){
				
				IsValidTotal = IsValidElement;
				
			}
			
		}
		
		return IsValidTotal;
			
	}
	
	IsValidGet(
	
	){
		
		let IsValid = [ ];

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			IsValid.push( IsValidElement );
			
		}
		
		return IsValid;
		
	}
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#TransferredVAT = TransferredVAT;
		
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			ItemListElement.TransferredVATToggle(
				TransferredVAT
			);
			
		}
		
	}
	
	ValueGet(
	
	){
		
		let Values = [ ];

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			Values.push( ItemListElement.ValueGet( ) );
			
		}
		
		return Values;
		
	}
	
	#OnChangeInner(
		
	){
		
		let Values = this.ValueGet( );
		
		let IsValid = this.IsValidGet( );
		
		let IsValidTotal = this.IsValidTotalGet( );
		
		if( super.EventExists( "ItemListChange" ) === true ){
			
			let EventFn = super.EventGet( "ItemListChange" );
			
			EventFn( Values, IsValidTotal, IsValid );
			
		}
		
	}
	
	LineAdd( 
		Locale,
		
		IsProductDefault,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
	
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let GTSUIModelOutinvoiceFormAddItemFormLine = new GTSUIModelOutinvoiceFormAddItemFormLineT(
			Locale,
			
			IsProductDefault,
			
			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
	
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		
		console.log( "DEBUG1", super.LineCount( ), GTSUIModelOutinvoiceFormAddItemFormLine );
		
		
		super.LineSet( 
			super.LineCount( ),
			GTSUIModelOutinvoiceFormAddItemFormLine
		);
		
		
		GTSUIModelOutinvoiceFormAddItemFormLine.Start( );
		
		GTSUIModelOutinvoiceFormAddItemFormLine.Listen(
			this.#OnRemove.bind( this ),
			this.#OnChange.bind( this )
		);
		
		this.#OnChangeInner( );
		
		GTSUIModelOutinvoiceFormAddItemFormLine.TransferredVATToggle(
			this.#TransferredVAT
		);
		
	
	}
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			ItemListElement.TransferredVATToggle(
				TransferredVAT
			);
			
		}
		
	}
	
	LineGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		return super.LineGet(
			Index
		);
		
	}
	
	LineRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.LineRemove( Index );
		
	}
	
	Clear( ){
		
		while(
			super.LineCount( ) > 0
		){
		
			this.LineRemove( 0 );

		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.EventSet( "ItemListChange", OnChangeFn );
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemLineListT;

/* "use strict";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";



import GTSUIModelOutinvoiceFormAddItemT from "./GTSUIModelOutinvoiceFormAddItemT.mjs";



const GTSUIModelOutinvoiceFormAddItemListT = class GTSUIModelOutinvoiceFormAddItemListT extends UIComponentLayoutVT {
	
	#ItemList = [ ];
	
	#TransferredVAT = false;
	
	constructor( 
		Locale
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"3px",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] ),
		
			null //Id
		
		);
		
		
		super( 
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
	}
	
	#OnRemove(
		RemoveElement
	){

		console.log( "GTSUIModelOutinvoiceFormAddItemListT.#OnRemove", RemoveElement );


		let Index = this.#ItemList.indexOf( RemoveElement );
		
		this.#ItemList.splice( Index, 1 );
		
		this.Remove( Index );
		
	}
	
	#OnChange(
		JSONValue,
		ItemIsValid,
		JSONValueIsValid
	){
		
		console.log(
			JSONValue,
			ItemIsValid,
			JSONValueIsValid
		);
		
		this.#OnChangeInner( );
		
	}
	
	IsValidTotalGet(
	
	){
		
		let IsValidTotal = true;

		for(
			let I = 0;
			I < this.#ItemList.length;
			I++
		){
			
			let ItemListElement = this.#ItemList[ I ];
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			if( IsValidElement === false ){
				
				IsValidTotal = IsValidElement;
				
			}
			
		}
		
		return IsValidTotal;
			
	}
	
	IsValidGet(
	
	){
		
		let IsValid = [ ];

		for(
			let I = 0;
			I < this.#ItemList.length;
			I++
		){
			
			let ItemListElement = this.#ItemList[ I ];
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			IsValid.push( IsValidElement );
			
		}
		
		return IsValid;
		
	}
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#TransferredVAT = TransferredVAT;
		
		
		for(
			let I = 0;
			I < this.#ItemList.length;
			I++
		){
			
			let ItemListElement = this.#ItemList[ I ];
			
			ItemListElement.TransferredVATToggle(
				TransferredVAT
			);
			
		}
		
	}
	
	ValueGet(
	
	){
		
		let Values = [ ];

		for(
			let I = 0;
			I < this.#ItemList.length;
			I++
		){
			
			let ItemListElement = this.#ItemList[ I ];
			
			Values.push( ItemListElement.ValueGet( ) );
			
		}
		
		return Values;
		
	}
	
	#OnChangeInner(
		
	){
		
		let Values = this.ValueGet( );
		
		let IsValid = this.IsValidGet( );
		
		let IsValidTotal = this.IsValidTotalGet( );
		
		if( super.EventExists( "ItemListChange" ) === true ){
			
			let EventFn = super.EventGet( "ItemListChange" );
			
			EventFn( Values, IsValidTotal, IsValid );
			
		}
		
	}
	
	Add( 
		Locale,
		
		UIOptionMapDescription,
		DescriptionDefault,
	
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let GTSUIModelOutinvoiceFormAddItem = new GTSUIModelOutinvoiceFormAddItemT(
			Locale,
			
			UIOptionMapDescription,
			DescriptionDefault,
	
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		UIComponentLayoutElement.DataSet( 
			GTSUIModelOutinvoiceFormAddItem 
		);
		
		super.ElementSet( this.#ItemList.length, UIComponentLayoutElement );
		
		this.#ItemList.push( GTSUIModelOutinvoiceFormAddItem );
		
		
		GTSUIModelOutinvoiceFormAddItem.Start( );
		
		//UIComponentLayoutElement.Update( );
		
		GTSUIModelOutinvoiceFormAddItem.Listen(
			this.#OnRemove.bind( this ),
			this.#OnChange.bind( this )
		)
		
		
		
		this.#OnChangeInner( );
		
		GTSUIModelOutinvoiceFormAddItem.TransferredVATToggle(
			this.#TransferredVAT
		);
		
	
	}
	
	Get(
		Index
	){
		
		return super.ElementGet(
			Index
		);
		
	}
	
	Remove(
		Index
	){
		
		this.#ItemList.splice( Index, 1 );
		
		super.ElementRemove( 
			Index 
		);
		
		
		this.#OnChangeInner( );
		
	}
	
	Listen(
		OnChangeFn
	){
		
		super.EventSet( "ItemListChange", OnChangeFn );
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemListT; */