import React, { Component } from "react";
import TopCards from "./topcards";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

class Home extends Component {


  render() {
    /*
     return (
     <React.Fragment>
     <TopCards />
     <div className="row">
     <div className="col-md-6">
     <TodoWrapper />
     </div>
     <div className="col-md-5">
     <Card title={this.props.t('Loadings')} titleIcon="ion-clipboard">
     <p>Zde budou grafy</p>
     </Card>
     </div>
     </div>
     </React.Fragment>
     );

     */


    return (
      <React.Fragment>
        <TopCards />

      </React.Fragment>
    );
  }
}
Home.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(Home);

