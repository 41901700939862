import { ColDef } from "ag-grid-community";


import InputBooleanParser from "../../inputs/Boolean/InputBooleanParser";
import InputBooleanSerializer from "../../inputs/Boolean/InputBooleanSerializer";


const ColumnSingleBoolean = class ColumnSingleBoolean implements ColDef {

	headerName = null;
		
	field = null;
		
	options = null;
		
	editable = false;
			
	cellEditor = 'ComponentBoolean';
	
	cellEditorPopup = true;
		
	cellEditorPopupPosition = 'over';
	
	cellRenderer = 'ComponentBooleanRenderer';
	
	singleClickEdit = true;
	
	
	comparator = function( valueA, valueB ) {
		
		console.log( valueA, valueB );
		
		if( ( valueA === null ) && ( valueB === null ) ){
				
			return 0;
			
		}
		
		if( valueA === null ){
				
			return -1;
			
		}
		
		if( valueB === null ){
			
			return 1;
			
		}
		
		if( ( valueA === false ) && ( valueB === true ) ){
			
			return -1;
			
		}
		
		if( ( valueA === true ) && ( valueB === false ) ){
			
			return 1;
			
		}
		
		return 0;
		
	};
	

	constructor(
		Name,
		Field,
		Placeholder,
		Editable
	){
		
		this.headerName = Name;
		
		this.field = Field;
		
		this.placeholder = Placeholder;
		
		this.editable = Editable;
		
		
		this.cellRendererParams = { 
			field: this.field,
			disabled: !this.editable
		};
		
		this.cellEditorParams = {
			field: this.field,
			disabled: !this.editable,
			placeholder: this.placeholder
		};
		
		this.valueGetter = function( params ) {
		
			console.log( "valueGetter", params );
			
			return InputBooleanParser( params.data[ this.field ] );
			
		}.bind( this );
			
		this.valueFormatter = function( params ){
			
			console.log( "valueFormatter", params );
			
			
			let Data = InputBooleanParser( params.data[ this.field ] );
			
			let Str = null;
			
			if( Data === null ){
				
				Str = "";
				
			} else {
				
				Str = Data;
				
			}
			
			return Str;
			
		}.bind( this );
			
		this.valueParser = function( params ){
				
			console.log( "valueParser", params );
				
		}.bind( this );
			
		this.valueSetter = function( params ){
			
			console.log( "valueSetter", params );
			
			
			params.data[ this.field ] = InputBooleanSerializer( params.newValue );
			
			
			return true;
			
		}.bind( this );
			
	}
	
};


export default ColumnSingleBoolean;