import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";

import AddHour from "./add_hour.tsx";
import {LoadState, SaveState} from "../../services/GridStateService";
class Workhours  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;

        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            now:Date.now(),

            showModal:false,

            localeText: loctext,
            rowdata: null,
            columnDefs: [

                {
                    headerName: this.props.t('Work date'),
                    field: 'work_date',
                    editable:false,
                    suppressMenu: true,
                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.work_date===null || params.data.work_date===0 || params.data.work_date==='') return '';
                        var  date = new Date(params.data.work_date);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();

                        month++;
                        return day+'.'+month+'.'+year;

                    }
                },




                {
                    headerName: this.props.t('Worker'),
                    field: 'worker',
                    valueGetter: function (params) {
                        var values= JSON.parse(localStorage.getItem('users'));
                        if (params.data.worker === null || params.data.worker === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.worker))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

        {
            headerName: this.props.t('Work type'),
                field: 'work_type',
            valueGetter: function (params) {
            var values= JSON.parse(localStorage.getItem('work_types'));
            if (params.data.work_type === null || params.data.work_type === '') return '';
            try {
                return values.find(refData => String(refData.id) === String(params.data.work_type))['name_'+locale];
            } catch (err) {
                console.log(values);
                console.log(err);
                return '';
            }
        },


        },

                {
                    headerName: this.props.t('Hours'),
                    field: 'hours',
                    editable:false,
                    suppressMenu: true,
                },


                {
                    headerName: this.props.t('Salary'),
                    field: 'salary',
                    editable:false,
                    suppressMenu: true,
                },

                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                    editable:true,
                    suppressMenu: true,
                },


                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.created_at===null || params.data.created_at===0 || params.data.created_at==='') return '';
                        var  date = new Date(params.data.created_at);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();
                        var hour=addZero(date.getHours());
                        var min=addZero(date.getMinutes());
                        month++;
                        return day+'.'+month+'.'+year+' '+hour+':'+min;

                    }
                },

                {
                    headerName: this.props.t('Created by'),
                    field: 'created_by',
                    valueGetter: function (params) {
                        var values= JSON.parse(localStorage.getItem('users'));
                        if (params.data.created_by === null || params.data.created_by === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.created_by))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

                         ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer:DeleteRenderer,


            }
        };
    }



    componentDidMount() {


        var url=UrlService.apiDomain() + 'api/workhours';

        HttpService.get(url)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });

                setTimeout(() => {
                    LoadState('WorkHours', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            });

    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));

        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });

        if (roles.includes('Admin') || roles.includes('Vedoucí výroby')) {
            items.push(   {headerName:this.props.t('Delete'),
                cellRenderer:"DeleteRenderer",

                cellRendererParams: {
                    gridType: "workhours",
                },
                suppressMenu: true,});
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        LoadState('WorkHours', params.columnApi, params.api);
    };

    async handleAdd(data) {

        HttpService.post(UrlService.apiDomain()+'api/workhours', data)
            .then(res => {
                if (res.data===null) alert ("Chyba připojení k serveru");
                if (typeof res.data.alert!=='undefined' && res.data.alert!='') {

                    alert(res.data.alert);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});

                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }

    getRowNodeId=(data) => {
        return data.id;
    };


    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/workhours/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
            })
            .catch((error) => {
                console.log(error);
            })


    }

    onColumnsStateChanged(params) {
        if (this.state.isColumnStateLoaded) {
            SaveState('WorkHours', params.columnApi, params.api);
        }
    }

    render() {



        return (
            <div className="wrapper">
                <Modal
                    title={this.props.t('Add work hours')}
                    visibility={this.state.showModal}
                    size="lg"
                    onClose={() => this.setState({ showModal: !this.state.showModal })}
                >
                    <AddHour tmp={this.state.now}  onAdd={(data) => this.handleAdd(data)} />
                </Modal>
                <Layout>
                    <Content hideTitle={true} title="Work hours">

                        <div className="buttons-companies">
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ now:Date.now() , showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>

                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>
                        </div>
                        <div style={{ 'height': '400px', width:'100%' }} id="workhoursGrid" className="ag-theme-alpine">

                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}

                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                getRowNodeId = {this.getRowNodeId}

                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>


        );
    }
}

Workhours.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Workhours);

