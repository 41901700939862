import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
interface Props {
    onAdd: (data) => void;
}

class DriversAdd extends Component<Props> {
    blankOption={value: "", label:this.props.t('Choose')};
    productsData=JSON.parse(localStorage.getItem('product_types'));
    state = { qty: "", product_type: "",product_type_value: this.blankOption,};
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { qty, product_type} = this.state;
        this.props.onAdd({qty, product_type});
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
            this.setState({ qty: "", product_type: "",product_type_value: this.blankOption});
        }

    }

    render() {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var product_types=[this.blankOption];

        this.productsData.map((e)=>{
            if (String(e.product_type)=="4") product_types.push({value: e.id, label:e['name_'+locale]})
        })

        const {name, address, passport} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">

                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Product type')}</label>
                                <Select
                                    name="company"
                                    onChange={(event) => {
                                        this.setState({product_type: event.value, product_type_value:event});
                                    }}
                                    value={this.state.product_type_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={product_types} />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Quantity')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={this.state.qty}
                                    pattern="[0-9]*"
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ qty: event.target.value })
                                    }
                                />
                            </div>
                        </div>



                    </div>



                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
DriversAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(DriversAdd);

