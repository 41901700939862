"use strict";


import UIElementT from "../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentTextT from "../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";


import UIStateT from "../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentTextConfigT from "../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDataConfigT from "../../../../../Type/UI/Component/TextData/UIComponentTextDataConfigT.mjs";

import UIComponentPlaceholderConfigT from "../../../../../Type/UI/Component/Placeholder/UIComponentPlaceholderConfigT.mjs";


const GTSUIComponentTextT = class GTSUIComponentTextT extends UIComponentTextT {
	
	constructor(
		ValueDefault,
		
		PlaceholderLabel,
		
		RegExpMatch,
		RegExpValidation,
		
		Id = null
	){
		
		let UIComponentTextDataConfig = new UIComponentTextDataConfigT(
			ValueDefault, //ValueDefault
			false, //EmptyOnFocus
			RegExpMatch, //RegExpMatch
			RegExpValidation, //RegExpValidation
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentTextDataT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "absolute" ),
						new UIStyleT( "visibility", "hidden" )
					] )
				)
			] ),
		
			null //Id
			
		);
		
		let UIComponentPlaceholderConfig = new UIComponentPlaceholderConfigT(
		
			PlaceholderLabel,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentPlaceholderT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "100" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "absolute" ),
						new UIStyleT( "visibility", "visible" )
					] )
				)
			] ),
			
			null
		);
		
		let UIComponentTextConfig = new UIComponentTextConfigT(
		
			UIComponentPlaceholderConfig,
			UIComponentTextDataConfig,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentTextT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" ),
						new UIStyleT( "position", "relative" )
					] )
				),
				new UIStateT( 
					"ValidFocused",
					"UIComponentTextT Valid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 5px 15px" ),
						new UIStyleT( "position", "relative" )
					] )
				),
				new UIStateT( 
					"Valid",
					"UIComponentTextT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" ),
						new UIStyleT( "position", "relative" )
					] )
				),
				new UIStateT( 
					"InvalidFocused",
					"UIComponentTextT Invalid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 5px 15px" ),
						new UIStyleT( "position", "relative" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentTextT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 0px 0px" ),
						new UIStyleT( "position", "relative" )
					] )
				)
			] )
			
		);
		
		
		super(
			new UIElementT( ),
			UIComponentTextConfig
		);
		
		
		if( super.ValueGet( ) === "" ){
			
			this.#OnPlaceholderShow( );
			
		} else {
			
			this.#OnPlaceholderHide( );
			
		}
		
	}
	
	ValueSet( 
		Value,
		NeedSelection
	){
		
		if( Value === "" ){
			
			this.#OnPlaceholderShow( );
			
		} else {
			
			this.#OnPlaceholderHide( );
			
		}
		
		super.ValueSet( Value, Value.length, Value.length, NeedSelection );
		
	}
	
	#OnPlaceholderHide( 
	
	){
		
		console.log( "GTSUIComponentTextT.#PlaceholderHide" );
		
		
		let UIComponentTextData = super.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "visible" )
			] )
		);
		
		let UIComponentPlaceholder = super.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "hidden" )
			] )
		);
		
	}
	
	#OnPlaceholderShow(
	
	){
		
		console.log( "GTSUIComponentTextT.#PlaceholderShow" );
		
		
		let UIComponentTextData = super.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "hidden" )
			] )
		);
			
		let UIComponentPlaceholder = super.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "visibility", "visible" )
			] )
		);
			
	}
	
	Start(
	
	){
	
		super.Start( );
	
	}
	
	Listen(
		OnChange
	){
		
		super.Listen(
			OnChange,
			this.#OnPlaceholderShow.bind( this ),
			this.#OnPlaceholderHide.bind( this )
		);
		
	}
	
};


export default GTSUIComponentTextT;
