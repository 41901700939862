import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select from 'react-select'
interface Props {
    onAdd: (data) => void;
}

class BankAccountAdd  extends Component<Props> {

    blankOption={value: "", label:this.props.t('Choose')};
    state = {account_name: "", bank_name: "", bank_address: "", prefix:"", account_number:"", bank_code:"", iban:"", swift:'', currency:"" , payer_name:"", payer_address:"", currency_value:this.blankOption,
    generator_type:'',  generator_type_value:this.blankOption
    };
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const {account_name, generator_type, bank_name, prefix, account_number,bank_address,  bank_code, iban, swift, currency, payer_name, payer_address} = this.state;
        this.props.onAdd({account_name, generator_type, bank_name, bank_address,  prefix, account_number, bank_code, iban, swift, currency, payer_name, payer_address});
    }


    render() {

        const {account_name, bank_address, bank_name, prefix, account_number, bank_code, iban, swift, currency, currency_value, payer_name, payer_address, generator_type, generator_type_value} = this.state;

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        const currencies = [this.blankOption];
        const generatorTypes = [this.blankOption, {value: 'Česká spořitelna', label:'Česká spořitelna'}, {value: 'Citfin', label:'Citfin'}];

        JSON.parse(JSON.parse(localStorage.getItem('columns_147')).data).map((e)=>{
            currencies.push({value: e.id, label:e['name_'+locale]});
        });

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Account name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={account_name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ account_name: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Bank name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={bank_name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ bank_name: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Account prefix')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={prefix}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ prefix: event.target.value })
                                    }
                                />
                            </div>
                        </div>




                    </div>




                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Account number')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={account_number}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ account_number: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Bank code')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={bank_code}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ bank_code: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('IBAN')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={iban}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ iban: event.target.value })
                                    }
                                />
                            </div>
                        </div>




                    </div>


                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('SWIFT')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={swift}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ swift: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="description">{this.props.t('Currency')}</label>
                            <Select
                                name="currency"
                                onChange={(event) => {
                                    var stateValue={};
                                    stateValue['currency']=event.value;
                                    stateValue['currency_value']=event;
                                    this.setState(stateValue);

                                }}
                                value={this.state.currency_value}
                                required

                                placeholder={this.props.t('Select')}
                                classNamePrefix="select"
                                options={currencies} />
                        </div>
                    </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Payer name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={payer_name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) => {

                                        this.setState({ payer_name: event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 32).replace(/[^a-z0-9 ]/gi,'') });
                                    }

                                    }
                                />
                            </div>
                        </div>





                    </div>
                    <div className="row">

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Bank address')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={bank_address}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) => {

                                        this.setState({ bank_address: event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 32).replace(/[^a-z0-9 ]/gi,'') });
                                    }

                                    }
                                />
                            </div>
                        </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="description">{this.props.t('Payer address')}</label>
                            <input
                                type="text"
                                required
                                className="form-control"
                                value={payer_address}
                                placeholder={this.props.t('Enter the name')}
                                onChange={(event) => {

                                    this.setState({ payer_address: event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 32).replace(/[^a-z0-9 ]/gi,'') });
                                }

                                }
                            />
                        </div>
                    </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Generator type')}</label>
                                <Select
                                    name="generator_type"
                                    onChange={(event) => {
                                        var stateValue={};
                                        stateValue['generator_type']=event.value;
                                        stateValue['generator_type_value']=event;
                                        this.setState(stateValue);

                                    }}
                                    value={this.state.generator_type_value}
                                    required

                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={generatorTypes} />
                            </div>
                        </div>
                    </div>

                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
BankAccountAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(BankAccountAdd);

