
import Row from "./Row";


const Table = function(
	Rows
) {
	
	return (
	
		<div className="container" style={{ maxWidth:"100%" }}>
			
			{ Rows }

		</div>
		
	);
	
};

export default Table;

