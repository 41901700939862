"use strict";


import UIStyleT from "../../../../Type/UI/Style/UIStyleT.mjs";

import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentLayoutElementT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";


import UIComponentLayoutHConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";



const UIComponentLayoutHT = class UIComponentLayoutHT extends UIComponentBaseT {

	#Value = false;
	
	#UIComponentLayoutHConfig = null;
	
	#Elements =  null;

	constructor(
		UIElement,
		UIComponentLayoutHConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentLayoutHConfig instanceof UIComponentLayoutHConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentLayoutHConfig
		);
		
		
		this.#Elements = new Map( );
		
		
		this.ConfigSet( UIComponentLayoutHConfig );
		
		
	
	}
	
	ConfigSet(
		UIComponentLayoutHConfig
	){
		
		if( ( UIComponentLayoutHConfig instanceof UIComponentLayoutHConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#UIComponentLayoutHConfig = UIComponentLayoutHConfig.Copy( );
		
		
		let UIStateMap = this.#UIComponentLayoutHConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		let UIStyleMap = UIState.StyleMapGet( );
		
		UIStyleMap.Set( new UIStyleT( "display", "flex" ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-direction", "row" ) );
		
		UIStyleMap.Set( new UIStyleT( "flex-wrap", UIComponentLayoutHConfig.WrapGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "justify-content", UIComponentLayoutHConfig.JustifyContentGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "align-items", UIComponentLayoutHConfig.AlignItemsGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "align-content", UIComponentLayoutHConfig.AlignContentGet( ) ) );
		
		UIStyleMap.Set( new UIStyleT( "column-gap",  UIComponentLayoutHConfig.ColumnGapGet( ) ) );
		
		
		console.log( UIComponentLayoutHConfig, this.#UIComponentLayoutHConfig );
		
		
		super.ConfigSet( this.#UIComponentLayoutHConfig );
		
		super.StyleMapSet( UIStyleMap );
		
	}
	
	ConfigGet(
	
	){
		
		return this.#UIComponentLayoutHConfig.Copy( );
		
	}
	
	
	ElementGet( 
		Index
	){
		
		return this.#Elements.get( Index );
		
	}
	
	ElementSet(
		Index,
		Element
	){
		
		console.log( "UIComponentLayoutHT.ElementSet", Element );
		
		
		if( ( Element instanceof UIComponentLayoutElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Elements.set( Index, Element );
		
		this.ChildSet( Index, Element );
		
	}
	
	Start( 
		
	){
		
	}
	
	Stop(
	
	){
		
	}
	
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Change" );
		
	}
	
};


export default UIComponentLayoutHT;
