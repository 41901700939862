"use strict";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";


import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import GTSUIModelSaleFormAddItemFormHeaderT from "./Header/GTSUIModelSaleFormAddItemFormHeaderT.mjs";

import GTSUIModelSaleFormAddItemFormLineListT from "./Line/GTSUIModelSaleFormAddItemFormLineListT.mjs";

import GTSUIComponentFormSecondaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/GTSUIComponentFormSecondaryT.mjs";


const GTSUIModelSaleFormAddItemFormT = class GTSUIModelSaleFormAddItemFormT extends GTSUIComponentFormSecondaryT {
	
	#Locale = null;
	
	#UIOptionMapProductType = null;
	#ProductTypeDefault = null;
					
	#UIOptionMapDescription = null;
	#DescriptionDefault = null;
					
	#UIOptionMapUnit = null;
	#UnitDefault = null;
					
	#UIOptionMapVATRate = null;
	#VATRateDefault = null;
	
	#TransferredVAT = false;
	#NeedOutinvoice = true;
	#NeedSupplylist = true;
	
	constructor( 
		Locale,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let GTSUIModelSaleFormAddItemFormHeader = new GTSUIModelSaleFormAddItemFormHeaderT(
			Locale
		);
		
		let GTSUIModelSaleFormAddItemFormLineList = new GTSUIModelSaleFormAddItemFormLineListT(
			Locale
		);
		
		
		super( 
			GTSUIModelSaleFormAddItemFormHeader,
			GTSUIModelSaleFormAddItemFormLineList
		);
		
		
		let Values = GTSUIModelSaleFormAddItemFormLineList.ValueGet( );
		
		if(
			Values.length === 0
		){
			
			GTSUIModelSaleFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			GTSUIModelSaleFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
		
		this.#Locale = Locale;
		
		this.#UIOptionMapProductType = UIOptionMapProductType;
		
		this.#ProductTypeDefault = ProductTypeDefault;
		
		this.#UIOptionMapDescription = UIOptionMapDescription;
		this.#DescriptionDefault = DescriptionDefault;
		
		this.#UIOptionMapUnit = UIOptionMapUnit;
		this.#UnitDefault = UnitDefault;
		
		this.#UIOptionMapVATRate = UIOptionMapVATRate;
		this.#VATRateDefault = VATRateDefault;
		
	}
	
	LineAdd(
		IsProduct = false,
		ProductTypeDefault = null
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
	
		GTSUIModelSaleFormAddItemFormLineList.LineAdd(
			this.#Locale,
			
			this.#UIOptionMapProductType,
			( ProductTypeDefault !== null ) ? ProductTypeDefault : this.#ProductTypeDefault,
			
			this.#UIOptionMapDescription,
			this.#DescriptionDefault,
		
			this.#UIOptionMapUnit,
			this.#UnitDefault,
			
			this.#UIOptionMapVATRate,
			this.#VATRateDefault,
			
			this.#TransferredVAT,
			this.#NeedOutinvoice,
			this.#NeedSupplylist,
			
			IsProduct
		);
	
	}
	
	LineGet(
		Index
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelSaleFormAddItemFormLineList.Get(
			Index
		);
		
	}
	
	Clear( ){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelSaleFormAddItemFormLineList.Clear( );
		
	}
	
	#ItemListOnChange(
		Values,
		IsValid,
		ValuesIsValid
	){
		
		console.log(
			"GTSUIModelSaleFormAddItemFormT.#ItemListOnChange",
			Values,
			IsValid,
			ValuesIsValid
		);
		
		let GTSUIModelSaleFormAddItemFormHeader = super.HeaderGet( );
		
		if(
			Values.length === 0
		){
			
			GTSUIModelSaleFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "none" )
				] )
			);
			
		} else {
			
			GTSUIModelSaleFormAddItemFormHeader.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "display", "flex" )
				] )
			);
			
		}
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( 
				Values,
				IsValid
			);
			
		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		
		GTSUIModelSaleFormAddItemFormLineList.Listen(
			this.#ItemListOnChange.bind( this )
		);
		
		super.EventSet(
			"Change",
			OnChangeFn
		);
		
	}
	
	NeedOutinvoiceToggle( 
		NeedOutinvoice
	){
		
		this.#NeedOutinvoice = NeedOutinvoice;
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelSaleFormAddItemFormLineList.NeedOutinvoiceToggle(
			NeedOutinvoice
		);
		
	}
	
	NeedSupplylistToggle( 
		NeedSupplylist
	){
		
		this.#NeedSupplylist = NeedSupplylist;
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelSaleFormAddItemFormLineList.NeedSupplylistToggle(
			NeedSupplylist
		);
		
	}
	
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		this.#TransferredVAT = TransferredVAT;
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		GTSUIModelSaleFormAddItemFormLineList.TransferredVATToggle(
			TransferredVAT
		);
		
	}
	
	VATTotal(
	
	){
		
		let VAT = 0;
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		let Values = GTSUIModelSaleFormAddItemFormLineList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			if( Values[ I ].in_outinvoice === true ){
			
				VAT += Values[ I ].vat;
			
			}
			
		}
		
		return VAT;
		
	}
	
	ExcVATTotal(
	
	){
		
		let ExcVAT = 0;
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		let Values = GTSUIModelSaleFormAddItemFormLineList.ValueGet( );
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			if( Values[ I ].in_outinvoice === true ){
			
				ExcVAT += Values[ I ].excvat * Values[ I ].qty;
			
			}
			
		}
		
		return ExcVAT;
		
	}
	
	IncVATTotal(
	
	){
		
		return ( this.ExcVATTotal( ) + this.VATTotal( ) );
		
	}
	
	IsValidTotalGet(
	
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelSaleFormAddItemFormLineList.IsValidTotalGet( );
		
	}
	
	IsValidGet(
	
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
	
		return GTSUIModelSaleFormAddItemFormLineList.IsValidGet( );
	
	}
	
	ValueGet(
	
	){
		
		let GTSUIModelSaleFormAddItemFormLineList = super.LineListGet( );
		
		return GTSUIModelSaleFormAddItemFormLineList.ValueGet( );
		
	}
	
};


export default GTSUIModelSaleFormAddItemFormT;