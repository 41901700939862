"use strict";


import HTTPClientCookieT from "./HTTPClientCookieT.mjs";


const HTTPClientCookiesT = class HTTPClientCookiesT {
	
	#Map = null;
	
	constructor( ){
		
		this.#Map = new Map( );
		
	}
	
	Set( Name, HTTPClientCookie ){
		
		this.#Map.set( Name, HTTPClientCookie );
		
	}
	
	Get( Name ){
		
		return this.#Map.get( Name );
		
	}
	
	Remove( Name ){
		
		this.#Map.delete( Name );
		
	}
	
	Serialize( ){
		
		let HTTPClientCookies = [ ];
		
		for(  
			let Key of this.#Map.keys( )
		){
			
			
			let Value = this.#Map.get( Key );
			
			
			HTTPClientCookies.push( Value.Serialize( ) );
			
		}
		
		return HTTPClientCookies.join( ";" );
		
	}
	
	Size( ){
		
		return this.#Map.size;
		
	}
	
	static Parse(
		HTTPCookieHeaderValue
	){
		
		let HTTPClientCookies = new HTTPClientCookiesT( );
		
		let HTTPClientCookiesTrimmed = HTTPCookieHeaderValue.trim( );
		
		if( HTTPClientCookiesTrimmed !== "" ){
			
			console.log( "HTTPClientCookiesTrimmed", HTTPClientCookiesTrimmed );
		
			let HTTPCookieHeaderValues = HTTPClientCookiesTrimmed.split( ";" );
		
			for(
				let Iterator = 0;
				Iterator < HTTPCookieHeaderValues.length;
				Iterator++
			){
			
				let HTTPClientCookie = HTTPClientCookieT.Parse( HTTPCookieHeaderValues[ Iterator ] );
			
				HTTPClientCookies.Set( HTTPClientCookie.Name( ), HTTPClientCookie );
				
			}
		
		}
		
		return HTTPClientCookies;
		
	}
	
};


export default HTTPClientCookiesT;