import React, { Component } from "react";
import $ from "jquery";

export default class RadioRenderer extends Component {
    constructor(props) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }
    checkedHandler(event) {
        // event.preventDefault();


        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);

    }
    render() {
        var value=this.props.value;
        if (value===null) value=0;
        return (


            <input type={"radio"} name={"rad"} defaultChecked={this.props.value} value={this.props.value} onClick={this.checkedHandler}/>

        );
    }
}