"use strict";


import UIComponentTextConfigT from "../Text/UIComponentTextConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";


const UIComponentDoubleConfigT = class UIComponentDoubleConfigT extends UIComponentTextConfigT {
	
	#ValueDefault = 0.0;
	
	#MayBeNegative = true;
	#MayBePositive = true;
	#FractionMaxSize = 0;
	
	constructor(
		ValueDefault,
		
		MayBeNegative,
		MayBePositive,
		FractionMaxSize,
	
		UIComponentPlaceholderConfig,
		UIComponentTextDataConfig,
		
		UIStateMap = null
	){
		
		super(
			UIComponentPlaceholderConfig,
			UIComponentTextDataConfig,
			
			UIStateMap
		);
		
		this.ValueDefaultSet( ValueDefault );
		
		this.MayBeNegativeSet( MayBeNegative );
		
		this.MayBePositiveSet( MayBePositive );
		
		this.FractionMaxSizeSet( FractionMaxSize );
		
	}
	
	FractionMaxSizeSet(
		FractionMaxSize
	){
		
		if( typeof( FractionMaxSize ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#FractionMaxSize = FractionMaxSize;
		
	}
	
	FractionMaxSizeGet(
	
	){
	
		return this.#FractionMaxSize;
	
	}
	
	MayBeNegativeSet(
		MayBeNegative
	){
		
		if( typeof( MayBeNegative ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#MayBeNegative = MayBeNegative;
		
	}
	
	MayBeNegativeGet(
	
	){
	
		return this.#MayBeNegative;
	
	}
	
	MayBePositiveSet(
		MayBePositive
	){
		
		if( typeof( MayBePositive ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#MayBePositive = MayBePositive;
		
	}
	
	MayBePositiveGet(
	
	){
	
		return this.#MayBePositive;
	
	}
	
	ValueDefaultSet(
		ValueDefault
	){
		
		if( typeof( ValueDefault ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#ValueDefault = ValueDefault;
		
	}
	
	ValueDefaultGet(
	
	){
	
		return this.#ValueDefault;
	
	}
	
	Copy(
	
	){
		
		return new UIComponentDoubleConfigT(
		
			this.ValueDefaultGet( ),
			this.MayBeNegativeGet( ),
			this.MayBePositiveGet( ),
			this.FractionMaxSizeGet( ),
			
			super.PlaceholderConfigGet( ),
			super.TextDataConfigGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentDoubleConfigT;