import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";


class Languages extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        super(props)
        this.state = {
            localeText: loctext,
            modules: AllCommunityModules,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Czech name'),
                    field: 'name_cs',
                    filter: 'agTextColumnFilter',
                },
                {
                    headerName: this.props.t('English name'),
                    field: 'name_en',
                    filter: 'agTextColumnFilter',
                },
                {
                    headerName: this.props.t('Active'),
                    field: 'active',
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                }],
            defaultColDef: {
                flex: 1,
                minWidth: 110,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/languages')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/languages/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('languages', res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t("Languages")}>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}
Languages.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Languages);

