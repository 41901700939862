
import InputInteger from "../inputs/Integer/InputInteger";
import InputIntegerSerializer from "../inputs/Integer/InputIntegerSerializer";
import InputIntegerParser from "../inputs/Integer/InputIntegerParser";

import InputDouble from "../inputs/Double/InputDouble";
import InputDoubleSerializer from "../inputs/Double/InputDoubleSerializer";
import InputDoubleParser from "../inputs/Double/InputDoubleParser";

import InputText from "../inputs/Text/InputText";
import InputTextSerializer from "../inputs/Text/InputTextSerializer";
import InputTextParser from "../inputs/Text/InputTextParser";

import InputSelectBase from "../inputs/Select/Base/InputSelectBase";
import InputSelectBaseSerializer from "../inputs/Select/Base/InputSelectBaseSerializer";
import InputSelectBaseParser from "../inputs/Select/Base/InputSelectBaseParser";

import InputMultiselectBase from "../inputs/Multiselect/Base/InputMultiselectBase";
import InputMultiselectBaseSerializer from "../inputs/Multiselect/Base/InputMultiselectBaseSerializer";
import InputMultiselectBaseParser from "../inputs/Multiselect/Base/InputMultiselectBaseParser";

import InputSelectTextBase from "../inputs/SelectText/Base/InputSelectTextBase";
import InputSelectTextBaseSerializer from "../inputs/SelectText/Base/InputSelectTextBaseSerializer";
import InputSelectTextBaseParser from "../inputs/SelectText/Base/InputSelectTextBaseParser";

import InputDate from "../inputs/Date/InputDate";
import InputDateSerializer from "../inputs/Date/InputDateSerializer";
import InputDateParser from "../inputs/Date/InputDateParser";

import InputDatetime from "../inputs/Datetime/InputDatetime";
import InputDatetimeSerializer from "../inputs/Datetime/InputDatetimeSerializer";
import InputDatetimeParser from "../inputs/Datetime/InputDatetimeParser";

import InputBoolean from "../inputs/Boolean/InputBoolean";
import InputBooleanSerializer from "../inputs/Boolean/InputBooleanSerializer";
import InputBooleanParser from "../inputs/Boolean/InputBooleanParser";

const Input = class Input {
	
	static Create( 
		ModelCell, 
		MetaCell, 
		ValuesOut, 
		HandleChange, 
		Locale, 
		ModelName, 
		ModelSchema,
		CellVisisbleChange,
		Items,
		ValueGet,
		ValueChange,
		RefCb,
		ValuesOutParent,
		Index,
		ItemElementDisable,
		ItemValueGet,
		ItemValueChange,
		ModelSchemaParent,
		PrepareData
	){
		
		let InputV = null;
		
			
		if( ModelCell.column_type === "boolean" ){
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputBooleanParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
			
			InputV = <InputBoolean
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => { 
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema );
							
						}
					} 
					
				}
			>
			
			</InputBoolean>;
			
		} else if( ModelCell.column_type === "integer" ){
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputIntegerParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
			
			if( MetaCell.Prepare ){
				
				MetaCell.Prepare( 
					ValuesOut.get( ModelCell.field ), 
					function( Field ){
						
						ValuesOut.set( Field, ValueGet( Field ) );
						
						return Input.Serialize( Field, ValuesOut, ModelSchema );
	
					}, function( Field, Value ){
						
						console.log( "Input.OnChange", Field, Value, ValueChange );

						ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
	
					}, 
					CellVisisbleChange, 
					Items, 
					ModelSchema,
					PrepareData
				);
				
			}
			
			InputV = <InputInteger
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => { 
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema );
							
						}
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
				regexp_match = { MetaCell.RegExpMatch }
				regexp_validation = { MetaCell.RegExpValidation }
			>
			
			</InputInteger>;
			
		} else if( ModelCell.column_type === "decimal" ){
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputDoubleParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
			
			console.log( ModelCell.field, ModelCell, MetaCell );
			
			InputV = <InputDouble
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => { 
					
						if( ModelSchema === ModelSchemaParent ){
					
							HandleChange( Field, Type, Value );
						
						}
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema, Index, ItemElementDisable, ItemValueGet, ItemValueChange );
							
						}
						
						if( ModelSchema !== ModelSchemaParent ){
					
							HandleChange( Field, Type, Value );
						
						}
						
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
				regexp_match = { MetaCell.RegExpMatch }
				regexp_validation = { MetaCell.RegExpValidation }
			>
			
			</InputDouble>;
			
		} else if( ModelCell.column_type === "text" ){
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputTextParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
			
			if( MetaCell.Prepare ){
				
				MetaCell.Prepare( 
					ValuesOut.get( ModelCell.field ), 
					function( Field ){
						
						ValuesOut.set( Field, ValueGet( Field ) );
						
						return Input.Serialize( Field, ValuesOut, ModelSchema );
	
					}, function( Field, Value ){
						
						console.log( "Input.OnChange", Field, Value, ValueChange );

						ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
	
					}, 
					CellVisisbleChange, 
					Items, 
					ModelSchema,
					PrepareData
				);
				
			}
		
			InputV = <InputText
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => { 
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema );
							
						}
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
				regexp_match = { MetaCell.RegExpMatch }
				regexp_validation = { MetaCell.RegExpValidation }
			>
			
			</InputText>;
	
		} else if( ModelCell.column_type === "multiselect" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Value = InputMultiselectBaseParser( MetaCell.DefaultValue, Options, "//" ); //TODO Joiner
				
				ValuesOut.set( ModelCell.field, Value ); 
				
			}
			
			
			InputV = <InputMultiselectBase
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				values = { ValuesOut.get( ModelCell.field ) }
				field = { ModelCell.field }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => {
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema, PrepareData );
							
						}
					} 
					
				}
				options_object = { Options }
				placeholder = { MetaCell.Placeholder }
				values_out = { ValuesOut }
				options_filter = { MetaCell.OptionsFilter }
			>
				
			</InputMultiselectBase>;
	
		} else if( ModelCell.column_type === "select" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			if( !Options ){
				
				console.log( ModelName, ModelCell.field, ModelCell.model, ModelSchema );
				
				throw new Error( "Options not found" );
				
				alert( "Options not found" );
				
			}
			
			console.log( "Options", ModelName, ModelCell.field, ModelCell.model, ModelSchema, Options );
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Value = InputSelectBaseParser( MetaCell.DefaultValue, Options ); //TODO Joiner
				
				ValuesOut.set( ModelCell.field, Value ); 
				
			}
			
		
			InputV = <InputSelectBase
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				value = { ValuesOut.get( ModelCell.field ) }
				field = { ModelCell.field }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => {
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema, PrepareData );
							
						}
					} 
					
				}
				options_object = { Options }
				placeholder = { MetaCell.Placeholder }
				values_out = { ValuesOut }
				options_filter = { MetaCell.OptionsFilter }
			>
			
			</InputSelectBase>;
	
		} else if( ModelCell.column_type === "select_text" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			if( !Options ){
				
				console.log( ModelName, ModelCell.field, ModelCell.model, ModelSchema );
				
				throw new Error( "Options not found" );
				
				alert( "Options not found" );
				
			}
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputSelectTextBaseParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
		
			InputV = <InputSelectTextBase
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = {
				
					( Field, Type, Value ) => {
						
						if( ModelSchema === ModelSchemaParent ){
					
							HandleChange( Field, Type, Value );
						
						}
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema, Index, ItemElementDisable, ItemValueGet, ItemValueChange );
							
						}
						
						if( ModelSchema !== ModelSchemaParent ){
					
							HandleChange( Field, Type, Value );
						
						}
						
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
				options_object = { Options }
				regexp_match = { MetaCell.RegExpMatch }
				regexp_validation = { MetaCell.RegExpValidation }
				values_out = { ValuesOut }
				values_out_parent = { ValuesOutParent }
				options_filter = { MetaCell.OptionsFilter }
			>
			
			</InputSelectTextBase>;
	
		} else if( ModelCell.column_type === "date" ){
		
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputDateParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
		
			InputV = <InputDate
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => {
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema, PrepareData );
							
						}
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
			>
			
			</InputDate>;
	
		} else if( ModelCell.column_type === "datetime" ){
			
			if( !ValuesOut.has( ModelCell.field ) ){
				
				let Data = InputDatetimeParser( MetaCell.DefaultValue );
				
				ValuesOut.set( ModelCell.field, Data ); 
				
			}
		
			InputV = <InputDatetime
				ref_cb = { ( Ref ) => RefCb( Ref ) }
				field = { ModelCell.field }
				value = { ValuesOut.get( ModelCell.field ) }
				disabled = { !MetaCell.Editable }
				on_change_cb = { 
				
					( Field, Type, Value ) => {
					
						HandleChange( Field, Type, Value );
						
						if( MetaCell.OnChange ) {
							
							MetaCell.OnChange( Value, function( Field ){
								
								ValuesOut.set( Field, ValueGet( Field ) );
								
								return Input.Serialize( Field, ValuesOut, ModelSchema );
			
							}, function( Field, Value ){
								
								console.log( "Input.OnChange", Field, Value, ValueChange );
		
								ValueChange( Field, Input.Parse( Field, Value, ModelSchemaParent ) );
			
							}, CellVisisbleChange, Items, ModelSchema );
							
						}
					} 
					
				}
				placeholder = { MetaCell.Placeholder }
			>
			
			</InputDatetime>;
	
		} else {
			
			console.log( "Unknown input type", ModelCell.column_type );
			
			throw new Error( "Unknown input type" );
			
		}
		
		
		return InputV;
		
	}
	
	
	static Reset( Field, DefaultValue, ValuesOut, ModelSchema, InputRef, MetaCell ){
		
		return Input.Set( Field, DefaultValue, ValuesOut, ModelSchema, InputRef, MetaCell );
		
	}
	
	static Set( Field, Value, ValuesOut, ModelSchema, InputRef, MetaCell ){
		
		let ModelCell = ModelSchema.model_schema_object.get( Field );
		
		let ModelName = ModelSchema.model_name;
		
			
		if( ModelCell.column_type === "multiselect" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			//let Data = InputMultiselectBaseParser( Value, Options, "//" ); //TODO Joiner
			
			ValuesOut.set( Field, Value ); 
			
			
			if( InputRef ){
			
				//InputRef.Input.placeholder = ( Data !== null ) ? Data.label : MetaCell.Placeholder;
				
				//TODO Set InputRef.Input.Select
			
			}
			
		} else if( ModelCell.column_type === "select" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			if( !Options ){
				
				console.log( ModelName, ModelCell.field, ModelCell.model, ModelSchema );
				
				throw new Error( "Options not found" );
				
				alert( "Options not found" );
				
			}
			
			let Data = InputSelectBaseParser( Value, Options );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.Input.placeholder = ( Data !== null ) ? Data.label : MetaCell.Placeholder;
				
				//TODO Set InputRef.Input.Select
			
			}
			
		} else if( ModelCell.column_type === "select_text" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			if( !Options ){
				
				console.log( ModelName, ModelCell.field, ModelCell.model, ModelSchema );
				
				throw new Error( "Options not found" );
				
				alert( "Options not found" );
				
			}
			
			let Data = InputSelectTextBaseParser( Value, Options );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "text" ){
			
			let Data = InputTextParser( Value );
			
			ValuesOut.set( Field, Data );
			
			console.log( Value, Data, InputRef );
			
			if( InputRef ){
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "decimal" ){
			
			let Data = InputDoubleParser( Value );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "integer" ){
			
			let Data = InputIntegerParser( Value );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "datetime" ){
			
			let Data = InputDatetimeParser( Value );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.placeholder = MetaCell.Placeholder;
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "date" ){
			
			let Data = InputDateParser( Value );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.placeholder = MetaCell.Placeholder;
			
				InputRef.value = Data;
			
			}
			
		} else if( ModelCell.column_type === "boolean" ){
			
			let Data = InputBooleanParser( Value );
			
			ValuesOut.set( Field, Data );
			
			if( InputRef ){
			
				InputRef.checked = Data;
			
			}
			
		} else {
		
			ValuesOut.set( Field, Value );
		
		}
		
	}
	
	static Get( Field, ValuesOut, ModelSchema ){
		
		let ModelSchemaObject = ModelSchema.model_schema_object;
		
		let ModelCell = ModelSchemaObject.get( Field );
		
		
		if( ModelCell.column_type === "select" ){
			
			return InputSelectBaseSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "multiselect" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelSchema.model_name ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			return InputMultiselectBaseSerializer( ValuesOut.get( Field ), Options, "//" );
			
		} else if( ModelCell.column_type === "select_text" ){
			
			return InputSelectTextBaseSerializer( ValuesOut.get( Field ) );
			
		} if( ModelCell.column_type === "text" ){
			
			return InputTextSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "decimal" ){
			
			return InputDoubleSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "integer" ){
			
			return InputIntegerSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "boolean" ){
			
			return InputBooleanSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "datetime" ){
			
			return InputDatetimeSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "date" ){
			
			return InputDateSerializer( ValuesOut.get( Field ) );
			
		} else {
		
			console.log( "Error invalid column_type" );
			
			throw new Error( "Error invalid column_type" );
			
		}
		
	}
	
	static Serialize( Field, ValuesOut, ModelSchema ){
		
		let ModelSchemaObject = ModelSchema.model_schema_object;
		
		let ModelCell = ModelSchemaObject.get( Field );
		
		if( ModelCell.column_type === "select" ){
			
			return InputSelectBaseSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "multiselect" ){
			
			return InputMultiselectBaseSerializer( ValuesOut.get( Field ), "//" ); //TODO Joiner
			
		} else if( ModelCell.column_type === "select_text" ){
			
			return InputSelectTextBaseSerializer( ValuesOut.get( Field ) );
			
		} if( ModelCell.column_type === "text" ){
			
			return InputTextSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "decimal" ){
			
			return InputDoubleSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "integer" ){
			
			return InputIntegerSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "boolean" ){
			
			return InputBooleanSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "datetime" ){
			
			return InputDatetimeSerializer( ValuesOut.get( Field ) );
			
		} else if( ModelCell.column_type === "date" ){
			
			return InputDateSerializer( ValuesOut.get( Field ) );
			
		} else {
		
			console.log( "Error invalid column_type" );
			
			throw new Error( "Error invalid column_type" );
			
		}
		
	}
	
	static Parse( Field, Value, ModelSchema ){
		
		let ModelSchemaObject = ModelSchema.model_schema_object;
		
		let ModelName = ModelSchema.model_name;
		
		
		let ModelCell = ModelSchemaObject.get( Field );
		
		if( ModelCell.column_type === "select" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			return InputSelectBaseParser( Value, Options );
			
		} else if( ModelCell.column_type === "multiselect" ){
			
			let Options = null;
			
			if( ModelCell.model === "custom" ){
				
				Options = ModelSchema.custom_options_object.get( ModelName ).get( ModelCell.field );
				
			} else {
				
				Options = ModelSchema.options_object.get( ModelCell.model );
				
			}
			
			return InputMultiselectBaseParser( Value, Options, "//" ); //TODO Joiner
			
		} else if( ModelCell.column_type === "select_text" ){
			
			return InputSelectTextBaseParser( Value );
			
		} if( ModelCell.column_type === "text" ){
			
			return InputTextParser( Value );
			
		} else if( ModelCell.column_type === "decimal" ){
			
			return InputDoubleParser( Value );
			
		} else if( ModelCell.column_type === "integer" ){
			
			return InputIntegerParser( Value );
			
		} else if( ModelCell.column_type === "boolean" ){
			
			return InputBooleanParser( Value );
			
		} else if( ModelCell.column_type === "datetime" ){
			
			return InputDatetimeParser( Value );
			
		} else if( ModelCell.column_type === "date" ){
			
			return InputDateParser( Value );
			
		} else {
		
			console.log( "Error invalid column_type" );
			
			throw new Error( "Error invalid column_type" );
			
		}
		
	}
	
	static Check( Field, MetaCell, Value, ModelSchema, Values ){
		
		console.log( "Check", MetaCell, Value );
		
		
		let IsRequired = MetaCell.IsRequiredFn ? MetaCell.IsRequiredFn( Values ) : MetaCell.IsRequired;
			
		
			
		if( !MetaCell ){
			
			throw new Error( "Not processed field" );
			
		}
		
		if( MetaCell.IsCustom === true ){
			
			return Value !== "";
		
		}
		
		
		let ModelSchemaObject = ModelSchema.model_schema_object;
		
		let ModelCell = ModelSchemaObject.get( Field );
		
		
		if( IsRequired === true ){
			
			if( ModelCell.column_type === "select" ){
				
				return Value !== null;
			
			} else if( ModelCell.column_type === "multiselect" ){
			
				return ( Value.size !== 0 );
				
			} else if( ModelCell.column_type === "select_text" ){
				
				if( !MetaCell.RegExpValidation ){
					
					return Value !== "";
					
				}
			
				return ( new RegExp( MetaCell.RegExpValidation, "g" ) ).test( Value );
				
			} else if( ModelCell.column_type === "text" ){
				
				if( !MetaCell.RegExpValidation ){
					
					return Value !== "";
					
				}
			
				return ( new RegExp( MetaCell.RegExpValidation, "g" ) ).test( Value );
				
			} else if( ModelCell.column_type === "decimal" ){
				
				console.log( "CheckDecimal", Value );
				
				if( !MetaCell.RegExpValidation ){
					
					return Value !== 0;
					
				}
				
				return ( new RegExp( MetaCell.RegExpValidation, "g" ) ).test( Value.toFixed( 8 ) );
				
			} else if( ModelCell.column_type === "integer" ){
				
				console.log( "CheckDecimal", Value );
				
				if( !MetaCell.RegExpValidation ){
					
					return Value !== 0;
					
				}
				
				return ( new RegExp( MetaCell.RegExpValidation, "g" ) ).test( Value.toString( ) );
				
			} else if( ModelCell.column_type === "boolean" ){
			
				return true;
				
			} else if( ModelCell.column_type === "datetime" ){
				
				console.log( "CheckDatetime", ModelCell.field, Value );
				
				return Value !== null;
				
			} else if( ModelCell.column_type === "date" ){
				
				console.log( "CheckDate", ModelCell.field, Value );
				
				return Value !== null;
				
			} else {
				
				throw new Error( "Invalid field type" );
				
			}
			
		} else {
			
			if(
				( ModelCell.column_type === "integer" ) ||
				( ModelCell.column_type === "decimal" ) ||
				( ModelCell.column_type === "text" ) ||
				( ModelCell.column_type === "select_text" )
			){
				
				let StringValue = null;
				
				if( ModelCell.column_type === "decimal" ){
					
					if( Value !== null ){
					
						StringValue = Value.toFixed( 8 );
					
					} else {
						
						StringValue = "0";
						
					}
					
				} else {
					
					if( Value !== null ){
				
						StringValue = Value.toString( );
						
					} else {
						
						StringValue = "";
						
					}
				
				}
			
				if( ( StringValue !== "" ) ){
					
					if( !MetaCell.RegExpValidation ){
						
						return StringValue !== "";
						
					}
				
					return ( new RegExp( MetaCell.RegExpValidation, "g" ) ).test( StringValue );
					
				}
				
			}
		
			return true;
		
		}
		
	}
	
};

export default Input;