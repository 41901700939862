import React, { Component } from 'react';

export default class CustomPinnedRowRenderer extends Component {
    render() {
        let val = this.props.value;
        if(this.props.colDef.valueFormatter) {
            val = this.props.colDef.valueFormatter(this.props);
        }
        return <span style={this.props.style}>{val}</span>;
    }
}