import React, { Component } from "react";

import GenerateDocumentsTab from "./generatedocuments.tsx";
import PrevGeneratedDocumentsTab from "./prevgenerateddocuments.tsx";
import UploadedDocumentsTab from "./uploadeddocuments.tsx";
import UploadTab from "./upload.tsx";
import TabPill from "../common/tab/tabpill";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import DragAndDrop from "../common/draganddrop";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
class LoadingCard extends Component {
    getTabs() {

        var active_generate_tab=true;
        var active_uploaded_tab=false;

        let tabs= {
            





        }
		
		let activeFirst = true;
		
		if( this.props.hide_generate !== true ){
			
			tabs.generatedocuments = {
                active: activeFirst,//active_generate_tab,
                name: this.props.t('Generate documents'),
                component: GenerateDocumentsTab,
            };
			
			activeFirst = false;
			
		}

        if (this.props.type==='single' || this.props.type==='outinvoice_single') {

            tabs.documents_generated={
                active: activeFirst,//false,
                name: this.props.t('Previously generated'),
                component: PrevGeneratedDocumentsTab,

            };

			activeFirst = false;

            tabs.documents_uploaded={
                active: activeFirst,//active_uploaded_tab,
                name: this.props.t('Uploaded'),
                component: UploadedDocumentsTab,
            };
			
			activeFirst = false;

            tabs.upload={
                active: activeFirst,//false,
                name: this.props.t('Upload'),
                component: UploadTab,

            }
			
			activeFirst = false;
			
        }

        if (this.props.type==='supplylist_single') {

            tabs.documents_generated = {
                active: activeFirst,//false,
                name: this.props.t('Previously generated'),
                component: PrevGeneratedDocumentsTab,

            };
			
			activeFirst = false;
			
        }


        return tabs;
    }

    state = {
        loading_number:null,
        tmp:null,
        files: [],

        tabs: {}
    };


    getActiveTab() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        let newTabs = tabs;

        let activeTab=null;
        keys.forEach((key) => {
            if (newTabs[key].active) activeTab=newTabs[key].name
        });
        return activeTab
    }
	
	componentDidMount( ){
	
		let tabs = this.getTabs();

		this.setState({loading_number:this.props.loading_number});
		this.setState({tmp:this.props.tmp});
		this.setState({tabs:tabs});


		if (this.props.tab=='uploaded') {
			this.enableTab('documents_uploaded');
		} else {


			if (this.getActiveTab() === null) {
			
				if( tabs.generatedocuments ){

					this.enableTab('generatedocuments');
				
				} else if( tabs.documents_generated ){

					this.enableTab('documents_generated');
				
				} else if( tabs.documents_uploaded ){

					this.enableTab('documents_uploaded');
				
				} else if( tabs.upload ){

					this.enableTab('upload');
				
				}
			}
		}
	
	}

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
		
			let tabs = this.getTabs();

            this.setState({loading_number:this.props.loading_number});
            this.setState({tmp:this.props.tmp});
            this.setState({tabs:tabs});


            if (this.props.tab=='uploaded') {
                this.enableTab('documents_uploaded');
            } else {


                if (this.getActiveTab() === null) {
				
					if( tabs.generatedocuments ){

						this.enableTab('generatedocuments');
					
					} else if( tabs.documents_generated ){

						this.enableTab('documents_generated');
					
					} else if( tabs.documents_uploaded ){

						this.enableTab('documents_uploaded');
					
					} else if( tabs.upload ){

						this.enableTab('upload');
					
					}
                }
            }


        }


        if (prevProps.show===true && this.props.show===false) {
            this.setState({tabs:this.getTabs()});
        }

    }

    enableTab(tabName: string) {
        const { tabs } = this.state;

        if (tabs[tabName] === undefined) {
            return true;
        }

        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
        });

        newTabs[tabName].active = true;
        this.setState({ tabs: newTabs });
    }


    shouldComponentUpdate( nextProps, nextState ){

        if (this.props.show===true && nextProps.show===false) {
            console.log('closing');
            const { tabs } = this.state;
            const keys = Object.keys(tabs);
            let newTabs = tabs;

            keys.forEach((key) => {
                newTabs[key].active = false;
                newTabs[key].name = tabs[key].name;
            });
        }



        if (
            (nextProps.show===true && this.props.show===false) ||
            (nextProps.show===true && this.props.show===true)
        ) {




            return true
        } else {
            return false
        }
    }

    renderTabContent() {
        const { tabs } = this.state;
        const keys = Object.keys(tabs);
        return keys.map((tabName, index) => {
            const Component = tabs[tabName].component;
            return (
                <div
                    className={`tab-pane ${tabs[tabName].active ? "active" : "inactive"}`}
                    id={tabName}
                    key={index}
                >
                    <Component from_sale={this.props.from_sale} loading_number={this.state.loading_number} type={this.props.type} company_name={this.props.company_name} show={this.props.show} tmp={Date.now()} />
                </div>
            );
        });
    }

    handleDrop = (files) => {

        if (this.props.type==='single') {
            if (files.length > 5) {
                alert(this.props.t('Please upload max 5 files at a time'));
                return false;
            }
        }

        if (this.props.type==='outinvoice_single' || this.props.type==='supplylist_single') {
            if (files.length > 1) {
                alert(this.props.t('Please upload max 1 files at a time'));
                return false;
            }
        }

        if (files.length>0) {



            const formData = new FormData();
            if (files[0]) formData.append('file1', files[0]);
            if (files[1]) formData.append('file2', files[1]);
            if (files[2]) formData.append('file3', files[2]);
            if (files[3]) formData.append('file4', files[3]);
            if (files[4]) formData.append('file5', files[4]);




var model='loadings';
if (this.props.type==='outinvoice_single') model='outinvoices';
if (this.props.type==='supplylist_single') model='supplylists';

            HttpService.post(UrlService.apiDomain()+'api/attachments/'+model+'/upload/other/'+this.props.loading_number, formData)
                .then(res => {

                    if (res.data===null) {
                        alert("File import error");
                        return false;
                    }
                    if (res.data.result==='failed') {
                        alert(res.data.message);
                        console.log(res);

                    } else {
                        if (res.data.message==='success') {
                            alert(this.props.t('Files uploaded successfully'));
                            this.setState({tmp:Date.now()});
                            console.log('Successfully updated');

                        } else {
                            alert("File import error");
                        }


                    }
                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                })


        }


    }

    render() {





        const { oldtabs } = this.state;
        var tabs=this.getTabs();
        var activetab=this.getActiveTab();


        const keys = Object.keys(tabs);
        let newTabs = tabs;

        keys.forEach((key) => {
            newTabs[key].active = false;
            newTabs[key].name = tabs[key].name;
            newTabs[key].component = tabs[key].component;
            if (tabs[key].name===activetab)  newTabs[key].active = true;
        });

        tabs=newTabs;


        return (

            <DragAndDrop handleDrop={this.handleDrop}>
                <div className="card" style={{'width':'100%'}}>
                    <div className="card-header p-2">
                        <TabPill
                            tabs={tabs}
                            handleEnableTab={(tabName) => this.enableTab(tabName)}
                        />
                    </div>

                    <div className="card-body">
                        <div className="tab-content">{this.renderTabContent()}</div>
                    </div>
                </div>
            </DragAndDrop>

        );
    }
}
LoadingCard.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingCard);
