import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select, { components } from 'react-select';

interface Props {
    onAdd: (data) => void;
}

class ContractAdd extends Component<Props> {
    blankOption={value: "", label:this.props.t('Choose')};
    state = { contract_number:"", address:"", deadline:"", company: "", date: "",qty: "",attachment: "", company_value: {value: "", label:this.props.t('Company')}};
    companiesData=JSON.parse(localStorage.getItem('companies'));
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();




        const fileInput = document.querySelector('#attachment') ;
        var files=fileInput.files;
/*
        if( files.length===0) {
            alert(this.props.t('Prosím, nahrajte sou'));
            return false;
        }
*/
        let files_ok=true;
        let allowed_exts=['jpg','jpeg','png','gif','bmp','tiff', 'pdf'];


        Array.from(files).forEach(e => {
            if(!allowed_exts.includes(e.name.split('.')[e.name.split('.').length - 1].toLowerCase())) files_ok=false;
        });


        if (!files_ok) {
            alert(this.props.t('Špatný formát skenu'));
            return false;
        }


        const { contract_number, company, date,qty, address, deadline} = this.state;
        this.props.onAdd({contract_number, company, date,qty, address, deadline, files});
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
            this.setState({ contract_number:"", address:"", deadline:"", company: "", date: "",qty: "",attachment: "", company_value: {value: "", label:this.props.t('Company')}});
        }

    }


    render() {


        var companies = [this.blankOption];

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        this.companiesData.map((e)=>{
            if (e.company_type==1) return false;
            companies.push({value: e.id, label:e['name']});
        });

        const {contract_number, company, date,qty, attachment, address, deadline} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Company')}</label>
                                <Select
                                    name="company"
                                    onChange={(event) => {
                                        this.setState({company: event.value, company_value:event});
                                       }}
                                    value={this.state.company_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={companies} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Date')}</label>
                                <Datetime
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={date}
                                    inputProps={{required:true, placeholder:this.props.t('Date')}}
                                    onChange={(event) => {

                                        if (typeof event==="object") {
                                            this.setState({date:event.format("DD.MM.YYYY")});
                                        } else {
                                            this.setState({date:event});
                                        }


                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Quantity')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={qty}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ qty: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Deadline')}</label>
                                <Datetime
                                    timeFormat={false}
                                    locale={locale}
                                    dateFormat="DD.MM.YYYY"
                                    value={deadline}
                                    inputProps={{required:true, placeholder:this.props.t('Deadline')}}
                                    onChange={(event) => {

                                        if (typeof event==="object") {
                                            this.setState({deadline:event.format("DD.MM.YYYY")});
                                        } else {
                                            this.setState({deadline:event});
                                        }


                                    }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Contract number')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={contract_number}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ contract_number: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Delivery address')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={address}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ address: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-3">
                        <button className="btn btn-success">{this.props.t('Save')}</button>
                        </div>
                        <div className="col-9">
                            <div className="form-group">

                                <input id="attachment" name="attachment" type="file" />
                            </div>
                        </div>


                    </div>




                </div>

            </form>
        );
    }
}
ContractAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ContractAdd);

