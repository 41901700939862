import React, { Component } from "react";
import "../modal/_style.scss";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
    title: string;
    visibility: boolean;
    size: string;

}

class Loading extends Component<Props> {
    render() {
        const { title, visibility, children, size } = this.props;
        return (
            <React.Fragment>
                <div
                    className={`modal fade ${visibility ? "show" : "hide"}`}
                    id="modal-default"
                >
                    <div className={`modal-dialog modal-${size}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{title}</h4>


                            </div>
                            <div className="modal-body">{children}</div>
                            <div className="modal-footer justify-content-between">

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal-backdrop fade ${visibility ? "show" : "hide"}`}
                ></div>
            </React.Fragment>
        );
    }
}
Loading.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Loading);

