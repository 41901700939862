"use strict";


import HTTPHeaderT from "../../../../Type/HTTP/Header/HTTPHeaderT.mjs";

import HTTPHeadersT from "../../../../Type/HTTP/Header/HTTPHeadersT.mjs";

import APIModelCallRequestT from "../../../../Type/API/Model/Call/Request/APIModelCallRequestT.mjs";

import APIModelCallResponseT from "../../../../Type/API/Model/Call/Response/APIModelCallResponseT.mjs";

import APIModelSchemaRequestT from "../../../../Type/API/Model/Schema/Request/APIModelSchemaRequestT.mjs";

import APIModelSchemaResponseT from "../../../../Type/API/Model/Schema/Response/APIModelSchemaResponseT.mjs";

import APIModelInsertRequestT from "../../../../Type/API/Model/Insert/Request/APIModelInsertRequestT.mjs";

import APIModelInsertResponseT from "../../../../Type/API/Model/Insert/Response/APIModelInsertResponseT.mjs";


const APIClientAbstractT = class APIClientAbstractT {
	
	constructor(

	){
	
	}
	
	ModelCall(
		APIModelCallRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelCallRequest instanceof APIModelCallRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ModelSchema(
		APIModelSchemaRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelSchemaRequest instanceof APIModelSchemaRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ModelInsert(
		APIModelInsertRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelInsertRequest instanceof APIModelInsertRequestT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
};


export default APIClientAbstractT;