import React, { Component } from "react";

interface Props {
  title: String;

}

class Content extends Component<Props> {
  render() {
    const { title } = this.props;
    const { hideTitle } = this.props;

    if (hideTitle) {
      return (
          <div className="content-wrapper">
            <section className="content" style={{height: '80vh'}}>{this.props.children}</section>
          </div>
      );
    } else {
      return (
          <div className="content-wrapper">

            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>{title}</h1>
                  </div>

                </div>
              </div>
            </section>

            <section className="content" style={{height: '80vh'}}>{this.props.children}</section>
          </div>
      );
    }

  }
}

export default Content;
