

const CellContainer = function( ClassName, Children ){
		
	return (
					
		<div className={ ClassName }>
		
			{ Children }
		
		</div>			
				
	);
	
};

export default CellContainer;

