"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentButtonConfigT from "../../../Type/Component/Button/ComponentButtonConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT,
	ComponentT as ComponentT 
} from "../ComponentInternalT.mjs";


import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentButtonT = class ComponentButtonT extends ComponentBaseT {

	#ComponentButtonConfig = null;
	
	#ClickCb = null;
	
	constructor(
		GUIElement,
		ComponentButtonConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		if( ( ComponentButtonConfig instanceof ComponentButtonConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentButtonConfig
		);
		
		
		this.#ComponentButtonConfig = ComponentButtonConfig;
		
	}
	
	Update( ){
		
		super.Update( );
		
	}
	
	#OnClick( 
		Ev
	){
		
		//TODO
		
		console.log( "ComponentButtonT.#OnClick", Ev );
		
		
		if( this.#ClickCb !== null ){
			
			let Name = this.#ComponentButtonConfig.NameGet( );
		
			this.#ClickCb(
				Name
			);
		
		}
		
	}
	
	Prepare(
		PrepareMap
	){
		
	}
	
	Listen( 
		EventBucket
	){
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		
		if( EventBucket !== null ){
			
			let Name = this.#ComponentButtonConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
		
				if( EventMap.IsExists( "ButtonClick" ) === true ){
					
					this.#ClickCb = EventMap.Get( "ButtonClick" );
					
				}
			
			}
		
		}
		
		
		this.EventAppend( 
			"click",
			this.#OnClick.bind( this )
		);
		
	}
				
};


export default ComponentButtonT;