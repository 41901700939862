"use strict";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import UIComponentAbstractT from "../../../../../../../Interface/UI/Component/_/UIComponentAbstractT.mjs";

import UIComponentLayoutHT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


const GTSUIComponentFormSecondaryLineElementT = class GTSUIComponentFormSecondaryLineElementT extends UIComponentLayoutElementT {
	
	#UIComponent = null;
	#Width = null;
	
	constructor( 
		UIComponentInner,
		Width
	){
		
		let UIComponentLayoutElementConfig = new UIComponentLayoutElementConfigT(
			"0",
			"0",
			"auto",
			"auto",
			
			new UIStateMapT( [
		
				new UIStateT(
					"Default",
					"UIComponentLayoutElementT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
		);
		
	
		super( 
			new UIElementT( ),
			UIComponentLayoutElementConfig
		);

		
		this.ComponentInnerSet( UIComponentInner );
		
		this.WidthSet( Width );
		
	}
	
	WidthSet(
		Width
	){
		
		if( typeof( Width ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Width = Width;
		
	}
	
	WidthGet(
	
	){
		
		return this.#Width;
		
	}
	
	ComponentInnerSet(
		UIComponentInner
	){
		
		if( ( UIComponentInner instanceof UIComponentAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super.DataSet( UIComponentInner );
		
	}
	
	ComponentInnerGet(
	
	){

		return super.DataGet( );
	
	}
	
};


export default GTSUIComponentFormSecondaryLineElementT;