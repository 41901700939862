"use strict";


import HTTPClientT from "../../../Infrastructure/Network/HTTP/Client/HTTPClientT.mjs";

import APIClientAbstractT from "../../../Procedure/API/Client/_/APIClientAbstractT.mjs";

import HTTPHeaderT from "../../../Type/HTTP/Header/HTTPHeaderT.mjs";

import HTTPHeadersT from "../../../Type/HTTP/Header/HTTPHeadersT.mjs";

import APIModelCallRequestT from "../../../Type/API/Model/Call/Request/APIModelCallRequestT.mjs";

import APIModelCallResponseT from "../../../Type/API/Model/Call/Response/APIModelCallResponseT.mjs";

import APIModelSchemaRequestT from "../../../Type/API/Model/Schema/Request/APIModelSchemaRequestT.mjs";

import APIModelSchemaResponseT from "../../../Type/API/Model/Schema/Response/APIModelSchemaResponseT.mjs";

import APIModelInsertRequestT from "../../../Type/API/Model/Insert/Request/APIModelInsertRequestT.mjs";

import APIModelInsertResponseT from "../../../Type/API/Model/Insert/Response/APIModelInsertResponseT.mjs";


const APIClientT = class APIClientT extends APIClientAbstractT {

	#APIDomain = null;
	#AuthToken = null;
	
	#TextEncoder = null;
	#TextDecoder = null;
	
	#HTTPClient = null;
	
	constructor(
		APIDomain,
		AuthToken,
		HTTPClient
	){
		
		if( typeof( APIDomain ) !== "string" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		if( AuthToken !== null ){
		
			if( typeof( AuthToken ) !== "string" ){
				
				throw new Error( "Invalid argument" );
				
			}
		
		}
		
		if( ( HTTPClient instanceof HTTPClientT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		super( );
		
		
		this.#APIDomain = APIDomain;
		
		this.#AuthToken = AuthToken;
		
		this.#TextDecoder = new TextDecoder( );
		
		this.#TextEncoder = new TextEncoder( );
		
		this.#HTTPClient = HTTPClient;
		
	
	}
	
	ModelCall(
		APIModelCallRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelCallRequest instanceof APIModelCallRequestT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		const Method = "POST";
		
		const URL = this.#APIDomain + "/" + "api/model/call";
		
		
		const HeadersArray = [
			new HTTPHeaderT( "Content-Type", "application/json" )
		];
		
		if( this.#AuthToken !== null ){
			
			HeadersArray.push(
				new HTTPHeaderT( "Authorization", "Bearer " + this.#AuthToken )
			);
			
		}
		
		const Headers = new HTTPHeadersT( HeadersArray );
		
		const RequestData = this.#TextEncoder.encode( JSON.stringify( { data: APIModelCallRequest.ToJSON( ) } ) );

		
		this.#HTTPClient.Request(
		
			Method,
			URL,
			Headers,
			RequestData,
		
			function( StatusCode, StatusMessage, Headers, ResponseData ){
				
				console.log( StatusCode, StatusMessage, Headers, ResponseData );
				
				if( StatusCode !== 200 ){
					
					ErrorCb( new Error( StatusMessage ) );
					
					return;
					
				}
				
				
				if( ( ResponseData instanceof Uint8Array ) === false ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}
				
				
				let JSONText = this.#TextDecoder.decode( ResponseData );
				
				
				let JSONData = JSON.parse( JSONText );
				
				
				if( JSONData.success === false ){
					
					ErrorCb( JSONData.message );
					
					return;
					
				}
				
				if( typeof( JSONData.data ) !== "object" ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}

			
				SuccessCb( APIModelCallResponseT.FromJSON( JSONData.data ) );
				
			}.bind( this ),
			
		
			function( Err ) {
	
				ErrorCb( Err );
		
			}.bind( this )
			
		);
		
	}
	
	ModelSchema(
		APIModelSchemaRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelSchemaRequest instanceof APIModelSchemaRequestT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		const Method = "POST";
		
		const URL = this.#APIDomain + "/" + "api/model/schema";
		
		
		const HeadersArray = [
			new HTTPHeaderT( "Content-Type", "application/json" )
		];
		
		if( this.#AuthToken !== null ){
			
			HeadersArray.push(
				new HTTPHeaderT( "Authorization", "Bearer " + this.#AuthToken )
			);
			
		}
		
		const Headers = new HTTPHeadersT( HeadersArray );
		
		const RequestData = this.#TextEncoder.encode( JSON.stringify( { data: APIModelSchemaRequest.ToJSON( ) } ) );

		
		this.#HTTPClient.Request(
		
			Method,
			URL,
			Headers,
			RequestData,
		
			function( StatusCode, StatusMessage, Headers, ResponseData ){
				
				console.log( StatusCode, StatusMessage, Headers, ResponseData );
				
				if( StatusCode !== 200 ){
					
					ErrorCb( new Error( StatusMessage ) );
					
					return;
					
				}
				
				
				if( ( ResponseData instanceof Uint8Array ) === false ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}
				
				
				let JSONText = this.#TextDecoder.decode( ResponseData );
				
				
				let JSONData = JSON.parse( JSONText );
				
				
				if( JSONData.success === false ){
					
					ErrorCb( JSONData.message );
					
					return;
					
				}
				
				if( typeof( JSONData.data ) !== "object" ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}

			
				SuccessCb( APIModelSchemaResponseT.FromJSON( JSONData.data ) );
				
			}.bind( this ),
			
		
			function( Err ) {
	
				ErrorCb( Err );
		
			}.bind( this )
			
		);
		
	}
	
	ModelInsert(
		APIModelInsertRequest,
		
		SuccessCb,
		ErrorCb
	){
		
		if( ( APIModelInsertRequest instanceof APIModelInsertRequestT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( SuccessCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( typeof( ErrorCb ) !== "function" ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		const Method = "POST";
		
		const URL = this.#APIDomain + "/" + "api/model/insert";
		
		
		const HeadersArray = [
			new HTTPHeaderT( "Content-Type", "application/json" )
		];
		
		if( this.#AuthToken !== null ){
			
			HeadersArray.push(
				new HTTPHeaderT( "Authorization", "Bearer " + this.#AuthToken )
			);
			
		}
		
		const Headers = new HTTPHeadersT( HeadersArray );
		
		const RequestData = this.#TextEncoder.encode( JSON.stringify( { data: APIModelInsertRequest.ToJSON( ) } ) );

		
		this.#HTTPClient.Request(
		
			Method,
			URL,
			Headers,
			RequestData,
		
			function( StatusCode, StatusMessage, Headers, ResponseData ){
				
				console.log( StatusCode, StatusMessage, Headers, ResponseData );
				
				if( StatusCode !== 200 ){
					
					ErrorCb( new Error( StatusMessage ) );
					
					return;
					
				}
				
				
				if( ( ResponseData instanceof Uint8Array ) === false ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}
				
				
				let JSONText = this.#TextDecoder.decode( ResponseData );
				
				
				let JSONData = JSON.parse( JSONText );
				
				
				if( JSONData.success === false ){
					
					ErrorCb( JSONData.message );
					
					return;
					
				}
				
				if( typeof( JSONData.data ) !== "object" ){
					
					ErrorCb( new Error( "Invalid data format" ) );
					
					return;
					
				}

			
				SuccessCb( APIModelInsertResponseT.FromJSON( JSONData.data ) );
				
			}.bind( this ),
			
		
			function( Err ) {
	
				ErrorCb( Err );
		
			}.bind( this )
			
		);
		
	}
	
};


export default APIClientT;