"use strict";


import APIModelInsertRequestAbstractT from "../_/APIModelInsertRequestAbstractT.mjs";


const APIModelInsertRequestChildrenListT = class APIModelInsertRequestChildrenListT {
	
	#List = null;
	
	constructor(
		APIModelInsertRequestChildrenList
	){
		
		if( ( APIModelInsertRequestChildrenList instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelInsertRequestChildrenList.length;
			I++
		){

			if( ( APIModelInsertRequestChildrenList[ I ] instanceof APIModelInsertRequestAbstractT ) === false ){
			
				throw new Error( "Argument" );
			
			}
		
		}

		
		this.#List = new Array( );
		
		
		for(
			let I = 0;
			I < APIModelInsertRequestChildrenList.length;
			I++
		){
			
			let APIModelInsertRequestChild = APIModelInsertRequestChildrenList[ I ];
			
			this.Set( I, APIModelInsertRequestChild );
		
		}
		
	}
	
	Count(
	
	){
		
		return this.#List.length;
		
	}
	
	Set( 
		Index,
		APIModelInsertRequestChild
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( APIModelInsertRequestChild instanceof APIModelInsertRequestAbstractT ) === false ){
			
			throw new Error( "Argument" );
		
		}
		
		if( Index > this.#List.length ){
				
			throw new Error( "Index" );
			
		}

		
		this.#List[ Index ] = APIModelInsertRequestChild;
		
	}
	
	Remove(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#List.length ){
				
			throw new Error( "Index" );
			
		}
		
	
		return this.#List.splice( Index, 1 );
	
	}
	
	Get(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( Index >= this.#List.length ){
				
			throw new Error( "Index" );
			
		}
		
	
		return this.#List[ Index ];
	
	}
	
	IsExists(
		Index
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return ( Index < this.#List.length );
	
	}
	
};


export default APIModelInsertRequestChildrenListT;