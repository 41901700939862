"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";

import ComponentLayoutElementConfigT from "./ComponentLayoutElementConfigT.mjs";


const ComponentLayoutHDynamicConfigT = class ComponentLayoutHDynamicConfigT extends ComponentBaseConfigT {

	#ComponentLayoutElementConfig = null;

	constructor(
		ComponentLayoutElementConfig,
		ClassName = null,
		StyleMap = null,
		Children = null,
		Name = null,
		Id = null
	){
		
		super(
			ClassName,
			StyleMap,
			Children,
			Name,
			Id
		);
		
		this.ComponentLayoutElementConfigSet( ComponentLayoutElementConfig );
	
	}
	
	ComponentLayoutElementConfigSet(
		ComponentLayoutElementConfig
	){
			
		if( ( ComponentLayoutElementConfig instanceof ComponentLayoutElementConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		this.#ComponentLayoutElementConfig = ComponentLayoutElementConfig;
		
	}
	
	ComponentLayoutElementConfigGet(
	
	){
	
		return this.#ComponentLayoutElementConfig;
	
	}

};


export default ComponentLayoutHDynamicConfigT;