import { Component } from "react";


import InputMultiselectBase from '../inputs/Multiselect/Base/InputMultiselectBase';


const ComponentMultiselectBase = class ComponentMultiselectBase extends Component {
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	#OptionsObject = null;
	#OptionsFilter = null;
	#Placeholder = null;
	
	#Values = null;
	
	#Input = null;
	
	constructor( props ){
		
		console.log( props );
		
		super( props );
		
		
		this.#Field = props.field;
		
		this.#Disabled = props.disabled;
		
		this.#OnChangeCb = props.on_change_cb;
		
		this.#Placeholder = props.placeholder;
		
		this.#OptionsObject = props.options_object;
		
		this.#OptionsFilter = props.options_filter;

		this.#Values = props.value;
		
	}
	
	#OnChange( Field, Type, Values ){
		
		console.log( "#OnChange", Values );
		
		
		this.#Values = Values;
		
	}
	
	afterGuiAttached( props ){
		
		console.log( "afterGuiAttached", this.props, this.#Values );
		
		this.setState( {
			Mounted: true
		} );
		
	}
	
	getValue( ) {
		
		console.log( "getValue", this.#Values );
		
		
		return this.#Values;
	
	}
	
	isPopup( ){
		
		return true;
            
	}
	
	render( ){

		return (
		
			<InputMultiselectBase
				ref_cb = { ( Ref ) => { this.#Input = Ref; } }
				values = { this.#Values }
				field = { this.#Field }
				disabled = { this.#Disabled }
				on_change_cb = { this.#OnChange.bind( this ) }
				options_object = { this.#OptionsObject }
				options_filter = { this.#OptionsFilter }
				placeholder = { this.#Placeholder }
				values_out = { this.props.data }
			>
				
			</InputMultiselectBase>

		);
	
	}


};


export default ComponentMultiselectBase;