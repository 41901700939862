"use strict";


import APIFieldValueT from "../../../../FieldValue/APIFieldValueT.mjs";

import APIFieldValueMapT from "../../../../FieldValue/APIFieldValueMapT.mjs";


const APIModelCallResponseElementT = class APIModelCallResponseElementT extends APIFieldValueMapT {
	
	constructor(
		APIFieldValueList
	){
		
		super(
			APIFieldValueList
		);
		
	}
	
	static FromJSON(
		JSONData
	){
		
		let APIFieldValues = [ ];
		
		for(
			let I in JSONData
		){
			
			APIFieldValues.push(
				APIFieldValueT.FromJSON(
					I,
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIModelCallResponseElementT(
			APIFieldValues
		);
		
	}
	
};


export default APIModelCallResponseElementT;