"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";


import UIComponentImageConfigT from "../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIStyleT from "../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../Type/UI/Style/UIStyleMapT.mjs";


const UIComponentImageT = class UIComponentImageT extends UIComponentBaseT {

	constructor(
		UIElement,
		UIComponentImageConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentImageConfig instanceof UIComponentImageConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentImageConfig
		);
		
		let UIStyleMap = new UIStyleMapT( [
			new UIStyleT( "width", UIComponentImageConfig.WidthGet( ) ),
			new UIStyleT( "height", UIComponentImageConfig.HeightGet( ) ),
			new UIStyleT( "background-size", UIComponentImageConfig.SizeGet( ) ),
			new UIStyleT( "background-repeat", UIComponentImageConfig.RepeatGet( ) ),
			new UIStyleT( "background-image", UIComponentImageConfig.SrcGet( ) ),
			new UIStyleT( "background-position", UIComponentImageConfig.PositionGet( ) )
		] );
		
		super.StyleMapSet( 
			UIStyleMap
		);
	
	}
	
	Update(
	
	){
		
		let UIComponentImageConfig = super.ConfigGet( );

		let UIStyleMap = new UIStyleMapT( [
			new UIStyleT( "width", UIComponentImageConfig.WidthGet( ) ),
			new UIStyleT( "height", UIComponentImageConfig.HeightGet( ) ),
			new UIStyleT( "background-size", UIComponentImageConfig.SizeGet( ) ),
			new UIStyleT( "background-repeat", UIComponentImageConfig.RepeatGet( ) ),
			new UIStyleT( "background-image", UIComponentImageConfig.SrcGet( ) ),
			new UIStyleT( "background-position", UIComponentImageConfig.PositionGet( ) )
		] );
		
		super.StyleMapSet( 
			UIStyleMap
		);
			
		super.Update( );
			
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextT.Start" );
		
		
		let UIElement = super.UIElement( );
		
	}
	
	Stop(
	
	){
		
		let UIElement = super.UIElement( );
		
	}
	
	
	Listen(
		
	){
		
		
	}
	
	Unlisten( 
	
	){
		
	}
	
};


export default UIComponentImageT;