import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import Communication from "../common/communication";
import Modal from "../../components/common/modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
class UploadedDocumentsTab  extends React.Component {

    constructor(props) {
        super(props)
        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
		
		let permissions = JSON.parse( localStorage.my_permissions );
		
		let colDefs = [
			{
				headerName: this.props.t('Name'),
				field: 'name',
				editable:false,

			},
			{
				headerName: this.props.t('Type'),
				field: 'type',
				editable:false,
				valueGetter: function(params) {
				return this.props.t(params.data.type);
				}.bind(this)

			},
			{
				headerName: this.props.t('Date'),
				field: 'date',
				editable:false,
				suppressMenu: true,
				valueGetter: function(params) {
					if (params.data && params.data.id) {
						if (params.data.date == null || params.data.date == '') return '';
						var d = new Date(params.data.date);
						return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
							d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
					}
				}
			},

			{
				headerName: this.props.t('File'),
				field: 'attachment',
				editable:false,
				suppressMenu: true,
				cellRenderer:"FileRenderer",
				cellRendererParams: {
					type: 'generated_documents'
				}


			},

			{
				headerName: this.props.t('Note'),
				field: 'note',
				editable:true,
				suppressMenu: true,
			}
		];
		
		if( permissions.includes( "attachments.delete_uploaded" ) ){
				
			colDefs.push(
				{
					headerName: this.props.t('Delete'),
					cellRenderer: "DeleteRenderer",
					cellRendererParams: {
						gridType: "attachments",
					},
					suppressMenu: true
				}

            );
			
		}

        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            showCommunicationId:null,
            showCommunication:false,

            localeText: loctext,
            rowdata: null,
            columnDefs: colDefs,
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                FileRenderer: FileRenderer,
                CheckboxRenderer: CheckboxRenderer,
				DeleteRenderer: DeleteRenderer
            }
        };
    }




    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.loading_number!=null)  {
                this.setState({
                    rowdata: []
                });
                var model='loadings';
                if (this.props.type==='outinvoice_single') model='outinvoices';

                HttpService.get(UrlService.apiDomain() + 'api/attachments/'+model+'/not_generated/'+this.props.loading_number)
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    onCellCValueChanged = (event) => {
        HttpService.put(UrlService.apiDomain()+'api/attachments/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');


            })
            .catch((error) => {
                console.log(error);
            })


    }


    render() {


        return (


            <div style={{ 'height': '400px', width:'100%' }} id="attachmentsGrid" className="ag-theme-alpine">
                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}

                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}
                />
            </div>


        );
    }
}

UploadedDocumentsTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(UploadedDocumentsTab);

