"use strict";


import UIElementT from "../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBooleanT from "../../../../../Interface/UI/Component/Boolean/UIComponentBooleanT.mjs";


import UIStateT from "../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentBooleanConfigT from "../../../../../Type/UI/Component/Boolean/UIComponentBooleanConfigT.mjs";


const GTSUIComponentBooleanT = class GTSUIComponentBooleanT extends UIComponentBooleanT {
	
	constructor(
		ValueDefault = false,
		ValueDefaultOnlyValid = null
	){
		
		let UIComponentBooleanConfig = new UIComponentBooleanConfigT(
			ValueDefault,
			ValueDefaultOnlyValid,
			
			new UIStateMapT( [
			
				new UIStateT(
					"False",
					"UIComponentBooleanT False",
					new UIStyleMapT( [
					
					] )
				),
				
				new UIStateT(
					"True",
					"UIComponentBooleanT True",
					new UIStyleMapT( [
					
					] )
				)
				
			] )
			
		);
		
		super(
			new UIElementT( ),
			UIComponentBooleanConfig
		);
		
	}
	
	Start(
	
	){
	
		super.Start( );
	
	}
	
	Listen(
		OnChange
	){
		
		super.Listen(
			OnChange
		);
		
	}
	
};


export default GTSUIComponentBooleanT;
