"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelSchemaResponseModelOptionT from "./APIModelSchemaResponseModelOptionT.mjs";


const APIModelSchemaResponseModelOptionMapT = class APIModelSchemaResponseModelOptionMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelSchemaResponseModelOptionList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelSchemaResponseModelOptionList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelSchemaResponseModelOptionList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelSchemaResponseModelOptionList.length;
			I++
		){

			if( ( APIModelSchemaResponseModelOptionList[ I ] instanceof APIModelSchemaResponseModelOptionT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelSchemaResponseModelOptionList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelSchemaResponseModelOptionList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelSchemaResponseModelOption = APIModelSchemaResponseModelOptionList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelSchemaResponseModelOption );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelSchemaResponseModelOption
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelSchemaResponseModelOption instanceof APIModelSchemaResponseModelOptionT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelSchemaResponseModelOption
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelSchemaResponseModelOptionList = [ ];
		
		for(
			let I in JSONData
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelSchemaResponseModelOptionList.push(
				APIModelSchemaResponseModelOptionT.FromJSON(
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIModelSchemaResponseModelOptionMapT(
			APIModelKeyList,
			APIModelSchemaResponseModelOptionList
		);
		
	}
	
};


export default APIModelSchemaResponseModelOptionMapT;