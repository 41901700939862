"use strict";


import UIComponentLayoutElementConfigT from "../Layout/UIComponentLayoutElementConfigT.mjs";

import UIOptionT from "../../Option/UIOptionT.mjs";


const UIComponentCheckListElementConfigT = class UIComponentCheckListElementConfigT extends UIComponentLayoutElementConfigT {
	
	#UIOption = null;
	
	constructor(
		UIOption,
		
		Grow = "1",
		Shrink = "1",
		Basis = "auto",
		AlignSelf = "auto",
		
		UIStateMap = null
	){
		
		super(
			Grow,
			Shrink,
			Basis,
			AlignSelf,
			UIStateMap
		);
		
		
		this.OptionSet( UIOption );
		
	}
	
	Copy( ){
		
		return new UIComponentCheckListElementConfigT(
			this.OptionGet( ),
			
			super.GrowGet( ),
			super.ShrinkGet( ),
			super.BasisGet( ),
			super.AlignSelfGet( ),
			super.StateMapGet( )
		);
		
	}
	
	OptionSet(
		UIOption 
	){
		
		if( ( UIOption instanceof UIOptionT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIOption = UIOption.Copy( );
		
	}
	
	OptionGet(

	){
		
		return this.#UIOption.Copy( );
		
	}
				
};


export default UIComponentCheckListElementConfigT;