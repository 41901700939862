"use strict";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIOptionT from "../../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";


import UIComponentBaseConfigT from "../../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";


import TranslatorT from "../../../../../../../../Interface/Translator/TranslatorT.mjs";



import GTSUIComponentFormPrimaryLineElementT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineElementT.mjs";

import GTSUIComponentFormPrimaryLineT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineT.mjs";

import GTSUIComponentFormPrimaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/GTSUIComponentFormPrimaryT.mjs";


import GTSUIComponentSelectT from "../../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";

import GTSUIComponentBooleanT from "../../../../../../../../Interface/GTS/UI/Component/Boolean/GTSUIComponentBooleanT.mjs";

import GTSUIComponentTextT from "../../../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";

import GTSUIComponentDoubleT from "../../../../../../../../Interface/GTS/UI/Component/Double/GTSUIComponentDoubleT.mjs";

import GTSUIComponentDateT from "../../../../../../../../Interface/GTS/UI/Component/Date/GTSUIComponentDateT.mjs";

import GTSUIComponentSelectTextT from "../../../../../../../../Interface/GTS/UI/Component/SelectText/GTSUIComponentSelectTextT.mjs";


const GTSUIModelSaleFormAddPrimarySupplylistFormT = class GTSUIModelSaleFormAddPrimarySupplylistFormT extends GTSUIComponentFormPrimaryT {
	
	#T = null;
	
	#UIComponentLabelStateMapDefault = null;
	
	//1
	
	#N1 = null;
	#N2 = null;
	#Driver = null;
	
	//Lines
	
	#Line1 = null;
	
	
	constructor( 
		Locale
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"20px",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] )
		);
		
		super( [
		
		] );
		
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		this.#UIComponentLabelStateMapDefault = new UIStateMapT( [
				
			new UIStateT(
				"Default",
				"UIComponentLabelT",
				new UIStyleMapT( [
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" )
				] )
			)
			
		] );
		
		this.#Line1Add(
			
		);
		
	}
	
	IsValidTotalGet(
	
	){
	
		let IsValid = true;
		
		//1
		
		if( this.#N1.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#N2.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#Driver.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		return IsValid;
	
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		//1
		
		JSONData[ "n_1" ] = this.#N1.ValueGet( );
		
		JSONData[ "n_2" ] = this.#N2.ValueGet( );
		
		JSONData[ "driver" ] = this.#Driver.ValueGet( );
		
		
		return JSONData;
		
	}
	
	#Line1Add( 
		
	){
		
		//N1
		
		let N1Label = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Truck plate 1" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let N1Component = new GTSUIComponentTextT(
			"",
			
			this.#T.Translate( "Enter the value" ),
			"^[A-Za-z0-9 ]{0,10}",
			"^[A-Za-z0-9 ]{0,10}$"
		);
		
		let N1LineElement = new GTSUIComponentFormPrimaryLineElementT(
			N1Label,
			N1Component,
			"15%"
		);
		
		//N2
		
		let N2Label = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Truck plate 2" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let N2Component = new GTSUIComponentTextT(
			"",
			
			this.#T.Translate( "Enter the value" ),
			"^[A-Za-z0-9 ]{0,10}",
			"^[A-Za-z0-9 ]{0,10}$"
		);
		
		let N2LineElement = new GTSUIComponentFormPrimaryLineElementT(
			N2Label,
			N2Component,
			"15%"
		);
		
		//Driver
		
		let DriverLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Driver" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let DriverComponent = new GTSUIComponentTextT(
			"",
			
			this.#T.Translate( "Enter the value" ),
			"^.{0,64}",
			"^.{0,64}$"
		);
		
		let DriverLineElement = new GTSUIComponentFormPrimaryLineElementT(
			DriverLabel,
			DriverComponent,
			"30%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			N1LineElement,
			N2LineElement,
			DriverLineElement
		] );
		
		super.LineSet(
			0,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#N1 = N1Component;
		
		this.#N2 = N2Component;
		
		this.#Driver = DriverComponent;
		
	}
	
	#OnValueChange(
		Value,
		Invalid
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.OnValueChange",  Value, Invalid );
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let IsValid = this.IsValidTotalGet( );
			
			let Value = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( Value, IsValid );
			
		}
		
	}
	
	Start( ){
		
		//1
		
		this.#N1.Start( );
		
		this.#N2.Start( );

		this.#Driver.Start( );
		
	}			
	
	Listen(
		OnChangeFn
	){
		
		
		super.EventSet( "Change", OnChangeFn );
		
		//1
		
		this.#N1.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#N2.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#Driver.Listen( 
			this.#OnValueChange.bind( this )
		);
		
	}
	
};


export default GTSUIModelSaleFormAddPrimarySupplylistFormT;