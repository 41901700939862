"use strict";


const TranslatorUnitT = class TranslatorUnitT {

	#Key = null;
	#Value = null;
	
	constructor(
		Key,
		Value
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) === "undefined" ){
			
			throw new Error( "Invalid argument type" );
			
		}

		
		this.#Key = Key;
		
		this.#Value = Value;
		
		
	}
	
	Key( ){
		
		return this.#Key;
		
	}
	
	Value( ){
		
		return this.#Value;
		
	}
	
};


export default TranslatorUnitT;