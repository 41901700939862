import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class ProductTypeAdd extends Component<Props> {
    state = { production_salary:"", product_type:"", name_cs: "", name_en: "",name_cs_official: "", name_en_official: "",name_de: "",name_fr: "",name_it: "",name_es: "",name_ru: "",name_cn:"",code:"", active: "", unit: "" };
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { production_salary, product_type, name_cs, name_en,name_cs_official, name_en_official, name_de, name_fr, name_it, name_es, name_ru,name_cn, code, unit, active } = this.state;
        this.props.onAdd({ production_salary, product_type, name_cs,name_cs_official, name_en_official, name_en, name_de, name_fr, name_it, name_es, name_ru, name_cn, code, unit, active });
    }
    MakeItem (X) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        return <option key={X.id} value={X.id}>{X['name_'+locale]}</option>;
    };
    render() {
        var product_types_types=JSON.parse(localStorage.getItem('product_types_types'));
		
		let columns = JSON.parse( localStorage.columns );
		
		let unit_options = [ ];
		
		for( let I = 0; I < columns.length; I++ ){
			
			if( columns[ I ].model === "product_types" ){
				
				if( columns[ I ].field === "unit" ){
					
					unit_options = JSON.parse( columns[ I ].data );
					
					break;
					
				}
				
			}
			
		}
		
		
        const { production_salary, product_type, name_cs, name_en, name_cs_official, name_de, name_en_official, name_fr, name_it, name_es, name_ru, name_cn, code, unit, active } = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name official')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs_official}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs_official: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name official')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en_official}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en_official: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('German name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_de}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_de: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('French name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_fr}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_fr: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Italian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_it}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_it: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Spanish name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_es}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_es: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                    </div>

                    <div className="row">

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Russian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_ru}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_ru: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Chinese name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_cn}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cn: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Code')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={code}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ code: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Active')}</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    value="1"
                                    onChange={(event) => this.setState({ active: event.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Type')}</label>
                                <select
                                    value={product_type}
                                    required
                                    className="form-control"
                                    onChange={(event) =>
                                        this.setState({ product_type: event.target.value })
                                    }><option value="">{this.props.t('Choose')}</option>{product_types_types.map(this.MakeItem)}</select>
                            </div>
                        </div>
						
						<div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Unit')}</label>
                                <select
                                    value={unit}
                                    required
                                    className="form-control"
                                    onChange={(event) =>
                                        this.setState({ unit: event.target.value })
                                    }><option value="">{this.props.t('Choose')}</option>{unit_options.map(this.MakeItem)}</select>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Salary')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={production_salary}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ production_salary: event.target.value })
                                    }
                                />

                            </div>
                        </div>

                    </div>

                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
ProductTypeAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ProductTypeAdd);

