"use strict";


import GUIElementT from "../../../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentTableHConfigT from "../../../../Type/Component/Table/H/ComponentTableHConfigT.mjs";

import ComponentConfigT from "../../../../Type/Component/ComponentConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT,
	ComponentLayoutHT as ComponentLayoutHT,
	ComponentT as ComponentT
} from "../../ComponentInternalT.mjs";


import EventT from "../../../Event/EventT.mjs";
import EventMapT from "../../../Event/EventMapT.mjs";
import EventObjectT from "../../../Event/EventObjectT.mjs";
import EventBucketT from "../../../Event/EventBucketT.mjs";

import KeyValueT from "../../../../Type/KeyValue/KeyValueT.mjs";
import KeyValueMapT from "../../../../Type/KeyValue/KeyValueMapT.mjs";
import KeyValueElementT from "../../../../Type/KeyValue/KeyValueElementT.mjs";
import KeyValueBucketT from "../../../../Type/KeyValue/KeyValueBucketT.mjs";


const ComponentTableHT = class ComponentTableHT extends ComponentBaseT {
	
	#ComponentTableHConfig = null;
	
	#RowIndexCounter = 0;
	#Rows = null;
	
	#RowAddCb = null;
	#RowRemoveCb = null;
	
	#InputFocusCb = null;
	#InputBlurCb = null;
	#InputChangeCb = null;
	#ButtonClickCb = null;
	
	#PrepareMap = null;

    constructor(
		GUIElement,
		ComponentTableHConfig
	) {
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ComponentTableHConfig instanceof ComponentTableHConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		super( 
			GUIElement,
			ComponentTableHConfig.Copy( )
		);
		
		
		this.#ComponentTableHConfig = ComponentTableHConfig.Copy( );

		
		this.#RowIndexCounter = 1;
		
		this.#Rows = new Map( );
		

    }
	
	Update( ){
		
		super.Update( );
		
		/*this.ElementGet( ).Update(  );

		this.#GUIComponentOutputTable = new GUIComponentOutputTableT(
			TableDynamicConfig.TableConfigGet( )
		);*/
		
	}
	
	Render( ){
		
		return this.Element( ).Render( );
		
	}
	
	/*#OnEvent( Key, RowIndex, CellIndex, Name, Value ){
		
		return this.#EventCb( Key, RowIndex, CellIndex, Name, Value );
		
	}*/
	
	RowCount(
	
	){
	
		return this.#Rows.size;
	
	}
	
	RowKeys(
		
	){
		
		return this.#Rows.keys( );
		
	}
	
	RowGet(
		RowIndex
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Rows.has( RowIndex ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Rows.get( RowIndex );
	
	}
	
	Filter( 
		CellNames
	){
		
		for(
			let RowIndex of this.#Rows.keys( )
		){
			
			if( RowIndex === 0 ){
				
				continue;
				
			}
			
			let Row = this.#Rows.get( RowIndex );
			
			let ComponentLayoutH = Row.ComponentTypedGet( );
			
			ComponentLayoutH.Filter( CellNames );
			
		}
		
	}
	
	Clear( ){
		
		for( let I of this.#Rows.keys( ) ){
			
			if( I === 0 ){
				
				continue;
				
			}
			
			this.RowRemove( I );
			
		}
		
		this.#RowIndexCounter = 1;
		
	}
	
	RowRemove( 
		RowIndex
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Rows.has( RowIndex ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		let ComponentOutputTableRow = this.RowGet( RowIndex );
	
		this.ChildRemove( 
			ComponentOutputTableRow
		);
	
		this.#Rows.delete( RowIndex );
		
		
		if(
			this.RowCount( ) === 1
		){
			
			let ComponentOutputTableRow = this.RowGet( 0 );
	
			this.ChildRemove( 
				ComponentOutputTableRow
			);
		
			this.#Rows.delete( 0 );
			
		}
	
	}
	
	#OnButtonClick( Name, RowIndex, CellIndex ){
		
		console.log( "ComponentTableHT.#OnButtonClick", Name, RowIndex, CellIndex );
	
		if( this.#ButtonClickCb !== null ){
			
			this.#ButtonClickCb( Name, RowIndex, CellIndex );
			
		}
	
	}
	
	#OnInputFocus( Name, RowIndex, CellIndex ){
		
		console.log( "ComponentTableHT.#OnInputFocus", Name, RowIndex, CellIndex );
	
		if( this.#InputFocusCb !== null ){
			
			this.#InputFocusCb( Name, RowIndex, CellIndex );
			
		}
		
	}
	
	#OnInputBlur( Name, RowIndex, CellIndex ){
		
		console.log( "ComponentTableHT.#OnInputBlur", Name, RowIndex, CellIndex );
	
		if( this.#InputBlurCb !== null ){
			
			this.#InputBlurCb( Name, RowIndex, CellIndex );
			
		}
		
	}
	
	#OnInputChange( Name, RowIndex, CellIndex, Value ){
		
		console.log( "ComponentTableHT.#OnInputChange", Name, RowIndex, Value );
		
		
		if( this.#InputChangeCb !== null ){
			
			this.#InputChangeCb( Name, RowIndex, CellIndex, Value );
			
		}
		
	}
	
	RowAdd(
		
	){
		
		console.log( "ComponentTableHT.RowAdd", this.RowCount( ), this.#RowIndexCounter );
		
		if(
			this.RowCount( ) === 0
		){
			
			let ComponentTableHRow = new ComponentLayoutHT(
				new GUIElementT( "div" ),
				this.#ComponentTableHConfig.ComponentTableHHeaderRowConfigGet( ).Copy( )
			);
			
			ComponentTableHRow.CellsCreate( );
			
			let Component = new ComponentT(
				"LayoutH",
				ComponentTableHRow
			);
			
			this.ChildAppend( 
				Component
			);
		
			this.#Rows.set( 0, Component );
			
		}
		
		let ComponentTableHRow = new ComponentLayoutHT(
			new GUIElementT( "div" ),
			this.#ComponentTableHConfig.ComponentTableHRowConfigGet( ).Copy( )
		);
		
		ComponentTableHRow.Prepare(
			this.#PrepareMap //TODO Prepare here too!
		);
		
		ComponentTableHRow.CellsCreate( );
		
		
		let Component = new ComponentT(
			"LayoutH",
			ComponentTableHRow
		);
		
		this.ChildAppend( 
			Component
		);
	
		this.#Rows.set( this.#RowIndexCounter, Component );

		
		Component.Listen(
			new EventBucketT( [
				new EventObjectT(
					Component.NameGet( ),
					new EventMapT( [
						new EventT(
							"ButtonClick",
							this.#OnButtonClick.bind( this )
						),
						new EventT(
							"InputChange",
							this.#OnInputChange.bind( this )
						),
						new EventT(
							"InputBlur",
							this.#OnInputBlur.bind( this )
						),
						new EventT(
							"InputFocus",
							this.#OnInputFocus.bind( this )
						)
					] )
				)
			] )
		);
		
		ComponentTableHRow.IndexSet( this.#RowIndexCounter );
		
		this.#RowIndexCounter = this.#RowIndexCounter + 1;

		
		if( this.#RowAddCb !== null ){
		
			this.#RowAddCb( this );
		
		}
		
	}
	
	Listen(
		EventBucket
	){
		
		console.log( "ComponentTableHT.Listen", EventBucket );
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		
		if( EventBucket !== null ){
		
			let Name = this.#ComponentTableHConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
			
				/*if( EventMap.IsExists( "RowAdd" ) ){
					
					this.#RowAddCb = EventMap.Get( "RowAdd" );
					
				}*/
				
				if( EventMap.IsExists( "ButtonClick" ) ){
					
					this.#ButtonClickCb = EventMap.Get( "ButtonClick" );
					
				}
				
				if( EventMap.IsExists( "InputFocus" ) === true ){
					
					this.#InputFocusCb = EventMap.Get( "InputFocus" );
					
				}
				
				if( EventMap.IsExists( "InputBlur" ) === true ){
					
					this.#InputBlurCb = EventMap.Get( "InputBlur" );
					
				}
				
				if( EventMap.IsExists( "InputChange" ) === true ){
					
					this.#InputChangeCb = EventMap.Get( "InputChange" );
					
				}
				
			}
			
		}
		
	}
	
	ElementGet( RowIndex, CellIndex, Name ){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		return ComponentLayoutH.ElementGet( CellIndex, Name );
		
	}
	
	ValuesElementCheck(
		RowIndex,
		CellIndex,
		Fields
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		return ComponentLayoutH.ValuesElementCheck( CellIndex, Fields );
		
	}
	
	ValuesLayoutCheck( 
		RowIndex,
		Fields
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		return ComponentLayoutH.ValuesCheck( Fields );
		
	}
	
	ValuesCheck( 
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesBucket = new KeyValueBucketT( [ ] );
		
		
		for(
			let RowIndex of this.#Rows.keys( )
		){
			
			if( RowIndex === 0 ){
				
				continue;
				
			}
			
			let RowValuesMap = this.ValuesLayoutCheck( RowIndex, Fields );
			
				
			if( ValuesBucket.IsExists( RowIndex ) === true ){
				
				throw new Error( "Duplicate element" );
				
			}
			
			
			ValuesBucket.Set( RowIndex, RowValuesMap );
			
		}
		
		
		return ValuesBucket;
		
	}
	
	ValuesElementHighlight(){
		
	}
	
	ValuesElementGet( 
		RowIndex,
		CellIndex,
		Fields
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		return ComponentLayoutH.ValuesElementGet( CellIndex, Fields );
		
	}
	
	ValuesLayoutGet( 
		RowIndex,
		Fields
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		return ComponentLayoutH.ValuesGet( Fields );
		
	}
	
	ValuesGet( 
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesBucket = new KeyValueBucketT( [ ] );
		
		
		for(
			let RowIndex of this.#Rows.keys( )
		){
			
			if( RowIndex === 0 ){
				
				continue;
				
			}
			
			let RowValuesMap = this.ValuesLayoutGet( RowIndex, Fields );
			
				
			if( ValuesBucket.IsExists( RowIndex ) === true ){
				
				throw new Error( "Duplicate element" );
				
			}
			
			
			ValuesBucket.Set( RowIndex, RowValuesMap );
			
		}
		
		
		return ValuesBucket;
		
	}
	
	ValuesElementSet( 
		RowIndex,
		CellIndex,
		ValuesMap
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ValuesMap instanceof KeyValueMapT ) === false ){
		
			throw new Error( "Invalid argument type" );
	
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		//TODO Check
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		ComponentLayoutH.ValuesElementSet( CellIndex, ValuesMap );
		
	}
	
	ValuesLayoutSet( 
		RowIndex,
		ValuesMap
	){
		
		if( typeof( RowIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ValuesMap instanceof KeyValueMapT ) === false ){
		
			throw new Error( "Invalid argument type" );
	
		}
		
		
		let Row = this.#Rows.get( RowIndex );
		
		//TODO Check
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		ComponentLayoutH.ValuesSet( ValuesMap );
		
	}
	
	ValuesSet( 
		ValuesBucket
	){
		
		if( ( ValuesBucket instanceof KeyValueBucketT ) === false ){
		
			throw new Error( "Invalid argument type" );
	
		}
		
		
		for(
			let RowIndex of ValuesBucket.Keys( )
		){
			
			if( this.#Rows.has( RowIndex ) === false ){
				
				throw new Error( "Layout not exists" );
				
			}
			
			let ValueMap = ValuesBucket.Get( RowIndex );
			
			let Row = this.#Rows.get( RowIndex );
			
			let ComponentLayoutH = Row.ComponentTypedGet( );
			
			ComponentLayoutH.ValuesSet( ValueMap );
			
		}
		
	}
	
	VisibleColumnsSet(
		ColumnNames,
		Visible
	){
		
		console.log( 
			ColumnNames,
			Visible
		);
		
		
		let ComponentLayoutHHeaderConfig = this.#ComponentTableHConfig.ComponentTableHHeaderRowConfigGet( );
		
		let ComponentLayoutElementHeaderConfigList = ComponentLayoutHHeaderConfig.ComponentLayoutElementConfigListGet( );
		
		for( 
			let I = 0;
			I < ComponentLayoutElementHeaderConfigList.length;
			I++
		){
			
			let ComponentLayoutElementHeaderConfig = ComponentLayoutElementHeaderConfigList[ I ];
			
			if( ColumnNames.includes( ComponentLayoutElementHeaderConfig.NameGet( ) ) === true ){
				
				ComponentLayoutElementHeaderConfig.StyleSet( "display", ( Visible === true ) ? "table-cell" : "none" );
				
			}
			
			
			
		}
		
		
		let ComponentLayoutHConfig = this.#ComponentTableHConfig.ComponentTableHRowConfigGet( );
		
		let ComponentLayoutElementConfigList = ComponentLayoutHConfig.ComponentLayoutElementConfigListGet( );
		
		for( 
			let I = 0;
			I < ComponentLayoutElementConfigList.length;
			I++
		){
			
			let ComponentLayoutElementConfig = ComponentLayoutElementConfigList[ I ];
			
			if( ColumnNames.includes( ComponentLayoutElementConfig.NameGet( ) ) === true ){
			
				ComponentLayoutElementConfig.StyleSet( "display", ( Visible === true ) ? "table-cell" : "none" );
			
			}
			
		}
		
		for(
			let RowIndex of this.#Rows.keys( )
		){
			
			if( this.#Rows.has( RowIndex ) === false ){
				
				throw new Error( "Layout not exists" );
				
			}
			
			let Row = this.#Rows.get( RowIndex );
		
			let ComponentLayoutH = Row.ComponentTypedGet( );
			
			ComponentLayoutH.VisibleColumnsSet( 
				ColumnNames,
				Visible
			);
			
		}
		
	}
	
	Prepare( 
		PrepareMap 
	){
		
		console.log( "ComponentTableHT.Prepare", PrepareMap );
		
		this.#PrepareMap = PrepareMap;
		
	}
	
	StyleMapElementSet(
		RowIndex,
		CellIndex,
		Fields,
		StyleMap
	){
		
		let Row = this.#Rows.get( RowIndex );
		
		let ComponentLayoutH = Row.ComponentTypedGet( );
		
		ComponentLayoutH.StyleMapElementSet(
			CellIndex,
			Fields,
			StyleMap
		);
		
	}

};

export default ComponentTableHT;

