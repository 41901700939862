import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import Modal from "../../components/common/modal";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Datetime from 'react-datetime';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";


class LoadingsFinalCalculationTable  extends React.Component {

    constructor(props) {
	
        console.log( "Constructor props", props );
	
        super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ){
			
			locale = localStorage.getItem( 'locale' );
			
		}
		
		let loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
		
		
		let datetime = new Date( );
		
		
		
		this.rowColSpan = [ ];
		
		let CellColSpan = function( params ){
		
			if( this.rowColSpan.includes( params.node.rowIndex ) ){
			
				return 6;
			
			} else {
			
				return 1;
			
			}

			/*if( 
				( params.node.rowIndex === 0 ) || 
				( params.node.rowIndex === 21 ) || 
				( params.node.rowIndex === 32 ) || 
				( params.node.rowIndex === 33 ) || 
				( params.node.rowIndex === 37 ) || 
				( params.node.rowIndex === 45 ) || 
				( params.node.rowIndex === 49 ) || 
				( params.node.rowIndex === 52 ) || 
				( params.node.rowIndex === 53 )   
			) {
			
				return 6;
				
			} else {
			
				return 1;
			
			}*/

		}.bind( this );
		
		
		this.rowColorLightBlueColumn3 = [ ];
		this.rowColorLightBlueColumn6 = [ ];
		this.rowColorLightBlue = [ ];
		this.rowColorLightBlueCenter = [ ];
		this.rowColorGray = [ ];
		this.rowColorYellow = [ ];
		this.rowColorYellowRedText = [ ];
		this.rowColorWhiteRedText = [ ];
		this.rowColorWhiteGreenText = [ ];
		
		
		let CellClassRules = {
				
			'row-color-light-blue': function( params ) { 
			
				if( ( params.colDef.field == "column3" ) && this.rowColorLightBlueColumn3.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
				
				if( ( params.colDef.field == "column6" ) && this.rowColorLightBlueColumn6.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
				
				if( ( ( params.colDef.field == "column1" ) || ( params.colDef.field == "column2" ) || ( params.colDef.field == "column4" ) || ( params.colDef.field == "column5" ) ) && this.rowColorLightBlue.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( params.colDef.field == "column3" ) && ( 
					( params.node.rowIndex === 1 ) || 
					( params.node.rowIndex === 9 ) || 
					( params.node.rowIndex === 12 ) || 
					( params.node.rowIndex === 15 ) || 
					( params.node.rowIndex === 19 ) || 
					( params.node.rowIndex > 53 ) 
				){
				
					return true;
					
				}
			
				if( ( ( params.colDef.field == "column1" ) || ( params.colDef.field == "column2" ) || ( params.colDef.field == "column4" ) || ( params.colDef.field == "column5" ) ) && ( ( params.node.rowIndex > 53 ) ) ){
				
					return true;
				
				}
			
				if( ( params.colDef.field == "column6" ) && ( ( ( params.node.rowIndex >= 1 ) && ( params.node.rowIndex <= 8 ) ) || ( params.node.rowIndex > 53 ) ) ){
				
					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-light-blue-center': function( params ){
			
				if( this.rowColorLightBlueCenter.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 0 ) || ( params.node.rowIndex === 53 ) ) {
				
					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-grey': function( params ) {
			
				if( this.rowColorGray.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 33 ) ) ){

					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-yellow-red-text': function( params ) {
			
				if( this.rowColorYellowRedText.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 36 ) ) ) {
				
					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-yellow': function( params ) {
			
				if( this.rowColorYellow.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 20 ) || ( params.node.rowIndex === 31 ) ) ){
				
					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-white-red-text': function( params ) {
			
				if( this.rowColorWhiteRedText.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 38 ) || ( params.node.rowIndex === 47 ) ) ){
				
					return true;
				
				}*/
				
				return false;
				
			}.bind( this ),
			
			'row-color-white-green-text': function( params ) {
			
				if( this.rowColorWhiteGreenText.includes( params.node.rowIndex ) ){
				
					return true;
				
				}
			
				/*if( ( ( params.node.rowIndex === 41 ) || ( params.node.rowIndex === 50 ) || ( params.node.rowIndex === 51 ) ) ) {

					return true;
					
				}*/
				
				return false;
				
			}.bind( this )
		
		};
		
		let columnDefinitions = [
			{
				
				field: "column1",
				headerName: "column1",
				valueGetter: function (params) {
					
					return params.data["column1"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column1"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				colSpan: CellColSpan,
				cellClassRules: CellClassRules
				
			},
			{
				
				field: "column2",
				headerName: "column2",
				valueGetter: function (params) {
					
					return params.data["column2"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column2"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				cellClassRules: CellClassRules
				
			},
			{
				
				field: "column3",
				headerName: "column3",
				valueGetter: function (params) {
					
					return params.data["column3"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column3"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				cellClassRules: CellClassRules
				
			},
			{
				
				field: "column4",
				headerName: "column4",
				valueGetter: function (params) {
					
					return params.data["column4"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column4"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				cellClassRules: CellClassRules
				
			},
			{
				
				field: "column5",
				headerName: "column5",
				valueGetter: function( params ) {
					
					return params.data["column5"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column5"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				cellClassRules: CellClassRules
				
			},
			{
				
				field: "column6",
				headerName: "column6",
				valueGetter: function( params ) {
					
					return params.data["column6"];
					
				},
				valueSetter: function( params ) {
					
					params.data["column6"] = params.newValue;
					
					return true;
					
				},
				comparator: function (valueA, valueB) {
					
                    return valueA == valueB;
					
				},
				hide: false,
				editable: true,
				cellClassRules: CellClassRules
				
			},
		];
		
		let excelStyles = [
			{
				id: "cell",
				font: { color: "#000000", bold: true },
				interior: {
					color: "#FFFFFF", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-light-blue",
				font: { color: "#000000"},
				interior: {
					color: "#ADD8E6", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-light-blue-center",
				font: { color: "#000000"},
				interior: {
					color: "#ADD8E6", pattern: 'Solid'
				},
				alignment: {
					horizontal: 'Center'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-yellow-red-text",
				font: { color: "#ff0000"},
				interior: {
					color: "#ffff00", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-yellow",
				font: { color: "#000000" },
				interior: {
					color: "#ffff00", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-grey",
				font: { color: "#000000" },
				interior: {
					color: "#bebebe", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-white-red-text",
				font: { color: "#ff0000" },
				interior: {
					color: "#ffffff", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			},
			{
				id: "row-color-white-green-text",
				font: { color: "#008000" },
				interior: {
					color: "#ffffff", pattern: 'Solid'
				},
				borders: {
					borderBottom: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderLeft: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderRight: {
						lineStyle: 'Continuous',
						weight: 1
					},
					borderTop: {
						lineStyle: 'Continuous',
						weight: 1
					}
				}
			}
		];
		
		let row_data = [
		
		];
		
		const customComparator = (valueA, valueB) => {
			
			console.log( valueA, valueB );
			
			if( valueA == null && valueB == null ){
				
				return 0;
				
			}
			
			if( valueA == null ){
				
				return -1;
				
			}
			
			if( valueB == null ){
				
				return 1;
				
			}
			
			if( ( typeof( valueA ) !== 'string' ) || ( typeof( valueB ) !== 'string' ) ) {

               if( valueA > valueB ) {

					return 1;
					
				} else if( valueA < valueB ) {

					return -1;
					
				} else {
					
					return 0;
					
				}
			   
			}
			
			return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
			
        };
		
		let defaultExcelExportParams = {
			autoConvertFormulas: true
		};
		
		this.formulaData1TotalIndex = 0;
		this.formulaData2TotalIndex = 0;
		
        this.state = {
			locale: locale,
			datetime: this.FormatDatetime( datetime ),
			datetime_server: this.FormatDatetimeServer( datetime ), 
			provision_datetime: this.FormatDatetime( datetime ),
			provision_datetime_server: this.FormatDatetimeServer( datetime ), 
			provision: 0, 
			manager: this.props.manager,
			top_manager: this.props.top_manager,
			is_top: "",
			loadingsArray: this.props.loadingsArray,
			loadingsObject: this.props.loadingsObject,
			loadingsObjectByLoadingNumber: this.props.loadingsObjectByLoadingNumber,
			loadingsSelected: this.props.loadingsSelected,
			expense_types_types: JSON.parse( localStorage.expense_types_types ),
			expense_types: JSON.parse( localStorage.expense_types ),
			expenses: this.props.expenses,
			isopen: this.props.isopen,
			disabled: false, 
			spinner: 'none',
			appraisals: this.props.apprisals,
			rates: this.props.rates,
			results: this.props.results,
			
			modules: AllCommunityModules,
            localeText: loctext,
            rowdata: row_data,
            columnDefs: columnDefinitions,
			excelStyles: excelStyles,
			defaultExcelExportParams: defaultExcelExportParams,

            statusBar: {
                statusPanels: [
                    

                ],
            },

            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

                comparator: customComparator
            },
		};
		
    }

    FormatDatetime( date ){
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	FormatDatetimeServer( date ){
	
		return this.FormatDateServer( date ) + ' ' + this.FormatTimeServer( date );
	
	}
	
	FormatDateServer( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FormatTimeServer( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	componentDidMount( ){
	
	}


    componentDidUpdate(prevProps) {

        console.log( "component table did update", this.props, prevProps, this.state );
		
		
		
		
		let StateChange = { };
		
		let NeedFill = false;
		
		if(  ( this.props.isopen != prevProps.isopen ) && ( this.props.isopen == false ) ){
		
			console.log( "component table closed" );
		
			StateChange.disabled = false; 
			StateChange.spinner = 'none';
			//StateChange.manager = null;
			//StateChange.top_manager = null;
			StateChange.is_top = "";
			
			NeedFill = true;
		
		}
		
		if( JSON.stringify( prevProps.loadingsArray ) != JSON.stringify( this.props.loadingsArray ) ){ 
		
			StateChange.loadingsArray = this.props.loadingsArray;
			
			NeedFill = true;
		
		}
		
		if( JSON.stringify( prevProps.loadingsObject ) != JSON.stringify( this.props.loadingsObject ) ){ 
		
			StateChange.loadingsObject = this.props.loadingsObject;
			
			NeedFill = true;
			
		}
		
		if( JSON.stringify( prevProps.loadingsObjectByLoadingNumber ) != JSON.stringify( this.props.loadingsObjectByLoadingNumber ) ){ 
		
			StateChange.loadingsObjectByLoadingNumber = this.props.loadingsObjectByLoadingNumber;
			
			NeedFill = true;
			
		}
		
		if( 
			//( this.props.manager != null ) && 
			( prevProps.manager != this.props.manager ) 
		){
		
			let manager = this.props.manager;
		
			let loadingsObject = this.state.loadingsObject;
			
			let loadingsArray = this.state.loadingsArray;
			
			let loadingsManager = [ ];
			
			for(
				let i in loadingsArray
			){
			
				if( loadingsObject[ loadingsArray[ i ].id ].manager == manager ){
			
					loadingsManager.push( loadingsArray[ i ] );
			
				}
				
			}

			StateChange.manager = manager;
			StateChange.loadingsSelected = [ ];
			StateChange.loadingsManager = loadingsManager;
			
			NeedFill = true;
		
		}
		
		if( ( prevProps.top_manager != this.props.top_manager ) ){
		
			let top_manager = this.props.top_manager;
		
			StateChange.top_manager = top_manager;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.loadingsSelected ) != JSON.stringify( this.props.loadingsSelected ) ) &&
			( this.props.loadingsSelected != null )
		) {
		
			StateChange.loadingsSelected = this.props.loadingsSelected;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.expenses ) != JSON.stringify( this.props.expenses ) ) &&
			( this.props.expenses != null )
		) {
		
			StateChange.expenses = this.props.expenses;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.appraisals ) != JSON.stringify( this.props.appraisals ) ) &&
			( this.props.appraisals != null )
		) {
		
			StateChange.appraisals = this.props.appraisals;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.results ) != JSON.stringify( this.props.results ) ) &&
			( this.props.results != null )
		) {
		
			StateChange.results = this.props.results;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.rates ) != JSON.stringify( this.props.rates ) ) &&
			( this.props.rates != null )
		) {
		
			StateChange.rates = this.props.rates;
			
			NeedFill = true;
		
		}
		
		
		
		
		if( NeedFill == true ){
		
			this.setState( 
				StateChange,
				function( ){
				
					this.FillTotal( );
					
				}.bind( this )
			);
		
		}
		
		
    }
	
	
	
	FormatProcessedMaterialByType( ){
	
		let product_types = JSON.parse( localStorage.product_types );
	
		let data = {  };
	
		for(
			let i in this.state.results
		){
		
			let result = this.state.results[ i ];
			
			let product_type = "";
			
			let salary = 0;
			
			let salary_volume = 0;
			
			for(
				let i2 in product_types
			){
			
				if( product_types[ i2 ].id.toString( ) == result.product_type.toString( ) ){
				
					if( product_types[ i2 ].product_type.toString( ) != "2" ){
					
						continue;
					
					}
				
					let pre_product_type = product_types[ i2 ][ "name_cs" ];//+ this.state.locale ];
					
					console.log( pre_product_type );
					
					if( pre_product_type.toLowerCase().indexOf( "silnice" ) != -1 || pre_product_type.toLowerCase().indexOf( "silnicni" ) != -1 ){
					
						product_type = "Silnice";
						
						salary = parseFloat( product_types[ i2 ].production_salary );
						
						salary_volume = parseFloat( result.salary_volume );
					
						break;
					
					}
					
					if( pre_product_type.toLowerCase().indexOf( "doly" ) != -1 || pre_product_type.toLowerCase().indexOf( "dulni" ) != -1 ){
					
						product_type = "Hlavice";
						
						salary = parseFloat( product_types[ i2 ].production_salary );
						
						salary_volume = parseFloat( result.salary_volume );
					
						break;
					
					}
					
					if( pre_product_type.toLowerCase().indexOf( "diamanty" ) != -1  ){
					
						product_type = "Diamanty";
						
						salary = parseFloat( product_types[ i2 ].production_salary );
						
						salary_volume = parseFloat( result.salary_volume );
					
						break;
					
					}
				
				}
			
			}
			
			if( product_type == "" ){
			
				continue;
			
			}
			
			if( result.qty != 0 ){
			
				if( !data[ product_type ] ){
				
					data[ product_type ] = { };
					
					data[ product_type ].weight = parseFloat( result.qty );
					
					data[ product_type ].salary = salary;
					
					data[ product_type ].salary_volume = salary_volume;
				
				} else {
					
					data[ product_type ].weight = data[ product_type ].weight + parseFloat( result.qty );
					
					data[ product_type ].salary_volume = data[ product_type ].salary_volume + salary_volume;
					
				}
			
			}
		
		}
		
		return data;
	
	}
	
	FormatManager( ){
	
		let users = JSON.parse( localStorage.getItem( 'users' ) );
				
		let manager = "";
				
		if( this.state.manager != null ){
				
			for(
				let i in users
			){
				
				if( users[ i ].id.toString( ) == this.state.manager.toString( ) ){
				
					console.log( users[ i ].id, this.state.manager );
					
					manager = users[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}
		
		}
	
		return manager;
	
	}
	
	FormatCalcLoading( loading ){
	
		let Result = {
			excvat: 0, //сумма за лоадинг от суммы всех инвоисов
			transportation_excvat: 0 //сумма за доправу от суммы всех инвоисов
		};
		
		if( 
			( loading.invoice_type == null ) &&
			( loading.invoice_excvat_kc_each == null )
		){
		
			console.log( "NaNCheck2", Result.excvat );
	
			Result.excvat = loading.invoice_excvat ? parseFloat( loading.invoice_excvat ) : 0;
		
			console.log( "NaNCheck2", Result.excvat );
		
		} else {
		
			
			
			let invoices_loadings = !loading.invoice_loadings ? [ ] : loading.invoice_loadings.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //погрузки каждого инвойса текущей погрузки
			
			let invoices_types = loading.invoice_type.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //тип каждого инвойса текущей погрузки
		
			let invoices_excvat_kc_each = loading.invoice_excvat_kc_each.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //сумма каждого инвойса текущей погрузки
			
			
			if(
				( invoices_types.length !== invoices_excvat_kc_each.length ) 
			){
			
				throw new Error( "Critical error" );
			
			}
			
			for(
				let Iterator = 0;
				Iterator < invoices_types.length; //проходим каждый инвойс
				Iterator++
			){
				
				let invoice_loadings = !invoices_loadings[ Iterator ] ? [ ] : invoices_loadings[ Iterator ].split( "//" ).map( function( el ){ return el.trim( ); } ).filter( function( el ){ return el != ''; } ); //лоадинги инвоиса
			
				let invoice_loadings_weight = 0; //вес всех лоадингов инвойса
				
				if( invoice_loadings.length > 0 ){
			
					for(
						let Iterator2 = 0;
						Iterator2 < invoice_loadings.length;//проходим каждый лоадинг инвойса
						Iterator2++
					){
						
						let invoice_loading = this.state.loadingsObjectByLoadingNumber[ invoice_loadings[ Iterator2 ] ];
						
						if( invoice_loading ){
						
							if( invoice_loading.net_material_qty ){
							
								console.log( invoice_loadings_weight, invoice_loading.net_material_qty );
							
								invoice_loadings_weight = invoice_loadings_weight + parseInt( invoice_loading.net_material_qty );
							
								console.log( invoice_loadings_weight, invoice_loading.net_material_qty );
							}
						}
						
					}
				
				} else {
				
					if( loading.net_material_qty ){
					
						console.log( invoice_loadings_weight, loading.net_material_qty );
					
						invoice_loadings_weight = parseInt( loading.net_material_qty );
						
						console.log( invoice_loadings_weight, loading.net_material_qty );
						
					}
					
				}
				
				
				if( Number.isNaN( invoice_loadings_weight ) === true ){
					
					throw new Error( "Critical error" );
					
				}
				
				if( invoice_loadings_weight === 0 ){
					
					continue;
					
				}
				
				if( !loading.net_material_qty ){
					continue;
				}
				
			
				let invoice_amount = ( invoices_excvat_kc_each[ Iterator ] ? parseFloat( invoices_excvat_kc_each[ Iterator ] ) : 0 );
				
				console.log( invoice_amount );
				
				let invoice_amount_per_unit = invoice_amount / invoice_loadings_weight; //сумма за кг(т) в инвоисе
				
				console.log( invoice_amount_per_unit );
				
				let invoice_weight_multiplier = parseInt( loading.net_material_qty ) / invoice_loadings_weight;
				
				console.log( invoice_weight_multiplier );
				
				let invoice_loading_amount = parseInt( loading.net_material_qty ) * invoice_amount_per_unit; //сумма за лоадинг от суммы инвоиса
				
				console.log( invoice_loading_amount );
			
				if( invoices_types[ Iterator ] != "2" ){ //transportation
				
					console.log( "NaNCheck2", Result.excvat );
				
					Result.excvat = Result.excvat + invoice_loading_amount;
					
					console.log( "NaNCheck2", Result.excvat );

				} else {
				
					console.log( "NaNCheck2", Result.transportation_excvat );
				
					Result.transportation_excvat = Result.transportation_excvat + invoice_loading_amount;
				
					console.log( "NaNCheck2", Result.transportation_excvat );
				
				}
			
			}
			
		}
		
		
		return Result;
	
	}

	
	FormatCalc( ){
	
		let excvat = 0;
		
		let weight = 0;
		
		let transportation_excvat = 0;
		
		let transportation_weight = 0;
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( !loading ){
			
				continue;
			
			}
			
			let Result = this.FormatCalcLoading( loading );
			
			if( loading.net_material_qty ){
			
				weight = weight + parseInt( loading.net_material_qty );
				
			}
			
			excvat = excvat + Result.excvat;
			
			transportation_excvat = transportation_excvat + Result.transportation_excvat;
		
		}
		
		return { 
			weight: weight, 
			amount: excvat,
			amount_eur: ( excvat / this.state.rates[ 3 ] ),
			transportation_amount: transportation_excvat,
			transportation_amount_eur: ( transportation_excvat / this.state.rates[ 3 ] )
		};
	
	}
	
	FormatCashExpensesAggrid( ){
	
		let cash_expenses = 0;
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( loading ){
			
				let Am = ( loading.cash_expenses ? parseFloat( loading.cash_expenses ) : 0 ) * ( loading.cash_expenses_rate ? parseFloat( loading.cash_expenses_rate ) : 0 );
				
				cash_expenses = cash_expenses + Am;
		
			}
		}
	
		return cash_expenses / this.state.rates[ 3 ];
	
	}
	
	FormatExpensesLoadings( ){
	
		let ExpensesObject = { };
	
		let ExpensesArray = [ ];
		
		let ExpensesTotal = 0;
		
				
		for(
			let i in this.state.expenses
		){
		
		
			let ExpenseElement = this.state.expenses[ i ];
			
			let manager = ExpenseElement.manager;
			
			
			if( this.state.manager == null ){
			
				continue;
			
			}
			
			//Kostyl neobhodimiy dlya svyazivania podhory(29) s sicho(14)
			
			if( this.state.manager == "14" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else if( this.state.manager == "29" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else {
			
				if( this.state.manager != manager ){
				
					continue;
				
				}
			
			}
			
			if( ExpenseElement.loadings == null ){
			
				continue;
			
			}
			
			let loadings = ExpenseElement.loadings.split( "//" );
			
			
			let netto_in_expense_kg = 0;
			
			for(
				let i2 in loadings
			){
			
				let loadingId = loadings[ i2 ];
				
				let load = this.state.loadingsObject[ loadingId.toString( ) ];
				
				if( load ){
				
					console.log( load.loading_number + " " + load.company, load.net_material_qty );
				
					netto_in_expense_kg += ( load.net_material_qty ) ? load.net_material_qty : 0;
				
				}
				
			}
			
			console.log( "netto_in_expense_kg", netto_in_expense_kg );
			
			let amount_per_kg = ( ExpenseElement.amount_eur ? ( parseFloat( ExpenseElement.amount_eur ) / netto_in_expense_kg ) : 0 );
			
			console.log( "amount_per_kg", amount_per_kg );
			
			let amount_per_expense = 0;
			
			let loadings_numbers = [ ];
			
			for(
				let i2 in this.state.loadingsSelected
			){
			
				if( !loadings.includes( this.state.loadingsSelected[ i2 ].value.toString( ) ) ){
				
					continue;
				
				}
				
				let load = this.state.loadingsObject[ this.state.loadingsSelected[ i2 ].value.toString( ) ];
				
				if( load ){
				
					loadings_numbers.push( load.loading_number );
					
					amount_per_expense += ( ( load.net_material_qty ) ? ( load.net_material_qty * amount_per_kg ) : 0 );

				}

			}
			
			console.log( "amount per expense", amount_per_expense );
			
			
			let ExpenseType = "";
			
			for(
				let i3 in this.state.expense_types
			){
			
				if( this.state.expense_types[ i3 ].id.toString( ) == ExpenseElement.expense_type.toString( ) ){
					
					ExpenseType = this.state.expense_types[ i3 ][ "name_" + this.state.locale ];
					
				}
			
			}
			
			let IncludeLoading = false;
			
			for(
				let i2 in this.state.loadingsSelected
			){
	
				if( !loadings.includes( this.state.loadingsSelected[ i2 ].value.toString( ) ) ){
				
					continue;
				
				}
				
				IncludeLoading = true;
				
				break;
				
			}
			
			if( IncludeLoading == false ){
			
				continue;
			
			}
				
			let Am = amount_per_expense;
			
			ExpensesTotal += Am;
		
				
			if( !ExpensesObject[ ExpenseType ] ){
			
				ExpensesObject[ ExpenseType ] = { type: ExpenseType, amount: Am };
				
			} else {
			
				ExpensesObject[ ExpenseType ].amount = ExpensesObject[ ExpenseType ].amount + Am;
			
			}
			
		}
		
		for(
			let i in ExpensesObject
		){
		
			ExpensesArray.push( { type: ExpensesObject[ i ].type, amount: ExpensesObject[ i ].amount } );
		
		}
		
		let Total = ( ExpensesTotal * this.state.rates[ 3 ] ).toFixed( 2 ) + " Kč" + " | " + ExpensesTotal.toFixed( 2 ) + " €";
		
		return { Array: ExpensesArray, Total: Total };

	}
	
	HandleChangeDatetime( Ev ){
	
		console.log( "HandleChangeDatetime", Ev );

		this.setState( {
			datetime: Ev.format( "DD.MM.YYYY hh:mm:ss" ),
			datetime_server: Ev.format( "YYYY-MM-DD hh:mm:ss" )
		} );
	
	}
	
	HandleChangeProvisionDatetime( Ev ){
	
		console.log( "HandleChangeProvisionDatetime", Ev );

		this.setState( {
			provision_datetime: Ev.format( "DD.MM.YYYY hh:mm:ss" ),
			provision_datetime_server: Ev.format( "YYYY-MM-DD hh:mm:ss" )
		} );
	
	}
	
	HandleChangeProvision( Ev ){
	
		console.log( "HandleChangeProvision", Ev );
	
		this.setState( {
			provision: parseFloat( Ev.target.value )
		} );
	
	}
	
	MakeItemManager( Item ) {
	
		console.log( "MakeItemManager", Item );
		
		
		let users = JSON.parse( localStorage.getItem( 'users' ) );
				
		let top_manager = null;
				
		let manager = null;
				
		
				
		for(
			let i in users
		){
			
			if( 
				( 
					( this.state.manager != null ) && ( this.state.manager != "" ) 
				) && 
				( users[ i ].id.toString( ) == this.state.manager.toString( ) ) 
			){
				
				manager = this.props.t('Manager') + ": " + users[ i ][ 'name_' + this.state.locale ];
				
				break;
				
			}
			
		}
		
		for(
			let i in users
		){
			
			if( 
				( 
					( this.state.top_manager != null ) && ( this.state.top_manager != "" ) 
				) && 
				( users[ i ].id.toString( ) == this.state.top_manager.toString( ) ) 
			){
				
				top_manager = this.props.t('Top manager') + ": " + users[ i ][ 'name_' + this.state.locale ];
				
				break;
				
			}
			
		}
		
		if( top_manager != null ){
		
			return <><option key={1} value={1}>{top_manager}</option><option key={0} value={0}>{manager}</option></>;
		
		}
		
		
		return <option key={0} value={0}>{manager}</option>;
		
    }
	
	HandleChangeManager( Ev ){
	
		console.log( "HandleChangeManager", Ev );
		
		let manager = Ev.target.value;
	
		this.setState( { 
			is_top: Ev.target.value
		} );
	
	}
	
	HandleSubmit( event ) {
	
		event.preventDefault( );
		
		console.log( this.state.loadingsObject );
	
		let AlertedProvised = false;
		
		for( let key in this.state.loadingsSelected ){
			
			let loading = this.state.loadingsObject[ this.state.loadingsSelected[ key ].value ];
			
			if(
				( ( loading.provision !== null ) || ( loading.provision_paid_datetime != null ) ) && ( this.state.is_top.toString( ) == "0" )
			){
			
				AlertedProvised = true;
				
				break;
			
			}
				
			if( 
				( ( loading.provision_top !== null ) || ( loading.provision_top_paid_datetime != null ) ) && ( this.state.is_top.toString( ) == "1" )
			){
					
				AlertedProvised = true;
				
				break;
				
			}
			
		}
		
		if( AlertedProvised == true ){
			
			if( this.state.locale == 'cs' ){
				
				alert( "Provize za jednu z vybraných nakládek již byla zaplacena" );
				
			} else {
				
				alert( "One or more of choosed loadings already provised" );
				
			}
			
		} else {
	
			this.setState( { disabled: true } );
			
			this.setState( { spinner: 'inline' } );
			
			let Ids = [ ];
			
			for(
				let i in this.state.loadingsSelected
			){
			
				Ids.push( this.state.loadingsSelected[ i ].value );
				
			}
			
			this.props.onSet( {
				ids: JSON.stringify( Ids ),
				is_top: this.state.is_top,
				provision: this.state.provision,
				provision_datetime: this.state.provision_datetime_server
			} );
			
		}
		
    }
	
	onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected: true});
        } else {
            this.setState({rowSelected: false});
        }
    }
	
	onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            var columnSettingsName = 'LoadingsFinalCalculationColumns';
            SaveState(columnSettingsName, params.columnApi, params.api);
        }
    }
	
	onFilterChanged( params ) {
		
		console.log( "onFilterChanged" );
		
        if( typeof( this.gridApi ) != 'undefined' ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
			
            let componentInstance = statusBarComponent;
			
            if( ( typeof( statusBarComponent ) !== 'undefined' ) && statusBarComponent.getFrameworkComponentInstance) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }
	
	getContextMenuItems( params ){

        let result = [

            'copy',
            'paste',
            'export',


        ];

        return result;
		
    }
	
	onGridReady = (params) => {
		
        this.gridApi = params.api;
		
        this.gridColumnApi = params.columnApi;
	

        this.gridApi.setDomLayout('normal');
		
    };
	
	onCellValueChanged( Ev ){
	
		console.log( Ev );

    }
	
	
	export_to_excel( ) {

		this.gridApi.exportDataAsExcel({
			shouldRowBeSkipped: function (params) {
				return false;
			},
			processCellCallback: function(params){
			
				console.log( params, this.formulaData1TotalIndex );
				
				if( ( params.node.rowIndex == this.formulaData1TotalIndex ) && params.column.colId == "column6" ){
				
					const valueGetter = params.column.getColDef( ).valueGetter;
					
					let Res = [ ];
					
					for(
						let Iterator = 3;
						Iterator < this.formulaData1TotalIndex + 1;
						Iterator++
					){
					
						Res.push( "F" + Iterator.toString( ) );
					
					}
					
					return !!valueGetter ? ( "=" + Res.join( "+" ) ) : params.value;
				
				}
				
				if( ( params.node.rowIndex == this.formulaData2TotalIndex ) && params.column.colId == "column6" ){
				
					const valueGetter = params.column.getColDef( ).valueGetter;
					
					let Res = [ ];
					
					for(
						let Iterator = this.formulaData2TotalIndex - 8;
						Iterator < this.formulaData2TotalIndex + 1;
						Iterator++
					){
					
						Res.push( "F" + Iterator.toString( ) );
					
					}
					
					return !!valueGetter ? ( "=" + Res.join( "+" ) ) : params.value;
				
				}
				
				if( ( params.node.rowIndex >= this.formulaData1TotalIndex - 11 ) && ( params.node.rowIndex <= this.formulaData1TotalIndex - 2 ) && params.column.colId == "column6" ){
				
					const valueGetter = params.column.getColDef( ).valueGetter;
				
					return !!valueGetter ? ( '=C' + ( params.node.rowIndex + 1 ) + "*" + "D" + ( params.node.rowIndex + 1 ) ) : params.value;
				
				}
			
				/*if( params.node.rowIndex == 20 && params.column.colId == "column6" ){
				
					console.log( "fount" );
				
					const valueGetter = params.column.getColDef( ).valueGetter;
					
					return !!valueGetter ? `=F2+F2+F3+F4+F5+F6+F7+F8+F9+F10+F11+F12+F13+F14+F15+F16+F17+F18+F19+F20` : params.value;
				
				}
				
				if( params.node.rowIndex >= 9 && params.node.rowIndex <= 18 && params.column.colId == "column6" ){
				
					const valueGetter = params.column.getColDef( ).valueGetter;
					
					return !!valueGetter ? ( '=C' + ( params.node.rowIndex + 1 ) + "*" + "D" + ( params.node.rowIndex + 1 ) ) : params.value;
				
				}
				
				if( params.node.rowIndex == 31 && params.column.colId == "column6" ){
				
					console.log( "fount" );
				
					const valueGetter = params.column.getColDef( ).valueGetter;
					
					return !!valueGetter ? `=F23+F24+F25+F26+F27+F28+F29+F30+F31` : params.value;
				
				}*/
			
				const colDef = params.column.getColDef()
				
				if (colDef.valueFormatter) {
				
					const valueFormatterParams = {
						...params,
						data: params.node.data,
						node: params.node,
						colDef: params.column.getColDef()
					};
					
					return colDef.valueFormatter(valueFormatterParams);
					
				} else {
					
					return params.value;
					
				}
				
			}.bind( this ),
			skipColumnHeaders: true
		});
			
    }
	
	
	
	
	FillManager( ){
	
		let row_data_manager = [
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			}
		];
		
		row_data_manager[ 0 ][ "column1" ] = this.FormatManager( ) + " " + this.state.datetime;
		
		return row_data_manager;
	
	}
	
	FillData1( ){
		
		let row_data_1_header = [
			{
				"column1": "",
				"column2": "",
				"column3": "Množstvi kg",
				"column4": "Cena za kg/ eur",
				"column6": "Cena celkem/eur"
			}
		];
		
		let Calc = this.FormatCalc( );
			
		let row_data_1_nakup = [
			{
				"column1": "Nakup",
				"column2": "",
				"column3": Calc.weight, 
				"column4": "",
				"column6": ( Calc.amount_eur + this.FormatCashExpensesAggrid( ) ).toFixed( 2 )
			}
		];
		
		let row_data_1_doprava = [
			{
				"column1": "DOPRAVA",
				"column2": "",
				"column3": Calc.weight, 
				"column4": "",
				"column6": ( Calc.transportation_amount_eur ).toFixed( 2 )
			}
		];
		
		
		let FormatExpensesResult = this.FormatExpensesLoadings( );
		
		let DataExpenses = FormatExpensesResult.Array;
		
		let row_data_1_expenses = [
		
		];
		
		for(
			let i in DataExpenses
		){
		
			let DataExpense = DataExpenses[ i ]
		
			row_data_1_expenses.push(
				{
					"column1": DataExpense.type,
					"column2": "",
					"column3": "",
					"column4": "",
					"column5": "",
					"column6": DataExpense.amount.toFixed( 2 )
				}
			);
		
		}
	
	
		let Data = this.FormatProcessedMaterialByType( );
	
		let row_data_1_main = [
			{
				"column1": "Zpracovaní",
				"column2": "Silnice",
				"column3": ( Data[ "Silnice" ] ) ? Data[ "Silnice" ].weight.toFixed( 2 ) : "0.00",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Energie",
				"column2": "Silnice",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Mořeni, triděni, baleni, doprava",
				"column2": "Silnice",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Zpracovaní stroj",
				"column2": "Hlavice",
				"column3": ( Data[ "Hlavice" ] ) ? Data[ "Hlavice" ].weight.toFixed( 2 ) : "0.00",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Energie stroj",
				"column2": "Hlavice",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Moreni gavanovna",
				"column2": "Hlavice",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Zpracovaní",
				"column2": "Diamanty",
				"column3": ( Data[ "Diamanty" ] ) ? Data[ "Diamanty" ].weight.toFixed( 2 ) : "0.00",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Energie",
				"column2": "Diamanty",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Mořeni, triděni, baleni, doprava",
				"column2": "Diamanty",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Ostatní naklady",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Rozdil fa hotovost",
				"column2": "",
				"column3": ( this.FormatCashExpensesAggrid( ) ).toFixed( 2 ),
				"column4": "",
				"column6": "0.00"
			}
		];
			
		let row_data_2_total = [
			{
				"column1": "Celkem naklady nakup, doprava, zpracovaní",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			}
		];
		
		let row_data_space = [
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			}
		];
		
		
		return [ ...row_data_1_header, ...row_data_1_nakup, ...row_data_1_doprava, ...row_data_1_expenses, ...row_data_1_main, ...row_data_2_total, ...row_data_space ];
		
	}
	
	FillData2( ){
	
		let row_data_2 = [
			{
				"column1": "Železo prodej",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Železo prodej šrot",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Železo prodej",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Železo prodej",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Silnice",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Silnice",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Silnice",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Hlavice epiroc",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Prodej novych spicek",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			}
		];
		
		let row_data_2_total = [
			{
				"column1": "Celkem prodej",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			}
		];
		
		let row_data_space = [
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			}
		];
	
		return [ ...row_data_2, ...row_data_2_total, row_data_space ];
	
	}

	FillData3( ){

		let row_data_3 = [
			{
				"column1": "ZISK",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "MICHAL-PAVEL fa",
				"column2": "25%",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "ZISK společnost",
				"column2": "75%",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "K VYPLACENI hot",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Michal Fa",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "ZAPLACENE FAKTURY MANAGERUM",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "FAKTURA LEDEN 23",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "k vyplaceni fa",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "k vyplaceni hot",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "zaloha /DLUH",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "k uhradě",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "Pavel fa",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "Pavel hot",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "DOPLATEK ZA NAKUP",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
			
			{
				"column1": "k vyplaceni fa",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "K vyplaceni HOTOVOST",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": "0.00"
			},
			
			{
				"column1": "",
				"column2": "",
				"column3": "",
				"column4": "",
				"column6": ""
			},
		
		];
		
		return [ ...row_data_3 ];
	
	}
	
	FillTable( ){
	
		let Data = this.FormatProcessedMaterialByType( );
	
		console.log( Data );
		
		let row_data = this.state.rowdata;
		
		
	
		
		//TODO Fill nakup - invoices + inc_vat + cash_expenses na loadings
	
		if( Data[ "Silnice" ] ){
		
			row_data[ 9 ][ "column3" ] = Data[ "Silnice" ].weight;
		
		} else {
		
			row_data[ 9 ][ "column3" ] = 0;
		
		}
		
		if( Data[ "Hlavice" ] ){
		
			row_data[ 12 ][ "column3" ] = Data[ "Hlavice" ].weight;
		
		} else {
		
			row_data[ 12 ][ "column3" ] = 0;
		
		}
		
		if( Data[ "Diamanty" ] ){
		
			row_data[ 15 ][ "column3" ] = Data[ "Diamanty" ].weight;
		
		} else {
		
			row_data[ 15 ][ "column3" ] = 0;
		
		}
		
		row_data[ 19 ][ "column3" ] = ( this.FormatCashExpensesAggrid( ) ).toFixed( 2 );
		
		//this.gridApi.setRowData( row_data );
		
		this.setState( {
			rowdata: row_data
		}, function(){

			this.gridApi.redrawRows( );
			
			this.FillLoadings( );

		}.bind( this ) );
	
	}
	
	FillLoadings( ){
	
		console.log( "FillLoadings", this.state.loadingsSelected, this.state.loadingsObject );
	
		let row_data = [ ]; 
		
		let loadingsData = { };
		
		for(
			let i in this.state.loadingsSelected
		){
		
			let loading = this.state.loadingsObject[ this.state.loadingsSelected[ i ].value ];
			
			loadingsData[ loading.loading_number ] = {
				loading_number: loading.loading_number.toString( ),
				company: loading.company,
				zelezo: 0,
				tvrdokov: 0,
				celkem: 0,
				percent: 0.0
			};
		
		}
	
		for(
			let i in this.state.results
		){
		
			let result = this.state.results[ i ];
			
			let isZelezo = false;
			
			if( result.product_type.toString( ) == "10" ){
			
				isZelezo = true;
			
			}
			
			if( !loadingsData[ result.loading_number ] ){
			
				continue;
			
			}
			
			if( result.qty != 0 ){
			
				if( isZelezo == true ){
			
					loadingsData[ result.loading_number ].zelezo = loadingsData[ result.loading_number ].zelezo + result.qty;
				
				} else {
				
					loadingsData[ result.loading_number ].tvrdokov = loadingsData[ result.loading_number ].tvrdokov + result.qty;
					
				}
			
			}
		
		}
	
		row_data.push( {
			"column1": "VYUČTOVANE NAKLADKY"
		} );
			
		row_data.push( {
			"column1": "ČÍSLO NAKLADKY",
			"column2": "SPOLEČNOST",
			"column3": "železo",
			"column4": "tvrdokov",
			"column5": "celkem",
			"column6": "procent"
		} );
		
		let total_zelezo = 0;
		
		let total_tvrdokov = 0;
		
		for(
			let i in loadingsData
		){
		
			let loading = loadingsData[ i ];
			
			let percent = 0.0;
			
			if( loading.zelezo == 0 && loading.tvrdokov == 0 ){
			
				percent = 0.0;
			
			} else if( loading.zelezo == 0 ){
			
				percent = 100.0;
			
			} else if( loading.tvrdokov == 0 ){
			
				percent = 0.0;
			
			} else {
				
				percent = ( loading.tvrdokov / ( loading.zelezo + loading.tvrdokov ) * 100 );
				
			}
		
			row_data.push( {
				"column1": loading.loading_number.toString( ),
				"column2": loading.company,
				"column3": loading.zelezo,
				"column4": loading.tvrdokov,
				"column5": loading.zelezo + loading.tvrdokov,
				"column6": percent.toFixed( 2 )
			} );
			
			total_zelezo = total_zelezo + loading.zelezo;
			
			total_tvrdokov = total_tvrdokov + loading.tvrdokov;
		
		}
		
		row_data.push( {
			"column1": " ",
			"column2": "",
			"column3": "",
			"column4": "",
			"column5": "",
			"column6": "",
		} );
		
		let total_percent = 0.0;
			
		if( total_zelezo == 0 && total_tvrdokov == 0 ){
		
			total_percent = 0.0;
		
		} else if( total_zelezo == 0 ){
		
			total_percent = 100.0;
		
		} else if( total_tvrdokov == 0 ){
		
			total_percent = 0.0;
		
		} else {
			
			total_percent = ( total_tvrdokov / ( total_zelezo + total_tvrdokov ) * 100 );
			
		}
		
		row_data.push( {
			"column1": "CELKEM",
			"column2": " ",
			"column3": total_zelezo,
			"column4": total_tvrdokov,
			"column5": total_zelezo + total_tvrdokov,
			"column6": total_percent.toFixed( 2 )
		} );
	
		return row_data;
	
	}
	
	
	FillTotal( ){
	
	
		this.rowColSpan = [ ];
		
		this.rowColorLightBlueColumn3 = [ ];
		this.rowColorLightBlueColumn6 = [ ];
		this.rowColorLightBlue = [ ];
		
		this.rowColorLightBlueCenter = [ ];
		
		this.rowColorYellow = [ ];
		
		this.rowColorYellowRedText = [ ];
		
		this.rowColorWhiteRedText = [ ];
		this.rowColorWhiteGreenText = [ ];
		this.rowColorGray = [ ];
	
	
		let Index = 0;
		
		this.rowColorLightBlueCenter.push( Index );
		
		this.rowColSpan.push( Index );
	
		let ManagerSegment = this.FillManager( );
		
		Index = Index + ManagerSegment.length;
		
		this.rowColorLightBlueColumn3.push( Index + 1 );
		
		this.rowColorLightBlueColumn3.push( Index + 2 );
		
		let Data1Segment = this.FillData1( );
		
		Index = Index + Data1Segment.length;
		
		this.formulaData1TotalIndex = Index - 2;
		
		for(
			let i = 14;
			i < Data1Segment.length;
			i++
		){
		
			this.rowColorLightBlueColumn6.push( Index - i );
		
		}
		
		this.rowColorLightBlueColumn3.push( Index - 13 );
		
		this.rowColorLightBlueColumn3.push( Index - 10 );
		
		this.rowColorLightBlueColumn3.push( Index - 7 );
		
		this.rowColorLightBlueColumn3.push( Index - 3 );
		
		this.rowColSpan.push( Index - 1 );
		
		this.rowColorYellow.push( Index - 2 );
		
		let Data2Segment = this.FillData2( );
		
		Index = Index + Data2Segment.length;
		
		this.formulaData2TotalIndex = Index - 2;
		
		this.rowColSpan.push( Index - 1 );
		
		this.rowColorYellow.push( Index - 2 );
		
		this.rowColorGray.push( Index );
		
		this.rowColorYellowRedText.push( Index + 3 );
		
		this.rowColorWhiteRedText.push( Index + 5 );
		
		this.rowColorWhiteRedText.push( Index + 14 );
		
		this.rowColorWhiteGreenText.push( Index + 8 );
		
		this.rowColorWhiteGreenText.push( Index + 17 );
		
		this.rowColorWhiteGreenText.push( Index + 18 );
		
		this.rowColSpan.push( Index );
		
		let Data3Segment = this.FillData3( );
		
		Index = Index + Data3Segment.length;
		
		this.rowColorLightBlueCenter.push( Index );
		
		this.rowColSpan.push( Index - 16 );
		
		this.rowColSpan.push( Index - 8 );
		
		this.rowColSpan.push( Index - 4 );
		
		this.rowColSpan.push( Index - 1 );
		
		this.rowColSpan.push( Index );
		
		let DataLoadings = this.FillLoadings( );
	
		for(
			let i = 1;
			i < DataLoadings.length;
			i++
		){
		
			this.rowColorLightBlueColumn3.push( Index + i );
			
			this.rowColorLightBlueColumn6.push( Index + i );
			
			this.rowColorLightBlue.push( Index + i );
		
		}
		
		Index = Index + DataLoadings.length;
		
		this.rowColorLightBlueCenter.push( Index - 2 );
		
		this.rowColSpan.push( Index - 2 );
	
		let TotalArray = [ ...ManagerSegment, ...Data1Segment, ...Data2Segment, ...Data3Segment, ...DataLoadings ];
		
		if( this.gridApi ){
		
			this.gridApi.setRowData( TotalArray );
	
		}
		
	}
	

    render( ) {
		
		return (
			<>
				<div className="container" style={{width: '100%', maxWidth: '100%' }}>
					<button type="button"
						className="btn btn-primary btn-sm"
						onClick={this.export_to_excel.bind(this)}
					><i className="fa  fa-file-excel"></i>
					</button>
				</div>
				<hr />
				<div style={{'height': ( window.innerHeight - 600 ) + "px", 'width': '100%' }} id="loadingsFinalCalculationGrid" className="ag-theme-alpine">
                    <AgGridReact
                        modules={this.state.modules}
                        rowData={this.state.rowdata}
                        columnDefs={this.state.columnDefs}
						excelStyles={this.state.excelStyles}
						defaultExcelExportParams={this.state.defaultExcelExportParams}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        onCellValueChanged={this.onCellValueChanged.bind( this )}
                        onCellClicked={this.onCellClicked}
                        frameworkComponents={this.state.frameworkComponents}
                        components={this.state.components}
                        enableCellChangeFlash={true}
                        rowSelection={'multiple'}
                        popupParent={this.state.popupParent}
                        suppressContextMenu={false}
                        pagination={true}
                        onRowSelected={this.onRowSelected.bind(this)}
                        paginationPageSize={1000}
                        localeText={this.state.localeText}
                        statusBar={this.state.statusBar}
                        getContextMenuItems={this.getContextMenuItems}
                        enableGroupEdit={true}
                        onColumnVisible={this.onColumnsStateChanged.bind(this)}
                        onDragStopped={this.onColumnsStateChanged.bind(this)}
                        onFilterChanged={this.onFilterChanged.bind(this)}
                        suppressAggFuncInHeader={true}
                        suppressRowClickSelection={true}
                        autoGroupColumnDef={{
                            headerName: this.props.t('Loading number'),
                            cellRenderer: 'agGroupCellRenderer',

                            cellRendererParams: {
                                suppressCount: true, 
								checkbox: function (params) {

                                    return true;

                                }
                            }
                        }}
                        suppressChangeDetection={true}


                    />
                </div>
				<hr />
				<div className="container" style={{width: '100%', maxWidth: '100%' }}>
					<form onSubmit={(event) => this.HandleSubmit(event)}>
						<div className="container" style={{width: '100%', maxWidth: '100%'}}>
							<div className="row">
								<div className="col-3">
									<div className="form-group">
										<label htmlFor="description">{this.props.t('Provise to')}</label>
										<select
											value={this.state.is_top}
											required
											className="form-control"
											onChange={(Ev) =>
												this.HandleChangeManager( Ev )
											}>
											<option value="">{this.props.t('Choose')}</option>
											{
												this.MakeItemManager( )
											}
										</select>
									</div>
								</div>
								<div className="col-3" style={{marginRight: '10px'}}>
									<div className="form-group">
										<label htmlFor="description">{this.props.t('Provision date')}</label>
										<Datetime
											timeFormat="hh:mm:ss"
											locale={this.state.locale}
											dateFormat="DD.MM.YYYY"
											value={this.state.provision_datetime}
											inputProps={{required: true}}
											onChange={(Ev) => 
												this.HandleChangeProvisionDatetime( Ev )
											}
										/>
									</div>
								</div>
								<div className="col-3" style={{marginRight: '10px', display:'none'}}>
									<div className="form-group">
										<label htmlFor="description">{this.props.t('Provision')}</label>
										<input
											type="text"
											disabled={false}
											pattern="^[0-9]+$"
											className="form-control"
											value={this.state.provision}
											placeholder={this.props.t('Enter the value')}
											onChange={(Ev) =>
												this.HandleChangeProvision( Ev )
											}
										/>
									</div>
								</div>
								<div className="col-3" style={{marginRight: '10px'}}>
								
								</div>
							</div>
							
							<div className="row">
								<div className="col-3" style={{marginRight: '10px'}}>
									<button 
										disabled={this.state.disabled}
										className="btn btn-success"
									>
										{this.props.t('Provision pay')}
										<div style={{display: this.state.spinner}}>
											<i className="fas fa-spinner fa-spin"></i>
										</div>
									</button>
								</div>
							</div>
							
						</div>
					</form>
				</div>
			</>
        );
    }
}

LoadingsFinalCalculationTable.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingsFinalCalculationTable);

