import { Component } from "react";

import { translate } from 'react-polyglot';

import PropTypes from 'prop-types';
 
import Grid from "../grid";


class InvoiceItemName extends Component< Props > {
	
	#Title = null;
	#ModelName = null;
	#ModelSchema = null;
	
	#CheckboxField = null;
	#PinnedLeftFields = null;
	#PinnedRightFields = null;
			
	#Modals = null;
	#Controls = null;
	
	#Fields = null;
	
	#Meta = null;
	
	#Prepare = null;
	

    constructor( props ) {
		
		super( props );

        this.#Title = this.props.t( "Outinvoice item names" );
			
		this.#ModelName = "outinvoice_item_names";
		
		this.#ModelSchema = {
			model_name: "outinvoice_item_names",
			type: null,
			type_data: null,
			preload_options: [
				{
					model_name: "users",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name" ],
					fields_label_joiner: ""
				},
				{
					model_name: "companies",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name" ],
					fields_label_joiner: "",
					fields_additional: [ "active" ]
				}
			],
			
			preload_custom_options: [
				
			],
			
			children: [
			
			]
			
		};
		
		this.#Prepare = {
			data: {
				
			}
		};
		
		this.#CheckboxField = "name";
			
		this.#PinnedLeftFields = [ ];
			
		this.#PinnedRightFields = [ ];
		
		this.#Fields = [
			"name",
			"company_ids",
			"code",
			"dph",
			"created_at",
			"created_by",
			"updated_at",
			"updated_by"
		];
		
		this.#Meta = {
			"name": {
				IsRequired: true,
				RegExpMatch: "^.{0,4096}",
				RegExpValidation: "^.{1,4096}$",
				Placeholder: this.props.t( "Enter the value" )
			},
			"company_ids": {
				IsRequired: false,
				Placeholder: this.props.t( "Choose" ),
				OptionsFilter: function( Values, Options ){
														
					console.log( "OptionsFilter", Values, Options );
					
					let FilteredOptions = new Map( );
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						if( Boolean( Option.fields.active ) === false ){
							
							continue;
							
						}
						
						FilteredOptions.set( I, Option );
						
					}
					
					return FilteredOptions;
					
				}
			},
			"code":{
				IsRequired: false,
				RegExpMatch: "^.{0,4096}",
				RegExpValidation: "^.{1,4096}$",
				Placeholder: this.props.t( "" )
			},
			"dph": {
				IsRequired: false,
				RegExpMatch: "^[0-9]{0,8}",
				RegExpValidation: "^[1-9]{1}[0-9]{0,7}$",
				Placeholder: this.props.t( "0" )
			},
			"created_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			},
			"updated_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			},
			"deleted_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			}
		};
		
		this.#Modals = [
			{
				Type: "Form",
				TypeData: {
					
					Id: "FormAdd",
					Title: this.props.t( "Add new" ),
					MaxWidth: "70vw",
					MaxHeight: "80vh", //TODO
					Children: [
						{
							Type: "Table",
							TypeData: {
								Children: [
									{
										Type: "Row",
										TypeData: {
											Children: [
												{
													Type: "Cell",
													TypeData: {
														Model: "outinvoice_item_names",
														Field: "name",
														Name: this.props.t( "Title" ),
														ClassName: "col-3",
														IsCustom: false,
														IsRequired: true,
														Editable: true,
														DefaultValue: "",
														Placeholder: this.props.t( "Enter the value" ),
														RegExpMatch: "^.{0,4096}",
														RegExpValidation: "^.{1,4096}$"
													}
													
												},
												{
													Type: "Cell",
													TypeData: {
														Model: "outinvoice_item_names",
														Field: "company_ids",
														Name: this.props.t( "Companies" ),
														ClassName: "col-3",
														IsCustom: false,
														IsRequired: false,
														Editable: true,
														DefaultValue: "",
														Placeholder: this.props.t( "Choose" ),
														OptionsFilter: function( Values, Options ){
															
															console.log( "OptionsFilter", Values, Options );
															
															let FilteredOptions = new Map( );
															
															for(
																let I of Options.keys( )
															){
																
																let Option = Options.get( I );
																
																if( Boolean( Option.fields.active ) === false ){
																	
																	continue;
																	
																}
																
																FilteredOptions.set( I, Option );
																
															}
															
															return FilteredOptions;
															
														}
													}
													
												},
												{
													Type: "Cell",
													TypeData: {
														Model: "outinvoice_item_names",
														Field: "code",
														Name: this.props.t( "Code" ),
														ClassName: "col-3",
														IsCustom: false,
														IsRequired: false,
														Editable: true,
														DefaultValue: "",
														Placeholder: this.props.t( "" ),
														RegExpMatch: "^.{0,4096}",
														RegExpValidation: "^.{1,4096}$"
													}
													
												},
												{
													Type: "Cell",
													TypeData: {
														Model: "outinvoice_item_names",
														Field: "dph",
														Name: this.props.t( "VAT rate %" ),
														ClassName: "col-3",
														IsCustom: false,
														IsRequired: false,
														Editable: true,
														DefaultValue: "",
														Placeholder: this.props.t( "0" ),
														RegExpMatch: "^[0-9]{0,8}",
														RegExpValidation: "^[0-9]{1,8}$"
													}
													
												}
											]
										}
									},
									{
										Type: "Row",
										TypeData: {
											Children: [
												{
													Type: "CellContainer",
													TypeData: {
														ClassName: "col-2 CellForButton",
														Children: [
															{
																Type: "Button",
																TypeData: {
																	Id: "FormAddButtonFormSend",
																	Title: this.props.t( "Save" ),
																	ClassName: "btn btn-success btn-sm ButtonInCell",
																	Icon: null,
																	Action: "FormSend",
																	ActionData: {
																		Fields: [ 
																			"company_ids",
																			"dph",
																			"code",
																			"name"
																		]
																	}
																}
															}
														]
													}
												},
												{
													Type: "CellContainer",
													TypeData: {
														ClassName: "col-2 CellForButton",
														Children: [
															{
																Type: "Button",
																TypeData: {
																	Id: "FormAddButtonFormReset",
																	Title: this.props.t( "Clear" ),
																	ClassName: "btn btn-primary btn-sm ButtonInCell",
																	Icon: null,
																	Action: "FormReset",
																	ActionData: {
																		Fields: [ 
																			"company_ids",
																			"dph",
																			"name",
																			"code"
																		]
																	}
																}
															}
														]
													}
												}
											]
											
										}
									}
								]
							}
						}
					]
				}
			}
		];
		
		this.#Controls = [
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonAdd",
					Title: this.props.t( "Add new" ),
					ClassName: "btn btn-success btn-sm",
					Icon: "fa fa-plus",
					Action: "ModalOpen",
					ActionData: {
						ModalId: "FormAdd"
					}
				}
			},
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonDelete",
					ClassName: "btn btn-danger btn-sm",
					Icon: "fa fa-trash",
					Title: this.props.t( "Delete" ),
					Action: "DeleteRows",
					ActionData: {
						ToDelete: "Selected"
					}
				}
			}
		];

    }
	
	componentDidMount( ){
		
		console.log( "InvoiceItemName", "componentDidMount" );
		
	}

    componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InvoiceItemName", "componentDidUpdate" );
        
    }
	
	render( ){
		
		return ( 
			<>
				<Grid 
					title = { this.#Title }
					meta = { this.#Meta }
					fields = { this.#Fields }
					model_name = { this.#ModelName }
					model_schema = { this.#ModelSchema }
					prepare = { this.#Prepare }
					pinned_left_fields = { this.#PinnedLeftFields }
					pinned_right_fields = { this.#PinnedRightFields }
					checkbox_field = { this.#CheckboxField }
					modals = { this.#Modals }
					controls = { this.#Controls }
				>
				
				</Grid>
			</>
		);
		
	}

};


InvoiceItemName.propTypes = {
	
    t: PropTypes.func.isRequired
	
};


export default translate( )( InvoiceItemName );

