import React from "react";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
class Communication  extends React.Component {

    constructor(props) {


        super(props)
        this.state = {

            from:null,
            to:null,
            subject:null,
            text:null,
            attachment:[],

        };
    }




    componentDidUpdate(prevProps) {
        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.id!==null)  {

                HttpService.get(UrlService.apiDomain() + 'api/communications/' + this.props.id)
                    .then(res => {

                        let att=[];
                        if (res.data.attachment!==null && res.data.attachment!=='') att=JSON.parse(res.data.attachment);
                        this.setState({

                            from:res.data.from,
                            to:res.data.to,
                            subject:res.data.subject,
                            text:res.data.text,
                            attachment:att,
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }


    shouldComponentUpdate( nextProps, nextState ){

        if (this.props.show===true && nextProps.show===false) {
            // cleaning aggrid
            this.setState({
                from:null,
                to:null,
                subject:null,
                text:null,
                attachment:[],
            })
        }

        if (
            (nextProps.show===true && this.props.show===false) ||
            (nextProps.show===true && this.props.show===true)
        ) {

            return true
        } else {
            return false
        }
    }

    MakeAttachment (X) {

        function downloadAttachment(X) {
            HttpService.postblob(UrlService.apiDomain() + 'api/attachments/download_attachment', {attachment:X})
                .then((response) => {
                    // Create blob link to download

                    const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    let tmp=X.split('/');
                    let filename=tmp[tmp.length-1];
                    link.setAttribute(
                        'download',
                        filename,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                });
        }
let tmp=X.split('/');
let filename=tmp[tmp.length-1];
        return <button
            href="#"
            className="btn btn-block btn-info btn-xs float-right"
            onClick={(event) => {
                event.preventDefault();
                downloadAttachment(X);
            }}
        >
            {filename}
        </button>;
    };



    render() {


        return (


            <div key={this.props.id}>
                <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                        <b>{this.props.t('From')}</b>
                        <span
                            className="float-right"

                        >
                {this.state.from}
              </span>
                    </li>

                    <li className="list-group-item">
                        <b>{this.props.t('To')}</b>
                        <span
                            className="float-right"

                        >
                 {this.state.to}
              </span>
                    </li>
                    {this.state.attachment.length > 0 &&
                    <li className="list-group-item">
                        <b>{this.props.t('Attachments')}</b>
                        <span
                            className="float-right"

                        >
                 {this.state.attachment.map(this.MakeAttachment)}
              </span>
                    </li>
                    }

                </ul>


                <h5>{this.state.subject}</h5>
                <div style={{height:'350px', overflow:'auto',}} >

                {/<\/?[a-z][\s\S]*>/i.test(this.state.text) === false &&
                <div style={{'whiteSpace': 'pre-wrap'}}>
                    {this.state.text}
                </div>
                }

                {/<\/?[a-z][^@>]*>/i.test(this.state.text) === true &&
                <div>
                    <div className="content" dangerouslySetInnerHTML={{__html: this.state.text}}></div>
                </div>
                }

                    {/<\/?[a-z][\s\S]*@[\s\S]*>/i.test(this.state.text) === true &&
                    <div style={{'whiteSpace': 'pre-wrap'}}>
                        {this.state.text}
                    </div>
                    }
                </div>

            </div>


        );
    }
}

Communication.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Communication);

