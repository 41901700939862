import React, {Component} from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import {confirmAlert} from 'react-confirm-alert'; // Import
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import SessionStorageService from "../services/SessionStorageService";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class ActionsRenderer extends Component {
    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        // return dd+'.'+mm + '.' +  yyyy;
        return yyyy + '-' + mm + '-' + dd;
    }

    row() {
        var row = null;
        if (this.props.node.data && this.props.node.data.id) {
            row = this.props.node.data;
        } else if (this.props.node.allLeafChildren && this.props.node.allLeafChildren[0] && this.props.node.allLeafChildren[0].data) {
            row = this.props.node.allLeafChildren[0].data;
        }

        return row;
    }

    buttonClick = (e) => {

        if (this.props.gridType === 'loadings') {
            var title = 'Confirm storing';
            var message = 'Are you sure you want to store this loading?';

        }

        confirmAlert({
            title: this.props.t(title),
            message: this.props.t(message),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({
                            visible: true
                        });

                        var row = this.row();
                        row.loading_stored = this.today();
                        row.field = 'loading_stored';
                        if (this.props.gridType === 'loadings') {
                            var url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + row.id;

                            HttpService.put(url, row)
                                .then(res => {
                                    console.log('Success')
                                    // e.gridApi.updateRowData({remove: [deletedRow]})

                                    var rownode;
                                    res.data.rows.map((i) => {
                                        rownode = e.gridApi.getRowNode(i.id);
                                        if (rownode) {
                                            rownode.setData(i);
                                        }
                                        return true;
                                    });

                                    console.log(e);
                                    // var rownode=res.data.rows[0];
                                    console.log(rownode);
                                    e.gridApi.refreshClientSideRowModel('aggregate')
                                    e.gridApi.redrawRows({rowNodes: [rownode.parent]});

                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });
    };

    /*
        storePartially = (e) => {


            const regex = new RegExp('[0-9]+');
            let partially_stored = prompt(this.props.t("Store partially kg"), "0");
            let text;
            if (partially_stored != null && partially_stored != "" && partially_stored != 0 && regex.test(partially_stored)) {
                var row=this.row();
                row.partially_stored=partially_stored;
                row.field='partially_stored';
                if (this.props.gridType === 'loadings') {
                    var url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + row.id;

                    HttpService.put(url, row)
                        .then(res => {
                            console.log('Success')
                            // e.gridApi.updateRowData({remove: [deletedRow]})

                            var rownode;
                            res.data.rows.map((i)=>{
                                rownode=e.gridApi.getRowNode(i.id);
                                if (rownode) {
                                    rownode.setData(i);
                                }
                                return true;
                            });

                            console.log(e);
                            // var rownode=res.data.rows[0];
                            console.log(rownode);
                            e.gridApi.refreshClientSideRowModel('aggregate')
                            e.gridApi.redrawRows({ rowNodes: [rownode.parent] });

                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
            } else {
                alert(this.props.t("Partial storing cancelled or invalid value"))
            }



        };
    */


    deleteClick = (e) => {


        var title = 'Confirm to delete';
        var message = 'Are you sure to delete this item and all other items associated with it?';


        confirmAlert({
            title: this.props.t(title),
            message: this.props.t(message),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.setState({
                            visible: true
                        });

                        var row = this.row();

                        if (this.props.gridType === 'loadings') {
                            var url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + row.loading_number;

                            HttpService.delete(url, row)
                                .then(res => {
                                    var rownodes = [];
                                    res.data.ids.map((i) => {
                                        if (rownodes.length === 0) rownodes.push(e.gridApi.getRowNode(i).parent);
                                        rownodes.push(e.gridApi.getRowNode(i));
                                        return true;
                                    });

                                    e.gridApi.updateRowData({remove: rownodes})

                                    e.gridApi.refreshClientSideRowModel('aggregate')


                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }


                        ///

                        if (this.props.gridType === 'outinvoices' || this.props.gridType === 'supplylists') {
                            var url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + this.props.data.id;
                            let deletedRow = this.props.node.data;
                            if (this.props.gridType === 'columns_options') url = UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + this.props.colid + '/' + this.props.data.id;

                            HttpService.delete(url)
                                .then(res => {
                                    if (res.data.alert != null && res.data.alert != '') {
                                        alert(res.data.alert);
                                        return false;
                                    }
                                    console.log('Success')
                                    e.gridApi.updateRowData({remove: [deletedRow]})  // It will update the row
                                    if (this.props.gridType === 'columns_options') {
                                        SessionStorageService.update('columns', res.data.entry);
                                    }

                                    if (this.props.gridType === 'columns') {
                                        SessionStorageService.remove_by_id('columns', this.props.data.id);
                                    }

                                    if (this.props.gridType === 'roles') {
                                        SessionStorageService.remove_by_id('roles', this.props.data.id);
                                    }

                                    if (this.props.gridType === 'users') {
                                        SessionStorageService.remove_by_id('users', this.props.data.id);
                                    }


                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }

                    }

                },
                {
                    label: this.props.t('No'),

                }
            ]
        });
    };

    downloadAttachment(X) {
        HttpService.postblob(UrlService.apiDomain() + 'api/attachments/download_attachment', {attachment: X})
            .then((response) => {
                // Create blob link to download

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                let tmp = X.split('/');
                let filename = tmp[tmp.length - 1];
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    render() {
        var row = this.row();
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));

        var data_text = '';
		var elements = [];
        if (this.props.gridType === 'loadings') {
            data_text = row.loading_number;
			
			
            var files = [];
            var invoice_numbers = []
            if (row.invoice_file != null && row.invoice_file != '') files = row.invoice_file.split(',');
            if (row.invoice_number != null && row.invoice_number != '') invoice_numbers = row.invoice_number.split(',');

            invoice_numbers.map((e, i) => {
                elements.push(<>
                    <button key={i}
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.downloadAttachment(files[i])}
                            title={this.props.t('Download') + ' ' + this.props.t('invoice') + ' ' + e}>
                        <i key={i} className="fa fa-file-invoice"></i>,
                    </button>
                </>);
            });
        }

        if (this.props.gridType === 'outinvoices') {
            data_text = row.invoice_number;
        }

        if (this.props.gridType === 'supplylists') {
            data_text = row.supplylist_number;
        }



        return (

            <>
                {data_text}

                <div className="btn-group" style={{'marginLeft': '9px', 'marginRight': '9px'}}>
                    {(row.loading_stored == null && permissions.includes('loadings.move_to_storage') && this.props.gridType === 'loadings') &&
                        <>
                            <button
                                className="btn btn-default btn-sm delete-ingrid-but"
                                onClick={() => this.buttonClick(this.props.node)}
                                title={this.props.t('Move to storage')}
                            ><i className="fa fa-boxes"></i>
                            </button>

                        </>
                    }
                    {(row.production_photo != null && this.props.gridType === 'loadings') &&
                        <>
                            <button
                                className="btn btn-default btn-sm delete-ingrid-but"
                                onClick={() => this.props.showcard(row, 'uploaded')}
                                title={this.props.t('Uploaded photo')}
                            ><i className="fa fa-image"></i>
                            </button>

                        </>
                    }
					
                    <button
                        className="btn btn-default btn-sm delete-ingrid-but"
                        onClick={ ( ) => this.props.showcard( row ) }
                        title={this.props.t('Show')}
                    >
						<i className="fa fa-file-pdf">
						</i>
                    </button>
					
					{(this.props.gridType === 'outinvoices') &&
						<>
                            <button
                                className="btn btn-default btn-sm delete-ingrid-but"
                                onClick={() => this.props.CopyFn( row )} //TODO
                                title={this.props.t('Copy')}
                            ><i className="fa fa-copy"></i>
                            </button>

                        </>
                    }
					
					
                    {((this.props.gridType == 'loadings' && permissions.includes('loadings.delete')) || (this.props.gridType == 'supplylists' && permissions.includes('sales.delete'))) &&
                        <button
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.deleteClick(this.props.node)}
                            title={this.props.t('Delete')}
                        ><i className="fa fa-trash"></i>
                        </button>
                    }
                    {row.weighting &&
                        <button
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.downloadAttachment(row?.weighting?.attachment)}
                            title={this.props.t('Weighted')}
                        ><i className="fa fa-balance-scale"></i>
                        </button>}
					{(this.props.gridType === 'loadings') &&
						<>
							{elements}
						</>
					}
                </div>
            </>
        );
    }
}

ActionsRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ActionsRenderer);