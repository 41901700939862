
import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentDateConfigT from "../../../Type/Component/Date/ComponentDateConfigT.mjs";

import ComponentConfigT from "../../../Type/Component/ComponentConfigT.mjs";


import {
	ComponentLayoutElementT as ComponentLayoutElementT,
	ComponentBaseT as ComponentBaseT,
	ComponentT as ComponentT
} from "../ComponentInternalT.mjs";


import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentDateT = class ComponentDateT extends ComponentBaseT {
	
	#ComponentDateConfig = null;

	#Value = false;

	constructor(
		GUIElement,
		ComponentDateConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ComponentDateConfig instanceof ComponentDateConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
		super(
			GUIElement,
			ComponentDateConfig
		);
		
		super.Element( ).Render( ).type = "date";
		
		this.#ComponentDateConfig = ComponentDateConfig;
		
		this.#Value = false;
	
	}
	
	
	ValueCheck( ){
		
		console.log( "ComponentDoubleT.ValueCheck" );
		
		return super.ValueCheck( );
		
	}
	
	ValueGet( ){
		
		console.log( "ComponentDoubleT.ValueGet" );
		
		
		return this.#Value;
		
	}
	
};


export default ComponentDateT;
