import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import TranslatedMessages from "./translations";
import { I18n } from 'react-polyglot';

var locale = 'cs';
if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

ReactDOM.render(<I18n locale={locale} messages={TranslatedMessages.getMessages(locale)}><App /></I18n>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
