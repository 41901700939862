import React, { Component } from "react";
import CookieService from "../../../../services/CookieService";
import { Redirect } from "react-router-dom";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import enflag from "../../../../assets/img/en.png";
import csflag from "../../../../assets/img/cs.png";
class RigthDropdown extends Component {
    state = {
        redirect: null,

    };
    handleLogout () {
        CookieService.remove('access_token');
        this.setState({redirect:"/"});
    }
    handleEn () {
        localStorage.setItem('locale', 'en');
        window.location.reload();
    }
    handleCz () {
        localStorage.setItem('locale', 'cs');
        window.location.reload();
    }
  render() {
      if (this.state.redirect) {

          return <Redirect to={this.state.redirect} />
      }
    return (
      <ul className="navbar-nav ml-auto flags-menu">
          <li> <a

              href="#"
              onClick={(event) => this.handleEn(event)}
          > <img
              src={enflag} height="15"

              alt="English"
          /></a>
          </li>
    <li> <a

        href="#"
        onClick={(event) => this.handleCz(event)}
    ><img height="15"
        src={csflag}

        alt="Czech"
    /></a>
    </li>
          <li>
          <a

              href="#"
              onClick={(event) => this.handleLogout(event)}
          >{this.props.t('Logout')}  <i className="fas fa-key"></i>

          </a>
</li>
      </ul>
    );
  }
}
RigthDropdown.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(RigthDropdown);
