"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelSchemaRequestModelOptionT from "./APIModelSchemaRequestModelOptionT.mjs";


const APIModelSchemaRequestModelOptionMapT = class APIModelSchemaRequestModelOptionMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelSchemaRequestModelOptionList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelKeyList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelSchemaRequestModelOptionList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelSchemaRequestModelOptionList.length;
			I++
		){

			if( ( APIModelSchemaRequestModelOptionList[ I ] instanceof APIModelSchemaRequestModelOptionT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelSchemaRequestModelOptionList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelSchemaRequestModelOptionList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelSchemaRequestModelOption = APIModelSchemaRequestModelOptionList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelSchemaRequestModelOption );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelSchemaRequestModelOption
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelSchemaRequestModelOption instanceof APIModelSchemaRequestModelOptionT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelSchemaRequestModelOption
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelSchemaRequestModelOptionList = [ ];
		
		for(
			let I in JSONData
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelSchemaRequestModelOptionList.push(
				APIModelSchemaRequestModelOptionT.FromJSON(
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIModelSchemaRequestModelOptionMapT(
			APIModelKeyList,
			APIModelSchemaRequestModelOptionList
		);
		
	}
	
	ToJSON( 
	
	){
		
		let JSONData = { };
		
		
		for(
			let I of this.#Map.keys( )
		){
			
			console.log( I, this.#Map );
			
			JSONData[ I ] = ( this.#Map.get( I ) ).ToJSON( );
		
		}


		return JSONData;
		
	}
	
};


export default APIModelSchemaRequestModelOptionMapT;