"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentLayoutElementConfigT from "../../../Type/Component/Layout/ComponentLayoutElementConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT,
	ComponentT as ComponentT 
} from "../ComponentInternalT.mjs";


import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";

import StyleMapT from "../../../Type/Style/StyleMapT.mjs";

import KeyValueT from "../../../Type/KeyValue/KeyValueT.mjs";
import KeyValueMapT from "../../../Type/KeyValue/KeyValueMapT.mjs";
import KeyValueElementT from "../../../Type/KeyValue/KeyValueElementT.mjs";
import KeyValueBucketT from "../../../Type/KeyValue/KeyValueBucketT.mjs";


const ComponentLayoutElementT = class ComponentLayoutElementT extends ComponentBaseT {

	#ComponentLayoutElementConfig = null;
	
	#InputFocusCb = null;
	#InputBlurCb = null;
	#InputChangeCb = null;
	#ButtonClickCb = null;
	
	#Index = 0;
	
	#PrepareMap = null;
	
	#ClickCb = null;
	#EnterCb = null;
	#LeaveCb = null;
	
	
	constructor(
		GUIElement,
		ComponentLayoutElementConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		if( ( ComponentLayoutElementConfig instanceof ComponentLayoutElementConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentLayoutElementConfig
		);
		
		
		this.#ComponentLayoutElementConfig = ComponentLayoutElementConfig;
		
		
		this.EventAppend(
			"click",
			this.#OnClick.bind( this )
		);
		
	}
	
	#OnClick( Ev ){
		
		Ev.preventDefault( );
		
		console.log( "ComponentLayoutElementT.#OnClick" );
		
		if( this.#ClickCb !== null ){
			
			this.#ClickCb( this.#Index );
			
		}
		
	}
	
	ElementKeys( ){
		
		return this.ChildrenKeys( );
		
	}
	
	ElementGet( 
		Name 
	){
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			console.log( Child.NameGet( ), Name );
			
			if( Child.NameGet( ) === Name ){
				
				return Child;
				
			}
			
		}
		
		return null;
		
	}
	
	ElementCreate(
		ChildConfig
	){
		
		let Child = ComponentT.FromConfig(
			ChildConfig
		);
		
		Child.Prepare( this.#PrepareMap );
		
		this.ChildAppend( Child );
		
	}
	
	ElementsCreate( ){
		
		console.log( "ComponentLayoutElementT.ElementsCreate" );
		
		let ChildrenConfig = this.#ComponentLayoutElementConfig.ChildrenGet( );
		
		console.log( ChildrenConfig );
		
		for( 
			let I = 0; 
			I < ChildrenConfig.length;
			I++
		){
			
			let ChildConfig = ChildrenConfig[ I ];
			
			this.ElementCreate( ChildConfig );
			
		}
		
		
	}
	
	Prepare( PrepareMap ){
		
		console.log( "ComponentLayoutElementT.Prepare", PrepareMap );
		
		this.#PrepareMap = PrepareMap;
		
	}
	
	IndexGet(){
		
		return this.#Index;
		
	}
	
	IndexSet( 
		Index 
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Index = Index;
		
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			Child.Listen(
			
				new EventBucketT( [
				
					new EventObjectT(
						Child.NameGet( ),
						new EventMapT( [
						
							new EventT(
								"ButtonClick",
								this.#OnButtonClick.bind( this )
							),
							new EventT(
								"InputFocus",
								this.#OnInputFocus.bind( this )
							),
							new EventT(
								"InputBlur",
								this.#OnInputBlur.bind( this )
							),
							new EventT(
								"InputChange",
								this.#OnInputChange.bind( this )
							)
						
						] )
					)
				
				] )
				
			);
			
		}
		
	}
	
	Update( ){
		
		super.Update( );
		
	}
	
	#OnButtonClick(
		Name,
		Ev
	){
		
		//TODO Checks
		
		console.log( "ComponentLayoutElementT.#OnButtonClick", Name, Ev );
		
		
		if( this.#ButtonClickCb !== null ){
		
			this.#ButtonClickCb(
				this.#Index,
				Name,
				Ev
			);
		
		}
		
	}
	
	#OnInputFocus(
		Name,
		Ev
	){
		
		//TODO Checks
		
		console.log( "ComponentLayoutElementT.#OnInputFocus", Name, Ev );
		
		
		if( this.#InputFocusCb !== null ){
			
			console.log( this.#InputFocusCb );
		
			this.#InputFocusCb(
				this.#Index,
				Name,
				Ev
			);
		
		}
		
	}
	
	#OnInputBlur(
		Name,
		Ev
	){
		
		//TODO Checks
		
		console.log( "ComponentLayoutElementT.#OnInputBlur", Name, Ev );
		
		
		if( this.#InputBlurCb !== null ){
		
			this.#InputBlurCb(
				this.#Index,
				Name,
				Ev
			);
		
		}
		
	}
	
	#OnInputChange(
		Name,
		Value
	){
		
		//TODO Checks
		
		console.log( "ComponentLayoutElementT.#OnInputChange", Name, Value );
		
		
		if( this.#InputChangeCb !== null ){
		
			this.#InputChangeCb(
				this.#Index,
				Name,
				Value
			);
		
		}
		
	}
	
	ListenParent(
		EventMap
	){
		
		if( EventMap.IsExists( "Click" ) ){
					
			this.#ClickCb = EventMap.Get( "Click" );
					
		}
		
		if( EventMap.IsExists( "Enter" ) ){
					
			this.#EnterCb = EventMap.Get( "Enter" );
					
		}
		
		if( EventMap.IsExists( "Leave" ) ){
					
			this.#LeaveCb = EventMap.Get( "Leave" );
					
		}
		
		this.EventAppend(
			"mouseenter",
			function( ){
				
				console.log( "enter" );
				
				if( this.#EnterCb !== null ){
					
					this.#EnterCb( this.#Index );
					
				}
				
			}.bind( this )
		);
		
		this.EventAppend(
			"mouseleave",
			function(){
				
				console.log( "leave" );
				
				if( this.#LeaveCb !== null ){
					
					this.#LeaveCb( this.#Index );
					
				}
				
			}.bind( this )
		);
		
	}
	
	Listen( 
		EventBucket
	){
		
		console.log( "ComponentLayoutElementT.Listen", EventBucket );
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		if( EventBucket !== null ){
		
			let Name = this.#ComponentLayoutElementConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
			
				/*if( EventMap.IsExists( "RowAdd" ) ){
					
					this.#RowAddCb = EventMap.Get( "RowAdd" );
					
				}*/
				
				if( EventMap.IsExists( "ButtonClick" ) ){
					
					this.#ButtonClickCb = EventMap.Get( "ButtonClick" );
					
				}
				
				if( EventMap.IsExists( "InputFocus" ) ){
					
					this.#InputFocusCb = EventMap.Get( "InputFocus" );
					
				}
				
				if( EventMap.IsExists( "InputBlur" ) ){
					
					this.#InputBlurCb = EventMap.Get( "InputBlur" );
					
				}
				
				if( EventMap.IsExists( "InputChange" ) ){
					
					this.#InputChangeCb = EventMap.Get( "InputChange" );
					
				}
				
				if( EventMap.IsExists( "Click" ) ){
					
					this.#ClickCb = EventMap.Get( "Click" );
					
				}
				
			}
			
		}
		
		
		
		/*if( EventMap !== null ){
			
			if( ( EventMap instanceof EventMapT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		
		//super.Listen( EventMap );
		
		
		if( EventMap !== null ){
		
			if( EventMap.IsExists( "Click" ) === true ){
				
				this.#OnClickCb = EventMap.Get( "Click" );
				
			}
		
		}
		
		
		this.EventAppend( 
			"click",
			this.#OnClick.bind( this )
		)*/
	
		
	}
	
	ValuesCheck(
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesMap = new KeyValueMapT( [ ] );

		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let Name = Child.NameGet( );
			
			console.log( Child.NameGet( ) );
			
			if( Fields.includes( Name ) === true ){
				
				if( ValuesMap.IsExists( Name ) === true ){
					
					throw new Error( "Duplicate element" );
					
				}
				
				console.log( Name, Child, Child.ValueCheck( ) );
				
				ValuesMap.Set( Name, Child.ValueCheck( ) );
				
			}
			
		}
		
		return ValuesMap;
		
	}
	
	StyleMapElementSet(
		Fields,
		StyleMap
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		if( ( StyleMap instanceof StyleMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let Name = Child.NameGet( );
			
			console.log( Child.NameGet( ) );
			
			if( Fields.includes( Name ) === true ){
				
				Child.StyleMapSet( StyleMap );
				
			}
			
		}
		
	}
	
	ValuesGet(
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesMap = new KeyValueMapT( [ ] );

		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let Name = Child.NameGet( );
			
			console.log( Child.NameGet( ) );
			
			if( Fields.includes( Name ) === true ){
				
				if( ValuesMap.IsExists( Name ) === true ){
					
					throw new Error( "Duplicate element" );
					
				}
				
				ValuesMap.Set( Name, Child.ValueGet( ) );
				
			}
			
		}
		
		return ValuesMap;
		
	}
	
	ValuesSet(
		ValuesMap
	){
		
		if( ( ValuesMap instanceof KeyValueMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let Name = Child.NameGet( );
			
			console.log( Child.NameGet( ) );
			
			if( ValuesMap.IsExists( Name ) ){
				
				Child.ValueSet( ValuesMap.Get( Name ) );
				
			}
			
		}
		
	}
	
	Filter( 
		CellNames
	){
		
		for(
			let I of this.ChildrenKeys( )
		){
			
			let Child = this.ChildGet( I );
			
			let Name = Child.NameGet( );
			
			console.log( Child.NameGet( ) );
			
			if( CellNames.includes( Name ) === true ){
				
				Child.ComponentTypedGet( ).Filter( );
				
			}
			
		}
		
	}
	
	VisibleSet(
		Visible
	){

		this.StyleSet( "display", ( Visible === true ) ? "table-cell" : "none" );
		
	}
	
	
	
				
};


export default ComponentLayoutElementT;