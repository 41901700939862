
import ModelController from "../../../model/ModelController";


const Model = class Model {
	
	#ModelDef = null;
	
	#Prepare = null;
	
	#ModelController = null;
	

    constructor(
		ModelDef,
		Prepare
	) {
		
		this.#ModelDef = ModelDef;
		
		this.#Prepare = Prepare;
		
		this.#ModelController = new ModelController( 
			ModelDef.model_name
		);

    }
	
	Schema( 
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Schema" );
		
	
		this.#ModelController.SchemaFull(
		
			this.#ModelDef,
			
			function( ModelSchema ){
				
				console.log( ModelSchema );
				
				
				SuccessCb( ModelSchema );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				ErrorCb( ErrorMessage );
		
			}.bind( this )
			
		);
		
	}
		
	Create(
		Data,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Create", Data );
		
	
		this.#ModelController.CreateFull( 
		
			this.#ModelDef,
			this.#Prepare,
			Data,
			
			function( Data ){
				
				SuccessCb( Data );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				//alert( ErrorMessage );
				
				ErrorCb( ErrorMessage );
		
			}.bind( this )
			
		);
		
	}
	
	Read(
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Read" );
		
		
		this.#ModelController.ReadAllFull(
		
			this.#ModelDef,
		
			function( Data ){
				
				console.log( Data );
				

				SuccessCb( Data );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				ErrorCb( ErrorMessage );
		
			}.bind( this )
			
		);
		
	}
	
	Update(
		Id,
		Field,
		Value,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Update", Id, Field, Value );
		
	
		this.#ModelController.Update( 
		
			Id,
			Field,
			Value,
			
			function( Data ){
				
				SuccessCb( Data );
				
			}.bind( this ),
			
			function( ErrorMessage, Data ) {
				
				ErrorCb( ErrorMessage, Data );
		
			}.bind( this )
			
		);
		
	}
	
	Delete(
		Id,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Delete", Id );
		
		
		this.#ModelController.Delete( 
		
			Id,
			
			function( Data ){
									
				SuccessCb( Data );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
				
				ErrorCb( ErrorMessage );
		
			}.bind( this )
			
		);
		
	}
	
	Prepare( 
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Schema" );
		
	
		this.#ModelController.Prepare(
		
			this.#Prepare,
			
			function( Prepare ){
				
				console.log( Prepare );
				
				
				SuccessCb( Prepare );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				ErrorCb( ErrorMessage );
		
			}.bind( this )
			
		);
		
	}
	
	Preload( 
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Preload" );
		
			
		this.Schema( 
		
			function( Schema ){
				
				this.Prepare(
				
					function( Prepare ){
				
						this.Read(
				
							function( Data ){
						
								SuccessCb( Schema, Prepare, Data );
								
							}.bind( this ),
							
							function( ErrorMessage ){
								
								ErrorCb( ErrorMessage );
								
							}.bind( this )
							
						);
						
					}.bind( this ),
					
					function( ErrorMessage ){
						
						ErrorCb( ErrorMessage );
						
					}.bind( this )
					
				);
				
			}.bind( this ),
			
			function( ErrorMessage ){
				
				ErrorCb( ErrorMessage );
				
			}.bind( this )
			
		);
		
	}

};


export default Model;

