import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import {AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import Select from 'react-select'
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import OutinvoiceAdd from "../../components/outinvoices/outinvoice_add.tsx";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange} from 'react-date-range';
import {registerLocale} from 'react-date-range';
import moment from 'moment';
import en from "date-fns/locale/en-US"; // the locale you want
import cs from "date-fns/locale/cs";
import {LoadState, SaveState} from "../../services/GridStateService"; // the locale you want
class Sales extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale === 'cs') loctext = LocaleCs;
        if (locale === 'en') loctext = LocaleEn;
        var date = new Date();
        var startdate = new Date(date.getFullYear(), date.getMonth(), 1);
        var enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            now: Date.now(),
            rowSelected: false,
			rowSelectedArray: [ ],
            filterRangeDisplay: 'none',
            backgroundOverlayDisplay: 'none',
            filterRange: moment(startdate).format('DD.MM.YYYY') + '-' + moment(enddate).format('DD.MM.YYYY'),
            filterStartDate: startdate,
            filterEndDate: enddate,
            filterRangeBy: '',
            filterRangeBy_value: {value: "", label: this.props.t('Choose filter')},
            showArchive: 'Show archive',
            currentArchive: 'Show actual',

            localeText: loctext,
            rowdata: null,
            columnDefs: [


                {
                    headerName: this.props.t('Product type'),
                    field: 'product_type',
                    editable: false,

                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                        if (params.data.product_type < 9000) {
                            var values = JSON.parse(localStorage.getItem('product_types'));
                        } else {
                            var values = JSON.parse(localStorage.getItem('invoice_otheritems'));
                        }

                        if (params.data.product_type === null || params.data.product_type === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.product_type))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }


                },


                {
                    headerName: this.props.t('Company'),
                    field: 'company',
                    editable: false,
                    checkboxSelection: true,
                    pinned: 'left',


                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                        var values = JSON.parse(localStorage.getItem('companies'));
                        if (params.data.company === null || params.data.company === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.company))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }


                },

                {
                    headerName: this.props.t('Order number'),
                    field: 'order_number',
                    editable: false,

                },

                {
                    headerName: this.props.t('Net kg'),
                    field: 'qty',
                    editable: false,

                },
                {
                    headerName: this.props.t('Gross kg'),
                    field: 'qty_gross',
                    editable: false,

                },

                {
                    headerName: this.props.t('Sale date'),
                    field: 'sale_date',
                    editable: false,

                    valueGetter: function (params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }

                        if (params.data.sale_date === null || params.data.sale_date === 0 || params.data.sale_date === '') return '';
                        var date = new Date(params.data.sale_date);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();

                        month++;
                        return day + '.' + month + '.' + year;

                    },
                    comparator: function (date1, date2) {

                        function _monthToNum(date) {
                            if (date === undefined || date === null || date === '') {
                                return null;
                            }

                            var date_arr = date.split('.');

                            var yearNumber = parseInt(date_arr[2]);
                            var monthNumber = parseInt(date_arr[1]);
                            var dayNumber = parseInt(date_arr[0]);

                            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                            // 29/08/2004 => 20040829
                            //console.log(result);
                            return result;
                        }


                        var date1Number = _monthToNum(date1);
                        var date2Number = _monthToNum(date2);


                        if (date1Number === null && date2Number === null) {
                            return 0;
                        }
                        if (date1Number === null) {
                            return -1;
                        }
                        if (date2Number === null) {
                            return 1;
                        }

                        return date1Number - date2Number;
                    },
                },


                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    comparator: function (date1, date2) {

                        function _monthToNum(date) {
                            if (date === undefined || date === null || date === '') {
                                return null;
                            }

                            var date_arr = date.split('.');

                            var yearNumber = parseInt(date_arr[2]);
                            var monthNumber = parseInt(date_arr[1]);
                            var dayNumber = parseInt(date_arr[0]);

                            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                            // 29/08/2004 => 20040829
                            //console.log(result);
                            return result;
                        }


                        var date1Number = _monthToNum(date1);
                        var date2Number = _monthToNum(date2);


                        if (date1Number === null && date2Number === null) {
                            return 0;
                        }
                        if (date1Number === null) {
                            return -1;
                        }
                        if (date2Number === null) {
                            return 1;
                        }

                        return date1Number - date2Number;
                    },
                    editable: false,

                    valueGetter: function (params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }

                        if (params.data.created_at === null || params.data.created_at === 0 || params.data.created_at === '') return '';
                        var date = new Date(params.data.created_at);
                        var month = date.getMonth();
                        var year = date.getFullYear();
                        var day = date.getDate();
                        var hour = addZero(date.getHours());
                        var min = addZero(date.getMinutes());
                        month++;
                        return day + '.' + month + '.' + year + ' ' + hour + ':' + min;

                    }
                },

                {
                    headerName: this.props.t('Created by'),
                    field: 'created_by',
                    valueGetter: function (params) {
                        var values = JSON.parse(localStorage.getItem('users'));
                        if (params.data.created_by === null || params.data.created_by === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.created_by))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

                {
                    headerName: this.props.t('Currency'),
                    field: 'currency',
                    editable: false,

                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
                        var values = JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);
                        if (params.data.currency === null || params.data.currency === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.currency))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }


                },


                {
                    headerName: this.props.t('Exc. VAT per kg'),
                    field: 'unit_excvat',
                    editable: true,

                },

                {
                    headerName: this.props.t('Inc. VAT per kg'),
                    field: 'unit_incvat',
                    editable: false,

                },

                {
                    headerName: this.props.t('VAT per kg'),
                    field: 'unit_vat',
                    editable: true,

                },

                {
                    headerName: this.props.t('Exc. VAT'),
                    field: 'excvat',
                    editable: false,

                },

                {
                    headerName: this.props.t('Inc. VAT'),
                    field: 'incvat',
                    editable: false,

                },

                {
                    headerName: this.props.t('VAT'),
                    field: 'vat',
                    editable: false,

                },

                {
                    headerName: this.props.t('Invoice number'),
                    field: 'invoice_number',
                    editable: false,

                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns: true,

                suppressMenu: false,
                filterParams: {
                    textFormatter: function (value) {
                        return value
                            .replace(/\s/g, '')
                            .replace(/[àáâãäå]/g, 'a')
                            .replace(/[ě]/g, 'e')
                            .replace(/[š]/g, 's')
                            .replace(/[č]/g, 'c')
                            .replace(/[ř]/g, 'r')
                            .replace(/[ž]/g, 'z')
                            .replace(/[ů]/g, 'u')
                            .replace(/[ň]/g, 'n')
                            .replace(/æ/g, 'ae')
                            .replace(/ç/g, 'c')
                            .replace(/[èéêë]/g, 'e')
                            .replace(/[ìíîï]/g, 'i')
                            .replace(/ñ/g, 'n')
                            .replace(/[òóôõö]/g, 'o')
                            .replace(/œ/g, 'oe')
                            .replace(/[ùúûü]/g, 'u')
                            .replace(/[ýÿ]/g, 'y')
                            .replace(/\W/g, '');
                    }
                }
            },
            frameworkComponents: {

                DeleteRenderer: DeleteRenderer,


            }
        };
    }

    onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected: true,rowSelectedArray:[ ...this.gridApi.getSelectedNodes() ]});
        } else {
            this.setState({rowSelected: false,rowSelectedArray:[]});
        }
    }

    componentDidMount() {


        var url = UrlService.apiDomain() + 'api/sales';

        HttpService.get(url)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });


                var hardcodedFilter = {
                    invoice_number: {
                        type: 'set',
                        values: [null],
                    },
                };


                function tt() {
                    this.gridApi.setFilterModel(hardcodedFilter)
                }


                setTimeout(tt.bind(this), 1);
                setTimeout(() => {
                    LoadState('Sales', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);

            })
            .catch((error) => {
                console.log(error);
            });

    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('sales.delete')) {
            items.push({
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",

                cellRendererParams: {
                    gridType: "sales",
                },
                suppressMenu: true,
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        LoadState('Sales', params.columnApi, params.api);
    };


    getRowNodeId = (data) => {
        return data.id;
    };


    handleFilterDateRangeSelect(ranges) {

        this.setState({
            filterStartDate: ranges.selection.startDate,
            filterEndDate: ranges.selection.endDate,
            filterRange: moment(ranges.selection.startDate).format('DD.MM.YYYY') + '-' + moment(ranges.selection.endDate).format('DD.MM.YYYY'),
        }, () => this.setFilter());

        if (ranges.selection.startDate != ranges.selection.endDate) {
            this.setState({

                filterRangeDisplay: 'none'
            });
        }


    }


    setFilter() {

        if (this.state.filterRangeBy == '') {
            this.gridApi.setFilterModel(this.archiveFilter());
        } else {

            var filtr = {};
            filtr[this.state.filterRangeBy] = {
                type: 'set',
                values: this.gridApi.getFilterInstance(this.state.filterRangeBy).getValues().filter((e) => {
                    if (moment(e, "DD.MM.YYYY") >= this.state.filterStartDate && moment(e, "DD.MM.YYYY") <= this.state.filterEndDate) {
                        return true;
                    } else {
                        return false;
                    }
                }),
            }

            if (this.state.filterRangeBy !== 'paid_date' && this.state.currentArchive !== 'Show all') {
                filtr = {...filtr, ...this.archiveFilter()}
            }
            this.gridApi.setFilterModel(filtr);
        }
    }

    archiveFilter() {

        if (this.state.currentArchive === 'Show archive') {
            return {
                invoice_number: {
                    type: 'set',
                    values: this.gridApi.getFilterInstance('invoice_number').getValues().filter(e => e !== null),
                },
            };
        }

        if (this.state.currentArchive === 'Show all') {

            return null;
        }

        if (this.state.currentArchive === 'Show actual') {
            return {
                invoice_number: {
                    type: 'set',
                    values: [null],
                },
            };
        }
    }

    handleFilterRangeChange(e) {

        this.setState({filterRange: e.target.value})
        var t = e.target.value.split('-');

        var start = moment(t[0], "DD.MM.YYYY", true);
        var end = moment(t[1], "DD.MM.YYYY", true);
        console.log(start.isValid());
        console.log(end.isValid());
        if (start.isValid() && end.isValid() && end >= start) {
            this.setState({
                filterStartDate: start.toDate(),
                filterEndDate: end.toDate(),

            }, () => this.setFilter());
        }
    }


    showInvoiceAdd() {
        var mult_lns = [];
        var ok = true;
        var existing_invoice = false;
        var different_companies = false;
        var different_currencies = false;
        var company = '';
        var currency = '';
        this.gridApi.getSelectedNodes().forEach((e) => {

            if (company == '') company = e.data.company;
            if (currency == '') currency = e.data.currency;
            if (company != '' && company != e.data.company) different_companies = true;
            if (currency != '' && currency != e.data.currency) different_currencies = true;
            if (e.data.invoice != null && e.data.invoice != '') existing_invoice = true;
            if (e.data.incvat == null || parseFloat(e.data.incvat) == 0) ok = false;
            mult_lns.push(e.data);

        });

        if (!ok) {
            alert(this.props.t('Cannot create an invoice. One of selected items has zero price'));
            return false;
        }

        if (existing_invoice) {
            alert(this.props.t('Cannot create an invoice. One of selected items already has assigned invoice'));
            return false;
        }

        if (different_companies) {
            alert(this.props.t('It is impossible to create invoice for sales to different companies'));
            return false;
        }

        if (different_currencies) {
            alert(this.props.t('It is impossible to create invoice for sales with different currencies'));
            return false;
        }

        this.setState({
            showInvoiceAdd: true,
            now: Date.now(),
            showInvoiceAddRownodes: mult_lns,
        });
    }


    async invoiceAdd(data) {


		this.setState({showInvoiceAdd: false});

        /*HttpService.post(UrlService.apiDomain() + 'api/outinvoices', data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (res.data.result === 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');
                    this.setState({showInvoiceAdd: false});


                    var rownode;
                    res.data.sales.map((e) => {
                        rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });*/


    }

    onCellCValueChanged = (event) => {
        console.log("nnnnn")
        event.data.field = event.colDef.field;
        HttpService.put(UrlService.apiDomain() + 'api/sales/' + event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');

                event.node.setData(res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('Sales', params.columnApi, params.api);
        }
    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        var daterange_locale = en;
        if (locale == 'cs') daterange_locale = cs;
        const selectionRange = {
            startDate: this.state.filterStartDate,
            endDate: this.state.filterEndDate,
            key: 'selection',
        }
        var height = '650px';
        if (this.props.fromtab) height = '450px';
        const filterRangeByFields = [

            {value: "", label: this.props.t('Choose filter')},
            {value: "sale_date", label: this.props.t('Sale date')},
            {value: "created_at", label: this.props.t('Created at')},


        ];

        return (

            <div className="wrapper">

                <div id="backgroundOverlay" onClick={() => {
                    this.setState({

                        filterRangeDisplay: 'none',
                        backgroundOverlayDisplay: 'none'
                    });
                }} style={{display: this.state.backgroundOverlayDisplay}}></div>

                <Layout>
                    <Content hideTitle={true} title="Sales">

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                {permissions.includes('invoices.excel') &&
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => this.gridApi.exportDataAsExcel()}
                                    >
                                        {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                                    </button>
                                }


                            </div>

                            <button type="button" style={{'marginRight': '9px'}}
                                    className="btn btn-success btn-sm"
                                    onClick={() => {

                                        if (this.state.showArchive === 'Show archive') {
                                            this.setState({
                                                currentArchive: 'Show archive',
                                                showArchive: 'Show all'
                                            }, () => this.setFilter());
                                        }

                                        if (this.state.showArchive === 'Show all') {

                                            this.setState({
                                                currentArchive: 'Show all',
                                                showArchive: 'Show actual'
                                            }, () => this.setFilter());
                                        }

                                        if (this.state.showArchive === 'Show actual') {
                                            this.setState({
                                                currentArchive: 'Show actual',
                                                showArchive: 'Show archive'
                                            }, () => this.setFilter());
                                        }


                                    }}
                            >{this.props.t(this.state.showArchive)} <i className="fa fa-archive"></i>
                            </button>


                            {(permissions.includes('outinvoices.create') && !this.props.fromtab) &&

                                <button type="button"
                                        disabled={!this.state.rowSelected}
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                            this.showInvoiceAdd();

                                        }}
                                >{this.props.t('Create invoice')} <i className="fa  fa-file-invoice"></i>
                                </button>
                            }

                            <div style={{display: 'inline-block', float: 'right', marginRight: '20px'}}>
                     <span style={{width: '150px', display: 'inline-block', marginRight: '8px'}}>
                <Select
                    name="filterRangeBy"

                    onChange={(event) => {
                        this.setState({filterRangeBy: event.value, filterRangeBy_value: event,}, () => this.setFilter())

                    }
                    }
                    value={this.state.filterRangeBy_value}


                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={filterRangeByFields}/>

                     </span>
                                <input style={{width: '190px', display: 'inline-block'}}
                                       onChange={this.handleFilterRangeChange.bind(this)}
                                       className="form-control" type="text" value={this.state.filterRange}
                                       onClick={() => {

                                           this.setState({
                                               backgroundOverlayDisplay: 'block',
                                               filterRangeDisplay: 'block'
                                           });
                                       }}/>


                                <div className="daterange_flt" style={{display: this.state.filterRangeDisplay}}>
                                    <DateRange

                                        ranges={[selectionRange]}
                                        showMonthAndYearPickers={false}
                                        moveRangeOnFirstSelection={false}
                                        locale={daterange_locale}
                                        onChange={this.handleFilterDateRangeSelect.bind(this)}
                                    />
                                </div>

                            </div>
                            <br clear="all"/>
                        </div>
                        {(permissions.includes('outinvoices.create') && !this.props.fromtab) &&
                            <Modal
                                title={this.props.t('Add new')}
                                visibility={this.state.showInvoiceAdd}
                                size="xxl"
                                onClose={() => this.setState({showInvoiceAdd: !this.state.showInvoiceAdd})}
                            >
                                <OutinvoiceAdd rownodes={this.state.showInvoiceAddRownodes} type='sales' fromtab={false}
                                               tmp={this.state.now}
                                               onAdd={(data) => this.invoiceAdd(data)} selectedArray={this.state.rowSelectedArray} from_sales={true}/>
                            </Modal>
                        }
                        <div style={{'height': height, width: '100%'}} id="salesGrid" className="ag-theme-alpine">

                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                onCellClicked={this.onCellClicked}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                getRowNodeId={this.getRowNodeId}
                                suppressRowClickSelection={true}
                                onRowSelected={this.onRowSelected.bind(this)}
                                rowSelection={'multiple'}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>


        );
    }
}

Sales.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Sales);

