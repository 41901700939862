import { translate } from 'react-polyglot';

import PropTypes from 'prop-types';

import { Component } from "react";


const InputSelectTextBase = class InputSelectTextBase extends Component { 
	
	#Input = null;
	#Select = null;
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	#OptionsObject = null;
	#OptionsFiltered = null;
	#OptionsFilter = null;
	
	#Value = null;
	
	#InputValue = null;
	#Placeholder = null;
	
	#ValuesOut = null;
	#ValuesOutParent = null;
	
	#RegExpMatch = null;
	#RegExpValidation = null;
	
	#RefCb = null;

	constructor( props ){
		
		super( props );
		
		this.#Input = null;
		this.#Select = null;
		
		this.#Field = props.field;
		this.#Disabled = props.disabled;
		this.#OnChangeCb = props.on_change_cb;
		this.#OptionsObject = props.options_object;
		this.#OptionsFilter = props.options_filter;
		
		this.#Placeholder = props.placeholder;
		
		this.#ValuesOut = props.values_out;
		
		this.#ValuesOutParent = props.values_out_parent;
		
		this.#Filter( );
		
		this.#RegExpMatch = props.regexp_match;
		
		this.#RegExpValidation = props.regexp_validation;
		
		this.#RefCb = props.ref_cb;
		
		this.#Value = undefined;
		
		this.#InputValue = "";
		
	}
	
	componentDidMount( ) {
		
		if( this.#RefCb ){
		
			this.#RefCb( this.#Input );
		
		}

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InputSelectBase", "componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if( this.props.value !== this.#Value ){
			
			this.#Value = this.props.value;
			
		
			this.#Input.value = this.#InputValue;
		
			
			this.#OnChangeCb( 
				this.#Field, 
				"select_text", 
				this.#InputValue
			);
			
		}
		
		if( this.props.disabled !== this.#Disabled ){
			
			this.#Disabled = this.props.disabled;
			
			
			this.#Input.disabled = this.#Disabled;
			
		}
		
	}
	
	componentWillUnmount( ){
		
	}
	
	#Filter( ){
		
		let FilteredOptions = null;
		
		if( this.#OptionsFilter ){
		
			FilteredOptions = this.#OptionsFilter( this.#ValuesOut, this.#OptionsObject, this.#ValuesOutParent );
		
		} else {
			
			FilteredOptions = this.#OptionsObject;
			
		}
		
		this.#OptionsFiltered = FilteredOptions;
		
	}
	
	#UpdateSelectVisible( ){
		
		this.#Input.value = this.#InputValue;
		
		
		let Count = 0;
		
		
		let Children = this.#Select.children;
		
		
		for(
			let I = 0;
			I < Children.length;
			I++
		){
			
			let Child = Children[ I ];
			
			
			if( Child.value === 0 ){
				
				Child.style.display = "block";
				
				continue;
				
			}
			
		
			if( this.#InputValue === "" ){
				
				if( this.#OptionsFiltered.has( Child.value.toString( ) ) === false ){
					
					if( Child.style.display !== "none" ){
						
						Child.style.display = "none";
					
					}
					
				} else {
				
					if( Child.style.display === "none" ){
					
						Child.style.display = "block";
						
					}
					
					Count++;
				
				}
				
			} else {
				
				if( this.#OptionsFiltered.has( Child.value.toString( ) ) === false ){
					
					if( Child.style.display !== "none" ){
						
						Child.style.display = "none";
					
					}
					
				} else {								
											
					if( Child.style.display === "none" ){
				
						Child.style.display = "block";
						
					}
						
					Count++;
			
					/*if( this.#OptionsFiltered.get( Child.value.toString( ) ).label.toLowerCase( ).indexOf( this.#InputValue.toLowerCase( ) ) === -1 ){
					
						if( Child.style.display !== "none" ){
					
							Child.style.display = "none";
							
						}
						
					} else {
						
						if( Child.style.display === "none" ){
					
							Child.style.display = "block";
							
						}
						
						Count++;
						
					}*/
				
				}
			
			}
			
		}
		
		return Count;
		
	}
	
	#SelectInputOnChange( Value ){
		
		console.log( "SelectInputOnChange", Value );
		
		
		let MatchDefaultValue = this.#MatchDefault( Value );
		
		console.log( "Default", MatchDefaultValue );
		
		if( MatchDefaultValue === null ){
			
			this.#Input.value = "";
			
			this.#Value = "";
			
			return;
			
		}
		
		
		let MatchValue = this.#Match( MatchDefaultValue );
		
		console.log( this.#RegExpMatch, MatchValue );
		
		if( MatchValue === null ){
			
			this.#Input.value = "";
			
			this.#Value = "";
			
			return;
			
		}
		
		
		this.#Input.value = MatchValue;
		
		this.#InputValue = this.#Parse( MatchValue );
		
			
		this.#OnChangeCb( 
			this.#Field, 
			"select_text", 
			this.#InputValue
		);
		
		
		/*let ValidationValue = this.#Validation( MatchValue );
		
		if( ValidationValue !== null ){
			
			this.#Set( ValidationValue );
			
			this.#OnChangeCb( 
				this.#Field, 
				"text", 
				this.#Value,
				this.#Input
			);
			
		}*/
		
	}
	
	#SelectOptionOnClick( Ev ){
		
		console.log( "li", Ev.target.value, Ev.target.innerText, this );
							
							
		Ev.preventDefault( );
		
		
		let Value = Ev.target.value.toString( );
		
		let Label = ( Value === "0" ) ? "" : Ev.target.innerText;
		
		
		this.#SelectInputOnChange( Label );
		
		this.#Select.style.display = "none"; 
		
		this.#Input.focus( );
		
		/*this.#OnChangeCb( 
			this.#Field, 
			"select_text", 
			this.#InputValue,
			this.#Input
		);*/
		
	}
	
	#SelectInputOnClick( ){
		
		console.log( "SelectInputOnClick" );
		
		
		this.#Select.style.display = "block";
		
	}
	
	#SelectInputOnFocus( ){
		
		console.log( "SelectInputOnFocus" );
		
		this.#Filter( );
		
		let Count = this.#UpdateSelectVisible( );
		
		/*if( Count === 0 ){
			
			this.#Empty.style.display = "block";
			
			this.#Select.style.display = "none";
		
		} else {
			
			this.#Empty.style.display = "none";
		
			this.#Select.style.display = "block";
		
		}*/
		
		//this.#Select.style.display = "block";
		
	}
	
	#MatchDefault( ValueString ){
		
		let ValueMatch = ValueString.match( new RegExp( "^.{0,4096}", "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Match( ValueString ){
		
		if( this.#RegExpMatch === null ){
			
			return ValueString;
			
		}
		
		let ValueMatch = ValueString.match( new RegExp( this.#RegExpMatch, "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Validation( ValueString ){
		
		if( this.#RegExpValidation === null ){
			
			return ValueString;
			
		}
		
		let ValueMatch = ValueString.match( new RegExp( this.#RegExpValidation, "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Parse( ValueString ){
		
		if( ValueString === "" ){
			
			return "";
			
		}
		
		return ValueString;
		
	}
	
	#Set( ValueString ){
		
		let ValueText = this.#Parse( ValueString );
		
		
		this.#InputValue = ValueText;
		
		this.#Input.value = ValueString;
		
	}
	
	#SelectInputOnBlur( ){
		
		console.log( "SelectInputOnBlur" );
		
	}
	
	#SelectOptionListOnMouseOver( ){
		
	}
	
	#SelectOptionListOnMouseOut( ){
		
	}
	
	#DivOnMouseOver( ){
		
		console.log( "DivOnMouseOver" );
		
	}
	
	#DivOnMouseOut( ){
		
		console.log( "DivOnMouseOut" );
		

		this.#Select.style.display = "none"; 
		
	}
	

	#MakeItems( ){
	
		let Result = [ ];
		
		Result.push(
			
			<li
				style = {{ "display": "block", "fontStyle": "italic" }}
				className = { "FastSelectUnselected" }
				selected={ false }
				key={ "0" } 
				value={ "0" }
				onClick = {
					( Ev ) => {
						
						this.#SelectOptionOnClick( Ev );
						
					}
				}
			>
				{ "{" + this.props.t( "Empty" ) + "}" }
			</li>
			
		);
		
		for(
			let I of this.#OptionsObject.keys( )
		){
			
			let Item = this.#OptionsObject.get( I );
			
			Result.push(
			
				<li
					className = { "FastSelectUnselected" }
					selected={ ( this.#Value ) && ( this.#Value.value === Item.value ) }
					key={ Item.value } 
					value={ Item.value }
					onClick = {
						( Ev ) => {
							
							this.#SelectOptionOnClick( Ev );
							
						}
					}
				>
					{ Item.label }
				</li>
				
			);
			
		}
		
		return Result;
		
	}
	
	render( ){
	
		return (
			<div 
				style = { { "position": "relative", "width": "100%" } }
				onMouseEnter={ 
					( ) => { 
						
						this.#DivOnMouseOver( );
						
					} 
				}
				onMouseLeave={ 
					( ) => { 
					
						this.#DivOnMouseOut( );
						
					} 
				}
			>
				<input
					type = "text"
					ref = { ( Ref ) => { this.#Input = Ref; } }
					disabled = { this.#Disabled }
					className = { "FastSelectInput" }
					placeholder = { this.#Placeholder }
					onClick= { 
						( ) => { 
						
							this.#SelectInputOnClick( );
							
						} 
					}
					onFocus= { 
						( ) => { 
						
							this.#SelectInputOnFocus( );
							
						} 
					}
					onBlur= { 
						( ) => { 
						
							this.#SelectInputOnBlur( );
							
						} 
					}
					onChange = { 
						( Ev ) => {
							
							this.#SelectInputOnChange( Ev.target.value );
						
						}
					}
				/>
				<ul
					style = { { 
						cursor: "pointer",
						display: "none", 
						position: "absolute",
						width: "100%",
						zIndex: 10000,
						height: "auto",
						maxHeight: "40vh",
						overflowY: "auto",
						overflowX: "hidden"
					} }
					ref = { ( Ref ) => { this.#Select = Ref; } }
					className = { "form-control" }
					multiple
					onMouseEnter={ 
						( ) => { 
							
							this.#SelectOptionListOnMouseOver( );
							
						} 
					}
					onMouseLeave={ 
						( ) => { 
						
							this.#SelectOptionListOnMouseOut( );
							
						} 
					}
				>
					{
						this.#MakeItems( )
					}
				</ul>
			</div>
								
		);
		
	};
	
};


InputSelectTextBase.propTypes = {
	
    t: PropTypes.func.isRequired
	
};


export default translate( )( InputSelectTextBase );