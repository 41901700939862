"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelCallResponseElementT from "./APIModelCallResponseElementT.mjs";


const APIModelCallResponseElementMapT = class APIModelCallResponseElementMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelCallResponseElementList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelKeyList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelCallResponseElementList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelCallResponseElementList.length;
			I++
		){

			if( ( APIModelCallResponseElementList[ I ] instanceof APIModelCallResponseElementT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelCallResponseElementList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelCallResponseElementList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelCallResponseElement = APIModelCallResponseElementList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelCallResponseElement );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelCallResponseElement
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelCallResponseElement instanceof APIModelCallResponseElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelCallResponseElement
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelCallResponseElementList = [ ];
		
		for(
			let I in JSONData
		){
			
			console.log( I, JSONData[ I ] );
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelCallResponseElementList.push(
				APIModelCallResponseElementT.FromJSON(
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIModelCallResponseElementMapT(
			APIModelKeyList,
			APIModelCallResponseElementList
		);
		
	}
	
	ToJSON( 
	
	){
		
		let JSONData = { };
		
		
		for(
			let I of this.#Map.keys( )
		){
			
			console.log( I, this.#Map );
			
			JSONData[ I ] = ( this.#Map.get( I ) ).ToJSON( );
		
		}


		return JSONData;
		
	}
	
};


export default APIModelCallResponseElementMapT;