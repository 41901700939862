"use strict";


import UIElementT from "../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";


import UIStateT from "../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentBaseConfigT from "../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";


const GTSUIComponentDateT = class GTSUIComponentDateT extends UIComponentBaseT {
	
	#ValueDefault = null;
	#IsEmptyValid = false;
	
	#Input = null;
	
	#Value = null;
	#IsValid = false;
	
	constructor(
		ValueDefault,
		IsEmptyValid,
		Id = null
	){
		
		let UIComponentBaseConfig = new UIComponentBaseConfigT(
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentDateT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"ValidFocused",
					"UIComponentDateT Valid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Valid",
					"UIComponentDateT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"InvalidFocused",
					"UIComponentDateT Invalid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentDateT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 0px 0px" )
					] )
				)
				
			] )
		);
		
		super(
			new UIElementT( ),
			UIComponentBaseConfig
		);
		
		this.#IsEmptyValid = IsEmptyValid;
		
		this.#ValueDefault = ValueDefault;
		
		let Input = document.createElement( "input" );
		
		Input.style.width = "100%";
		Input.style.outline = "0px solid transparent";
		Input.style.border = "0px solid transparent";
		
		super.UIElement( ).Render( ).appendChild( Input );
		
		Input.setAttribute( "type", "date" );
		
		this.#Input = Input;
		
		this.ValueSet( this.#ValueDefault, false );
		
	}
	
	#OnChange(
		Ev
	){
			
		console.log( Ev );
		
		
		this.ValueSet( ( Ev.target.value === "" ) ? null : Ev.target.value, true );
		
		
		if( this.EventExists( "Change" ) === true ){
			
			let EventFn = this.EventGet( "Change" );
			
			EventFn( this.ValueGet( ), this.IsValidGet( ) );
			
		}
			
	}
	
	#OnFocus(
		Ev
	){
		
		let UIComponentBaseConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentBaseConfig.StateMapGet( );
		
		let UIState = null;
		
		
		if( this.#IsValid === true ){
			
			UIState = UIStateMap.Get( "ValidFocused" );
			
		} else {
				
			UIState = UIStateMap.Get( "InvalidFocused" );
		
		}
		
		
		super.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		super.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
	}

	#OnBlur(
		Ev
	){
		
		let UIComponentBaseConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentBaseConfig.StateMapGet( );
		
		let UIState = null;
		
		
		if( this.#IsValid === true ){
			
			UIState = UIStateMap.Get( "Valid" );
			
		} else {
				
			UIState = UIStateMap.Get( "Invalid" );
		
		}
		
		
		super.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		super.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
	}
	
	Start(
	
	){
	
		super.Start( );
		
		this.#Input.addEventListener(
			"change",
			this.#OnChange.bind( this )
		);
		
		this.#Input.addEventListener(
			"focus",
			this.#OnFocus.bind( this )
		);
		
		this.#Input.addEventListener(
			"blur",
			this.#OnBlur.bind( this )
		);
	
	}
	
	IsValidGet( 
	
	){
		
		return this.#IsValid;
		
	}
	
	ValueGet( 
	
	){
		
		return this.#Value;
		
	}
	
	ValueSet(
		Value,
		NeedFocus = true
	){
		
		this.#Value = Value;
		
		this.#Input.value = Value;
		
		if( this.#Value === null ){
			
			if( this.#IsEmptyValid === true ){
				
				this.#IsValid = true;
				
			} else {
				
				this.#IsValid = false;
				
			}
			
		} else {
			
			this.#IsValid = true;
			
		}
		
		
		let UIComponentBaseConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentBaseConfig.StateMapGet( );
		
		let UIState = null;
		
		
		if( this.#IsValid === true ){
			
			if( NeedFocus === true ){
				
				UIState = UIStateMap.Get( "ValidFocused" );
				
			} else {
		
				UIState = UIStateMap.Get( "Valid" );
				
			}
			
		} else {
			
			if( NeedFocus === true ){
				
				UIState = UIStateMap.Get( "InvalidFocused" );
				
			} else {
		
				UIState = UIStateMap.Get( "Invalid" );
				
			}
		
		}
		
		
		super.ClassNameSet(
			UIState.ClassNameGet( )
		);
		
		super.StyleMapSet(
			UIState.StyleMapGet( )
		);
		
	}
	
	Listen(
		OnChangeFn
	){
		
		super.EventSet(
			"Change",
			OnChangeFn
		);
		
	}
	
};


export default GTSUIComponentDateT;
