"use strict";


import ComponentBaseConfigT from "../Base/ComponentBaseConfigT.mjs";


const ComponentLayoutElementConfigT = class ComponentLayoutElementConfigT extends ComponentBaseConfigT {
	
	#Children = null;
	
	constructor(
		ClassName = null,
		StyleMap = null,
		Children = null,
		Name = null,
		Id = null
	){
		
		super( 
			ClassName,
			StyleMap,
			Children,
			Name,
			Id
		);
		
	}
	
	Copy( ){
		
		return new ComponentLayoutElementConfigT( 
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.ChildrenGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
				
};


export default ComponentLayoutElementConfigT;