export function SaveState(name, columnApi, api) {
  let columnState = JSON.stringify(columnApi.getColumnState());
  localStorage.setItem(`${name}ColumnState`, columnState);

  let filterState = JSON.stringify(api.getFilterModel());
  localStorage.setItem(`${name}FilterState`, filterState);
}

export function LoadState(name, columnApi, api) {
  let columnState = JSON.parse(localStorage.getItem(`${name}ColumnState`));
  if (columnState) {
    columnApi.setColumnState(columnState);
  }

  let filterState = JSON.parse(localStorage.getItem(`${name}FilterState`));
  if (filterState) {
    let currentState = api.getFilterModel();
    for (const filterModelKey in currentState) {
      filterState[filterModelKey] = currentState[filterModelKey];
    }
    api.setFilterModel(filterState);
  }
}