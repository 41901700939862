"use strict";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIOptionT from "../../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";


import UIComponentBaseConfigT from "../../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";


import TranslatorT from "../../../../../../../../Interface/Translator/TranslatorT.mjs";



import GTSUIComponentFormPrimaryLineElementT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineElementT.mjs";

import GTSUIComponentFormPrimaryLineT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineT.mjs";

import GTSUIComponentFormPrimaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/GTSUIComponentFormPrimaryT.mjs";


import GTSUIComponentSelectT from "../../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";

import GTSUIComponentBooleanT from "../../../../../../../../Interface/GTS/UI/Component/Boolean/GTSUIComponentBooleanT.mjs";

import GTSUIComponentTextT from "../../../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";

import GTSUIComponentDoubleT from "../../../../../../../../Interface/GTS/UI/Component/Double/GTSUIComponentDoubleT.mjs";

import GTSUIComponentDateT from "../../../../../../../../Interface/GTS/UI/Component/Date/GTSUIComponentDateT.mjs";

import GTSUIComponentSelectTextT from "../../../../../../../../Interface/GTS/UI/Component/SelectText/GTSUIComponentSelectTextT.mjs";


const GTSUIModelSaleFormAddPrimarySaleFormT = class GTSUIModelSaleFormAddPrimarySaleFormT extends GTSUIComponentFormPrimaryT {
	
	#T = null;
	
	#UIComponentLabelStateMapDefault = null;
	
	//1
	
	#Company = null;
	#Currency = null;
	#SaleDate = null;
	#ContractNumber = null;
	
	//2
	
	#OrderNumber = null;
	#OrderDate = null;
	#DeliveryAddress = null;
	
	//Lines
	
	#Line1 = null;
	#Line2 = null;
	
	//Add
	
	#CompanyModelOptionMap = null;
	#CurrencyCustomOptionMap = null;
	#ContractNumberModelOptionMap = null;
	
	
	#Default = null;
	
	
	constructor( 
		Locale,
		
		UIOptionMapCompany,
		CompanyDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContractNumber,
		ContractNumberDefault,
		
		SaleDateDefault,
		
		OrderNumberDefault,
		OrderDateDefault,
		DeliveryAddressDefault,
		
		CompanyModelOptionMap,
		CurrencyCustomOptionMap,
		ContractNumberModelOptionMap
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"20px",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] )
		);
		
		super( [
			
		] );
		
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		this.#UIComponentLabelStateMapDefault = new UIStateMapT( [
				
			new UIStateT(
				"Default",
				"UIComponentLabelT",
				new UIStyleMapT( [
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" )
				] )
			)
			
		] );
		
		this.#Line1Add(
			UIOptionMapCompany,
			CompanyDefault,
		
			UIOptionMapCurrency,
			CurrencyDefault,
		
			UIOptionMapContractNumber,
			ContractNumberDefault,
			
			SaleDateDefault
		);
		
		this.#Line2Add(
			OrderNumberDefault,
			OrderDateDefault,
			DeliveryAddressDefault
		);
		
		
		this.#CompanyModelOptionMap = CompanyModelOptionMap;
		
		this.#CurrencyCustomOptionMap = CurrencyCustomOptionMap;
		
		this.#ContractNumberModelOptionMap = ContractNumberModelOptionMap;
		
	}
	
	IsValidTotalGet(
	
	){
	
		let IsValid = true;
		
		//1
		
		if( this.#Company.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#Currency.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#SaleDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#ContractNumber.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		//2
		
		if( this.#OrderNumber.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#OrderDate.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#DeliveryAddress.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		return IsValid;
	
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		//1
		
		JSONData[ "company" ] = this.#Company.ValueGet( );
		
		JSONData[ "currency" ] = this.#Currency.ValueGet( );
		
		JSONData[ "sale_date" ] = this.#SaleDate.ValueGet( );
		
		JSONData[ "contract" ] = this.#ContractNumber.ValueGet( );
		
		JSONData[ "contract_number" ] = ( this.#ContractNumber.ValueGet( ) === null ) ? null : this.#ContractNumber.LabelGet( );
		
		//2
		
		JSONData[ "order_number" ] = this.#OrderNumber.ValueGet( );
		
		JSONData[ "order_date" ] = this.#OrderDate.ValueGet( );
		
		JSONData[ "delivery_address" ] = this.#DeliveryAddress.ValueGet( );
		
		
		return JSONData;
		
	}
	
	#Line1Add( 
		UIOptionMapCompany,
		CompanyDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContractNumber,
		ContractNumberDefault,
		
		SaleDateDefault
	){
		
		//Company
		
		let CompanyLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Company" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let CompanyComponent = new GTSUIComponentSelectT(
			UIOptionMapCompany,
			false,
			CompanyDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let CompanyLineElement = new GTSUIComponentFormPrimaryLineElementT(
			CompanyLabel,
			CompanyComponent,
			"30%"
		);
		
		//Currency
		
		let CurrencyLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Currency" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let CurrencyComponent = new GTSUIComponentSelectT(
			UIOptionMapCurrency,
			false,
			CurrencyDefault,
			this.#T.Translate( "Select" )
		);
		
		
		let CurrencyLineElement = new GTSUIComponentFormPrimaryLineElementT(
			CurrencyLabel,
			CurrencyComponent,
			"15%"
		);
		
		//SaleDate
		
		let SaleDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Sale date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let SaleDateComponent = new GTSUIComponentDateT(

			SaleDateDefault,
			true			
			
		);
		
		
		let SaleDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			SaleDateLabel,
			SaleDateComponent,
			"25%"
		);
		
		//ContractNumber
		
		let ContractNumberLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Contract number" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let ContractNumberComponent = new GTSUIComponentSelectT(
			UIOptionMapContractNumber,
			true,
			ContractNumberDefault,
			this.#T.Translate( "Select" )
		);
		
		let ContractNumberLineElement = new GTSUIComponentFormPrimaryLineElementT(
			ContractNumberLabel,
			ContractNumberComponent,
			"30%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			CompanyLineElement,
			CurrencyLineElement,
			SaleDateLineElement,
			ContractNumberLineElement
		] );
		
		super.LineSet(
			0,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#Company = CompanyComponent;
		
		this.#Currency = CurrencyComponent;
		
		this.#SaleDate = SaleDateComponent;
		
		this.#ContractNumber = ContractNumberComponent;
		
	}
	
	#Line2Add(
		OrderNumberDefault,
		
		OrderDateDefault,
		
		DeliveryAddressDefault
	){
		
		//OrderNumber
		
		let OrderNumberLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Order number" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let OrderNumberComponent = new GTSUIComponentTextT(
			OrderNumberDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^.{0,64}",
			"^.{0,64}$"
		);
		
		
		let OrderNumberLineElement = new GTSUIComponentFormPrimaryLineElementT(
			OrderNumberLabel,
			OrderNumberComponent,
			"30%"
		);
		
		//OrderDate
		
		let OrderDateLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Order date" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let OrderDateComponent = new GTSUIComponentDateT(

			OrderDateDefault,
			true			
			
		);
		
		
		let OrderDateLineElement = new GTSUIComponentFormPrimaryLineElementT(
			OrderDateLabel,
			OrderDateComponent,
			"25%"
		);
		
		//DeliveryAddress
		
		let DeliveryAddressLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Delivery address" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let DeliveryAddressComponent = new GTSUIComponentTextT(
			DeliveryAddressDefault,
			
			this.#T.Translate( "Enter the value" ),
			"^.{0,64}",
			"^.{0,64}$"
		);
		
		let DeliveryAddressLineElement = new GTSUIComponentFormPrimaryLineElementT(
			DeliveryAddressLabel,
			DeliveryAddressComponent,
			"30%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			OrderNumberLineElement,
			OrderDateLineElement,
			DeliveryAddressLineElement
		] );
		
		super.LineSet(
			1,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#OrderNumber = OrderNumberComponent;
		
		this.#OrderDate = OrderDateComponent;
		
		this.#DeliveryAddress = DeliveryAddressComponent;
		
	}
	
	#OnValueChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.OnValueChange",  Value, IsValid );
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let IsValid = this.IsValidTotalGet( );
			
			let Value = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( Value, IsValid );
			
		}
		
	}
	
	Start( ){
		
		//1
		
		this.#Company.Start( );
		
		this.#Currency.Start( );
		
		this.#SaleDate.Start( );
		
		this.#ContractNumber.Start( );
		
		//2
		
		this.#OrderNumber.Start( );
		
		this.#OrderDate.Start( );
		
		this.#DeliveryAddress.Start( );
		
	}
	
	ContactNumberFilter( 
		Company
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.#ContactNumberFilter" );
		
		//let Company = this.#Company.ValueGet( );
		
		let ContactNumberModelFilterCb = function(
			APIFieldValueMap
		){
			
			let APIFieldValueFilled = APIFieldValueMap.Get( "filled" );
				
			let APIValueFilled = APIFieldValueFilled.Value( );
			
			let ValueFilled = APIValueFilled.Value( );
			
			
			let APIFieldValueCompany = APIFieldValueMap.Get( "company" );
				
			let APIValueCompany = APIFieldValueCompany.Value( );
			
			let ValueCompany = APIValueCompany.Value( );
			
			
			console.log( ValueFilled, Company, ValueCompany );
			
			
			if( Boolean( ValueFilled ) === true ){
				
				return false;
				
			}
			
			if( Company === null ){
																	
				return false;
				
			}
			
			if( ValueCompany === null ){
				
				return false;
				
			}
			
			if( Company !== ValueCompany.toString( ) ){
				
				return false;
				
			}
			
			return true;
			
		}
		
		console.log( this.#ContractNumberModelOptionMap );
		
		let CompanyModelOptionMap = this.#ContractNumberModelOptionMap.Filter( ContactNumberModelFilterCb );
		
		let CompanyUIOptionMap = CompanyModelOptionMap.ToUIOptionMap( "id", "contract_number" );
		
		console.log( CompanyUIOptionMap, CompanyModelOptionMap );
		
		this.#ContractNumber.Stop( );
	
		this.#ContractNumber.OptionMapSet( CompanyUIOptionMap );
		
		this.#ContractNumber.Start( );
	
	}
	
	#OnCompanyChange( 
		Value,
		IsValid
	){
		
		/*this.#ContractNumber.Stop( );
		
		this.#ContactNumberFilter( );
		
		this.#ContractNumber.Start( );
		
		
		this.#PreInvoice.Stop( );
		
		this.#PreInvoiceFilter( );
		
		this.#PreInvoice.Start( );
		
		
		this.#PostInvoice.Stop( );
		
		this.#PostInvoiceFilter( );
		
		this.#PostInvoice.Start( );
		
		
		this.#AdditionalText.Stop( );
		
		this.#AdditionalTextFilter( );
		
		this.#AdditionalText.Start( );*/
		
		if( super.EventExists( "CompanyChange" ) === true ){
			
			let EventFn = super.EventGet( "CompanyChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	#OnCurrencyChange(
		Value,
		IsValid
	){
			
		if( super.EventExists( "CurrencyChange" ) === true ){
			
			let EventFn = super.EventGet( "CurrencyChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	#OnContractChange(
		Value,
		IsValid
	){
			
		if( super.EventExists( "ContractChange" ) === true ){
			
			let EventFn = super.EventGet( "ContractChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		let FieldValueBucket = this.#ContractNumberModelOptionMap.FieldValueBucket( );
		
		let Contract = FieldValueBucket.Get( Value );
		
		let OrderNumberValue = Contract.Get( "contract_number" ).Value( ).Value( );
		
		let OrderDateValue = Contract.Get( "date" ).Value( ).Value( );
		
		let DeliveryAddressValue = Contract.Get( "address" ).Value( ).Value( );
		
		console.log( OrderNumberValue, OrderDateValue, DeliveryAddressValue );
		
		this.#OrderNumber.ValueSet( OrderNumberValue ? OrderNumberValue : "", false );
		
		this.#OrderDate.ValueSet( OrderDateValue, false );
		
		this.#DeliveryAddress.ValueSet( DeliveryAddressValue ? DeliveryAddressValue : "", false );
		
		//let CompanyModelOptionMap = this.#ContractNumberModelOptionMap.Get();
		
		this.#OnValueChange( 
			Value,
			IsValid
		);
		
	}
	
	Listen(
		OnChangeCompanyFn,
		OnChangeCurrencyFn,
		OnChangeContractFn,
		OnChangeFn
	){
		
		super.EventSet( "CompanyChange", OnChangeCompanyFn );
		
		super.EventSet( "CurrencyChange", OnChangeCurrencyFn );
		
		super.EventSet( "ContractChange", OnChangeContractFn );
		
		super.EventSet( "Change", OnChangeFn );
		
		//1
		
		this.#Company.Listen( 
			this.#OnCompanyChange.bind( this )
		);
		
		this.#Currency.Listen( 
			this.#OnCurrencyChange.bind( this )
		);
		
		this.#SaleDate.Listen(
			this.#OnValueChange.bind( this )
		);
		
		this.#ContractNumber.Listen( 
			this.#OnContractChange.bind( this )
		);
		
		//2
		
		this.#OrderNumber.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#OrderDate.Listen( 
			this.#OnValueChange.bind( this )
		);
		
		this.#DeliveryAddress.Listen(
			this.#OnValueChange.bind( this )
		);
		
	}
	
};


export default GTSUIModelSaleFormAddPrimarySaleFormT;