import React, { Component} from "react";

import Modal from "../components/common/modal";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';



    class ContactEditor extends Component {

        constructor(props) {
            super(props)


            this.state = {
                showModal: true
            }
        }


        isPopup() {
            return true;
        }


        render() {

            return (

                <Modal
                    title='sadasd'
                    visibility={this.state.showModal}
                    size="lg"
                    onClose={() => this.setState({showModal: !this.state.showModal})}
                >
                    fsdfsdfsd
                </Modal>

            );
        }


    }
ContactEditor.propTypes = {
        t: PropTypes.func.isRequired,
    };
    export default translate()(ContactEditor);


