import React, { Component } from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import SessionStorageService from "../services/SessionStorageService";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class GenerateRenderer extends Component {




    render() {
		
		var permissions = JSON.parse(localStorage.getItem('my_permissions'));
		
		var row=this.props.node.data;
		
		let MayPrintWord = false;
		
		console.log( this.props );
		
		if( 
			( this.props.model == 'loadings' && permissions.includes('loadings.print_word') ) || 
			( this.props.model == 'outinvoices' && permissions.includes('outinvoices.print_word') ) ||
			( this.props.model == 'supplylists' && permissions.includes('supplylists.print_word') ) 
		){
		
			MayPrintWord = true;
		
		}
		
        
        var ext='word';

        if (row.filetype==='pdf') ext='pdf';
        if (row.filetype==='xlsx') ext='excel';

        return (

            <>

            <div className="btn-group" style={{'marginRight':'6px', 'marginLeft':'6px'}}>

            <button
                className="btn btn-default btn-sm delete-ingrid-but"
                onClick={() => this.props.generateFunc('pdf_one', row)}
            ><i className="fa fa-file-pdf"></i>
            </button>


                {row.id != 72 && ( MayPrintWord == true ) &&
                <button
                    className="btn btn-default btn-sm delete-ingrid-but"
                    onClick={() => this.props.generateFunc(ext + '_one', row)}
                ><i className={"fa fa-file-" + ext}></i>
                </button>
                }
            </div>

            {row[this.props.colDef.field]}


            </>
        );
    }
}

GenerateRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(GenerateRenderer);