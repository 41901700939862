"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelCallRequestElementT from "./APIModelCallRequestElementT.mjs";


const APIModelCallRequestElementMapT = class APIModelCallRequestElementMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelCallRequestElementList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelKeyList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelCallRequestElementList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelCallRequestElementList.length;
			I++
		){

			if( ( APIModelCallRequestElementList[ I ] instanceof APIModelCallRequestElementT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelCallRequestElementList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelCallRequestElementList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelCallRequestElement = APIModelCallRequestElementList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelCallRequestElement );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelCallRequestElement
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelCallRequestElement instanceof APIModelCallRequestElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelCallRequestElement
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelCallRequestElementList = [ ];
		
		for(
			let I in JSONData
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelCallRequestElementList.push(
				APIModelCallRequestElementT.FromJSON(
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIModelCallRequestElementMapT(
			APIModelKeyList,
			APIModelCallRequestElementList
		);
		
	}
	
	ToJSON( 
	
	){
		
		let JSONData = { };
		
		
		for(
			let I of this.#Map.keys( )
		){
			
			console.log( I, this.#Map );
			
			JSONData[ I ] = ( this.#Map.get( I ) ).ToJSON( );
		
		}


		return JSONData;
		
	}
	
};


export default APIModelCallRequestElementMapT;