"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentPlaceholderT from "../../../../Interface/UI/Component/Placeholder/UIComponentPlaceholderT.mjs";


import UIComponentTextDataConfigT from "../../../../Type/UI/Component/TextData/UIComponentTextDataConfigT.mjs";


const UIComponentTextDataT = class UIComponentTextDataT extends UIComponentBaseT {

	#Value = null;
	
	#IsValid = true;
	
	#RegExpMatch = null;
	
	#RegExpValidation = null;
	
	#States = null;
	
	#StateIndex = 0;

	constructor(
		UIElement,
		UIComponentTextDataConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentTextDataConfig instanceof UIComponentTextDataConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentTextDataConfig
		);
		
		this.ConfigSet( UIComponentTextDataConfig );
		
		super.EditableSet( true );
		
		
		let RegExpMatch = UIComponentTextDataConfig.RegExpMatchGet( );
		
		this.#RegExpMatch = new RegExp( ( ( RegExpMatch === null ) ? ".*" : RegExpMatch ) , "" );
		
		
		let RegExpValidation = UIComponentTextDataConfig.RegExpValidationGet( );
		
		this.#RegExpValidation = new RegExp( ( ( RegExpValidation === null ) ? ".*" : RegExpValidation ), "" );
		
		
		this.#States = new Array( );
		
		this.#StateIndex = 0;
		
		this.#StateSave( );
		
		
		let ValueDefault = UIComponentTextDataConfig.ValueDefaultGet( );
		
		this.ValueSet( ValueDefault );
	
	}
	
	ConfigSet(
		UIComponentTextDataConfig
	){
		
		if( ( UIComponentTextDataConfig instanceof UIComponentTextDataConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		let UIStateMap = UIComponentTextDataConfig.StateMapGet( );
		
		let UIState = UIStateMap.Get( "Default" );
		
		let UIStyleMap = UIState.StyleMapGet( );
		
		
		super.StyleMapSet( UIStyleMap );
		
		super.ConfigSet( UIComponentTextDataConfig );
		
	}
	
	ConfigGet(
	
	){
		
		return super.ConfigGet( );
		
	}
	
	IsValidGet(
	
	){
			
		return this.#IsValid;
			
	}
	
	ValueGet( 
	
	){
		
		return this.#Value;
		
	}
	
	ValueSet( 
		Value,
		AnchorOffset = Value.length,
		FocusOffset = Value.length,
		NeedSelection = true
	){
		
		console.log( "UIComponentTextDataT.ValueSet", Value );
		
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#RegExpMatch !== null ){
			
			let MatchData = Value.match( this.#RegExpMatch );
			
			if( MatchData === null ){
				
				return;
				
			} else {
				
				if( Value !== MatchData[ 0 ] ){
					
					return;
					
				}
				
			}
			
		}
		
		
		super.InnerTextSet( 
			Value
		);
		
		
		this.#Value = Value;
		
		if( this.#RegExpValidation !== null ){
			
			let MatchData = Value.match( this.#RegExpValidation );
			
			if( MatchData === null ){
				
				console.log( "UIComponentTextDataT.ValueSet", "validation failed" );
			
				this.#IsValid = false;
			
			} else {
				
				console.log( "UIComponentTextDataT.ValueSet", "validation success", this.#RegExpValidation );
				
				this.#IsValid = true;
				
			}
			
		}
		
		
		if( NeedSelection === true ){
		
			let UIElement = this.UIElement( );
		
			let Element = UIElement.Render( );
		
		
			let Selection = document.getSelection( );

			Selection.setBaseAndExtent( Element.firstChild, AnchorOffset, Element.firstChild, FocusOffset );
		
		}
		
		this.#StateSave( );
		
		
		
		/*if( super.EventExists( "Change" ) === true ){
			
			let OnChangeFn = super.EventGet( "Change" );
			
			OnChangeFn( this.#Value, this.#IsValid );
			
		}*/
		
	}
	
	#StateSave( ){
		
		this.#States.splice( this.#StateIndex + 1, this.#States.length - this.#StateIndex );
		
		this.#States.push( super.InnerTextGet( ) );
		
		this.#StateIndex = this.#States.length - 1;
		
	}
	
	#Undo( ){
		
		if ( this.#StateIndex !== 0 ) {
			
			this.#StateIndex = this.#StateIndex - 1;
			
			
			let InnerText = this.#States[ this.#StateIndex ];
			
			super.InnerTextSet( InnerText );
			
			
			let Selection = document.getSelection( );
			
			let UIElement = super.UIElement( );
		
			let Element = UIElement.Render( );
			
			Selection.setBaseAndExtent( Element.firstChild, InnerText.length, Element.firstChild, InnerText.length );
			
		}
		
	}
	
	#Redo( ){
		
		if ( ( this.#StateIndex + 1 ) < this.#States.length ) {
			
			this.#StateIndex = this.#StateIndex + 1;
			
			
			let InnerText = this.#States[ this.#StateIndex ];
			
			super.InnerTextSet( InnerText );
			
			
			let Selection = document.getSelection( );
			
			let UIElement = super.UIElement( );
		
			let Element = UIElement.Render( );
			
			Selection.setBaseAndExtent( Element.firstChild, InnerText.length, Element.firstChild, InnerText.length );
		
		}
		
	}
	
	#OnCopy(  
		Ev
	){
		
		console.log( "UIComponentTextT.#OnCopy", Ev );
		
		
		Ev.preventDefault( );
		
		Ev.stopPropagation( );
		
		
		let Selection = document.getSelection( );
		
		let AnchorNode = Selection.anchorNode;
		
		let AnchorOffset = Selection.anchorOffset;
		
		let FocusNode = Selection.focusNode;
		
		let FocusOffset = Selection.focusOffset;
		
		
		let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
		let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
		
		let Value = this.#Value.slice( First, Second );
		
		
		Ev.clipboardData.setData( "text/plain", Value );
		
	}
	
	#OnCut(  
		Ev
	){
		
		console.log( "UIComponentTextT.#OnCut", Ev );
		
		
		Ev.preventDefault( );
		
		Ev.stopPropagation( );
		
		
		let Selection = document.getSelection( );
		
		let AnchorNode = Selection.anchorNode;
		
		let AnchorOffset = Selection.anchorOffset;
		
		let FocusNode = Selection.focusNode;
		
		let FocusOffset = Selection.focusOffset;
		
		
		let Value = "";
		
		let CopyValue = "";
		
		let NewFocusOffset = 0;
		
		let NewAnchorOffset = 0;
		
		
		if( FocusOffset === AnchorOffset ){
		
			return;
		
		}
		
		let InnerText = super.InnerTextGet( );
			
		let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
		
		let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
		
		Value = InnerText.slice( 0, First ) +
			InnerText.slice( Second );
			
		CopyValue = InnerText.slice( First, Second );
			
		NewFocusOffset = First;
		
		NewAnchorOffset = First;
			
		
		

		this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
		
		
		Ev.clipboardData.setData( "text/plain", CopyValue );
		
	}
	
	#OnPaste( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#OnPaste", Ev );
		
		
		Ev.preventDefault( );
		
		Ev.stopPropagation( );
		
		
		let PastedText = Ev.clipboardData.getData( "text/plain" );
		
		
		let Selection = document.getSelection( );
		
		let AnchorNode = Selection.anchorNode;
		
		let AnchorOffset = Selection.anchorOffset;
		
		let FocusNode = Selection.focusNode;
		
		let FocusOffset = Selection.focusOffset;
		
		
		let UIElement = super.UIElement( );
		
		let Element = UIElement.Render( );
		
		
		let Value = "";
		
		let NewFocusOffset = 0;
		
		let NewAnchorOffset = 0;
		
		
		let InnerText = super.InnerTextGet( );
		
		
		if( FocusOffset === AnchorOffset ){
		
			Value = InnerText.slice( 0, FocusOffset ) +
				PastedText +
				InnerText.slice( FocusOffset );
			
			NewFocusOffset = FocusOffset + PastedText.length;
			
			NewAnchorOffset = AnchorOffset + PastedText.length;
		
		} else {
			
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			Value = InnerText.slice( 0, First ) +
				PastedText +
				InnerText.slice( Second );
			
			NewFocusOffset = First + PastedText.length;
			
			NewAnchorOffset = First + PastedText.length;
			
		}
		

		this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
		
	}
	
	#ClipboardCopyError(
		Err
	){
		
		console.log( "UIComponentTextT.#ClipboardCopy", "fail", Err );
		
	}
	
	#ClipboardCopySuccess(
		
	){
		
		console.log( "UIComponentTextT.#ClipboardCopy", "success" );
		
	}
	
	#ClipboardCopy( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#ClipboardCopy", Ev );
		
		
		if( navigator.clipboard ){
		
			let Selection = document.getSelection( );
			
			let AnchorNode = Selection.anchorNode;
			
			let AnchorOffset = Selection.anchorOffset;
			
			let FocusNode = Selection.focusNode;
			
			let FocusOffset = Selection.focusOffset;
			
			
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
				
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			let Value = this.#Value.slice( First, Second );
			
			
			let Write = navigator.clipboard.writeText( Value );
			
			Write.catch( this.#ClipboardCopyError.bind( this ) );
			
			Write.then( this.#ClipboardCopySuccess.bind( this ) );
		
		} else {
			
			document.execCommand( "copy" );
			
		}
		
	}
	
	#ClipboardPasteError(
		Err
	){
		
		console.log( "UIComponentTextT.#ClipboardPaste", "fail", Err );
		
	}
	
	#ClipboardPasteSuccess(
		PastedText
	){
		
		console.log( "UIComponentTextT.#ClipboardPaste", "success", PastedText );
		
		
		let Selection = document.getSelection( );
				
		let AnchorNode = Selection.anchorNode;
		
		let AnchorOffset = Selection.anchorOffset;
		
		let FocusNode = Selection.focusNode;
		
		let FocusOffset = Selection.focusOffset;
		
		
		let UIElement = this.UIElement( );
		
		let Element = UIElement.Render( );
		
		
		let Value = "";
		
		let NewFocusOffset = 0;
		
		let NewAnchorOffset = 0;
		
		
		let InnerText = this.InnerTextGet( );
		
		
		if( FocusOffset === AnchorOffset ){
		
			Value = InnerText.slice( 0, FocusOffset ) +
				PastedText +
				InnerText.slice( FocusOffset );
			
			NewFocusOffset = FocusOffset + PastedText.length;
			
			NewAnchorOffset = AnchorOffset + PastedText.length;
		
		} else {
			
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			Value = InnerText.slice( 0, First ) +
				PastedText +
				InnerText.slice( Second );
			
			NewFocusOffset = First + PastedText.length;
			
			NewAnchorOffset = First + PastedText.length;
			
		}
		

		this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
		
	}
	
	#ClipboardPaste( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#ClipboardPaste", Ev );
		
		
		if( navigator.clipboard ){
		
			let Read = navigator.clipboard.readText( );
			
			Read.catch( this.#ClipboardPasteError.bind( this ) );
			
			Read.then( this.#ClipboardPasteSuccess.bind( this ) );
		
		} else {
			
			document.execCommand( "paste" );
			
		}
		
	}
	
	#ClipboardCutError(
		Err
	){
		
		console.log( "UIComponentTextT.#ClipboardCut", "fail", Err );
		
	}
	
	#ClipboardCutSuccess(
		
	){
		
		console.log( "UIComponentTextT.#ClipboardCut", "success" );
		
	}
	
	#ClipboardCut( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#ClipboardCut", Ev );
		
		if( navigator.clipboard ){
		
			let Selection = document.getSelection( );
		
			let AnchorNode = Selection.anchorNode;
			
			let AnchorOffset = Selection.anchorOffset;
			
			let FocusNode = Selection.focusNode;
			
			let FocusOffset = Selection.focusOffset;
			
			
			let Value = "";
			
			let CopyValue = "";
			
			let NewFocusOffset = 0;
			
			let NewAnchorOffset = 0;
			
			
			if( FocusOffset === AnchorOffset ){
			
				return;
			
			}
			
			let InnerText = super.InnerTextGet( );
				
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			Value = InnerText.slice( 0, First ) +
				InnerText.slice( Second );
				
			CopyValue = InnerText.slice( First, Second );
				
			NewFocusOffset = First;
			
			NewAnchorOffset = First;
				

			this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
			
			
			let Write = navigator.clipboard.writeText( CopyValue );
			
			Write.catch( this.#ClipboardCutError.bind( this ) );
			
			Write.then( this.#ClipboardCutSuccess.bind( this ) );
		
		} else {
			
			document.execCommand( "cut" );
			
		}
		
	}
	
	#Input( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#Input", Ev );
		
		
		if( Ev.key.length !== 1 ){
			
			return;
			
		}
		
		let Letter = Ev.key;
		
		
		let Selection = document.getSelection( );
		
		let AnchorNode = Selection.anchorNode;
		
		let AnchorOffset = Selection.anchorOffset;
		
		let FocusNode = Selection.focusNode;
		
		let FocusOffset = Selection.focusOffset;
		
		
		let UIElement = super.UIElement( );
		
		let Element = UIElement.Render( );
		
		
		
		let Value = "";
		
		let NewFocusOffset = 0;
		
		let NewAnchorOffset = 0;
		
		
		let InnerText = super.InnerTextGet( );
		
		
		if( FocusOffset === AnchorOffset ){
		
			Value = InnerText.slice( 0, FocusOffset ) +
				Letter +
				InnerText.slice( FocusOffset );
			
			NewFocusOffset = FocusOffset + 1;
			
			NewAnchorOffset = AnchorOffset + 1;
		
		} else {
			
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			Value = InnerText.slice( 0, First ) +
				Letter +
				InnerText.slice( Second );
			
			NewFocusOffset = First + 1;
			
			NewAnchorOffset = First + 1;
			
		}
		

		this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
		
	}
	
	#Delete(
		Ev
	){
		
		console.log( "UIComponentTextT.#Delete", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let UIElement = super.UIElement( );
		
		let Element = UIElement.Render( );
		
		
		let InnerText = super.InnerTextGet( );
		
		
		if( 
			( InnerText.length === 0 ) || 
			( 
				( FocusOffset === 0 ) &&
				( AnchorOffset === 0 )
			)
		){
			
			return;
			
		}

		
		let Value = "";
		
		let NewFocusOffset = 0;
		
		let NewAnchorOffset = 0;
		
		
		if( FocusOffset === AnchorOffset ){
		
			Value = InnerText.slice( 0, FocusOffset - 1 ) +
				InnerText.slice( FocusOffset );
			
			NewFocusOffset = FocusOffset - 1;
			
			NewAnchorOffset = AnchorOffset - 1;
		
		} else {
			
			let First = ( FocusOffset > AnchorOffset ) ? AnchorOffset : FocusOffset;
			
			let Second = ( FocusOffset > AnchorOffset ) ? FocusOffset : AnchorOffset;
			
			Value = InnerText.slice( 0, First ) +
				InnerText.slice( Second );
			
			NewFocusOffset = First;
			
			NewAnchorOffset = First;
			
		}
		

		this.ValueSet( Value, NewAnchorOffset, NewFocusOffset );
		
	}
	
	#CaretAnchorDecrement(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretAnchorDecrement", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		if( ( FocusOffset === 0 ) || ( InnerText.length === 0 ) ){
			
			Selection.setBaseAndExtent( AnchorNode, 0, FocusNode, 0 );
			
		} else {
		
			Selection.setBaseAndExtent( AnchorNode, FocusOffset - 1, FocusNode, FocusOffset - 1 );
		
		}
		
	}
	
	#CaretAnchorIncrement(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretAnchorIncrement", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		if( FocusOffset === InnerText.length ){
			
			Selection.setBaseAndExtent( AnchorNode, InnerText.length, FocusNode, InnerText.length );
			
		} else {
		
			Selection.setBaseAndExtent( AnchorNode, FocusOffset + 1, FocusNode, FocusOffset + 1 );
		
		}
		
	}
	
	#CaretFocusDecrement(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusDecrement", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		if( ( FocusOffset === 0 ) || ( InnerText.length === 0 ) ){
			
			Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, 0 );
			
		} else {
		
			Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, FocusOffset - 1 );
		
		}
		
	}
	
	#CaretFocusStart(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusStart", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, 0 );
		
	}
	
	#CaretAnchorStart(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusStart", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		Selection.setBaseAndExtent( AnchorNode, 0, FocusNode, 0 );
		
	}
	
	#CaretFocusEnd(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusEnd", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, InnerText.length );
		
	}
	
	#CaretAnchorEnd(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusEnd", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		Selection.setBaseAndExtent( AnchorNode, InnerText.length, FocusNode, InnerText.length );
		
	}
	
	#CaretFocusIncrement(
		Ev
	){
		
		console.log( "UIComponentTextT.#CaretFocusIncrement", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		if( FocusOffset === InnerText.length ){
			
			Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, InnerText.length );
			
		} else {
		
			Selection.setBaseAndExtent( AnchorNode, AnchorOffset, FocusNode, FocusOffset + 1 );
		
		}
		
	}
	
	#SelectAll(
		Ev
	){
		
		console.log( "UIComponentTextT.#SelectAll", Ev );
		
		
		let Selection = document.getSelection( );
		
		let FocusNode = Selection.focusNode;
		
		let AnchorNode = Selection.anchorNode;
		
		let FocusOffset = Selection.focusOffset;
		
		let AnchorOffset = Selection.anchorOffset;
		
		
		let InnerText = super.InnerTextGet( );
		
		Selection.setBaseAndExtent( AnchorNode, 0, FocusNode, InnerText.length );
		
	}
	
	
	#CaretPositionX( ) {
		
		let X = 0;
		
		let Selection = window.getSelection( );
		
		if( Selection.rangeCount > 0 ) {

			let Range = Selection.getRangeAt( 0 );
			
			let Rect = Range.getBoundingClientRect( );
			
			X = Rect.left;
			
		}
		
		return X;
	}

	#CaretPositionY( ) {
		
		let Y = 0;
		
		let Selection = window.getSelection( );
		
		if( Selection.rangeCount > 0 ) {

			let Range = Selection.getRangeAt( 0 );
			
			let Rect = Range.getBoundingClientRect( );
			
			Y = Rect.top;
		}
		
		return Y;
		
	}
	
	#OnKeyDown( 
		Ev 
	){
		
		console.log( "UIComponentTextT.#OnKeyDown", Ev );
		
		
		Ev.stopPropagation( );
			
		Ev.preventDefault( );
		

		if( ( Ev.ctrlKey === true ) && ( Ev.shiftKey === true ) && ( Ev.code === "KeyZ" ) ) {
			
			this.#Redo( Ev );
			
		} else if( ( Ev.ctrlKey === true ) && ( Ev.code === "KeyZ" ) ) {
		
			this.#Undo( Ev );
			
		} else if( ( Ev.ctrlKey === true ) && ( Ev.code === "KeyA" ) ) {
			
			this.#SelectAll( Ev );
			
		} else if( ( Ev.ctrlKey === true ) && ( Ev.code === "KeyC" ) ){
			
			this.#ClipboardCopy( Ev );
			
		} else if( ( Ev.ctrlKey === true ) && ( Ev.code === "KeyV" ) ){
			
			this.#ClipboardPaste( Ev );
			
		}  else if( ( Ev.ctrlKey === true ) && ( Ev.code === "KeyX" ) ){
			
			this.#ClipboardCut( Ev );
			
		} else if( Ev.code === "Backspace" ){
			
			this.#Delete( Ev );
			
		} else if( ( Ev.shiftKey === true ) && ( Ev.code === "ArrowLeft" ) ){
			
			this.#CaretFocusDecrement( Ev );
		
		} else if( Ev.code === "ArrowLeft" ){
			
			this.#CaretAnchorDecrement( Ev );
		
		} else if( ( Ev.shiftKey === true ) && ( Ev.code === "ArrowRight" ) ){
			
			this.#CaretFocusIncrement( Ev );
		
		} else if( Ev.code === "ArrowRight" ){
			
			this.#CaretAnchorIncrement( Ev );
		
		} else if( ( Ev.shiftKey === true ) && ( Ev.code === "ArrowUp" ) ){
			
			this.#CaretFocusStart( Ev );
		
		} else if( Ev.code === "ArrowUp" ){
			
			this.#CaretAnchorStart( Ev );
		
		} else if( ( Ev.shiftKey === true ) && ( Ev.code === "ArrowDown" ) ){
			
			this.#CaretFocusEnd( Ev );
		
		} else if( Ev.code === "ArrowDown" ){
			
			this.#CaretAnchorEnd( Ev );
		
		} else {
			
			this.#Input( Ev );
			
		}
		
		
		let UIElement = this.UIElement( );
		
		let Element = UIElement.Render( );
		
		let ElementRect = Element.getBoundingClientRect( );
		
		let CurrenctCaretPositionX = this.#CaretPositionX( );
		
		
		
		if( CurrenctCaretPositionX > ( ElementRect.x + ElementRect.width) ){
			
			let Offset = CurrenctCaretPositionX - ( ElementRect.x + ElementRect.width );
			
			console.log( Offset );
			
			Element.scrollBy( Offset, 0 );
			
		} else if( CurrenctCaretPositionX < ElementRect.x ){
			
			let Offset = ( ElementRect.x - CurrenctCaretPositionX );
			
			console.log( Offset );
			
			Element.scrollBy( -Offset, 0 );
			
		}
		
		if( super.EventExists( "Change" ) === true ){
			
			let OnChangeFn = super.EventGet( "Change" );
			
			OnChangeFn( this.#Value, this.#IsValid );
			
		}
		
	}
	
	#OnFocus(
		Ev
	){
		
		console.log( "UIComponentTextT.#OnFocus", Ev );
		
		
		let UIComponentTextDataConfig = super.ConfigGet( );
		
		if( UIComponentTextDataConfig.EmptyOnFocusGet( ) === true ){
		
			this.ValueSet( "" );
		
		}
		
		
		if( super.EventExists( "Focus" ) === true ){
			
			let EventFn = super.EventGet( "Focus" );
			
			EventFn( );
			
		}
		
	}
	
	#OnBlur(
		Ev
	){
		
		console.log( "UIComponentTextT.#OnBlur", Ev );
		
		
		let UIElement = this.UIElement( );
		
		let Element = UIElement.Render( );
		
		Element.scrollTo( 0, 0 );
		
		if( super.EventExists( "Blur" ) === true ){
		
			let EventFn = super.EventGet( "Blur" );
			
			EventFn( );
			
		}
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextT.Start" );
		
		
		let UIElement = super.UIElement( );
		
		
		UIElement.EventSet( "copy", this.#OnCopy.bind( this ) );
		
		UIElement.EventSet( "paste", this.#OnPaste.bind( this ) );
		
		UIElement.EventSet( "cut", this.#OnCut.bind( this ) );
		
		UIElement.EventSet( "keydown", this.#OnKeyDown.bind( this ) );
		
		UIElement.EventSet( "focusin", this.#OnFocus.bind( this ), true );
		
		UIElement.EventSet( "focusout", this.#OnBlur.bind( this ), true );
		
	}
	
	Stop(
	
	){
		
		let UIElement = super.UIElement( );
		
		
		UIElement.EventRemove( "copy" );
		
		UIElement.EventRemove( "paste" );
		
		UIElement.EventRemove( "cut" );
		
		UIElement.EventRemove( "keydown" );
		
		UIElement.EventRemove( "focusin" );
		
		UIElement.EventRemove( "focusout" );
		
	}
	
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		super.EventSet( "Change", OnChangeFn );
		
	}
	
	Unlisten( 
	
	){
		
		super.EventRemove( "Change" );
		
	}
	
	EditableSet(
		Editable
	){
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		super.EditableSet( Editable );
		
	}
	
	EditableGet(
		
	){
	
		return super.EditableGet( );
		
	}
	
};


export default UIComponentTextDataT;
