"use strict";


import UIElementT from "../../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import UIComponentLayoutHT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import TranslatorT from "../../../../../../../../../Interface/Translator/TranslatorT.mjs";

import GTSUIComponentFormSecondaryHeaderElementT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Header/GTSUIComponentFormSecondaryHeaderElementT.mjs";

import GTSUIComponentFormSecondaryHeaderT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Header/GTSUIComponentFormSecondaryHeaderT.mjs";


const GTSUIModelOutinvoiceFormAddItemsHeaderT = class GTSUIModelOutinvoiceFormAddItemsHeaderT extends GTSUIComponentFormSecondaryHeaderT {
	
	#T = null;
	
	#Code = null;
	#Description = null;
	#Unit = null;
	#Quantity = null;
	#ExcVAT = null;
	#VATRate = null;
	#VAT = null;
	#Remove = null;
	
	constructor( 
		Locale
	){


		let LabelUIStateMapDefault = new UIStateMapT( [
			
			new UIStateT(
				"Default",
				"UIComponentLabelT", //Class
				new UIStyleMapT( [ //UIStyleMap
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" ),
					new UIStyleT( "display", "flex" ),
					new UIStyleT( "flex", "0 0 auto" ),
					new UIStyleT( "align-self", "auto" )
				] )
			)
			
		] );
		
		
		let T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		//IsProduct
		
		let IsProduct = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				"M",//T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"40px"
		);
		
		//ProductType
		
		let ProductType = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Product type" ),
				LabelUIStateMapDefault.Copy( )
			),
			"20%"
		);
		
		//Code
		
		let Code = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Code" ),
				LabelUIStateMapDefault.Copy( )
			),
			"10%"
		);
		
		//Description
		
		let Description = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Description" ),
				LabelUIStateMapDefault.Copy( )
			),
			"20%"
		);
		
		//Unit
		
		let Unit = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Unit" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		//Quantity
		
		let Quantity = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Quantity" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		//ExcVAT
		
		let ExcVAT = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Exc. VAT" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		//ExcVAT
		
		let VATRate = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "VAT rate" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		//VAT
		
		let VAT = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "VAT" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		
		//Remove
		
		let Remove = new GTSUIComponentFormSecondaryHeaderElementT(
			new UIComponentLabelConfigT(
				T.Translate( "Delete" ),
				LabelUIStateMapDefault.Copy( )
			),
			"7%"
		);
		
		super( [
			IsProduct,
			ProductType,
			Code,
			Description,
			Unit,
			Quantity,
			ExcVAT,
			VATRate,
			VAT,
			Remove
		] )
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemsHeaderT;

/* "use strict";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutHConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutHConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import UIComponentLayoutHT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutHT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import TranslatorT from "../../../../../../../../Interface/Translator/TranslatorT.mjs";


const GTSUIModelOutinvoiceFormAddItemsHeaderT = class GTSUIModelOutinvoiceFormAddItemsHeaderT extends UIComponentLayoutHT {
	
	#T = null;
	
	#Code = null;
	#Description = null;
	#Unit = null;
	#Quantity = null;
	#ExcVAT = null;
	#VATRate = null;
	#VAT = null;
	#Remove = null;
	
	constructor( 
		Locale
	){

		let UIComponentLayoutHConfig = new UIComponentLayoutHConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutHT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] ),
		
			null //Id
		);
	
		super( 
			new UIElementT( ),
			UIComponentLayoutHConfig
		);
		
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		//Code
		
		this.#VAT = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "VAT" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElement.DataSet( this.#VAT );
		
		//UIComponentLayoutElement.Update( );
		
		super.ElementSet( 0, UIComponentLayoutElement );
		
		//Code
		
		this.#Code = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Code" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementCode = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementCode.DataSet( this.#Code );
		
		//UIComponentLayoutElementCode.Update( );
		
		super.ElementSet( 0, UIComponentLayoutElementCode );
		
		//Description
		
		this.#Description = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Description" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementDescription = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "30%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementDescription.DataSet( this.#Description );
		
		//UIComponentLayoutElementDescription.Update( );
		
		super.ElementSet( 1, UIComponentLayoutElementDescription );
		
		//Unit
		
		this.#Unit = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Unit" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementUnit = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementUnit.DataSet( this.#Unit );
		
		//UIComponentLayoutElementUnit.Update( );
		
		super.ElementSet( 2, UIComponentLayoutElementUnit );
		
		//Quantity
		
		this.#Quantity = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Quantity" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementQuantity = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementQuantity.DataSet( this.#Quantity );
		
		//UIComponentLayoutElementQuantity.Update( );
		
		super.ElementSet( 3, UIComponentLayoutElementQuantity );
		
		//ExcVAT
		
		this.#ExcVAT = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Exc. VAT" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementExcVAT = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementExcVAT.DataSet( this.#ExcVAT );
		
		//UIComponentLayoutElementExcVAT.Update( );
		
		super.ElementSet( 4, UIComponentLayoutElementExcVAT );
		
		//ExcVAT
		
		this.#VATRate = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "VAT rate" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementVATRate = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementVATRate.DataSet( this.#VATRate );
		
		//UIComponentLayoutElementVATRate.Update( );
		
		super.ElementSet( 5, UIComponentLayoutElementVATRate );
		
		//VAT
		
		this.#VAT = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "VAT" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementVAT = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementVAT.DataSet( this.#VAT );
		
		//UIComponentLayoutElementVAT.Update( );
		
		super.ElementSet( 6, UIComponentLayoutElementVAT );
		
		
		//Remove
		
		this.#Remove = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Delete" ),
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLabelT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "font-weight", "700" ),
							new UIStyleT( "font-size", "16px" ),
							new UIStyleT( "margin-bottom", "0.5rem" ),
							new UIStyleT( "white-space", "nowrap" ),
							new UIStyleT( "overflow", "hidden" ),
							new UIStyleT( "display", "flex" ),
							new UIStyleT( "flex", "0 0 auto" ),
							new UIStyleT( "align-self", "auto" )
						] )
					)
					
				] ),
				null //Id
			)
		);

		let UIComponentLayoutElementRemove = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							new UIStyleT( "width", "10%" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		UIComponentLayoutElementRemove.DataSet( this.#Remove );
		
		//UIComponentLayoutElementRemove.Update( );
		
		super.ElementSet( 7, UIComponentLayoutElementRemove );
		
	}
	
	Render( 
	
	){
		
		return super.Render( );
		
	}
	
};


export default GTSUIModelOutinvoiceFormAddItemsHeaderT; */