import React, {Component} from "react";

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import ExpenseAdd from "./expense_add.tsx";
import ExpensesReport from "./expenses_report.tsx";

import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import ActionsRenderer from "../../aggrid/ActionsRenderer.jsx";
import MainTableColumnDefs from "../../aggrid/MainTableColumnDefs.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import DatePicker from "../../aggrid/DatePicker.js";
import DateTimePicker from "../../aggrid/DateTimePicker.jsx";
import DateTimePickerSeconds from "../../aggrid/DateTimePickerSeconds.jsx";

import MultiSelectSimple from "../../aggrid/MultiSelectSimple.jsx";
import ManufacturingRenderer from "../../aggrid/ManufacturingRenderer.jsx";
import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData.jsx";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import {translate} from 'react-polyglot';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import PropTypes from 'prop-types';

import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {LoadState, SaveState} from "../../services/GridStateService";


class Expenses extends Component {
	
	#rowData = null;

    constructor( props ) {
		
		console.log( "ExpensesProps", props );
		
        let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ){
			
			locale = localStorage.getItem( 'locale' );
			
		}
		
		
        let loctext = null;
		
        if (locale == 'cs') {
			
			loctext = LocaleCs;
			
		} else if (locale == 'en') {
			
			loctext = LocaleEn;
			
		}
		
        const customComparator = function( valueA, valueB ){
			
            if( 
				( valueA && ( valueA != null ) ) && 
				( valueB && ( valueB != null ) ) && 
				( typeof( valueA.toLowerCase ) == 'function' ) && 
				( typeof( valueB.toLowerCase ) == 'function' ) ){
					
                return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
				
			} else {
				
				return false;
				
			}
			
        };


        super( props );
		
		
		//+У админа фильтруются по менеджеру(без комманды) лоадинги в добавлении.
		//+Он не может добавить произвольный лоадинг.
		//+Админ видит все экспенсы.
		//+Может редактировать все экспенсы.

		//==============================

		//Менеджер не может редактировать записи админа( created_by ).
		//Менеджер видит записи админа, где менеджер сам менеджер
		//Менеджер не видит записи админа, где менеджер - участник команды( team )
		//Менеджер может редактировать свои записи( created_by )
		//Менеджер может видеть записи своих подчиненных( created_by )
		//Менеджер не может редактировать записи своих подчиненных( created_by )
		//+Менеджер может добавлять только по себе 
		
		
		let my_user = JSON.parse( localStorage.my_user );
		
		let permissions = JSON.parse( localStorage.getItem( "my_permissions" ) );
		
		let isAdmin = permissions.includes( "expenses.cashiers_report" );
		
		/*for(
			let i in my_user.roles
		){
			
			if( my_user.roles[ i ].id == 1 ){
				
				isAdmin = true;
				
				break;
				
			}
					
		}*/
		
		let my_user_id = my_user.id.toString( );
		
		let team = null;
			
		if( isAdmin == true ){
			
			team = [ ];
			
		} else {
			
			team = JSON.parse( localStorage.my_team ).members.split( "//" );
			
		}
		
		let currencies = JSON.parse( JSON.parse( localStorage.getItem('columns_108') ).data );
		
		//TODO RUB UAH 
		
		let datetime = new Date( );
		
        this.state = {
			isAdmin: isAdmin,
			my_user_id: my_user_id,
			team: team,
			
			datetime: this.FormatDatetime( datetime ),
			currencies: currencies,
			
			loadingsArray: [ ],
			loadingsObject: { },
			
			loadingsArrayManager: [ ],
			loadingsObjectManager: { },
			
			filter_date_from: null,
			filter_date_to: null,
			filter_manager: "",
			
			debit: 0.00,
			credit: 0.00,
			debit_credit_before: 0.00,
			debit_credit_during: 0.00,
			debit_credit_difference: 0.00,

            rowSelected: false,
            popupParent: document.querySelector( 'body' ),

            showModal: false,
			showModalReport: false,

            modules: AllCommunityModules,
			locale: locale,
            localeText: loctext,
            rowdata: null,
			rowdata_filtered: null,
            columnDefs: [ ],


            statusBar: {
                statusPanels: [

                ]
            },

            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

                comparator: customComparator
            },

            frameworkComponents: {

                DeleteRenderer: DeleteRenderer,
                ActionsRenderer: ActionsRenderer,
                FileRenderer: FileRenderer,
                MultiSelectSimple: MultiSelectSimple,
                DateTimePicker: DateTimePicker,
				DateTimePickerSeconds: DateTimePickerSeconds,
                MultiSelectLargeData: MultiSelectLargeData,
                ManufacturingRenderer: ManufacturingRenderer,
                DatePicker: DatePicker,
            }

        };

    }
	
	FormatDatetime( date ){
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	GetRates( ){
	
		let url = UrlService.apiDomain() + 'api/exchange_rate/now';
		
		let Data = {
			datetime: this.state.datetime
		};
			
		HttpService.post( 
			url, Data
		).then( function( res ) {
		
			if( res.data === null ){
					
				alert( "Chyba připojení k serveru" );
				
				return;
			
			}
			
			if( 
				( res.data.result !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				return;
				
			}
			
			if( 
				( res.data.message !== 'success' )
			) {
				
				alert( "Chyba připojení k serveru\n" + res.data.message );
				
				return;
				
			}
			
			console.log( res.data );
			
			this.setState( {
				rates: res.data.rates
			} );
			
			this.GetExpenses( );
			

		}.bind( this ) ).catch( function( error ){
			
			console.log( error );
		
		}.bind( this ) );
	
	}
	
	GetLoadings( ){
	
		let url = UrlService.apiDomain( ) + 'api/loadings/0';
	
		HttpService.get( url )
            .then( function( res ) {
				
				const loadingsArray = [ ];
				const loadingsObject = { };
				
				res.data.map((e) => {
				
					e.name_cs = e.loading_number + " " + e.company;
					e.name_en = e.loading_number + " " + e.company;
				
					loadingsArray.push( e );
					
					loadingsObject[ e.id ] = e; 
					
				});
				
				
				this.setState( {
					loadingsArray: loadingsArray,
					loadingsObject: loadingsObject,
					loadingsArrayManager: loadingsArray,
					loadingsObjectManager: loadingsObject 
				} );
				
				
				let columnDefs = MainTableColumnDefs( 'expenses' );
				
				columnDefs[ 0 ].showDisabledCheckboxes = true;
				
				columnDefs[ 0 ].checkboxSelection = function( params ){
					
					if( this.state.isAdmin == true ){
							
						return true;
						
					} else {
				
						if( 
							( params.data.manager.toString( ) == this.state.my_user_id.toString( ) ) &&  
							( params.data.created_by.toString( ) == this.state.my_user_id.toString( ) ) 
							
						){
							
							return true;
							
						} else {
					
							return false;
						
						}
						
					}
					
				}.bind( this );
				
				for(
					let i in columnDefs
				){
					
					columnDefs[ i ].editable = function( params ){ 
					
						if( this.state.isAdmin == true ){
							
							return true;
							
						} else {
					
							if( 
								( params.data.manager.toString( ) == this.state.my_user_id.toString( ) ) &&  
								( params.data.created_by.toString( ) == this.state.my_user_id.toString( ) ) 
							){
								
								return true;
								
							} else {
						
								return false;
						
							}
							
						}
						
					}.bind( this );
					
				}
				
				
				let loadingColumnDef = null;
				
				for(
					let i in columnDefs
				){
					
					if( columnDefs[ i ].field == 'loadings' ){
						
						loadingColumnDef = columnDefs[ i ];
						
						break;
						
					}
					
				}
				
				loadingColumnDef.data_array = 'custom';
				
				loadingColumnDef.data = this.state.loadingsArrayManager;
				
				loadingColumnDef.valueGetter = function ( params ) {
					
					let values = [ ];
					
					if( ( !params.data[ 'loadings' ] ) || ( params.data[ 'loadings' ].length == 0 ) ){
						
						return "";
						
					}
					
					let ids = params.data[ 'loadings' ].split( '//' );
					
					ids.map( function( id ){
						
						console.log( this.state.loadingsObject[ id ], id );
						
						if( this.state.loadingsObject[ id ] ){
						
							values.push( this.state.loadingsObject[ id ][ 'name_' + this.state.locale ] );
	
						}
	
					}.bind( this ) );
					
					return values.join( "//" );
                
				}.bind( this );
				
				
				
				let currencyColumnDef = null;
				
				for(
					let i in columnDefs
				){
					
					if( columnDefs[ i ].field == 'currency' ){
						
						currencyColumnDef = columnDefs[ i ];
						
						break;
						
					}
					
				}
				
				currencyColumnDef.editable = true;
				
				currencyColumnDef.cellEditor = 'agRichSelectCellEditor';

				currencyColumnDef.cellEditorParams = {
					
					values: this.state.currencies.map( function( It ){ 
					
						return It[ 'name_' + this.state.locale ];
						
					}.bind( this ) )
					
				};

                currencyColumnDef.valueSetter = function( params ) {
					
					params.data[ 'currency' ] = this.state.currencies.find( function( It ){ 
					
						return ( It[ 'name_' + this.state.locale ] == params.newValue );
						
					}.bind( this ) ).id;
					
					return true;
					
				}.bind( this );
					
                currencyColumnDef.valueGetter = function( params ) {
					
					if ( !params.data || !params.data['currency'] ) {
						
						return "";
						
					}
					
					return this.state.currencies.find( function( It ) { 
					
						return It.id.toString( ) == params.data['currency'].toString( );
						
					}.bind( this ) )[ 'name_' + this.state.locale ];
					
				}.bind( this );
				
				
				columnDefs.push( {
					headerName: this.props.t( 'File' ),
					field: 'expense_files',
					cellRenderer: "FileRenderer",
					minWidth:400,
					suppressMenu: true,
					cellRendererParams: {
						gridType: 'expenses',
						type: 'scan',
						on_update: function( Id, RowData ){
							
							console.log( Id, RowData );
							
							let rownode = this.gridApi.getRowNode( Id );
					
							rownode.setData( RowData );
					
							this.gridApi.redrawRows({rowNodes: [rownode.parent]});
							
						}.bind( this )
					}
				} );
				
				
				columnDefs.push(
					{
						headerName: this.props.t('Delete'),
						cellRendererSelector: function( params ){
							
							if( this.state.isAdmin == true ){
							
								return {
									component: "DeleteRenderer",
									params: { gridType: "expenses" }
								};
								
							} else {
						
								if( 
									( params.data.manager.toString( ) == this.state.my_user_id.toString( ) ) &&  
									( params.data.created_by.toString( ) == this.state.my_user_id.toString( ) ) 
								){
									
									return {
										component: "DeleteRenderer",
										params: { gridType: "expenses" }
									};
									
								} else {
							
									return undefined;
							
								}
								
							}
							
						}.bind( this ),
						suppressMenu: true
					}
				);
				
				this.setState( {
					columnDefs: columnDefs
				} );
				
				this.GetRates( );
				
            }.bind( this ) )
            .catch( function( error ){
                console.log( error );
            }.bind( this ) )
	
	}

    onColumnsStateChanged( params ) {
		
		console.log( "onColumnsStateChanged" );
		
    }

	onDataReceived( ){
		
		console.log( "onDataReceived" );
		
	}

    GetExpenses( ) {
		
		let url = UrlService.apiDomain() + 'api/expenses';
			
		HttpService.get( 
			url 
		).then( function( res ) {
			
			//Filter
			
			let Rows = [ ];
			
			for(
				let i in res.data
			){
				
				if( this.state.isAdmin == true ){
					
					Rows.push( res.data[ i ] );
					
				} else {
				
					if( this.state.team.includes( res.data[ i ].manager.toString( ) ) ){
						
						if( res.data[ i ].manager == res.data[ i ].created_by ){
							
							Rows.push( res.data[ i ] );
						
						}
						
					} else if( this.state.my_user_id.toString( ) == res.data[ i ].manager.toString( ) ){
						
						Rows.push( res.data[ i ] );
						
					}
				
				}
				
			}
			
			this.#rowData = Rows;
			
			this.setFilter( );

		}.bind( this ) ).catch( function( error ){
			
			console.log( error );
		
		}.bind( this ) );
			

    }
	
	componentDidMount( ) {
		
		this.GetLoadings( );
		
	}

    componentDidUpdate( prevProps, prevState ) {
		
		console.log( "expenses update", this.props, prevProps );
		
		if( this.state.filter_date_from != prevState.filter_date_from ){
			
			this.setFilter( );
			
		}
		
		if( this.state.filter_date_to != prevState.filter_date_to ){
			
			this.setFilter( );
			
		}
		
		if( this.state.filter_manager != prevState.filter_manager ){
			
			this.setFilter( );
			
		}
		
		if( this.props.Continent !== this.props.Continent ){
			
			this.setFilter( );
			
		}
		
		if( this.props.country !== prevProps.country ){
			
			this.setFilter( );
			
		}
		
    }
	
	CalculateDebitCreditBefore( ){
		
		let amount = 0;
		
		
		let date_from = 0;
		
		if( this.state.filter_date_from != null ){
			
			date_from = this.state.filter_date_from.getTime( );
		
		} else {
			
			date_from = new Date( "1970-01-01T00:00:00.000Z" ).getTime( );
			
		}
		
		let manager = this.state.filter_manager;
		
		for(
			let I = 0;
			I < this.#rowData.length;
			I++
		){
			
			let data = this.#rowData[ I ];
				
			let date_node = new Date( data.datetime );
			
			if( date_from >= date_node ){
				
				if( manager != "" ){
					
					if( data.manager == manager ){
						
						console.log( "before(with manager)", data.amount );
						
						amount = amount + ( parseFloat( data.amount_eur ) );
						
					}
					
				} else {
					
					console.log( "before(no manager)", data.amount );
				
					amount = amount + ( parseFloat( data.amount_eur ) );
				
				}
				
			}
			
		}
		
		return amount;
		
	}
	
	CalculateDebitCreditAfter( ){
		
		let amount = 0;
		
		
		let date_from = 0;
		
		if( this.state.filter_date_from != null ){
			
			date_from = this.state.filter_date_from.getTime( );
		
		} else {
			
			date_from = new Date( "1970-01-01T00:00:00.000Z" ).getTime( );
			
		}
		
		let date_to = 0;
		
		if( this.state.filter_date_to != null ){
			
			date_to = this.state.filter_date_to.getTime( ) + ( 24 * 60 * 60 * 1000 );
		
		} else {
			
			date_to = new Date( "2099-12-31T23:59:59.000Z" ).getTime( );
			
		}
		
		let manager = this.state.filter_manager;
		
		for(
			let I = 0;
			I < this.#rowData.length;
			I++
		){
			
			let data = this.#rowData[ I ];
				
			let date_node = new Date( data.datetime );
			
			if( date_from <= date_node && date_to >= date_node ){
				
				if( manager != "" ){
					
					if( data.manager == manager ){
						
						console.log( "after(with manager)", data.amount );
						
						amount = amount + ( parseFloat( data.amount_eur ) );
						
					}
					
				} else {
					
					console.log( "after(no manager)", data.amount );
				
					amount = amount + ( parseFloat( data.amount_eur ) );
				
				}
				
			}
			
		}
		
		return amount;
		
	}
	
	CalculateDebit( ){
		
		let amount = 0;
		
		
		let date_from = 0;
		
		if( this.state.filter_date_from != null ){
			
			date_from = this.state.filter_date_from.getTime( );
		
		} else {
			
			date_from = new Date( "1970-01-01T00:00:00.000Z" ).getTime( );
			
		}
		
		let date_to = 0;
		
		if( this.state.filter_date_to != null ){
			
			date_to = this.state.filter_date_to.getTime( ) + ( 24 * 60 * 60 * 1000 );
		
		} else {
			
			date_to = new Date( "2099-12-31T23:59:59.000Z" ).getTime( );
			
		}
		
		let manager = this.state.filter_manager;
		
		for(
			let I = 0;
			I < this.#rowData.length;
			I++
		){
			
			let data = this.#rowData[ I ];
				
			let date_node = new Date( data.datetime );
			
			if( date_from <= date_node && date_to >= date_node ){
				
				if( manager != "" ){
					
					if( data.manager == manager ){
						
						console.log( "after(with manager)", data.amount );
						
						let Res = parseFloat( data.amount_eur );
						
						if( Res > 0 ){
						
							amount = amount + Res;
						
						}
						
					}
					
				} else {
					
					console.log( "after(no manager)", data.amount );
					
					let Res = parseFloat( data.amount_eur );
					
					if( Res > 0 ){
				
						amount = amount + Res;
						
					}
				
				}
				
			}
			
		}
		
		return amount;
		
	}
	
	CalculateCredit( ){
		
		let amount = 0;
		
		
		let date_from = 0;
		
		if( this.state.filter_date_from != null ){
			
			date_from = this.state.filter_date_from.getTime( );
		
		} else {
			
			date_from = new Date( "1970-01-01T00:00:00.000Z" ).getTime( );
			
		}
		
		let date_to = 0;
		
		if( this.state.filter_date_to != null ){
			
			date_to = this.state.filter_date_to.getTime( ) + ( 24 * 60 * 60 * 1000 );
		
		} else {
			
			date_to = new Date( "2099-12-31T23:59:59.000Z" ).getTime( );
			
		}
		
		let manager = this.state.filter_manager;
		
		for(
			let I = 0;
			I < this.#rowData.length;
			I++
		){
			
			let data = this.#rowData[ I ];
				
			let date_node = new Date( data.datetime );
			
			if( date_from <= date_node && date_to >= date_node ){
				
				if( manager != "" ){
					
					if( data.manager == manager ){
						
						console.log( "after(with manager)", data.amount );
						
						let Res = parseFloat( data.amount_eur );
						
						if( Res < 0 ){
						
							amount = amount + Res;
						
						}
						
					}
					
				} else {
					
					console.log( "after(no manager)", data.amount );
				
					let Res = parseFloat( data.amount_eur );
						
					if( Res < 0 ){
					
						amount = amount + Res;
					
					}
				
				}
				
			}
			
		}
		
		return amount;
		
	}
	
	CalculateDebitCredit( ){
		
		let Debit = this.CalculateDebit( );
		
		let Credit = this.CalculateCredit( );
		
		let Before = this.CalculateDebitCreditBefore( );

		let After = this.CalculateDebitCreditAfter( );
		
		let Diff = ( After - Before );
		
		
		console.log( "BeforeAfterDiff", Before, After, Diff );
		
		
		this.setState( {
			debit: Debit,
			credit: Credit,
			debit_credit_before: Before,
			debit_credit_during: After, 
			debit_credit_difference: Diff
		} );
		
	}

	setFilter( ){
		
		console.log( "setFilter", this.state.isAdmin, this.state.filter_manager, this.state.filter_date_from, this.state.filter_date_to, this.props.continent, this.props.country );
		
		
		
		let users = JSON.parse( localStorage.getItem( 'users' ) );
		
		if( this.#rowData == null ){
			
			return;
			
		}
		
		let RowData = this.#rowData;
		
		let FilteredData = [ ];
		
		
		
		for(
			let I = 0;
			I < RowData.length;
			I++
		){
			
			if( ( this.state.filter_manager === "" ) || ( this.state.filter_manager == RowData[ I ].manager ) ) {
				
				FilteredData.push( RowData[ I ] );
				
			}
			
		}
		
		
		let FilteredData2 = [ ];
		
		for(
			let I = 0;
			I < FilteredData.length;
			I++
		){
			
			if( ( this.state.filter_date_from != null ) || ( this.state.filter_date_to != null ) ){
			
				console.log( FilteredData[ I ].datetime, this.state.filter_date_from, this.state.filter_date_to );
				
				let D = FilteredData[ I ].datetime ? new Date( FilteredData[ I ].datetime ) : null;
				
				let DS = this.state.filter_date_from ? new Date( this.state.filter_date_from ) : null;
				
				let DE = this.state.filter_date_to ? new Date( this.state.filter_date_to ) : null;
				
				if( DE !== null ){
				
					DE.setDate( DE.getDate( ) + 1 );
				
				}
				
				if( D === null ){
					
					FilteredData2.push( FilteredData[ I ] );
					
				} else {
					
					if( ( DS !== null ) && ( DE === null ) ){
						
						if( DS.getTime( ) <= D.getTime( ) ){
							
							FilteredData2.push( FilteredData[ I ] );
							
						}
						
					} else if( ( DS === null ) && ( DE !== null ) ){
						
						if( DE.getTime( ) >= D.getTime( ) ){
							
							FilteredData2.push( FilteredData[ I ] );
							
						}
						
					} else if( ( DS === null ) && ( DE === null ) ){
						
						FilteredData2.push( FilteredData[ I ] );
						
					} else {
				
						if( ( DS.getTime( ) <= D.getTime( ) ) && ( DE.getTime( ) >= D.getTime( ) ) ){
							
							FilteredData2.push( FilteredData[ I ] );
							
						}
					
					}
				
				}
				
			} else {
				
				FilteredData2.push( FilteredData[ I ] );
				
			}
			
		}
		
		
		
		let FilteredData3 = [ ];
		
		for(
			let I = 0;
			I < FilteredData2.length;
			I++
		){
		
			if( this.props.country ){
				
				if( FilteredData2[ I ].loadings !== null ){
					
					let LoadingIds = FilteredData2[ I ].loadings.split( "//" );
				
					for( 
						let I2 = 0;
						I2 < LoadingIds.length;
						I2++
					){
						
						if( this.state.loadingsObject[ LoadingIds[ I2 ] ] ){
							
							if( this.state.loadingsObject[ LoadingIds[ I2 ] ].country == this.props.country ){
								
								FilteredData3.push( FilteredData2[ I ] );
								
							}
							
						}
						
					}
				
				}
			
			} else {
				
				FilteredData3.push( FilteredData2[ I ] );
				
			}
				
		}
		
		
		
		
		
		console.log( FilteredData, FilteredData2, FilteredData3 );
		
		this.CalculateDebitCredit( );
		
		this.setState( {
			
			rowdata_filtered: FilteredData3
			
		} );
		
		
		/*let filtr = {
			datetime: null,
			manager: null
		};
		
		if( ( this.state.filter_date_from != null ) || ( this.state.filter_date_to != null ) ){
			
			filtr.datetime = {
				filterType: 'date',
				type: 'inRange'
			};
			
			if( this.state.filter_date_from != null ){
			
				filtr.datetime.dateFrom = this.FilterFormatDateTime( this.state.filter_date_from );
				
			} else {
				
				filtr.datetime.dateFrom = this.FilterFormatDateTime( new Date( "1970-01-01T00:00:00.000Z" ) );
				
			}
			
			if( this.state.filter_date_to != null ){
				
				filtr.datetime.dateTo = this.FilterFormatDateTime( new Date( this.state.filter_date_to.getTime( ) + ( 24 * 60 * 60 * 1000 ) ) );
				
			} else {
				
				filtr.datetime.dateTo = this.FilterFormatDateTime( new Date( "2099-12-31T23:59:59.000Z" ) );
				
			}
			
		}
		
		if( this.state.isAdmin == true ){
		
			if( this.state.filter_manager != "" ){
				
				let users = JSON.parse( localStorage.getItem( 'users' ) );
				
				let managers = [ ];
				
				for(
					let i in users
				){
					
					if( users[ i ].id == this.state.filter_manager ){
						
						managers.push( users[ i ][ 'name_' + this.state.locale ] )
						
					}
					
				}
				
				filtr.manager = {
					type: 'set',
					values: managers
				};
				
			}
		
		} else {
			
			if( this.state.filter_manager == "" ){
				
				//TODO filter in future if needed
				
			}
			
		}
		
		
		this.CalculateDebitCredit( );
		
		console.log( filtr );
		
		this.gridApi.setFilterModel( filtr );
			
		this.gridApi.onFilterChanged( );*/
		
    }

    onGridReady(params){
		
        this.gridApi = params.api;
		
        this.gridColumnApi = params.columnApi;
	

        this.gridApi.setDomLayout( 'normal' );
		
		//this.setFilter( );
		
    }

    onCellValueChanged( Ev ){
		
		console.log( Ev );
		
		if( Ev.newValue == Ev.oldValue ){
			
			return;
			
		}
		
		let expense_types = JSON.parse( localStorage.expense_types );
		
		let expense_type = Ev.data.expense_type;
		
		let positive = false;
		
		for(
			let i in expense_types
		){
			
			console.log( expense_types[ i ].id, expense_type );
		
			if( expense_types[ i ].id == expense_type ){
				
				console.log( expense_types[ i ].positive );
				
				if( expense_types[ i ].positive ){
				
					positive = true;
					
					break;
				
				}
				
			}
		
		}
		
		Ev.data.field = Ev.column.colId;
		
		Ev.data.updated_by = JSON.parse( localStorage.my_user ).id;
		
		if( positive === true ) {
			
			if( Ev.data.amount < 0 ){
			
				Ev.data.amount = -Ev.data.amount;

			}

		} else{	
		
			if( Ev.data.amount > 0 ){
				
				Ev.data.amount = -Ev.data.amount;
				
			} 

		}

        HttpService.put(UrlService.apiDomain( ) + 'api/expenses/' + Ev.data.id, Ev.data )
            .then( function( Res ) {
				
                if( Res.data == null ) {
					
					alert("Chyba připojení k serveru");
					
					return;
					
				}
				
				let rownode = this.gridApi.getRowNode( Res.data.entry.id );
				
				Res.data.entry.files = rownode.data.files;
				
				if ( Res.data.result == 'failed' ) {
					
                    alert( Res.data.message );
					
					rownode.setData( Res.data.entry );
					
                    this.gridApi.redrawRows({rowNodes: [rownode.parent]});

                } else {
					
                    console.log( 'Successfully updated' );
					
					rownode.setData( Res.data.entry );
					
                    this.gridApi.redrawRows({rowNodes: [rownode.parent]});

                }
				
            }.bind( this ) )
            .catch( function( Err ){
				
                alert( Err );
				
                console.log( Err );
				
            }.bind( this ))

    }

    onCellClicked( Ev ){

		if( Ev.column.colId == 'loadings' ){
			
			if( this.state.isAdmin == true ){
				
				const loadingsArray = [ ];
				const loadingsObject = { };
					
				for( let i in this.state.loadingsArray ){
					
					let loading = this.state.loadingsArray[ i ];
					
					if( loading.manager == Ev.data.manager ){
						
						loadingsArray.push( loading );
						
						loadingsObject[ loading.id ] = loading;
						
					}
					
				}
				
				let columnDefs = this.state.columnDefs;
				
				let loadingColumnDef = null;
					
				for(
					let i in columnDefs
				){
					
					if( columnDefs[ i ].field == 'loadings' ){
						
						loadingColumnDef = columnDefs[ i ];
						
						break;
						
					}
					
				}
				
				loadingColumnDef.data = loadingsArray;
				
				this.setState( {
					loadingsArrayManager: loadingsArray,
					loadingsObjectManager: loadingsObject,
					columnDefs: columnDefs
				} );
				
			} else {
			
				const loadingsArray = [ ];
				const loadingsObject = { };
					
				for( let i in this.state.loadingsArray ){
					
					let loading = this.state.loadingsArray[ i ];
					
					if( loading.manager == Ev.data.manager ){
						
						loadingsArray.push( loading );
						
						loadingsObject[ loading.id ] = loading;
						
					}
					
				}
				
				let columnDefs = this.state.columnDefs;
				
				let loadingColumnDef = null;
					
				for(
					let i in columnDefs
				){
					
					if( columnDefs[ i ].field == 'loadings' ){
						
						loadingColumnDef = columnDefs[ i ];
						
						break;
						
					}
					
				}
				
				loadingColumnDef.data = loadingsArray;
				
				this.setState( {
					loadingsArrayManager: loadingsArray,
					loadingsObjectManager: loadingsObject,
					columnDefs: columnDefs
				} );
				
			}
			
		}

    }

    getContextMenuItems( params ){

        return [
            'copy',
            'paste',
            'export',
        ];
		
    }

    getRowNodeId( data ){
		
        return data.id;
		
    }

    async HandleAdd( Data ) {
		
		Data.created_by = JSON.parse( localStorage.my_user ).id;
		
		let RequestFormData = new FormData( );

        for( let Key in Data ) {
			
            if( Key !== 'files'){

				RequestFormData.append( Key, Data[ Key ] );
			
			}
			
        }
		
		RequestFormData.append( 'filecount', Data.files.length );

        if ( Data.files.length !== 0 ){
			
			let Files = [ ];
			
			for(
				let I = 0;
				I < Data.files.length;
				I++
			){
				
				RequestFormData.append( 'file_' + I, Data.files[ I ] );
				
				RequestFormData.append( 'filename_' + I, Data.files[ I ].name );
				
			}
		
		}
		

        HttpService.post( UrlService.apiDomain() + 'api/expenses', RequestFormData )
            .then(res => {
				
                if( res.data === null ){
					
					alert( "Chyba připojení k serveru" );
                
				}
				
				if( 
					( res.data.result !== 'success' )
				) {
					
                    alert( "Chyba připojení k serveru\n" + res.data.message );
					
                } else {
					
                    console.log( 'Successfully updated' );
					
                    this.setState( { showModal: false } );


                    let result = this.gridApi.applyTransaction({
                        add: [ res.data.entry ],
                        addIndex: 0
                    });

                    this.gridApi.flashCells({rowNodes: result.add});

                }

            })

            .catch( ( error ) => {
				
                alert( error );
				
                console.log( error );
				
            });


    }

    async HandleDelete( ) {

        confirmAlert( {
			
            title: this.props.t( 'Confirm to delete' ),
            message: this.props.t( 'Are you sure to delete this item and all other items associated with it?' ),
            buttons: [
			
                {
                    label: this.props.t( 'Yes' ),
                    onClick: function( ) {
						
						let url_start = UrlService.apiDomain( ) + 'api/expenses/';

                        this.gridApi.getSelectedNodes( ).forEach( function( SelectedNode ) {
							
                            HttpService.delete( url_start + SelectedNode.data.id )
                                .then( function( Res ) {
									
                                    console.log( 'Success' );
									
                                    this.gridApi.updateRowData( {
										
										remove: [ SelectedNode.data ] 
										
									} )
									
                                }.bind( this ) )
                                .catch( function( Err ) {
									
									console.log( 'Error' );
									
									console.log( Err );
									
                                    alert( Err );
									
                                }.bind( this ) );

                        }.bind( this ) );

                    }.bind( this )
                },
				
                {
                    label: this.props.t( 'No' ),

                }
				
            ]
        } );

    }

    onRowSelected( ) {
		
        if ( this.gridApi.getSelectedNodes().length > 0 ) {
			
            this.setState( { rowSelected: true } );
			
        } else {
			
            this.setState( { rowSelected: false } );
			
        }
		
    }
	
	export_to_excel( ) {

        this.gridApi.exportDataAsExcel( );

    }

    onFilterChanged( params ) {
		
		console.log( "onFilterChanged", params, this.gridApi.getFilterModel( ) );
		
        if ( typeof( this.gridApi ) != 'undefined' ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel( 'statusCountKey' );
			
            let componentInstance = statusBarComponent;
			
            if ( typeof( statusBarComponent ) !== 'undefined' && statusBarComponent.getFrameworkComponentInstance ) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }
	
	FilterFormatDateTime( date ) {
		
		return this.FilterFormatDate( date ) + ' ' + this.FilterFormatTime( date );
				
	}
	
	FilterFormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FilterFormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	SortManagers( V1, V2 ) {

		return V1[ 'name_' + this.state.locale ].toLowerCase( ).localeCompare( V2[ 'name_' + this.state.locale ].toLowerCase( ) );
		
	}

	MakeItemManager( Val ) {
		
		console.log( Val );

		return <option key={Val.id} value={Val.id}>{Val['name_' + this.state.locale]}</option>;
  
	}

    render( ) {
		
		let permissions = JSON.parse( localStorage.getItem( "my_permissions" ) );
		
		let rowClassRules = null;

        let height = '100%';
	
        return (
            <>
               
                <div className="buttons-companies">
				
                    <div className="tool-buttons">
						
						<button
							className="btn btn-success btn-sm"
							onClick={() => this.setState({ showModal: true })}
						>
							{this.props.t('Add new')} <i className="fa fa-plus"></i>
						</button>
					
					
						<button
							className="btn btn-danger btn-sm"
							onClick={() => this.HandleDelete()}
						>
							{this.props.t('Delete')} <i className="fa fa-trash"></i>
						</button>
						
					</div>
						
					<div className="row">
					
						
						<div className="col-2">
							<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
								<span style={{marginRight:'10px'}}><strong>Od:</strong></span> 
							</div>
							<Datetime
								timeFormat={ false }
								locale={ this.state.locale }
								dateFormat="DD.MM.YYYY"
								value={ this.state.filter_date_from }

								onChange={(event) => {
									
									if( !event ){
										
										this.setState( { filter_date_from: null } );
										
									} else {
										
										if( typeof( event.toDate ) != 'function' ){
											
											this.setState( { filter_date_from: null } );
											
										} else {
									
											this.setState( { filter_date_from: event.toDate( ) } );
											
										}
										
									}
									
								}}
							/>
						</div>
						
						<div className="col-2">
							<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
								<span style={{marginRight:'10px'}}><strong>Do:</strong></span> 
							</div>
							<Datetime
								timeFormat={ false }
								locale={ this.state.locale }
								dateFormat="DD.MM.YYYY"

								value={ this.state.filter_date_to }

								onChange={(event) => {
									
									if( !event ){
										
										this.setState( { filter_date_to: null } );
										
									} else {
										
										if( typeof( event.toDate ) != 'function' ){
											
											this.setState( { filter_date_to: null } );
											
										} else {
									
											this.setState( { filter_date_to: event.toDate( ) } );
											
										}
										
									}
									
								}}
							/>
						</div>
						
						{( this.state.isAdmin == true ) &&
							<>
								<div className="col-2">
									<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
										<span style={{marginRight:'10px'}}><strong>Manažer:</strong></span> 
									</div>
									<select
										value={ this.state.filter_manager }
										required
										className="form-control"
										onChange={(event) => {
											
											console.log( event.target.value );
											
											this.setState( { filter_manager: event.target.value } );
											
										}
										}>
											<option value="">{this.props.t("Select manager")}</option>
											{ 
												JSON.parse( localStorage.getItem( 'users' ) ).sort(
													this.SortManagers.bind( this )
												).map( 
													this.MakeItemManager.bind( this ) 
												)
											}
									</select>
								</div>
							</>
						}
						
						
						{( this.state.isAdmin == true ) && 
							<div className="col-1.">
								<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
									<span style={{marginRight:'10px'}}><strong></strong></span> 
								</div>
						
								
							
								<div style={{marginTop:'2px'}}>
									<button
										style={{width:"100%"}}
										className="btn btn-success btn-sm"
										onClick={() => this.setState({ showModalReport: true })}
									>
										{this.props.t('Cashier\'s report')} <i className="fa fa-file-invoice-dollar"></i>
									</button>
								</div>
								
							</div>
							
						}
						
						{permissions.includes( "expenses.excel" ) && 
								
							<div className="col-1.">
								<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
									<span style={{marginRight:'10px'}}><strong></strong></span> 
								</div>
									
								<div style={{marginTop:'2px',marginLeft:'2px'}}>
									<button
										style={{width:"100%"}}
										type="button"
										className="btn btn-primary btn-sm"
										onClick={ this.export_to_excel.bind( this ) }
									>
										<i className="fa fa-file-excel"></i>
									</button>
								</div>
								
						
							</div>
							
						}
						
					</div>
					
					<div className="row">
						<div className="col-2">
							<div className="ag-status-name-value ag-status-panel ag-status-panel-total-row-count">
								<span style={{marginRight:'10px'}}>{this.props.t("Before period")}: <strong>{ this.state.debit_credit_before.toFixed( 2 ) + " €" }</strong></span>
								<span style={{marginRight:'10px'}}>{this.props.t("Revenues")}: <strong>{ this.state.debit.toFixed( 2 ) + " €" }</strong></span>
								<span style={{marginRight:'10px'}}>{this.props.t("Cost")}: <strong>{ this.state.credit.toFixed( 2 ) + " €" }</strong></span>
								<span style={{marginRight:'10px'}}>{this.props.t("Difference")}: <strong>{ this.state.debit_credit_during.toFixed( 2 ) + " €" }</strong></span>
								<span style={{marginRight:'10px'}}>{this.props.t("During period")}: <strong>{ ( this.state.debit_credit_before + this.state.debit_credit_during ).toFixed( 2 ) + " €" }</strong></span>
							</div>
						</div>
					</div>
					
					
					<Modal
						title={this.props.t('Add new')}
						visibility={this.state.showModal}
						size="lg"
						onClose={() => this.setState({ showModal: !this.state.showModal })}
					>
						<ExpenseAdd 
							onAdd={
								(Data) => this.HandleAdd( Data )
							} 
							loadingsArray={
								this.state.loadingsArray
							}
							isAdmin={
								this.state.isAdmin
							}
							manager={
								null
							}
							loadingsSelected={
								null
							}
							country={
								this.props.country
							}
						/>
					</Modal>
					
					<Modal
						title={this.props.t('Report')}
						visibility={this.state.showModalReport}
						size="lg"
						onClose={() => this.setState({ showModalReport: !this.state.showModalReport })}
					>
						<ExpensesReport 
							isopen={
								this.state.showModalReport
							}
							expenses={
								this.state.rowdata_filtered
							}
							expenses_all={
								this.#rowData
							}
							rates={
								this.state.rates
							}
							date_from={
								this.state.filter_date_from
							}
							date_to={
								this.state.filter_date_to
							}
						/>
					</Modal>

                </div>
				

                <div style={{'height': height, width: '100%'}} id="expensesGrid" className="ag-theme-alpine">
                    <AgGridReact
                        modules={this.state.modules}
                        rowData={this.state.rowdata_filtered}
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady.bind( this )}
                        onCellValueChanged={this.onCellValueChanged.bind( this )}
                        onCellClicked={this.onCellClicked.bind( this )}
                        frameworkComponents={this.state.frameworkComponents}
                        components={this.state.components}
                        enableCellChangeFlash={true}
                        rowSelection={'multiple'}
                        popupParent={this.state.popupParent}
                        suppressContextMenu={false}
                        pagination={true}
                        onRowSelected={this.onRowSelected.bind( this )}
                        paginationPageSize={1000}
                        localeText={this.state.localeText}
                        statusBar={this.state.statusBar}
                        getRowNodeId={this.getRowNodeId.bind( this )}
                        getContextMenuItems={this.getContextMenuItems.bind( this )}
                        enableGroupEdit={true}
                        onColumnVisible={this.onColumnsStateChanged.bind( this )}
                        onDragStopped={this.onColumnsStateChanged.bind( this )}
                        onFilterChanged={this.onFilterChanged.bind( this )}
                        suppressAggFuncInHeader={true}
                        suppressRowClickSelection={true}
						rowClassRules={rowClassRules}
                        suppressChangeDetection={true}
                    />
                </div>
				
            </>
			
        );
		
    }
	
}

Expenses.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate( )(Expenses);

