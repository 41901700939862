"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentTextT from "../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";


import UIComponentDoubleConfigT from "../../../../Type/UI/Component/Double/UIComponentDoubleConfigT.mjs";



const UIComponentDoubleT = class UIComponentDoubleT extends UIComponentTextT {

	constructor(
		UIElement,
		UIComponentDoubleConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIComponentDoubleConfig instanceof UIComponentDoubleConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
	
	
		super(
			UIElement,
			UIComponentDoubleConfig
		);
	
	}
	
	Update( 
	
	){
		
		console.log( "UIComponentDoubleT.Update" );
		
		
		super.Update( );
		
	}
	
	IsValidGet(
	
	){
			
		return super.IsValidGet( );
			
	}
	
	ValueGet( 
	
	){
		
		if( super.IsValidGet( ) === false ){
			
			return 0;
			
		} else {
			
			return parseFloat( super.ValueGet( ) );
		
		}
		
	}
	
	ValueSet( 
		Value,
		NeedSelection = true
	){
		
		console.log( "UIComponentDoubleT.ValueSet", Value );
		
		
		let PreValueText = Value.toString( );
		
		let ValueText = null;
		
		
		let RegExpMatchValue = super.TextDataGet( ).ConfigGet( ).RegExpMatchGet( );
		
		if( RegExpMatchValue === null ) {
			
			ValueText = PreValueText;
			
		} else {
		
			let RegExpMatch = new RegExp( RegExpMatchValue, "" );
			
			//RegExpMatch
			
			let MatchData = PreValueText.match( RegExpMatch );
			
			if( MatchData === null ){
				
				ValueText = "0";
				
			} else {
				
				ValueText = MatchData[ 0 ];
				
			}
		
		}
		
		super.ValueSet( 
			ValueText,
			ValueText.length,
			ValueText.length,
			NeedSelection
		);
		
		this.#OnPlaceholderHide( );
		
		
		let UIComponentDoubleConfig = super.ConfigGet( );
			
		let UIStateMap = UIComponentDoubleConfig.StateMapGet( );
		
		
		if( super.IsValidGet( ) === true ){
		
			if( UIStateMap.Exists( "Valid" ) === true ){
			
				let UIState = UIStateMap.Get( "Valid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		} else {
			
			if( UIStateMap.Exists( "Invalid" ) === true ){
			
				let UIState = UIStateMap.Get( "Invalid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
	}
	
	#OnPlaceholderShow(
	
	){
		
		if( super.EventExists( "DoublePlaceholderShow" ) === true ) {
			
			let EventFn = super.EventGet( "DoublePlaceholderShow" );
			
			EventFn( );
		
		}
		
	}
	
	#OnPlaceholderHide(
	
	){
		
		if( super.EventExists( "DoublePlaceholderHide" ) === true ) {
			
			let EventFn = super.EventGet( "DoublePlaceholderHide" );
			
			EventFn( );
		
		}
		
	}
	
	Focus( 
	
	){
		
		super.Focus( );
		
	}
	
	Blur( 
	
	){
		
		super.Blur( );
		
	}
	
	#TextOnChange(
		Value,
		IsValid
	){
		
		console.log( "UIComponentDoubleT.#TextOnChange", Value, IsValid );
		
		
		let ValueDouble = 0;
		
		if( IsValid === false ){
			
			ValueDouble = 0;
			
		} else {
			
			ValueDouble = parseFloat( super.ValueGet( ) );
		
		}
		
		
		if( super.EventExists( "DoubleChange" ) === true ) {
			
			let EventFn = super.EventGet( "DoubleChange" );
			
			EventFn( ValueDouble, IsValid );
		
		}
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentDoubleT.Start" );
		
		
		//super.EventSet( "Change", this.#TextOnChange.bind( this ) );
		
		
		super.Start( );
		
	}
	
	Stop(
	
	){
		
		let UIElement = super.UIElement( );
		
		
		
		super.EventRemove( "Change" );
		
		
		super.Stop( );
		
	}
	
	Listen(
		OnChangeFn,
		OnPlaceholderShow,
		OnPlaceholderHide
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderShow ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderHide ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		//super.EventSet( "Change", this.#TextOnChange.bind( this ) );
		
		super.EventSet( "DoubleChange", OnChangeFn );
		
		super.EventSet( "DoublePlaceholderShow", OnPlaceholderShow );
		
		super.EventSet( "DoublePlaceholderHide", OnPlaceholderHide );
		
		super.Listen(
			this.#TextOnChange.bind( this ),
			this.#OnPlaceholderShow.bind( this ),
			this.#OnPlaceholderHide.bind( this )
		);
		
	}
	
	Unlisten( 
	
	){
		
		super.EventRemove( "DoubleChange" );
		
	}
	
	EditableSet(
		Editable
	){
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		super.EditableSet( Editable );
		
	}
	
	EditableGet(
		
	){
	
		return super.EditableGet( );
		
	}
	
};


export default UIComponentDoubleT;
