"use strict";


const GUIElementT = class GUIElementT {
	
	#Element = null;
	
	constructor(
		ElementName
	){
		
		if( typeof( ElementName ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element = document.createElement( ElementName );
		
	}
	
	IdSet( 
		Id 
	){
		
		if( Id === null ){
		
			this.#Element.id = Id;
			
			return;
		
		}
		
		if( typeof( Id ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.id = Id;
		
	}
	
	IdGet( 
	
	){
		
		return this.#Element.id;
		
	}
	
	ClassNameSet( 
		ClassName
	){
	
		if( ClassName === null ){
		
			this.#Element.className = "";
		
			return;
		
		}
		
		if( typeof( ClassName ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.className = ClassName;
		
	}
	
	ClassNameGet( 
	
	){
		
		return this.#Element.className;
		
	}
	
	StyleSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.style[ Key ] = Value;
		
	}
	
	StyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Element.style[ Key ];
		
	}
	
	ChildAppend(
		Child
	){
		
		if( ( Child instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		console.log( Child, Child.Render( ) );
		
		this.#Element.appendChild( Child.Render( ) );
		
	}
	
	ChildRemove(
		Child
	){
		
		if( ( Child instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.removeChild( Child.Render( ) );
		
	}
	
	ChildReplace(
		ChildNew,
		ChildOld
	){
	
		if( ( ChildNew instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
		if( ( ChildOld instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.replaceChild( ChildNew.Render( ), ChildOld.Render( ) );
	
	}
	
	EventAppend(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.addEventListener( Key, Value );
		
	}
	
	EventRemove(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.removeEventListener( Key, Value );
		
	}
	
	Update( 
		GUIElementBaseConfig
	){
	
		//TODO Check
		
		this.IdSet( GUIElementBaseConfig.IdGet( ) );
		
		this.ClassNameSet( GUIElementBaseConfig.ClassNameGet( ) );
		
		
		let StyleMap = GUIElementBaseConfig.StyleMapGet( );
		
		for(
			let I of StyleMap.Keys( )
		){
			
			this.StyleSet( I, StyleMap.Get( I ) );
			
		}
		
	}
	
	Focus( ){
	
		this.#Element.focus( );
	
	}
	
	Blur( ){
	
		this.#Element.blur( );
	
	}
	
	Render( ){
	
		return this.#Element;
		
	}
	
	InnerTextSet(
		InnerText
	){
	
		if( typeof( InnerText ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		//TO Config
	
		this.#Element.innerText = InnerText;
	
	}
	
	DataRowSet(
		RowIndex
	){
	
		this.#Element[ "data-row" ] = RowIndex;
	
	}
	
	DataRowGet(
	
	){
	
		return this.#Element[ "data-row" ];
	
	}
	
};


export default GUIElementT;