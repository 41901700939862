"use strict";


import UIComponentConfigAbstractT from "../../../../Type/UI/Component/_/UIComponentConfigAbstractT.mjs";

import UIStyleMapT from "../../../../Type/UI/Style/UIStyleMapT.mjs";


const UIComponentAbstractT = class UIComponentAbstractT {
	
	constructor(
		
	){
		
	}
	
	
	ChildSet(
		Index,
		Child
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( Child instanceof UIComponentAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ChildRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ChildGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ChildrenCount( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	EventSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventGet(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventExists(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	EventKeys( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	UIStyleMapClear( 
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	StyleMapSet( 
		UIStyleMap 
	){
		
		if( ( UIStyleMap instanceof UIStyleMapT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );

	}
	
	StyleMapGet( 
		
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	ClassNameSet(
		ClassName
	){
		
		if( typeof( ClassName ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ClassNameGet( 
	
	){
	
		throw new Error( "Abstract" );
	
	}
	
	Focus( ){
	
		throw new Error( "Abstract" );
	
	}
	
	Blur( ){
	
		throw new Error( "Abstract" );
	
	}
	
	
	InnerTextSet(
		InnerText
	){
		
		if( typeof( InnerText ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	InnerTextGet(
		
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	ConfigSet(
		UIComponentConfig
	){
		
		if( ( UIComponentConfig instanceof UIComponentConfigAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		throw new Error( "Abstract" );
		
	}
	
	ConfigGet(
	
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	Update(
		
	){
		
		throw new Error( "Abstract" );
		
	}
	
	
	UIElement( ){
		
		throw new Error( "Abstract" );
		
	}
	
	Render( ){
		
		throw new Error( "Abstract" );
		
	}
	
};


export default UIComponentAbstractT;