import { Component } from "react";

import { translate } from 'react-polyglot';

import PropTypes from 'prop-types';
 
import Grid from "../grid";

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";


import UIElementT from "../../Infrastructure/UI/Element/UIElementT.mjs";

import HTTPClientT from "../../Infrastructure/Network/HTTP/Client/HTTPClientT.mjs";


import APIClientAbstractT from "../../Procedure/API/Client/_/APIClientAbstractT.mjs";


import APIClientT from "../../Interface/API/Client/APIClientT.mjs";

import UIComponentBooleanT from "../../Interface/UI/Component/Boolean/UIComponentBooleanT.mjs";

import UIComponentTextT from "../../Interface/UI/Component/Text/UIComponentTextT.mjs";


import HTTPClientConfigT from "../../Type/HTTP/Client/Config/HTTPClientConfigT.mjs";

import HTTPClientCookiesT from "../../Type/HTTP/Client/Cookie/HTTPClientCookiesT.mjs";

import APIFieldT from "../../Type/API/Field/APIFieldT.mjs";

import APIFieldListT from "../../Type/API/Field/APIFieldListT.mjs";

import APIValueT from "../../Type/API/Value/APIValueT.mjs";

import APIFieldValueT from "../../Type/API/FieldValue/APIFieldValueT.mjs";

import APIFieldValueMapT from "../../Type/API/FieldValue/APIFieldValueMapT.mjs";

import APIModelKeyT from "../../Type/API/Model/Key/APIModelKeyT.mjs";

import APIModelNameT from "../../Type/API/Model/Name/APIModelNameT.mjs";

import APIModelCallRequestElementT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementT.mjs";

import APIModelCallRequestElementMapT from "../../Type/API/Model/Call/Request/Element/APIModelCallRequestElementMapT.mjs";

import APIModelCallRequestT from "../../Type/API/Model/Call/Request/APIModelCallRequestT.mjs";

import APIModelCallResponseT from "../../Type/API/Model/Call/Response/APIModelCallResponseT.mjs";

import APIModelSchemaRequestJoinTypeT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinTypeT.mjs";

import APIModelSchemaRequestJoinDataT from "../../Type/API/Model/Schema/Request/Join/APIModelSchemaRequestJoinDataT.mjs";

import APIModelSchemaRequestModelOptionT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionT.mjs";

import APIModelSchemaRequestModelOptionMapT from "../../Type/API/Model/Schema/Request/ModelOption/APIModelSchemaRequestModelOptionMapT.mjs";

import APIModelSchemaRequestCustomOptionT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionT.mjs";

import APIModelSchemaRequestCustomOptionMapT from "../../Type/API/Model/Schema/Request/CustomOption/APIModelSchemaRequestCustomOptionMapT.mjs";

import APIModelSchemaRequestChildrenMapT from "../../Type/API/Model/Schema/Request/Children/APIModelSchemaRequestChildrenMapT.mjs";

import APIModelSchemaRequestT from "../../Type/API/Model/Schema/Request/APIModelSchemaRequestT.mjs";

import APIModelSchemaResponseT from "../../Type/API/Model/Schema/Response/APIModelSchemaResponseT.mjs";


import APIModelInsertRequestJoinTypeT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinTypeT.mjs";

import APIModelInsertRequestJoinDataT from "../../Type/API/Model/Insert/Request/Join/APIModelInsertRequestJoinDataT.mjs";

import APIModelInsertRequestChildrenListT from "../../Type/API/Model/Insert/Request/Children/APIModelInsertRequestChildrenListT.mjs";

import APIModelInsertRequestT from "../../Type/API/Model/Insert/Request/APIModelInsertRequestT.mjs";

import APIModelInsertResponseChildrenListT from "../../Type/API/Model/Insert/Response/Children/APIModelInsertResponseChildrenListT.mjs";

import APIModelInsertResponseT from "../../Type/API/Model/Insert/Response/APIModelInsertResponseT.mjs";


import UIComponentBooleanConfigT from "../../Type/UI/Component/Boolean/UIComponentBooleanConfigT.mjs";

import UIComponentTextConfigT from "../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIStyleT from "../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../Type/UI/Style/UIStyleMapT.mjs";

import KeyValueT from "../../Type/KeyValue/KeyValueT.mjs";

import KeyValueMapT from "../../Type/KeyValue/KeyValueMapT.mjs";


import GTSUIModelOutinvoiceFormAddT from "../../Interface/GTS/UI/Model/Outinvoice/Form/Add/GTSUIModelOutinvoiceFormAddT.mjs";

const API_URL = UrlService.API_URL( );


function createCSSSelector (selector, style) {
  if (!document.styleSheets) return;
  if (document.getElementsByTagName('head').length == 0) return;

  var styleSheet,mediaType;

  if (document.styleSheets.length > 0) {
    for (var i = 0, l = document.styleSheets.length; i < l; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
	  }
	  if (document.styleSheets[i].href && !document.styleSheets[i].href.startsWith(window.location.origin)){
		continue;
	  }
	  
      var media = document.styleSheets[i].media;
      mediaType = typeof media;

      if (mediaType === 'string') {
        if (media === '' || (media.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }
      else if (mediaType=='object') {
        if (media.mediaText === '' || (media.mediaText.indexOf('screen') !== -1)) {
          styleSheet = document.styleSheets[i];
        }
      }

      if (typeof styleSheet !== 'undefined') 
        break;
    }
  }

  if (typeof styleSheet === 'undefined') {
    var styleSheetElement = document.createElement('style');
    styleSheetElement.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(styleSheetElement);

    for (i = 0; i < document.styleSheets.length; i++) {
      if (document.styleSheets[i].disabled) {
        continue;
      }
      styleSheet = document.styleSheets[i];
    }

    mediaType = typeof styleSheet.media;
  }

  if (mediaType === 'string') {
    for (var i = 0, l = styleSheet.rules.length; i < l; i++) {
      if(styleSheet.rules[i].selectorText && styleSheet.rules[i].selectorText.toLowerCase()==selector.toLowerCase()) {
        styleSheet.rules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.addRule(selector,style);
  }
  else if (mediaType === 'object') {
    var styleSheetLength = (styleSheet.cssRules) ? styleSheet.cssRules.length : 0;
    for (var i = 0; i < styleSheetLength; i++) {
      if (styleSheet.cssRules[i].selectorText && styleSheet.cssRules[i].selectorText.toLowerCase() == selector.toLowerCase()) {
        styleSheet.cssRules[i].style.cssText = style;
        return;
      }
    }
    styleSheet.insertRule(selector + '{' + style + '}', styleSheetLength);
  }
}


createCSSSelector( ".UIComponentBooleanT", "display: block; height: 38px; width: 38px; background-color: white; background-repeat: no-repeat; background-size: 38px 38px" );

createCSSSelector( ".UIComponentBooleanT.True", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/6/68/Blue_checkbox-checked.svg\" )" );

createCSSSelector( ".UIComponentBooleanT.False", "background-image: url( \"https://upload.wikimedia.org/wikipedia/commons/8/8b/Blue_checkbox-unchecked.svg\" )" );

//createCSSSelector( ".UIComponentBooleanT:hover",  )





let ValueSale = null;

let IsValidSale = false;



const OutinvoiceAddT = class OutinvoiceAddT {

	#Ref = null;

	#GTSUIOutinvoiceFormAdd = null;
	
	#HTTPClientCookies = null;
	
	#APIClient = null;
	
	#APIModelCallRequestOutinvoicePrepare = null;
	
	#APIModelCallResponseOutinvoicePrepare = null;
	
	#APIModelSchemaRequestOutinvoice = null;
	
	#APIModelSchemaResponseOutinvoice = null;
	
	
	#TempOutinvoiceId = null;
	
	#TempSaleIds = [ ];
	
	#TempSaleId = [ ];
	
	#OnOutinvoiceClose = null;
	
	
	constructor(
		HTTPClientCookies,
		APIClient,
		UIComponent
	){
		
		if( ( HTTPClientCookies instanceof HTTPClientCookiesT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		if( ( APIClient instanceof APIClientAbstractT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		
		this.#HTTPClientCookies = HTTPClientCookies;
		
		this.#APIClient = APIClient;
	

		this.#APIModelCallRequestOutinvoicePrepare = new APIModelCallRequestT( 
			new APIModelCallRequestElementMapT( 
				[
					new APIModelKeyT( "outinvoice_number" )
				],
				[
					new APIModelCallRequestElementT(
						new APIFieldT( "outinvoices" ),
						new APIFieldT( "outinvoices_prepare" ),
						new APIFieldListT( [ 
							new APIFieldT( "invoice_number" ) 
						] ),
						new APIFieldListT( [ 
						
						] )
					)
				] 
			) 
		);

		this.#APIModelSchemaRequestOutinvoice = new APIModelSchemaRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelSchemaRequestJoinTypeT( "NONE" ),
			new APIModelSchemaRequestJoinDataT( [ ] ),
			new APIModelSchemaRequestModelOptionMapT( 
				[
					//new APIModelKeyT( "users" ),
					new APIModelKeyT( "companies" ),
					new APIModelKeyT( "bank_accounts" ),
					new APIModelKeyT( "contracts" ),
					new APIModelKeyT( "outinvoices" ),
					new APIModelKeyT( "additional_texts" ),
					new APIModelKeyT( "product_types" )
				],
				[
					/*new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "users" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" )
						] )
					),*/
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "companies" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name" ),
							new APIFieldT( "active" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "bank_accounts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "account_name" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "contracts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "contract_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "filled" ),
							new APIFieldT( "date" ),
							new APIFieldT( "address" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "invoice_number" ),
							new APIFieldT( "company" ),
							new APIFieldT( "pre_invoice" ),
							new APIFieldT( "post_invoice" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "additional_texts" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "text" ),
							new APIFieldT( "company_ids" )
						] )
					),
					new APIModelSchemaRequestModelOptionT(
						new APIModelNameT( "product_types" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestCustomOptionMapT( 
				[
					new APIModelKeyT( "outinvoice_type" ),
					new APIModelKeyT( "currency" ),
					new APIModelKeyT( "payment_method" )
				],
				[
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "outinvoice_type" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "currency" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					),
					new APIModelSchemaRequestCustomOptionT(
						new APIModelNameT( "outinvoices" ),
						new APIFieldT( "payment_method" ),
						new APIFieldListT( [
							new APIFieldT( "id" ),
							new APIFieldT( "name_en" ),
							new APIFieldT( "name_cs" )
						] )
					)
				]
			),
			
			new APIModelSchemaRequestChildrenMapT( 
				[
					new APIModelKeyT( "outinvoice_items" )
				], 
				[
					new APIModelSchemaRequestT(
						new APIModelNameT( "outinvoice_items" ),
						
						new APIModelSchemaRequestJoinTypeT( "ONE_TO_MANY" ),
						new APIModelSchemaRequestJoinDataT( [
							new APIFieldValueT(
								new APIFieldT( "ParentField" ),
								new APIValueT( "id" )
							),
							new APIFieldValueT(
								new APIFieldT( "ChildField" ),
								new APIValueT( "outinvoice_id" )
							)
						] ),
						
						new APIModelSchemaRequestModelOptionMapT( 
							[
								//new APIModelKeyT( "users" ),
								new APIModelKeyT( "outinvoices" ),
								new APIModelKeyT( "outinvoice_item_names" )
							],
							[
								/*new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "users" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" )
									] )
								),*/
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoices" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "invoice_number" ),
										new APIFieldT( "company" ),
										new APIFieldT( "pre_invoice" ),
										new APIFieldT( "post_invoice" )
									] )
								),
								new APIModelSchemaRequestModelOptionT(
									new APIModelNameT( "outinvoice_item_names" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name" ),
										new APIFieldT( "company_ids" ),
										new APIFieldT( "dph" ),
										new APIFieldT( "code" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestCustomOptionMapT( 
							[
								new APIModelKeyT( "unit" ),
								new APIModelKeyT( "vat_rate" )
							],
							[
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "unit" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								),
								new APIModelSchemaRequestCustomOptionT(
									new APIModelNameT( "outinvoice_items" ),
									new APIFieldT( "vat_rate" ),
									new APIFieldListT( [
										new APIFieldT( "id" ),
										new APIFieldT( "name_en" ),
										new APIFieldT( "name_cs" )
									] )
								)
							]
						),
						
						new APIModelSchemaRequestChildrenMapT( 
						
							[
							
							], 
							
							[
							
							]
						
						)
					
					)
				]
			)
			
		);
	
	}
	
	#Draw(
	
	){
	
	
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		let CurrentMonth = ( new Date( ) ).toISOString( ).substring( 0, 7 );
		
		let GTSUIOutinvoiceNumberValue = JSONValue[ CurrentMonth ];
		
		let GTSUIOutinvoiceNumberValuePre = JSONValue[ "ZAL-" + CurrentMonth ];
	
	
		let APIModelSchemaResponse = this.#APIModelSchemaResponseOutinvoice;
		
								
		let CustomOptionMap = APIModelSchemaResponse.CustomOptionMap( );
		
		let ModelOptionMap = APIModelSchemaResponse.ModelOptionMap( );
		
		
		let CompanyModelOptionMap = ModelOptionMap.Get( "companies" );
		
		let CompanyModelFilterCb = function(
			APIFieldValueMap
		){
			
			let APIFieldValue = APIFieldValueMap.Get( "active" );
				
			let APIValue = APIFieldValue.Value( );
			
			let Value = APIValue.Value( );
			
			
			let APIFieldValue2 = APIFieldValueMap.Get( "name" );
				
			let APIValue2 = APIFieldValue2.Value( );
			
			let Value2 = APIValue2.Value( );
			
			console.log( "CompanyModelFilterCb", Value2, Value );
			
			if( Boolean( Value ) === false ){
				
				return false;
				
			}
			
			return true;
			
		}
		
		CompanyModelOptionMap = CompanyModelOptionMap.Filter( CompanyModelFilterCb );
		
		let CompanyUIOptionMap = CompanyModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let PaymentMethodCustomOptionMap = CustomOptionMap.Get( "payment_method" );
		
		let PaymentMethodUIOptionMap = PaymentMethodCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let PayToModelOptionMap = ModelOptionMap.Get( "bank_accounts" );
		
		let PayToUIOptionMap = PayToModelOptionMap.ToUIOptionMap( "id", "account_name" );
		
		
		let CurrencyCustomOptionMap = CustomOptionMap.Get( "currency" );
		
		let CurrencyUIOptionMap = CurrencyCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let ContractModelOptionMap = ModelOptionMap.Get( "contracts" );
		
		let ContractUIOptionMap  = ContractModelOptionMap.ToUIOptionMap( "id", "contract_number" );
		
		
		let OutinvoiceTypeCustomOptionMap = CustomOptionMap.Get( "outinvoice_type" );
		
		let OutinvoiceTypeUIOptionMap = OutinvoiceTypeCustomOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceModelOptionMap = ModelOptionMap.Get( "outinvoices" );
		
		let OutinvoiceUIOptionMap  = OutinvoiceModelOptionMap.ToUIOptionMap( "id", "invoice_number" );
		
		
		let AdditionalTextModelOptionMap = ModelOptionMap.Get( "additional_texts" );
		
		let AdditionalTextUIOptionMap  = AdditionalTextModelOptionMap.ToUIOptionMap( "id", "text" ); 
		
		
		let ProductTypeModelOptionMap = ModelOptionMap.Get( "product_types" );
		
		let ProductTypeUIOptionSort = function( UIOption1, UIOption2 ) {
		
			console.log( UIOption1, UIOption2 );
		
			if ( UIOption1.ValueGet( ) < UIOption2.ValueGet( ) ) {

				return -1;

			} else if ( UIOption2.ValueGet( ) < UIOption1.ValueGet( ) ) {
    
				return 1;

			}

			return 0;
  
		};
		
		let ProductTypeUIOptionMap = ProductTypeModelOptionMap.ToUIOptionMap( "id", "name_cs" ).Sort( ProductTypeUIOptionSort ); 
		
		
		
		
		
		let CallElementResponseChildrenMap = APIModelSchemaResponse.ChildrenMap( );
		
		let OutinvoiceItem = CallElementResponseChildrenMap.Get( "outinvoice_items" );
		
		
		let OutinvoiceItemModelOptionMap = OutinvoiceItem.ModelOptionMap( );
		
		let OutinvoiceItemCustomOptionMap = OutinvoiceItem.CustomOptionMap( );
		
		
		let OutinvoiceItemNameModelOptionMap = OutinvoiceItemModelOptionMap.Get( "outinvoice_item_names" );
		
		let OutinvoiceItemNameUIOptionMap = OutinvoiceItemNameModelOptionMap.ToUIOptionMap( "id", "name" );
		
		
		let OutinvoiceItemUnitModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "unit" );
		
		let OutinvoiceItemUnitUIOptionMap = OutinvoiceItemUnitModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		let OutinvoiceItemVATRateModelOptionMap = OutinvoiceItemCustomOptionMap.Get( "vat_rate" );
		
		let OutinvoiceItemVATRateUIOptionMap = OutinvoiceItemVATRateModelOptionMap.ToUIOptionMap( "id", "name_cs" );
		
		
		this.#GTSUIOutinvoiceFormAdd = new GTSUIModelOutinvoiceFormAddT( 
			"cs",
			
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			CompanyUIOptionMap,
			null,
			
			PaymentMethodUIOptionMap,
			null,
			
			PayToUIOptionMap,
			null,
			
			CurrencyUIOptionMap,
			null,

			ContractUIOptionMap, //UIOptionMapContract,
			null,//ContractDefault,
			
			"", //ReceiptNumberDefault
			
			null, //ReceiptDateDefault
			null, //IssueDateDefault
			null, //DueDateDefault
			null, //TaxableDateDefault
			null, //PaidDateDefault
			
			
			"", //ConstantSymbolDefault
			"", //SpecificSymbolDefault
			"", //NoteDefault
			
			OutinvoiceTypeUIOptionMap, //UIOptionMapOutinvoiceType,
			null, //OutinvoiceTypeDefault,
			
			OutinvoiceUIOptionMap,//UIOptionMapPreInvoice,
			null, //PreInvoiceDefault,
			
			OutinvoiceUIOptionMap, //UIOptionMapPostInvoice,
			null, //PostInvoiceDefault,
			
			AdditionalTextUIOptionMap, //UIOptionMapAdditionalText,
			"", //AdditionalTextDefault,
		
			0, //ExcVATDefault,
			0, //VATDefault,
			0, //IncVATDefault,
			
			false,
		
			ProductTypeUIOptionMap,
			null,
			
			OutinvoiceItemNameUIOptionMap,
			"",
			
			OutinvoiceItemUnitUIOptionMap,
			null,
			
			OutinvoiceItemVATRateUIOptionMap,
			"",
			
			ContractModelOptionMap,
			OutinvoiceModelOptionMap,
			JSONValue,
			AdditionalTextModelOptionMap
		);
		
		this.#GTSUIOutinvoiceFormAdd.Start( );
		
		this.#GTSUIOutinvoiceFormAdd.Listen( 
			this.#OnAccept.bind( this ),
			this.#OnClear.bind( this )
		);
		
		this.Reset(
			
		);
		
		
		if( this.#Ref !== null ){
		
			this.#Ref.appendChild( this.Render( ) );
		
		}
	
	}
	
	Render( ){
	
		if( this.#GTSUIOutinvoiceFormAdd !== null ){
		
			return this.#GTSUIOutinvoiceFormAdd.Render( );
		
		}
	
		return null;
	
	}
	
	RefSet( Ref ){
	
		this.#Ref = Ref;
	
	}
	
	#OnModelSchemaOutinvoiceResponse(
		APIModelSchemaResponseOutinvoice
	){
	
		if( ( APIModelSchemaResponseOutinvoice instanceof APIModelSchemaResponseT ) === false ){
									
			throw new Error( "Invalid response format" );
									
		}
		
		this.#APIModelSchemaResponseOutinvoice = APIModelSchemaResponseOutinvoice;
		
		this.#Draw( );
	
	}
	
	#OnModelCallResponseOutinvoicePrepare( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;
		
		this.#APIClient.ModelSchema(

			this.#APIModelSchemaRequestOutinvoice,
			
			this.#OnModelSchemaOutinvoiceResponse.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	#OnModelError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	Start(
	
	){

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepare.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
	Reset(
		
	){
	
		this.#GTSUIOutinvoiceFormAdd.ItemFormReset( 
			
		); 
	
	}

	#OnOutinvoiceInsertSuccess(
		APIModelInsertResponseOutinvoice
	){
	
		if( ( APIModelInsertResponseOutinvoice instanceof APIModelInsertResponseT ) === false ){
			
			throw new Error( "Invalid response format" );
			
		}
		
		let Data = APIModelInsertResponseOutinvoice.Data( );
		
		let FieldValue = Data.Get( "id" );
		
		let Value = FieldValue.Value( );
		
		this.#TempOutinvoiceId = Value.Value( ).toString( );
		
		console.log( APIModelInsertResponseOutinvoice, this.#TempOutinvoiceId );
		
		
		this.ResetPrepare( );
		
		if( this.#OnOutinvoiceClose !== null ){
		
			this.#OnOutinvoiceClose( 

			);
		
		}
	
	}
	
	#OnError(
		Err
	){
	
		if( ( Err instanceof Error ) === false ){
											
			throw new Error( "Invalid error format" );
			
		}
		
		alert( Err.message );
	
		throw Err;
	
	}
	
	#OutinvoiceRequest(
		
	){
	
		let Values = this.#GTSUIOutinvoiceFormAdd.ValueGet( );
						
		let APIFieldBucketOutinvoiceItems = [ ];
		
		let OutinvoiceItemsOld = [ ];
		
		for(
			let I = 0;
			I < Values.items.length;
			I++
		){
		
			if( Values.items[ I ].in_outinvoice === false ){
			
				continue;
			
			}
		
			let OutinvoiceItem = {
				"description": Values.items[ I ].description,
				"product_type": Values.items[ I ].product_type,
				"unit": Values.items[ I ].unit,
				"qty": Values.items[ I ].qty,
				"excvat": Values.items[ I ].excvat,
				"vat": Values.items[ I ].vat,
				"code": Values.items[ I ].code,
				"vat_rate": Values.items[ I ].vat_rate
			};
			
			
			OutinvoiceItemsOld.push( { 
				"description": Values.items[ I ].description,
				"product_type": Values.items[ I ].product_type,
				"qty": Values.items[ I ].qty.toString( ),
				"unit": parseInt( Values.items[ I ].unit ),
				"unit_excvat": Values.items[ I ].excvat.toString( ),
				"unit_vat": Values.items[ I ].vat.toString( ),
				"unit_incvat": Values.items[ I ].excvat + Values.items[ I ].vat,
				"unit_value":{ "value": Values.items[ I ].unit.toString( ), "label": Values.items[ I ].unit_label }
			} );
		
			APIFieldBucketOutinvoiceItems.push( 
			
				new APIModelInsertRequestT(
					new APIModelNameT( "outinvoice_items" ),
					new APIModelInsertRequestJoinTypeT( "ONE_TO_MANY" ),
					new APIModelInsertRequestJoinDataT( [
						new APIFieldValueT(
							new APIFieldT( "ParentField" ),
							new APIValueT( "id" )
						),
						new APIFieldValueT(
							new APIFieldT( "ChildField" ),
							new APIValueT( "outinvoice_id" )
						)
					] ),
					APIFieldValueMapT.FromJSON( OutinvoiceItem ),
					new APIModelInsertRequestChildrenListT( [ 

					] )
				)
			);
		
		}
		
		let Outinvoice = {
			"company": Values.outinvoice.company,
			"currency": Values.outinvoice.currency,
			"contract": Values.outinvoice.contract,
			"paid_date": Values.outinvoice.paid_date,
			"invoice_number": Values.outinvoice.invoice_number,
			"invoice_excvat": Values.outinvoice.invoice_excvat,
			"invoice_vat": Values.outinvoice.invoice_vat,
			"invoice_incvat": Values.outinvoice.invoice_incvat,
			"invoice_rate": 0, // TODO Fixes in all other places where it will be needed
			"invoice_issue": Values.outinvoice.invoice_issue,
			"invoice_due": Values.outinvoice.invoice_due,
			"invoice_taxable": Values.outinvoice.invoice_taxable,
			"invoice_type": null,
			"payment_method": Values.outinvoice.payment_method,
			"pay_to": Values.outinvoice.pay_to,
			"note": Values.outinvoice.note,
			"invoice_file": null,
			"payment_type": null,
			"beneficiary_note": null,
			"variable_symbol": Values.outinvoice.invoice_number,
			"constant_symbol": Values.outinvoice.constant_symbol,
			"specific_symbol": Values.outinvoice.specific_symbol,
			"invoice_items": JSON.stringify( OutinvoiceItemsOld ),
			"invoice_excvat_kc": null, //TODO Fix on server side where it will be needed
			"receipt_number": Values.outinvoice.receipt_number,
			"receipt_date": Values.outinvoice.receipt_date,
			"odpad": null,
			"additional_text": Values.outinvoice.additional_text,
			"transferred_vat": Values.outinvoice.transferred_vat,
			"cnb": Values.outinvoice.cnb,
			"pre_invoice": Values.outinvoice.pre_invoice,
			"post_invoice": Values.outinvoice.post_invoice,
			"outinvoice_type": Values.outinvoice.outinvoice_type
		};
		
		console.log( Outinvoice );
		
		let APIFieldBucketOutinvoice = APIFieldValueMapT.FromJSON( Outinvoice );
		
		let APIModelInsertRequestOutinvoice = new APIModelInsertRequestT(
			new APIModelNameT( "outinvoices" ),
			new APIModelInsertRequestJoinTypeT( "NONE" ),
			new APIModelInsertRequestJoinDataT( [ ] ),
			APIFieldBucketOutinvoice,
			new APIModelInsertRequestChildrenListT( [ 
			
				...APIFieldBucketOutinvoiceItems
			
			] )
		);
		
		
		this.#APIClient.ModelInsert(

			APIModelInsertRequestOutinvoice,
			
			this.#OnOutinvoiceInsertSuccess.bind( this ),
			
			this.#OnError.bind( this )
			
		);
	
	}
	
	#OnAccept(
		Values,
		IsValid
	){
	
		console.log( "OutinvoiceAddT.#OnAccept", Values, IsValid );
		
		this.#OutinvoiceRequest( );
	
	}
	
	#OnClear( ){
	
	}
	
	OnOutinvoiceCloseSet(
		OnOutinvoiceClose
	){
	
		this.#OnOutinvoiceClose = OnOutinvoiceClose;
	
	}
	
	
	//Restart
	
	#OnModelCallResponseOutinvoicePrepareReset( 
		APIModelCallResponse 
	){
	
		if( ( APIModelCallResponse instanceof APIModelCallResponseT ) === false ){
					
			throw new Error( "Invalid response format" );
					
		}
		
		this.#APIModelCallResponseOutinvoicePrepare = APIModelCallResponse;

	
		
		let CallElementResponseElementMap = this.#APIModelCallResponseOutinvoicePrepare.ElementMap( );
							
		let CallElementResponseElement = CallElementResponseElementMap.Get( "outinvoice_number" );
		
		let APIFieldValue = CallElementResponseElement.Get( "invoice_number" );
		
		let APIValue = APIFieldValue.Value( );
		
		let Value = APIValue.Value( );
		
		let JSONValue = JSON.parse( APIValue.Value( ) );
		
		this.#GTSUIOutinvoiceFormAdd.OutinvoicePrepareJSONValueSet( JSONValue );
	
	}
	
	ResetPrepare(
	
	){
	
		//TODO Reset

		this.#APIClient.ModelCall(

			this.#APIModelCallRequestOutinvoicePrepare,
			
			this.#OnModelCallResponseOutinvoicePrepareReset.bind( this ),
				
			this.#OnModelError.bind( this )
			
		);
	
	}
	
};


class OutInvoices extends Component< Props > {
	
	#Title = null;
	#ModelName = null;
	#ModelSchema = null;
	
	#CheckboxField = null;
	#PinnedLeftFields = null;
			
	#PinnedRightFields = null;
			
	#Modals = null;
	#Controls = null;
	
	#Fields = null;
	
	#ExcVATMemory = null;
	#VATMemory = null;
	
	#ItemsExcVATMemory = null;
	#ItemsVATMemory = null;
	
	#Meta = null;
	
	#CustomOptionsLocale = null;

	#ItemsVATFromItemNames = null;
	
	#Prepare = null;
	
	#IsDisabledMassPrint = false;
	
	#IsDisabledExcelExport = false;
	
	
	#OutinvoiceAdd = null;
	
	#ModalAdd = null;
	
	#Refresh = 0;
	

    constructor( props ) {
		
		console.log( props );
		
        super( props );
		
		
		this.#ExcVATMemory = 0.0;
		
		this.#VATMemory = 0.0;
		
		this.#ItemsExcVATMemory = 0.0;
		
		this.#ItemsVATMemory = 0.0;
		
		
		this.#ItemsVATFromItemNames = new Map( );
		
		
		this.#CustomOptionsLocale = "cs"; //OR "en"
		
		
		this.#Title = this.props.t( "Outgoing invoices" );
			
		this.#ModelName = "outinvoices";
		
		this.#ModelSchema = {
			model_name: "outinvoices",
			type: null,
			type_data: null,
			preload_options: [
				{
					model_name: "users",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name" ],
					fields_label_joiner: ""
				},
				{
					model_name: "companies",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name" ],
					fields_label_joiner: "",
					fields_additional: [ "active" ]
				},
				{
					model_name: "bank_accounts",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "account_name" ],
					fields_label_joiner: ""
				},
				{
					model_name: "contracts",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "contract_number" ],
					fields_label_joiner: "",
					fields_additional: [ "company", "filled" ]
				},
				{
					model_name: "outinvoices",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "invoice_number" ],
					fields_label_joiner: "",
					fields_additional: [ "company", "pre_invoice", "post_invoice" ]
				},
				{
					model_name: "additional_texts",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "text" ],
					fields_label_joiner: "",
					fields_additional: [ "company_ids" ]
				}
			],
			preload_custom_options: [
				{
					model_name: "outinvoices",
					field: "outinvoice_type",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name_" + this.#CustomOptionsLocale ],
					fields_label_joiner: ""
				},
				{
					model_name: "outinvoices",
					field: "currency",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name_" + this.#CustomOptionsLocale ],
					fields_label_joiner: ""
				},
				{
					model_name: "outinvoices",
					field: "payment_method",
					fields_value: [ "id" ],
					fields_value_joiner: "",
					fields_label: [ "name_" + this.#CustomOptionsLocale ],
					fields_label_joiner: ""
				}
			],
			children: [
				{
			
					model_name: "outinvoice_items",
					type: "parent_inclusive",
					type_data: {
						field: "outinvoice_id"
					},
					preload_options: [
						{
							model_name: "users",
							fields_value: [ "id" ],
							fields_value_joiner: "",
							fields_label: [ "name" ],
							fields_label_joiner: ""
						},
						{
							model_name: "outinvoices",
							fields_value: [ "id" ],
							fields_value_joiner: "",
							fields_label: [ "invoice_number" ],
							fields_label_joiner: ""
						},
						{
							model_name: "outinvoice_item_names",
							fields_value: [ "id" ],
							fields_value_joiner: "",
							fields_label: [ "name" ],
							fields_label_joiner: "",
							fields_additional: [ "company_ids", "dph", "code" ]
						}
					],
					
					preload_custom_options: [
						{
							model_name: "outinvoice_items",
							field: "unit",
							fields_value: [ "id" ],
							fields_value_joiner: "",
							fields_label: [ "name_" + this.#CustomOptionsLocale ],
							fields_label_joiner: ""
						},
						{
							model_name: "outinvoice_items",
							field: "vat_rate",
							fields_value: [ "id" ],
							fields_value_joiner: "",
							fields_label: [ "name_" + this.#CustomOptionsLocale ],
							fields_label_joiner: ""
						}
					],
					
					children: [
					
					]
					
				}
			]
		};
		
		this.#Prepare = {
			data: {
				"outinvoice_number": {
					model_name: "outinvoices",
					command: "outinvoices_prepare",
					out_fields: [ "invoice_number" ],
					in_fields: [ ]
				}
			}
		};
		
		this.#CheckboxField = "invoice_number";
			
		this.#PinnedLeftFields = [ "company" ];
			
		this.#PinnedRightFields = [ ];
		
		this.#Fields = [ 
			"company",
			"invoice_number",
			"currency",
			"contract",
			"receipt_number",
			"receipt_date",
			"invoice_excvat",
			"invoice_vat",
			//"invoice_rate",
			"invoice_incvat",
			"invoice_issue",
			"invoice_due",
			"invoice_taxable",
			"paid_date",
			//"invoice_type",
			"payment_method",
			//"payment_type",
			"pay_to",
			"constant_symbol",
			"specific_symbol",
			//"beneficiary_note",
			"note",
			"cnb",
			"transferred_vat",
			"pre_invoice",
			"post_invoice",
			"additional_text",
			"created_at",
			"created_by",
			"updated_at",
			"updated_by",
			"id"
		];
		
		this.#Meta = {
			"company": {
				IsRequired: true,
				Placeholder: this.props.t( "Choose" ),
				OptionsFilter: function( Values, Options ){
					
					let FilteredOptions = new Map( );
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						if( Boolean( Option.fields.active ) === false ){
							
							continue;
							
						}
						
						FilteredOptions.set( I, Option );
						
					}
					
					return FilteredOptions;
					
				}
			},
			"invoice_number": {
				IsRequired: true,
				RegExpMatch: "^[0-9]{0,6}",
				RegExpValidation: "^[0-9]{6}$",
				Placeholder: this.props.t( "Enter the value" ),
				Renderer: "ActionsRenderer",
                RendererParams: {
                    Model: "outinvoices"
                }
			},
			"currency": {
				IsRequired: true,
				Placeholder: this.props.t( "Choose" )
			},
			"contract": {
				IsRequired: false,
				Placeholder: this.props.t( "Choose" ),
				OptionsFilter: function( Values, Options ){
					
					let FilteredOptions = new Map( );
					
					let Company = Values[ "company" ];
					
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						if( Boolean( Option.fields.filled ) === true ){
							
							continue;
							
						}
						
						if( Company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company.toString( ) !== Company.toString( ) ){
							
							continue;
							
						}
						
						FilteredOptions.set( I, Option );
						
					}
					
					return FilteredOptions;
					
				}
			},
			//"receipt_number",
			//"receipt_date",
			"invoice_excvat": {
				IsRequired: true,
				RegExpMatch: "^[0-9]{0,8}(\\.[0-9]{0,8})?",
				RegExpValidation: "^[0-9]{0,8}(\\.[0-9]{0,8})?$",
				Placeholder: "0.0"
			},
			"invoice_vat": {
				IsRequired: false,
				RegExpMatch: "^[0-9]{0,8}(\\.[0-9]{0,8})?",
				RegExpValidation: "^[0-9]{0,8}(\\.[0-9]{0,8})?$",
				Placeholder: "0.0"
			},
			"invoice_incvat": {
				IsRequired: true,
				RegExpMatch: "^[0-9]{0,8}(\\.[0-9]{0,8})?",
				RegExpValidation: "^[0-9]{0,8}(\\.[0-9]{0,8})?$",
				Placeholder: "0.0"
			}, /*,
			"invoice_issue",
			"invoice_due",
			"invoice_taxable",
			"paid_date",*/
			/*"invoice_type": {
				IsRequired: true,
				Placeholder: this.props.t( "Choose" )
			},*/
			"payment_method": {
				IsRequired: true,
				Placeholder: this.props.t( "Choose" )
			},
			/*"payment_type": {
				IsRequired: true,
				Placeholder: this.props.t( "Choose" )
			},*/
			"pay_to": {
				/*IsRequiredFn: function( Values ){
														
					if( Values.get( "payment_method" ].value.toString( ) === "2" ){
						
						return true;
						
					} else {
						
						return false;
						
					}
					
				},*/
				IsRequired: false,
				Placeholder: this.props.t( "Choose" )
			},
			"constant_symbol": {
				IsRequired: false,
				RegExpMatch: "^[0-9]{0,4}",
				RegExpValidation: "^[0-9]{1,4}$",
				Placeholder: this.props.t( "Enter the value" )
			},
			"specific_symbol": {
				IsRequired: false,
				Editable: true,
				RegExpMatch: "^[0-9]{0,10}",
				RegExpValidation: "^[0-9]{1,10}%",
				Placeholder: this.props.t( "Enter the value" )
			},
			/*
			"beneficiary_note",
			"note",
			"cnb",
			"transferred_vat",*/
			"outinvoice_type": {
				IsRequired: false,
				Editable: false,
				Placeholder: this.props.t( "Choose" )
			},
			"pre_invoice": {
				IsRequired: false,
				Placeholder: this.props.t( "Choose" ),
				OptionsFilter: function( Values, Options ){
					
					console.log( "OptionsFilter", Options );
					
					let Company = Values[ "company" ];
					
					
					let FilteredOptions = new Map( );
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						if( Company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company.toString( ) !== Company.toString( ) ){
							
							continue;
							
						}
						
						if( Option.fields.post_invoice !== null ){
							
							continue;
							
						}
						
						FilteredOptions.set( I, Option );
						
					}
					
					console.log( Company, FilteredOptions );
					
					return FilteredOptions;
					
				}
			},
			"post_invoice": {
				IsRequired: false,
				Placeholder: this.props.t( "Choose" ),
				OptionsFilter: function( Values, Options ){
														
					console.log( "OptionsFilter", Options );
					
					let Company = Values[ "company" ];
					
					
					let FilteredOptions = new Map( );
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						if( Company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company === null ){
							
							continue;
							
						}
						
						if( Option.fields.company.toString( ) !== Company.toString( ) ){
							
							continue;
							
						}
						
						if( Option.fields.pre_invoice !== null ){
							
							continue;
							
						}
						
						FilteredOptions.set( I, Option );
						
					}
					
					console.log( Company, FilteredOptions );
					
					return FilteredOptions;
					
				}
			},
			"additional_text": {
				IsRequired: false,
				RegExpMatch: "^.{0,512}",
				RegExpValidation: "^.{1,512}$",
				Placeholder: this.props.t( "Enter the value" ),
				OptionsFilter: function( Values, Options ){
					
					console.log( "filter", Values, Options );
					
					let Company = Values[ "company" ];

					let FilteredOptions = new Map( );
					
					for(
						let I of Options.keys( )
					){
						
						let Option = Options.get( I );
						
						
						if( Option.fields.company_ids === null ){
							
							FilteredOptions.set( I, Option );
							
						} else {
							
							if( Company !== null ){
								
								let CompanyIds = Option.fields.company_ids.split( "//" );
								
								if( CompanyIds.includes( Company.toString( ) ) === true ){
									
									FilteredOptions.set( I, Option );
									
								}
								
							}
							
						}
						
					}
					
					console.log( FilteredOptions );
					
					return FilteredOptions;
					
				}
			},
			"created_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			},
			"updated_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			},
			"deleted_by": {
				IsRequired: false,
				Placeholder: this.props.t( "" )
			}
			/*"created_at",
			"updated_at",
			"id"*/
		};
			
		this.#Modals = [
			{
				Type: "OutinvoiceCard",
				TypeData: {
					Id: "DocGen",
					Title: this.props.t( "Add new" )
				}
			}
		];
			
		this.#Controls = [
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonAdd",
					Title: this.props.t( "Add new" ),
					ClassName: "btn btn-success btn-sm",
					Icon: "fa fa-plus",
					Action: "Custom", 
					ActionData: {
						Fields: "All",
						IsDisabled: function( RowCount ){
							
							return false;
							
						}.bind( this ),
						Process: function( SelectedNodes, FinishCb, GridAPI ){
		
							this.ActionModalVisible( true );

						}.bind( this )
					}
				}
			},
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonExcelExport",
					Title: this.props.t( "" ),
					ClassName: "btn btn-primary btn-sm",
					Icon: "fa fa-file-excel",
					Action: "Custom", 
					ActionData: {
						Fields: "Selected",
						IsDisabled: function( RowCount ){
							
							return ( this.#IsDisabledExcelExport === true );
							
						}.bind( this ),
						Process: function( SelectedNodes, FinishCb, GridAPI ){
		
							console.log( SelectedNodes );
							
							this.#IsDisabledExcelExport = true;
							
							GridAPI.exportDataAsExcel( {
								processCellCallback: function( Params ){
									
									console.log( Params );
									
									if( ( Params.value !== null ) && ( typeof( Params.value ) === "object" ) ){
										
										return Params.value.label;
										
									}
									
									return Params.value
									
								}
							} );
							
							this.#IsDisabledExcelExport = false;
									
							FinishCb( );

						}.bind( this )
					}
				}
			},
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonMassPrint",
					Title: this.props.t( "Mass printing" ),
					ClassName: "btn btn-success btn-sm",
					Icon: "fa fa-print",
					Action: "Custom", 
					ActionData: {
						Fields: "Selected",
						IsDisabled: function( RowCount ){
							
							return ( ( this.#IsDisabledMassPrint === true ) || ( RowCount === 0 ) );
							
						}.bind( this ),
						Process: function( SelectedNodes, FinishCb ){
		
							console.log( SelectedNodes );
							
							this.#IsDisabledMassPrint = true;
							
							//Buttp
							//this.setState( { disabled: true } );
							
							//this.setState( { spinner: 'inline' } );
						
							//e.preventDefault();
							

							let form_data = new FormData( );
							
							let invoice_ids = [];
							
							let SelectedRows = SelectedNodes;
							
							console.log( SelectedRows );
							
							for( let key in SelectedRows ){
								
								console.log( SelectedRows[key].data.id );
								
								invoice_ids.push( SelectedRows[key].data.id );
								
							}
							
							let json_data = { ids: invoice_ids };
							
							form_data.append( 'json', JSON.stringify( json_data ) ); 
							
							HttpService.postblob(UrlService.apiDomain() + 'api/documents/outinvoices/print_pdf', form_data)
								.then( response => {
									
									if( response.status != 200 ) {
										
										alert(this.props.t('Chyba při generaci')+'. '+this.props.t(response.statusText));
										
										//this.setState( { disabled: false } );
							
										//this.setState( { spinner: 'none' } );
										
										this.#IsDisabledMassPrint = false;

										FinishCb( );
										
										return;

									}


									console.log(response);

									const url = window.URL.createObjectURL(
										new Blob([response.data]),
									);
									
									const link = document.createElement('a');
									
									link.href = url;

									var filename = 'mass_print.pdf';
									
									link.setAttribute(
										'download',
										filename,
									);

									document.body.appendChild( link );

									link.click( );
									
									link.parentNode.removeChild( link );
									
									this.#IsDisabledMassPrint = false;
									
									FinishCb( );
									
									//this.setState( { disabled: false } );
							
									//this.setState( { spinner: 'none' } );

								})

								.catch((error) => {
									alert(error);
									console.log(error);
									FinishCb( );
								});

						}.bind( this )
					}
				}
			},
			{
				Type: "Button",
				TypeData: {
					Id: "ButtonDelete",
					ClassName: "btn btn-danger btn-sm",
					Icon: "fa fa-trash",
					Title: this.props.t( "Delete" ),
					Action: "DeleteRows",
					ActionData: {
						ToDelete: "Selected"
					}
				}
			}
		];
		
		let HTTPClientCookies = HTTPClientCookiesT.Parse( document.cookie );

		let APIClient = new APIClientT(
			API_URL,
			HTTPClientCookies.Get( "access_token" ) ? HTTPClientCookies.Get( "access_token" ).Value( ) : null,
			new HTTPClientT(
				new HTTPClientConfigT(
				
				)
			)
		);
		
		this.#OutinvoiceAdd = new OutinvoiceAddT(
			HTTPClientCookies,
			APIClient
		);
		
		this.#OutinvoiceAdd.Start( );
		
		this.#OutinvoiceAdd.OnOutinvoiceCloseSet( this.ActionModalHide.bind( this ) );
		
		this.state = {
			showModalAdd: false
		};
		
    }
	
	ActionModalHide( ){
		
		this.ActionModalVisible( false )
		
	}
	
	ActionModalVisible( Visible ){
		
		console.log( "ActionModalVisible", Visible );
		
		
		this.#Refresh = this.#Refresh + 1;
		
		this.setState( {
			showModalAdd: Visible
		} );
		
	}
	
	componentDidMount( ){
		
		console.log( "Outinvoice", "componentDidMount" );
		
	}

    componentDidUpdate( prevProps, prevState ) {
		
		console.log( "Outinvoice", "componentDidUpdate" );
        
    }
	
	render( ){
		
		let ModalShow = this.state.showModalAdd;
		
		return ( 
			<>
				
				<div 
					ref={ ( ModalRef ) => {  } } 
					className={ "modal" }
					style={ { zIndex:1050, position: "absolute", width: "100%", height: "100%", display: ( ModalShow ? "table" : "none" ) }}
				>
					<div className={`modal-dialog modal-xxl`}>
						<div className="modal-content">
							<div className="modal-header">
								
								<h4 className="modal-title">{ this.props.t( "Add new" ) }</h4>
									
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={ ( ) => { 
									
										this.setState( {
											showModalAdd: false
										} );
										
									} }
								>
									<span aria-hidden="true">&times;</span>
								</button>
									
							</div>
							<div className="modal-body" ref={ 
								( Ref ) => {
								
									if( Ref !== null ){ 
									
										let Component = this.#OutinvoiceAdd.Render( );
									
										if( Component !== null ){
									
											Ref.appendChild( Component );
											
										} else {
										
											this.#OutinvoiceAdd.RefSet( Ref );
										
										}
										
									} else {
									
										this.#OutinvoiceAdd.RefSet( Ref );
									
									}
									
								} 
					
							} >
								
							</div>
						</div>
					</div>
				</div>
				<div
					ref={ ( ModalRefBD ) => { }} 
					className={ "modal-backdrop fade" }
					style={ { display: ( ModalShow ? "block" : "none" ), opacity: 0.5 } }
				>
				</div>
				
				
				
				<Grid 
					title = { this.#Title }
					meta = { this.#Meta }
					fields = { this.#Fields }
					year = { this.props.year }
					month = { this.props.month }
					model_name = { this.#ModelName }
					model_schema = { this.#ModelSchema }
					prepare = { this.#Prepare }
					pinned_left_fields = { this.#PinnedLeftFields }
					pinned_right_fields = { this.#PinnedRightFields }
					checkbox_field = { this.#CheckboxField }
					modals = { this.#Modals }
					controls = { this.#Controls }
					refresh = { this.#Refresh }
				>
				
				</Grid>
			</>
		);
		
	}

};


OutInvoices.propTypes = {
	
    t: PropTypes.func.isRequired
	
};


export default translate( )( OutInvoices );


