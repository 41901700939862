"use strict";


import ComponentAbstractConfigT from "./_/ComponentAbstractConfigT.mjs";

import ComponentBaseConfigT from "./Base/ComponentBaseConfigT.mjs";

import ComponentBooleanConfigT from "./Boolean/ComponentBooleanConfigT.mjs";

import ComponentButtonConfigT from "./Button/ComponentButtonConfigT.mjs";

import ComponentCheckListConfigT from "./CheckList/ComponentCheckListConfigT.mjs";

import ComponentLabelConfigT from "./Label/ComponentLabelConfigT.mjs";

import ComponentLayoutElementConfigT from "./Layout/ComponentLayoutElementConfigT.mjs";

import ComponentLayoutHConfigT from "./Layout/ComponentLayoutHConfigT.mjs";

import ComponentLayoutHDynamicConfigT from "./Layout/ComponentLayoutHDynamicConfigT.mjs";

import ComponentLayoutVConfigT from "./Layout/ComponentLayoutVConfigT.mjs";

import ComponentLayoutVDynamicConfigT from "./Layout/ComponentLayoutVDynamicConfigT.mjs";

import ComponentSelectConfigT from "./Select/ComponentSelectConfigT.mjs";

import ComponentSelectTextConfigT from "./SelectText/ComponentSelectTextConfigT.mjs";

import ComponentTableHConfigT from "./Table/H/ComponentTableHConfigT.mjs";

import ComponentTextConfigT from "./Text/ComponentTextConfigT.mjs";

import ComponentDoubleConfigT from "./Double/ComponentDoubleConfigT.mjs";

import ComponentDateConfigT from "./Date/ComponentDateConfigT.mjs";


const ComponentConfigT = class ComponentConfigT extends ComponentAbstractConfigT {
	
	#Type = null;
	#ComponentConfigTyped = null;
	
	constructor(
		Type,
		ComponentConfigTyped
	){

		if( typeof( Type ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Type === "Base" ){
			
			if( ( ComponentConfigTyped instanceof ComponentBaseConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "Button" ){
			
			if( ( ComponentConfigTyped instanceof ComponentButtonConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "CheckList" ){
			
			if( ( ComponentConfigTyped instanceof ComponentCheckListConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
			
		} else if( Type === "Label" ){ 
		
			if( ( ComponentConfigTyped instanceof ComponentLabelConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "LayoutElement" ){
			
			if( ( ComponentConfigTyped instanceof ComponentLayoutElementConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "LayoutV" ){
			
			if( ( ComponentConfigTyped instanceof ComponentLayoutVConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "LayoutVDynamic" ){
			
			if( ( ComponentConfigTyped instanceof ComponentLayoutVDynamicConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "LayoutH" ){
			
			if( ( ComponentConfigTyped instanceof ComponentLayoutHConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "LayoutHDynamic" ){
			
			if( ( ComponentConfigTyped instanceof ComponentLayoutHDynamicConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "Select" ){ 
		
			if( ( ComponentConfigTyped instanceof ComponentSelectConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "SelectText" ){
			
			if( ( ComponentConfigTyped instanceof ComponentSelectTextConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
			
		} else if( Type === "TableH" ){
			
			if( ( ComponentConfigTyped instanceof ComponentTableHConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
			
		} else if( Type === "Text" ){
			
			if( ( ComponentConfigTyped instanceof ComponentTextConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "Double" ){
			
			if( ( ComponentConfigTyped instanceof ComponentDoubleConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "Boolean" ){
			
			if( ( ComponentConfigTyped instanceof ComponentBooleanConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else if( Type === "Date" ){
			
			if( ( ComponentConfigTyped instanceof ComponentDateConfigT ) === false ){
		
				throw new Error( "Invalid argument type" );
			
			}
		
		} else {
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		super( );
		
		
		this.#Type = Type;
		
		this.#ComponentConfigTyped = ComponentConfigTyped;
		
	}
	
	Copy( ){
		
		return new ComponentConfigT(
			this.#Type,
			this.#ComponentConfigTyped.Copy( )
		);
		
	}
	
	TypeGet( ){
	
		return this.#Type;
	
	}
	
	ComponentConfigTypedGet( ){
	
		return this.#ComponentConfigTyped;
	
	}
				
};


export default ComponentConfigT;