"use strict";


import UIElementT from "../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentDoubleT from "../../../../../Interface/UI/Component/Double/UIComponentDoubleT.mjs";


import UIStateT from "../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentDoubleConfigT from "../../../../../Type/UI/Component/Double/UIComponentDoubleConfigT.mjs";

import UIComponentTextDataConfigT from "../../../../../Type/UI/Component/TextData/UIComponentTextDataConfigT.mjs";

import UIComponentPlaceholderConfigT from "../../../../../Type/UI/Component/Placeholder/UIComponentPlaceholderConfigT.mjs";


const GTSUIComponentDoubleT = class GTSUIComponentDoubleT extends UIComponentDoubleT {
	
	constructor(
		ValueDefault,
		
		PlaceholderLabel,
		
		ZeroValid = true,
		
		CanBeSigned = true,
		
		Id = null
	){
		
		
		let RegExpSigned = "[\\-]{0,1}"
		
		let RegExpMatch = "^" + ( ( CanBeSigned === true ) ? RegExpSigned : "" ) + "([0-9]{1,8}(\.[0-9]{0,8})?)?";
		
		let RegExpValidation = ( ZeroValid === true ) ? ( "^" + RegExpSigned + "([0-9]{1,8}(\.[0-9]{0,8})?)?$" ) : ( "^" + RegExpSigned + "(?=.*[1-9])[0-9]*[.]?[0-9]{1,8}$" );
		
		
		let ValueDefaultTextData = ( ValueDefault === 0 ) ? "" : ValueDefault.toString( );
		
		
		let UIComponentTextDataConfig = new UIComponentTextDataConfigT(
			ValueDefaultTextData, //ValueDefault
			false, //EmptyOnFocus
			RegExpMatch, //RegExpMatch
			RegExpValidation, //RegExpValidation
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentTextDataT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" )
					] )
				)
			] ),
		
			null //Id
			
		);
		
		let UIComponentPlaceholderConfig = new UIComponentPlaceholderConfigT(
		
			PlaceholderLabel,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentPlaceholderT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "100" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "border", "0px solid transparent" ),
						new UIStyleT( "outline", "0px solid transparent" ),
						new UIStyleT( "position", "absolute" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" )
					] )
				)
			] ),
			
			null
		);
		
		let UIComponentDoubleConfig = new UIComponentDoubleConfigT(
		
			ValueDefault,
			
			false,//MayBeNegative,
			true, //MayBePositive,
			2, //FractionMaxSize,
		
			UIComponentPlaceholderConfig,
			UIComponentTextDataConfig,
			
			new UIStateMapT( [
				new UIStateT( 
					"Default",
					"UIComponentTextT",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "relative" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"ValidFocused",
					"UIComponentTextT Valid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "relative" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Valid",
					"UIComponentTextT Valid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "relative" ),
						new UIStyleT( "border", "1px solid black" ),
						new UIStyleT( "box-shadow", "black 0px 0px 0px" )
					] )
				),
				new UIStateT( 
					"InvalidFocused",
					"UIComponentTextT Invalid Focused",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "relative" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 5px 15px" )
					] )
				),
				new UIStateT( 
					"Invalid",
					"UIComponentTextT Invalid",
					new UIStyleMapT( [
						new UIStyleT( "width", "100%" ),
						new UIStyleT( "height", "38px" ),
						new UIStyleT( "padding", "6px 12px" ),
						new UIStyleT( "font-size", "16px" ),
						new UIStyleT( "font-weight", "400" ),
						new UIStyleT( "line-height", "1.5" ),
						new UIStyleT( "border-radius", "4px" ),
						new UIStyleT( "display", "block" ),
						new UIStyleT( "white-space", "nowrap" ),
						new UIStyleT( "overflow", "hidden" ),
						new UIStyleT( "position", "relative" ),
						new UIStyleT( "border", "1px solid red" ),
						new UIStyleT( "box-shadow", "red 0px 0px 0px" )
					] )
				)
			] ),
			
			Id
		
			/*new UIStyleMapT( [ //UIStyleMap
				new UIStyleT( "position", "relative" ),
				new UIStyleT( "width", "100%" )
			] )*/
			
		);
		
		
		super(
			new UIElementT( ),
			UIComponentDoubleConfig
		);
		
		let UIComponentTextData = super.TextDataGet( );
		
		if( UIComponentTextData.ValueGet( ) === "" ){
			
			this.#OnPlaceholderShow( );
			
		} else {
			
			this.#OnPlaceholderHide( );
			
		}
		
	}
	
	
	#OnPlaceholderHide( 
	
	){
		
		console.log( "GTSUIComponentTextT.#PlaceholderHide" );
		
		
		/*if( this.EditableGet( ) === false ){
			
			return;
			
		}*/
		
		
		let UIComponentTextData = super.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "block" )
			] )
		);
		
		let UIComponentPlaceholder = super.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "none" )
			] )
		);
		
	}
	
	#OnPlaceholderShow(
	
	){
		
		console.log( "GTSUIComponentTextT.#PlaceholderShow" );
		
		
		/*if( this.EditableGet( ) === false ){
			
			return;
			
		}*/
		
		
		let UIComponentTextData = super.TextDataGet( );
		
		UIComponentTextData.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "none" )
			] )
		);
			
		let UIComponentPlaceholder = super.PlaceholderGet( );
		
		UIComponentPlaceholder.StyleMapSet( 
			new UIStyleMapT( [
				new UIStyleT( "display", "block" )
			] )
		);
			
	}
	
	Start(
	
	){
	
		super.Start( );
	
	}
	
	Listen(
		OnChange
	){
		
		super.Listen(
			OnChange,
			this.#OnPlaceholderShow.bind( this ),
			this.#OnPlaceholderHide.bind( this )
		);
		
	}
	
	EditableSet(
		Editable
	){
		
		if( Editable === false ){
			
			this.StyleMapSet(
				new UIStyleMapT( [
					new UIStyleT( "opacity", "0.5" )
				] )
			);
			
		}
		
		super.EditableSet( Editable );
		
	}
	
	EditableGet(
	
	){
		
		return super.EditableGet( );
		
	}
	
	ValueSet(
		Value,
		NeedFocus,
		Precision = 2
	){
		
		let MultiplierDivider = Math.pow( 10, Precision );
		
		
		let ResultValue = Math.round( Value * MultiplierDivider ) / MultiplierDivider;
		
		
		super.ValueSet(
			ResultValue,
			NeedFocus
		);
		
		this.#OnPlaceholderHide( );
		
	}
	
};


export default GTSUIComponentDoubleT;
