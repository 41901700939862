"use strict";


import ComponentTextConfigT from "../Text/ComponentTextConfigT.mjs";


const ComponentBooleanConfigT = class ComponentBooleanConfigT extends ComponentTextConfigT {
	
	constructor(
		Value,
		Placeholder,
		RegExpMatch,
		RegExpValidation,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			Value,
			Placeholder,
			RegExpMatch,
			RegExpValidation,
			ClassName,
			StyleMap,
			Name,
			Id
		);
		
	}
	
	Copy(
	
	){
		
		return new ComponentBooleanConfigT(
			this.ValueGet( ),
			this.PlaceholderGet( ),
			this.RegExpMatchGet( ),
			this.RegExpValidationGet( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
				
};


export default ComponentBooleanConfigT;