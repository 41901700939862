import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import Communication from "../common/communication";
import Modal from "../../components/common/modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
class CommunicationTab  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            showCommunicationId:null,
            showCommunication:false,

            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Type'),
                    field: 'type',
                    editable:false,

                },
                {
                    headerName: this.props.t('From'),
                    field: 'from',
                    editable:false,
                    cellRenderer:function(params) {
                        if (params.data.from!==null) {
                            return params.data.from.replace('<', "").replace('>', "");
                        } else {
                            return '';
                        }
                    }

                },
                {
                    headerName: this.props.t('To'),
                    field: 'to',
                    editable:false,
                    suppressMenu: true,
                    cellRenderer:function(params) {
                        if (params.data.to!==null) {
                            return params.data.to.replace('<', "").replace('>', "");
                        } else {
                            return '';
                        }
                    }
                },

            {
                headerName: this.props.t('Subject'),
                field: 'subject',
                editable:false,
                suppressMenu: true,
            },

            {
                headerName: this.props.t('Text'),
                field: 'text',
                editable:false,
                suppressMenu: true,
                cellRenderer:function(params) {
                    if (params.data.text!==null) {
                        return params.data.text.replace(/(<([^>]+)>)/gi, "");
                    } else {
                        return '';
                    }
                }
            },

            {
                headerName: this.props.t('Date'),
                field: 'date',
                editable:false,
                suppressMenu: true,
            },

                {
                    headerName: this.props.t('Attachments'),
                    field: 'attachment',
                    editable:false,
                    suppressMenu: true,
                    valueGetter:function(params) {
                        if (params.data.attachment===null || params.data.attachment==='' || params.data.attachment==='[]') return '';
                        return JSON.parse(params.data.attachment).length;
                    }
                },

            {
                headerName: this.props.t('Note'),
                field: 'note',
                editable:true,
                suppressMenu: true,
            },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,

            }
        };
    }




    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.id!=null)  {
                this.setState({
                    rowdata: []
                });

                HttpService.get(UrlService.apiDomain() + 'api/communications/companies/'+this.props.id)
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    onCellCValueChanged = (event) => {
        HttpService.put(UrlService.apiDomain()+'api/communications/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');


            })
            .catch((error) => {
                console.log(error);
            })


    }

    onCellClicked=(e)=> {

            this.setState({
                showCommunication: true,
                showCommunicationId: e.node.data.id,
            });

        }

    render() {


        return (


            <div style={{ 'height': '400px', width:'100%' }} id="companyCommunicationsGrid" className="ag-theme-alpine">
                <Modal
                    title={this.props.t('Communication')}

                    visibility={this.state.showCommunication}
                    size="lg"
                    onClose={() => this.setState({ showCommunication: !this.state.showCommunication })}
                >
                    <Communication show={this.state.showCommunication} tmp={Date.now()} id={this.state.showCommunicationId} model={"company"} />
                </Modal>
                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    onCellClicked={this.onCellClicked}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}
                />
            </div>


        );
    }
}

CommunicationTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(CommunicationTab);

