import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import SupplylistCard from "../loadingcard";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import ActionsRenderer from "../../aggrid/ActionsRenderer.jsx";
import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import OutinvoiceAdd from "../../components/outinvoices/outinvoice_add.tsx";

import AddSupplyList from "./supplylist_add.tsx";
import {LoadState, SaveState} from "../../services/GridStateService";
class SupplyLists  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;

		const DateComparator = ( ValueA, ValueB ) => {
			
			console.log( ValueA, ValueB );
			
			if( ( ValueA === null ) && ( ValueB === null ) ){
				
				return 0;
				
			} else if( ( ValueA === null ) && ( ValueB !== null ) ){
				
				return -1;
				
			} else if( ( ValueA !== null ) && ( ValueB === null ) ){
				
				return 1;
				
			} else {
				
				let ValueADate = new Date( ValueA );
				
				let ValueBDate = new Date( ValueB );
				
				
				if( ValueADate.getTime( ) < ValueBDate.getTime( ) ){
				
					return -1;
					
				} else if( ValueADate.getTime( ) > ValueBDate.getTime( ) ) {

					return 1;

				} else {
					
					return 0;
					
				}
				
			}
			
        };
		
		const DateFormatterCZ = function( Params ) {
						
			if( Params.value === null ){
				
				return "";
				
			}
			
			
			let ValueDate = new Date( Params.value );
			
			
			let YearString = null;
			
			let MonthString = null;
			
			let DayString = null;
			
			
			let Year = ValueDate.getFullYear( );
			
			YearString = Year.toString( );
			
			
			let Month = ValueDate.getMonth( ) + 1;
			
			if( Month < 10 ) {
				
				MonthString = "0" + Month.toString( );

			} else {
				
				MonthString = Month.toString( );
				
			}
			
			
			let Day = ValueDate.getDate( );

			if( Day < 10 ) {
				
				DayString = "0" + Day.toString( );
			
			} else {
				
				DayString = Day.toString( );
				
			}
			
			
			return DayString + "." + MonthString + "." + YearString;

		};
		
		const DateTimeFormatterCZ = function( Params ) {
			
			console.log( Params.value );
						
			if( Params.value === null ){
				
				return "";
				
			}
			
			
			let ValueDate = new Date( Params.value );
			
			
			let YearString = null;
			
			let MonthString = null;
			
			let DayString = null;
			
			let HoursString = null;
			
			let MinutesString = null;
			
			let SecondsString = null;
			
			
			let Year = ValueDate.getFullYear( );
			
			YearString = Year.toString( );
			
			
			let Month = ValueDate.getMonth( ) + 1;
			
			if( Month < 10 ) {
				
				MonthString = "0" + Month.toString( );

			} else {
				
				MonthString = Month.toString( );
				
			}
			
			
			let Day = ValueDate.getDate( );

			if( Day < 10 ) {
				
				DayString = "0" + Day.toString( );
			
			} else {
				
				DayString = Day.toString( );
				
			}
			
			
			let Hours = ValueDate.getHours( );
			
			if( Hours < 10 ) {
			
				HoursString = "0" + Hours.toString( );
			
			} else {
				
				HoursString = Hours.toString( );
				
			}
			
			let Minutes = ValueDate.getMinutes( );
			
			if( Minutes < 10 ) {
			
				MinutesString = "0" + Minutes.toString( );
				
			} else {
				
				MinutesString = Minutes.toString( );
				
			}
			
			
			let Seconds = ValueDate.getSeconds( );
			
			if( Seconds < 10 ) {
			
				SecondsString = "0" + Seconds.toString( );
				
			} else {
				
				SecondsString = Seconds.toString( );
				
			}
			
			
			return DayString + "." + MonthString + "." + YearString + " " + 
				HoursString + ":" + MinutesString + ":" + SecondsString;

		};

        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            now:Date.now(),
            showSupplylistCard: false,
            showSupplylistCardType: '',
            showSupplylistCardName: '',
            showSupplylistCardId: '',
            showSupplylistCardPrint:'',
            showModal:false,

            rowSelected:false,
			rowSelectedArray: [ ],
            showInvoiceAdd:false,
            showInvoiceAddRownodes:[],

            localeText: loctext,
            rowdata: null,
            columnDefs: [






                {
                    headerName: this.props.t('Supply list number'),
                    field: 'supplylist_number',
                    editable:true,
                    cellRenderer: "ActionsRenderer",
                    checkboxSelection:true,
                    minWidth:200,
        cellRendererParams :{
            gridType: "supplylists",
            showcard: this.showSupplyListCard.bind(this)

        }

                },

                {
                    headerName: this.props.t('Company'),
                    field: 'company',
                    editable:false,
                },

                {
                    headerName: this.props.t('Invoice number'),
                    field: 'invoice_number',
                    editable:false,


                },

                {
                    headerName: this.props.t('Truck plate 1'),
                    field: 'spz1',
                    editable:true,
                },
                {
                    headerName: this.props.t('Truck plate 2'),
                    field: 'spz2',
                    editable:true,
                },

                {
                    headerName: this.props.t('Order number'),
                    field: 'order_number',
                    editable:false,
                },

                {
                    headerName: this.props.t('Driver name'),
                    field: 'name',
                    editable:false,
                },

                {
                    headerName: this.props.t('Driver address'),
                    field: 'address',
                    editable:false,
                },

                {
                    headerName: this.props.t('Driver passport'),
                    field: 'passport',
                    editable:false,
                },

                {
                    headerName: this.props.t('Order date'),
                    field: 'order_date',
                    editable: false,
					valueFormatter: DateFormatterCZ,
					comparator: DateComparator
                },

                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    valueFormatter: DateTimeFormatterCZ,
					editable: false,
					comparator: DateComparator
                },

                {
                    headerName: this.props.t('Created by'),
                    field: 'created_by',
                    valueGetter: function (params) {
                        var values= JSON.parse(localStorage.getItem('users'));
                        if (params.data.created_by === null || params.data.created_by === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.created_by))['name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,

                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer:DeleteRenderer,
                ActionsRenderer: ActionsRenderer,


            }
        };
    }

    showSupplyListCard(e) {

        this.setState({
            showSupplylistCard: true,
            showSupplylistCardType: 'supplylist_single',

            showSupplylistCardName: this.props.t('Generate supply list')+' '+e.supplylist_number,
            showSupplylistCardId: e.id,
        })

    }

    showInvoiceAdd() {

        var sale_ids='';
        this.gridApi.getSelectedNodes().forEach((e) => {
            sale_ids+=e.data.sale_ids
        });

        var url = UrlService.apiDomain() + 'api/sales_by_ids/'+sale_ids;

        HttpService.get(url)
            .then(res => {
                var mult_lns=[];
                var ok=true;
                var existing_invoice=false;
                var different_companies=false;
                var different_currencies=false;
                var company='';
                var currency='';
                res.data.sales.map((e) => {

                    if (company=='') company=e.company;
                    if (currency=='') currency=e.currency;
                    if (company!='' && company!=e.company) different_companies=true;
                    if (currency!='' && currency!=e.currency) different_currencies=true;
                    if (e.invoice!=null && e.invoice!='') existing_invoice=true;
                   // if (e.incvat==null || parseFloat(e.incvat)==0) ok=false;
                    mult_lns.push(e);

                });

                if (!ok) {
                    alert(this.props.t('Cannot create an invoice. One of selected items has zero price'));
                    return false;
                }

                if (existing_invoice) {
                    alert(this.props.t('Cannot create an invoice. One of selected items already has assigned invoice'));
                    return false;
                }

                if (different_companies) {
                    alert(this.props.t('It is impossible to create invoice for sales to different companies'));
                    return false;
                }

                if (different_currencies) {
                    alert(this.props.t('It is impossible to create invoice for sales with different currencies'));
                    return false;
                }

                this.setState({
                    showInvoiceAdd: true,
                    now:Date.now(),
                    showInvoiceAddRownodes: mult_lns,
                });

            })
            .catch((error) => {
                console.log(error);
            });



    }


    async invoiceAdd(data) {

		this.setState({showInvoiceAdd: false});
        /*HttpService.post(UrlService.apiDomain()+'api/outinvoices', data)
            .then(res => {
                if (res.data===null) alert ("Chyba připojení k serveru");
                if (res.data.result==='failed' || typeof res.data.errors!=='undefined') {
                    var msg=res.data.message+"\n";
                    if (typeof res.data.errors!=='undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg+=value[0]+"\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');
                    this.setState({showInvoiceAdd: false});


                    var rownode;
                    res.data.supplylists.map((e)=>{
                        rownode=this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });



                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });*/


    }

    getData() {
        var invoice_id = 0;
        var sale_ids = 0;
        if (this.props.invoice_id) invoice_id = this.props.invoice_id;
        if (this.props.sale_ids) sale_ids = this.props.sale_ids;
        var url = UrlService.apiDomain() + 'api/supplylists/' + invoice_id+'/'+sale_ids;

        HttpService.get(url)
            .then(res => {
                this.setState({
                    rowdata: res.data
                },()=>{

                    if (this.props.from_sale=='yes') {

                        function tt() {
                            this.gridApi.selectAll();
                           // this.showMultiPrint();

                        }


                        setTimeout(tt.bind(this), 100);
                        setTimeout(() => {
                            LoadState('SupplyList', this.gridColumnApi, this.gridApi);
                            this.setState({
                                isColumnStateLoaded: true
                            })
                        }, 200);

                    }

                });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            if (this.props.from_sale=='yes') {
                this.setState({now: Date.now(), showModal: true});
            }
            this.getData();

        }
    }

    showOutinvoiceCard(e) {
        this.setState({
            showSupplylistCard: true,
            showSupplylistCardType: 'supplylist_single',

            showSupplylistCardName: this.props.t('Generate supply list')+' '+e.supplylist_number,
            showSupplylistCardId: e.id,
        })

    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));

        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

    };

    async handleAdd(data) {

        HttpService.post(UrlService.apiDomain()+'api/supplylists', data)
            .then(res => {
                if (res.data===null) alert ("Chyba připojení k serveru");
                if (typeof res.data.alert!=='undefined' && res.data.alert!='') {

                    alert(res.data.alert);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});

                    this.getData();



                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }

    getRowNodeId=(data) => {
        return data.id;
    };

    showMultiPrint() {
        var mult_lns=[];
        this.gridApi.getSelectedNodes().forEach((e) => {
            // console.log(e);

                mult_lns.push(e.data.id);

        });

        this.setState({
            showSupplylistCard: true,
            showSupplylistCardType: 'supplylist_single',

            showSupplylistCardName: this.props.t('Supply lists'),
            showSupplylistCardId: mult_lns,
            showSupplylistCardPrint:this.props.from_sale
        });
    }


    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/supplylists/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
            })
            .catch((error) => {
                console.log(error);
            })


    }
	
	onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected: true,rowSelectedArray:[ ...this.gridApi.getSelectedNodes() ]});
        } else {
            this.setState({rowSelected: false,rowSelectedArray:[]});
        }
    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('SupplyList', params.columnApi, params.api);
        }
    }

    render() {


        var permissions = JSON.parse(localStorage.getItem('my_permissions'));

        return (
            <>
            {(permissions.includes('outinvoices.create') && !this.props.fromtab) &&
            <Modal
                title={this.props.t('Add new')}
                visibility={this.state.showInvoiceAdd}
                size="lg"
                onClose={() => this.setState({showInvoiceAdd: !this.state.showInvoiceAdd})}
            >
                <OutinvoiceAdd rownodes={this.state.showInvoiceAddRownodes} type='sales' fromtab={false} tmp={this.state.now}
                               onAdd={(data) => this.invoiceAdd(data)} selectedArray={this.state.rowSelectedArray}  from_supplylists={true}/>
            </Modal>
            }
                <Modal
                    title={this.props.t('Add supply list')}
                    visibility={this.state.showModal}
                    size="lg"
                    onClose={() => this.setState({ showModal: !this.state.showModal })}
                >
                    <AddSupplyList from_sale={this.props.from_sale} sale_ids={this.props.sale_ids} invoice_id={this.props.invoice_id} tmp={this.state.now}  onAdd={(data) => this.handleAdd(data)} />
                </Modal>
                <Modal

                    title={this.state.showSupplylistCardName}
                    zindex="1051"
                    visibility={this.state.showSupplylistCard}
                    size="lg"
                    onClose={() => this.setState({ showSupplylistCard: !this.state.showSupplylistCard })}
                >
                    <SupplylistCard from_sale={this.state.showSupplylistCardPrint} type={this.state.showSupplylistCardType} show={this.state.showSupplylistCard} tmp={Date.now()} loading_number={this.state.showSupplylistCardId} />
                </Modal>


                        <div className="buttons-companies">
                            <div className="tool-buttons">
                                <button type="button"
                                        disabled={!this.state.rowSelected}
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            this.showMultiPrint();

                                        }}
                                >{this.props.t('Mass print')} <i className="fa  fa-file-pdf"></i>
                                </button>
                                <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => this.setState({ now:Date.now() , showModal: true })}
                                >
                                    {this.props.t('Add new')} <i className="fa fa-plus"></i>
                                </button>

                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.gridApi.exportDataAsExcel()}
                                >
                                    {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                                </button>

                                {(permissions.includes('outinvoices.create') && this.props.from_sale!='yes' ) &&

                                <button type="button"
                                        disabled={!this.state.rowSelected}
                                        className="btn btn-success btn-sm"
                                        onClick={() => {
                                            this.showInvoiceAdd();

                                        }}
                                >{this.props.t('Create invoice')} <i className="fa  fa-file-invoice"></i>
                                </button>
                                }
                            </div>
                        </div>
                        <div style={{ 'height': '80vh', width:'100%' }} id="suppylistsGrid" className="ag-theme-alpine">

                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                rowSelection={'multiple'}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                getRowNodeId = {this.getRowNodeId}
                                onRowSelected={this.onRowSelected.bind(this)}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>

            </>


        );
    }
}

SupplyLists.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SupplyLists);

