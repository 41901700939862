"use strict";


const HTTPClientConfigT = class HTTPClientConfigT {
	
	constructor(
		
	){
		
	}
	
};


export default HTTPClientConfigT;