
const messages = {
    "hello_name": "Ahojj",
    "num_cars": "%{smart_count} car |||| %{smart_count} cars",
    "dashboard": "Nástěnka",
    "companies": "Společnosti",
    "list": "list",
    "settings": "Nastavení",
    "Settings": "Nastavení",
    "continents": "Kontinenty",
    "countries": "Země",
    "languages": "Jazyky",
    "product_codes": "Kódy produktů",
    "product_types": "Typy produktů",
    "contact_types": "Typy kontaktů",
    "active_companies": "Aktivní společnosti",
    "Common": "Obecné",
    "Name": "Jméno",
    "Email": "Email",
    "Submit": "Odeslat",
    "Home": "Úvod",
    "Logout": "Odhlásit se",
    "Loadings": "Nakládky",
    "Active companies": "Aktivních společností",
    "Contact success": "Úspěšnost kontaktů",
    "More info": "Více info",
    "Sells": "Prodejů",
    "Todo list": "Úkoly",
    "Add new Todo": "Přidat úkol",
    "Description": "Popis",
    "Enter the todo description": "Zadejte popis úkolu",
    "Date": "Datum",
    "Save": "Uložit",
	"Clear": "Vyčistit",
    "Close": "Zavřit",
    "Continents": "Kontinenty",
    "Active": "Aktivní",
    "Czech name": "Český název",
    "English name": "Anglický název",
    "Loading data": "Načítám data",
    "ISO code 3": "ISO kód 3",
    "ISO code 2": "ISO kód 2",
    "Countries": "Země",
    "Languages": "Jazyky",
    "Product codes": "Kódy produktů",
    "Product types": "Typy produktů",
    "Confirm to delete": "Potvrdit odstranění",
    "Are you sure to delete this item and all other items associated with it?": "Skutečně chcete odstranit vybraný objekt a všechny asociované s nim objekty?",
    "Add new": "Přidat",
    "Excel": "Excel",
    "Yes": "Ano",
    "No": "Ne",
    "Enter the name": "Zadejte název",
    "Delete": "Odstranit",
    "Contact types": "Typy kontaktů",
    "Company columns": "Kolonky společností",
    "ID": "ID",
    "Cell renderer": "Zobrazovač",
    "Cell editor": "Editor",
    "Data array": "Zdroj dat",
    "Loading": "Načítám",
    "Audit": "Audit",
    "User": "Uživatel",
    "Users": "Uživatele",
    "Users settings": "Nastavení uživatelů",
    "Column": "Kolonka",
    "Old value": "Stará hodnota",
    "New value": "Nová hodnota",
    "Roles": "Role",
    "Role": "Role",
    "Password": "Heslo",
    "Enter the ": "Zadejte ",
    "Phone": "Telefon",
    "Select": "Vyberte",
    "Permissions": "Práva",
    "Permission": "Právo",
    "visible": "Viditelné",
    "editable": "Upravovatelné",
    "Technical info": "Technické informace",
    "Names": "Názvy",
    "Column type": "Typ kolonky",
    "Options": "Možnosti výběru",
    "Column options": "Možnosti výběru",
    "Lowercase letters only": "Pouze malá písmenka",
    "Choose": "Vyberte",
    "Filter": "Filtrovat",
    "Branch name": "Název pobočky",
    "Type": "Typ",
    "Company ID": "IČO",
    "VAT ID": "DIČ",
    "Enter the value": "Zadejte hodnotu",
    "Continent": "Kontinent",
    "Country": "Země",
    "City": "Město",
    "ZIP": "PSČ",
    "Street": "Ulice",
    "House nr.": "Číslo domu",
    "Name of company": "Název právnické osoby",
    "Import from Excel": "Import z Excel",
    "Please choose the file.": "Vyberte prosím soubor.",
    "File imported successfully": "Soubor byl úspěšně importován",
    "Locked": "Uzamčená",
    "Contacts": "Kontakty",
    "Note": "Poznámka",
    "Main": "Hlavní",
    "State": "Kraj",
    "Address": "Adresa",
    "Profile": "Profil",
    "Field": "Pole",
    "Show": "Zobrazit",
    "Company card": "Karta společnosti",
    "Details": "Detaily",
    "Communication": "Komunikace",
    "Value": "Hodnota",
    "From": "Od",
    "To": "Komu",
    "Subject": "Předmět",
    "Text": "Text",
    "Attachments": "Přílohy",
    "Mass SMS": "Hromadné SMS",
    "Mass Email": "Hromadné Emaily",
    "Your phone": "Vaše číslo",
    "Message": "Zpráva",
    "Your email": "Váš email",
    "Reply-to email": "Email pro odpověď",
    "Drop here": "Přetáhněte sem",
    "Submit for": "Odeslat na",
    "numbers": "čísel",
    "addresses": "adres",
    "Email for check": "Email pro kontrolu",
    "Copy": "Kopie",
    "Position": "Pozice",
    "Confirm to send": "Potvrdit odeslání",
    "Are you sure you want to send mass message?": "Skutečně chcete poslat hromadnou zprávu?",
    "You don't have permissions for this address: ": "Nemáte práva na tuto adresu: ",
    "Address wasn't found": "Adresa nebyla nalezena",
    "Lattitude": "Zem. šířka",
    "Longitude": "Zem. délka",
    "Search address": "Najít adresu",
    "Find on map": "Najít na mapě",
    "Show map": "Ukázat mapu",
    "Fax": "Fax",
    "Appraisals": "Nacenění",
    "Material type": "Typ materiálu",
    "Approx. qty.": "Předpok. množství",
    "Created at": "Zadáno",
    "Appraised at": "Naceněno",
    "Price": "Cena",
    "Images": "Fotky",
    "Please choose material type": "Prosím vyberte typ materiálu",
    "Please upload only images": "Prosím nahrávejte pouze obrázky",
    "Please upload images": "Prosím nahrajte obrázky",
    "Company name": "Název firmy",
    "Loading city": "Město nakládky",
    "Appraised by": "Nacenil",
    "Requested by": "Zadal",
    "Archive": "Archiv",
    "Loading columns": "Kolonky nakládek",
    "Code": "Kód",
    "In form": "Ve formuláři",
    "Required": "Povinná",
    "Company": "Společnost",
    "Loading address": "Adresa nakládky",
    "Loading date": "Datum nakládky",
    "Loading number": "Č. nakládky",
    "Confirm storing": "Potvrdit uskladněni",
    "Are you sure you want to store this loading?": "Opravdu chcete uskladnit tuto nakládku?",
    "Document templates": "Šablóny dokumentů nakládek",
    "Template": "Šablóna",
    "Successfully uploaded": "Soubor úspěšně nahrán",
    "Generate documents": "Generovat dokumenty",
    "Document": "Dokument",
    "Send to": "Odeslat komu",
    "Send copy": "Přidat do kopie",
    "Container type": "Typ obalu",
    "Container number": "Počet obalů",
    "Count": "Počet",
    "Print pdf": "Tisk pdf",
    "Print word": "Tisk word",
    "Send": "Odeslat",
    "Files uploaded successfully": "Soubory úspěšně nahrány",
    "Previously generated": "Dříve vygenerované",
    "File": "Soubor",
    "Uploaded": "Nahrané",
    "Upload": "Nahrát",
    "Please upload max 5 files at a time": "V jedné dávce je možné nahrát maximálně 5 souborů",
    "other": "Ostatní",
    "generated": "Generovaný",
    "invoice": "Faktura",
    "weighting": "Vážní lístek",
    "Download pdf": "Stáhnout pdf",
    "Download zip": "Stáhnout zip",
    "Fill weights": "Vyplnit váhy",
    "Without weights": "Bez váh",
    "Spedition": "Spedice",
    "Contact person": "Kontakt",
    "Product type": "Materiál",
    "Kg": "Kg",
    "Tara": "Tara",
    "Download route": "Stáhnout trasu",
    "Print documents": "Dokumenty",
    "Accept route": "Přijmout trasu",
    "Trips": "Svozy",
    "Speditor": "Speditér",
    "Loading numbers": "Č. nakládek",
    "Calculated km": "Vypočtené km",
    "Real km": "Reálné km",
    "Finished": "Dokončeno",
    "Invoice number": "Č. faktury",
    "Invoice currency": "Měna",
    "Exc. VAT": "Bez DPH",
    "VAT": "DPH",
    "Inc. VAT": "Vč. DPH",
    "Exch. Rate": "Kurz",
    "Issue date": "D. vystavení",
    "Due date": "D. splatnost",
    "Taxable": "Datum ZP",
    "Show archive": "Zobrazit archiv",
    "Show all": "Zobrazit vše",
    "Show actual": "Zobrazit aktuální",
    "Chinese name": "Čínský název",
    "Stamp image": "Razítko",
    "Hourly salary": "Hodinový plat",
    "German name": "Německý název",
    "French name": "Francouzský název",
    "Italian name": "Italský název",
    "Spanish name": "Španělský název",
    "Russian name": "Ruský název",
    "Storage": "Sklad",
    "Quantity": "Množství",
    "Additional hours": "Dodatečné hod. práce",
    "Processed qty.": "Zpracované množství",
    "Worker": "Pracovník",
    "Production date": "Zpracováno dne",
    "Production history": "Historie produkce",
    "Production": "Zpracování",
    "Salary per kg": "Plat za kg",
    "Production processes": "Zpracování",
    "Products": "Výstupy",
    "Manufacturer": "Zpracoval",
    "Add. work hours": "Dodatečné hod. práce",
    "Last update": "Poslední změna",
    "Salary volume": "Mzda za kg",
    "Salary hours": "Mzda za hodiny",
    "Salary total": "Mzda celkem",
    "Supplier type": "Typ dodavatele",
    "Work types": "Typy prácí",
    "Work type": "Typ práce",
    "Work note": "Poznámka",
    "Invoices columns": "Kolonky příchozích faktur",
    "Finance": "Finance",
    "Bank accounts": "Bankovní účty",
    "Account name": "Název účtu",
    "Bank name": "Název banky",
    "Account prefix": "Předčíslí účtu",
    "Account number": "Číslo účtu",
    "Bank code": "Kód banky",
    "IBAN": "IBAN",
    "SWIFT": "SWIFT",
    "Currency": "Měna",
    "Payment method": "Způsob úhrady",
    "Pay from account": "Zaplatit z účtu",
    "Incoming invoices": "Přijaté faktury",
    "Fill in company": "Vyplňte ve společnosti",
    "Payment type": "Typ příkazu",
    "Excluding VAT": "Bez DPH",
    "Including VAT": "Včetně DPH",
    "Taxable date": "Datum ZP",
    "Paid date": "Zaplaceno dne",
    "Payer note": "Popis příkazce",
    "Beneficiary note": "Popis příjemce",
    "Cannot create an invoice. One of selected items has zero price": "Nelze vytvořit fakturu. Jedna z vybraných položek nemá zadanou cenu.",
    "Cannot create an invoice. One of selected items already has assigned invoice": "Nelze vytvořit fakturu. Jedna z vybraných položek již má zadanou faturu.",
    "Send notifications": "Posílat upozornění",
    "BIC/SWIFT": "BIC/SWIFT",
    "Speditor company": "Speditér - společnost",
    "Pay from bank account": "Zaplatit převodem",
    "Choose bank account": "Vyberte účet",
    "Cannot pay because of transfer types are mixed up": "Nelze vygenerovat příkaz protože jste vybrali faktury s více než jednim typem převodu (tuzemský, SEPA, zahraniční)",
    "Payer name": "Jméno příkazce",
    "Payer address": "Adresa příkazce",
    "Bank name 1": "Název banky 1",
    "Bank name 2": "Název banky 2",
    "Bank address 1": "Ulice banky",
    "Bank address 2": "Město banky",
    "Beneficiary name 1": "Název příjemce 1",
    "Beneficiary name 2": "Název příjemce 2",
    "Beneficiary address 1": "Adresa příjemce 1",
    "Beneficiary address 2": "Adresa příjemce 2",
    "Beneficiary country": "Země příjemce",
    "Bank country": "Země banky",
    "Variable symbol": "Variabilní symbol",
    "Constant symbol": "Konstantní symbol",
    "Specific symbol": "Specifický symbol",
    "Wire transfers": "Bankovní převody",
    "Total amount": "Celkem",
    "Paid from": "Zaplaceno z",
    "Transfers count": "Počet příkazů",
    "Sell product": "Prodat produktt",
    "Sell": "Prodat",
    "Sale date": "Datum prodeje",
    "Sales": "Prodeje",
    "Mixed currencies are not allowed": "Není možné míchat různé měny v jednom příkazu",
    "Choose filter": "Vyberte filtr",
    "Pay invoices": "Platby faktur",
    "Generator type": "Typ generátora",
    "Outgoing invoices columns": "Kolonky vystavených faktur",
    "Outgoing invoices": "Vystavené faktury",
    "Create invoice": "Založit fakturu",
    "Pay to account": "Uhradit na účet",
    "It is impossible to create invoice for sales to different companies": "Není možné vystavit fakturu za materiál různym firmám",
    "It is impossible to create invoice for sales with different currencies": "Není možné vystavit fakturu v různých měnách",
    "Excluding VAT per kg": "Bez DPH za kg",
    "VAT per kg": "DPH za kg",
    "Including VAT per kg": "Vč. DPH za kg",
    "Unit": "Jednotka",
    "Exc. VAT per kg": "Bez DPH za kg",
    "Inc. VAT per kg": "Vč. DPH za kg",
    "Item description": "Popis položky",
    "Outgoing invoices templates": "Šablóny vystavených faktur",
    "Generate invoice": "Generovat fakturu",
    "Net kg": "Net kg",
    "Gross kg": "Gross kg",
    "Work hours": "Hodiny práce",
    "Add work hours": "Přidat hodiny práce",
    "Work date": "Datum práce",
    "Hours": "Hodiny",
    "Salary": "Mzda",
    "I. invoices": "P. faktury",
    "O. invoices": "V. faktury",
    "Reports": "Reporty",
    "Salary report": "Report mezd",
    "Created by": "Zadal",
    "Hour salary": "Mzda hod.",
    "Volume salary": "Mzda kg",
    "Total salary": "Mzda celkem",
    "Total": "Celkem",
    "Confirm archiving": "Potvrdit archivaci",
    "Are you sure to archive this loading?": "Opravdu chcete archivovat tuto nakládku?",
    "Czech name official": "Český ofic. název",
    "English name official": "Anglický ofic. název",
    "Please enter an amount in KG to send to galvanisation": "Prosím uveďte množství v KG k odeslání do galvanovny",
    "Actions": "Akce",
    "Galvanisation": "Galvanizace",
    "Company type": "Typ společnosti",
    "Invoice type": "Typ faktury",
    "Invoice subtype": "Podtyp faktury",
    "Send to galvanisation": "Poslat do galvanovny",
    "Please enter an amount": "Zadejte množství",
    "Team": "Tým",
    "Read emails": "Číst emaily",
    "Supply lists templates": "Šablóny dodacích listů",
    "Supply lists": "Dodací listy",
    "Supply list number": "Č. dodacího listu",
    "Truck plate 1": "SPZ 1",
    "Truck plate 2": "SPZ 2",
    "Order number": "Č. objednávky",
    "Order date": "Datum objednávky",
    "Add supply list": "Přidat dodací list",
    "Invoice": "Faktura",
    "Add item": "Přidat položku",
    "Palette number": "Č. palety",
    "Add material": "Přidat prodej",
    "Save and create supply list": "Uložit a založit dodací listy",
    "Mass print": "Hromadný tisk",
    "Contract number": "Č. smlouvy",
    "Additional text": "Dodatečný text",
    "Store partially kg": "Částečně uskladnit kg",
    "Partial storing cancelled or invalid value": "Částečné uskladněni zrušeno nebo neplatná hodnota kg",
    "Delivery address": "Adresa dodání",
    "Invoices - additional items": "Dodatečné položky faktur",
    "Contracts": "Kontrakty",
    "Filled": "Splněno",
    "Company specific product types": "Názvy typů produktů pro jednotlivé společnosti",
    "Company specific names": "Speciální Názvy pro firmy",
    "Deadline": "Naplnit do",
    "Ordered quantity": "Objednané množství",
    "Supplied quantity": "Dodané množství",
    "Drivers": "Řídiče",
    "Passport number": "Č. pasu",
    "Loading lists": "Nákladní listy",
    "Loading lists templates": "Šablóny nákladních listů",
    "Templates supply/loading lists": "Šablóny dodací/nákladní listy",
    "Supply and loading lists templates": "Šablóny dodacích a nákladních listů",
    "Amount to pay": "Suma k platbě",
    "Driver": "Řídič",
    "Driver name": "Jméno řídiče",
    "Driver address": "Bydliště řídiče",
    "Driver passport": "Č. pasu řídiče",
	"Year": "Rok",
    "Month": "Měsíc",
    "Receipt number": "Č. dokladu o převzetí",
    "Receipt date": "Datum dokladu o převzetí",
    "Download": "!T! Download",
    "Uploaded photo": "!T! Uploaded photo",
    "Move to storage": "!T! Move to storage",
	"Mass printing": "Hromadný tisk",
	"Invoice": "Faktura",
	"Weighting": "Vážný lístek",
	"CMR": "CMR",
	"Other": "Ostatní",
	"Loadings for accountants": "Nakládky pro účetní",
	"Positive": "Pozitivní",
	"Expenses": "Vyučtování manažerů",
	"Cash expenses": "Hotovostní výdaje",
	"Expense types": "Typy výdajů",
	"Expenses columns": "Kolonky vyučtování manažerů",
	"Updated by": "Upravil",
	"Manager": "Manažer",
	"Amount": "Částka",
	"Select manager": "Vyberte menežera",
	"Expense type": "Typ výdaju",
	"Before period": "Na začátku",
	"During period": "Na konci",
	"Difference": "Rozdíl",
	"Add expense": "Přidat výdaj",
	"Final calculation": "Výpočet provize",
	"Provision pay": "Zaplatit provizi",
	"Weight netto": "Suma Netto",
	"Invoice pay": "Zaplaceno na fakturu",
	"Cash expenses pay": "Zaplaceno v hotovosti",
	"Expenses total": "Vydaje",
	"Provision date": "Provize zaplacena",
	"Provision": "Provize",
	"Not processed material": "Nezpracovaný materíál",
	"Production results": "Výsledek zpracování",
	"Plain": "Obecné",
	"Table": "Tabulka",
	"Manager provision": "Provize manažer",
	"Top manager provision": "Provize nadřízený manažer",
	"Top manager": "Nadřízený manažer",
	"Provise to": "Provize komu",
	"Cashier's report": "Report pokladnika",
	"Revenues": "Příjmy",
	"Cost": "Výdaje",
	"Balance to": "Zůstatek k",
	"Choose continent": "Vyberte kontinent",
	"Choose country": "Vyberte zemi",
	"Find the city": "Najít Město",
	"Find a company": "Najít firmu",
	"Invoice report": "Report faktur",
	"Speditions": "Spedice",
	"E-mail templates": "Šablony e-mailů",
	"Weighted": "Vážený",
	"Loadings expenses": "Vyučtování manažerů na nakladky",
	"Loadings xpenses total": "Vydaje na nakladky",
	"Archive": "Archiv",
	"All": "Vše",
	"Actual": "Aktuální",
	"Transportation pay": "Zaplaceno na dopravu",
	"Outinvoice item names": "Nazvy položek v. faktur",
	"Additional texts": "Dodatečné texty v.faktur",
	"Companies": "Společnosti",
	"Selected": "Vybráno",
	"Select all": "Vybrat vše",
	"Title": "Název",
	"VAT rate %": "Sazba DPH %",
	"Pre-invoice": "Zálohová faktura",
	"Post-invoice": "Daňový doklad",
	"Transferred VAT": "Přenesené DPH",
	"CNB Rate": "Kurz ČNB",
	"Contract number": "Č. smlouvy",
	"Outinvoice items": "Položky faktur",
	"Empty": "Prázdno",
	"Pay to": "Úhrada na účet",
	
	
	"Processed pcs.": "Zpracováno kusů",
	"Processed pcs.": "Processed pcs.",
	"Create supplylist": "Založit dodací list",
	"Sale": "Prodej",
	"Supplylist": "Dodací list",
	"Outinvoice number": "Číslo v. faktury"
};

export default messages;