"use strict";


import EventObjectT from "./EventObjectT.mjs";

import EventMapT from "./EventMapT.mjs";


const EventBucketT = class EventBucketT {

	#Map = null;
	
	constructor(
		EventObjectList
	){
	
		for(
			let I = 0;
			I < EventObjectList.length;
			I++
		){

			if( ( EventObjectList[ I ] instanceof EventObjectT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < EventObjectList.length;
			I++
		){

			this.#Map.set( EventObjectList[ I ].Name( ), EventObjectList[ I ].EventMap( ) )
		
		}
		
	}
	
	Set( 
		Name,
		EventMap
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( EventMap instanceof EventMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.set( 
			Name,
			EventMap
		);
		
	}
	
	Remove(
		Name
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.delete( Name );
	
	}
	
	Get(
		Name
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.get( Name );
	
	}
	
	IsExists(
		Name
	){
	
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Name );
	
	}
	
};


export default EventBucketT;