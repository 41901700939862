import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { confirmAlert } from 'react-confirm-alert'; // Import
interface Props {
    onAdd: (data) => void;
}

class MassEmail extends Component<Props> {

    constructor(props) {
        super(props)
        var my_email=JSON.parse(localStorage.getItem('my_user')).email;
        this.state = {

            allow_submit: false,
            message:this.podpis(),
            added_address:'',
            from_address:my_email,
            subject:'',
            to:'',

        };

    }

podpis() {
    var my_email=JSON.parse(localStorage.getItem('my_user')).email;
    var my_name=JSON.parse(localStorage.getItem('my_user')).name;
    var my_phone=JSON.parse(localStorage.getItem('my_user')).phone;
    if (my_phone!=='') {
        my_phone='Tel.: '+my_phone+'<br />';
    } else {
        my_phone='';
    }
    var my_position=JSON.parse(localStorage.getItem('my_user')).position;
    if (my_position!=='') {
        my_position=my_position+'<br />';
    } else {
        my_position='';
    }

    return '<br /><br /><div><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet"><img style="display:block; padding-top:16px; margin-bottom:16px" src="http://gtsdb.com/images/logo.png" /><div style="font-family: &#39;Open Sans&#39;, sans-serif; line-height:13px; font-size:9px; color:#2b3345"><strong>'+my_name+'</strong><br />'+my_position+my_phone+'<a href="https://www.gtsmetal.com" style="color:#2b3345; text-decoration: none">www.gtsmetal.com</a></div><img style="display:block; padding-top:16px;" src="http://gtsdb.com/images/bottom_logo.png" /></div>            ';

}
    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            var emails=[];
            var pattern = "^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$";
            this.props.addresses.map((e)=>{

                if (e==='') return false;
                var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                console.log(regexEmail.test(e));
                if (regexEmail.test(e)) emails.push(e);
            })
            var my_email=JSON.parse(localStorage.getItem('my_user')).email;
            this.setState({ message: '',
                message:this.podpis(),
                added_address: my_email,
                from_address: my_email,
                subject: '',
                to: emails.join(','),

                allow_submit:false});
        }
    }
    handleFormSubmit(event) {
        event.preventDefault();

        const fileInput = document.querySelector('#massemail_attachments') ;
        var files=fileInput.files;

        const { message, added_address, from_address, subject, to } = this.state;




        confirmAlert({
            title: this.props.t('Confirm to send'),
            message: this.props.t('Are you sure you want to send mass message?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.props.onAdd({ message, added_address, from_address, subject, to, files });

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }


    render() {
        var locale = 'cs';


        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        var tmce_loc=locale;
        if (tmce_loc=='en') tmce_loc='en';
var my_email=JSON.parse(localStorage.getItem('my_user')).email;

        const {message, added_address, from_address, subject, to } = this.state;


        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">{this.props.t('From')}</label>

                    <div className="col-sm-4">
                        <select
                            type="email"
                            placeholder={"jan.novak@gtsmetal.com"}
                            required
                            className="form-control"
                            value={from_address}
                            onChange={(event) => this.setState({ from_address: event.target.value })}
                        >
<option>{my_email}</option>
<option>info@gtsmetal.com</option>
<option>no-reply@gtsmetal.com</option>
                        </select>
                    </div>


                    <label className="col-sm-2 col-form-label">{this.props.t('Copy')}</label>

                    <div className="col-sm-4">
                        <input
                            type="email"
                            placeholder={"jan.novak@gtsmetal.com"}

                            className="form-control"
                            value={added_address}
                            onChange={(event) => this.setState({ added_address: event.target.value })}
                        />
                    </div>
                </div>

                <div className="form-group row">



                    <label className="col-sm-2 col-form-label">{this.props.t('To')}</label>

                    <div className="col-sm-10">
                        <input
                            type="text"
                            placeholder={this.props.t('To')}
                            required
                            className="form-control"
                            value={to}
                            onChange={(event) => this.setState({ to: event.target.value })}
                        />
                    </div>
                </div>

                <div className="form-group row">



                    <label className="col-sm-2 col-form-label">{this.props.t('Subject')}</label>

                    <div className="col-sm-10">
                        <input
                            type="text"
                            placeholder={this.props.t('Subject')}
                            required
                            className="form-control"
                            value={subject}
                            onChange={(event) => this.setState({ subject: event.target.value })}
                        />
                    </div>
                </div>



                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">{this.props.t('Message')}</label>
                    <div className="col-sm-10">

                        <Editor
                            apiKey="pqxxdqgsizy8axfavg7za32ixllf0k3skjsmfwfpq7so3yyr"
                            required className="form-control" onChange={(event) => {

                                this.setState({ message: event.target.getContent() })
                        }}
                            value={message}
                            init={{
                                language:tmce_loc,
                                height: 310,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic | \
                                    alignleft aligncenter alignright | \
                                    bullist numlist outdent indent | help'
                            }}
                        />



                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">{this.props.t('Attachments')}</label>
                    <div className="col-sm-5">
                        <input multiple id="massemail_attachments" name="massemail_attachments" type="file" />

                    </div>

                    <div className="col-sm-5">
                        <button type="submit" className="btn btn-danger">
                            {this.props.t('Submit for')+' '+this.state.to.split(',').length+' '+this.props.t('addresses')}
                        </button>
                    </div>

                </div>



            </form>
        );
    }
}
MassEmail.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(MassEmail);

