"use strict";


const APIValueT = class APIValueT {

	#Value = null;
	
	constructor(
		Value
	){
		
		if( typeof( Value ) === "undefined" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Value = Value;
		
	}
	
	static FromJSON(
		Value
	){
		
		if( typeof( Value ) === "undefined" ){
			
			throw new Error( "Invalid value" );
			
		}
		
		
		return new APIValueT(
			Value
		);
		
	}
	
	
	Value( ){
		
		return this.#Value;
		
	}
	
	ToJSON( 
	
	){
		
		return this.#Value;
		
	}
	
};


export default APIValueT;