

// ************** STATIC OPTIONS ***************

export const PDF_HEADER_COLOR = "#f8f8f8";
export const PDF_INNER_BORDER_COLOR = "#dde2eb";
export const PDF_OUTER_BORDER_COLOR = "#babfc7";
export const PDF_LOGO =
  "https://gtsdb.com/logo-bigger.png";

// ************** COLOR PICKER **************

// export let PDF_ODD_BKG_COLOR = "#03A9F4";
// export let PDF_EVEN_BKG_COLOR = "#E91E63";
export let PDF_ODD_BKG_COLOR = "#fcfcfc";
export let PDF_EVEN_BKG_COLOR = "#fff";

// ************** DYNAMIC OPTIONS ***************

export let PDF_PAGE_ORITENTATION= "landscape";
export let PDF_WITH_HEADER_IMAGE=true;
export let PDF_WITH_FOOTER_PAGE_COUNT=false;
export let PDF_HEADER_HEIGHT=25;
export let PDF_ROW_HEIGHT=15;
export let PDF_WITH_CELL_FORMATTING;
export let PDF_WITH_COLUMNS_AS_LINKS;
export let PDF_SELECTED_ROWS_ONLY;
