"use strict";


import ComponentLayoutVDynamicConfigT from "../Layout/ComponentLayoutVDynamicConfigT.mjs";

import ComponentLayoutElementConfigT from "../Layout/ComponentLayoutElementConfigT.mjs";


const ComponentCheckListConfigT = class ComponentCheckListConfigT extends ComponentLayoutVDynamicConfigT {
	
	#IsMultiple = null;
	#Value = null;
	#ComponentConfigChecked = null;
	#ComponentConfigUnchecked = null;
	#ComponentConfigHovered = null;
	
	constructor(
		IsMultiple,
		Value,
		ComponentConfigChecked,
		ComponentConfigUnchecked,
		ComponentConfigHovered,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){
		
		super(
			ComponentConfigUnchecked.Copy( ),
			ClassName,
			StyleMap,
			Name,
			Id
		);
		
		this.ComponentConfigCheckedSet( ComponentConfigChecked.Copy( ) );
		
		this.ComponentConfigUncheckedSet( ComponentConfigUnchecked.Copy( )  );
		
		this.ComponentConfigHoveredSet( ComponentConfigHovered.Copy( ) );
		
		
		this.IsMultipleSet( IsMultiple );
		
		this.ValueSet( Value );
		
	}
	
	Copy( ){
		
		return new ComponentCheckListConfigT(
			this.#IsMultiple,
			this.#Value,
			this.#ComponentConfigChecked.Copy( ),
			this.#ComponentConfigUnchecked.Copy( ),
			this.#ComponentConfigHovered.Copy( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	IsMultipleSet( 
		Value 
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#IsMultiple = Value;
		
	}
	
	IsMultipleGet(

	){
		
		return this.#IsMultiple;
		
	}
	
	ValueSet( 
		Value 
	){
		
		if( Value !== null ){
		
			if( typeof( Value ) !== "string" ){
				
				throw new Error( "Invalid argument type" );
				
			}
		
		}
		
		
		this.#Value = Value;
		
	}
	
	ValueGet(

	){
		
		return this.#Value;
		
	}
	
	ComponentConfigCheckedSet( 
		ComponentConfigChecked 
	){
		
		if( ( ComponentConfigChecked instanceof ComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentConfigChecked = ComponentConfigChecked;
		
	}
	
	ComponentConfigCheckedGet(

	){
		
		return this.#ComponentConfigChecked;
		
	}
	
	ComponentConfigUncheckedSet( 
		ComponentConfigUnchecked 
	){
		
		if( ( ComponentConfigUnchecked instanceof ComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentConfigUnchecked = ComponentConfigUnchecked;
		
	}
	
	ComponentConfigUncheckedGet(

	){
		
		return this.#ComponentConfigUnchecked;
		
	}
	
	ComponentConfigHoveredSet( 
		ComponentConfigHovered 
	){
		
		if( ( ComponentConfigHovered instanceof ComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentConfigHovered = ComponentConfigHovered;
		
	}
	
	ComponentConfigHoveredGet(

	){
		
		return this.#ComponentConfigHovered;
		
	}
				
};


export default ComponentCheckListConfigT;