"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutElementT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";


import UIComponentCheckListElementConfigT from "../../../../Type/UI/Component/CheckList/UIComponentCheckListElementConfigT.mjs";


const UIComponentCheckListElementT = class UIComponentCheckListElementT extends UIComponentLayoutElementT {

	constructor(
		UIElement,
		UIComponentCheckListElementConfig
	){
		
		super(
			UIElement,
			UIComponentCheckListElementConfig
		);
		
		
		let UIOption = UIComponentCheckListElementConfig.OptionGet( );
		
		let InnerText = UIOption.ValueGet( );
		
		
		super.InnerTextSet( InnerText );
		
	}
	
	#OnClick( Ev ){
		
		console.log( "UIComponentCheckListElementT.#OnClick", Ev );
		
		Ev.preventDefault( );
		
		Ev.stopPropagation( );
		
		
		if( super.EventExists( "Click" ) === true ){
		
			let OnClickFn = super.EventGet( "Click" );
			
			let UIComponentCheckListElementConfig = super.ConfigGet( );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( );
		
			OnClickFn( UIOption );
		
		}
		
	}
	
	#OnMouseEnter( Ev ){
		
		console.log( "UIComponentCheckListElementT.#MouseEnter", Ev );
		
		
		if( super.EventExists( "MouseEnter" ) === true ){
		
			let OnMouseEnterFn = super.EventGet( "MouseEnter" );
			
			let UIComponentCheckListElementConfig = super.ConfigGet( );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( );
		
			OnMouseEnterFn( UIOption );
		
		}
		
	}
	
	#OnMouseLeave( Ev ){
		
		console.log( "UIComponentCheckListElementT.#OnMouseLeave", Ev );
		
		
		let UIComponentCheckListElementConfig = super.ConfigGet( );
		
		
		if( super.EventExists( "MouseLeave" ) === true ){
		
			let OnMouseLeaveFn = super.EventGet( "MouseLeave" );
			
			let UIComponentCheckListElementConfig = super.ConfigGet( );
			
			let UIOption = UIComponentCheckListElementConfig.OptionGet( );
		
			OnMouseLeaveFn( UIOption );
		
		}
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextT.Start" );
		
		
		let UIElement = this.UIElement( );
		
		
		
		UIElement.EventSet( "click", this.#OnClick.bind( this ) );
		
		UIElement.EventSet( "mouseenter", this.#OnMouseEnter.bind( this ) );
		
		UIElement.EventSet( "mouseleave", this.#OnMouseLeave.bind( this ) );
		
	}
	
	Stop(
	
	){
		
		let UIElement = this.UIElement( );
		
		
		
		UIElement.EventRemove( "click" );
		
		UIElement.EventRemove( "mouseenter" );

		UIElement.EventRemove( "mouseleave" );
		
	}
	
	Listen(
		OnClickFn,
		OnMouseEnterFn,
		OnMouseLeaveFn
	){
		
		if( typeof( OnClickFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnMouseEnterFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnMouseLeaveFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.EventSet( "Click", OnClickFn );
		
		this.EventSet( "MouseEnter", OnMouseEnterFn );
		
		this.EventSet( "MouseLeave", OnMouseLeaveFn );
		
	}
	
	Unlisten( 
	
	){
		
		this.EventRemove( "Click" );
		
		this.EventRemove( "MouseEnter" );
		
		this.EventRemove( "MouseLeave" );
		
	}
	
};


export default UIComponentCheckListElementT;