
const InputBooleanSerializer = function( Value ){
	
	if( Value === true ){
		
		return Value;
		
	} else if( Value === false ) {
		
		return Value;
		
	} else {
		
		throw new Error( "Invalid value" );
		
	}
		
};


export default InputBooleanSerializer;