
const InputSelectTextBaseParser = function( Value ){
	
	if( Value === null ){
		
		return null;
		
	}
	
	return Value;
		
};


export default InputSelectTextBaseParser;