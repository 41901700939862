"use strict";


import TranslatorImporterT from "../../Infrastructure/Translator/TranslatorImporterT.mjs";

import TranslatorMapT from "../../Type/Translator/TranslatorMapT.mjs";


const TranslatorT = class TranslatorT {
	
	#TranslatorMap = null;

	constructor(
		TranslatorMap
	){
		
		if( ( TranslatorMap instanceof TranslatorMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#TranslatorMap = TranslatorMap;
		
	}
	
	static Import( 
		Lang
	){
		
		return new TranslatorT(
			TranslatorImporterT.Import( Lang )
		);
	
	}
	
	Translate( 
		Data
	){

		if( this.#TranslatorMap.IsExists( Data ) === false ){
			
			throw new Error( "Translation missing for: '" + Data + "'" );
			
		}
		
		
		return this.#TranslatorMap.Get( Data );
		
	}

};


export default TranslatorT;