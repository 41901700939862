"use strict";


import APIFieldT from "../../../../Field/APIFieldT.mjs";

import APIFieldListT from "../../../../Field/APIFieldListT.mjs";


const APICallRequestElementT = class APICallRequestElementT {
	
	#ModelName = null;
	#Command = null;
	#Out = null;
	#In = null;
	
	constructor(
		ModelName,
		Command,
		Out,
		In
	){
		
		if( ( ModelName instanceof APIFieldT ) === false ){
			
			throw new Error( "Invalid value" );
			
		}
		
		if( ( Command instanceof APIFieldT ) === false ){
			
			throw new Error( "Invalid value" );
			
		}
		
		if( ( Out instanceof APIFieldListT ) === false ){
			
			throw new Error( "Invalid value" );
			
		}
		
		if( ( In instanceof APIFieldListT ) === false ){
			
			throw new Error( "Invalid value" );
			
		}

		
		this.#ModelName = ModelName;
		
		this.#Command = Command;
		
		this.#Out = Out;
		
		this.#In = In;
		
	}
	
	ModelName(
		
	){
		
		return this.#ModelName;
		
	}
	
	Command(
		
	){
		
		return this.#Command;
		
	}
	
	Out(
		
	){
		
		return this.#Out;
		
	}
	
	In(
		
	){
		
		return this.#In;
		
	}
	
	ToJSON( 
	
	){
	
		return {
			ModelName: this.#ModelName.ToJSON( ),
			Command: this.#Command.ToJSON( ),
			Out: this.#Out.ToJSON( ),
			In: this.#In.ToJSON( )
		};
				
	}
	
};


export default APICallRequestElementT;