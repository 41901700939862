import React, {Component} from "react";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select'
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import $ from 'jquery';
import MainTableColumnDefs from "../../aggrid/MainTableColumnDefs";
import DeleteRenderer from "../../aggrid/DeleteRenderer";
import ActionsRenderer from "../../aggrid/ActionsRenderer";
import MultiSelectSimple from "../../aggrid/MultiSelectSimple";
import DateTimePicker from "../../aggrid/DateTimePicker";
import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData";
import FileRenderer from "../../aggrid/FileRenderer";
import invoicesStatusCount from "../../aggrid/invoicesStatusCount";
import DatePicker from "../../aggrid/DatePicker";
import { AgGridReact } from "ag-grid-react";
import {AllCommunityModules} from 'ag-grid-community';


interface Props {
  onAdd: (data) => void;
}

class InvoiceAdd extends Component<Props> {

  select_cols = ['invoice_incvat'];

  blankOption = {value: "", label: this.props.t('Choose')};

  companiesData = JSON.parse(localStorage.getItem('companies'));
  invoiceSubtypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_230')).data);
  countriesData = JSON.parse(localStorage.getItem('countries'));
  bankAccountsData = JSON.parse(localStorage.getItem('bank_accounts'));
  currenciesData = JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);
  invoiceTypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_155')).data);
  paymentMethodsData = JSON.parse(JSON.parse(localStorage.getItem('columns_136')).data);
  paymentTypesData = JSON.parse(JSON.parse(localStorage.getItem('columns_158')).data);

  blankState = {};

  constructor(props) {
  
    super(props)

    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

    var payment_type = '';
    var payment_type_id = 1;
    var company = this.props.company_id;
    var company_value = this.blankOption;
    var invoice_type = 3;
    if (this.props.type === 'loadings') invoice_type = 1;
    if (this.props.type === 'trips') invoice_type = 2;

    var invoice_type_value = this.blankOption;
    var payment_method = '';
    var payment_method_value = this.blankOption;
    var bank_account = '';
    var currency = '';
    var bank_name_1 = '';
    var bank_name_2 = '';
    var bank_address_1 = '';
    var bank_address_2 = '';
    var bank_country_code = '';
    var country_code = '';
    var beneficiary_name_1 = '';
    var beneficiary_name_2 = '';
    var beneficiary_address_1 = '';
    var beneficiary_address_2 = '';
    var bank_account_value = this.blankOption;
    var invoice_subtype_value = this.blankOption;
    var invoice_subtype = '';
    var account_name = '';
    var account_number = '';
    var iban = '';
    var bic_swift = '';
    var bank_code = '';
    var variable_symbol = '';
    var constant_symbol = '';
    var specific_symbol = '';
    var invoice_excvat = '';
    var invoice_vat = 0.00;
    var invoice_incvat = 0.00;
    var invoice_topay = 0.00;
    var payer_note = '';
    if (this.props.type === 'loadings') payer_note = "N ";
    if (this.props.type === 'trips') payer_note = "T ";

    if (this.props.type === 'loadings' || this.props.type === 'trips') {
      this.props.rownodes.forEach((e) => {
        if (e.invoice_excvat == null) e.invoice_excvat = 0;
        if (e.invoice_incvat == null) e.invoice_incvat = 0;
        if (e.invoice_topay == null) e.invoice_topay = 0;
        if (e.invoice_vat == null) e.invoice_vat = 0;
        invoice_excvat = invoice_excvat + parseFloat(e.invoice_excvat);
		if(invoice_excvat == 0){
			invoice_excvat = "";
		}
        invoice_incvat = invoice_incvat + parseFloat(e.invoice_incvat);
        invoice_topay = invoice_topay + parseFloat(e.invoice_topay);
        invoice_vat = invoice_vat + parseFloat(e.invoice_vat);
        if (this.props.type === 'loadings') payer_note += e.loading_number + ",";
        if (this.props.type === 'trips') payer_note += e.id + ",";
      });
    }
    payer_note = payer_note.slice(0, -1).substring(0, 16);


    if (this.props.fromtab) {

      if (this.props.rownode && this.props.rownode.data) {

        if (this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type) && this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale]) {
          payment_type = this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale];
          payment_type_id = this.props.rownode.data.payment_type;
        }

        if (this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency) && this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale]) {
          currency = this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale];
        }

        if (this.props.rownode.data.bank_name_1) bank_name_1 = this.props.rownode.data.bank_name_1;
        if (this.props.rownode.data.bank_name_2) bank_name_2 = this.props.rownode.data.bank_name_2;
        if (this.props.rownode.data.bank_address_1) bank_address_1 = this.props.rownode.data.bank_address_1;
        if (this.props.rownode.data.bank_address_2) bank_address_2 = this.props.rownode.data.bank_address_2;

        if (this.props.rownode.data.beneficiary_name_1) beneficiary_name_1 = this.props.rownode.data.beneficiary_name_1;
        if (this.props.rownode.data.beneficiary_name_2) beneficiary_name_2 = this.props.rownode.data.beneficiary_name_2;
        if (this.props.rownode.data.beneficiary_address_1) beneficiary_address_1 = this.props.rownode.data.beneficiary_address_1;
        if (this.props.rownode.data.beneficiary_address_2) beneficiary_address_2 = this.props.rownode.data.beneficiary_address_2;

        if (this.props.rownode.data.country !== null) country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.country)['code2'];
        if (this.props.rownode.data.bank_country !== null) bank_country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.bank_country)['code2'];


        if (this.props.rownode.data.account_number) account_number = this.props.rownode.data.account_number;
        if (this.props.rownode.data.iban) iban = this.props.rownode.data.iban;
        if (this.props.rownode.data.bic_swift) bic_swift = this.props.rownode.data.bic_swift;
        if (this.props.rownode.data.bank_code) bank_code = this.props.rownode.data.bank_code;


      }


      company = this.props.company_id;
      company_value = this.blankOption;


      for (var i = 0; i < this.companiesData.length; i++) {
        if (this.companiesData[i].id === this.props.company_id) {
          company_value = {value: this.companiesData[i].id, label: this.companiesData[i].name};
          break;
        }
      }

    }


    for (var i = 0; i < this.invoiceTypesData.length; i++) {

      if (this.invoiceTypesData[i].id == invoice_type) {
        invoice_type_value = {value: this.invoiceTypesData[i].id, label: this.invoiceTypesData[i]['name_' + locale]};
        break;
      }
    }


    this.state = {
      last_invoices: [],

      loadings: "",
      loadingsSelected: [],
      company: company,
      company_value: company_value,
      pay_from: bank_account,
      pay_from_value: bank_account_value,
      payment_type: payment_type,
      payment_method: payment_method,
      payment_method_value: payment_method_value,
      company_name: "",
      invoice_type: invoice_type,
      invoice_type_value: invoice_type_value,
      bank_name_1: bank_name_1,
      bank_name_2: bank_name_2,
      bank_address_1: bank_address_1,
      bank_address_2: bank_address_2,
      bank_country_code: bank_country_code,
      country_code: country_code,
      beneficiary_name_1: beneficiary_name_1,
      beneficiary_name_2: beneficiary_name_2,
      beneficiary_address_1: beneficiary_address_1,
      beneficiary_address_2: beneficiary_address_2,

      invoice_subtype_value: invoice_subtype_value,
      invoice_subtype: invoice_subtype,

      invoice_subtypes: this.invoiceSubtypesData,


      currency: currency,
      account_name: account_name,
      account_number: account_number,
      iban: iban,
      bic_swift: bic_swift,
      bank_code: bank_code,
      payment_type_id: payment_type_id,
      variable_symbol: variable_symbol,
      constant_symbol: constant_symbol,
      specific_symbol: specific_symbol,


      invoice_excvat: invoice_excvat,
      invoice_vat: invoice_vat,
      invoice_incvat: invoice_incvat,
      invoice_topay: invoice_topay,

      invoice_issue: this.today(),
      invoice_due: this.today(),
      invoice_taxable: this.today(),
      paid_date: this.today(),

      invoice_number: '',
      note: '',
      payer_note: payer_note,
      beneficiary_note: 'GTS',


      allow_submit: false,
	  forSpeditionSet: false,
	  loadingsArray: [],
	  waiting: false,
	  
	  hideDisabled: true
    };


  }

  today() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return dd + '.' + mm + '.' + yyyy;
  }
  
  componentDidMount(){
  
	if( this.state.loadingsArray.length === 0 ){

	  HttpService.get(UrlService.apiDomain() + 'api/invoices_get_loadings')
		.then(res => {


		  const optionsArray = [];
		  
		  res.data.map((e) => {
			optionsArray.push({value: e.id, label: e.name});
		  });

		  this.setState({loadingsArray: optionsArray, loadingsSelected: [], loadings: ''});

		});
	
	}
  
  }

  componentDidUpdate(prevProps) {
  
    console.log("component did update invoice add", prevProps, this.props);
	
	let permissions = JSON.parse(localStorage.getItem('my_permissions'));
	
	let speditionPermission = false;
	
	if (permissions.includes('loadings.spedition')){
	
		speditionPermission = true;
	
	}
	
	if( prevProps.open_modal != this.props.open_modal ){	
		
		if( speditionPermission == true ){
		
			if( this.props.open_modal == true ){

				let invoice_type = 2;
				let invoice_type_value = {value: 2, label: "For transportation"};
				
				let company = 25930;
				let company_value = {value: 25930, label: "Hátle Miroslav s.r.o."};
				
				let invoice_subtype = 2;
				let invoice_subtype_value = {value: 2, label: "Transportation"};

				
				this.setState( {
					invoice_type: invoice_type,
					invoice_type_value: invoice_type_value,
					invoice_subtype_value: invoice_subtype_value,
					invoice_subtype: invoice_subtype,
					company: company,
					company_value: company_value,
					forSpeditionSet: true 
				} );
				
				this.handleChange( 
					invoice_type_value, 
					'invoice_type', 
					'select' 
				);
				
				this.handleChange( 
					company_value, 
					'company', 
					'select' 
				);
				
				this.handleChange( 
					invoice_subtype_value, 
					'invoice_subtype', 
					'select' 
				);
				
				
				//return;
			
			} else {
			
				let payment_type = '';
				let payment_type_id = 1;
				let company = this.props.company_id;
				let company_value = this.blankOption;
				let invoice_type = 3;
				if (this.props.type === 'loadings') invoice_type = 1;
				if (this.props.type === 'trips') invoice_type = 2;

				let invoice_type_value = this.blankOption;
				let payment_method = '';
				let payment_method_value = this.blankOption;
				let bank_account = '';
				let currency = '';
				let bank_name_1 = '';
				let bank_name_2 = '';
				let bank_address_1 = '';
				let bank_address_2 = '';
				let bank_country_code = '';
				let country_code = '';
				let beneficiary_name_1 = '';
				let beneficiary_name_2 = '';
				let beneficiary_address_1 = '';
				let beneficiary_address_2 = '';
				let bank_account_value = this.blankOption;
				let invoice_subtype_value = this.blankOption;
				let invoice_subtype = '';
				let account_name = '';
				let account_number = '';
				let iban = '';
				let bic_swift = '';
				let bank_code = '';
				let variable_symbol = '';
				let constant_symbol = '';
				let specific_symbol = '';
				let invoice_excvat = 0.00;
				let invoice_vat = 0.00;
				let invoice_incvat = 0.00;
				let invoice_topay = 0.00;
				let payer_note = '';
				if (this.props.type === 'loadings') payer_note = "N ";
				if (this.props.type === 'trips') payer_note = "T ";

				if (this.props.type === 'loadings' || this.props.type === 'trips') {
				  this.props.rownodes.forEach((e) => {
					if (e.invoice_excvat == null) e.invoice_excvat = 0;
					if (e.invoice_incvat == null) e.invoice_incvat = 0;
					if (e.invoice_topay == null) e.invoice_topay = 0;
					if (e.invoice_vat == null) e.invoice_vat = 0;
					invoice_excvat = invoice_excvat + parseFloat(e.invoice_excvat);
					if(invoice_excvat == 0){
						invoice_excvat = "";
					}
					invoice_incvat = invoice_incvat + parseFloat(e.invoice_incvat);
					invoice_topay = invoice_topay + parseFloat(e.invoice_topay);
					invoice_vat = invoice_vat + parseFloat(e.invoice_vat);
					if (this.props.type === 'loadings') payer_note += e.loading_number + ",";
					if (this.props.type === 'trips') payer_note += e.id + ",";
				  });
				} else {
					if(invoice_excvat == 0){
						invoice_excvat = "";
					}
				}
				payer_note = payer_note.slice(0, -1).substring(0, 16);


				if (this.props.fromtab) {

				  if (this.props.rownode && this.props.rownode.data) {

					if (this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type) && this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale]) {
					  payment_type = this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale];
					  payment_type_id = this.props.rownode.data.payment_type;
					}

					if (this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency) && this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale]) {
					  currency = this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale];
					}

					if (this.props.rownode.data.bank_name_1) bank_name_1 = this.props.rownode.data.bank_name_1;
					if (this.props.rownode.data.bank_name_2) bank_name_2 = this.props.rownode.data.bank_name_2;
					if (this.props.rownode.data.bank_address_1) bank_address_1 = this.props.rownode.data.bank_address_1;
					if (this.props.rownode.data.bank_address_2) bank_address_2 = this.props.rownode.data.bank_address_2;

					if (this.props.rownode.data.beneficiary_name_1) beneficiary_name_1 = this.props.rownode.data.beneficiary_name_1;
					if (this.props.rownode.data.beneficiary_name_2) beneficiary_name_2 = this.props.rownode.data.beneficiary_name_2;
					if (this.props.rownode.data.beneficiary_address_1) beneficiary_address_1 = this.props.rownode.data.beneficiary_address_1;
					if (this.props.rownode.data.beneficiary_address_2) beneficiary_address_2 = this.props.rownode.data.beneficiary_address_2;

					if (this.props.rownode.data.country !== null) country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.country)['code2'];
					if (this.props.rownode.data.bank_country !== null) bank_country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.bank_country)['code2'];


					if (this.props.rownode.data.account_number) account_number = this.props.rownode.data.account_number;
					if (this.props.rownode.data.iban) iban = this.props.rownode.data.iban;
					if (this.props.rownode.data.bic_swift) bic_swift = this.props.rownode.data.bic_swift;
					if (this.props.rownode.data.bank_code) bank_code = this.props.rownode.data.bank_code;


				  }


				  company = this.props.company_id;
				  company_value = this.blankOption;


				  for (let i = 0; i < this.companiesData.length; i++) {
					if (this.companiesData[i].id === this.props.company_id) {
					  company_value = {value: this.companiesData[i].id, label: this.companiesData[i].name};
					  break;
					}
				  }

				}


				for (let i = 0; i < this.invoiceTypesData.length; i++) {

				  if (this.invoiceTypesData[i].id == invoice_type) {
					invoice_type_value = {value: this.invoiceTypesData[i].id, label: this.invoiceTypesData[i]['name_' + locale]};
					break;
				  }
				}


				this.setState( {
				  last_invoices: [],

				  loadings: "",
				  loadingsSelected: [],
				  company: company,
				  company_value: company_value,
				  pay_from: bank_account,
				  pay_from_value: bank_account_value,
				  payment_type: payment_type,
				  payment_method: payment_method,
				  payment_method_value: payment_method_value,
				  company_name: "",
				  invoice_type: invoice_type,
				  invoice_type_value: invoice_type_value,
				  bank_name_1: bank_name_1,
				  bank_name_2: bank_name_2,
				  bank_address_1: bank_address_1,
				  bank_address_2: bank_address_2,
				  bank_country_code: bank_country_code,
				  country_code: country_code,
				  beneficiary_name_1: beneficiary_name_1,
				  beneficiary_name_2: beneficiary_name_2,
				  beneficiary_address_1: beneficiary_address_1,
				  beneficiary_address_2: beneficiary_address_2,

				  invoice_subtype_value: invoice_subtype_value,
				  invoice_subtype: invoice_subtype,

				  invoice_subtypes: this.invoiceSubtypesData,


				  currency: currency,
				  account_name: account_name,
				  account_number: account_number,
				  iban: iban,
				  bic_swift: bic_swift,
				  bank_code: bank_code,
				  payment_type_id: payment_type_id,
				  variable_symbol: variable_symbol,
				  constant_symbol: constant_symbol,
				  specific_symbol: specific_symbol,


				  invoice_excvat: invoice_excvat,
				  invoice_vat: invoice_vat,
				  invoice_incvat: invoice_incvat,
				  invoice_topay: invoice_topay,

				  invoice_issue: this.today(),
				  invoice_due: this.today(),
				  invoice_taxable: this.today(),
				  paid_date: this.today(),

				  invoice_number: '',
				  note: '',
				  payer_note: payer_note,
				  beneficiary_note: 'GTS',


				  allow_submit: false,
				  forSpeditionSet: false
				} );
				
				$('#invoice_file').val('');
				
				//return;
			
			}
		
		}
	
	}
    
    if ( prevProps.tmp !== this.props.tmp && this.props.row_data == null && this.props.open_modal === true ) {

		  HttpService.get(UrlService.apiDomain() + 'api/invoices_get_loadings')
			.then(res => {


			  const loadingsSelected = [];
			  const optionsArray = [];

			  var loadings_ids = [];

			  if (this.props.type === 'loadings') {
				this.props.rownodes.map((e) => {
				  loadings_ids.push(e.loading_number);
				});
			  }

			  var val = '';
			  res.data.map((e) => {
				optionsArray.push({value: e.id, label: e.name});
				if (loadings_ids.includes(e.id) && this.props.type === 'loadings') {
				  loadingsSelected.push({value: e.id, label: e.name});
				  val += e.id + '//';
				}

			  });

			  this.setState({loadingsArray: optionsArray, loadingsSelected: loadingsSelected, loadings: val.slice(0, -2)});

			});

		if( speditionPermission == false && this.props.row_data == null ){
	
      var locale = 'cs';
      if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

      var payment_type = '';
      var payment_type_id = 1;
      var company = this.props.company_id;
      var company_value = this.blankOption;
      var invoice_type = 3;
      if (this.props.type === 'loadings') invoice_type = 1;
      if (this.props.type === 'trips') invoice_type = 2;
      var invoice_type_value = this.blankOption;
      var payment_method = '';
      var payment_method_value = this.blankOption;
      var bank_account = '';
      var currency = '';
      var bank_name_1 = '';
      var bank_name_2 = '';
      var bank_address_1 = '';
      var bank_address_2 = '';
      var bank_country_code = '';
      var country_code = '';
      var beneficiary_name_1 = '';
      var beneficiary_name_2 = '';
      var beneficiary_address_1 = '';
      var beneficiary_address_2 = '';
      var bank_account_value = this.blankOption;
      var account_name = '';
      var account_number = '';
      var iban = '';
      var bic_swift = '';
      var bank_code = '';
      var variable_symbol = '';
      var constant_symbol = '';
      var specific_symbol = '';
      var invoice_excvat = 0.00;
      var invoice_vat = 0.00;
      var invoice_incvat = 0.00;
      var invoice_topay = 0.00;
      var invoice_subtype_value = this.blankOption;
      var invoice_subtype = '';

      var payer_note = '';
      if (this.props.type === 'loadings') payer_note = "N ";
      if (this.props.type === 'trips') payer_note = "T ";

      if (this.props.type === 'loadings' || this.props.type === 'trips') {
        this.props.rownodes.forEach((e) => {
          if (e.invoice_excvat == null) e.invoice_excvat = 0;
          if (e.invoice_incvat == null) e.invoice_incvat = 0;
          if (e.invoice_topay == null) e.invoice_topay = 0;
          if (e.invoice_vat == null) e.invoice_vat = 0;
          //invoice_excvat=invoice_excvat+parseFloat(e.invoice_excvat);
          //invoice_incvat=invoice_incvat+parseFloat(e.invoice_incvat);
          //invoice_vat=invoice_vat+parseFloat(e.invoice_vat);
          if (this.props.type === 'loadings') payer_note += e.loading_number + ",";
          if (this.props.type === 'trips') payer_note += e.id + ",";
        });
      } else {
		if(invoice_excvat == 0){
			invoice_excvat = "";
		}
	  }
      payer_note = payer_note.slice(0, -1).substring(0, 16);

      if (this.props.fromtab) {

        if (this.props.rownode && this.props.rownode.data) {

          if (this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type) && this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale]) {
            payment_type = this.paymentTypesData.find(refData => refData['id'] == this.props.rownode.data.payment_type)['name_' + locale];
            payment_type_id = this.props.rownode.data.payment_type;
          }

          if (this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency) && this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale]) {
            currency = this.currenciesData.find(refData => refData['id'] == this.props.rownode.data.currency)['name_' + locale];

          }

          if (this.props.rownode.data.bank_name_1) bank_name_1 = this.props.rownode.data.bank_name_1;
          if (this.props.rownode.data.bank_name_2) bank_name_2 = this.props.rownode.data.bank_name_2;
          if (this.props.rownode.data.bank_address_1) bank_address_1 = this.props.rownode.data.bank_address_1;
          if (this.props.rownode.data.bank_address_2) bank_address_2 = this.props.rownode.data.bank_address_2;

          if (this.props.rownode.data.beneficiary_name_1) beneficiary_name_1 = this.props.rownode.data.beneficiary_name_1;
          if (this.props.rownode.data.beneficiary_name_1) payer_note = this.props.rownode.data.beneficiary_name_1;
          if (this.props.rownode.data.beneficiary_name_2) beneficiary_name_2 = this.props.rownode.data.beneficiary_name_2;
          if (this.props.rownode.data.beneficiary_address_1) beneficiary_address_1 = this.props.rownode.data.beneficiary_address_1;
          if (this.props.rownode.data.beneficiary_address_2) beneficiary_address_2 = this.props.rownode.data.beneficiary_address_2;

          if (this.props.rownode.data.country !== null) country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.country)['code2'];
          if (this.props.rownode.data.bank_country !== null) bank_country_code = this.countriesData.find(refData => refData['id'] == this.props.rownode.data.bank_country)['code2'];


          if (this.props.rownode.data.account_number) account_number = this.props.rownode.data.account_number;
          if (this.props.rownode.data.iban) iban = this.props.rownode.data.iban;
          if (this.props.rownode.data.bic_swift) bic_swift = this.props.rownode.data.bic_swift;
          if (this.props.rownode.data.bank_code) bank_code = this.props.rownode.data.bank_code;


        }


        company = this.props.company_id;
        company_value = this.blankOption;


		for (var i = 0; i < this.companiesData.length; i++) {
		
			console.log("company", this.companiesData[i] );
			
		}

        for (var i = 0; i < this.companiesData.length; i++) {
          if (this.companiesData[i].id === this.props.company_id) {
            company_value = {value: this.companiesData[i].id, label: this.companiesData[i].name};
            break;
          }
        }

      }


		for (var i = 0; i < this.invoiceTypesData.length; i++) {
		
			console.log("invoice_type", this.invoiceTypesData[i] );
			
		}

      for (var i = 0; i < this.invoiceTypesData.length; i++) {
        if (this.invoiceTypesData[i].id == invoice_type) {
			
			
		
          invoice_type_value = {value: this.invoiceTypesData[i].id, label: this.invoiceTypesData[i]['name_' + locale]};
          break;
        }
      }

      $('#invoice_file').val('');

      this.setState({
        loadingsSelected: [],
        loadings: "",
        company: company,
        company_value: company_value,
        pay_from: bank_account,
        pay_from_value: bank_account_value,
        payment_type: payment_type,
        payment_method: payment_method,
        payment_method_value: payment_method_value,
        company_name: "",
        invoice_type: invoice_type,
        invoice_type_value: invoice_type_value,
        bank_name_1: bank_name_1,
        bank_name_2: bank_name_2,
        bank_address_1: bank_address_1,
        bank_address_2: bank_address_2,
        bank_country_code: bank_country_code,
        country_code: country_code,
        beneficiary_name_1: beneficiary_name_1,
        beneficiary_name_2: beneficiary_name_2,
        beneficiary_address_1: beneficiary_address_1,
        beneficiary_address_2: beneficiary_address_2,


        invoice_subtype_value: invoice_subtype_value,
        invoice_subtype: invoice_subtype,
        invoice_subtypes: this.invoiceSubtypesData,

        currency: currency,

        account_name: account_name,
        account_number: account_number,
        iban: iban,
        bic_swift: bic_swift,
        bank_code: bank_code,
        variable_symbol: variable_symbol,
        constant_symbol: constant_symbol,
        specific_symbol: specific_symbol,


        payment_type_id: payment_type_id,

        invoice_excvat: invoice_excvat,
        invoice_vat: invoice_vat,
        invoice_incvat: invoice_incvat,
        invoice_topay: invoice_topay,

        invoice_issue: this.today(),
        invoice_due: this.today(),
        invoice_taxable: this.today(),
        paid_date: this.today(),

        invoice_number: '',
        note: '',
        payer_note: payer_note,
        beneficiary_note: 'GTS',


        allow_submit: false,
      });
	  
		}


    }
	
	if( prevProps.waiting != this.props.waiting ){
		
		this.setState( {
			waiting: this.props.waiting
		} );
		
	}
	
	if( prevProps.open_modal != this.props.open_modal ){	
	
		if( this.props.row_data != null && this.props.open_modal === true ){
				
			console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
		
			this.setState( {
				account_number: this.props.row_data.data[ "account_number" ],
				bank_address_1: this.props.row_data.data[ "bank_address_1" ],
				bank_address_2: this.props.row_data.data[ "bank_address_2" ],
				bank_code: this.props.row_data.data[ "bank_code" ],
				bank_country_code: this.props.row_data.data[ "bank_country_code" ],
				bank_name_1: this.props.row_data.data[ "bank_name_1" ],
				bank_name_2: this.props.row_data.data[ "bank_name_2" ],
				beneficiary_address_1: this.props.row_data.data[ "beneficiary_address_1" ],
				beneficiary_address_2: this.props.row_data.data[ "beneficiary_address_2" ],
				
				beneficiary_name_1: this.props.row_data.data[ "beneficiary_name_1" ],
				beneficiary_name_2: this.props.row_data.data[ "beneficiary_name_2" ],
				beneficiary_note: this.props.row_data.data[ "beneficiary_note" ],
				bic_swift: this.props.row_data.data[ "bic_swift" ],
				//company: this.props.row_data.data[ "company" ],
				company_type: this.props.row_data.data[ "company_type" ],
				constant_symbol: this.props.row_data.data[ "constant_symbol" ],
				country_code: this.props.row_data.data[ "country_code" ],
				currency: this.props.row_data.data[ "currency" ],
				
				
				iban: this.props.row_data.data[ "iban" ],
				invoice_due: this.props.row_data.data[ "invoice_due" ],
				invoice_excvat: this.props.row_data.data[ "invoice_excvat" ],
				//invoice_file: this.props.row_data.data[ "invoice_file" ],
				//invoice_rate
				//wiretransfer
				invoice_incvat: this.props.row_data.data[ "invoice_incvat" ],
				invoice_issue: this.props.row_data.data[ "invoice_issue" ],
				invoice_number: this.props.row_data.data[ "invoice_number" ],
				//invoice_subtype: this.props.row_data.data[ "invoice_subtype" ],
				invoice_taxable: this.props.row_data.data[ "invoice_taxable" ],
				invoice_topay: this.props.row_data.data[ "invoice_topay" ],
				
				//invoice_type: this.props.row_data.data[ "invoice_type" ],
				invoice_vat: this.props.row_data.data[ "invoice_vat" ],
				//loadings: this.props.row_data.data[ "loadings" ],
				note: this.props.row_data.data[ "note" ],
				paid_date: this.props.row_data.data[ "paid_date" ],
				//pay_from: this.props.row_data.data[ "pay_from" ],
				payer_note: this.props.row_data.data[ "payer_note" ],
				payment_method: this.props.row_data.data[ "payment_method" ],
				payment_method_type: this.props.row_data.data[ "payment_method_type" ],
				
				payment_type: this.props.row_data.data[ "payment_type" ],
				specific_symbol: this.props.row_data.data[ "specific_symbol" ],
				variable_symbol: this.props.row_data.data[ "variable_symbol" ],
				payment_method_type: this.props.row_data.data[ "payment_method_type" ]
				
			} );
			
			var locale = 'cs';
			if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
			
			let company_value = null;
			
			for (let i = 0; i < this.companiesData.length; i++) {
				if (this.props.row_data.data[ "company" ] && this.companiesData[i].id.toString() === this.props.row_data.data[ "company" ].toString()) {
					company_value = {value: this.companiesData[i].id, label: this.companiesData[i].name};
					break;
				}
			}
			
			if( company_value !== null ){
			
				this.handleChange( company_value, 'company', 'select' );
			
			}
			
			
			let invoice_type_value = null;
			
			for (var i = 0; i < this.invoiceTypesData.length; i++) {

			  if (this.props.row_data.data[ "invoice_type" ] && this.invoiceTypesData[i].id.toString() == this.props.row_data.data[ "invoice_type" ].toString()) {
				invoice_type_value = {value: this.invoiceTypesData[i].id, label: this.invoiceTypesData[i]['name_' + locale]};
				break;
			  }
			}
			
			if( invoice_type_value !== null ){
			
				this.handleChange( invoice_type_value, 'invoice_type', 'select' );
				
			}
			
			
			let invoice_subtype_value = null;
			
			if( this.invoiceSubtypesData ){
			
				this.invoiceSubtypesData.map((i) => {
					if ( this.props.row_data.data[ "invoice_subtype" ] && i.id.toString() == this.props.row_data.data[ "invoice_subtype" ].toString()) invoice_subtype_value = { value: i.id, label: i['name_' + locale]}
				} );
			
			}
			
			if( invoice_subtype_value ){
			
				this.handleChange( invoice_subtype_value, 'invoice_subtype', 'select' );
			
			}
			
			
			let payment_method_value = null;
			
			this.paymentMethodsData.map((i) => {
              if ( this.props.row_data.data[ "payment_method" ] && i.id.toString() == this.props.row_data.data[ "payment_method" ].toString()) payment_method_value = {value: i.id, label: i['name_' + locale]}
            })
			
			if( payment_method_value !== null ){
				
				this.handleChange( payment_method_value, 'payment_method', 'select' );
			
			}
			
			
			let payment_from_value = null;
			
			this.bankAccountsData.map((i) => {
				if ( this.props.row_data.data[ "pay_from" ] && i.id.toString() == this.props.row_data.data[ "pay_from" ].toString()) payment_from_value = {value: i.id, label: i['account_name']};
			});
			
			if( payment_from_value !== null ){
			
				this.handleChange( payment_from_value, 'pay_from', 'select');
				
			}
			
			
			if( this.props.row_data.data[ "loadings" ] ){
			
				let Ids = this.props.row_data.data[ "loadings" ].split( "//" );
				
				console.log( "ids", Ids );
				
				let Res = [ ];
			
				for(
					let i = 0;
					i < this.state.loadingsArray.length;
					i++
				){
				
					if( Ids.includes( this.state.loadingsArray[ i ].value.toString( ) ) ){
					
						Res.push( this.state.loadingsArray[ i ] );
					
					}
				
				}
				
				console.log( Res );
			
				this.multiselectOnChange( Res );
			
			} else {
			
				this.multiselectOnChange( [ ] );
				
			}
			
		}
	}
	
  }


  handleFormSubmit(event) {
	
	console.log( "handleFormSubmit" );
  
    event.preventDefault();

    const {
      invoice_type,
      invoice_subtype,
      company,
      payment_method,
      pay_from,
      payment_type,
      payment_type_id,
      bank_name,
      currency,
      account_name,
      account_number,
      iban,
      bic_swift,
      bank_code,
      invoice_excvat,
      invoice_vat,
      invoice_incvat,
      invoice_topay,
      invoice_issue,
      invoice_due,
      invoice_taxable,
      paid_date,
      invoice_number,
      note,
      payer_note,
      beneficiary_note,
      variable_symbol,
      constant_symbol,
      specific_symbol,
      loadings
    } = this.state;


    const fileInput = document.querySelector('#invoice_file');
    var files = fileInput.files;

    if (files.length === 0) {
      alert(this.props.t('Prosím, nahrajte fakturu'));
	  this.setState( { waiting: false } );
      return false;
    }

    let files_ok = true;
    let allowed_exts = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'pdf'];


    Array.from(files).forEach(e => {
      if (!allowed_exts.includes(e.name.split('.')[e.name.split('.').length - 1].toLowerCase())) files_ok = false;
    });


    if (!files_ok) {
      alert(this.props.t('Špatný formát skenu faktury'));
	  this.setState( { waiting: false } );
      return false;
    }


    var type = this.props.type;
    var ids = [];
    if (type === 'loadings') {

      this.props.rownodes.forEach((e) => {
        ids.push(e.loading_number);

      });
    }

    if (type === 'trips') {

      this.props.rownodes.forEach((e) => {
        ids.push(e.id);

      });
    }

    this.props.onAdd({
      type,
      ids,
      invoice_type,
      invoice_subtype,
      company,
      payment_method,
      pay_from,
      payment_type,
      payment_type_id,
      bank_name,
      currency,
      account_name,
      account_number,
      iban,
      bic_swift,
      bank_code,
      invoice_excvat,
      invoice_vat,
      invoice_incvat,
      invoice_topay,
      invoice_issue,
      invoice_due,
      invoice_taxable,
      paid_date,
      invoice_number,
      note,
      payer_note,
      beneficiary_note,
      variable_symbol,
      constant_symbol,
      specific_symbol,
      loadings,
      files
    });

  }

  allow_submit() {
    console.log("allow_submit", this.state);
    var allow_submit = true;


    if (this.state.company == '' ||
      this.state.payment_method == '' ||
      (this.state.payment_method != 1 && this.state.payment_type == '') ||
      this.state.currency == '' ||
      this.state.invoice_excvat == 0 ||
      this.state.invoice_incvat == 0 ||
      this.state.invoice_topay == 0 ||
      this.state.invoice_issue == '' ||
      this.state.invoice_due == '' ||
      this.state.invoice_taxable == '' ||
      this.state.invoice_number == '') allow_submit = false;

    if (this.state.payment_method != 1) {
      if (this.state.payment_type == 1 && (this.state.account_number == '' || this.state.bank_code == '')) allow_submit = false;
      if ((this.state.payment_type_id == 2 || this.state.payment_type_id == 3) &&
        (
			//this.state.account_name == '' ||
          this.state.bank_name_1 == '' ||
          this.state.iban == '' ||
          this.state.bank_address_1 == '' ||
          this.state.bank_country_code == '' ||
          this.state.country_code == '' ||
          this.state.beneficiary_name_1 == '' ||
          this.state.beneficiary_address_1 == '' ||

          this.state.bic_swift == '')) allow_submit = false;
    }


    // if($('#invoice_file').val()=='') allow_submit=false;


    this.setState({allow_submit: allow_submit});
  }


  handleChange(event, type, inputType) {
  
	console.log( "change", type, inputType, event );

    var stateValue = {};


    if (inputType === 'select') {
      stateValue[type] = event.value;
      stateValue[type + '_value'] = event;
    }

    if (inputType === 'multiselect') {

      if (event != null) {


        var val = '';
        event.map((i) => val += i.value + '//');


        stateValue[type] = val.slice(0, -2)

      }


    }

    if (inputType === 'text') {
      stateValue[type] = event.target.value;

      if (type == 'payer_note') {
        stateValue[type] = event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 16).replace(/[^a-z0-9 ]/gi, '');
      }

      if (type == 'beneficiary_note') {
        stateValue[type] = event.target.value.replace(/[^\x00-\x7F]/g, "").toUpperCase().substring(0, 25).replace(/[^a-z0-9 ]/gi, '');
      }

      if (type == 'specific_symbol' || type == 'variable_symbol') {
        stateValue[type] = event.target.value.replace(/[^0-9]/g, "").substring(0, 10);
      }
      if (type == 'constant_symbol') {
        stateValue[type] = event.target.value.replace(/[^0-9]/g, "").substring(0, 4);
      }


    }


    if (inputType === 'date') {

      if (typeof event === "object") {
        stateValue[type] = event.format("DD.MM.YYYY");
      } else {
        stateValue[type] = event;
      }
    }

    this.setState(stateValue, () => {
      this.allow_submit();
    });

    if (type === 'invoice_excvat') {
      if (event.target.value == '') event.target.value = 0;
      var sum = parseFloat(event.target.value) + parseFloat(this.state.invoice_vat);
      this.setState({invoice_incvat: sum, invoice_topay: sum}, () => {
        this.allow_submit();
      });
    }

    if (type === 'invoice_vat') {
      if (event.target.value == '') event.target.value = 0;
      var sum = parseFloat(event.target.value) + parseFloat(this.state.invoice_excvat);
      this.setState({invoice_incvat: sum, invoice_topay: sum}, () => {
        this.allow_submit();
      });
    }


    if (type === 'company') {
	
		console.log( "set_company", event );
	
      var url = UrlService.apiDomain() + 'api/invoices/all/' + event.value;
      this.setState({
        last_invoices: null
      });
      HttpService.get(url).then(response => {
        this.setState({
          last_invoices: response.data.sort((a, b) => {
            if(a.invoice_issue > b.invoice_issue){
              return -1;
            }
            if(a.invoice_issue < b.invoice_issue){
              return 1;
            }
            return 0;
          })
        });
      })



      HttpService.get(UrlService.apiDomain() + 'api/companies/' + event.value)
        .then(res => {

          var locale = 'cs';
          if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

          var payment_type = '';
          var payment_type_id = '';
          var currency = '';

          var account_number = '';
          var iban = '';
          var bic_swift = '';
          var bank_code = '';


          var bank_name_1 = '';
          var bank_name_2 = '';
          var bank_address_1 = '';
          var bank_address_2 = '';
          var bank_country_code = '';
          var country_code = '';
          var beneficiary_name_1 = '';
          var beneficiary_name_2 = '';
          var beneficiary_address_1 = '';
          var beneficiary_address_2 = '';
          var invoice_subtypes = [];

          var payment_method = '';
          var payment_method_value = this.blankOption;

          if (res.data.payment_type !== null) {
            payment_type = this.paymentTypesData.find(refData => refData['id'] == res.data.payment_type)['name_' + locale];
            payment_type_id = res.data.payment_type;
          }
          if (res.data.currency !== null) {
            currency = this.currenciesData.find(refData => refData['id'] == res.data.currency)['name_' + locale];
          }

          if (res.data.payment_method !== null) {
            payment_method = res.data.payment_method;

            this.paymentMethodsData.map((i) => {
              if (i.id == res.data.payment_method) payment_method_value = {value: i.id, label: i['name_' + locale]}
            })

          }
          if (res.data.bank_name_1 !== null) bank_name_1 = res.data.bank_name_1;
          if (res.data.bank_name_2 !== null) bank_name_2 = res.data.bank_name_2;
          if (res.data.bank_address_1 !== null) bank_address_1 = res.data.bank_address_1;
          if (res.data.bank_address_2 !== null) bank_address_2 = res.data.bank_address_2;

          if (res.data.beneficiary_name_1 !== null) beneficiary_name_1 = res.data.beneficiary_name_1;
          if (res.data.beneficiary_name_2 !== null) beneficiary_name_2 = res.data.beneficiary_name_2;
          if (res.data.beneficiary_address_1 !== null) beneficiary_address_1 = res.data.beneficiary_address_1;
          if (res.data.beneficiary_address_2 !== null) beneficiary_address_2 = res.data.beneficiary_address_2;

          if (res.data.account_number !== null) account_number = res.data.account_number;
          if (res.data.iban !== null) iban = res.data.iban;
          if (res.data.bic_swift !== null) bic_swift = res.data.bic_swift;
          if (res.data.bank_code !== null) bank_code = res.data.bank_code;
          if (res.data.country !== null) country_code = this.countriesData.find(refData => refData['id'] == res.data.country)['code2'];
          if (res.data.bank_country !== null) bank_country_code = this.countriesData.find(refData => refData['id'] == res.data.bank_country)['code2'];


          if (res.data.invoice_subtype !== null) {
            res.data.invoice_subtype.split('//').map((e) => {
              this.invoiceSubtypesData.map((i) => {
                if (i.id == e) invoice_subtypes.push(i);
              })
            })
          }


          this.setState({
            payment_method: payment_method,
            payment_method_value: payment_method_value,
            invoice_subtypes: invoice_subtypes,
            payment_type: payment_type,
            payment_type_id: payment_type_id,
            bank_name_1: bank_name_1,
            bank_name_2: bank_name_2,
            bank_address_1: bank_address_1,
            bank_address_2: bank_address_2,
            bank_country_code: bank_country_code,
            country_code: country_code,
            beneficiary_name_1: beneficiary_name_1,
            payer_note: beneficiary_name_1,
            beneficiary_name_2: beneficiary_name_2,
            beneficiary_address_1: beneficiary_address_1,
            beneficiary_address_2: beneficiary_address_2,
            currency: currency,

            account_number: account_number,
            iban: iban,
            bic_swift: bic_swift,
            bank_code: bank_code,
          }, () => {
            this.allow_submit();
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }


  }

  multiselectOnChange(value, event) {


    if (typeof value !== 'undefined') {

      var val = '';
      var loadingsSelected = [];


      value.map((i) => {
        if (i.value !== '*') {
          val += i.value + '//';
          loadingsSelected.push({value: i.value, label: i.label});
        }

        return true;
      });
	  
      this.setState({loadings: val.slice(0, -2), loadingsSelected: loadingsSelected});
    }
  }

  getDropdownButtonLabel({placeholderButtonLabel, value}) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: ` + all;
    } else {
      return `${placeholderButtonLabel}: ${value.length} ` + selectedtext;
    }
  }

  render() {
	

    console.log(this.props);

    var allowed_companies = [];
    if (this.props.type === 'loadings') {
      this.props.rownodes.forEach((e) => {
        allowed_companies.push(e.company_id);
      });
    }

    if (this.props.type === 'trips') {
      this.props.rownodes.forEach((e) => {
        allowed_companies.push(e.speditor_company);
      });
    }

    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

    let {
      invoice_type,
      company,
      payment_method,
      pay_from,
      payment_type,
      payment_type_id,
      currency,
      account_number,
      iban,
      bic_swift,
      bank_code,
      invoice_excvat,
      invoice_vat,
      invoice_incvat,
      invoice_topay,
      invoice_issue,
      invoice_due,
      invoice_taxable,
      paid_date,
      invoice_number,
      note,
      payer_note,
      beneficiary_note,
      bank_name_1,
      bank_name_2,
      bank_address_1,
      bank_address_2,
      bank_country_code,
      country_code,
      beneficiary_name_1,
      beneficiary_name_2,
      beneficiary_address_1,
      beneficiary_address_2,
      variable_symbol,
      constant_symbol,
      specific_symbol
    } = this.state;


    const invoiceTypes = [this.blankOption];
    var companies = [];
    const paymentMethods = [this.blankOption];
    const bankAccounts = [this.blankOption];
    const invoiceSubtypes = [this.blankOption];

    this.state.invoice_subtypes.map((e) => {
      invoiceSubtypes.push({value: e.id, label: e['name_' + locale]});
    });

    this.bankAccountsData.map((e) => {
      bankAccounts.push({value: e.id, label: e['account_name']});
    });

    this.invoiceTypesData.map((e) => {
      if (e.id == 1) return false;
      invoiceTypes.push({value: e.id, label: e['name_' + locale]});
    });

    this.companiesData.map((e) => {
      if ((allowed_companies.includes(e.id) || this.props.type === 'other') && e.company_type != 2) companies.push({
        value: e.id,
        label: e['name']
      });
    });


    companies = companies.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
    companies.unshift(this.blankOption);
    this.paymentMethodsData.map((e) => {
      paymentMethods.push({value: e.id, label: e['name_' + locale]});
    });


    var pay_from_disabled = false;
    var pay_from_required = true;
	

    var foreign_details = <>
      <div className="row">


        { this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Bank name 1')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={bank_name_1}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bank_name_1', false)
				  }
				/>
			  </div>
			</div> 
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Bank name 2')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={bank_name_2}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bank_name_2', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Bank address 1')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={bank_address_1}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bank_address_1', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Bank address 2')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={bank_address_2}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bank_address_2', false)
				  }
				/>
			  </div>
			</div>
		}

      </div>

      <div className="row">

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Beneficiary name 1')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={beneficiary_name_1}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'beneficiary_name_1', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Beneficiary name 2')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={beneficiary_name_2}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'beneficiary_name_2', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Beneficiary address 1')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={beneficiary_address_1}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'beneficiary_address_1', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Beneficiary address 2')}</label>
				<input
				  type="text"
				  disabled={true}
				  className="form-control"
				  value={beneficiary_address_2}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'beneficiary_address_2', false)
				  }
				/>
			  </div>
			</div>
		}

      </div>
      <div className="row">

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Beneficiary country')}</label>
				<input
				  type="text"

				  disabled={true}
				  className="form-control"
				  value={country_code}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'country_code', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('Bank country')}</label>
				<input
				  type="text"

				  disabled={true}
				  className="form-control"
				  value={bank_country_code}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bank_country_code', false)
				  }
				/>
			  </div>
			</div>
		}

		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('IBAN')}</label>
				<input
				  type="text"

				  disabled={true}
				  className="form-control"
				  value={iban}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'iban', false)
				  }
				/>
			  </div>
			</div>
		}
		
		{ this.state.hideDisabled === false && 
			<div className="col-3">
			  <div className="form-group">
				<label htmlFor="description">{this.props.t('BIC/SWIFT')}</label>
				<input
				  type="text"

				  disabled={true}
				  className="form-control"
				  value={bic_swift}
				  placeholder={this.props.t('Enter the value')}
				  onChange={(event) =>
					this.handleChange(event, 'bic_swift', false)
				  }
				/>
			  </div>
			</div>
		}


      </div>
    </>
    var domestic_details = <>
      <div className="row">

        <div className="col-3">
          <div className="form-group">
            <label htmlFor="description">{this.props.t('Account number')}</label>
            <input
              type="text"

              disabled={true}
              className="form-control"
              value={account_number}
              placeholder={this.props.t('Fill in company')}
              onChange={(event) =>
                this.handleChange(event, 'account_number', false)
              }
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label htmlFor="description">{this.props.t('Bank code')}</label>
            <input
              type="text"

              disabled={true}
              className="form-control"
              value={bank_code}
              placeholder={this.props.t('Fill in company')}
              onChange={(event) =>
                this.handleChange(event, 'bank_code', false)
              }
            />
          </div>
        </div>


        <div className="col-3">
          <div className="form-group">
            <label htmlFor="description">{this.props.t('Variable symbol')}</label>
            <input
              type="text"

              required={true}
              className="form-control"
              value={variable_symbol}
              placeholder={this.props.t('Enter the value')}
              onChange={(event) =>
                this.handleChange(event, 'variable_symbol', 'text')
              }
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label htmlFor="description">{this.props.t('Constant symbol')}</label>
            <input
              type="text"
              className="form-control"
              value={constant_symbol}
              placeholder={this.props.t('Enter the value')}
              onChange={(event) =>
                this.handleChange(event, 'constant_symbol', 'text')
              }
            />
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="description">{this.props.t('Specific symbol')}</label>
            <input
              type="text"
              className="form-control"
              value={specific_symbol}
              placeholder={this.props.t('Enter the value')}
              onChange={(event) =>
                this.handleChange(event, 'specific_symbol', 'text')
              }
            />
          </div>
        </div>
      </div>

    </>

    var show_paid_date = {display: 'none'};
    if (payment_method == 1) {
      show_paid_date = {display: 'block'};
    }

    var payment_details = '';

    console.log(payment_type_id);
    if (payment_method == 2) {
      if (payment_type_id == 1) {
        payment_details = domestic_details;
      } else {
        payment_details = foreign_details;
      }
    }


    if (this.state.payment_method == 2) {
      pay_from_disabled = false;
      pay_from_required = true;
    } else {
      var pay_from_disabled = true;
      var pay_from_required = false;
    }

    var all = 'All';
    var selectedtext = 'selected';
    if (locale === 'cs') all = 'Vše';
    if (locale === 'cs') selectedtext = 'vybráno';
	
	
	
	let permissions = JSON.parse(localStorage.getItem('my_permissions'));
	
	let speditionPermission = false;
	
	if (permissions.includes('loadings.spedition')){
	
		speditionPermission = true;
	
	}


    var placeholder = "Select";
    if (locale === 'cs') placeholder = "Vyberte";
	
    return (
      <div style={{'height': '500px', 'overflowY': this.state.overflowY, 'overflowX': 'hidden'}}>
        <form onSubmit={(event) => {  } }>

          <div className="container">
            <div className="row">


              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}} >
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Type')}</label>
                  <Select
                    name="invoice_type"
                    onChange={(event) => this.handleChange(event, 'invoice_type', 'select')}
                    value={this.state.invoice_type_value}
                    required
                    isDisabled={(this.props.type !== 'loadings') ? false : true}
                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={invoiceTypes}/>
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Loadings')}</label>


                  <ReactMultiSelectCheckboxes

                    options={this.state.loadingsArray}
                    placeholderButtonLabel={this.props.t('Select')}
                    value={this.state.loadingsSelected}

                    onChange={(value, event) => this.multiselectOnChange(value, event)}


                  />


                </div>
              </div>
			  
			  <div className="col-3">
                <div className="form-group">
					<label htmlFor="description">{this.props.t('Bankovní údaje')}</label>
					<input
						type="checkbox"
						className="form-control"
						checked={!this.state.hideDisabled}
						onChange={(event) => this.setState({ hideDisabled: !this.state.hideDisabled })}

					/>
				</div>
			  </div>


            </div>

            <div className="row">


              <div className="col-3" style={{display: 'none'}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Type')}</label>
                  <Select
                    name="invoice_type"
                    onChange={(event) => this.handleChange(event, 'invoice_type', 'select')}
                    value={this.state.invoice_type_value}
                    required
                    isDisabled={true}
                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={invoiceTypes}/>
                </div>
              </div>

              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Company')}</label>
                  <Select
                    name="company"
                    onChange={(event) => this.handleChange(event, 'company', 'select')}
                    value={this.state.company_value}
                    required
                    isDisabled={this.props.fromtab}
                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={companies}/>
                </div>
              </div>


              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Invoice type')}</label>
                  <Select
                    name="invoice_subtype"
                    onChange={(event) => this.handleChange(event, 'invoice_subtype', 'select')}
                    value={this.state.invoice_subtype_value}

                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={invoiceSubtypes}/>
                </div>
              </div>

              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Payment method')}</label>
                  <Select
                    name="payment_method"
                    onChange={(event) => this.handleChange(event, 'payment_method', 'select')} 
                    value={this.state.payment_method_value}
                    required

                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={paymentMethods}/>
                </div>
              </div>

              <div className="col-3" style={{display: "none"}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Pay from account')}</label>
                  <Select
                    name="pay_from"
                    onChange={(event) => this.handleChange(event, 'pay_from', 'select')}
                    value={this.state.pay_from_value}
                    required={pay_from_required}

                    isDisabled={pay_from_disabled}

                    placeholder={this.props.t('Select')}
                    classNamePrefix="select"
                    options={bankAccounts}/>
                </div>
              </div>

				{ this.state.hideDisabled === false && 
					  <div className="col-3" style={(this.state.payment_method == 1) ? {display: 'none'} : {}}>
						<div className="form-group">
						  <label htmlFor="description">{this.props.t('Payment type')}</label>
						  <input
							type="text"

							disabled={true}
							className="form-control"
							value={payment_type}
							placeholder={this.props.t('Fill in company')}
							onChange={(event) =>
							  this.handleChange(event, 'payment_type', 'text')
							}
						  />
						</div>
					  </div>
				}

            </div>


            {payment_details}


            <div className="row">

			{ this.state.hideDisabled === false && 
              <div className="col-4" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Currency')}</label>
                  <input
                    type="text"

                    disabled={true}
                    className="form-control"
                    value={currency}
                    placeholder={this.props.t('Fill in company')}
                    onChange={(event) =>
                      this.handleChange(event, 'currency', 'text')
                    }
                  />
                </div>
              </div>
			}

              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Excluding VAT')}</label>
                  <input
                    type="text"
                    disabled={(this.props.type === 'other' || this.props.type === 'loadings') ? false : true}
                    pattern="[-.0-9]*"
                    className="form-control"
                    value={invoice_excvat}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'invoice_excvat', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('VAT')}</label>
                  <input
                    type="text"
                    disabled={(this.props.type === 'other' || this.props.type === 'loadings') ? false : true}
                    pattern="[-.0-9]*"
                    className="form-control"
                    value={invoice_vat}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'invoice_vat', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Including VAT')}</label>
                  <input
                    type="text"

                    disabled={true}
                    className="form-control"
                    value={invoice_incvat}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'invoice_incvat', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Amount to pay')}</label>
                  <input
                    type="text"

                    disabled={false}
                    className="form-control"
                    value={invoice_topay}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'invoice_topay', 'text')
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Issue date')}</label>
                  <Datetime
					key={'invoice_issue_' + invoice_issue}
                    timeFormat={false}
                    locale={locale}
                    dateFormat="DD.MM.YYYY"
                    value={invoice_issue}
                    inputProps={{required: true}}
                    onChange={(event) => this.handleChange(event, 'invoice_issue', 'date')}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Due date')}</label>
                  <Datetime
					key={'invoice_due_' + invoice_due}
                    timeFormat={false}
                    locale={locale}
                    dateFormat="DD.MM.YYYY"
                    value={invoice_due}
                    inputProps={{required: true}}
                    onChange={(event) => this.handleChange(event, 'invoice_due', 'date')}
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Taxable date')}</label>
                  <Datetime
					key={'invoice_taxable_' + invoice_taxable}
                    timeFormat={false}
                    locale={locale}
                    dateFormat="DD.MM.YYYY"
                    value={invoice_taxable}
                    inputProps={{required: true}}
                    onChange={(event) => this.handleChange(event, 'invoice_taxable', 'date')}
                  />
                </div>
              </div>

              <div className="col-3 rightdate" style={show_paid_date}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Paid date')}</label>
                  <Datetime
					key={'paid_date_' + paid_date}
                    timeFormat={false}
                    locale={locale}
                    dateFormat="DD.MM.YYYY"
                    value={paid_date}

                    onChange={(event) => this.handleChange(event, 'paid_date', 'date')}
                  />
                </div>
              </div>

            </div>


            <div className="row">

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Invoice number')}</label>
                  <input
                    type="text"

                    required={true}
                    className="form-control"
                    value={invoice_number}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'invoice_number', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Payer note')}</label>
                  <input
                    type="text"
                    disabled={(payment_method == 1 || payment_method == '') ? true : false}
                    className="form-control"
                    value={payer_note}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'payer_note', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-3" style={( speditionPermission == true ) ? {display: 'none'} : {}}>
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Beneficiary note')}</label>
                  <input
                    type="text"
                    disabled={(payment_method == 1 || payment_method == '') ? true : false}
                    className="form-control"
                    value={beneficiary_note}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'beneficiary_note', 'text')
                    }
                  />
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="description">{this.props.t('Note')}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={note}
                    placeholder={this.props.t('Enter the value')}
                    onChange={(event) =>
                      this.handleChange(event, 'note', 'text')
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <div className="form-group">

					<button 
						disabled={ !this.state.allow_submit || this.state.waiting == true } 
						onClick={ function( e ){ this.setState( { waiting: true, forSpeditionSet: false } ); this.handleFormSubmit( e ) }.bind( this ) }
						className="btn btn-success"
					>
						{this.props.t('Save')}
					</button>
                </div>
              </div>
              <div className="col-9">
                <div className="form-group">
                  <input id="invoice_file" name="invoice_file" type="file"/>
                </div>
              </div>
            </div>
            <div style={{'height': "500px", width: '100%'}} id="invoicesGrid" className="ag-theme-alpine">
              <AgGridReact
                modules={AllCommunityModules}
                rowData={this.state.last_invoices}
                columnDefs={MainTableColumnDefs('invoices').map(c => {
                  c.minWidth = 20;
                  return c;
                })}
                defaultColDef={
                  {
                    editable: false,
                    filter: false,
                    resizable: true,
                    sortable: true,
                    minWidth: 20,
                  }
                }
                frameworkComponents={{
                  DeleteRenderer: DeleteRenderer,
                  ActionsRenderer: ActionsRenderer,
                  MultiSelectSimple: MultiSelectSimple,
                  DateTimePicker: DateTimePicker,
                  MultiSelectLargeData: MultiSelectLargeData,
                  FileRenderer: FileRenderer,
                  invoicesStatusCount: invoicesStatusCount,
                  DatePicker: DatePicker,
                }}
                suppressAggFuncInHeader={true}
                suppressRowClickSelection={true}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

InvoiceAdd.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(InvoiceAdd);

