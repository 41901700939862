export default function (table, showLoadingCard, opts, showManufacturingCard, pinned) {

    var locale = 'cs';
    if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');

    var columnDefs = [];

    var visible = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => refData['name'] == table + '-visible').value.split('//');
    var editable = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => refData['name'] == table + '-editable').value.split('//');

    JSON.parse(localStorage.getItem('columns')).map((c) => {
        if (c.field === 'loading_lat' || c.field === 'loading_lng') return false;
        if (c.model !== table) return false;
        if (!visible.includes(c.field)) return true;
        var obj = {};
        if (editable.includes(c.field) && c.locked != 1) obj.editable = function (params) {
            if (params.data && params.data.id) {
                return true;
            } else {
                if (table === 'loadings' && (c.field === 'loading_qty' || c.field === 'product_type' || c.field === 'partially_stored' || c.field === 'net_material_qty' ||
                    c.field === 'gross_material_qty' || c.field === 'material_units_qty' || c.field === 'material_units_description')) return false;
            }
            return true;
        }
        obj.headerName = c['name_' + locale];
        obj.field = c.field;

        if (c.column_type == 'integer') {
			
			obj.filter = 'agNumberColumnFilter';
            // validating integers
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = parseInt(params.newValue);
                } else {
                    params.node.data.groupedvalue = parseInt(params.newValue);
                    params.node.data.groupedfield = c.field;
                }
            }
            obj.type = 'numericColumn';
        }

        if (c.column_type == 'decimal') {
			
			obj.filter = 'agNumberColumnFilter';
            // validating decimals
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = parseFloat(params.newValue);
                } else {
                    params.node.data.groupedvalue = parseFloat(params.newValue);
                    params.node.data.groupedfield = c.field;

                }
            }
            obj.type = 'numericColumn';
        }

        if (c.column_type == 'text') {

            obj.comparator = function (valueA, valueB) {
				
                if (valueA != null && valueB != null)
                    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
            };

            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = params.newValue;
                } else {
                    params.node.data.groupedvalue = params.newValue;
                    params.node.data.groupedfield = c.field;
                }
            }
			
			obj.filter = 'agTextColumnFilter';
			
			//obj.filter = "agSetColumnFilter";
			
			
			if( c.model == 'companies' && c.field == 'name' ){
				
				console.log( "companies_name_bl" );
			
				obj.filterParams = {
					comparator: function (t1, t2) {
						
						console.log( t1, t2 );
						
						if( t1 === null && t2 === null ){
							
							return 0;
							
						}
						
						if( t1 === null ){
							
							return -1;
							
						}
						
						if( t2 === null ){
							
							return 1;
							
						}
						
						return t1.toLowerCase( ).localeCompare(t2.toLowerCase( ));
					
					}
					
				}
				
			} else {
				
				obj.filterParams = {
					comparator: function (t1, t2) {
						
						if( t1 === null && t2 === null ){
							
							return 0;
							
						}
						
						if( t1 === null ){
							
							return -1;
							
						}
						
						if( t2 === null ){
							
							return 1;
							
						}
						
						return t1.localeCompare(t2);
					
					}
					
				}
				
			}
        }


        if (c.column_type == 'text' || c.column_type == 'integer' || c.column_type == 'decimal') {

            obj.valueGetter = function (params) {

                if (params.data && params.data.id) {
                    if (params.data[c.field] === null) return '';
                    return params.data[c.field];
                } else {
                    var ret = '';
                    var vals = [];
                    var sum = 0;
                    try {
                        params.node.allLeafChildren.map((e) => {
                            if (vals.includes(e.data[c.field])) return true;
                            ret += e.data[c.field] + ', ';
                            sum = sum + parseFloat(e.data[c.field]);
                            vals.push(e.data[c.field]);
                        });
                    } catch (err) {
                        console.log(params);
                        console.log(err);
                    }
                    if (c.field == 'tc_percent') {
                        var fin = sum / vals.length;
                        if (isNaN(fin)) return '';
                        return fin.toFixed(2);
                    }

                    if (ret.slice(0, -2) === 'null') return '';
                    return ret.slice(0, -2);
                }
            }

        }

        if (c.column_type == 'boolean') {

            obj.cellEditor = 'agRichSelectCellEditor';

            obj.cellEditorParams = {values: JSON.parse(localStorage.getItem('booleans')).map(e => e['name_' + locale])}
            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = JSON.parse(localStorage.getItem('booleans')).find(refData => refData['name_' + locale] == params.newValue).id;
                    return true;
                } else {
                    params.node.data.groupedvalue = JSON.parse(localStorage.getItem('booleans')).find(refData => refData['name_' + locale] == params.newValue).id;
                    params.node.data.groupedfield = c.field;
                }
            }
            obj.valueGetter = function (params) {
                if (params.data && params.data.id) {
                    if (params.data[c.field] === null) return '';
                    try {
                        return JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(params.data[c.field]))['name_' + locale];
                    } catch (err) {
                        console.error(params.data[c.field], c.field, err)
                        return '';
                    }
                } else {
                    
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (vals.includes(e.data[c.field])) return true;
                        try {
							
							let v = e.data[c.field];
							
							if( c.field == 'odvoz' ){
								if( v == null ){
									v = 0;
								}
							}
							
                            ret += JSON.parse(localStorage.getItem('booleans')).find(refData => String(refData.id) === String(v))['name_' + locale] + ', ';
                            vals.push(e.data[c.field]);
                        } catch (err) {
                            console.log(e.data[c.field])
                            console.log(c.field)
                            console.log(err);
                            return '';
                        }
                    });
                    return ret.slice(0, -2);
                }
            }

        }
        if (c.column_type == 'select') {
			
			obj.filter = "agSetColumnFilter";

            obj.valueSetter = function (params) {
                if (params.data && params.data.id) {
                    params.data[c.field] = params.newValue;
                } else {
                    params.node.data.groupedvalue = params.newValue;
                    params.node.data.groupedfield = c.field;
                }
            }

            if (c.data_array == 'zipcodes' || c.data_array == 'cities' || c.data_array == 'states') {
                obj.cellEditor = 'MultiSelectLargeData';
                obj.cellEditorParams = {
                    gridType: table,
                    multiple: false,
                };
            } else {

                obj.cellEditor = 'agRichSelectCellEditor';
                var values = [];
                var tmp_values = [];
                if (c.data_array == 'custom') {
                    if (c.data != null && c.data != '') values = JSON.parse(c.data);
                } else if (c.data_array == '' || c.data_array == null || c.data_array == 'none') {
                    //
                } else {
                    values = JSON.parse(localStorage.getItem(c.data_array));
                }

                // checking if values are allowed
                var allowed_options = [];
                JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
                    if (e.name == table + '/columns-' + c.field) {
                        allowed_options = e.value.split('//');
                    }
                });


                if( ( c.model != 'expenses' ) && ( c.model != "loading" ) && ( c.field == 'manager' ) ) {
                    // adding team members

                    console.log(JSON.parse(localStorage.getItem('my_team')).members.split('//'));
                    console.log(JSON.parse(localStorage.getItem('my_team')).members);
                    allowed_options = allowed_options.concat(JSON.parse(localStorage.getItem('my_team')).members.split('//'));


                    console.log(values);

                    console.log(allowed_options);
                }

                let onlyEmpty = true;

                for (const allowed_option of allowed_options) {
                    if(allowed_option && allowed_option !== ''){
                        onlyEmpty = false;
                        break;
                    }
                }


                ( values !== null ) && values.map((e) => {
                    if (allowed_options.includes(e.id.toString()) || onlyEmpty) {
                        tmp_values.push(e);
                    }
                });
                // end of allowed values check

                var users_values = values;

				if (c.data_array == 'users') {

					

				} else {
					
					values = tmp_values;

				}
				
                obj.cellEditorParams = {values: values.map(e => e['name_' + locale])}
                obj.valueSetter = function (params) {
                    if (params.data && params.data.id) {
						
						console.log( params, c );
						
                        try {
                            params.data[c.field] = values.find(refData => refData['name_' + locale] == params.newValue).id;
                            return true;
                        } catch (err) {
                            console.log(err);
                            return true;
                        }
                    } else {
                        try {
                            params.node.data.groupedvalue = values.find(refData => refData['name_' + locale] == params.newValue).id;
                            params.node.data.groupedfield = c.field;
                            return true;
                        } catch (err) {
                            console.log(err);
                            return true;
                        }
                    }
                }
                obj.valueGetter = function (params) {
                    if (params.data && params.data.id) {

                        if (params.data[c.field] === null || params.data[c.field] === '') return '';
                        try {
							
							let founded = values.find(refData => String(refData.id) === String(params.data[c.field]))
							
                            if (c.data_array == 'companies') return founded ? founded['name'] : null;
                            if (c.data_array == 'bank_accounts') return founded ? founded['account_name'] : null;
                            if (c.data_array == 'users') return founded ? founded['name'] : null;
							
							console.log( founded );
							
                            return founded ? founded['name_' + locale] : null;
                        } catch (err) {
                            console.log(params);
                            console.log(params.data);
                            console.log(c);
                            console.log(params.data[c.field]);
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    } else {
                        // for grouped rows
                        var ret = '';
                        var vals = [];
                        params.node.allLeafChildren.map((e) => {
                            if (e.data[c.field] === null || e.data[c.field] === '' || vals.includes(e.data[c.field])) return true;
                            try {
                                if (c.data_array == 'companies') {
                                    ret += values.find(refData => String(refData.id) === String(e.data[c.field]))['name'] + ', ';
                                } else if (c.data_array == 'bank_accounts') {
                                    ret += values.find(refData => String(refData.id) === String(e.data[c.field]))['account_name'] + ', ';
                                } else if (c.data_array == 'users') {
                                    ret += users_values.find(refData => String(refData.id) === String(e.data[c.field]))['name'] + ', ';
                                } else {
                                    ret += values.find(refData => String(refData.id) === String(e.data[c.field]))['name_' + locale] + ', ';
                                }

                                vals.push(e.data[c.field]);
                            } catch (err) {
                                console.log(c);
                                console.log(e);
                                console.log(e.data[c.field]);
                                console.log(values);
                                console.log(err);
                                return '';
                            }
                        });
                        return ret.slice(0, -2);
                    }
                }
				
				if( ( c.model == 'loadings' || c.model == 'companies' ) && c.field == 'country' ){
					
					if( !obj.filterParams ){
					
						obj.filterParams = {
							comparator: function (n1, n2) {
							
								//console.log( n1, n2 );
								
								return ( n1 == n2 ) ? 0 : ( ( n1 > n2 ) ? 1 : -1 );
							
							}
							
						}
					
					}
					
					obj.filterValueGetter = function( params ){
							
						//console.log( params.data.country );
						
						let countries = JSON.parse( localStorage.getItem( 'countries' ) );
			
						let country = null;
						
						for( let i in countries ){
							
							if( params.data.country && countries[ i ].id.toString( ) == params.data.country.toString( ) ){
								
								country = countries[ i ][ 'name_' + locale ];
								
								break;
								
							}
							
						}
						
						//console.log( country );
						
						return country;
						
					}
				
				}
				
				if( c.model == 'companies' && c.field == 'continent' ){
					
					obj.filterParams = {
						comparator: function (n1, n2) {
						
							//console.log( n1, n2 );
							
							return ( n1 == n2 ) ? 0 : ( ( n1 > n2 ) ? 1 : -1 );
						
						}
						
					}
					
					obj.filterValueGetter = function( params ){
							
						//console.log( params.data.continent );
						
						let continents = JSON.parse( localStorage.getItem( 'continents' ) );
			
						let continent = null;
						
						for( let i in continents ){
							
							if( params.data.continent && continents[ i ].id.toString( ) == params.data.continent.toString( ) ){
								
								continent = continents[ i ][ 'name_' + locale ];
								
								break;
								
							}
							
						}
						
						return continent;
						
					}
				
				}

            }
        }


        if (c.column_type == 'multiselect') {
            obj.cellEditor = 'MultiSelectSimple';
            obj.cellEditorParams = {
                gridType: table
            };
            var values = [];
			
            if (c.data_array == 'custom') {
				
                if(c.data != null && c.data != '') {
					
					values = JSON.parse(c.data);
					
				}
				
            } else if (c.data_array == '' || c.data_array == null || c.data_array == 'none') {
                //
            } else {
				
                values = JSON.parse(localStorage.getItem(c.data_array));
            }


            obj.valueGetter = function (params) {
                if (params.data && params.data.id) {
					console.log( params.data, values, c.field );
                    if (params.data[c.field] == null || params.data[c.field] == '') return '';
                    var res = '';
                    params.data[c.field].split('//').map((e) => {
                        try {
                            res += values.find(refData => String(refData.id) === String(e))['name_' + locale] + '//';
                        } catch (err) {
                            console.log(err);
                        }
                    });

                    return res.slice(0, -2);
                } else {
                    // for grouped rows
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return true;
                        var res = '';
                        e.data[c.field].split('//').map((i) => {
                            try {
                                res += values.find(refData => String(refData.id) === String(i))['name_' + locale] + ', ';
                            } catch (err) {
                                console.log(err);
                            }
                        });

                        ret += res.slice(0, -2) + ', ';
                        vals.push(e.data[c.field]);
                    });
                    return ret.slice(0, -2);
                }
            }
        }

        if (c.column_type == 'date') {
			
            //if (c.field != 'paid_date' ) {
                obj.filter = 'agDateColumnFilter';
            //}
            obj.cellEditor = 'DatePicker';
            obj.comparator = function (date1, date2, nodeA, nodeB) {
                if(!nodeA?.data?.id) {
                    let val = null;
                    if(nodeA.allLeafChildren.length > 0) {
                        val = nodeA.allLeafChildren[0].data[c.field];
                    }
                    date1 = val;
                }
                if(!nodeB?.data?.id) {
                    let val = null;
                    if(nodeB.allLeafChildren.length > 0) {
                        val = nodeB.allLeafChildren[0].data[c.field];
                    }
                    date2 = val;
                }
                function _monthToNum(date) {
                    if (date === undefined || date === null || date === '') {
                        return null;
                    }
					
					
					date = date.split(' ')[ 0 ]; //for datetime
                    var date_arr = date.split('-');

                    if(!date.includes('-')){
                        date_arr = date.split('.');
                    }

                    var yearNumber = parseInt(date_arr[0]);
                    var monthNumber = parseInt(date_arr[1]);
                    var dayNumber = parseInt(date_arr[2]);

                    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                    // 29/08/2004 => 20040829
                    //console.log(result);
                    return result;
                }


                var date1Number = _monthToNum(date1);
				
                var date2Number = _monthToNum(date2);
				
				
				//console.log( "datenumbers", date1, date2, date1Number, date2Number );

                if (date1Number === null && date2Number === null) {
                    return 0;
                }
                if (date1Number === null) {
                    return -1;
                }
                if (date2Number === null) {
                    return 1;
                }

                return date1Number - date2Number;
            }
			
			let FilterOptions =  [
				'empty',
				{
					displayKey: 'blank',
					displayName: '(Prázdno)',
					test: function (filterValue, cellValue) {
						return cellValue == undefined;
					},
					hideFilterInput: true,
				},
				{
					displayKey: 'notBlank',
					displayName: '(Ne prázdno)',
					test: function (filterValue, cellValue) {
						return cellValue != undefined;
					},
					hideFilterInput: true,
				},
				'equals',
				'notEqual',
				'lessThan',
				'lessThanOrEqual',
				'greaterThan',
				'greaterThanOrEqual',
				'inRange'
			];

            obj.filterParams = {
				filterOptions: FilterOptions,
                includeBlanksInEquals: true,
				includeBlanksInRange: true,
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue == null ? null : cellValue.split(' ')[ 0 ]; //for datetime
                    if (dateAsString == null) {
                        return -1;
                    }
					
                    // In the example application, dates are stored as dd/mm/yyyy
                    // We create a Date object for comparison against the filter date
                    const dateParts = dateAsString.includes('-') ? dateAsString.split('-') : dateAsString.split('.');
                    const day = Number(dateParts[2]);
                    const month = Number(dateParts[1]) - 1;
                    const year = Number(dateParts[0]);
                    const cellDate = new Date(year, month, day);

                    // Now that both parameters are Date objects, we can compare
                    if (cellDate < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (cellDate > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                }
            }
			
			if( c.model == 'loadings' && c.field == 'loading_stored' ){
				
				obj.filterParams.includeBlanksInRange = true;
				
			}

            obj.valueSetter = function (params) {
                if (/^\d{4}-\d{2}-\d{2}$/) {
                    if(params.data && params.data.id) {
                        params.data[c.field] = params.newValue;
                        return true;
                    } else {
                        params.node.data.groupedvalue = params.newValue;
                        params.node.data.groupedfield = c.field;
                        return true;
                    }
                }
                if (params.data && params.data.id) {
                    params.data[c.field] = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0];
                    return true;
                } else {
                    params.node.data.groupedvalue = params.newValue.split(".")[2] + '-' + params.newValue.split(".")[1] + '-' + params.newValue.split(".")[0];
                    params.node.data.groupedfield = c.field;
                }
            };

            obj.valueFormatter = function (params) {
                if (params.data && params.data.id) {
                    if (params.data[c.field] == null || params.data[c.field] == '') return '';
                    var d = new Date(params.data[c.field]);
                    var day = d.getDate().toString().padStart(2, '0');
                    var month = (d.getMonth() + 1).toString().padStart(2, '0');
                    var year = d.getFullYear().toString().padStart(4, '0');

                    return day + '.' + month + '.' + year;
                } else {
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return '';
                        var d = new Date(e.data[c.field]);
                        var day = d.getDate().toString().padStart(2, '0');
                        var month = (d.getMonth() + 1).toString().padStart(2, '0');
                        var year = d.getFullYear().toString().padStart(4, '0');

                        ret += day + '.' + month + '.' + year  + ', ';
                        vals.push(e.data[c.field]);
                    });

                    return ret.slice(0, -2);
                }
            }
        }


        if (c.column_type == 'datetime') {
            obj.filter = 'agDateColumnFilter';
			
			if( c.model == 'expenses' && c.field == 'datetime' ){
				obj.cellEditor = 'DateTimePickerSeconds';
			} else {
				obj.cellEditor = 'DateTimePicker';
			}


            obj.comparator = function (date1, date2) {

                function _monthToNum(date) {

                    if (date === undefined || date === null || date === '') {
                        return null;
                    }

                    var date_arr1 = date.split(' ');
                    var date_arr = date_arr1[0].split('.');

                    var yearNumber = parseInt(date_arr[2]);
                    var monthNumber = parseInt(date_arr[1]);
                    var dayNumber = parseInt(date_arr[0]);

                    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                    // 29/08/2004 => 20040829
                    //console.log(result);
                    return result;
                }


                var date1Number = _monthToNum(date1);
                var date2Number = _monthToNum(date2);


                if (date1Number === null && date2Number === null) {
                    return 0;
                }
                if (date1Number === null) {
                    return -1;
                }
                if (date2Number === null) {
                    return 1;
                }

                return date1Number - date2Number;
            }
			
			if( c.model == 'expenses' && c.field == 'datetime' ){
				
				obj.filterParams = {
					
					inRangeInclusive: true,
					
					comparator: function( FilterLocalDateAtMidnight, CellValue ) {

						if( ( CellValue == null ) || ( CellValue == '' ) ) {
							
							return -1;
							
						}

						let BothParts = CellValue.split(' ');
						
						let DateParts = BothParts[ 0 ].split('.');
						
						let Day = parseInt( DateParts[ 0 ] );
						let Month = parseInt( DateParts[ 1 ] ) - 1;
						let Year = parseInt( DateParts[ 2 ] );
						
						let TimeParts = BothParts[ 1 ].split(":");
						
						let Hour = parseInt( TimeParts[0] );
						let Minute = parseInt( TimeParts[1] );
						let Second = parseInt( TimeParts[2] );

						let CellDate = new Date( Year, Month, Day, Hour, Minute, Second );
						
						let FixedFilterLocalDateAtMidnight = new Date( 
							FilterLocalDateAtMidnight.getTime( ) - ( FilterLocalDateAtMidnight.getTimezoneOffset( ) * 60 * 1000 ) 
						);
						
						let FixedCellDate = new Date( 
							CellDate.getTime( ) - ( CellDate.getTimezoneOffset( ) * 60 * 1000 ) 
						);

						if( FixedCellDate.getTime( ) < FixedFilterLocalDateAtMidnight.getTime( ) ) {
							
							return -1;
							
						} else if( FixedCellDate.getTime( ) > FixedFilterLocalDateAtMidnight.getTime( ) ) {
							
							return 1;
							
						}
						
						return 0;
						
					}
					
				};
					
			} else {
				
				let FilterOptions =  [
					'empty',
					{
						displayKey: 'blank',
						displayName: '(Prázdno)',
						test: function (filterValue, cellValue) {
							return cellValue == undefined;
						},
						hideFilterInput: true,
					},
					{
						displayKey: 'notBlank',
						displayName: '(Ne prázdno)',
						test: function (filterValue, cellValue) {
							return cellValue != undefined;
						},
						hideFilterInput: true,
					},
					'equals',
					'notEqual',
					'lessThan',
					'lessThanOrEqual',
					'greaterThan',
					'greaterThanOrEqual',
					'inRange'
				];

				obj.filterParams = {
					// provide comparator function
					filterOptions: FilterOptions,
					includeBlanksInEquals: true,
					comparator: (filterLocalDateAtMidnight, cellValue) => {
						const dateAsString = cellValue;

						if (dateAsString == null || dateAsString == '') {
							return -1;
						}

						// In the example application, dates are stored as dd/mm/yyyy
						// We create a Date object for comparison against the filter date
						const parts = dateAsString.split(' ');
						const dateParts = parts[0].split('.');
						const day = Number(dateParts[0]);
						const month = Number(dateParts[1]) - 1;
						const year = Number(dateParts[2]);
						const timeParts = parts[1].split(":");
						const hour = timeParts[0];
						const minute = timeParts[1];

						const cellDate = new Date(year, month, day);

						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						} else if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						}
						return 0;
					}
				}
				
			}


            obj.valueSetter = function (params) {
                var d = new Date(Date.parse(params.newValue));
                if (params.data && params.data.id) {
					
					if( c.model == 'expenses' && c.field == 'datetime' ){
						
						params.data[c.field] = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
							+ ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
							
						return true;
						
					} else {

						params.data[c.field] = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
							+ ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
							
						return true;
						
					}
                } else {
                    params.node.data.groupedvalue = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
                        + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
                    params.node.data.groupedfield = c.field;
                }
            };

            obj.valueGetter = function (params) {
                if (params.data && params.data.id) {
                    if (params.data[c.field] == null || params.data[c.field] == '') return null;
                    var d = new Date(params.data[c.field]);
					
					if( c.model == 'expenses' && c.field == 'datetime' ){
						
						return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
							d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ( "0" + d.getSeconds() ).slice(-2);
						
					} else {
					
						return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
							d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
					}
					
                } else {
                    // for grouped rows
                    var ret = '';
                    var vals = [];
                    params.node.allLeafChildren.map((e) => {
                        if (e.data[c.field] == null || e.data[c.field] == '' || vals.includes(e.data[c.field])) return true;
                        var d = new Date(e.data[c.field]);
                        ret += ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
                            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ', ';
                        vals.push(e.data[c.field]);
                    });

                    return ret.slice(0, -2);
                }
            }
        }


        if (table === 'invoices') {

            if (c.field === 'paid_date') {
                obj.editable = function (params) {
                    if (params.data.paid_date == null || params.data.paid_date == '') {
                        return true;
                    } else {
                        if (params.data.payment_method == 1) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }

            }

            if (c.field === 'invoice_number') {
				obj.headerCheckboxSelection = true;
				obj.headerCheckboxSelectionFilteredOnly = true;
                obj.checkboxSelection = function (params) {
					return true;
                    /*
						Maybe in pay_invoice???
					if (!params.data || !params.data.payment_method) return false;
                    if (params.data.paid_date && params.data.paid_date != null) return false;
                    if (params.data.payment_method == 2) {
                        return true;
                    } else {
                        return false;
                    }*/
                };
                // obj.sort='asc';
                obj.pinned = 'left';
                obj.minWidth = 200;

                obj.cellRenderer = "FileRenderer";

                obj.cellRendererParams = {
                    type: 'invoice_file'
                };
				
            }

            if (c.field === 'invoice_due') {

                obj.sort = 'desc';
                obj.pinned = 'left';
                obj.minWidth = 200;
            }

            if (c.field === 'invoice_file') {
                return true;
            }


        }


        if (table === 'companies') {
            if (c.field === 'name') {
                obj.checkboxSelection = true;
                // obj.sort='asc';
                obj.pinned = 'left';
                obj.minWidth = 200;


            }
        }


        if (table == 'outinvoices') {

            if (c.field === 'invoice_number') {
				
				
				 obj.checkboxSelection = function (params) {
                    //if (params.data.payment_method == 2) {
                        return true;
                    //} else {
                    //    return false;
                    //}
                };

                var roles = [];
                JSON.parse(localStorage.getItem('my_user')).roles.forEach((r) => {
                    roles.push(r.name);
                });

                if (roles.includes('Admin')) obj.editable = true;

                obj.cellRenderer = "ActionsRenderer";

                obj.cellRendererParams = {
                    gridType: "outinvoices",
                    showcard: showLoadingCard

                };
            }

            if (c.field === 'company') {
                if (pinned) obj.pinned = 'left';
            }
        }

        if (table === 'loadings') {

            if (c.field === 'invoice') return true;
            if (c.field === 'invoice_file') return true;
            if (c.field === 'invoice_excvat' || c.field === 'invoice_vat') {
                obj.editable = function (params) {
                    console.log(params);
                    if (params.data && params.data.id) {
                        if (params.data.invoice == null || params.data.invoice == '') {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if (!params.node.allLeafChildren[0] || !params.node.allLeafChildren[0].data) return false;

                        if (params.node.allLeafChildren[0].data.invoice == null || params.node.allLeafChildren[0].data.invoice == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            }

            if (c.field === 'invoice_number') {

                obj.cellRenderer = "FileRenderer";

                obj.cellRendererParams = {
                    type: 'invoice_file',
                    gridType: 'loadings'
                };
            }

            if (c.field === 'remaining_qty') {
                obj.hide = true;
            }

            if (opts && (opts.type === 'storage' || opts.type === 'galvanisation')) {
                var permissions = JSON.parse(localStorage.getItem('my_permissions'));
                if (!permissions.includes('storage.edit')) obj.editable = false;
                if (c.field === 'remaining_qty') {
                    obj.hide = false;
                }
            }


            if (c.field === 'loading_number') {
                if (opts && (opts.type === 'storage' || opts.type === 'galvanisation')) {
                    obj.pinned = 'left';
                    obj.filter = "agNumberColumnFilter";
                    obj.cellRenderer = 'ManufacturingRenderer';
                    obj.cellRendererParams = {
                        gridType: "storage",
                        showcard: showManufacturingCard
                    };
                } else {
                    obj.pinned = 'left';
                    obj.minWidth = 300;

                    obj.rowGroup = true;
                    if (opts && (opts.type === 'storage' || opts.type === 'galvanisation')) obj.rowGroup = false;
                    obj.hide = true;
                    obj.cellRenderer = "ActionsRenderer";

                    obj.cellRendererParams = {
                        gridType: "loadings",
                        showcard: showLoadingCard
                    };
                }
				
				obj.headerCheckboxSelection = true;
				obj.checkboxSelection = true;
				
            }

            if (opts && (opts.type === 'storage' || opts.type === 'galvanisation')) {


                if (c.field === 'product_type') {
                    obj.pinned = 'left';
                }
                if (c.field === 'remaining_qty') {
                    obj.filter = "agNumberColumnFilter";
                    if (!permissions.includes('storage.edit')) obj.hide = true;
                    if (opts && (opts.type === 'galvanisation')) obj.hide = false;

                }

                if (!permissions.includes('storage.edit')) {
                    obj.menuTabs = ['filterMenuTab'];
                }


            }
            if (c.field === 'loading_qty' || c.field === 'loaded_qty' || c.field === 'partially_stored' || c.field === 'net_material_qty' || c.field === 'gross_material_qty' || c.field === 'material_units_qty') {
                obj.aggFunc = 'sum';
            }

            /*if (c.field === 'tc_percent' || c.field === 'tc_kg') {
                obj.aggFunc = 'sum';
            }*/


            /*
                        if (c.field === 'company') {
                            obj.checkboxSelection=true;
                            obj.pinned = 'left';

                        }
            */


        }

        columnDefs.push(obj);
    });


    return columnDefs;
}