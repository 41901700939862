"use strict";


const APIFieldT = class APIFieldT {
	
	#Value = null;
	
	constructor(
		Value
	){

		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid value" );
			
		}
		
		
		this.#Value = Value;
		
	}
	
	static FromJSON(
		Value
	){
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid value" );
			
		}
		
		
		return new APIFieldT(
			Value
		);
		
	}
	
	Value( 
	
	){
		
		return this.#Value;
		
	}
	
	ToJSON( 
	
	){
		
		return this.#Value;
		
	}
	
};


export default APIFieldT;