import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

class TopCards extends Component {


  render() {
      /*
       return (
       <div className="row">
       <div className="col-md-3 col-sm-12">
       <SmallBox
       icon="ion-bag"
       colorClass="bg-info"
       stats="150"
       label={this.props.t('Active companies')}
       linkLabel={this.props.t('More info')}
       onClickHandler={(data) => {
       console.log(data);
       }}
       />
       </div>
       <div className="col-md-3 col-sm-12">
       <SmallBox
       icon="ion-stats-bars"
       colorClass="bg-success"
       stats="53%"
       label={this.props.t('Contact success')}
       linkLabel={this.props.t('More info')}
       onClickHandler={(data) => {
       console.log(data);
       }}
       />
       </div>
       <div className="col-md-3 col-sm-12">
       <SmallBox
       icon="ion-person-add"
       colorClass="bg-warning"
       stats="44"
       label={this.props.t('Loadings')}
       linkLabel={this.props.t('More info')}
       onClickHandler={(data) => {
       console.log(data);
       }}
       />
       </div>
       <div className="col-md-3 col-sm-12">
       <SmallBox
       icon="ion-pie-graph"
       colorClass="bg-danger"
       stats="65"
       label={this.props.t('Sells')}
       linkLabel={this.props.t('More info')}
       onClickHandler={(data) => {
       console.log(data);
       }}
       />
       </div>
       </div>
       );



       <SmallBox
       icon="ion-bag"
       colorClass="bg-info"
       stats="523"
       label={this.props.t('Active companies')}
       linkLabel={this.props.t('More info')}
       onClickHandler={(data) => {
       console.log(data);
       }}
       />

       */





    return (
      <div className="row">
        <div className="col-md-3 col-sm-12">

        </div>

      </div>
    );
  }
}
TopCards.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(TopCards);

