import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
    onAdd: (data) => void;
}

class LoadingsPrint extends Component<Props> {
	
	constructor( props ) {
	
		console.log( "Constructor props", props );
	
        super( props );

        this.state = {
			disabled: false, spinner: 'none', isCheckedInvoice: false, isCheckedWeighting: false, isCheckedCMR: false, isCheckedOther: false
		}
		
	}
	
	componentDidUpdate( prevProps, prevStates ){
		
		console.log( "component did update", this.props, prevProps, this.state );
		
		if( this.props.isopen == false && prevProps.isopen == true ){
		
			console.log( "component closed" );
		
			this.setState( {
				disabled: false, spinner: 'none', isCheckedInvoice: false, isCheckedWeighting: false, isCheckedCMR: false, isCheckedOther: false
			} );
		
		}
		
		
	}
	
    handleFormSubmit(event) {
	
		this.setState( { disabled: true } );
		
		this.setState( { spinner: 'inline' } );
	
        event.preventDefault();
		
        this.props.onAdd({ 
			faktura: this.state.isCheckedInvoice, 
			weighting: this.state.isCheckedWeighting, 
			cmr: this.state.isCheckedCMR, 
			other: this.state. isCheckedOther
		});
		
    }


    render() {

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-1" style={{marginRight: '10px'}}>
							<label htmlFor="description">{this.props.t('Invoice')}</label>
						</div>
						<div className="col-2">
							<input
								type="checkbox"
								checked={this.state.isCheckedInvoice}
								onChange={(event) =>
									this.setState({ isCheckedInvoice: !this.state.isCheckedInvoice })
								}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-1" style={{marginRight: '10px'}}>
							<label htmlFor="description">{this.props.t('Weighting')}</label>
						</div>
						<div className="col-2">
							<input
								type="checkbox"
								checked={this.state.isCheckedWeighting}
								onChange={(event) =>
									this.setState({ isCheckedWeighting: !this.state.isCheckedWeighting })
								}
							/>
						</div>
                    </div>
					<div className="row">
						<div className="col-1" style={{marginRight: '10px'}}>
							<label htmlFor="description">{this.props.t('CMR')}</label>
						</div>
						<div className="col-2">
							<input
								type="checkbox"
								checked={this.state.isCheckedCMR}
								onChange={(event) =>
									this.setState({ isCheckedCMR: !this.state.isCheckedCMR })
								}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-1" style={{marginRight: '10px'}}>
							<label htmlFor="description">{this.props.t('Other')}</label>
						</div>
						<div className="col-2">
							<input
								type="checkbox"
								checked={this.state.isCheckedOther}
								onChange={(event) =>
									this.setState({ isCheckedOther: !this.state.isCheckedOther })
								}
							/>
						</div>
					</div>
                </div>
                <button 
					disabled={this.state.disabled}
					className="btn btn-success"
				>
				{this.props.t('Mass printing')}
					<div style={{display: this.state.spinner}}>
						<i className="fas fa-spinner fa-spin"></i>
					</div>
				</button>
				
            </form>
        );
    }
}
LoadingsPrint.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingsPrint);

