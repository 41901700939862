"use strict";


import KeyValueElementT from "./KeyValueElementT.mjs";

import KeyValueMapT from "./KeyValueMapT.mjs";


const KeyValueBucketT = class KeyValueBucketT {

	#Map = null;
	
	constructor(
		KeyValueElementList
	){
	
		for(
			let I = 0;
			I < KeyValueElementList.length;
			I++
		){

			if( ( KeyValueElementList[ I ] instanceof KeyValueElementT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < KeyValueElementList.length;
			I++
		){

			this.#Map.set( KeyValueElementList[ I ].Name( ), KeyValueElementList[ I ].KeyValueMap( ) )
		
		}
		
	}
	
	Keys( ){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Name,
		KeyValueMap
	){
	
		if( typeof( Name ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( KeyValueMap instanceof KeyValueMapT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.set( 
			Name,
			KeyValueMap
		);
		
	}
	
	Remove(
		Name
	){
	
		if( typeof( Name ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.delete( Name );
	
	}
	
	Get(
		Name
	){
	
		if( typeof( Name ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.get( Name );
	
	}
	
	IsExists(
		Name
	){
	
		if( typeof( Name ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Name );
	
	}
	
};


export default KeyValueBucketT;