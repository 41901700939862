"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelSchemaResponseAbstractT from "../_/APIModelSchemaResponseAbstractT.mjs";


const APIModelSchemaResponseChildrenMapT = class APIModelSchemaResponseChildrenMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelSchemaResponseList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelKeyList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelSchemaResponseList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelSchemaResponseList.length;
			I++
		){

			if( ( APIModelSchemaResponseList[ I ] instanceof APIModelSchemaResponseAbstractT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelSchemaResponseList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelSchemaResponseList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelSchemaResponse = APIModelSchemaResponseList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelSchemaResponse );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelSchemaResponse
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelSchemaResponse instanceof APIModelSchemaResponseAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelSchemaResponse
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
};


export default APIModelSchemaResponseChildrenMapT;