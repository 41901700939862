"use strict";


import UIElementT from "../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import GTSUIComponentTextT from "../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";


const GTSUIModelOutinvoiceNumberT = class GTSUIModelOutinvoiceNumberT extends GTSUIComponentTextT {
	
	#GTSUIOutinvoiceNumberValue = null;
	#GTSUIOutinvoiceNumberValuePre = null;
	
	constructor( 
		PlaceholderLabel,
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre
	){
		
		super(
			GTSUIOutinvoiceNumberValue, //ValueDefault
			PlaceholderLabel, //PlaceholderLabel
			"^[A-Za-z0-9\-]{0,16}", //RegExpMatch
			"^[A-Za-z0-9\-]{6,16}$" //RegExpValidate
		);
		
		
		this.#GTSUIOutinvoiceNumberValue = GTSUIOutinvoiceNumberValue;
		
		this.#GTSUIOutinvoiceNumberValuePre = GTSUIOutinvoiceNumberValuePre;
		
	}
	
	Default(
	
	){
		
	}
	
	DefaultToPre(
	
	){
		
	}
	
};


export default GTSUIModelOutinvoiceNumberT;
