import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import Invoice from "../../components/invoices";


import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
  onUpdateYear: (data) => void;
  onUpdateMonth: (data) => void;
}

class InvoicesPagee extends Component<Props> {

	constructor( props ){
		
		super( props );
		
		let YearValue = null;
		
		if( localStorage.getItem( 'filter_global_year' ) ){
			
			YearValue = JSON.parse( localStorage.getItem( 'filter_global_year' ) );

		} else {
		
			let Y = ( new Date( ) ).getFullYear( ).toString( );
		
			YearValue = { value: Y, label: Y };
		
			localStorage.setItem( 'filter_global_year', JSON.stringify( YearValue ) );
		
		}
		
		
		let MonthValue = null;
		
		if( localStorage.getItem( 'filter_global_month' ) ){
			
			MonthValue = JSON.parse( localStorage.getItem( 'filter_global_month' ) );

		} else {
		
			MonthValue = { value: null, label: this.props.t( 'Month' ) };
		
			localStorage.setItem( 'filter_global_month', JSON.stringify( MonthValue ) );
		
		}
		
		this.state = {
			Year: YearValue.value,
			Month: MonthValue.value
		};
		
	}

    render() {
        return (
            <div className="wrapper">
                <Layout 
					onUpdateYear={(e)=>{ console.log("invoice update year", e); this.setState( { Year: e.Year } ) } } 
					onUpdateMonth={(e)=>{ console.log("invoice update month", e); this.setState( { Month: e.Month } ) } }
				>
                    <Content hideTitle={true} title="Invoices">
                        <Invoice id="0" opts={this.props.opts} year={ this.state.Year } month={ this.state.Month }/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

InvoicesPagee.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(InvoicesPagee);

