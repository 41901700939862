import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select from 'react-select'
import $ from 'jquery'
interface Props {
    onAdd: (data) => void;
}

class AppraisalAdd extends Component<Props> {

    state = {
        material_type: '', material_type_value: {value: "", label:this.props.t('Choose')}, approx_qty: "", note: "", images:""
    };

    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {


            this.setState({
                material_type: "", approx_qty: "", note: "", material_type_value: {value: "", label:this.props.t('Choose')}

            });

            $('#appraisal_add_images').val('');


        }
    }

    handleFormSubmit(event) {
        event.preventDefault();

        if (this.state.material_type==='') {
            alert(this.props.t('Please choose material type'));
            return false;
        }


        const fileInput = document.querySelector('#appraisal_add_images') ;
        var files=fileInput.files;

       if( files.length===0) {
            alert(this.props.t('Please upload images'));
            return false;
        }

        let files_ok=true;
        let allowed_exts=['jpg','jpeg','png','gif','bmp','tiff'];


        Array.from(files).forEach(e => {
            if(!allowed_exts.includes(e.name.split('.')[e.name.split('.').length - 1].toLowerCase())) files_ok=false;
        });


        if (!files_ok) {
            alert(this.props.t('Please upload only images'));
            return false;
        }

        const { material_type, approx_qty, note} = this.state;
        this.props.onAdd({ material_type, approx_qty, note, files });

    }
    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            optionsArray.push({value: e.id, label:e['name_'+locale]});
        });
        const { approx_qty, note} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Material type')}</label>
                                <Select
                                    id="appraisal_add_material_type"
                                    name="material_type"
                                    onChange={(event) =>
                                        this.setState({ material_type: event.value, material_type_value:event })
                                    }
                                    value={this.state.material_type_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={optionsArray} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Approx. qty.')}</label>
                                <input
                                    type="text"
                                    pattern="[0-9]*"
                                    className="form-control"
                                    value={approx_qty}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ approx_qty: event.target.value })
                                    }
                                />
                            </div>
                        </div>



                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Note')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={note}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) =>
                                        this.setState({ note: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Images')}</label><br />
                                <input multiple id="appraisal_add_images" name="appraisal_add_images" type="file" />
                            </div>
                        </div>
                    </div>



                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
AppraisalAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(AppraisalAdd);

