
	
const InputMultiselectBaseParser = function( Value, OptionsObject, Splitter ){
	
	console.log( Value, OptionsObject, Splitter );
	
	let ValueArray = null;
	
	if( ( Value === "" ) || ( Value === null ) ){
		
		ValueArray = [ ];
		
	} else {
		
		ValueArray = Value.split( Splitter )
		
	}
	
	let Values = new Map( );
	
	
	for(
		let I = 0;
		I < ValueArray.length;
		I++
	){
		
		console.log( ValueArray[ I ].toString( ) );
		
		Values.set( ValueArray[ I ].toString( ), OptionsObject.get( ValueArray[ I ].toString( ) ).label );
		
	}
	
	return Values;
		
};


export default InputMultiselectBaseParser;