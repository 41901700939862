"use strict";


import APIModelCallRequestElementMapT from "./Element/APIModelCallRequestElementMapT.mjs";


const APIModelCallRequestT = class APIModelCallRequestT {
	
	#ElementMap = null;
	
	constructor(
		APIModelCallRequestElementMap
	){
		
		if( ( APIModelCallRequestElementMap instanceof APIModelCallRequestElementMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		this.#ElementMap = APIModelCallRequestElementMap;
	}
	
	
	
	ElementMap( 
	
	){
		
		return this.#ElementMap;
		
	}
	
	ToJSON( ){
			
		let JSONData = { };
		
		JSONData[ "CallMap" ] = this.#ElementMap.ToJSON( );
			
		return JSONData;
		
	}
	
};



export default APIModelCallRequestT;