import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { ProtectedRoute } from "./components/common/router/protected";
import rootReducers from "./store/reducers";

import "./assets/css/gloabl.scss";
import "./assets/css/adminlte.css";
import "./assets/css/fontawesome-free/css/all.css";

import Error from "./pages/error";
import Login from "./pages/login";
import MasterLogin from "./pages/master_login";
import Home from "./pages/homepage";
import Profile from "./pages/profile";
import Continents from "./pages/settings/continents";
import Languages from "./pages/settings/languages";
import MailTemplates from "./pages/settings/mail_templates";
import Countries from "./pages/settings/countries";
import Columns from "./pages/settings/columns";
import ProductTypes from "./pages/settings/product_types";
import ExpenseTypes from "./pages/settings/expense_types";
import WorkTypes from "./pages/settings/work_types";
import Wiretransfers from "./pages/wiretransfers";
import BankAccountss from "./pages/settings/bank_accounts";
import Companies from "./pages/companies";
import Workhours from "./pages/workhours";
import SalaryReport from "./pages/reports/salary_report";
import Documents from "./pages/settings/documents";
import Drivers from "./pages/settings/drivers";
import InvoiceOtheritems from "./pages/settings/invoice_otheritems";
import Contracts from "./pages/contracts";
import Loadings from "./pages/loadings";
import Expenses from "./pages/expenses";
import Speditions from "./pages/speditions";
import LoadingsForAccountants from "./pages/loadings_for_accountants";
import Invoices from "./pages/invoices";
import GoogleCallback from "./pages/login/google_callback.js";
import Outinvoices from "./pages/outinvoices/outinvoices";
import Supplylists from "./pages/supplylists";
import InvoicesReport from "./pages/reports/invoices_report";
import Products from "./pages/products";
import Sales from "./pages/sales";
import Productions from "./pages/productions";
import Trips from "./pages/trips";
import Appraisals from "./pages/appraisals";
import Roles from "./pages/settings/roles";
import Permissions from "./pages/settings/permissions";
import Users from "./pages/settings/users";
import OutinvoiceItemNames from "./pages/outinvoice_item_names";
import AdditionalTexts from "./pages/additional_texts";
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';



interface GuestRouteInterface {
  path: string;
  component: any;
  exact?: boolean;
}

const store = createStore(rootReducers, applyMiddleware(thunk));

const pusherClient = new Pusher('0bf868bce6669c55fad9', {
  cluster: 'eu'
});

  setPusherClient(pusherClient);


const guestRoutes: Array<GuestRouteInterface> = [
  { path: "/", component: Login, exact: true, locale:"cs" },
  { path: "/master_login", component: MasterLogin, exact: true, locale:"cs" },
  { path: "/auth/google_callback",  opts:{}, component: GoogleCallback, model:'', exact: true, locale:"cs" },
];


const protectedRoutes: Array<any> = [
  { path: "/home", opts:{}, component: Home, model:'', exact: true , locale:"cs"},
  { path: "/profile",  opts:{}, component: Profile, model:'', exact: true, locale:"cs" },
  { path: "/continents",  opts:{}, component: Continents, model:'', exact: true, locale:"cs" },
  { path: "/companies",  opts:{}, component: Companies, model:'', exact: true, locale:"cs" },
  { path: "/companies/add/*",  opts:{action:'add'}, component: Companies, model:'', exact: true, locale:"cs" },
  { path: "/loadings",  opts:{}, component: Loadings, model:'', exact: true, locale:"cs" },
  { path: "/speditions",  opts:{}, component: Speditions, model:'', exact: true, locale:"cs" },
  { path: "/loadings_for_accountants",  opts:{}, component: LoadingsForAccountants, model:'', exact: true, locale:"cs" },
  { path: "/galvanisation",  opts:{type:'galvanisation'}, component: Loadings, model:'', exact: true, locale:"cs" },
  { path: "/invoices",  opts:{pay_invoices:false}, component: Invoices, model:'', exact: true, locale:"cs" },
  { path: "/supplylists",  opts:{invoice_id:0}, component: Supplylists, model:'', exact: true, locale:"cs" },
  { path: "/outinvoices",  opts:{}, component: Outinvoices, model:'', exact: true, locale:"cs" },
  { path: "/pay_invoices",  opts:{pay_invoices:true}, component: Invoices, model:'', exact: true, locale:"cs" },
  { path: "/productions",  opts:{}, component: Productions, model:'', exact: true, locale:"cs" },
  { path: "/products",  opts:{}, component: Products, model:'', exact: true, locale:"cs" },
  { path: "/invoices_report",  opts:{}, component: InvoicesReport, model:'', exact: true, locale:"cs" },
  { path: "/sales",  opts:{}, component: Sales, model:'', exact: true, locale:"cs" },
  { path: "/storage",  opts:{type:'storage'}, component: Loadings, model:'', exact: true, locale:"cs" },
  { path: "/workhours",  opts:{}, component: Workhours, model:'', exact: true, locale:"cs" },
  { path: "/trips",  opts:{}, component: Trips, model:'', exact: true, locale:"cs" },
  { path: "/salary_report",  opts:{}, component: SalaryReport, model:'', exact: true, locale:"cs" },
  { path: "/documents",  opts:{model:'loadings'}, component: Documents, model:'', exact: true, locale:"cs" },
  { path: "/drivers",  opts:{}, component: Drivers, model:'', exact: true, locale:"cs" },
  { path: "/outinvoices_documents",  opts:{model:'outinvoices'}, component: Documents, model:'', exact: true, locale:"cs" },
  { path: "/supplylists_documents",  opts:{model:'supplylists'}, component: Documents, model:'', exact: true, locale:"cs" },
  { path: "/loadinglists_documents",  opts:{model:'loadinglists'}, component: Documents, model:'', exact: true, locale:"cs" },
  { path: "/appraisals",  opts:{}, component: Appraisals, model:'', exact: true, locale:"cs" },
  { path: "/active_companies",  opts:{}, component: Companies, model:'', exact: true, locale:"cs" },
  { path: "/invoice_otheritems",  opts:{}, component: InvoiceOtheritems, model:'', exact: true, locale:"cs" },
  { path: "/contracts",  opts:{}, component: Contracts, model:'', exact: true, locale:"cs" },
  { path: "/languages",  opts:{}, component: Languages, model:'', exact: true, locale:"cs" },
  { path: "/mail_templates",  opts:{}, component: MailTemplates, model:'', exact: true, locale:"cs" },
  { path: "/countries",  opts:{}, component: Countries, model:'', exact: true, locale:"cs" },
  { path: "/expenses",  opts:{}, component: Expenses, model:'', exact: true, locale:"cs" },
  { path: "/product_types",  opts:{}, component: ProductTypes, model:'', exact: true, locale:"cs" },
  { path: "/expense_types",  opts:{}, component: ExpenseTypes, model:'', exact: true, locale:"cs" },
  { path: "/work_types",  opts:{}, component: WorkTypes, model:'', exact: true, locale:"cs" },
  { path: "/wiretransfers",  opts:{}, component: Wiretransfers, model:'', exact: true, locale:"cs" },
  { path: "/bank_accounts",  opts:{}, component: BankAccountss, model:'', exact: true, locale:"cs" },
  { path: "/outinvoice_item_names",  opts:{}, component: OutinvoiceItemNames, model:'', exact: true, locale:"cs" },
  { path: "/additional_texts",  opts:{}, component: AdditionalTexts, model:'', exact: true, locale:"cs" },


  { path: "/company_columns",  opts:{}, component: Columns, model:'companies', exact: true, locale:"cs" },
  { path: "/loading_columns",  opts:{}, component: Columns, model:'loadings', exact: true, locale:"cs" },
  { path: "/invoice_columns",  opts:{}, component: Columns, model:'invoices', exact: true, locale:"cs" },
  { path: "/expense_columns",  opts:{}, component: Columns, model:'expenses', exact: true, locale:"cs" },
  { path: "/outinvoice_columns",  opts:{}, component: Columns, model:'outinvoices', exact: true, locale:"cs" },
  { path: "/outinvoice_item_name_columns",  opts:{}, component: Columns, model:'outinvoice_item_names', exact: true, locale:"cs" },
  { path: "/additional_text_columns",  opts:{}, component: Columns, model:'additional_texts', exact: true, locale:"cs" },
  { path: "/roles",  opts:{}, component: Roles, model:'', exact: true, locale:"cs" },
  { path: "/users",  opts:{}, component: Users, model:'', exact: true, locale:"cs" },
  { path: "/permissions",  opts:{}, component: Permissions, model:'', exact: true, locale:"cs" },


];

class App extends Component {



  render() {
    return (

      <BrowserRouter>
        <Switch>
          {guestRoutes.map((route, key) => {
            return (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                locale={route.locale}
                key={key}
              />
            );
          })}
          <Provider store={store}>
            {protectedRoutes.map((route, key) => {
              return (
                  <ProtectedRoute
                  exact={route.exact}
                  path={route.path}
                  opts={route.opts}
                  model={route.model}
                  component={route.component}
                  locale={route.locale}
                  key={key}
                  />
              );
            })}
          </Provider>
          <Route component={Error} />
        </Switch>
      </BrowserRouter>

    );
  }
}

export default App;
