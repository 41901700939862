import { Component } from "react";


const Button = class Button extends Component {
	
	#Id = null;
	#Action = null;
	#ActionData = null;
	#Title = null;
	#Icon = null;
	#Disabled = null;
	#ClassName = null;
	#OnClickCb = null;
	#RefCb = null;
	#Input = null;
	
	#Display = null;
	
	constructor( props ){
		
		super( props );
		
		this.#Id = props.id;
		
		this.#Action = props.action;
		
		this.#ActionData = props.action_data;
		
		this.#Title = props.title;
		
		this.#Icon = props.icon;
		
		this.#Disabled = props.disabled;
		
		this.#ClassName = props.class_name;
		
		this.#OnClickCb = props.on_click_cb;
		
		this.#RefCb = props.ref_cb;
		
		this.#Input = null;
		
		this.#Display = props.display;
		
		this.state = {
			Refresh: 0
		};
		
	}
	
	componentDidMount( ){
		
		if( this.#RefCb ){ 
		
			this.#RefCb( this.#Input ); 
			
		}
		
	}
	
	componentDidUpdate( prevProps ){
		
		if( this.#Disabled !== this.props.disabled ){
			
			this.#Disabled = this.props.disabled;
			
			this.setState( {
				Refresh: this.state.Refresh + 1
			} )
			
		}
		
	}
	
	#OnClick( ){
		
		this.#OnClickCb( this.#Id, this.#Action, this.#ActionData );
		
	}
	
	render( ){
  
		return (
			<div style={ { display: this.#Display } }>
				<button
					ref = { ( Input ) => { this.#Input = Input; } }
					className = { this.#ClassName }
					onClick = { ( ) => this.#OnClick( ) }
				>
					{ this.#Title }
					{ ( this.#Icon && " " ) }
					{ ( this.#Icon && <i className={ this.#Icon }></i> ) }
				</button>
			</div>
		);
	
	}
	
};


export default Button;

