"use strict";


import APIModelSchemaResponseAbstractT from "./_/APIModelSchemaResponseAbstractT.mjs";

import APIModelSchemaResponseElementMapT from "./Element/APIModelSchemaResponseElementMapT.mjs";

import APIModelSchemaResponseModelOptionMapT from "./ModelOption/APIModelSchemaResponseModelOptionMapT.mjs";

import APIModelSchemaResponseCustomOptionMapT from "./CustomOption/APIModelSchemaResponseCustomOptionMapT.mjs";

import APIModelSchemaResponseChildrenMapT from "./Children/APIModelSchemaResponseChildrenMapT.mjs";

import APIModelKeyT from "../../Key/APIModelKeyT.mjs";


const APIModelSchemaResponseT = class APIModelSchemaResponseT extends APIModelSchemaResponseAbstractT {
	
	#ElementMap = null;
	
	#ModelOptionMap = null;
	#CustomOptionMap = null;
	
	#ChildrenMap = null;
	
	constructor(
		ElementMap,
		
		ModelOptionMap,
		CustomOptionMap,
		
		ChildrenMap
	){
		
		if( ( ElementMap instanceof APIModelSchemaResponseElementMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ModelOptionMap instanceof APIModelSchemaResponseModelOptionMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( CustomOptionMap instanceof APIModelSchemaResponseCustomOptionMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ChildrenMap instanceof APIModelSchemaResponseChildrenMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
	
		super( );
	

		this.#ElementMap = ElementMap;
		
		this.#ModelOptionMap = ModelOptionMap;
		
		this.#CustomOptionMap = CustomOptionMap;
		
		this.#ChildrenMap = ChildrenMap;
		
	}
	
	ElementMap(
	
	){
		
		return this.#ElementMap;
	
	}
	
	ModelOptionMap(
	
	){
		
		return this.#ModelOptionMap;
	
	}
	
	CustomOptionMap(
	
	){
		
		return this.#CustomOptionMap;
	
	}
	
	ChildrenMap(
	
	){
		
		return this.#ChildrenMap;
	
	}
	
	static FromJSON(
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelSchemaResponseList = [ ];
		
		let JSONChildrenMap = JSONData[ "ChildrenMap" ];
		
		for(
			let I in JSONChildrenMap
		){
			
			APIModelKeyList.push(
				APIModelKeyT.FromJSON( 
					I 
				)
			);
			
			APIModelSchemaResponseList.push(
				APIModelSchemaResponseT.FromJSON(
					JSONChildrenMap[ I ]
				)
			);
			
		}
		
		let ChildrenMap = new APIModelSchemaResponseChildrenMapT(
			APIModelKeyList,
			APIModelSchemaResponseList
		);

		
		return new APIModelSchemaResponseT(
			APIModelSchemaResponseElementMapT.FromJSON( JSONData[ "ElementMap" ] ),
			APIModelSchemaResponseModelOptionMapT.FromJSON( JSONData[ "ModelOptionMap" ] ),
			APIModelSchemaResponseCustomOptionMapT.FromJSON( JSONData[ "CustomOptionMap" ] ),
			ChildrenMap
		);
		
	}
	
	ToJSON(
	
	){
	
		let JSONData = { };
		
		JSONData[ "ElementMap" ] = this.#ElementMap.ToJSON( );
		
		JSONData[ "ModelOptionMap" ] = this.#ModelOptionMap.ToJSON( );
		
		JSONData[ "CustomOptionMap" ] = this.#CustomOptionMap.ToJSON( );
		
		JSONData[ "ChildrenMap" ] = this.#ChildrenMap.ToJSON( );
		
		return JSONData;
	
	}
	
};


export default APIModelSchemaResponseT;