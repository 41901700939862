"use strict";


import APIModelKeyT from "../../../Key/APIModelKeyT.mjs";

import APIModelSchemaRequestAbstractT from "../_/APIModelSchemaRequestAbstractT.mjs";


const APIModelSchemaRequestChildrenMapT = class APIModelSchemaRequestChildrenMapT {

	#Map = null;
	
	constructor(
		APIModelKeyList,
		APIModelSchemaRequestList
	){
		
		if( ( APIModelKeyList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < APIModelKeyList.length;
			I++
		){

			if( ( APIModelKeyList[ I ] instanceof APIModelKeyT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		
		if( ( APIModelSchemaRequestList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIModelSchemaRequestList.length;
			I++
		){

			if( ( APIModelSchemaRequestList[ I ] instanceof APIModelSchemaRequestAbstractT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}
		

		if( APIModelKeyList.length !== APIModelSchemaRequestList.length ){
			
			throw new Error( "Invalid argument" );
			
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIModelSchemaRequestList.length;
			I++
		){
			
			let APIModelKey = APIModelKeyList[ I ];
			
			let APIModelSchemaRequest = APIModelSchemaRequestList[ I ];
			
			
			this.Set( APIModelKey.Value( ), APIModelSchemaRequest );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIModelSchemaRequest
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIModelSchemaRequest instanceof APIModelSchemaRequestAbstractT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Map.set( 
			Key,
			APIModelSchemaRequest
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
};


export default APIModelSchemaRequestChildrenMapT;