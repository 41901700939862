import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import AppraisalAdd from "./appraisal_add.tsx";
import Modal from "../../components/common/modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import Pusher from 'react-pusher';

class AppraisalTab  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,

            localeText: loctext,
            showAppraisalAdd: false,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Material type'),
                    field: 'material_type',
                    editable:false,
                    valueGetter:function(params) {

                        var locale = 'cs';
                        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
                        var value = '';
                        JSON.parse(localStorage.getItem('product_types')).map((c) => {

                            if (String(c.id) === String(params.data.material_type)) {
                                value = c['name_'+locale];

                            }
                            return true;

                        });
                        return value;
                    }
                },
                {
                    headerName: this.props.t('Approx. qty.'),
                    field: 'approx_qty',
                    editable:false,

                },
                {
                    headerName: this.props.t('Images'),
                    field: 'images',
                    minWidth:200,
                    editable:false,
                    cellRenderer:function(params) {
                        if (params.data.images===null || params.data.images===0 || params.data.images==='') return '';
                        var ret='';
                        JSON.parse(params.data.images).map((image)=>{
                            ret+="<img width='50' height='50' style='border: 1px #eee solid; cursor:pointer; border-radius:4px; margin:6px;' onclick='$(&quot;.zevgan_modal&quot;).show(); $(&quot;#zevgan_lightbox_img&quot;).attr(&quot;src&quot;, &quot;data:image/png;base64, "+image+"&quot;);' src='data:image/png;base64, "+image+"' /> ";
                        });
                        return ret;
                    }
                },
                {
                    headerName: this.props.t('Price')+' €/t',
                    field: 'price',
                    editable:false,

                },
                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    editable:false,
                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.created_at===null || params.data.created_at===0 || params.data.created_at==='') return '';
                        var  date = new Date(params.data.created_at);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();
                        var hour=addZero(date.getHours());
                        var min=addZero(date.getMinutes());
                        month++;
                        return day+'.'+month+'.'+year+' '+hour+':'+min;

                    }

                },
                {
                    headerName: this.props.t('Appraised at'),
                    field: 'appraised_on',
                    editable:false,

                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.appraised_on===null || params.data.appraised_on===0 || params.data.appraised_on==='') return '';
                        var  date = new Date(params.data.appraised_on);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();
                        var hour=addZero(date.getHours());
                        var min=addZero(date.getMinutes());
                        month++;
                        return day+'.'+month+'.'+year+' '+hour+':'+min;

                    }

                },

                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                    editable:true,
                    suppressMenu: true,
                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,

            }
        };
    }




    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.id!=null)  {
                this.setState({
                    rowdata: []
                });

                HttpService.get(UrlService.apiDomain() + 'api/appraisals/'+this.props.id)
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    onCellCValueChanged = (event) => {
        HttpService.put(UrlService.apiDomain()+'api/appraisals/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');


            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {


        var form_data = new FormData();

        for ( var key in data ) {
            if (key!=='files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0]!=='undefined') form_data.append('file1', data.files[0]);
        if (typeof data.files[1]!=='undefined') form_data.append('file2', data.files[1]);
        if (typeof data.files[2]!=='undefined') form_data.append('file3', data.files[2]);
        if (typeof data.files[3]!=='undefined') form_data.append('file4', data.files[3]);
        if (typeof data.files[4]!=='undefined') form_data.append('file5', data.files[4]);

        HttpService.post(UrlService.apiDomain()+'api/appraisals/'+this.props.id, form_data)
            .then(res => {

                if (res.data.result=='failed' || typeof res.data.errors!=='undefined') {
                    var msg=res.data.message+"\n";
                    if (typeof res.data.errors!=='undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg+=value[0]+"\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');


                    HttpService.get(UrlService.apiDomain() + 'api/appraisal/'+res.data.entry.id)
                        .then(res => {
                            this.setState({showAppraisalAdd: false});
                            let r = this.gridApi.updateRowData({
                                add: [res.data.entry]
                            });
                            this.gridApi.flashCells({rowNodes: [r.add[0]]});

                        })
                        .catch((error) => {
                            console.log(error);
                        });


                }

            })
            .catch((error) => {
                console.log(error);
            });


    }

    getRowNodeId=(data) => {
        return data.id;
    };

    render() {
        const rowClassRules = {
            'rag-green': 'data.price > 0',

        };

        return (

<div>
    <Pusher
        channel="my-channel"
        event="my-event"
        onUpdate={(e)=>{ //console.log(e);

            var msg=JSON.parse(e.message);
            if (msg.action=='appraisals_edit') {
                var rowNode = this.gridApi.getRowNode(msg.id);
                if (typeof rowNode != 'undefined' && typeof rowNode.data != 'undefined' && typeof rowNode.data[msg.field] != 'undefined') {
                    if (rowNode.data[msg.field] != msg.value) {
                        rowNode.data[msg.field] = msg.value;
                        var params = {
                            force: true,
                            suppressFlash: false,
                            rowNodes: [rowNode],
                            columns: [msg.field]
                        };
                        this.gridApi.refreshCells(params);
                    }
                }
            }


        }}
    />

    <div className="zevgan_modal" onClick={()=>{$('.zevgan_modal').hide()}}>
    <div className="zevgan_modal-content" onClick={()=>{$('.zevgan_modal').hide()}}>
        <img src="" id="zevgan_lightbox_img" onClick={()=>{$('.zevgan_modal').hide()}}/>
    </div>
    </div>
    <div className="tool-buttons">
        <button
            className="btn btn-success btn-sm"
            onClick={() => this.setState({ showAppraisalAdd: true })}
        >
            {this.props.t('Add new')} <i className="fa fa-plus"></i>
        </button>
    </div>
            <div style={{ 'height': '300px', width:'100%' }} id="companyCommunicationsGrid" className="ag-theme-alpine">
                <Modal
                    title={this.props.t('Add new')}
                    visibility={this.state.showAppraisalAdd}
                    size="lg"
                    onClose={() => this.setState({ showAppraisalAdd: !this.state.showAppraisalAdd })}
                >
                    <AppraisalAdd id={Date.now()} onAdd={(data) => this.handleAdd(data)} />
                </Modal>

                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    onCellClicked={this.onCellClicked}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}
                    rowClassRules={rowClassRules}
                    getRowNodeId = {this.getRowNodeId}
                />
            </div>
</div>


        );
    }
}

AppraisalTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(AppraisalTab);

