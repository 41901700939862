"use strict";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";

import UIElementAbstractT from "../../../../../../../Interface/UI/Element/UIElementAbstractT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


const GTSUIComponentFormSecondaryHeaderElementT = class GTSUIComponentFormSecondaryHeaderElementT extends UIComponentLabelT {
	
	#Width = null;
	
	constructor(
		UIComponentLabelConfig,
		Width
	){
		
		if( ( UIComponentLabelConfig instanceof UIComponentLabelConfigT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( Width ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super(
			new UIElementT( ),
			UIComponentLabelConfig
		);
		
		
		this.WidthSet( Width );
		
	}
	
	WidthSet(
		Width
	){
		
		if( typeof( Width ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Width = Width;
		
	}
	
	WidthGet(
	
	){
		
		return this.#Width;
		
	}
	
};


export default GTSUIComponentFormSecondaryHeaderElementT;