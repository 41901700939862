"use strict";


import APIFieldT from "../Field/APIFieldT.mjs";

import APIValueT from "../Value/APIValueT.mjs";


const APIFieldValueT = class APIFieldValueT {

	#Field = null;
	#Value = null;
	
	constructor(
		Field,
		Value
	){
	
		if( ( Field instanceof APIFieldT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( Value instanceof APIValueT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}

		
		this.#Field = Field;
		
		this.#Value = Value;
		
		
	}
	
	Field( ){
		
		return this.#Field;
		
	}
	
	Value( ){
		
		return this.#Value;
		
	}
	
	static FromJSON(
		JSONField,
		JSONValue
	){
		
		return new APIFieldValueT(
			new APIFieldT( JSONField ),
			new APIValueT( JSONValue )
		);
		
	}
	
};


export default APIFieldValueT;