import { Component } from "react";

import InputSelectTextBase from '../inputs/SelectText/Base/InputSelectTextBase';


const ComponentSelectTextBase = class ComponentSelectTextBase extends Component {
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	#OptionsObject = null;
	#OptionsFilter = null;
	#Placeholder = null;
	#RegExpMatch = null;
	#RegExpValidation = null;
	
	#Value = null;
	#Input = null;
	
	constructor( props ){
		
		console.log( props );
		
		super( props );
		
		
		this.#Field = props.field;
		
		this.#Disabled = props.disabled;
		
		this.#OnChangeCb = props.on_change_cb;
		
		this.#Placeholder = props.placeholder;
		
		this.#OptionsObject = props.options_object;
		
		this.#OptionsFilter = props.options_filter;
		
		this.#RegExpMatch = props.regexp_match;
		
		this.#RegExpValidation = props.regexp_validation;
		
		this.#Value = props.value;	
		
	}
	
	#OnChange( Field, Type, Value ){
		
		console.log( "#OnChange", Value );
		
		
		this.#Value = Value;
		
	}
	
	afterGuiAttached( props ){
		
		console.log( "afterGuiAttached", props, this.#Value );
		
		this.setState( {
			Mounted: true
		} );
		
	}
	
	getValue( ) {
		
		console.log( "getValue", this.#Value );
		
		
		return this.#Value;
	
	}
	
	isPopup( ){
		
		return true;
            
	}
	
	render( ){

		return (
		
			<InputSelectTextBase
				ref_cb = { ( Ref ) => { this.#Input = Ref; } }
				value = { this.#Value }
				field = { this.#Field }
				disabled = { this.#Disabled }
				on_change_cb = { this.#OnChange.bind( this ) }
				options_object = { this.#OptionsObject }
				options_filter = { this.#OptionsFilter }
				placeholder = { this.#Placeholder }
				values_out = { this.props.data }
				regexp_match = { this.#RegExpMatch }
				regexp_validation = { this.#RegExpValidation }
			>
				
			</InputSelectTextBase>

		);
	
	}


};


export default ComponentSelectTextBase;