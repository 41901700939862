import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import ColumnAdd from "./column_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import OptionsRenderer from "../../../aggrid/OptionsRenderer.js";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import ColumnOptions from "../../../components/common/column_options";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class Columns extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };

        super(props)
        var that=this;

        this.model_name='Company';
        if (this.props.model==='loadings') {
            this.model_name='Loading';
        }

        if (this.props.model==='invoices') {
            this.model_name='Invoices';
        }

        if (this.props.model==='outinvoices') {
            this.model_name='Outgoing invoices';
        }

        this.state = {
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            showOptions: false,
            showOptionsData: null,
            showOptionsColId: null,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('ID'),
                    field: 'field',
                    editable: false,
                    rowDrag: true,
                    filter: 'agTextColumnFilter',
                    columnGroupShow: 'open',
                },
                        {
                            headerName: this.props.t('Czech name'),
                            field: 'name_cs',
                            editable: true,

                            filter: 'agTextColumnFilter',

                            comparator: customComparator
                        },
                        {
                            headerName: this.props.t('English name'),
                            field: 'name_en',
                            editable: true,

                            filter: 'agTextColumnFilter',

                            comparator: customComparator
                        },

                {
                    headerName: this.props.t('Column type'),
                    field: 'column_type',
                    editable: false,

                    cellEditor: 'agRichSelectCellEditor',

                   cellEditorParams: {values: JSON.parse(localStorage.getItem('column_types')).map(e=>e['name_'+locale])},

               valueSetter: function(params) {
                    params.data['column_type'] = JSON.parse(localStorage.getItem('column_types')).find(refData => refData['name_'+locale] == params.newValue).id;
                    return true;
                },
                valueGetter: function(params) {
                        try {
                            if (typeof params.data == 'undefined' || params.data['column_type'] == null || params.data['column_type'] == '') return '';
                            return JSON.parse(localStorage.getItem('column_types')).find(refData => String(refData.id) === String(params.data['column_type']))['name_' + locale];
                        } catch(err) {
                            console.log(err);
                        }
                }

                },

                {
                    headerName: this.props.t('Data array'),
                    field: 'data_array',
                    editable: true,
                    cellEditor: 'agRichSelectCellEditor',

                    cellEditorParams: {values: JSON.parse(localStorage.getItem('data_arrays')).map(e=>e['name_'+locale])},

                    valueSetter: function(params) {
                        params.data['data_array'] = JSON.parse(localStorage.getItem('data_arrays')).find(refData => refData['name_'+locale] == params.newValue).id;
                        return true;
                    },
                    valueGetter: function(params) {
                        try {
                            if (typeof params.data == 'undefined' || params.data['data_array'] == null || params.data['data_array'] == '' || params.data['data_array'] == 'none') return '';
                            return JSON.parse(localStorage.getItem('data_arrays')).find(refData => String(refData.id) === String(params.data['data_array']))['name_' + locale];
                        } catch(err) {
                            console.log(err);
                        }
                    }

                },




                {
                    headerName: this.props.t('Permissions'),
                    field: 'permissions',
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                },


                {
                    headerName: this.props.t('Locked'),
                    field: 'locked',
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                },




                {headerName:this.props.t('Options'),
                    cellRenderer:"OptionsRenderer",

                    cellRendererParams: {
                        openOptions: function(colId)  {

                            that.setState({showOptions: true, showOptionsColId: colId});
                        }
                    },
                    suppressMenu: true,},

                {headerName:this.props.t('Delete'),
                    cellRenderer:"DeleteRenderer",

                    cellRendererParams: {
                    gridType: "columns"
                },
                    suppressMenu: true,},
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 110,
                editable: false,
                resizable: true,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                OptionsRenderer: OptionsRenderer
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/columns/'+this.props.model)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onSortChanged = () => {
        var sortModel = this.gridApi.getSortModel();
        sortActive = sortModel && sortModel.length > 0;
        var suppressRowDrag = sortActive || filterActive;
        console.log(
            'sortActive = ' +
            sortActive +
            ', filterActive = ' +
            filterActive +
            ', allowRowDrag = ' +
            suppressRowDrag
        );
        this.gridApi.setSuppressRowDrag(suppressRowDrag);
    };

    onFilterChanged = () => {
        filterActive = this.gridApi.isAnyFilterPresent();
        var suppressRowDrag = sortActive || filterActive;
        console.log(
            'sortActive = ' +
            sortActive +
            ', filterActive = ' +
            filterActive +
            ', allowRowDrag = ' +
            suppressRowDrag
        );
        this.gridApi.setSuppressRowDrag(suppressRowDrag);
    };

    onRowDragMove = (event) => {
        var movingNode = event.node;
        var overNode = event.overNode;
        var rowNeedsToMove = movingNode !== overNode;
        if (rowNeedsToMove) {
            var movingData = movingNode.data;
            var overData = overNode.data;
            var store=this.state.rowdata;
            var fromIndex = store.indexOf(movingData);
            var toIndex = store.indexOf(overData);
            var newStore = store.slice();
            moveInArray(newStore, fromIndex, toIndex);
            this.state.rowdata = newStore;
            this.gridApi.setRowData(newStore);

            var order_data=[];
            var pos=0;
            newStore.map((e)=>{
                order_data.push({'field':e.field, 'column_order':pos});
                pos++;
            });
            HttpService.post(UrlService.apiDomain()+'api/columns_order/'+this.props.model, order_data)
                .then(res => {
                    //console.log(res);

                    SessionStorageService.remove('columns');
                    SessionStorageService.initializeLocalDB({'columns': res.data.columns});
                   // localStorage.setItem('columns', JSON.stringify(res.data.columns));
                })
                .catch((error) => {
                    console.log(error);
                });
            this.gridApi.clearFocusedCell();
        }
        function moveInArray(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }
    };



    getRowNodeId = (data) => {
        //console.log(data);
       if (typeof data !== 'undefined') {
           return data.id;
       }

       else {
           var id=0;
           this.gridApi.forEachNode((rowNode, index) => {
               console.log(rowNode.data);
               if(typeof rowNode.data!=='undefined' && rowNode.data.id>=id) id=rowNode.data.id;
           });
           console.log(id);
           return id;
       }
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';





    };

    onCellCValueChanged = (event) => {
//console.log(event);
        HttpService.put(UrlService.apiDomain()+'api/columns/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('columns', res.data.entry);
                this.gridApi.redrawRows({rowNodes:[event.node]});

                if (res.data.new_columns_permissions!='none') {
                    SessionStorageService.remove('columns_permissions');
                    localStorage.setItem('columns_permissions', JSON.stringify(res.data.new_columns_permissions));
                }

            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;
        HttpService.post(UrlService.apiDomain()+'api/columns/'+this.props.model, data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});


                    var r = this.gridApi.applyTransaction({
                        add: [res.data.entry],
                        addIndex: 0,
                    });
                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    //console.log(r);


                    if (res.data.new_columns_permissions!='none') {
                        SessionStorageService.remove('columns_permissions');
                        localStorage.setItem('columns_permissions', JSON.stringify(res.data.new_columns_permissions));
                    }



                    SessionStorageService.add('columns', res.data.entry);
                }

            })
            .catch((error) => {
               console.log(error);
            });


    }


    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/columns' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                    SessionStorageService.remove_by_id('columns', e.data.id);

                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }



    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t(this.model_name+' columns')}>

                        <Modal
                            title={this.props.t('Column options')}

                            visibility={this.state.showOptions}
                            size="lg"
                            onClose={() => this.setState({ showOptions: !this.state.showOptions })}
                        >
                            <ColumnOptions id={this.state.showOptionsColId} open_options={this.openOptions} />
                        </Modal>

                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>

                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <ColumnAdd id={Date.now()} onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'none'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                rowDragManaged={true}
                                immutableData={true}
                                animateRows={true}
                                getRowNodeId={this.getRowNodeId}

                                onSortChanged={this.onSortChanged.bind(this)}
                                onFilterChanged={this.onFilterChanged.bind(this)}
                                onRowDragMove={this.onRowDragMove.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

var sortActive = false;
var filterActive = false;

Columns.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Columns);

