import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
interface Props {
    onAdd: (data) => void;
}

class ColumnOptionAdd extends Component<Props> {
    blankOption={value: "", label:this.props.t('Choose')};
    state = { company:"", company_value:{value: "", label:this.props.t('Choose')}, name_cs: "", name_en: "", code:"", name_de:"", name_fr:"", name_it:"", name_es:"", name_ru:"", name_cn:""};
    companiesData=JSON.parse(localStorage.getItem('companies'));
    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {


            this.setState({
                company:"", company_value:{value: "", label:this.props.t('Choose')}, name_cs: "", name_en: "", code:"", name_de:"", name_fr:"", name_it:"", name_es:"", name_ru:"", name_cn:""

            });
        }
    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name_cs, name_en, code, name_de, name_fr, name_it, name_es, name_ru, name_cn, company} = this.state;
        this.props.onAdd({ name_cs, name_en, code, name_de, name_fr, name_it, name_es, name_ru, name_cn, company});
    }
    render() {


        var companies = [this.blankOption];

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        this.companiesData.map((e)=>{
            if (e.company_type==1 || e.company_type==null) return false;
            companies.push({value: e.id, label:e['name']});
        });

        const { name_cs, name_en, code, name_de, name_fr, name_it, name_es, name_ru, name_cn, company} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Company')}</label>
                                <Select
                                    name="company"
                                    onChange={(event) => {
                                        this.setState({company: event.value, company_value:event});
                                    }}
                                    value={this.state.company_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={companies} />
                            </div>
                        </div>


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Code')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={code}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ code: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>



                    <div className="row">


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('German name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_de}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_de: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('French name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_fr}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_fr: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Italian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_it}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_it: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Spanish name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_es}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_es: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>




                    <div className="row">


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Russian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_ru}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_ru: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Chinese name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_cn}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cn: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                    </div>


                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
ColumnOptionAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ColumnOptionAdd);

