import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import BankAccountAdd from "./bank_account_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class BankAccountss extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;

        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };

        super(props)
        this.state = {
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            popupParent: document.querySelector('body'),
            rowdata: null,
            columnDefs: [

                {
                    headerName: this.props.t('Account name'),
                    field: 'account_name',
                    editable: true,
                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Bank name'),
                    field: 'bank_name',
                    editable: true,

                },

                {
                    headerName: this.props.t('Generator type'),
                    field: 'generator_type',
                    editable: true,
                    cellEditor: 'agRichSelectCellEditor',

                    cellEditorParams: {values: ['Česká spořitelna', 'Citfin']},


                },

                {
                    headerName: this.props.t('Account prefix'),
                    field: 'prefix',
                    editable: true,

                },

                {
                    headerName: this.props.t('Account number'),
                    field: 'account_number',
                    editable: true,

                },
                {
                    headerName: this.props.t('Bank code'),
                    field: 'bank_code',
                    editable: true,

                },
                {
                    headerName: this.props.t('IBAN'),
                    field: 'iban',
                    editable: true,

                },
                {
                    headerName: this.props.t('SWIFT'),
                    field: 'swift',
                    editable: true,

                },
                {
                    headerName: this.props.t('Payer name'),
                    field: 'payer_name',
                    editable: true,

                },
                {
                    headerName: this.props.t('Payer address'),
                    field: 'payer_address',
                    editable: true,

                },
                {
                    headerName: this.props.t('Bank address'),
                    field: 'bank_address',
                    editable: true,

                },

                {
                    headerName: this.props.t('Currency'),
                    field: 'currency',
                    editable: true,
                    cellEditor: 'agRichSelectCellEditor',

                    cellEditorParams: {values: JSON.parse(JSON.parse(localStorage.getItem('columns_147')).data).map(e=>e['name_'+locale])},

                    valueSetter: function(params) {
                        params.data['currency'] = JSON.parse(JSON.parse(localStorage.getItem('columns_147')).data).find(refData => refData['name_'+locale] == params.newValue).id;
                        return true;
                    },
                    valueGetter: function(params) {
                        try {
                            if (typeof params.data == 'undefined' || params.data['currency'] == null || params.data['currency'] == '') return '';
                            return JSON.parse(JSON.parse(localStorage.getItem('columns_147')).data).find(refData => String(refData.id) === String(params.data['currency']))['name_' + locale];
                        } catch(err) {
                            console.log(err);
                        }
                    }
                },



                {headerName:this.props.t('Delete'),cellRenderer:"DeleteRenderer",cellRendererParams: {
                    gridType: "bank_accounts"
                },
                    suppressMenu: true,},
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/bank_accounts')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/bank_accounts/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('bank_accounts', res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {


        HttpService.post(UrlService.apiDomain()+'api/bank_accounts', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    SessionStorageService.add('bank_accounts', res.data.entry);

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t('Bank accounts')}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>

                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <BankAccountAdd onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'single'}
                                suppressContextMenu={true}
                                popupParent={this.state.popupParent}
                                localeText={this.state.localeText}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}


BankAccountss.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(BankAccountss);

