"use strict";


import APIFieldValueT from "./APIFieldValueT.mjs";


const APIFieldValueMapT = class APIFieldValueMapT {

	#Map = null;
	
	constructor(
		APIFieldValueList
	){
		
		if( ( APIFieldValueList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
	
		for(
			let I = 0;
			I < APIFieldValueList.length;
			I++
		){

			if( ( APIFieldValueList[ I ] instanceof APIFieldValueT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
		
		}

		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < APIFieldValueList.length;
			I++
		){
			
			let APIFieldValue = APIFieldValueList[ I ];
	
	
			let Field = APIFieldValue.Field( );
			
			this.Set( Field.Value( ), APIFieldValue );
		
		}
		
	}
	
	Keys(
	
	){
		
		return this.#Map.keys( );
		
	}
	
	Set( 
		Key,
		APIFieldValue
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( APIFieldValue instanceof APIFieldValueT ) === false ){
			
			throw new Error( "Invalid argument type" );
		
		}
		
		if( this.#Map.has( Key ) === true ){
				
			throw new Error( "Already exists" );
			
		}
		
		
		return this.#Map.set( 
			Key,
			APIFieldValue
		);
		
	}
	
	Remove(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.delete( Key );
	
	}
	
	Get(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Map.has( Key ) === false ){
				
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Map.get( Key );
	
	}
	
	IsExists(
		Key
	){
	
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		return this.#Map.has( Key );
	
	}
	
	static FromJSON( 
		JSONData
	){
		
		let APIFieldValues = [ ];
		
		for(
			let I in JSONData
		){
			
			APIFieldValues.push(
				APIFieldValueT.FromJSON(
					I,
					JSONData[ I ]
				)
			);
			
		}
		
		return new APIFieldValueMapT(
			APIFieldValues
		);
		
	}
	
	ToJSON( 
	
	){
		
		let JSONData = { };
		
		
		for(
			let I of this.#Map.keys( )
		){
			
			let APIFieldValue = this.#Map.get( I );
			
			JSONData[ APIFieldValue.Field( ).Value( ) ] = APIFieldValue.Value( ).Value( );
		
		}


		return JSONData;
		
	}
	
};


export default APIFieldValueMapT;