"use strict";


import StyleT from "./StyleT.mjs";


const StyleMapT = class StyleMapT {
	
	#StyleMap = null;
	
	constructor(
		StyleList
	){
		
		if( ( StyleList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		for(
			let I = 0;
			I < StyleList.length;
			I++
		){
			
			if( ( StyleList[ I ] instanceof StyleT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		this.#StyleMap = new Map( );
		
		
		for(
			let I = 0;
			I < StyleList.length;
			I++
		){
			
			let Style = StyleList[ I ];
			
			this.#StyleMap.set( Style.KeyGet( ), Style.ValueGet( ) );
			
		}
		
	}
	
	Keys( ){
	
		return this.#StyleMap.keys( );
	
	}
	
	Set(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		this.#StyleMap.set( Key, Value );
		
	}
	
	Get(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#StyleMap.get( Key );
		
	}
				
};


export default StyleMapT;