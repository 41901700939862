import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import GenerateRenderer from "../../aggrid/GenerateRenderer.jsx";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import MassEmail from "../massemail";
import Modal from "../../components/common/modal";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
class GenerateDocumentsTab  extends React.Component {

    constructor(props) {
        super(props)
        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale==='cs') loctext=LocaleCs;
        if (locale==='en') loctext=LocaleEn;
		
		var model='loadings';
		if (this.props.type==='outinvoice_single') model='outinvoices';
		if (this.props.type==='supplylist_single') model='supplylists';

        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            single_fill_weights: false,
            showCommunicationId:null,
            showCommunication:false,
            showModalEmail:false,
            showModalEmailAddresses:[],
            showSpinner: 'none',



            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Document'),
                    field: 'name_'+locale,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    minWidth:350,
                    editable:false,
                    cellRenderer: "GenerateRenderer",

					cellRendererParams: {
						generateFunc:this.generate.bind(this),
						model: model
					},

                },
                {
                    headerName: this.props.t('Count'),
                    field: 'count',
                    minWidth:100,
                    editable:true,

                },







            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns:true,

                suppressMenu: false,
            },
            frameworkComponents: {
                GenerateRenderer:GenerateRenderer

            }
        };
    }

    componentDidMount () {
        this.componentDidUpdate({tmp:0});
    }


    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.loading_number!=null)  {
                this.setState({
                    rowdata: []
                });
                var model='loadings';
                if (this.props.type==='outinvoice_single') model='outinvoices';
                if (this.props.type==='supplylist_single') model='supplylists';

                HttpService.get(UrlService.apiDomain() + 'api/documents/generate_list/'+model+'/'+this.props.loading_number)
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        }, ()=>{
                            if (this.props.from_sale=='yes') {
                                function tt() {
                                    this.gridApi.selectAll();
                                    this.generate('pdf');

                                }


                                setTimeout(tt.bind(this), 100);
                            }
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });


            }


        }
    }

    generate(type, rownodedata) {

        this.setState({showSpinner:'inline'});
        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');

        var model='loadings';
        if (this.props.type==='outinvoice_single') model='outinvoices';
        if (this.props.type==='supplylist_single') model='supplylists';

        var rows=[];
        this.gridApi.getSelectedNodes().forEach((e) => {
            rows.push(e.data);
        });
        var url='';
        if (type==='word_one' || type==='pdf_one' || type==='excel_one') {
            rows=[rownodedata];
            url=UrlService.apiDomain() + 'api/documents/generate/'+model+'/'+type+'/'+this.state.single_fill_weights+"/"+rows[0].loading_number;
        } else {
            url=UrlService.apiDomain() + 'api/documents/generate/'+model+'/'+type+'/'+this.state.single_fill_weights+"/"+this.props.loading_number;
        }

        HttpService.postblob(url, {rows:rows})
            .then(response => {



                if (response.status!=200) {
                    console.log(response);
                    alert(this.props.t('Chyba při generaci')+'. '+this.props.t(response.statusText));
                    this.setState({showSpinner:'none'});
                    return false;

                }



                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;

                var filename='';
                if (type==='pdf') filename='print.pdf';
                if (type==='word') filename='print.zip';
                if (type==='pdf_one') filename=rows[0]['name_'+locale]+'.pdf';
                if (type==='word_one') filename=rows[0]['name_'+locale]+'.docx';
                if (type==='excel_one') filename=rows[0]['name_'+locale]+'.xlsx';
                link.setAttribute(
                    'download',
                    filename,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                this.setState({showSpinner:'none'});

            })
            .catch((error) => {
                console.log(error);
            });
    }

    generateHandle(send) {


        if (send) {
            confirmAlert({
                title: this.props.t('Confirm to send'),
                message: this.props.t('Are you sure you want to send mass message?'),
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: () => {
                           this.generate(send);

                        }
                    },
                    {
                        label: this.props.t('No'),

                    }
                ]
            });
        } else {
            this.generate(send);
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };


    onCellCValueChanged = (event) => {


    }

    onCellClicked=(e)=> {


    }

    async handleMassEmail(data) {

        data.ids=[this.gridApi.getDisplayedRowAtIndex(0).data.company];



        var documents=[];
        this.gridApi.getSelectedNodes().forEach((e) => {
            documents.push(JSON.stringify(e.data));
        });

        data.documents=documents.join('@$@#');


        data.loading_number=this.gridApi.getDisplayedRowAtIndex(0).data.loading_number;

        var form_data = new FormData();

        for ( var key in data ) {
            if (key!=='files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0]!=='undefined') form_data.append('file1', data.files[0]);
        if (typeof data.files[1]!=='undefined') form_data.append('file2', data.files[1]);
        if (typeof data.files[2]!=='undefined') form_data.append('file3', data.files[2]);
        if (typeof data.files[3]!=='undefined') form_data.append('file4', data.files[3]);
        if (typeof data.files[4]!=='undefined') form_data.append('file5', data.files[4]);

        //formData.append('file', fileInput.files[0]);
        var model='loadings';
        if (this.props.type==='outinvoice_single') model='outinvoices';
        if (this.props.type==='supplylist_single') model='supplylists';

        HttpService.post(UrlService.apiDomain()+'api/mass_email/'+model, form_data)
            .then(res => {
                if (res.data==null) alert ("Chyba připojení k serveru");
                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully sent email');
                    this.setState({showModalEmail: false});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }

    render() {
	
		var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        var model='loadings';
        if (this.props.type==='outinvoice_single') model='outinvoices';
        if (this.props.type==='supplylist_single') model='supplylists';
		
		let MayPrintWord = false;
		
		if( 
			( model == 'loadings' && permissions.includes('loadings.print_word') ) || 
			( model == 'outinvoices' && permissions.includes('outinvoices.print_word') ) ||
			( model == 'supplylists' && permissions.includes('supplylists.print_word') ) 
		){
		
			MayPrintWord = true;
		
		}
		
        return (
<>

        <div className="tool-buttons">
            <button
            className="btn btn-success btn-sm"
            onClick={() => this.generate('pdf')}
        >
            {this.props.t('Download pdf')} <i className="fa fa-file-pdf"></i>
        </button>
			
			{( MayPrintWord == true ) &&
				<button
					className="btn btn-success btn-sm"
					onClick={() => this.generate('word')}
				>
					{this.props.t('Download zip')} <i className="fa fa-file-archive"></i>
				</button>
			}

            <button type="button"
                    className="btn btn-warning btn-sm"
                    onClick={() =>{
                        let addresses=[];

                        console.log(this.gridApi.getDisplayedRowAtIndex(0));
                            addresses.push(this.gridApi.getDisplayedRowAtIndex(0).data.email);
                        console.log(addresses);
                        this.setState({showModalEmail: true, showModalEmailAddresses:addresses});
                    }
                    }
            >{this.props.t('Send')} <i className="fa fa-envelope"></i>
            </button>
            {model == 'loadings' &&
            <div className="inlinecheckbox">

                <input type="checkbox"
                       checked={this.state.single_fill_weights}
                       onChange={(event) => {


                           this.setState({single_fill_weights: !this.state.single_fill_weights});
                       }}/> {this.props.t('Without weights')}

            </div>
            }
            <div style={{display: this.state.showSpinner}}>
            <i  class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
<Modal
    title={this.props.t('Email')}
    visibility={this.state.showModalEmail}
    size="lg"
    onClose={() => this.setState({ showModalEmail: !this.state.showModalEmail })}
>
    <MassEmail tmp={Date.now()} addresses={this.state.showModalEmailAddresses}  onAdd={(data) => this.handleMassEmail(data)} />
</Modal>
            <div style={{ 'height': '400px', width:'100%' }} id="generateDocumentsGrid" className="ag-theme-alpine">

                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    onCellClicked={this.onCellClicked}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    rowSelection={'multiple'}
                    localeText={this.state.localeText}
                />
            </div>

</>
        );
    }
}

GenerateDocumentsTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(GenerateDocumentsTab);

