"use strict";


import UIElementT from "../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import GTSUIComponentFormPrimaryLineT from "../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIStateT from "../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../Type/UI/Style/UIStyleMapT.mjs";



const GTSUIComponentFormPrimaryT = class GTSUIComponentFormPrimaryT extends UIComponentLayoutVT {
	
	constructor( 
		GTSUIComponentFormPrimaryLineList
	){
		
		if( ( GTSUIComponentFormPrimaryLineList instanceof Array ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		for(
			let I = 0;
			I < GTSUIComponentFormPrimaryLineList.length;
			I++
		){
			
			if( ( GTSUIComponentFormPrimaryLineList[ I ] instanceof GTSUIComponentFormPrimaryLineT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"20px",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT",
					new UIStyleMapT( [
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] )
		);
		
		
		super(
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		
		for(
			let I = 0;
			I < GTSUIComponentFormPrimaryLineList.length;
			I++
		){
			
			this.LineSet(
				I,
				GTSUIComponentFormPrimaryLineList[ I ]
			);
			
		}
		
	}
	
	LineCount(
	
	){
		
		return super.ElementCount( );
		
	}
	
	LineGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		return super.ElementGet( Index );
		
	}
	
	LineRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.ElementRemove( Index );
		
	}
	
	LineSet( 
		Index,
		GTSUIComponentFormPrimaryLine
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		if( ( GTSUIComponentFormPrimaryLine instanceof GTSUIComponentFormPrimaryLineT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
					
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT",
						new UIStyleMapT( [
						
						] )
					)
					
				] )
			)
		);
		
		UIComponentLayoutElement.DataSet( 
			GTSUIComponentFormPrimaryLine
		);
		
		super.ElementSet( 
			Index, 
			UIComponentLayoutElement 
		);
		
	}
	
};


export default GTSUIComponentFormPrimaryT;