import React, { Component } from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import Production from "../../components/manufacturingcard/production_history";



import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';


class ProductionsPage extends Component {


    render() {


        return (
            <div className="wrapper">

                <Layout>
                    <Content hideTitle={true} title="Loadings">

                        <Production fromtab={false} tmp={Date.now()} company_id="0" opts={this.props.opts} />
                    </Content>
                </Layout>
            </div>
        );
    }
}

ProductionsPage.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ProductionsPage);

