"use strict";


import ComponentBaseConfigT from "../../Base/ComponentBaseConfigT.mjs";

import ComponentLayoutHConfigT from "../../Layout/ComponentLayoutHConfigT.mjs";


const ComponentTableHConfigT = class ComponentTableHConfigT extends ComponentBaseConfigT {

	#ComponentTableHHeaderRowConfig = null;
	#ComponentTableHRowConfig = null;

	constructor(
		ComponentTableHHeaderRowConfig,
		ComponentTableHRowConfig,
		ClassName = null,
		StyleMap = null,
		Name = null,
		Id = null
	){

		super(
			ClassName,
			StyleMap,
			null,
			Name,
			Id
		);
		
		this.ComponentTableHHeaderRowConfigSet( ComponentTableHHeaderRowConfig );
		
		this.ComponentTableHRowConfigSet( ComponentTableHRowConfig );
	
	}
	
	Copy( ){
		
		return new ComponentTableHConfigT( 
			this.ComponentTableHHeaderRowConfigGet( ).Copy( ),
			this.ComponentTableHRowConfigGet( ).Copy( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}
	
	ComponentTableHHeaderRowConfigSet(
		ComponentTableHHeaderRowConfig
	){
		
		if( ( ComponentTableHHeaderRowConfig instanceof ComponentLayoutHConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentTableHHeaderRowConfig = ComponentTableHHeaderRowConfig;
		
	}
	
	ComponentTableHHeaderRowConfigGet(
	
	){
		
		return this.#ComponentTableHHeaderRowConfig;
		
	}
	
	ComponentTableHRowConfigSet(
		ComponentTableHRowConfig
	){
		
		if( ( ComponentTableHRowConfig instanceof ComponentLayoutHConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#ComponentTableHRowConfig = ComponentTableHRowConfig;
		
	}
	
	ComponentTableHRowConfigGet(
	
	){
		
		return this.#ComponentTableHRowConfig;
		
	}
	
	Copy(
	
	){
		
		return new ComponentTableHConfigT(
			this.ComponentTableHHeaderRowConfigGet( ).Copy( ),
			this.ComponentTableHRowConfigGet( ).Copy( ),
			this.ClassNameGet( ),
			this.StyleMapGet( ),
			this.NameGet( ),
			this.IdGet( )
		);
		
	}

};


export default ComponentTableHConfigT;