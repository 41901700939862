import React, { Component } from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import axios from "axios";
import CookieService from "./../../services/CookieService";
import UserService from "./../../services/UserService";

class LoginGoogle extends Component {
    state = {
        loading: true,
        error: null,
        data: {},
    };

    componentDidMount() {
        /*
        fetch(`/api/auth/google/callback${this.props.location.search}`, { headers: new Headers({ accept: 'application/json' }) })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong!');
            })
            .then((data) => {

            })
            .catch((error) => {
                this.setState({ loading: false, error });
                console.error(error);
            });
*/

      //  console.log(this.props);


        axios.get(UrlService.apiDomain() + `api/auth/google/callback${this.props.location.search}`)
            .then(res => {

var data=res.data;
console.log(data);
                const options = { path: "/" };
                CookieService.set("access_token", data.token, options);
                UserService.getDataLists();
               // this.setState({ loading: false, data });
            })
            .catch((error) => {
                console.log(error);
               this.setState({ loading: false, error });
            });

    }

    render() {
        const { loading, error, data } = this.state;
        if (loading) {
            return  <React.Fragment>
                <div className="login-page">
                    <div className="login-box">
                        <div className="login-logo">

                            <b>GTS</b>DB

                        </div>
                        Loading....
                    </div>
                </div>
            </React.Fragment>
                        ;
        }

        if (error) {
            return (
                <React.Fragment>
                    <div className="login-page">
                        <div className="login-box">
                            <div className="login-logo">

                                <b>GTS</b>DB

                            </div>
                    <div>
                        <p>Error:</p>
                        <code className="Code-block">{error.toString()}</code>
                    </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="login-page">
                    <div className="login-box">
                        <div className="login-logo">

                                <b>GTS</b>DB

                        </div>
                <div>
                    <details>
                        <summary>Welcome {data.user.name}</summary>
                        <p>Here is your info: </p>
                        <code className="Code-block">{JSON.stringify(data, null, 2)}</code>
                    </details>
                </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LoginGoogle;