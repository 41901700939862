"use strict";


import UIComponentLayoutVConfigT from "../Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../Layout/UIComponentLayoutElementConfigT.mjs";

import UIOptionMapT from "../../Option/UIOptionMapT.mjs";


const UIComponentCheckListConfigT = class UIComponentCheckListConfigT extends UIComponentLayoutVConfigT {
	
	#IsMultiple = false;
	#IsEmptyValid = false;
	#ValueDefault = null;
	#UIOptionMap = null;
	#LayoutElementConfig = null;
	
	constructor(
		IsMultiple,
		IsEmptyValid,
		ValueDefault,
		UIOptionMap,
		UIComponentLayoutElementConfig,
		
		Wrap = "nowrap",
		JustifyContent = "flex-start",
		AlignItems = "stretch",
		AlignContent = "stretch",
		RowGap = "0",
		UIStateMap = null
	){
		
		super(
			Wrap,
			JustifyContent,
			AlignItems,
			AlignContent,
			RowGap,
			UIStateMap,
		);
		
		
		this.IsMultipleSet( IsMultiple );
		
		this.IsEmptyValidSet( IsEmptyValid );
		
		this.ValueDefaultSet( ValueDefault );
		
		this.OptionMapSet( UIOptionMap );
		
		this.LayoutElementConfigSet( UIComponentLayoutElementConfig );
		
	}
	
	LayoutElementConfigSet(
		UIComponentLayoutElementConfig
	){
		
		if( ( UIComponentLayoutElementConfig instanceof UIComponentLayoutElementConfigT ) === false ){
				
			throw new Error( "Argument" );
				
		}
		
		
		this.#LayoutElementConfig = UIComponentLayoutElementConfig.Copy( );
		
	}
	
	LayoutElementConfigGet(
		
	){
		
		return this.#LayoutElementConfig.Copy( );
		
	}
	
	IsEmptyValidSet( 
		Value 
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#IsEmptyValid = Value;
		
	}
	
	IsEmptyValidGet(

	){
		
		return this.#IsEmptyValid;
		
	}
	
	IsMultipleSet( 
		Value 
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#IsMultiple = Value;
		
	}
	
	IsMultipleGet(

	){
		
		return this.#IsMultiple;
		
	}
	
	ValueDefaultSet( 
		ValueDefault 
	){
		
		console.log( ValueDefault );
		
		if( ValueDefault !== null ){
			
			if( ( ValueDefault instanceof Array ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			for(
				let I = 0;
				I < ValueDefault.length;
				I++
			){
		
				if( typeof( ValueDefault[ I ] ) !== "string" ){
					
					throw new Error( "Argument" );
					
				}
			
			}
			
			this.#ValueDefault = ValueDefault.slice( 0 );
		
		} else {
			
			this.#ValueDefault = new Array( );
			
		}
		
	}
	
	ValueDefaultGet(

	){
		
		return this.#ValueDefault.slice( 0 );
		
	}
	
	OptionMapSet( 
		UIOptionMap
	){
		
		if( UIOptionMap !== null ){
			
			if( ( UIOptionMap instanceof UIOptionMapT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
			this.#UIOptionMap = UIOptionMap.Copy( );
		
		} else {
			
			this.#UIOptionMap = null;
			
		}
		
	}
	
	OptionMapGet(
	
	){
		
		if( this.#UIOptionMap === null ){
			
			return null;
			
		}
	
		return this.#UIOptionMap.Copy( );
	
	}
	
	Copy( ){
		
		return new UIComponentCheckListConfigT(
			this.IsMultipleGet( ),
			this.IsEmptyValidGet( ),
			this.ValueDefaultGet( ),
			this.OptionMapGet( ),
			this.LayoutElementConfigGet( ),
			
			super.WrapGet( ),
			super.JustifyContentGet( ),
			super.AlignItemsGet( ),
			super.AlignContentGet( ),
			super.RowGapGet( ),
			super.StateMapGet( )
		);
		
	}
				
};


export default UIComponentCheckListConfigT;