"use strict";


import UIElementAbstractT from "../../../Interface/UI/Element/UIElementAbstractT.mjs";


const UIElementT = class UIElementT extends UIElementAbstractT {
	
	#Element = null;
	
	#Id = null;
	#ClassName = null;
	
	#UIStyles = null;
	#Children = null;
	#Events = null;
	
	#Editable = false;
	
	#InnerText = null;
	
	constructor(
		
	){
		
		super( );
		

		this.#Element = document.createElement( "div" );
		
		
		this.#Id = null;
		
		this.#ClassName = null;
		
		
		
		this.#Children = new Array( );
		
		this.#Events = new Map( );
		
		this.#UIStyles = new Map( );
		
		
		this.#Editable = false;
		
		this.#InnerText = "";
		
	}
	
	IdSet( 
		Id 
	){
		
		if( Id !== null ){
			
			if( typeof( Id ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		if( Id === null ){
		
			this.#Element.removeAttribute( "id" );
		
		} else {
		
			this.#Element.setAttribute( "id", Id );
		
		}
		
		
		this.#Id = Id;
		
	}
	
	IdGet( 
	
	){
		
		return this.#Id;
		
	}
	
	ClassNameSet( 
		ClassName
	){
	
		if( ClassName !== null ){
			
			if( typeof( ClassName ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
	
		if( ClassName === null ){
		
			this.#Element.removeAttribute( "class" );
		
		} else {
		
			this.#Element.setAttribute( "class", ClassName );
		
		}
		
		
		this.#ClassName = ClassName;
		
	}
	
	ClassNameGet( 
	
	){
		
		return this.#ClassName;
		
	}
	
	#CSSToJS( 
		CSSName 
	){
		
		if( typeof( CSSName ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
		
		}
			
		
		if( CSSName === "float" ){
			
			return "cssFloat";
			
		}
    
		let Parts = CSSName.split( "-" );
		
		let PartsResult = [ ];
		
		for(
			let I = 0; 
			I < Parts.length; 
			I++
		) {
			
			if( I === 0 ){
				
				PartsResult[ I ] = Parts[ I ];
				
			} else {
			
				PartsResult[ I ] = Parts[ I ].substring( 0, 1 ).toUpperCase( ) + Parts[ I ].substring( 1 );
			
			}
			
		}
		
		return PartsResult.join( "" );
		
	}

	#JSToCSS(
		JSName
	) {
		
		if( typeof( JSName ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
		
		}
			
		
		if( JSName === "cssFloat" ){
			
			return "float";
			
		}
		
		
		return JSName.replace( /([A-Z])/g, "-$1" ).toLowerCase( );
	
	}
	
	UIStyleSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		console.log( Key, this.#CSSToJS( Key ), Value );
		
		this.#Element.style[ this.#CSSToJS( Key ) ] = Value;
		
		this.#UIStyles.set( Key, Value );
		
	}
	
	UIStyleExists( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#UIStyles.has( Key );
		
	}
	
	UIStyleGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#UIStyles.get( Key );
		
	}
	
	UIStyleRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Element.style.removeProperty( Key );
		
		this.#UIStyles.delete( Key );
		
	}
	
	UIStyleKeys( ){
		
		return [ ...this.#UIStyles.keys( ) ];
		
	}
	
	ChildSet(
		Index,
		Child
	){
	
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
		if( ( Child instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( Index === this.#Children.length ){
			
			this.#Children.push( Child );
			
			this.#Element.appendChild( Child.Render( ) );
			
			return;
			
		}
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		let ChildOld = this.#Children[ Index ];
		
		this.#Element.replaceChild( Child.Render( ), ChildOld.Render( ) );
		
		this.#Children[ Index ] = Child;
	
	}
	
	ChildGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		return this.#Children[ Index ];
		
	}
	
	ChildRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( Index >= this.#Children.length ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		let Child = this.#Children[ Index ];
		
		this.#Element.removeChild( Child.Render( ) );
		
		this.#Children.splice( Index, 1 );
		
	}
	
	ChildrenCount( ){
		
		return this.#Children.length;
		
	}
	
	EventSet(
		Key,
		Value
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Value ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Events.has( Key ) === true ){
			
			throw new Error( "Already exists" );
			
		}
		
		
		this.#Element.addEventListener( Key, Value );
		
		
		this.#Events.set( Key, Value );
		
	}
	
	EventGet( 
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Events.has( Key ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		return this.#Events.get( Key );
		
	}
	
	EventRemove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		if( this.#Events.has( Key ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
		
		let Value = this.#Events.get( Key );
		
		
		this.#Element.removeEventListener( Key, Value );
		
		
		this.#Events.delete( Key );
		
	}
	
	EventKeys( ){
		
		return [ ...this.#Events.keys( ) ];
		
	}
	
	Focus( ){
	
		this.#Element.focus( );
	
	}
	
	Blur( ){
	
		this.#Element.blur( );
	
	}
	
	EditableSet(
		Value
	){
		
		if( typeof( Value ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		console.log( "contenteditable", Value );
		
		this.#Element.setAttribute( "contenteditable", Value.toString( ) );
		
		
		this.#Editable = Value;
		
	}
	
	EditableGet(
	
	){
			
		return this.#Editable;
			
	}
	
	InnerTextSet(
		InnerText
	){
	
		if( typeof( InnerText ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		this.#Element.innerText = InnerText;
		
		
		this.#InnerText = InnerText;
	
	}
	
	InnerTextGet(
		
	){
	
		return this.#InnerText;
	
	}
	
	
	Render( ){
	
		return this.#Element;
		
	}
	
};


export default UIElementT;