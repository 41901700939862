import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import WorkTypeAdd from "./work_type_add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import MultiSelectSimple from "../../../aggrid/MultiSelectSimple.jsx";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class WorkTypes extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;

        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };

        super(props)
        this.state = {
            showModal: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            popupParent: document.querySelector('body'),
            columnDefs: [

                {
                    headerName: this.props.t('Czech name'),
                    field: 'name_cs',
                    editable: true,

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('English name'),
                    field: 'name_en',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Hourly salary'),
                    field: 'hour_salary',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },


                {
                    headerName: this.props.t('Users'),
                    field: 'users',
                    editable: true,
                    cellEditor:'MultiSelectSimple',
                    filter: 'agTextColumnFilter',
                    valueGetter: function(params) {
                    if (params.data.users==null) return '';
                    var res='';
                    params.data.users.split('//').map((e)=>{
                        try {
                            res+= JSON.parse(localStorage.getItem('users')).find((refData) => {if(refData.id == e) {return true;} else {return false;}})['name']+'//';
                        } catch(err) {
                            console.log(err);
                        }
                    });

                    return res.slice(0, -2);
                },
                    cellEditorParams:{
                    gridType: "work_types",
                    multiple: true,
                }

                },



                {headerName:this.props.t('Delete'),cellRenderer:"DeleteRenderer",cellRendererParams: {
                    gridType: "work_types"
                },
                    suppressMenu: true,},
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                MultiSelectSimple: MultiSelectSimple,
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/work_types')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/work_types/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('work_types', res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;
        HttpService.post(UrlService.apiDomain()+'api/work_types', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    SessionStorageService.add('work_types', res.data.entry);

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    async deleteRows(data) {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/work_types/' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                    SessionStorageService.remove_by_id('work_types', e.data.id);
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t('Work types')}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => this.setState({ showModal: true })}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>

                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <WorkTypeAdd onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                popupParent={this.state.popupParent}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

WorkTypes.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(WorkTypes);

