"use strict";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentAbstractT from "../../../../../../../Interface/UI/Component/_/UIComponentAbstractT.mjs";

import UIComponentLayoutVT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";


const GTSUIComponentFormPrimaryLineElementT = class GTSUIComponentFormPrimaryLineElementT extends UIComponentLayoutVT {
	
	#UIComponentLabel = null;
	#UIComponentInner = null;
	
	#UIComponentLayoutElementLabel = null;
	#UIComponentLayoutElementInner = null;
	
	#Width = null;
	
	constructor( 
		UIComponentLabel,
		UIComponentInner,
		Width
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT",
					new UIStyleMapT( [
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] )
		);
		
		
		super(
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		this.#UIComponentLayoutElementLabel = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT",
						new UIStyleMapT( [
							
						] )
					)
					
				] )
				
			)
		);
		
		this.#UIComponentLayoutElementInner = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT",
						new UIStyleMapT( [
							
						] )
					)
					
				] )
			)
		);
	
		super.ElementSet( 0, this.#UIComponentLayoutElementLabel );
		
		super.ElementSet( 1, this.#UIComponentLayoutElementInner );
		
		
		this.ComponentLabelSet( UIComponentLabel );
		
		this.ComponentInnerSet( UIComponentInner );
		
		this.WidthSet( Width );
		
	}
	
	ComponentLabelSet(
		UIComponentLabel
	){
		
		if( ( UIComponentLabel instanceof UIComponentLabelT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentLayoutElementLabel.DataSet( 
			UIComponentLabel 
		);
		
		
		this.#UIComponentLabel = UIComponentLabel;
		
	}
	
	ComponentLabelGet(
	
	){
		
		return this.#UIComponentLabel;
		
	}
	
	ComponentInnerSet(
		UIComponentInner
	){
		
		if( ( UIComponentInner instanceof UIComponentAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#UIComponentLayoutElementInner.DataSet( 
			UIComponentInner 
		);
		
		
		this.#UIComponentInner = UIComponentInner;
		
	}
	
	ComponentInnerGet(
	
	){
		
		return this.#UIComponentInner;
		
	}
	
	WidthSet(
		Width
	){
		
		if( typeof( Width ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		this.#Width = Width;
		
	}
	
	WidthGet(
	
	){
		
		return this.#Width;
		
	}
	
};


export default GTSUIComponentFormPrimaryLineElementT;