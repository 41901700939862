import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class OptionsRenderer extends Component {



 handleClick(event) {

   this.props.openOptions(this.props.data.id);
}


    render() {

        if (this.props.node.data.data_array==='custom' || this.props.type=='product_types') {
            return (

                <button
                    className="btn btn-default btn-sm edit-ingrid-but"
                    onClick={(event) => {this.handleClick(event);
                  //  this.props.open_options(this.props.id);
                    }}
                ><i className="fa fa-edit"></i>
                </button>

            );
        } else {
            return('');
        }
    }
}

OptionsRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(OptionsRenderer);