import React, { Component } from "react";

import * as ReactDOM from 'react-dom';

import { translate } from 'react-polyglot';

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import PropTypes from 'prop-types';


import CellContainer from "../table/CellContainer";
import Cell from "../table/Cell";
import Row from "../table/Row";
import Table from "../table/Table";

//import TableItems from "../table_items/TableItems";

import Button from "../button/Button";

import Input from "../inputs/Input";


import StyleT from "../../../Type/Style/StyleT.mjs";
import StyleMapT from "../../../Type/Style/StyleMapT.mjs";

import ComponentTableHConfigT from "../../../Type/Component/Table/H/ComponentTableHConfigT.mjs";

import ComponentBaseConfigT from "../../../Type/Component/Base/ComponentBaseConfigT.mjs";
import ComponentButtonConfigT from "../../../Type/Component/Button/ComponentButtonConfigT.mjs";
import ComponentTextConfigT from "../../../Type/Component/Text/ComponentTextConfigT.mjs";
import ComponentCheckListConfigT from "../../../Type/Component/CheckList/ComponentCheckListConfigT.mjs";
import ComponentSelectTextConfigT from "../../../Type/Component/SelectText/ComponentSelectTextConfigT.mjs";
import ComponentSelectConfigT from "../../../Type/Component/Select/ComponentSelectConfigT.mjs";
import ComponentDoubleConfigT from "../../..//Type/Component/Double/ComponentDoubleConfigT.mjs";
import ComponentLayoutElementConfigT from "../../../Type/Component/Layout/ComponentLayoutElementConfigT.mjs";
import ComponentLayoutHConfigT from "../../../Type/Component/Layout/ComponentLayoutHConfigT.mjs";

import ComponentConfigT from "../../../Type/Component/ComponentConfigT.mjs";

import ComponentLabelConfigT from "../../../Type/Component/Label/ComponentLabelConfigT.mjs";

import ComponentTableHT from "../../../Interface/Component/Table/H/ComponentTableHT.mjs";

import ComponentLabelT from "../../../Interface/Component/Label/ComponentLabelT.mjs";

import ComponentT from "../../../Interface/Component/ComponentT.mjs";

import EventT from "../../../Interface/Event/EventT.mjs";
import EventMapT from "../../../Interface/Event/EventMapT.mjs";
import EventObjectT from "../../../Interface/Event/EventObjectT.mjs";
import EventBucketT from "../../../Interface/Event/EventBucketT.mjs";

import KeyValueT from "../../../Type/KeyValue/KeyValueT.mjs";
import KeyValueMapT from "../../../Type/KeyValue/KeyValueMapT.mjs";
import KeyValueElementT from "../../../Type/KeyValue/KeyValueElementT.mjs";
import KeyValueBucketT from "../../../Type/KeyValue/KeyValueBucketT.mjs";


import TranslatorT from "../../../Interface/Translator/TranslatorT.mjs";


interface Props {
	
    grid_add:( data ) => void;
	display: boolean
	
};

class Form extends Component<Props> {
	
	#FormIsSuccess = false;
	#ValuesOut = null;
	#Children = null;
	#Items = null;
	#ItemsIsValid = null;
	
	#Fields = null;
	#ModelSchema = null;
	#Prepare = null;
	#CellsRef = null;
	#CellsVisible = null;
	
	#InputRef = null;
	
	#Buttons = null;
	#ButtonsRef = null;
	
	#RequiredRef = null;
	
	#Data = null;
	
	#TableItems = null;
	
	#TableItemsWrapRef = null;
	
	#Preset = null;

	constructor( props ){
	
		super( props );
		
		
		let Locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) ){
			
			Locale = localStorage.getItem( 'locale' );
			
		}
		
		
		this.T = TranslatorT.Import( Locale.toUpperCase( ) );
	
		this.Preload = props.preload;
		
		this.PreloadCustom = props.preload_custom;
		
		
		this.Ref = null;
		
		this.RefBD = null;
		
		this.#Data = props.data;

	
		this.state = {
			
			ModalId: props.modal_id,
			ModelSchema: props.model_schema,
			Preload: props.preload,
			GridAdd: props.grid_add,
			Data: props.data,
			Display: props.display,

			Locale: Locale,
			
			FormIsSuccess: false,

			Table: null,
			Buttons: null,
			OnRef: props.on_ref,
			OnRefBD: props.on_ref_bd,
			
			ModelSchemaObject: null
			
		};
		
		this.#FormIsSuccess = false;
		
		this.ModelName = props.model_name;
		
		this.#ValuesOut = new Map( );
		
		this.#Children = props.children;
		
		this.#Items = new Map( );
		
		this.#ItemsIsValid = new Map( );
		
		this.#Fields = new Map( );
		
		this.#Buttons = new Map( );
		
		this.#ButtonsRef = new Map( );
		
		this.#ModelSchema = props.model_schema;
		
		this.#CellsRef = new Map( );
		
		this.#CellsVisible = new Map( );
		
		this.#InputRef = new Map( );
		
		this.#RequiredRef = new Map( );
		
		console.log( props.prepare );
		
		this.#Prepare = props.prepare;
		
		this.#Preset = props.preset;
	
	}
	
    componentDidMount( ) {

		console.log( "Form.componentDidMount" );
		
		

		for( 
			let I of this.#Fields.keys( )
		){
		
			let MetaCell = this.#Fields.get( I );
			
			Input.Set( I, this.#ValuesOut.get( I ), this.#ValuesOut, this.#ModelSchema, this.#InputRef.get( I ), MetaCell );

		}


		this.SetRequired( );
		
		for(
			let I of this.#CellsRef.keys( )
		){
			
			this.#CellsRef.get( I ).style.display = ( this.#CellsVisible.get( I ) === true ) ? "block" : "none";
			
		}
		
		this.CheckButtons( );

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "Form", "componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if(
			this.props.prepare != this.#Prepare
		){
			
			console.log( "!!!!!!!!!!!!!!!", this.props.model_schema );
			
			this.#Prepare = this.props.prepare;
			
			/*this.setState( {
				Prepare: this.props.prepare
			} );*/
			
		}
		
		if(
			this.props.model_schema != this.state.ModelSchema
		){
			
			console.log( "!!!!!!!!!!!!!!!", this.props.model_schema );
			
			this.#ModelSchema = this.props.model_schema;
			
			this.setState( {
				ModelSchema: this.props.model_schema
			} );
			
		}
		
		if( 
			( this.state.FormIsSuccess != prevState.FormIsSuccess )
		){
			
			
			
		}
		
		this.SetRequired( );
		
		for(
			let I of this.#CellsRef.keys( )
		){
			
			this.#CellsRef.get( I ).style.display = ( this.#CellsVisible.get( I ) === true ) ? "block" : "none";
			
		}
		
		this.CheckButtons( );
		
		
		if( this.props.preset !== prevProps.preset ){
			
			for( 
				let I of this.#Fields.keys( )
			){
			
				let MetaCell = this.#Fields.get( I );
				
				let Value = this.props.preset[ I ];
				
				if( Value === null ){
					
					Value = this.#Fields.get( I ).DefaultValue;
					
				}
				
				console.log( I, Value );
				
				this.#ValuesOut.set( I, Value );
				
				Input.Set( I, this.#ValuesOut.get( I ), this.#ValuesOut, this.#ModelSchema, this.#InputRef.get( I ), MetaCell );

			}
			
			
			
			this.#TableItems.ComponentTypedGet( ).Clear( );
			
			if( this.props.preset.invoice_items ){
				
				let Items = JSON.parse( this.props.preset.invoice_items );
				
				let KV = new KeyValueBucketT( [ ] );
				
				for(
					let I = 0;
					I < Items.length;
					I++
				){
					
					let Item = Items[ I ];
					
					let KVM = new KeyValueMapT( [ ] );
					
					for( let I2 in Item ){
						
						if( I2 === "unit" ){
							
							KVM.Set( I2, Item[ I2 ].toString( ) );
							
						} else if( I2 === "qty" ){
							
							KVM.Set( I2, Item[ I2 ].toString( ) );
							
						} else if( I2 === "unit_excvat" || I2 === "excvat" ){
							
							KVM.Set( "excvat", Item[ I2 ].toString( ) );
							
						} else if( I2 === "unit_vat" || I2 === "vat" ){
							
							KVM.Set( "vat", Item[ I2 ].toString( ) );
							
						} else if( I2 === "vat_rate" ){
							
							KVM.Set( I2, Item[ I2 ].toString( ) );
							
						} else {
						
							KVM.Set( I2, Item[ I2 ] );
							
						}
						
					}
					
					KV.Set( I + 1, KVM );
					
					this.#TableItems.ComponentTypedGet( ).RowAdd( );
					
				}
				
				console.log( KV );
				
				this.#TableItems.ComponentTypedGet( ).ValuesSet( KV );
				
			}
			
			
			
			console.log( "Form preset update", this.props.preset );
			
			//this.#TableItems.ComponentTypedGet( ).RowAdd( );
			
			this.setState( {
				
				preset: this.props.preset
				
			} );
			
			
			
		}
		
	}

	SetRequired( ){
		
		console.log( "SetRequired" );
		
		for(
			let I of this.#RequiredRef.keys( )
		){
			
			console.log( "SetRequired", I );
			
			let MetaCell = this.#Fields.get( I );
			
			console.log( "SetRequired", I, MetaCell );
			
			/*if( !MetaCell ){
				continue;
			}*/
			
			let IsRequred = 
				MetaCell.IsRequiredFn ? 
					MetaCell.IsRequiredFn( this.#ValuesOut ) : 
						MetaCell.IsRequired;
						
			let ReqRef = this.#RequiredRef.get( I );
			
			if( ReqRef !== null ){
			
				this.#RequiredRef.get( I ).style.display = IsRequred ? "inline-block" : "none";
			
			}
			
		}
		
	}

	CheckItems( ){
		
		let Checked = true;
		
		
		for(
			let I of this.#ItemsIsValid.keys( )
		){
			
			let ItemsIsValid = this.#ItemsIsValid.get( I );

			if( ItemsIsValid === false ){
				
				Checked = false;
				
				break;
				
			}
			
		}
		
		
		return Checked;
		
	}
	
	CheckValues( Values ){
		
		let Fields = this.#Fields;
		
		
		let Checked = true;
		
		for(
			let I of Fields.keys( )
		){
			let Value = Values.get( I );
			
			let MetaCell = Fields.get( I );
			
			let IsCheck = Input.Check( I, MetaCell, Value, this.#ModelSchema, Values );
			
			//TODO if false hightlight fields
			
			console.log( "Form", "CheckValues", I, IsCheck );

			if( IsCheck === false ){
				
				Checked = false;
				
				break;
				
			}
			
		}
		
		
		return Checked;
		
	}
	
	HandleChange( Field, Type, Value ){
		
		console.log( "HandleChange", Field, Type, Value );
		
		if( Field === "company" ){
			
			this.#TableItems.ComponentTypedGet( ).Filter( [ 
				"description"
			] );
			
		}
		
		if( Field === "transferred_vat" ){
			
			console.log( "transferred_vat", Value );
																
			this.#TableItems.ComponentTypedGet( ).VisibleColumnsSet( 
				[ 
					"outinvoice_item_table_cell_input_vat_rate",
					"outinvoice_item_table_cell_input_vat",
					"outinvoice_item_table_header_input_vat_rate",
					"outinvoice_item_table_header_vat"
				], 
				( Value === false ) 
			);
			
		}
		
		
		this.#ValuesOut.set( Field, Value );
		
		let FormIsSuccess = this.CheckValues( this.#ValuesOut );
		
		if( FormIsSuccess === true ){
			
			FormIsSuccess = this.CheckItems( );
			
		}
		
		this.#FormIsSuccess = FormIsSuccess;
		
		this.SetRequired( );
		
		this.CheckButtons( );
	
	}

	ButtonIsDisabled( Id, Action, ActionData ){
		
		if( Action === "FormSend" ){
			
			console.log( "ButtonIsDisabled", Id, Action, ActionData );
			
			if(!this.state){
				
				return true;
				
			}
			
			return !this.#FormIsSuccess || !this.CheckItems( );
				
		} else if( Action === "FormReset" ){
			
			console.log( "ButtonIsDisabled", Id, Action, ActionData, false );
			
			return false;
			
		}
		
		
		console.log( "ButtonIsDisabled", Id, Action, ActionData, true );
		
		return false; //TODO True after tests???
		
	}
	
	FormSendValue( Field ){
		
		console.log( Field );
		
		return Input.Get( Field, this.#ValuesOut, this.props.model_schema );
		
	}
	
	FormSend( Id, ActionData ){
		
		console.log( "FormSend", Id, ActionData );
		
			
		let ValuesToSend = { };
		
		for(
			let I = 0;
			I < ActionData.Fields.length;
			I++
		){
			
			ValuesToSend[ ActionData.Fields[ I ] ] = this.FormSendValue( ActionData.Fields[ I ] );
			
		}
		
		
		if( this.#TableItems !== null ){
		
			let ValuesBucket = this.#TableItems.ComponentTypedGet( ).ValuesGet( 
				[ 
					"code",
					"description",
					"unit",
					"qty",
					"excvat",
					"vat_rate", 
					"vat"
				] 
			);
			
			console.log( "Form.FormSend", ValuesBucket );
			
			let Value = Input.Get( "transferred_vat", this.#ValuesOut, this.props.model_schema );
			
			let ValuesList = [ ];
			
			for(
				let I of ValuesBucket.Keys( )
			){
				
				let Values = { };
				
				let ValueMap = ValuesBucket.Get( I );
				
				for(
					let I2 of ValueMap.Keys( )
				){
					
					if( ( I2 === "vat" ) || ( I2 === "vat_rate" ) ){
					
						if( Value === true ){
							
							Values[ I2 ] = 0;
							
						} else {
							
							Values[ I2 ] = ValueMap.Get( I2 );
							
						}
					
					} else {
						
						Values[ I2 ] = ValueMap.Get( I2 );
						
					}
					
				}
				
				ValuesList.push( Values );
				
			}

			
			for(
				let I in ActionData.Additional
			){
				
				ValuesToSend[ I ] = ActionData.Additional[ I ].bind( this )( ValuesList );
				
			}
		
		}
		
		
		this.state.GridAdd( ValuesToSend );
		
	}
	
	FormReset( Id, ActionData ){
		
		console.log( "FormReset", Id, ActionData );
		
		
		for(
			let I = 0;
			I < ActionData.Fields.length;
			I++
		){
			
			let Field = ActionData.Fields[ I ];
			
			if( !this.#Fields.has( Field ) ){
				
				console.log( "Error", this.#Fields, Field );
				
				throw new Error( "Form has no field" );
				
			}
			
			let MetaCell = this.#Fields.get( Field );
			
			Input.Reset( Field, this.#Fields.get( Field ).DefaultValue, this.#ValuesOut, this.props.model_schema, this.#InputRef.get( Field ), MetaCell );
			
		}
		
		for(
			let I in ActionData.Additional
		){
			
			ActionData.Additional[ I ].bind( this )( this.#Items, this.#ItemsIsValid );
			
		}
		
		console.log( "After_reset", this.#ValuesOut, this.#Items );
		
		
		this.#FormIsSuccess = this.CheckValues( this.#ValuesOut );
		
		if( this.#FormIsSuccess === true ){
			
			this.#FormIsSuccess = this.CheckItems( );
			
		}
		
		if( this.#TableItems !== null ){
			
			this.#TableItems.ComponentTypedGet( ).Clear( );
			
		}
		
		this.CheckButtons( );
		
		
		this.setState( {
			FormIsSuccess: this.#FormIsSuccess
		} );
		
	}
	
	ActionItemAdd( Id, ActionData ){
		
		console.log( "ActionItemAdd", Id, ActionData );
		
		this.#TableItems.ComponentTypedGet( ).RowAdd( );
		
		//this.#TableItemsWrapRef.appendChild( this.#TableItems.ElementGet( ).Render( ) )
		
	}
	
	ButtonOnClick( Id, Action, ActionData ){
		
		console.log( "ButtonOnClick", Action, ActionData );
		
		
		if( Action === "FormSend" ){
			
			this.FormSend( Id, ActionData );
				
		} else if( Action === "FormReset" ){
			
			this.FormReset( Id, ActionData );
			
		} else if( Action === "ItemAdd" ){ //TODO Remove
			
			this.ActionItemAdd( Id, ActionData );
			
		}
		
	}
	
	CheckButtons( ){
		
		console.log( "Form.CheckButtons", this.#Buttons, this.#ButtonsRef );
		
		for(
			let I of this.#Buttons.keys( )
		){
			
			let Button = this.#Buttons.get( I );
			
			if( this.#ButtonsRef.has( Button.Id ) === false ){
				
				continue;
				
			}
		
			this.#ButtonsRef.get( Button.Id ).disabled = this.ButtonIsDisabled( Button.Id, Button.Action, Button.ActionData );
				
		}
		
	}
	
	OnClickClose( ){
		
		this.Ref.style.display = "none";
		
		this.RefBD.style.display = "none";
		
	}
	
	CellVisibleChange( Field, Visible ){
		
		this.#CellsVisible.set( Field, Visible );
		
			
		if( this.#CellsRef.has( Field ) === false ){
			
			return;
			
		}
		
		if( this.#CellsRef.get( Field ) === null ){ //TODO Remove when Cell = Component
			
			return;
			
		}
			
		this.#CellsRef.get( Field ).style.display = ( Visible === true ) ? "block" : "none";
		
	}
	
	ValueChange( Field, Value ){
		
		console.log( "Form.ValueChange", Field, Value, this.#ModelSchema, this.#Fields, this.#ValuesOut );
		
		if( this.#ModelSchema === null ){
			
			return;
			
		}
		
		if( this.#Fields.has( Field ) === false ){
			
			return;
			
		}
		
		let MetaCell = this.#Fields.get( Field );
		
		Input.Set( Field, Value, this.#ValuesOut, this.#ModelSchema, this.#InputRef.get( Field ), MetaCell );
		
	}
	
	ValueGet( Field ){
		
		return this.#ValuesOut.get( Field );
		
	}
	
	InputCreate( ModelCell, MetaCell ){
		
		let InputV = Input.Create( 
			ModelCell, 
			MetaCell, 
			this.#ValuesOut, 
			this.HandleChange.bind( this ), 
			this.state.Locale, 
			this.ModelName, 
			this.props.model_schema,
			this.CellVisibleChange.bind( this ),
			this.#Items,
			this.ValueGet.bind( this ),
			this.ValueChange.bind( this ),
			function( Ref ){
				
				this.#InputRef.set( MetaCell.Field, Ref );
				
			}.bind( this ),
			null,
			null, //Index
			null,//this.#DisableInput,
			null,//this.#ItemValueGet,
			null,//this.#ItemValueChange,
			this.props.model_schema,
			this.props.prepare
		);
		
		return InputV;
		
	}
	
	ElementPreCreate( Element ){
		
		console.log( "ElementCreate", Element );
		
		
		if( Element.Type === "Text" ){
			
			
			
		} else if( Element.Type === "TableItems" ){
			
			console.log( this.props.model_schema.children[ Element.TypeData.Model ], Element );
			
			/*if( this.#Items.has( Element.TypeData.Id ) === false ){
			
				this.#Items.set( Element.TypeData.Id, Element.TypeData.Items );
		
			}*/
		
		} else if( Element.Type === "Table" ){
			
			let Children = Element.TypeData.Children;
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				this.ElementPreCreate( Children[ I ] );
				
			}
			
		} else if( Element.Type === "Row" ){
			
			let Children = Element.TypeData.Children;
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				this.ElementPreCreate( Children[ I ] );
				
			}
			
		} else if( Element.Type === "Cell" ){
			
			console.log( Element.TypeData.Field, this.props.model_schema );
			
			this.#Fields.set( Element.TypeData.Field, Element.TypeData );
			
			if( this.#CellsVisible.has( Element.TypeData.Field ) === false ){
			
				this.#CellsVisible.set( Element.TypeData.Field, true );
			
			}
			
		} else if( Element.Type === "CellContainer" ){
			
			let Children = Element.TypeData.Children;
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				this.ElementPreCreate( Children[ I ] );
				
			}
			
		} else if( Element.Type === "Button" ){
			
			if( this.#Buttons.has( Element.TypeData.Id ) === false ){
			
				this.#Buttons.set( Element.TypeData.Id, Element.TypeData );
			
			}
			
		} else if( Element.Type === "AgGridTable" ){
			
		}
		
	}
	
	#FilterMapByCompany( Options ){
											
		console.log( "FilterMap", "description" );
		
		let Company = this.ValueGet( "company" );
		

		let FilteredOptions = new Map( );
		
		for(
			let I of Options.keys( )
		){
			
			let Option = Options.get( I );
			
			
			if( Option.fields.company_ids === null ){
				
				FilteredOptions.set( I, Option );
				
			} else {
				
				if( Company !== null ){
					
					let CompanyIds = Option.fields.company_ids.split( "//" );
					
					if( CompanyIds.includes( Company.value.toString( ) ) === true ){
						
						FilteredOptions.set( I, Option );
						
					}
					
				}
				
			}
			
		}
		
		
		return FilteredOptions;
				
	}
	
	ElementCreate( Element ){
		
		console.log( "ElementCreate", Element );
		
		
		if( Element.Type === "Text" ){
			
			return ( 
				<div
					className = { Element.TypeData.ClassName }
				>
					{ Element.TypeData.Data }
				</div>
			);
			
		} else if( Element.Type === "TableItems" ){
			
			
			if( this.#TableItems !== null ){
				
				return;
				
			}
			
			
			let ComponentConfig = new ComponentConfigT(
				
				"TableH",
				
				new ComponentTableHConfigT(
					
					new ComponentLayoutHConfigT( 
						
						[
						
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Code" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_code_label"
										)
									) /*,
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_code_req"
										)
									)*/
								],
								"outinvoice_item_table_header_input_code"
							),
					
							new ComponentLayoutElementConfigT(
								
								"col-5",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Description" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_description_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_description_req"
										)
									)
								],
								"outinvoice_item_table_header_input_description"
							),

							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Unit" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_unit_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_unit_req"
										)
									)
								],
								"outinvoice_item_table_header_input_unit"
							),
							
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Quantity" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_qty_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_qty_req"
										)
									)
								],
								"outinvoice_item_table_header_input_qty"
							),
							
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Exc. VAT" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_excvat_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_excvat_req"
										)
									)
								],
								"outinvoice_item_table_header_input_excvat"
							),
							
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "VAT rate" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_vat_rate_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_vat_rate_req"
										)
									)
								],
								"outinvoice_item_table_header_input_vat_rate"
							),
							
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "VAT" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_vat_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "color", "red" )
											] ),
											"header_vat_req"
										)
									)
								],
								"outinvoice_item_table_header_vat"
							),
							
							new ComponentLayoutElementConfigT(
								
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											this.T.Translate( "Delete" ),
											null,
											new StyleMapT( [
												new StyleT( "display", "contents" ),
												new StyleT( "float", "right" )
											] ),
											"header_remove_label"
										)
									),
									
									new ComponentConfigT(
										"Label",
										new ComponentLabelConfigT(
											"*",
											null,
											new StyleMapT( [
												new StyleT( "display", "none" ),
												new StyleT( "color", "red" )
											] ),
											"header_remove_req"
										)
									)
								],
								"outinvoice_item_table_header_button_remove"
							)
						
						],
						
						"row",
						new StyleMapT( [
							new StyleT( "display", "table-row" )
						] ),
					
						"outinvoice_item_table_header"
					),
					
					new ComponentLayoutHConfigT(
			
						[
						
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									/*new ComponentConfigT(
										"SelectText",
										new ComponentSelectTextConfigT(
											
											new ComponentTextConfigT(
												"",
												"Choose",
												"^.{0,512}",
												"^.{0,512}$",
												"FastSelectInput",
												new StyleMapT( [
														
												] )
											),
											
											new ComponentCheckListConfigT(
												false,
												null,
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "grey" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#FFFFFF" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#D0D0D0" )
													] )
												),
												"form-control",
												new StyleMapT( [
													new StyleT( "cursor", "pointer" ),
													new StyleT( "position", "absolute" ),
													new StyleT( "width", "100%" ),
													new StyleT( "height", "auto" ),
													new StyleT( "maxHeight", "40vh" ),
													new StyleT( "overflowY", "auto" ),
													new StyleT( "overflowX", "hidden" ),
													new StyleT( "zIndex", "1" )
												] ),
												"code_checklist"
											),
											
											null,
											new StyleMapT( [
												new StyleT( "position", "relative" ),
												new StyleT( "width", "100%" )
											] ),
											"code"
											
										)
									)*/
									
									new ComponentConfigT(
										"Text",
										new ComponentTextConfigT(
											
											"",
											"Choose",
											"^.{0,512}",
											"^.{0,512}$",
											"form-control",
											new StyleMapT( [
												new StyleT( "position", "relative" ),
												new StyleT( "width", "100%" )
											] ),
											"code"
											
										)
									)
									
								],
								"outinvoice_item_table_cell_input_code"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-5",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"SelectText",
										new ComponentSelectTextConfigT(
											
											new ComponentTextConfigT(
												"",
												"Choose",
												"^.{0,512}",
												"^.{1,512}$",
												"FastSelectInput",
												new StyleMapT( [
														
												] )
											),
											
											new ComponentCheckListConfigT(
												false,
												null,
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "grey" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#FFFFFF" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#D0D0D0" )
													] )
												),
												"form-control",
												new StyleMapT( [
													new StyleT( "cursor", "pointer" ),
													new StyleT( "position", "absolute" ),
													new StyleT( "width", "100%" ),
													new StyleT( "height", "auto" ),
													new StyleT( "maxHeight", "20vh" ),
													new StyleT( "overflowY", "auto" ),
													new StyleT( "overflowX", "hidden" ),
													new StyleT( "zIndex", "1" )
												] ),
												"description_checklist"
											),
											
											null,
											new StyleMapT( [
												new StyleT( "position", "relative" ),
												new StyleT( "width", "100%" )
											] ),
											"description"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_description"
							), 
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Select",
										new ComponentSelectConfigT(
											
											new ComponentTextConfigT(
												"",
												"Choose",
												"^.{0,512}",
												"^.{1,512}$",
												"FastSelectInput",
												new StyleMapT( [
														
												] )
											),
											
											new ComponentCheckListConfigT(
												false,
												null,
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "grey" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#FFFFFF" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#D0D0D0" )
													] )
												),
												"form-control",
												new StyleMapT( [
													new StyleT( "cursor", "pointer" ),
													new StyleT( "position", "absolute" ),
													new StyleT( "width", "100%" ),
													new StyleT( "height", "auto" ),
													new StyleT( "maxHeight", "40vh" ),
													new StyleT( "overflowY", "auto" ),
													new StyleT( "overflowX", "hidden" ),
													new StyleT( "zIndex", "1" )
												] ),
												"unit_checklist"
											),
											
											null,
											new StyleMapT( [
												new StyleT( "position", "relative" ),
												new StyleT( "width", "100%" )
											] ),
											"unit"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_unit"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Double",
										new ComponentDoubleConfigT(
											"",
											"0",
											"^([0-9]{1,8}(\.[0-9]{0,8})?)?",
											"^(?=.*[1-9])[0-9]*[.]?[0-9]{1,8}$",
											"form-control",
											new StyleMapT( [
													
											] ),
											"qty"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_qty"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Double",
										new ComponentDoubleConfigT(
											"",
											"0",
											"^[\\-]{0,1}([0-9]{1,8}(\\.[0-9]{0,32})?)?$",
											"^[\\-]{0,1}(?=.*[1-9])[0-9]*[.]?[0-9]{1,32}$",
											"form-control",
											new StyleMapT( [
													
											] ),
											"excvat"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_excvat"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"SelectText",
										new ComponentSelectTextConfigT(
											
											new ComponentTextConfigT(
												"",
												"Choose",
												"^.{0,512}",
												"^.{1,512}$",
												"FastSelectInput",
												new StyleMapT( [
														
												] )
											),
											
											new ComponentCheckListConfigT(
												false,
												null,
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "grey" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#FFFFFF" )
													] )
												),
												new ComponentLayoutElementConfigT(
													"",
													new StyleMapT( [
														new StyleT( "background-color", "#D0D0D0" )
													] )
												),
												"form-control",
												new StyleMapT( [
													new StyleT( "cursor", "pointer" ),
													new StyleT( "position", "absolute" ),
													new StyleT( "width", "100%" ),
													new StyleT( "height", "auto" ),
													new StyleT( "maxHeight", "40vh" ),
													new StyleT( "overflowY", "auto" ),
													new StyleT( "overflowX", "hidden" ),
													new StyleT( "zIndex", "1" )
												] ),
												"vat_rate_checklist"
											),
											
											null,
											new StyleMapT( [
												new StyleT( "position", "relative" ),
												new StyleT( "width", "100%" )
											] ),
											"vat_rate"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_vat_rate"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Double",
										new ComponentDoubleConfigT(
											"",
											"0",
											"^[\\-]{0,1}([0-9]{1,8}(\.[0-9]{0,32})?)?",
											"^[\\-]{0,1}(?=.*[1-9])[0-9]*[.]?[0-9]{1,32}$",
											"form-control",
											new StyleMapT( [
												new StyleT( "opacity", "0.5" ),
												new StyleT( "pointerEvents", "none" ),
												new StyleT( "backgroundColor", "#e9ecef" )
											] ),
											"vat"
										)
									)
									
								],
								"outinvoice_item_table_cell_input_vat"
							),
							
							new ComponentLayoutElementConfigT(
							
								"col-1",
								new StyleMapT( [
									new StyleT( "display", "table-cell" )
								] ),
								
								[
								
									new ComponentConfigT(
										"Button",
										new ComponentButtonConfigT(
											"btn btn-primary",
											new StyleMapT( [
												new StyleT( "height", "calc(2.25rem + 2px)" )	
											] ),
											[
												new ComponentConfigT(
													"Label",
													new ComponentLabelConfigT(
														"",
														"fa fa-minus",
														new StyleMapT( [
															
														] )
													)
												)
											],
											"button_remove"
										)
									)
									
								],
								"outinvoice_item_table_cell_button_remove"
							)
							
						],
						
						"row",
						new StyleMapT( [
							new StyleT( "display", "table-row" )
						] ),
					
						"outinvoice_item_table_row"
						
					),
					
					"container",
					
					new StyleMapT( [
						new StyleT( "display", "table" ),
						new StyleT( "maxWidth", "100%" )
					] ),
					
					"outinvoice_item_table"
					
				)
			);
			
			
			//OnChange: function( Value, ValueGet, ValueChange, CellVisisbleChange, Items, ModelSchemaObject, Index, DisableItemField, ItemValueGet, ItemValueChange ){ //TODO hide Field
			//IsRequired: true,
			//Editable: true,
			
			this.#TableItems = ComponentT.FromConfig(
				
				ComponentConfig
			);
			
			let PrepareMap = new Map( [
				[
					"outinvoice_item_table",
					function( Component ){
						
						console.log( "Form.Prepare", "outinvoice_item_table", Component );
						
					}.bind( this )
				],
				[ 
					"code",
					function( Component ){
						
						console.log( "Form.Prepare", "code", Component );
					
						/*let ModelSchema = this.props.model_schema.children[ Element.TypeData.Model ];
					
						if( !ModelSchema ){
							
							throw new Error( "Model schema not found" );
							
						}
						
						let OptionsMap = new Map( );
						
						for( let I of ModelSchema.options_object.get( "product_types" ).keys( ) ){
							
							let Element = ModelSchema.options_object.get( "product_types" ).get( I );
							
							if( Element.fields.code !== null ){
								
								OptionsMap.set( Element.value, Element );
								
							}
							
						}
						
						Component.OptionMapSet(
							OptionsMap
						);*/
						
						/*Component.OptionMapFilterSet(
							function( ){
								
								
							}.bind( this )
						)*/
							
						/*Component.ComponentTypedGet( ).OptionMapSet(
							OptionsMap
						);*/
							
					}.bind( this )
				],
				[ 
					"description",
					function( Component ){
					
						let ModelSchema = this.props.model_schema.children[ Element.TypeData.Model ];
					
						if( !ModelSchema ){
							
							throw new Error( "Model schema not found" );
							
						}
						
						let OptionMap = ModelSchema.options_object.get( "outinvoice_item_names" );
						
						Component.OptionMapSet(
							OptionMap
						);
						
						Component.FilterSet(
							this.#FilterMapByCompany.bind( this )
						);
							
						/*Component.ComponentTypedGet( ).OptionMapSet(
							ModelSchema.options_object.get( "outinvoice_item_names" )
						);*/
							
					}.bind( this )
				],
				[ 
					"unit",
					function( Component ){
					
						let ModelSchema = this.props.model_schema.children[ Element.TypeData.Model ];
					
						if( !ModelSchema ){
							
							throw new Error( "Model schema not found" );
							
						}
						
						let OptionMap = ModelSchema.custom_options_object.get( ModelSchema.model_name ).get( "unit" );
							
						Component.OptionMapSet(
							OptionMap
						);
						/*Component.ComponentTypedGet( ).OptionMapSet(
							OptionMap
						);*/
							
					}.bind( this )
				],
				[ 
					"vat_rate",
					function( Component ){
					
						let ModelSchema = this.props.model_schema.children[ Element.TypeData.Model ];
					
						if( !ModelSchema ){
							
							throw new Error( "Model schema not found" );
							
						}
						
						let OptionMap = ModelSchema.custom_options_object.get( ModelSchema.model_name ).get( "vat_rate" );
							
						Component.OptionMapSet(
							OptionMap
						);	
							
						/*Component.ComponentTypedGet( ).OptionMapSet(
							OptionMap
						);*/
							
					}.bind( this )
				],
				[
					"vat",
					function( Component ){

						/*Component.StyleMapSet(
							new StyleMapT( [
								new StyleT( "opacity", "0.5" ),
								new StyleT( "pointerEvents", "none" )
							] )
						);	*/
							
						/*Component.ComponentTypedGet( ).OptionMapSet(
							OptionMap
						);*/
							
					}.bind( this )
				]
				
			] );
			
			/*Component.ComponentTypedGet( ).Listen(
				new EventMapT( [ 
					new EventT(
						"RowAdd",
						function( ){
							
							ComponentT.Prepare( Component, PrepareMap, EventBucket );
							
						}
					)
				] )
			);*/
			
			let EventBucket = new EventBucketT( [
				new EventObjectT(
					"outinvoice_item_table",
					new EventMapT( [
						new EventT(
							"ButtonClick", 
							function( RowIndex, CellIndex, Name ){
								
								console.log( RowIndex, CellIndex, Name );
								
								/*let InputCode = this.#TableItems.ComponentTypedGet( ).ElementGet( RowIndex, 0, "code" );
								
								console.log( InputCode );
								
								let RowValuesMap = this.#TableItems.ComponentTypedGet( ).ValuesLayoutGet( 
									RowIndex,
									[ 
										"code" //,
										//"description",
										//"vat_rate", 
										//"vat" 
									] 
								);
								
								let ValuesMap = this.#TableItems.ComponentTypedGet( ).ValuesGet( 
									[ 
										"code" //,
										//"description",
										///"vat_rate", 
										//"vat" 
									] 
								);
								
								this.#TableItems.ComponentTypedGet( ).ValuesLayoutSet( 
									RowIndex,
									new KeyValueMapT( [
										new KeyValueT(
											"code",
											"223"
										)
									] )
								);
								
								this.#TableItems.ComponentTypedGet( ).ValuesSet( 
									new KeyValueBucketT( [ 
										new KeyValueElementT(
											1,
											new KeyValueMapT( [
												new KeyValueT(
													"code",
													"223"
												)
											] )
										)
										//"description",
										///"vat_rate", 
										//"vat" 
									] )
								);
								
								console.log( RowValuesMap );
								
								console.log( ValuesMap );
								
								
								let ValuesCheckBucket = this.#TableItems.ComponentTypedGet( ).ValuesCheck( 
									[ 
										"code",
										"description",
										///"vat_rate", 
										//"vat" 
									] 
								);
								
								for(
									let I of ValuesCheckBucket.Keys( )
								){
									
									let ValuesCheckMap = ValuesCheckBucket.Get( I );
									
									for(
										let I2 of ValuesCheckMap.Keys( )
									){
										
										let Value = ValuesCheckMap.Get( I2 );
										
										console.log( Value );
										
									}
									
								}
								
								console.log( ValuesCheckBucket );*/
								
								//InputCode.ComponentTypedGet( ).HighlightError( );
								
								this.#TableItems.ComponentTypedGet( ).RowRemove( RowIndex );
								
							}.bind( this ),
						),
						new EventT(
							"InputChange",
							function( RowIndex, CellIndex, Name, Value ){
								
								console.log( " ", RowIndex, CellIndex, Name, Value );
								
								let ValuesCheckMap = this.#TableItems.ComponentTypedGet( ).ValuesElementCheck( 
									RowIndex,
									CellIndex,
									[ Name ]
								);
								
								if( ValuesCheckMap.Get( Name ) === false ){
									
									
									this.#TableItems.ComponentTypedGet( ).StyleMapElementSet(
										RowIndex,
										CellIndex,
										[ Name ],
										new StyleMapT( [
											new StyleT( "borderColor", "#f90000" ),
											new StyleT( "boxShadow", "0 0 0 0.2rem rgba(245, 102, 102, 0.5)" )
										] )
									);
									
								} else {
									
									this.#TableItems.ComponentTypedGet( ).StyleMapElementSet(
										RowIndex,
										CellIndex,
										[ Name ],
										new StyleMapT( [
											new StyleT( "borderColor", "#80bdff" ),
											new StyleT( "boxShadow", "inset 0 0 rgb(0, 0, 0)" )
										] )
									);
									
								}
								
								if( ( Name === "excvat" ) || ( Name === "qty" ) || ( Name === "vat_rate" ) ){
									
									let ValuesMap = this.#TableItems.ComponentTypedGet( ).ValuesLayoutGet( 
										RowIndex,
										[ "excvat", "qty", "vat_rate" ]
									);
									
									console.log( ValuesMap );
									
									let ExcVAT = ValuesMap.Get( "excvat" );
											
									let QTY = ValuesMap.Get( "qty" );
									
									console.log( ExcVAT );
									
									
									if( ValuesMap.Get( "vat_rate" ) !== "" ){
										
										let VATRate = parseFloat( ValuesMap.Get( "vat_rate" ) );
										
										let VAT = ( ( ExcVAT * QTY ) / 100 ) * VATRate;
										
										this.#TableItems.ComponentTypedGet( ).ValuesLayoutSet( 
											RowIndex,
											new KeyValueMapT( [
												new KeyValueT(
													"vat",
													VAT.toString( )
												)
											] )
										);
										
									} 
									
									
								}
								
								if( Name === "description" ){
									
									let ModelSchema = this.props.model_schema.children[ Element.TypeData.Model ];
					
									if( !ModelSchema ){
										
										throw new Error( "Model schema not found" );
										
									}
									
									let OptionMap = ModelSchema.options_object.get( "outinvoice_item_names" );
									
									let Option = null;
									
									console.log( OptionMap );
									
									for(
										let I of OptionMap.keys( )
									){
										
										console.log( OptionMap.get( I ).label, Value  );
										
										if( OptionMap.get( I ).label === Value ){
											
											Option = OptionMap.get( I );
											
											break;
											
										}
										
									}
									
									if( Option !== null ){
										
										console.log( "VATRate" );
										
										let Code = Option.fields.code;
										
										let VATRate = Option.fields.dph;
										
										console.log( VATRate );
										
										if( Code !== null ){
											
											this.#TableItems.ComponentTypedGet( ).ValuesLayoutSet( 
												RowIndex,
												new KeyValueMapT( [
													new KeyValueT(
														"code",
														Code
													)
												] )
											);
											
										}
										
										if( VATRate !== null ){
											
											this.#TableItems.ComponentTypedGet( ).ValuesLayoutSet( 
												RowIndex,
												new KeyValueMapT( [
													new KeyValueT(
														"vat_rate",
														VATRate.toString( )
													)
												] )
											);
												
											let ValuesMap = this.#TableItems.ComponentTypedGet( ).ValuesLayoutGet( 
												RowIndex,
												[ "excvat", "qty" ]
											);
											
											console.log( ValuesMap );
											
											let ExcVAT = ValuesMap.Get( "excvat" );
											
											let QTY = ValuesMap.Get( "qty" );
											
											console.log( ExcVAT );
										
											let VAT = ( ( ExcVAT * QTY ) / 100 ) * VATRate;
											
											console.log( VAT );
											
											this.#TableItems.ComponentTypedGet( ).ValuesLayoutSet( 
												RowIndex,
												new KeyValueMapT( [
													new KeyValueT(
														"vat",
														VAT.toString( )
													)
												] )
											);
											
										}
										
									}
									
								}
								
								
								let TransferredVAT = Input.Get( "transferred_vat", this.#ValuesOut, this.props.model_schema );
								
								let Columns = null;
								
								if( TransferredVAT === false ){
								
									Columns = [ 
										"code",
										"description",
										"unit",
										"qty",
										"excvat",
										"vat_rate" //, 
										//"vat"
									];
								
								} else {
									
									Columns = [ 
										"code",
										"description",
										"unit",
										"qty",
										"excvat"
									];
									
								}
								
								let ValuesCheckBucket = this.#TableItems.ComponentTypedGet( ).ValuesCheck( 
									Columns
								);
								
								console.log( ValuesCheckBucket );
								
								let IsValid = true;
								
								for(
									let I of ValuesCheckBucket.Keys( )
								){
									
									let ValuesCheckMap = ValuesCheckBucket.Get( I );
									
									for(
										let I2 of ValuesCheckMap.Keys( )
									){
										
										let ValueCheck = ValuesCheckMap.Get( I2 );
										
										if( ValueCheck === false ){
											
											IsValid = false;
											
											break;
											
										}
										
									}
									
									if( IsValid === false ){
										
										break; //TODO optimize
										
									}
									
								}
									
									
								this.#ItemsIsValid.set( Element.TypeData.Id, IsValid );
								
								
								let FormIsSuccessValues = this.CheckValues( this.#ValuesOut );
								
								let FormIsSuccessItems = this.CheckItems( );
								
								console.log( "FormValidation", FormIsSuccessValues, FormIsSuccessItems );
								
								if( ( FormIsSuccessValues === true ) && ( FormIsSuccessItems === true ) ){
									
									this.#FormIsSuccess = true;
									
								} else {
									
									this.#FormIsSuccess = FormIsSuccessValues;
									
								}
								
								let ValuesBucket = this.#TableItems.ComponentTypedGet( ).ValuesGet( 
									[ 
										"code",
										"description",
										"unit",
										"qty",
										"excvat",
										"vat_rate", 
										"vat"
									] 
								);
								
								Element.TypeData.OnChange(
									this.ValueGet.bind( this ),
									function( Field, Name ){
										
										this.ValueChange( Field, Name )
										
									}.bind( this ),
									ValuesBucket
								);
								
								this.CheckButtons( );
								
							}.bind( this )
						),
						new EventT(
							"InputFocus",
							function( RowIndex, CellIndex, Name ){
								
								console.log( "Form.InputFocus", RowIndex, CellIndex, Name );
								
							}.bind( this )
						),
						new EventT(
							"InputBlur",
							function( RowIndex, CellIndex, Name ){
								
								console.log( "Form.InputBlur", RowIndex, CellIndex, Name );
								
								
								let ValuesCheckMap = this.#TableItems.ComponentTypedGet( ).ValuesElementCheck( 
									RowIndex,
									CellIndex,
									[ Name ]
								);
								
								console.log( ValuesCheckMap );
								
								
								if( ValuesCheckMap.Get( Name ) === false ){
									
									
									this.#TableItems.ComponentTypedGet( ).StyleMapElementSet(
										RowIndex,
										CellIndex,
										[ Name ],
										new StyleMapT( [
											new StyleT( "borderColor", "#f90000" ),
											new StyleT( "boxShadow", "0 0 0 0.2rem rgba(245, 102, 102, 0.5)" )
										] )
									);
									
								} else {
									
									this.#TableItems.ComponentTypedGet( ).StyleMapElementSet(
										RowIndex,
										CellIndex,
										[ Name ],
										new StyleMapT( [
											new StyleT( "borderColor", "#80bdff" ),
											new StyleT( "boxShadow", "inset 0 0 rgb(0, 0, 0)" )
										] )
									);
									
								}
								
								//InputCode.ComponentTypedGet( ).HighlightError( );
								
							}.bind( this )
						)
					] )
				)
			] );

			ComponentT.Prepare(
				this.#TableItems,
				PrepareMap,
				EventBucket
				
			);
		
		} else if( Element.Type === "Table" ){
			
			let Children = Element.TypeData.Children;
			
			let Rows = [ ];
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				Rows.push( this.ElementCreate( Children[ I ] ) );
				
			}
			
			return Table( Rows );
			
		} else if( Element.Type === "Row" ){
			
			let Children = Element.TypeData.Children;
			
			let Cells = [ ];
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				Cells.push( this.ElementCreate( Children[ I ] ) );
				
			}
			
			return Row( Cells );
			
		} else if( Element.Type === "Cell" ){
			
			console.log( Element.TypeData.Field, this.props.model_schema );
			
			
			let ModelSchemaObject = this.props.model_schema.model_schema_object;//this.state.ModelSchema.model_schema_object;
			
			let Input = this.InputCreate( ModelSchemaObject.get( Element.TypeData.Field ), Element.TypeData );
			
			
			return Cell( 
				Element.TypeData.Name, 
				Input, 
				Element.TypeData.ClassName, 
				function( Ref ){ 
					this.#CellsRef.set( Element.TypeData.Field, Ref ); 
				}.bind( this ),
				this.#CellsVisible.get( Element.TypeData.Field ),
				function( Ref ){ 
					this.#RequiredRef.set( Element.TypeData.Field, Ref ); 
				}.bind( this )
			);
			
		} else if( Element.Type === "CellContainer" ){
			
			let Children = Element.TypeData.Children;
			
			let ElementData = [ ];
			
			for(
				let I = 0;
				I < Children.length;
				I++
			){
				
				ElementData.push( this.ElementCreate( Children[ I ] ) );
				
			}
			
			return CellContainer( Element.TypeData.ClassName, ElementData );
			
		} else if( Element.Type === "Button" ){
			
			return ( 
				<Button
					ref_cb = { ( Ref ) => { this.#ButtonsRef.set( Element.TypeData.Id, Ref ) } }
					id = { Element.TypeData.Id }
					class_name = { Element.TypeData.ClassName }
					icon = { Element.TypeData.Icon }
					action = { Element.TypeData.Action }
					action_data = { Element.TypeData.ActionData }
					title = { Element.TypeData.Title }
					disabled = { this.ButtonIsDisabled( Element.TypeData.Id, Element.TypeData.Action, Element.TypeData.ActionData ) }
					on_click_cb = { ( ) => { this.ButtonOnClick( Element.TypeData.Id, Element.TypeData.Action, Element.TypeData.ActionData ) } }
					display = { "block" }
				>
				</Button>
			);
			
		} else if( Element.Type === "AgGridTable" ){
			
		} else if( Element.Type === "ItemBlock" ){
			
			return <div ref={ ( Ref )=> { this.#TableItemsWrapRef = Ref; if( Ref!== null){ Ref.appendChild( this.#TableItems.Element( ).Render( ) ) } } } className="container" style={{maxWidth:"100%" }}>
				
			</div>
			
		}
		
	}
	
	ContentCreate( Children ){
		
		let Elements = [ ];
		
		for( 
			let I = 0;
			I < Children.length;
			I++
		){
			
			this.ElementPreCreate( Children[ I ] );
		
		}
		
		for( 
			let I = 0;
			I < Children.length;
			I++
		){
			
			Elements.push( this.ElementCreate( Children[ I ] ) );
		
		}
		
		return Elements;
		
	}
	
	Hide( Field ){
		
	}
	
	ShowField( Field ){
		
	}
	
    render( ) {
		
		console.log( "Form", "render", this.props.model_schema, this.props.prepare );
		
		if( this.props.is_content_only ){
			
			let Content = ( this.props.model_schema && this.props.prepare ) ? 
				this.ContentCreate( this.#Children ) : 
				[ ];
				
			return <div className="container" style={{height:"60vh",overflowX:'auto', overflowY:"none", maxWidth:"100%" }}>
				{ Content }
			</div>;
			
		}
		
		let Content = ( this.props.model_schema && this.props.prepare ) ? 
			this.ContentCreate( this.#Children ) : 
			[ ];
			
		console.log( Content );
			
		
        return (
			<React.Fragment>
			<div 
				ref={ ( input ) => { this.Ref = input; this.state.OnRef( input ) }} 
				className={ "modal" }
				style={ { zIndex:1050, position: "absolute", width: "100%", height: "100%", display: "none" }}
			>
				<div className={"modal-dialog"} style={ { "maxWidth": this.#Data.MaxWidth } }>
					<div className="modal-content">
						<div className="modal-header">
						
							<h4 className="modal-title">{ this.props.title }</h4>
							
							<Button
								ref_cb = { ( Ref ) => { this.#ButtonsRef.set( "ButtonFormClose", Ref ) } }
								id = { "ButtonFormClose" }
								class_name = { "ButtonSquare" }		
								icon = { "far fa-window-close" }
								action = { "Close" }
								action_data = { { } }
								title = { "" }
								disabled = { false }
								on_click_cb = { ( ) => { this.OnClickClose( ) } }
								display = { "block" }
							>
							
							</Button>
							
						</div>
						<div className="modal-body" >
						
							<div className="container" style={{height:"60vh",overflowX:'auto', overflowY:"none", maxWidth:"100%" }}>
								{ Content }
							</div>

							<div className="container" style={{height:'5vh'}}>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			<div
				ref={ ( input ) => { this.RefBD = input; this.state.OnRefBD( input ) }} 
				className={ "modal-backdrop fade" }
				style={ { display: "none", opacity: 0.5 } }
			>
			</div>
		</React.Fragment>
        );
    }
}

Form.propTypes = {
	
    t: PropTypes.func.isRequired
	
};

export default translate( )( Form );

