import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
    onAdd: (data) => void;
}

class RoleAdd extends Component<Props> {
    state = { name: ""};
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name} = this.state;
        this.props.onAdd({  name});
    }


    render() {
        const {  name} = this.state;



        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-5">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>



                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
RoleAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(RoleAdd);

