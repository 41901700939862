import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";


const ModelControllerOptionReduce = function( Obj ){
	
	Obj.options_object = new Map( );
				
	for( 
		let I in Obj.options
	){
		
		let Arr = Obj.options[ I ];
		
		let M = new Map( );
		
		for(
			let I2 = 0;
			I2 < Arr.length;
			I2++
		){
		
			M.set( Arr[ I2 ].value.toString( ), Arr[ I2 ] );
			
		}
		
		Obj.options_object.set( I, M );
		
	}
	
};

const ModelControllerOptionsReduce = function( Obj ){
	
	for(
		let Iterator in Obj.children
	){
		
		ModelControllerOptionsReduce( Obj.children[ Iterator ] );
		
	}
	
	
	ModelControllerOptionReduce( Obj );
	
};


const ModelControllerCustomOptionReduce = function( Obj ){
	
	Obj.custom_options_object = new Map( );
				
	for( 
		let I in Obj.custom_options
	){
		
		let CustomByModel = Obj.custom_options[ I ];
		
		let M1 = new Map( );
		
		for( 
			let I2 in CustomByModel
		){
		
			let Arr = CustomByModel[ I2 ];
			
			let M2 = new Map( );
			
			for(
				let I3 = 0;
				I3 < Arr.length;
				I3++
			){
			
				M2.set( Arr[ I3 ].value.toString( ), Arr[ I3 ] );
				
			}
			
			M1.set( I2, M2 );
		
		}
		
		Obj.custom_options_object.set( I, M1 );
		
	}
	
};

const ModelControllerCustomOptionsReduce = function( Obj ){
	
	for(
		let Iterator in Obj.children
	){
		
		ModelControllerCustomOptionsReduce( Obj.children[ Iterator ] );
		
	}
	
	
	ModelControllerCustomOptionReduce( Obj );
	
};

const ModelControllerPrepareReduce = function( Data ){
	
	let Result = new Map( );
				
	for( let I in Data ){
		
		let Item = Data[ I ];
		
		let ResultItem = new Map( );
		
		for( let I2 in Item ){
			
			ResultItem.set( I2, Item[ I2 ] );
			
		}
		
		Result.set( I, ResultItem )
		
	}
	
	return Result;
	
};


const ModelControllerSchemaElementReduce = function( Obj ){
	
	Obj.model_schema_object = new Map( );
	
	for( 
		let I = 0;
		I < Obj.model_schema.length;
		I++
	){
		
		let ModelElement = Obj.model_schema[ I ];
		
		Obj.model_schema_object.set( ModelElement.field, ModelElement );
		
	}
	
};

const ModelControllerSchemaReduce = function( Obj ){
	
	for(
		let Iterator in Obj.children
	){
		
		ModelControllerSchemaReduce( Obj.children[ Iterator ] );
		
	}
	
	
	ModelControllerSchemaElementReduce( Obj );
	
};


const ModelController = class ModelController {
	
	#ModelName = null;
	
	constructor(
		ModelName
	){
		
		this.#ModelName = ModelName;
		
	}
	
	Schema(
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/schema/" + this.#ModelName;
		
		HttpService.get( URL ).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	SchemaFull(
		ModelSchema,
		
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/schema_full";
		
		HttpService.post( URL, ModelSchema ).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				
				let Data = ResData.data;
				
				ModelControllerOptionsReduce( Data );
				
				ModelControllerCustomOptionsReduce( Data );
				
				ModelControllerSchemaReduce( Data );
				
				SuccessCb( Data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	Prepare(
		PrepareV,
		
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/prepare_full";
		
		HttpService.post( URL, PrepareV ).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				
				let Data = ResData.data;
				
				let Result =  ModelControllerPrepareReduce( Data );
				
				SuccessCb( Result );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	Create(
		Data,
		
		SuccessCb,
		ErrorCb
	){
		
		console.log( "Create", Data );
		
		const URL = UrlService.apiDomain( ) + "api/create/" + this.#ModelName;
		
		return HttpService.post( 
			URL,
			{ 
				fields: Data 
			}
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	CreateFull(
		ModelSchema,
		Prepare,
		Data,
	
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/create_full";
	
		HttpService.post( 
			URL,
			{
				schema: ModelSchema,
				prepare: Prepare,
				fields: Data
			}
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				ResData.data.prepare = ModelControllerPrepareReduce( ResData.data.prepare );
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	CreateOrUpdate(
		Data,
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/create_or_update/" + this.#ModelName;
		
		return HttpService.post( 
			URL,
			Data
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	ReadOne(
		Id,
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_one/" + this.#ModelName + "/" + Id;
	
		HttpService.get( 
			URL
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	ReadAll( 
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_all/" + this.#ModelName;
	
		HttpService.get( 
			URL
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	ReadAllWithChildren(
		Children,
	
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_all_with_children/" + this.#ModelName;
	
		HttpService.post( 
			URL,
			{
				children: Children 
			}
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	ReadAllOption(
		FieldsValue,
		FieldsValueJoiner,
		FieldsLabel,
		FieldsLabelJoiner,
		
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_all_option/" + this.#ModelName;
	
		HttpService.post( 
			URL,
			{
				fields_value: FieldsValue,
				fields_value_joiner: FieldsValueJoiner,
				fields_label: FieldsLabel,
				fields_label_joiner: FieldsLabelJoiner
			}
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	ReadAllCustomOption(
		Field,
		FieldsValue,
		FieldsValueJoiner,
		FieldsLabel,
		FieldsLabelJoiner,
		
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_all_custom_option/" + this.#ModelName;
	
		HttpService.post( 
			URL,
			{
				field: Field,
				fields_value: FieldsValue,
				fields_value_joiner: FieldsValueJoiner,
				fields_label: FieldsLabel,
				fields_label_joiner: FieldsLabelJoiner
			}
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	ReadAllFull(
		ModelSchema,
	
		SuccessCb,
		ErrorCb
	) {

		const URL = UrlService.apiDomain( ) + "api/read_all_full";
	
		HttpService.post( 
			URL,
			ModelSchema
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				//TODO Lists
				
				SuccessCb( ResData.data );
				
			}
			
		).catch(
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);

	}
	
	Update(
		Id,
		Field,
		Value,
		
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/update/" + this.#ModelName + "/" + Id;
	
		HttpService.put( 
			URL,
			{
				field: Field,
				value: Value
			}
		).then(
			
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText, ResData.data );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message, ResData.data );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch( 
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
	
	Delete(
		Id,
		
		SuccessCb,
		ErrorCb
	){
		
		const URL = UrlService.apiDomain( ) + "api/delete/" + this.#ModelName + "/" + Id;
	
		HttpService.delete( 
			URL
		).then(
		
			function( Res ){
				
				if( Res.status !== 200 ){
					
					ErrorCb( Res.statusText );
					
					return;
					
				}
				
				let ResData = Res.data;
				
				if( ResData.success === false ){
					
					ErrorCb( ResData.message );
					
					return;
					
				}
				
				SuccessCb( ResData.data );
				
			}
			
		).catch( 
		
			function( Error ) {
	
				ErrorCb( Error.message );
		
			}
			
		);
		
	}
  
};


export default ModelController;
