import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import

interface Props {
    onAdd: (data) => void;
}

class MassSMS extends Component<Props> {

    constructor(props) {
        super(props)

        this.state = {

            allow_submit: false,
            message:'',
            added_address:'',
        };

    }


    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {

            this.setState({ message: '',

                added_address: '',
                allow_submit:false});
        }
    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { message, added_address } = this.state;


        confirmAlert({
            title: this.props.t('Confirm to send'),
            message: this.props.t('Are you sure you want to send mass message?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        this.props.onAdd({ message, added_address });

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });





    }


    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        const {message, added_address } = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">{this.props.t('Your phone')}</label>

                    <div className="col-sm-10">
                        <input
                            type="text"
                            placeholder={"+420608123456"}
                            required
                            className="form-control"
                            value={added_address}
                            onChange={(event) => this.setState({ added_address: event.target.value })}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-sm-2 col-form-label">{this.props.t('Message')}</label>
                    <div className="col-sm-10">
                        <textarea required value= {message} className="form-control" onChange={(event) => this.setState({ message: event.target.value })} />

                    </div>
                </div>


                <div className="form-group row">
                    <div className="offset-sm-2 col-sm-10">
                        <button type="submit" className="btn btn-danger">
                            {this.props.t('Submit for')+' '+this.props.addresses.length+' '+this.props.t('numbers')}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}
MassSMS.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(MassSMS);

