/* global google */
import React from "react";

import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    DirectionsRenderer
} from "react-google-maps";

class MapDirectionsRenderer extends React.Component {
    state = {
        directions: null,
        distance:0,
        error: null
    };

    shortest_origin=[];

    arraysMatch (arr1, arr2) {

    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }

    // Otherwise, return true
    return true;

};

    shouldComponentUpdate( nextProps, nextState ){

        if (!this.arraysMatch(this.props.places,nextProps.places)) {
            console.log(this.props.places);
            console.log(nextProps.places);
            console.log('UPDATING MAP');
            return true;
        } else {
        return false;
        }
    }

    requestRoute(origin_location, destination, travelMode, waypoints, sets) {
        const directionsService = new google.maps.DirectionsService();

        var origin=destination;
        if (sets) origin=origin_location;


        directionsService.route(
            {
                origin: origin,
                destination: destination,
                optimizeWaypoints:true,
                travelMode: travelMode,
                waypoints: waypoints
            },
            (result, status) => {

                if (status === google.maps.DirectionsStatus.OK) {
                    var distance=0;
                    result.routes[0].legs.forEach((e)=>{
                        distance+=e.distance.value;
                    });
                    if (sets) {
                        this.setState({
                            directions: result,
                            distance:distance
                        });
                    } else {
                        this.requestRoute({lat:result.routes[0].legs[0].end_location.lat(), lng:result.routes[0].legs[0].end_location.lng()}, destination, travelMode, waypoints, true)

                    }
                } else {
                    this.setState({ error: result });
                }
            }
        );
    }

    componentDidUpdate() {


        /*
        commented 08.07.2021
        const { places, travelMode } = this.props;
console.log(places);
        const waypoints = places.map(p =>({
            location: {lat: p.latitude, lng:p.longitude},
            stopover: true
        }))
       // const origin = waypoints.shift().location;
        const origin = {lat:50.16221887, lng:14.0579977};
       // const destination = waypoints.pop().location;
        const destination = {lat:50.16221887, lng:14.0579977};
        this.shortest_origin=[];
      if (waypoints.length>0)  this.requestRoute(0, destination, travelMode, waypoints, false);
*/

    }

    render() {
        console.log(this.state);
        if (this.state.error) {
            return <h1>{this.state.error}</h1>;
        }
        return (
            <>
        {this.state.directions &&
        <DirectionsRenderer directions={this.state.directions}/>
    }
            Distance: <span id="mapdistance">{this.state.distance}</span> m.
        </>
        )
    }
}

const Map = withScriptjs(
    withGoogleMap(props => (
        <>
        <MapDirectionsRenderer
            places={props.markers}
            travelMode={google.maps.TravelMode.DRIVING}
        />
        <GoogleMap
            defaultCenter={props.defaultCenter}
            defaultZoom={props.defaultZoom}
        >
            {props.markers.map((marker, index) => {
                const position = { lat: marker.latitude, lng: marker.longitude };
                return <Marker key={index} position={position} />;
            })}

        </GoogleMap>
        </>
    ))
);

export default Map;
