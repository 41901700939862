import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

class FileUploadComponent extends Component
{
    constructor(props) {


        super(props);
        this.state ={
            image: ''
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    onFormSubmit(e){
        e.preventDefault()
        this.fileUpload();
    }
    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                image: e.target.result
            })
        };
        reader.readAsDataURL(file);
    }
    fileUpload(){
        const fileInput = document.querySelector('#myfile') ;
        if (fileInput.files.length===0) {
            alert(this.props.t('Please choose the file.'));
        } else {

            const formData = new FormData();
            formData.append('file', fileInput.files[0]);




            HttpService.post(UrlService.apiDomain()+'api/companies/import', formData)
                .then(res => {
                    if (res.data===null) {
                        alert("File import error");
                        return false;
                    }
                    if (res.data.result==='failed') {
                        alert(res.data.message);
                        console.log(res);

                    } else {
                        if (res.data.message==='success') {
                            alert(this.props.t('File imported successfully'));
                            console.log('Successfully updated');
                            window.location.reload();
                        } else {
                           alert("File import error");
                        }


                    }
                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                })


        }


    }

    render()
    {
        return(
            <form onSubmit={this.onFormSubmit}><button  type="submit"> {this.props.placeholder}</button><input id="myfile" name="myfile" type="file" /></form>
        )
    }
}

FileUploadComponent.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(FileUploadComponent);