"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentBaseT from "../../../../Interface/UI/Component/Base/UIComponentBaseT.mjs";

import UIComponentTextDataT from "../../../../Interface/UI/Component/TextData/UIComponentTextDataT.mjs";

import UIComponentPlaceholderT from "../../../../Interface/UI/Component/Placeholder/UIComponentPlaceholderT.mjs";


import UIComponentTextConfigT from "../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";


const UIComponentTextT = class UIComponentTextT extends UIComponentBaseT {

	#UIComponentTextData = null;
	
	#UIComponentPlaceholder = null;

	constructor(
		UIElement,
		UIComponentTextConfig
	){
		
		if( ( UIElement instanceof UIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( UIComponentTextConfig instanceof UIComponentTextConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
	
		super(
			UIElement,
			UIComponentTextConfig
		);
		
		
		this.#UIComponentPlaceholder = new UIComponentPlaceholderT(
			new UIElementT( ),
			UIComponentTextConfig.PlaceholderConfigGet( )
		);
		
		this.#UIComponentTextData = new UIComponentTextDataT(
			new UIElementT( ),
			UIComponentTextConfig.TextDataConfigGet( )
		);
		
		
		super.ChildSet( 0, this.#UIComponentPlaceholder );
		
		super.ChildSet( 1, this.#UIComponentTextData );
		
		
			
		let UIStateMap = UIComponentTextConfig.StateMapGet( );
		
		if( this.IsValidGet( ) === true ){
		
			if( UIStateMap.Exists( "Valid" ) === true ){
			
				let UIState = UIStateMap.Get( "Valid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		} else {
			
			if( UIStateMap.Exists( "Invalid" ) === true ){
			
				let UIState = UIStateMap.Get( "Invalid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
	
	}
	
	TextDataGet(
	
	){
	
		return this.#UIComponentTextData;
	
	}
	
	PlaceholderGet(
	
	){
		
		return this.#UIComponentPlaceholder;
		
	}
	
	#PlaceholderHide( 
	
	){
		
		console.log( "UIComponentTextT.#PlaceholderHide" );
		
		
		if( this.EventExists( "OnPlaceholderHide" ) === true ) {
			
			let EventFn = this.EventGet( "OnPlaceholderHide" );
		
			EventFn(
			
			);
		
		}
		
	}
	
	#PlaceholderShow(
	
	){
		
		console.log( "UIComponentTextT.#PlaceholderShow" );
		
			
		if( this.EventExists( "OnPlaceholderShow" ) === true ) {
			
			let EventFn = this.EventGet( "OnPlaceholderShow" );
		
			EventFn(
			
			);
		
		}
			
	}
	
	Update( 
	
	){
		
		console.log( "UIComponentTextT.Update" );
		
		
		this.#UIComponentTextData.Update( );
		
		this.#UIComponentPlaceholder.Update( );
		
		
		super.Update( );
		
	}
	
	IsValidGet(
	
	){
			
		return this.#UIComponentTextData.IsValidGet( );
			
	}
	
	ValueGet( 
	
	){
		
		return this.#UIComponentTextData.ValueGet( );
		
	}
	
	ValueSet( 
		Value,
		AnchorOffset = Value.length,
		FocusOffset = Value.length,
		NeedSelection
	){
		
		console.log( "UIComponentTextT.ValueSet", Value );
		
		
		this.#UIComponentTextData.ValueSet( 
			Value,
			AnchorOffset,
			FocusOffset,
			NeedSelection
		);
		
		
		let UIComponentTextConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentTextConfig.StateMapGet( );
		
		let UIState = null;
		
		if( this.#UIComponentTextData.IsValidGet( ) === true ){
			
			UIState = UIStateMap.Get( "Valid" );
		
		} else {
			
			UIState = UIStateMap.Get( "Invalid" );
			
		}
		
		let ClassName = UIState.ClassNameGet( );
			
		let UIStyleMap = UIState.StyleMapGet( );
		
		super.ClassNameSet( ClassName );
			
		super.StyleMapSet( UIStyleMap );
		
		
		console.log( this.#UIComponentTextData.ValueGet( ), Value );
		
		if( this.#UIComponentTextData.ValueGet( ) === "" ){
			
			//this.#PlaceholderShow( ); 
			
		} else {
			
			//this.#PlaceholderHide( );
			
		}
		
	}
	
	PlaceholderSet( Show ){
		
		if( Show === true ){
			
			this.#PlaceholderShow( );
			
		} else {
			
			this.#PlaceholderHide( );
			
		}
		
	}
	
	/*#OnFocus(
		Ev
	){
		
		console.log( "UIComponentTextT.#OnFocus", Ev );
		
		
		super.ChildRemove( 0 ); 
		
		
		if( super.EventExists( "Focus" ) === true ){
			
			let EventFn = super.EventGet( "Focus" );
			
			EventFn( );
			
		}
		
	}
	
	#OnBlur(
		Ev
	){
		
		console.log( "UIComponentTextT.#OnBlur", Ev );
		
		
		if( this.#Value === "" ){
			
			super.ChildSet( 0, this.#Placeholder );
			
			
		} else {
			
			super.InnerTextSet( this.#Value );
			
		}
		
		
		if( super.EventExists( "Blur" ) === true ){
		
			let EventFn = super.EventGet( "Blur" );
			
			EventFn( );
			
		}
		
	}*/
	
	#OnClick(
		Ev
	){
		
		console.log( "UIComponentTextT.#OnClick" );
		
		
		/*if( this.#UIComponentTextData.ValueGet( ) === "" ){
		
			this.#PlaceholderShow( );
		
		} else {
			
			this.#PlaceholderHide( );
			
		}*/
		
		if( this.EditableGet( ) === true ){
		
			this.#PlaceholderHide( );
		
			this.#UIComponentTextData.Focus( );
		
		}
		
	}
	
	#TextOnChange(
		Value,
		IsValid
	){
		
		console.log( "UIComponentTextT.#TextOnChange", Value, IsValid );
		
		
		let UIComponentTextConfig = super.ConfigGet( );
			
		let UIStateMap = UIComponentTextConfig.StateMapGet( );
		
		if( IsValid === true ){
		
			if( UIStateMap.Exists( "ValidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "ValidFocused" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		} else {
			
			if( UIStateMap.Exists( "InvalidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "InvalidFocused" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
		
		if( this.EventExists( "Change" ) === true ) {
			
			let EventFn = this.EventGet( "Change" );
		
		
			EventFn( 
				Value,
				IsValid
			);
		
		}
		
	}
	
	Focus( 
	
	){
		
		console.log( "UIComponentTextT.Focus" );
		
		
		this.#UIComponentTextData.Focus( );
		
	}
	
	Blur( 
	
	){
		
		this.#UIComponentTextData.Blur( );
		
	}
	
	#TextOnFocus(
		Ev
	){
		
		console.log( "UIComponentTextT.#TextOnFocus" );
		
		
		let UIComponentTextConfig = super.ConfigGet( );
			
		let UIStateMap = UIComponentTextConfig.StateMapGet( );
		
		if( this.IsValidGet( ) === true ){
		
			if( UIStateMap.Exists( "ValidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "ValidFocused" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		} else {
			
			if( UIStateMap.Exists( "InvalidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "InvalidFocused" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
		
		if( this.EventExists( "Focus" ) === true ) {
			
			let EventFn = this.EventGet( "Focus" );
		
			EventFn( );
		
		}
		
	}
	
	#TextOnBlur(
		Ev
	){
		
		console.log( "UIComponentTextT.#TextOnBlur" );
		
		
		let UIComponentTextConfig = super.ConfigGet( );
			
		let UIStateMap = UIComponentTextConfig.StateMapGet( );
		
		if( this.IsValidGet( ) === true ){
		
			if( UIStateMap.Exists( "Valid" ) === true ){
			
				let UIState = UIStateMap.Get( "Valid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		} else {
			
			if( UIStateMap.Exists( "Invalid" ) === true ){
			
				let UIState = UIStateMap.Get( "Invalid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
		
		if( this.EventExists( "Blur" ) === true ) {
			
			let EventFn = this.EventGet( "Blur" );
		
			EventFn( );
		
		}
		
		//this.#PlaceholderHide( );
		
		if( this.#UIComponentTextData.ValueGet( ) === "" ){
			
			this.#PlaceholderShow( );
			
		} else {
			
			this.#PlaceholderHide( );
			
		}
		
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextT.Start" );
		
		
		this.#UIComponentTextData.EventSet( "Change", this.#TextOnChange.bind( this ) );
		
		this.#UIComponentTextData.EventSet( "Focus", this.#TextOnFocus.bind( this ) );
		
		this.#UIComponentTextData.EventSet( "Blur", this.#TextOnBlur.bind( this ) );
		
		this.#UIComponentTextData.Start( );
		
		this.#UIComponentPlaceholder.Start( );
		
		
		super.EventSet( "click", this.#OnClick.bind( this ) );
		
		
		super.Start( );
		
	}
	
	Stop(
	
	){

		
		this.#UIComponentTextData.EventRemove( "Change" );
		
		this.#UIComponentTextData.EventRemove( "Focus" );
		
		this.#UIComponentTextData.EventRemove( "Blur" );
		
		this.#UIComponentTextData.Stop( );
		
		this.#UIComponentPlaceholder.Stop( );
		
		super.EventRemove( "click" );
		
		
		super.Stop( );
		
	}
	
	
	Listen(
		OnChangeFn,
		OnPlaceholderShow,
		OnPlaceholderHide
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderShow ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( OnPlaceholderHide ) !== "function" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		super.EventSet( "Change", OnChangeFn );
		
		super.EventSet( "OnPlaceholderShow", OnPlaceholderShow );
		
		super.EventSet( "OnPlaceholderHide", OnPlaceholderHide );
		
	}
	
	Unlisten( 
	
	){
		
		super.EventRemove( "Change" );
		
		super.EventRemove( "OnPlaceholderShow" );
		
		super.EventRemove( "OnPlaceholderHide" );
		
	}
	
	EditableSet(
		Editable
	){
		
		if( typeof( Editable ) !== "boolean" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
	
		this.#UIComponentTextData.EditableSet( Editable );
		
	}
	
	EditableGet(
		
	){
	
		return this.#UIComponentTextData.EditableGet( );
		
	}
	
};


export default UIComponentTextT;
