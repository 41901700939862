"use strict";


import UIElementT from "../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIStateT from "../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIComponentLayoutVConfigT from "../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";


import UIComponentLayoutVT from "../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";


const GTSUIComponentFormSecondaryT = class GTSUIComponentFormSecondaryT extends UIComponentLayoutVT {
	
	#GTSUIComponentFormSecondaryHeader = null;
	#GTSUIComponentFormSecondaryLineList = null;
	
	constructor( 
		GTSUIComponentFormSecondaryHeader,
		GTSUIComponentFormSecondaryLineList
	){

		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
			
				new UIStateT(
					"Default",
					"UIComponentLayoutHT", //Class
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
				
			] )
		);
		
		
		super( 
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		
		this.HeaderSet( 
			GTSUIComponentFormSecondaryHeader
		);
		
		this.LineListSet( 
			GTSUIComponentFormSecondaryLineList
		);
		
	}
	
	HeaderSet( 
		GTSUIComponentFormSecondaryHeader
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							
						] )
					)
					
				] )
			)
		);
		
		
		UIComponentLayoutElement.DataSet( 
			GTSUIComponentFormSecondaryHeader
		);
		
		
		super.ElementSet( 0, UIComponentLayoutElement );
		
	}
	
	HeaderGet(
	
	){
	
		return super.ElementGet( 0 ).DataGet( );
	
	}
	
	LineListSet( 
		GTSUIComponentFormSecondaryLineList
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
			
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //Class
						new UIStyleMapT( [ //UIStyleMap
							
						] )
					)
					
				] )
			)
		);
		
		
		UIComponentLayoutElement.DataSet( 
			GTSUIComponentFormSecondaryLineList
		);
		
		
		super.ElementSet( 1, UIComponentLayoutElement );
		
	}
	
	LineListGet(
	
	){
	
		return super.ElementGet( 1 ).DataGet( );
	
	}
	
};


export default GTSUIComponentFormSecondaryT;