"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentLayoutVConfigT from "../../../Type/Component/Layout/ComponentLayoutVConfigT.mjs";

import ComponentLayoutElementConfigT from "../../../Type/Component/Layout/ComponentLayoutElementConfigT.mjs";

import ComponentConfigT from "../../../Type/Component/ComponentConfigT.mjs";


import {
	ComponentLayoutElementT as ComponentLayoutElementT,
	ComponentBaseT as ComponentBaseT,
	ComponentT as ComponentT
} from "../ComponentInternalT.mjs";


import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";

import KeyValueT from "../../../Type/KeyValue/KeyValueT.mjs";
import KeyValueMapT from "../../../Type/KeyValue/KeyValueMapT.mjs";
import KeyValueElementT from "../../../Type/KeyValue/KeyValueElementT.mjs";
import KeyValueBucketT from "../../../Type/KeyValue/KeyValueBucketT.mjs";


const ComponentLayoutVT = class ComponentLayoutVT extends ComponentBaseT {

	#ComponentLayoutVConfig = null;
	#Index = 0;
	
	#Cells = null;
	#CellIndexCounter = 0;
	
	#InputFocusCb = null;
	#InputBlurCb = null;
	#InputChangeCb = null;
	#ButtonClickCb = null;
	
	#PrepareMap = null;

	constructor(
		GUIElement,
		ComponentLayoutVConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( ( ComponentLayoutVConfig instanceof ComponentLayoutVConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
	
		super(
			GUIElement,
			ComponentLayoutVConfig
		);
		
		
		this.#ComponentLayoutVConfig = ComponentLayoutVConfig;
		
		
		this.#CellIndexCounter = 0;
		
		this.#Cells = new Map( );
	
	}
	
	Prepare(
		PrepareMap
	){
		
		this.#PrepareMap = PrepareMap;
		
	}
	
	Update(
	
	){
		
		super.Update( );
		
	}
	
	Listen(
		EventBucket
	){
		
		console.log( "ComponentLayoutHT.Listen", EventBucket );
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		
		if( EventBucket !== null ){
			
			let Name = this.#ComponentLayoutVConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
		
				if( EventMap.IsExists( "ButtonClick" ) === true ){
					
					this.#ButtonClickCb = EventMap.Get( "ButtonClick" );
					
				}
				
				if( EventMap.IsExists( "InputFocus" ) === true ){
					
					this.#InputFocusCb = EventMap.Get( "InputFocus" );
					
				}
				
				if( EventMap.IsExists( "InputBlur" ) === true ){
					
					this.#InputBlurCb = EventMap.Get( "InputBlur" );
					
				}
				
				if( EventMap.IsExists( "InputChange" ) === true ){
					
					this.#InputChangeCb = EventMap.Get( "InputChange" );
					
				}
				
			}
			
		}
		
	}
	
	#OnButtonClick( CellIndex, Name ){
		
		console.log( "ComponentLayoutHT.#OnButtonClick", CellIndex, Name );
		
		if( this.#ButtonClickCb !== null ){
			
			this.#ButtonClickCb(
				this.#Index,
				CellIndex, 
				Name
			);
			
		}
		
	}
	
	#OnInputFocus( CellIndex, Name ){
		
		console.log( "ComponentLayoutHT.#OnInputFocus", CellIndex, Name );
		
		if( this.#InputFocusCb !== null ){
			
			this.#InputFocusCb(
				this.#Index,
				CellIndex, 
				Name
			);
			
		}
		
	}
	
	#OnInputBlur( CellIndex, Name ){
		
		console.log( "ComponentLayoutHT.#OnInputBlur", CellIndex, Name );
		
		if( this.#InputBlurCb !== null ){
			
			this.#InputBlurCb(
				this.#Index,
				CellIndex, 
				Name
			);
			
		}
		
	}
	
	#OnInputChange( CellIndex, Name, Value ){
		
		console.log( "ComponentLayoutHT.#OnInputFocus", CellIndex, Name, Value );
		
		if( this.#InputChangeCb !== null ){
			
			this.#InputChangeCb(
				this.#Index,
				CellIndex, 
				Name,
				Value
			);
			
		}
		
	}
	
	CellAppend(
		ComponentLayoutElement
	){
		
		if( ( ComponentLayoutElement instanceof ComponentLayoutElementT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		ComponentLayoutElement.ElementsCreate( );
		
		let Component = new ComponentT(
			"LayoutElement",
			ComponentLayoutElement
		);
		
		Component.Prepare( this.#PrepareMap );
		
		this.ChildAppend(
			Component
		);
		
		
		this.#Cells.set( this.#CellIndexCounter, Component );
		
		Component.Listen(
			new EventBucketT( [
				new EventObjectT(
					Component.NameGet( ),
					new EventMapT( [
						new EventT(
							"ButtonClick",
							this.#OnButtonClick.bind( this )
						),
						new EventT(
							"InputChange",
							this.#OnInputChange.bind( this )
						),
						new EventT(
							"InputFocus",
							this.#OnInputFocus.bind( this )
						),
						new EventT(
							"InputBlur",
							this.#OnInputBlur.bind( this )
						),
					] )
				)
			] )
		);
		
		ComponentLayoutElement.IndexSet( this.#CellIndexCounter );
		
		this.#CellIndexCounter = this.#CellIndexCounter + 1;
		
	}
	
	CellGet(
		CellIndex
	){
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( this.#Cells.has( CellIndex ) === false ){
			
			throw new Error( "Not exists" );
			
		}
		
	
		return this.#Cells.get( CellIndex );
	
	}
	
	CellCount( ){
		
		return this.#Cells.size;
		
	}
	
	CellCreate( 
		ComponentLayoutElementConfig
	){
		
		if( ( ComponentLayoutElementConfig instanceof ComponentLayoutElementConfigT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		let ComponentLayoutElement = new ComponentLayoutElementT(
			new GUIElementT( "div" ),
			ComponentLayoutElementConfig
		);
		
		this.CellAppend( ComponentLayoutElement );
		
	}
	
	CellsCreate( ){
		
		console.log( "CellsCreate" );
		
		let ComponentLayoutElementConfigList = this.#ComponentLayoutVConfig.ComponentLayoutElementConfigListGet( );
		
		console.log( ComponentLayoutElementConfigList );
		
		for( 
			let I = 0;
			I < ComponentLayoutElementConfigList.length;
			I++
		){
			
			this.CellCreate( ComponentLayoutElementConfigList[ I ] );
			
		}
		
	}
	
	IndexSet(
		Index
	){
		
		console.log( "ComponentLayoutHT.IndexSet", Index );
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Index = Index;
		
		//this.DataRowSet( this.#RowIndex );
		
	}
	
	IndexGet(
	
	){
			
		return this.#Index;
			
	}
	
	ElementGet( 
		CellIndex,
		Name
	){
		
		if( typeof( CellIndex ) !== "number" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		if( typeof( Name ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		console.log( this.#Cells );
		
		
		let Cell = this.#Cells.get( CellIndex );
		
		let ComponentLayoutElement = Cell.ComponentTypedGet( );
		
		return ComponentLayoutElement.ElementGet( Name );
		
	}
	
	ValuesElementCheck(
		CellIndex,
		Fields
	){
		
		let ValuesMap = new KeyValueMapT( [ ] );
		
		let Cell = this.#Cells.get( CellIndex );
		
		let ComponentLayoutElement = Cell.ComponentTypedGet( );
		
		let CellValuesMap = ComponentLayoutElement.ValuesCheck( Fields );
		
		for(
			let I of CellValuesMap.Keys( )
		){
			
			if( ValuesMap.IsExists( I ) === true ){
				
				throw new Error( "Duplicate element" );
				
			}
			
			
			ValuesMap.Set( I, CellValuesMap.Get( I ) );
			
		}
		
		return ValuesMap;
		
	}
	
	ValuesCheck(
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesMap = new KeyValueMapT( [ ] );
		
		
		for( 
			let CellIndex of this.#Cells.keys( )
		){
			
			let CellValuesMap = this.ValuesElementCheck( CellIndex, Fields );
			
			for(
				let I of CellValuesMap.Keys( )
			){
				
				if( ValuesMap.IsExists( I ) === true ){
					
					throw new Error( "Duplicate element" );
					
				}
				
				
				ValuesMap.Set( I, CellValuesMap.Get( I ) );
				
			}
		
		}
		
		return ValuesMap;
		
	}
	
	ValuesElementGet( 
		CellIndex,
		Fields
	){
		
		let ValuesMap = new KeyValueMapT( [ ] );
		
		
		let Cell = this.#Cells.get( CellIndex );
		
		let ComponentLayoutElement = Cell.ComponentTypedGet( );
		
		let CellValuesMap = ComponentLayoutElement.ValuesGet( Fields );
		
		
		for(
			let I of CellValuesMap.Keys( )
		){
			
			if( ValuesMap.IsExists( I ) === true ){
				
				throw new Error( "Duplicate element" );
				
			}
			
			
			ValuesMap.Set( I, CellValuesMap.Get( I ) );
			
		}
		
		
		return ValuesMap;
		
	}
	
	ValuesGet(
		Fields
	){
		
		if( ( Fields instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		for(
			let I = 0;
			I < Fields.length;
			I++
		){
			
			if( typeof( Fields[ I ] ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		let ValuesMap = new KeyValueMapT( [ ] );
		
		
		for( 
			let CellIndex of this.#Cells.keys( )
		){
			
			let CellValuesMap = this.ValuesElementGet( CellIndex, Fields );
			
			for(
				let I of CellValuesMap.Keys( )
			){
				
				if( ValuesMap.IsExists( I ) === true ){
					
					throw new Error( "Duplicate element" );
					
				}
				
				
				ValuesMap.Set( I, CellValuesMap.Get( I ) );
				
			}
		
		}
		
		return ValuesMap;
		
	}
	
	ValuesElementSet(
		CellIndex,
		ValuesMap
	){
		
		if( ( ValuesMap instanceof KeyValueMapT ) === false ){
		
			throw new Error( "Invalid argument type" );
	
		}
		
		let Cell = this.#Cells.get( CellIndex );
		
		let ComponentLayoutElement = Cell.ComponentTypedGet( );
		
		ComponentLayoutElement.ValuesSet( ValuesMap );
		
	}
	
	ValuesSet(
		ValuesMap
	){
		
		if( ( ValuesMap instanceof KeyValueMapT ) === false ){
		
			throw new Error( "Invalid argument type" );
	
		}
		
		
		for( 
			let CellIndex of this.#Cells.keys( )
		){
		
			this.ValuesElementSet( CellIndex, ValuesMap );
		
		}
		
	}
	
	StyleMapElementSet(
		CellIndex,
		Fields,
		StyleMap
	){
		
		let Cell = this.#Cells.get( CellIndex );
		
		Cell.ComponentTypedGet( ).StyleMapElementSet( Fields, StyleMap );
		
	}

};


export default ComponentLayoutVT;