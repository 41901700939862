"use strict";


import UIStyleT from "./UIStyleT.mjs";


const UIStyleMapT = class UIStyleMapT {
	
	#Map = null;
	
	constructor(
		UIStyleList
	){
		
		if( ( UIStyleList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		for(
			let I = 0;
			I < UIStyleList.length;
			I++
		){
			
			if( ( UIStyleList[ I ] instanceof UIStyleT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < UIStyleList.length;
			I++
		){
			
			this.Set( UIStyleList[ I ] );
			
		}
		
	}
	
	Keys( ){
	
		return this.#Map.keys( );
	
	}
	
	Set(
		Value
	){
		
		if( ( Value instanceof UIStyleT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		this.#Map.set( Value.KeyGet( ), Value );
		
	}
	
	Exists(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.has( Key );
		
	}
	
	Get(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.get( Key );
		
	}
	
	Remove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Map.delete( Key );
		
	}
	
	Copy( 
	
	){
		
		return new UIStyleMapT( [
			...this.#Map.values( )
		] );
		
	}
				
};


export default UIStyleMapT;