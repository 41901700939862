import React, { Component } from "react";
import axios from "axios";
import { RouteComponentProps } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import "./_style.scss";
import AuthService from "./../../services/AuthService";
import UserService from "./../../services/UserService";
import CookieService from "./../../services/CookieService";
import SessionStorageService from "../../services/SessionStorageService";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

//import zipfile  from "../../assets/ZipCodes.zip";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import Dexie from 'dexie';
class Login extends Component<RouteComponentProps> {
  state = {
    redirect: null,
    username: "",
    password: "",
    isChecked: false,
  };








  async handleFormSubmit(event) {
    event.preventDefault();
    const postData = {
      username: this.state.username,
      password: this.state.password,
    };
    const response = await AuthService.doUserLogin(postData);
    if (response) {
      AuthService.handleLoginSuccess(response, this.state.isChecked);
     // localStorage.clear();

     //

      UserService.getDataLists();

    } else {
      alert("Please check your credentials and try agian");
    }
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }


  async getGoogleUrl() {

    const response = await axios.get(UrlService.apiDomain() + 'api/auth/google');
    window.location.replace(response.data.url);

  }

  render() {
    const { username, password, isChecked } = this.state;

    if (this.state.redirect) {
     // console.log("dsfdasd"+this.state.redirect);
      window.location.replace("/"+this.state.redirect)
     // return <Redirect to={this.state.redirect} />
    }


    if (CookieService.get("access_token")!=null && CookieService.get("access_token")!='') {
        UserService.getDataLists();
      return(<></>);
    }
else {

     this.getGoogleUrl();

      return(<></>);

      /*
      return (
          <React.Fragment>
            <div className="login-page">
              <div className="login-box">
                <div className="login-logo">
                  <a
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                      }}
                  >
                    <b>GTS</b>DB
                  </a>
                </div>

                <div className="card">
                  <div className="card-body login-card-body">
                    <p className="login-box-msg">Sign in to start your session</p>

                    <form onSubmit={(event) => this.handleFormSubmit(event)}>
                      <div className="input-group mb-3">
                        <input
                            type="email"
                            name="name"
                            className="form-control"
                            placeholder="Email"
                            value={username}
                            onChange={(event) =>
                                this.setState({username: event.target.value})
                            }
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-envelope"></span>
                          </div>
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(event) =>
                                this.setState({password: event.target.value})
                            }
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-lock"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <div className="icheck-primary">
                            <input
                                type="checkbox"
                                id="remember"
                                onChange={() => this.handleChecked()}
                                checked={isChecked}
                            />
                            <label
                                onClick={() => this.handleChecked()}
                                id="remember-label"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <button
                              type="submit"
                              className="btn btn-primary btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </form>


                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
      );
      */
    }
  }
}

export default withRouter(Login);
