import React, { Component } from 'react';
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import SessionStorageService from "../services/SessionStorageService";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class DeleteRenderer extends Component {
    buttonClick = (e) => {

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {    this.setState({
                        visible: true
                    })
                        let deletedRow = this.props.node.data;


                        var url=UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + this.props.data.id;

                        if (this.props.gridType==='columns_options') url=UrlService.apiDomain() + 'api/' + this.props.gridType + '/' + this.props.colid + '/'+this.props.data.id;
                        if (this.props.gridType==='product_types_company_name') url=UrlService.apiDomain() + 'api/product_types/company_names/' + this.props.colid + '/'+this.props.data.id;

                        HttpService.delete(url)
                            .then(res => {
                                if (res.data.alert!=null && res.data.alert!='') {
                                    alert(res.data.alert);
                                    return false;
                                }
                                console.log('Success')
                                e.gridApi.updateRowData({remove: [deletedRow]})  // It will update the row
                                if (this.props.gridType==='columns_options') {
                                    SessionStorageService.update('columns', res.data.entry);
                                }

                                if (this.props.gridType==='columns') {
                                    SessionStorageService.remove_by_id('columns', this.props.data.id);
                                }

                                if (this.props.gridType==='roles') {
                                    SessionStorageService.remove_by_id('roles', this.props.data.id);
                                }

                                if (this.props.gridType==='users') {
                                    SessionStorageService.remove_by_id('users', this.props.data.id);
                                }



                            })
                            .catch((error) => {
                                console.log(error);
                            })}
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });
    };





    render() {

        return (

        <button
            className="btn btn-default btn-sm delete-ingrid-but"
            onClick={() => this.buttonClick(this.props.node)}
        ><i className="fa fa-trash"></i>
        </button>

        );
    }
}

DeleteRenderer.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(DeleteRenderer);