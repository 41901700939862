import React, { Component } from "react";

import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import OutinvoiceCard from "../loadingcard";
import Audit from "../common/audit";
import OutinvoiceAdd from "./outinvoice_add.tsx";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import ActionsRenderer from "../../aggrid/ActionsRenderer.jsx";
import MainTableColumnDefs from "../../aggrid/MainTableColumnDefs.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import DatePicker from "../../aggrid/DatePicker.js";
import DateTimePicker from "../../aggrid/DateTimePicker.jsx";
import Select from 'react-select'
import MultiSelectSimple from "../../aggrid/MultiSelectSimple.jsx";
import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData.jsx";
import moment from 'moment';

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { registerLocale } from 'react-date-range';

import en from "date-fns/locale/en-US"; // the locale you want
import cs from "date-fns/locale/cs"; // the locale you want
import invoicesStatusCount from "../../aggrid/invoicesStatusCount.jsx";
//import {LoadState, SaveState} from "../../services/GridStateService";


import AgGridState from "../common/ag_grid_state";


class OutInvoices extends Component {


    bankAccountsData=JSON.parse(localStorage.getItem('bank_accounts'));

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
			console.log( "customComparator", valueA, valueB );
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };



		

        super(props)
        
		let colDefs = MainTableColumnDefs('outinvoices', this.showOutinvoiceCard.bind(this), this.props.opts, null, !this.props.fromtab);
		
		for(
			let i in colDefs
		){
			if( colDefs[ i ].field == 'invoice_items' ){
				
				colDefs[ i ].hide = true;
				colDefs[ i ].lockVisible = true;
				
			}
		}
		
		console.log( colDefs );
		
		colDefs.checkboxSelection = true;
		//console.log(props);
        this.AuditElement = React.createRef();
        var date = new Date();
        var startdate=new Date(date.getFullYear(), date.getMonth(), 1);
        var enddate=new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.state = {
			disabled: false,
			spinner: 'none',
            isColumnStateLoaded: false,
            showLoading: true,
            rowSelected: false,
            pages: 0,
            filterRangeDisplay:'none',
            backgroundOverlayDisplay:'none',
            filterRange:moment(startdate).format('DD.MM.YYYY')+'-'+moment(enddate).format('DD.MM.YYYY'),
            filterStartDate:startdate,
            filterEndDate: enddate,
            popupParent: document.querySelector('body'),

            showOutinvoiceCard: false,
            showOutinvoiceCardType: 'outinvoice_single',

            showOutinvoiceCardName: '',
            showOutinvoiceCardId: '',

            filterRangeBy:'',
            filterRangeBy_value:{value: "", label:this.props.t('Choose filter')},
            showArchive:'Show archive',
            currentArchive:'Show actual',
            showAudit: false,
            showAuditId: null,
            showModal: false,
            showCompanyCard: false,
            showCompanyCardName: 'Company card',
            showCompanyCardNode: null,
            showCompanyCardId: null,

            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'invoicesStatusCount',
                        align: 'left',
                        key:'statusCountKey'
                    },

                ],
            },

            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: colDefs,
            defaultColDef: {
                editable: false,
                filter: true,

                minWidth: 20,
                resizable: true,

                sortable: true,

                comparator: customComparator,
                filterParams:{
                    textFormatter: function(value) {
                        return value
                            .replace(/\s/g, '')
                            .replace(/[àáâãäå]/g, 'a')
                            .replace(/[ě]/g, 'e')
                            .replace(/[š]/g, 's')
                            .replace(/[č]/g, 'c')
                            .replace(/[ř]/g, 'r')
                            .replace(/[ž]/g, 'z')
                            .replace(/[ů]/g, 'u')
                            .replace(/[ň]/g, 'n')
                            .replace(/æ/g, 'ae')
                            .replace(/ç/g, 'c')
                            .replace(/[èéêë]/g, 'e')
                            .replace(/[ìíîï]/g, 'i')
                            .replace(/ñ/g, 'n')
                            .replace(/[òóôõö]/g, 'o')
                            .replace(/œ/g, 'oe')
                            .replace(/[ùúûü]/g, 'u')
                            .replace(/[ýÿ]/g, 'y')
                            .replace(/\W/g, '');
                    }
                }
            },

            frameworkComponents: {

                DeleteRenderer: DeleteRenderer,
                ActionsRenderer: ActionsRenderer,

                MultiSelectSimple: MultiSelectSimple,
                DateTimePicker: DateTimePicker,
                MultiSelectLargeData: MultiSelectLargeData,
                FileRenderer: FileRenderer,
                invoicesStatusCount: invoicesStatusCount,
                DatePicker: DatePicker,


            },
			now: ( new Date() ).getTime( ),

			AgGridReady: false


        };


    }

    showOutinvoiceCard(e) {
        this.setState({
            showOutinvoiceCard: true,
            showOutinvoiceCardType: 'outinvoice_single',

            showOutinvoiceCardName: this.props.t('Generate invoice')+' '+e.invoice_number,
            showOutinvoiceCardId: e.id,
        })

    }

    onColumnVisible(params) {

        var columnState = JSON.stringify(params.columnApi.getColumnState());
        var columnSettingsName='OutinvoicesColumns';

        localStorage.setItem(columnSettingsName, columnState);

    }

    onDragStopped(params) {

        var columnState = JSON.stringify(params.columnApi.getColumnState());
        var columnSettingsName='OutinvoicesColumns';

        localStorage.setItem(columnSettingsName, columnState);
    }



    componentDidMount() {
        var url=UrlService.apiDomain()+'api/outinvoices/'+this.props.id;



        if (this.props.id!==null) HttpService.get(url)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });


                var hardcodedFilter = {
                    paid_date: {
                        type: 'set',
                        values: [null],
                    },
                };


                function tt() {
					
					if( this.props.year != null && this.props.month != null ){
		
						hardcodedFilter = {...hardcodedFilter, ...this.dateFilter() };
		
					}
					
                    this.gridApi.setFilterModel(hardcodedFilter);

                    const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
                    let componentInstance = statusBarComponent;
                    if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
                        componentInstance = statusBarComponent.getFrameworkComponentInstance();
                    }

                    if (componentInstance) {
                        console.log(componentInstance);
                        componentInstance.forceUpdate();
                    }
                }


                setTimeout(tt.bind(this), 1);
                setTimeout(() => {
                    //LoadState('OutInvoices', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);

            })
            .catch((error) => {
                console.log(error);
            });

    }

    componentDidUpdate(prevProps) {
		
		console.log( "outinvoice update", this.props );

        if (prevProps.tmp !== this.props.tmp) {


            if (this.props.id!=null)  {
                this.setState({
                    rowdata: []
                });

                this.componentDidMount();


            }

            this.setState({showArchive:'Show archive', currentArchive:'Show actual'});

        }
		
		this.setFilter( ); //this need for hardcore filter
		
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        this.gridApi.setDomLayout('normal');


        var hidden=JSON.parse(localStorage.getItem('outinvoices_hiddencols'));

        this.gridColumnApi.setColumnsVisible( hidden, false);
		
		this.gridColumnApi.setColumnsVisible( ["invoice_items" ], false);

        var columnState = JSON.parse(localStorage.getItem('OutinvoicesColumns'));

        if (columnState) {
            params.columnApi.setColumnState(columnState);
        }



        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('outinvoices.delete')) {
            items.push(  {
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",

                cellRendererParams: {
                    gridType: "outinvoices",
                },
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);
		
		this.setState( {
			AgGridReady: true
		} );


    };

    onCellCValueChanged = (event) => {

        event.data.field = event.colDef.field;

        HttpService.put(UrlService.apiDomain()+'api/outinvoices/'+event.data.id, event.data)
            .then(res => {
                if (res.data==null) alert ("Chyba připojení k serveru");
                if (res.data.alert!=='') alert(res.data.alert);
                if (res.data.result=='failed') {
                    alert(res.data.message);

                } else {
                    console.log('Successfully updated');
                    var rownode;
                    res.data.rows.map((e)=>{
                        rownode=this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                        return true;
                    });



                }
            })
            .catch((error) => {
                alert(error);
                console.log(error);
            })


    }


    onCellClicked=(e)=> {
        var that=this;
        var field=e.column.colId;
/*
        if (field==="company") {

            that.setState({
                showCompanyCard: true,
                showCompanyCardName: e.node.data.company,
                showCompanyCardId: e.node.data.company_id,
                showCompanyCardNode: null
            });



        }
        */

    }



    getContextMenuItems = (params) => {

        var that=this;
        var result = [


            'copy',

            'paste',
            'export',


        ];

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('outinvoices.audit')) {
            result.push('separator');
            result.push({
                name: 'Audit',
                checked: true,
                action: function () {

                    if (params.node && params.node.data) {
                        that.setState({
                            showAudit: true,
                            showAuditId: params.node.data.id
                        });
                    }

                },
                icon:
                    '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
            })
        }
        return result;
    };

    getRowNodeId=(data) => {
        return data.id;
    };


    async handleAdd(data) {


        HttpService.post(UrlService.apiDomain()+'api/outinvoices', data)
            .then(res => {

                if (res.status!=200) {
                    console.log(res);
                    alert(this.props.t('Chyba')+'. '+this.props.t(res.statusText));
                    return false;

                }

                if (res.data===null) alert ("Chyba připojení k serveru");
                if (res.data.result==='failed' || typeof res.data.errors!=='undefined') {
                    var msg=res.data.message+"\n";
                    if (typeof res.data.errors!=='undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg+=value[0]+"\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});


                    var res = this.gridApi.applyTransaction({
                        add: res.data.rows,
                        addIndex: 0,
                    });

                    this.gridApi.flashCells({rowNodes: res.add});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }



    onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected:true, rowSelectedCount: this.gridApi.getSelectedNodes().length});
        } else {
            this.setState({rowSelected:false, rowSelectedCount: 0 });
        }
    }

    export_to_excel () {

        this.gridApi.exportDataAsExcel();

    }


    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return dd+'.'+mm + '.' +  yyyy;
    }

    handleFilterDateRangeSelect(ranges){

        this.setState({
            filterStartDate:ranges.selection.startDate,
            filterEndDate: ranges.selection.endDate,
            filterRange: moment(ranges.selection.startDate).format('DD.MM.YYYY')+'-'+moment(ranges.selection.endDate).format('DD.MM.YYYY'),
        }, ()=>this.setFilter());

        if (ranges.selection.startDate!=ranges.selection.endDate) {
            this.setState({

                filterRangeDisplay:'none'
            });
        }

    }


    setFilter() {
		
		console.log( "set filter" );
		
		let filtr = {};

        if (this.state.filterRangeBy != '') {
			
            filtr[this.state.filterRangeBy] = {
                type: 'set',
                values: this.gridApi.getFilterInstance(this.state.filterRangeBy).getValues().filter((e) => {
                    if (moment(e, "DD.MM.YYYY") >= this.state.filterStartDate && moment(e, "DD.MM.YYYY") <= this.state.filterEndDate) {
                        return true;
                    } else {
                        return false;
                    }
                }),
            }
			
			if (this.state.filterRangeBy !== 'paid_date' && this.state.currentArchive !== 'Show all') {
				filtr = {...filtr, ...this.archiveFilter()}
			}
			
		} else {
			
			filtr = {...filtr, ...this.archiveFilter()}
			
		}
			
		//TODO
		if( this.props.year != null && this.props.month != null ){
		
			filtr = {...filtr, ...this.dateFilter() };
		
		}
		
		//TODO
		
		console.log( filtr );
			
		this.gridApi.setFilterModel(filtr);
		
    }

    archiveFilter() {
		
		console.log("archiveFilter");

        if (this.state.currentArchive === 'Show archive') {
			
			console.log( "Archive" );
			
            return {
                paid_date: {
                    type: 'set',
                    values: this.gridApi.getFilterInstance('paid_date').getValues().filter(e => e !== null),
                },
            };
        }

        if (this.state.currentArchive === 'Show all') {
			
			console.log( "All" );

            return null;
        }

        if (this.state.currentArchive === 'Show actual') {
			
			console.log( "Actual" );
			
            return {
                paid_date: {
                    type: 'set',
                    values: [null],
                },
            };
        }
    }

    handleFilterRangeChange(e) {

        this.setState({filterRange:e.target.value})
        var t=e.target.value.split('-');

        var start=moment(t[0], "DD.MM.YYYY", true);
        var end=moment(t[1], "DD.MM.YYYY", true);
        console.log(start.isValid());
        console.log(end.isValid());
        if(start.isValid() && end.isValid() && end>=start) {
            this.setState({
                filterStartDate:start.toDate(),
                filterEndDate: end.toDate(),

            }, ()=>this.setFilter());
        }
    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            //SaveState('OutInvoices', params.columnApi, params.api);
        }
    }

    onFilterChanged(params) {

//console.log(this.gridApi.getFilterModel());
        if (typeof this.gridApi !='undefined') {

            const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
            let componentInstance = statusBarComponent;
            if (typeof statusBarComponent !== 'undefined' && statusBarComponent.getFrameworkComponentInstance) {
                componentInstance = statusBarComponent.getFrameworkComponentInstance();
            }

            if (componentInstance) {
                console.log(componentInstance);
                componentInstance.forceUpdate();
            }

        }

        this.onColumnsStateChanged(params);
    }
	
	dateFilter() {
		
		console.log( "date filter" );
			
		let Data = this.gridApi.getModel().gridOptionsWrapper.gridOptions.rowData;
		
		console.log( this.gridApi.getColumnDefs(), this.state.columnDefs, this.gridApi.getModel(), Data);
			
		let FilterDate = this.props.year + "-" + this.props.month;
		
		let EndDate = '';
		
		if( this.props.month == '12' ){
			
			EndDate += ( parseInt( this.props.year ) + 1 ).toString( );
			
			EndDate += '-';
			
			EndDate += '01';
			
		} else {
			
			let EndYear = this.props.year;
			
			let EndMonth = ( parseInt( this.props.month ) + 1 ).toString( );
			
			if( EndMonth.length == 1 ){
				
				EndMonth = '0' + EndMonth;
				
			}
			
			EndDate = EndYear + '-' + EndMonth;
			
		}
		
		return {
			invoice_taxable: {
				filterType: 'datetime',
				type: 'inRange',
				dateFrom: FilterDate+'-01 00:00:00',
				dateTo: EndDate+'-01 00:00:00',
			}
		};
        
    }
	
	async outInvoicesPrint( e ){
		
		console.log( e, this.gridApi.getSelectedNodes() );
		
		this.setState( { disabled: true } );
		
		this.setState( { spinner: 'inline' } );
	
        e.preventDefault();
		

        let form_data = new FormData( );
		
		let invoice_ids = [];
		
		let SelectedRows = this.gridApi.getSelectedNodes();
		
		console.log( SelectedRows );
		
		for( let key in SelectedRows ){
			
			console.log( SelectedRows[key].data.id );
			
			invoice_ids.push( SelectedRows[key].data.id );
			
		}
		
		let json_data = { ids: invoice_ids };
		
		form_data.append( 'json', JSON.stringify( json_data ) ); 
		
		HttpService.postblob(UrlService.apiDomain() + 'api/documents/outinvoices/print_pdf', form_data)
            .then( response => {
				
				if( response.status != 200 ) {
					
                    alert(this.props.t('Chyba při generaci')+'. '+this.props.t(response.statusText));
					
                    this.setState( { disabled: false } );
		
					this.setState( { spinner: 'none' } );
					
                    return false;

                }


				console.log(response);

                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
				
                const link = document.createElement('a');
				
                link.href = url;

                var filename = 'mass_print.pdf';
				
                link.setAttribute(
                    'download',
                    filename,
                );

                document.body.appendChild( link );

                link.click( );
				
                link.parentNode.removeChild( link );
				
				this.setState( { disabled: false } );
		
				this.setState( { spinner: 'none' } );

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });

    }

    render() {

//console.log(this.props);
        var roles=[];
        JSON.parse(localStorage.getItem('my_user')).roles.forEach((r)=>{
            roles.push(r.name);
        });

        var that=this;
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        var daterange_locale=en;
        if (locale=='cs') daterange_locale=cs;

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        var height='650px';
        if (this.props.fromtab) height='450px';





        const selectionRange = {
            startDate: this.state.filterStartDate,
            endDate: this.state.filterEndDate,
            key: 'selection',
        }

        const filterRangeByFields =[

            {value: "", label:this.props.t('Choose filter')},
            {value: "paid_date", label:this.props.t('Paid date')},
            {value: "invoice_issue", label:this.props.t('Issue date')},
            {value: "invoice_due", label:this.props.t('Due date')},
            {value: "invoice_taxable", label:this.props.t('Taxable date')},

        ];

        return (
            <>

            <div id="backgroundOverlay" onClick={()=>{this.setState({

                filterRangeDisplay:'none',
                backgroundOverlayDisplay:'none'
            });}} style={{display:this.state.backgroundOverlayDisplay}}></div>

            <div className="buttons-companies">
                <div className="btn-group" style={{'marginRight':'9px'}}>


                    {permissions.includes('outinvoices.excel') &&
                    <button type="button"
                            className="btn btn-primary btn-sm"
                            onClick={this.export_to_excel.bind(this)}
                    ><i className="fa  fa-file-excel"></i>
                    </button>
                    }

                </div>


                <button type="button" style={{'marginRight': '9px'}}
                        className="btn btn-success btn-sm"
                        onClick={() => {

                            if (this.state.showArchive==='Show archive') {
                                this.setState({currentArchive: 'Show archive', showArchive: 'Show all'}, ()=>this.setFilter());
                            }

                            if (this.state.showArchive==='Show all') {

                                this.setState({currentArchive: 'Show all', showArchive: 'Show actual'}, ()=>this.setFilter());
                            }

                            if (this.state.showArchive==='Show actual') {
                                this.setState({currentArchive: 'Show actual', showArchive: 'Show archive'}, ()=>this.setFilter());
                            }



                        }}
                >{this.props.t(this.state.showArchive)} <i className="fa fa-archive"></i>
                </button>


                {(permissions.includes('outinvoices.create') &&
                ((this.props.rownode && this.props.rownode.data && this.props.rownode.data.active===1 && this.props.fromtab) || !this.props.fromtab )) &&
                <button style={{'marginRight':'9px'}} type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => this.setState({now:Date.now(), showModal: true})}
                ><i className="fa fa-plus"></i>
                </button>
                }
				{(permissions.includes('outinvoices.create') &&
                ((this.props.rownode && this.props.rownode.data && this.props.rownode.data.active===1 && this.props.fromtab) || !this.props.fromtab )) &&
                <button style={{'marginRight':'9px'}} type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => this.setState({now:Date.now(), showModal: true})}
						disabled={ !this.gridApi || this.state.rowSelectedCount !== 1 }
                ><i className="fa fa-copy"></i>
                </button>
                }
				
				{(permissions.includes('outinvoices.view')) && 
                        <button type="button"
                                disabled={!this.state.rowSelected || this.state.disabled}
                                className="btn btn-success btn-sm"
                                onClick={(e) => {
                                    this.outInvoicesPrint(e);

                                }}
							>{this.props.t('Mass printing')} 
							<i className="fa  fa-print">
							</i>
							<div style={{display: this.state.spinner}}>
								<i  class="fas fa-spinner fa-spin"></i>
							</div>
                        </button>
                    }

                {this.props.fromtab &&
                <a style={{color:'white'}} href="outinvoices"
                   className="btn btn-primary btn-sm"
                ><i className="fa fa-external-link-alt"></i>
                </a>
                }
				
				<div style={{display: 'inline-block', float: 'right', marginRight: '20px'}}>
					<AgGridState 
						model = "outivoices"
						api = { this.gridApi }
						api_column = { this.gridColumnApi }
						ready = { this.state.AgGridReady }
					/>
				</div>
				
				<br clear="all"/>

                {/*<div style={{display: 'inline-block', float:'right', marginRight:'20px'}} >
				
					<span style={{width:'150px', display: 'inline-block', marginRight:'8px'}}>
					 
						<Select
							name="filterRangeBy"

							onChange={
								(event) => {
									
									this.setState({filterRangeBy:event.value, filterRangeBy_value:event,}, ()=>this.setFilter())

								}
							}
							value={this.state.filterRangeBy_value}
							placeholder={this.props.t('Select')}
							classNamePrefix="select"
							options={filterRangeByFields}
						/>

					</span>
					
                    <input 
						style={{width:'190px', display: 'inline-block'}}
						onChange={this.handleFilterRangeChange.bind(this)}
						className="form-control" 
						type="text" 
						value={this.state.filterRange} 
						onClick={()=>{

							this.setState({backgroundOverlayDisplay:'block', filterRangeDisplay:'block'});
							
						}} 
					/>

                    <div className="daterange_flt" style={{display:this.state.filterRangeDisplay}}>
                        <DateRange

                            ranges={[selectionRange]}
                            showMonthAndYearPickers={false}
                            moveRangeOnFirstSelection={false}
                            locale={daterange_locale}
                            onChange={this.handleFilterDateRangeSelect.bind(this)}
                        />
                    </div>



				</div>*/}

            </div>

            <Modal

                title={this.state.showOutinvoiceCardName}
                zindex="1051"
                visibility={this.state.showOutinvoiceCard}
                size="lg"
                onClose={() => this.setState({ showOutinvoiceCard: !this.state.showOutinvoiceCard })}
            >
                <OutinvoiceCard type={this.state.showOutinvoiceCardType} show={this.state.showOutinvoiceCard} tmp={Date.now()} loading_number={this.state.showOutinvoiceCardId} />
            </Modal>

            <Modal
                title={this.props.t('Audit')}

                visibility={this.state.showAudit}
                size="lg"
                onClose={() => this.setState({ showAudit: !this.state.showAudit })}
            >
                <Audit type={"outinvoices"} tmp={Date.now()} id={this.state.showAuditId} model={"outinvoice"} />
            </Modal>

            {permissions.includes('outinvoices.create') &&
            <Modal
                title={this.props.t('Add new')}
                visibility={this.state.showModal}
                size="lg"
                onClose={() => this.setState({showModal: !this.state.showModal})}
            >
                <OutinvoiceAdd 
					type='other' 
					rownode={this.props.rownode} 
					fromtab={this.props.fromtab} 
					tmp={this.state.now} 
					company_id={this.props.id} 
					company_name={this.props.company_name}
                    onAdd={(data) => this.handleAdd(data)}
					row_data={ ( this.gridApi && this.state.rowSelectedCount == 1 ) ? this.gridApi.getSelectedNodes()[ 0 ] : null }
				/>
            </Modal>

            }

            <div style={{ 'height': height, width:'100%' }} id="outinvoicesGrid" className="ag-theme-alpine">
                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    onCellClicked={this.onCellClicked}
                    frameworkComponents={this.state.frameworkComponents}
                    components={this.state.components}
                    enableCellChangeFlash={true}
                    rowSelection={'multiple'}
                    popupParent={this.state.popupParent}
                    suppressContextMenu={false}
                    pagination={true}
                    onRowSelected={this.onRowSelected.bind(this)}
                    paginationPageSize={1000}
                    localeText={this.state.localeText}
                    getRowNodeId = {this.getRowNodeId}
                    getContextMenuItems={this.getContextMenuItems}
                    statusBar={this.state.statusBar}
                    onColumnVisible={this.onColumnsStateChanged.bind(this)}
                    onDragStopped={this.onColumnsStateChanged.bind(this)}
                    onFilterChanged={this.onFilterChanged.bind(this)}
                    suppressAggFuncInHeader={true}
                    suppressRowClickSelection={true}



                />
            </div>
            </>
        );
    }
}

OutInvoices.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(OutInvoices);

