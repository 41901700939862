import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import en from "date-fns/locale/en-US"; // the locale you want
import cs from "date-fns/locale/cs"; // the locale you want
import SessionStorageService from "../../../services/SessionStorageService";

import CustomPinnedRowRenderer from "../../../aggrid/CustomPinnedRowRenderer.jsx";
import {LoadState, SaveState} from "../../../services/GridStateService";
class SalaryReport extends Component {

    constructor(props) {
        super(props)
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;

        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };



        var date = new Date();
        var startdate=new Date(date.getFullYear(), date.getMonth(), 1);
        var enddate=new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.state = {
            isColumnStateLoaded: false,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            pinnedTopRowData:[],
            filterRangeDisplay:'none',
            backgroundOverlayDisplay:'none',
            filterRange:moment(startdate).format('DD.MM.YYYY')+'-'+moment(enddate).format('DD.MM.YYYY'),
            filterStartDate:startdate,
            filterEndDate: enddate,
            columnDefs: [

                {
                    headerName: this.props.t('Worker'),
                    field: 'worker',
                    editable: false,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: { 'font-weight': 'bold' },
                    },

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Hour salary'),
                    field: 'hour_salary',
                    editable: false,

                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: { 'font-weight': 'bold' },
                    },
                    comparator: customComparator
                },

                {
                    headerName: this.props.t('Volume salary'),
                    field: 'volume_salary',
                    editable: false,

                    filter: 'agTextColumnFilter',
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: { 'font-weight': 'bold' },
                    },
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Total salary'),
                    field: 'total_salary',
                    editable: false,
                    pinnedRowCellRenderer: 'customPinnedRowRenderer',
                    pinnedRowCellRendererParams: {
                        style: { 'font-weight': 'bold' },
                    },
                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },

            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                customPinnedRowRenderer: CustomPinnedRowRenderer
            }
        };
    }

    getSums(data) {
        const initial = {

            worker: this.props.t('Total'),
            hour_salary: 0,
            volume_salary: 0,
            total_salary: 0,
        };

        data.forEach((e)=>{
            initial.hour_salary=initial.hour_salary+parseFloat(e.hour_salary);
            initial.volume_salary=initial.volume_salary+parseFloat(e.volume_salary);
            initial.total_salary=initial.total_salary+parseFloat(e.total_salary);
        })

        return [initial];
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/reports/salary_report/'+this.state.filterRange)
            .then(res => {


                this.setState({
                    rowdata: res.data,
                    pinnedTopRowData:this.getSums(res.data)
                });
                setTimeout(() => {
                    LoadState('SalaryReport', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        LoadState("SalaryReport", params.columnApi, params.api);
    };


    today() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return dd+'.'+mm + '.' +  yyyy;
    }

    handleFilterDateRangeSelect(ranges){

        this.setState({
            filterStartDate:ranges.selection.startDate,
            filterEndDate: ranges.selection.endDate,
            filterRange: moment(ranges.selection.startDate).format('DD.MM.YYYY')+'-'+moment(ranges.selection.endDate).format('DD.MM.YYYY'),
        }, ()=>this.componentDidMount());

        if (ranges.selection.startDate!=ranges.selection.endDate) {
            this.setState({

                filterRangeDisplay:'none'
            });
        }

    }





    handleFilterRangeChange(e) {

        this.setState({filterRange:e.target.value})
        var t=e.target.value.split('-');

        var start=moment(t[0], "DD.MM.YYYY", true);
        var end=moment(t[1], "DD.MM.YYYY", true);
        console.log(start.isValid());
        console.log(end.isValid());
        if(start.isValid() && end.isValid() && end>=start) {
            this.setState({
                filterStartDate:start.toDate(),
                filterEndDate: end.toDate(),

            }, ()=>this.componentDidMount());
        }
    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('SalaryReport', params.columnApi, params.api);
        }
    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        var daterange_locale=en;
        if (locale=='cs') daterange_locale=cs;
        const selectionRange = {
            startDate: this.state.filterStartDate,
            endDate: this.state.filterEndDate,
            key: 'selection',
        }
        return (
            <div className="wrapper">
                <div id="backgroundOverlay" onClick={()=>{this.setState({

                    filterRangeDisplay:'none',
                    backgroundOverlayDisplay:'none'
                });}} style={{display:this.state.backgroundOverlayDisplay}}></div>
                <Layout>
                    <Content title={this.props.t('Salary report')}>
                    <div style={{float:'left'}}>
                        <input style={{width:'190px', display: 'inline-block'}}
                               onChange={this.handleFilterRangeChange.bind(this)}
                               className="form-control" type="text" value={this.state.filterRange} onClick={()=>{

                            this.setState({backgroundOverlayDisplay:'block', filterRangeDisplay:'block'});
                        }} />



                        <div className="daterange_flt" style={{display:this.state.filterRangeDisplay}}>
                            <DateRange

                                ranges={[selectionRange]}
                                showMonthAndYearPickers={false}
                                moveRangeOnFirstSelection={false}
                                locale={daterange_locale}
                                onChange={this.handleFilterDateRangeSelect.bind(this)}
                            />
                        </div>
                    </div>
                        <div className="tool-buttons">


                            <button style={{marginLeft:'8px', position:'relative',top:'3px'}}
                                className="btn btn-primary btn-sm"
                                onClick={() => this.gridApi.exportDataAsExcel()}
                            >
                                {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                            </button>


                        </div>


                        <div style={{ 'height': '100%', width:'100%' }} id="salaryReportGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                pinnedTopRowData={this.state.pinnedTopRowData}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

SalaryReport.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SalaryReport);

