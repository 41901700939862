import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import Modal from "../../components/common/modal";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Datetime from 'react-datetime';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";


class LoadingsFinalCalculationPlain  extends React.Component {

    constructor(props) {
	
        console.log( "Constructor props", props );
	
        super( props );
		
		let locale = 'cs';
		
        if( localStorage.getItem( 'locale' ) != null ){
			
			locale = localStorage.getItem( 'locale' );
			
		}
		
		let loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
		
		
		let datetime = new Date( );
		
		this.state = {
			locale: locale,
			datetime: this.FormatDatetime( datetime ),
			datetime_server: this.FormatDatetimeServer( datetime ), 
			provision: 0, 
			manager: this.props.manager,
			top_manager: this.props.top_manager,
			loadingsArray: this.props.loadingsArray,
			loadingsObject: this.props.loadingsObject,
			loadingsObjectByLoadingNumber: this.props.loadingsObjectByLoadingNumber,
			loadingsSelected: this.props.loadingsSelected,
			expense_types_types: JSON.parse( localStorage.expense_types_types ),
			expense_types: JSON.parse( localStorage.expense_types ),
			expenses: this.props.expenses,
			isopen: false,
			disabled: false, 
			spinner: 'none',
			appraisals: this.props.apprisals,
			rates: this.props.rates,
			results: this.props.results,
			
			modules: AllCommunityModules,
            localeText: loctext
		};
		
    }

    FormatDatetime( date ){
		
		return this.FormatDate( date ) + ' ' + this.FormatTime( date );
	
	}
	
	FormatDate( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return DD + '.' + MM + '.' + YYYY;
		
	}
	
	FormatTime( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	FormatDatetimeServer( date ){
	
		return this.FormatDateServer( date ) + ' ' + this.FormatTimeServer( date );
	
	}
	
	FormatDateServer( date ) {
		
		let DD = String( date.getDate( ) ).padStart( 2, '0' );
		
		let MM = String( date.getMonth( ) + 1 ).padStart( 2, '0' );
		
		let YYYY = date.getFullYear( );

		return YYYY + '-' + MM + '-' + DD;
		
	}
	
	FormatTimeServer( date ) {
		
		let hh = String( date.getHours( ) ).padStart( 2, '0' );
		
		let mm = String( date.getMinutes( ) ).padStart( 2, '0' );
		
		let ss = String( date.getSeconds( ) ).padStart( 2, '0' );
		
		return hh + ':' + mm + ':' + ss;
		
	}
	
	componentDidMount( ){
	
	}


    componentDidUpdate(prevProps) {

        console.log( "component plain did update", this.props, prevProps, this.state );
		
		
		let StateChange = { };
		
		let NeedFill = false;
		
		if(  ( this.props.isopen != prevProps.isopen ) && ( this.props.isopen == false ) ){
		
			console.log( "component table closed" );
		
			//StateChange.manager = null;
			//StateChange.top_manager = null;
			
			NeedFill = true;
		
		}
		
		if( JSON.stringify( prevProps.loadingsArray ) != JSON.stringify( this.props.loadingsArray ) ){ 
		
			StateChange.loadingsArray = this.props.loadingsArray;
			
			NeedFill = true;
		
		}
		
		if( JSON.stringify( prevProps.loadingsObject ) != JSON.stringify( this.props.loadingsObject ) ){ 
		
			StateChange.loadingsObject = this.props.loadingsObject;
			
			NeedFill = true;
			
		}
		
		if( JSON.stringify( prevProps.loadingsObjectByLoadingNumber ) != JSON.stringify( this.props.loadingsObjectByLoadingNumber ) ){ 
		
			StateChange.loadingsObjectByLoadingNumber = this.props.loadingsObjectByLoadingNumber;
			
			NeedFill = true;
			
		}
		
		if( 
			//( this.props.manager != null ) && 
			( prevProps.manager != this.props.manager ) 
		){
		
			let manager = this.props.manager;
		
			let loadingsObject = this.state.loadingsObject;
			
			let loadingsObjectByLoadingNumber = this.state.loadingsObjectByLoadingNumber;
			
			let loadingsArray = this.state.loadingsArray;
			
			let loadingsManager = [ ];
			
			for(
				let i in loadingsArray
			){
			
				if( loadingsObject[ loadingsArray[ i ].id ].manager == manager ){
			
					loadingsManager.push( loadingsArray[ i ] );
			
				}
				
			}

			StateChange.manager = manager;
			StateChange.loadingsSelected = [ ];
			StateChange.loadingsManager = loadingsManager;
			
			NeedFill = true;
		
		}
		
		if( 
			//( this.props.top_manager != null ) && 
			( prevProps.top_manager != this.props.top_manager ) 
		){
		
			let top_manager = this.props.top_manager;
		
			StateChange.top_manager = top_manager;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.loadingsSelected ) != JSON.stringify( this.props.loadingsSelected ) ) &&
			( this.props.loadingsSelected != null )
		) {
		
			StateChange.loadingsSelected = this.props.loadingsSelected;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.expenses ) != JSON.stringify( this.props.expenses ) ) &&
			( this.props.expenses != null )
		) {
		
			StateChange.expenses = this.props.expenses;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.appraisals ) != JSON.stringify( this.props.appraisals ) ) &&
			( this.props.appraisals != null )
		) {
		
			StateChange.appraisals = this.props.appraisals;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.results ) != JSON.stringify( this.props.results ) ) &&
			( this.props.results != null )
		) {
		
			StateChange.results = this.props.results;
			
			NeedFill = true;
		
		}
		
		if( 
			( JSON.stringify( prevProps.rates ) != JSON.stringify( this.props.rates ) ) &&
			( this.props.rates != null )
		) {
		
			StateChange.rates = this.props.rates;
			
			NeedFill = true;
		
		}
		
		
		
		
		if( NeedFill == true ){
		
			this.setState( 
				StateChange
			);
		
		}
		
    }

	FormatLoadings( ){
	
		let LoadingsFormatted = [ ];
	
		for(
			let i in this.state.loadingsSelected
		){
		
			if( !this.state.loadingsObject[ this.state.loadingsSelected[ i ].value ] ){
			
				continue;
			
			}
		
			LoadingsFormatted.push( 
				this.state.loadingsObject[ this.state.loadingsSelected[ i ].value ]
			);
		
		}
		
		return LoadingsFormatted;
	
	}
	
	FormatManager( ){
	
		let users = JSON.parse( localStorage.getItem( 'users' ) );
				
		let manager = "";
				
		if( this.state.manager != null ){
				
			for(
				let i in users
			){
				
				if( users[ i ].id.toString( ) == this.state.manager.toString( ) ){
				
					console.log( users[ i ].id, this.state.manager );
					
					manager = users[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}
		
		}
	
		return manager;
	
	}
	
	FormatTopManager( ){
	
		let users = JSON.parse( localStorage.getItem( 'users' ) );
				
		let top_manager = "-";
				
		if( this.state.top_manager != null ){
				
			for(
				let i in users
			){
				
				if( users[ i ].id.toString( ) == this.state.top_manager.toString( ) ){
				
					console.log( users[ i ].id, this.state.top_manager );
					
					top_manager = users[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}
		
		}
	
		return top_manager;
	
	}
	
	FormatWeight(){
	
		let product_types = JSON.parse( localStorage.product_types );
	
		let weights = {  };
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( !loading ){
			
				continue;
			
			}
			
			let product_type = "";
			
			for(
				let i2 in product_types
			){
			
				if( product_types[ i2 ].id.toString( ) == loading.product_type.toString( ) ){
				
					product_type = product_types[ i2 ][ "name_" + this.state.locale ];
					
					break;
				
				}
			
			}
			
			if( loading.net_material_qty != 0 ){
			
				if( !weights[ product_type ] ){
				
					weights[ product_type ] = ( loading.net_material_qty / 1000 );
				
				} else {
					
					weights[ product_type ] = weights[ product_type ] + ( loading.net_material_qty / 1000 );
					
				}
			
			}
		
		}
		
		let weights_array = [];
		
		for(
			let i in weights
		){
		
			weights_array.push( { material: i, weight: weights[ i ].toFixed( 2 ) } );
		
		}
	
		return weights_array;
	
	}
	
	FormatProcessedMaterial( ){
	
		let product_types = JSON.parse( localStorage.product_types );
	
		let weights = {  };
		
		console.log( this.state.results );
	
		for(
			let i in this.state.results
		){
		
			let result = this.state.results[ i ];
			
			if( !result ){
			
				continue;
			
			}
			
			let product_type = "";
			
			for(
				let i2 in product_types
			){
			
				if( product_types[ i2 ].id.toString( ) == result.product_type.toString( ) ){
				
					product_type = product_types[ i2 ][ "name_" + this.state.locale ];
					
					break;
				
				}
			
			}
			
			if( result.qty != 0 ){
			
				if( !weights[ product_type ] ){
				
					weights[ product_type ] = ( result.qty / 1000 );
				
				} else {
					
					weights[ product_type ] = weights[ product_type ] + ( result.qty / 1000 );
					
				}
			
			}
		
		}
		
		let weights_array = [];
		
		for(
			let i in weights
		){
		
			weights_array.push( { material: i, weight: weights[ i ].toFixed( 2 ) } );
		
		}
	
		return weights_array;
	
	}
	
	FormatNotProcessedMaterial( ){
	
		let weights = {  };
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( !loading ){
			
				continue;
			
			}
			
			let loading_number = loading.loading_number;
			
			if( loading.remaining_qty != 0 ){
			
				if( !weights[ loading_number ] ){
				
					weights[ loading_number ] = ( loading.remaining_qty / 1000 );
				
				} else {
					
					weights[ loading_number ] = weights[ loading_number ] + ( loading.remaining_qty / 1000 );
					
				}
			
			}
		
		}
		
		let weights_array = [ ];
		
		for(
			let i in weights
		){
		
			weights_array.push( { loading_number: i, weight: weights[ i ].toFixed( 2 ) } );
		
		}
	
		return weights_array;
	
	}
	
	
	FormatCalcLoading( loading ){
	
		let Result = {
			excvat: 0, //сумма за лоадинг от суммы всех инвоисов
			transportation_excvat: 0 //сумма за доправу от суммы всех инвоисов
		};
		
		if( 
			( loading.invoice_type == null ) &&
			( loading.invoice_excvat_kc_each == null )
		){
		
			console.log( "NaNCheck", Result.excvat );
	
			Result.excvat = loading.invoice_excvat ? parseFloat( loading.invoice_excvat ) : 0;
		
			console.log( "NaNCheck", Result.excvat );
		
		} else {
			
			let invoices_loadings = !loading.invoice_loadings ? [ ] : loading.invoice_loadings.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //погрузки каждого инвойса текущей погрузки
			
			let invoices_types = loading.invoice_type.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //тип каждого инвойса текущей погрузки
		
			let invoices_excvat_kc_each = loading.invoice_excvat_kc_each.trim( ).split( "," ).map( function( el ){ return el.trim( ); } ); //сумма каждого инвойса текущей погрузки
			
			
			if(
				( invoices_types.length !== invoices_excvat_kc_each.length ) 
			){
			
				throw new Error( "Critical error" );
			
			}
			
			for(
				let Iterator = 0;
				Iterator < invoices_types.length; //проходим каждый инвойс
				Iterator++
			){
				
				let invoice_loadings = !invoices_loadings[ Iterator ] ? [ ] : invoices_loadings[ Iterator ].split( "//" ).map( function( el ){ return el.trim( ); } ).filter( function( el ){ return el != ''; } ); //лоадинги инвоиса
			
				let invoice_loadings_weight = 0; //вес всех лоадингов инвойса
				
				if( invoice_loadings.length > 0 ){
			
					for(
						let Iterator2 = 0;
						Iterator2 < invoice_loadings.length;//проходим каждый лоадинг инвойса
						Iterator2++
					){
					
						let invoice_loading = this.state.loadingsObjectByLoadingNumber[ invoice_loadings[ Iterator2 ] ];
						
						console.log( this.state.loadingsObject, this.state.loadingsObjectByLoadingNumber, invoice_loadings[ Iterator2 ], invoice_loading );
						
						if( invoice_loading ){
						
							if( invoice_loading.net_material_qty ){
							
								console.log( invoice_loadings_weight, invoice_loading.net_material_qty );
						
								invoice_loadings_weight = invoice_loadings_weight + parseInt( invoice_loading.net_material_qty );
						
								console.log( invoice_loadings_weight, invoice_loading.net_material_qty );
						
							}
							
						}
						
					}
				
				} else {
				
					if( loading.net_material_qty ){
					
						console.log( invoice_loadings_weight, loading.net_material_qty );
					
						invoice_loadings_weight = parseInt( loading.net_material_qty );
						
						console.log( invoice_loadings_weight, loading.net_material_qty );
						
					}
					
				}
				
				
				if( Number.isNaN( invoice_loadings_weight ) === true ){
					
					throw new Error( "Critical error" );
					
				}
				
				if( invoice_loadings_weight === 0 ){
					
					continue;
					
				}
				
				
				
				
				if( !loading.net_material_qty ){
					continue;
				}
			
				let invoice_amount = ( invoices_excvat_kc_each[ Iterator ] ? parseFloat( invoices_excvat_kc_each[ Iterator ] ) : 0 );
				
				console.log( invoice_amount );
				
				let invoice_amount_per_unit = invoice_amount / invoice_loadings_weight; //сумма за кг(т) в инвоисе
				
				console.log( invoice_amount_per_unit );
				
				let invoice_weight_multiplier = parseInt( loading.net_material_qty ) / invoice_loadings_weight;
				
				console.log( invoice_weight_multiplier );
				
				let invoice_loading_amount = parseInt( loading.net_material_qty ) * invoice_amount_per_unit; //сумма за лоадинг от суммы инвоиса
				
				console.log( invoice_loading_amount );
			
				if( invoices_types[ Iterator ] != "2" ){ //transportation
				
					console.log( "NaNCheck", Result.excvat );
				
					Result.excvat = Result.excvat + invoice_loading_amount;
					
					console.log( "NaNCheck", Result.excvat );

				} else {
				
					console.log( "NaNCheck", Result.transportation_excvat );
				
					Result.transportation_excvat = Result.transportation_excvat + invoice_loading_amount;
				
					console.log( "NaNCheck", Result.transportation_excvat );
				
				}
			
			}
			
		}
		
		
		return Result;
	
	}

	
	FormatCalc( ){
	
		let excvat = 0;
		
		let weight = 0;
		
		let transportation_excvat = 0;
		
		let transportation_weight = 0;
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( !loading ){
			
				continue;
			
			}
			
			let Result = this.FormatCalcLoading( loading );
			
			weight = weight + parseInt( loading.net_material_qty );
			
			excvat = excvat + Result.excvat;
			
			transportation_excvat = transportation_excvat + Result.transportation_excvat;
		
		}
		
		return { 
			weight: weight, 
			amount: excvat,
			amount_eur: ( excvat / this.state.rates[ 3 ] ),
			transportation_amount: transportation_excvat,
			transportation_amount_eur: ( transportation_excvat / this.state.rates[ 3 ] )
		};
	
	}
	
	FormatCashExpensesNakup( ){
	
		let cash_expenses = 0;
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			let Am = ( loading.cash_expenses ? parseFloat( loading.cash_expenses ) : 0 ) * ( loading.cash_expenses_rate ? parseFloat( loading.cash_expenses_rate ) : 0 );
				
			cash_expenses = cash_expenses + Am;
		
		}
	
		return cash_expenses / this.state.rates[ 3 ];
	
	}
	
	FormatCashExpenses( ){
	
		let cash_expenses = 0;
	
		for(
			let i in this.state.loadingsSelected
		){
		
			let loadingId = this.state.loadingsSelected[ i ].value;
			
			let loading = this.state.loadingsObject[ loadingId ];
			
			if( !loading ){
			
				continue;
			
			}
			
			let Am = ( loading.cash_expenses ? parseFloat( loading.cash_expenses ) : 0 ) * ( loading.cash_expenses_rate ? parseFloat( loading.cash_expenses_rate ) : 0 );
				
			cash_expenses = cash_expenses + Am;
		
		}
	
		return cash_expenses.toFixed( 2 ) + " Kč" + " | " + ( cash_expenses / this.state.rates[ 3 ] ).toFixed( 2 ) + " €";
	
	}
	
	FormatExpenses( ){
	
		let ExpensesArray = [ ];
		
		let ExpensesTotal = 0;
		
				
		for(
			let i in this.state.expenses
		){
		
		
			let ExpenseElement = this.state.expenses[ i ];
			
			let manager = ExpenseElement.manager;
			
			
			if( this.state.manager == null ){
			
				continue;
			
			}
			
			//Kostyl neobhodimiy dlya svyazivania podhory(29) s sicho(14)
			
			if( this.state.manager == "14" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else if( this.state.manager == "29" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else {
			
				if( this.state.manager != manager ){
				
					continue;
				
				}
			
			}
			
			if( ExpenseElement.loadings == null ){
			
				continue;
			
			}
			
			let loadings = ExpenseElement.loadings.split( "//" );
			
			
			let ExpenseType = "";
			
			for(
				let i3 in this.state.expense_types
			){
			
				if( this.state.expense_types[ i3 ].id.toString( ) == ExpenseElement.expense_type.toString( ) ){
					
					ExpenseType = this.state.expense_types[ i3 ][ "name_" + this.state.locale ];
					
				}
			
			}
			
			let IncludeLoading = false;
			
			for(
				let i2 in this.state.loadingsSelected
			){
	
				if( !loadings.includes( this.state.loadingsSelected[ i2 ].value.toString( ) ) ){
				
					continue;
				
				}
				
				IncludeLoading = true;
				
				break;
				
			}
			
			if( IncludeLoading == false ){
			
				continue;
			
			}
			
			let Labels = [ ];
				
			for(
				let i3 in loadings
			){
			
				let loadingId = loadings[ i3 ];
				
				let load = this.state.loadingsObject[ loadingId.toString( ) ];
			
				Labels.push( load.loading_number );
				
			}
				
			let Am = ExpenseElement.amount_eur ? parseFloat( ExpenseElement.amount_eur ) : 0;
			
			ExpensesTotal += Am;
			
			let Am2 = ( Am * this.state.rates[ 3 ] ).toFixed( 2 ) + " Kč"+ " | " + Am.toFixed( 2 ) + " €";
			
			ExpensesArray.push( { type: ExpenseType, datetime: ExpenseElement.datetime.substr( 0, 10 ), label: Labels.join( ", " ), amount: Am2 } );
		
		}
		
		let Total = ( ExpensesTotal * this.state.rates[ 3 ] ).toFixed( 2 ) + " Kč"+ " | " + ExpensesTotal.toFixed( 2 ) + " €";
		
		return { Array: ExpensesArray, Total: Total };

	}
	
	FormatExpensesLoadings( ){
	
		let ExpensesArray = [ ];
		
		let ExpensesTotal = 0;
		
				
		for(
			let i in this.state.expenses
		){
		
		
			let ExpenseElement = this.state.expenses[ i ];
			
			let manager = ExpenseElement.manager;
			
			
			if( this.state.manager == null ){
			
				continue;
			
			}
			
			//Kostyl neobhodimiy dlya svyazivania podhory(29) s sicho(14)
			
			if( this.state.manager == "14" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else if( this.state.manager == "29" ){
			
				if( ( manager != "14" ) && ( manager != "29" ) ){
				
					continue;
				
				}
			
			} else {
			
				if( this.state.manager != manager ){
				
					continue;
				
				}
			
			}
			
			if( ExpenseElement.loadings == null ){
			
				continue;
			
			}
			
			let loadings = ExpenseElement.loadings.split( "//" );
			
			
			let netto_in_expense_kg = 0;
			
			for(
				let i2 in loadings
			){
			
				let loadingId = loadings[ i2 ];
				
				let load = this.state.loadingsObject[ loadingId.toString( ) ];
				
				if( load ){
				
					console.log( this.state.loadingsObject, this.state.loadingsObjectByLoadingNumber );
					
					console.log( load.loading_number + " " + load.company, load.net_material_qty );
				
					netto_in_expense_kg += ( load.net_material_qty ) ? load.net_material_qty : 0;
				
				}
				
			}
			
			console.log( "netto_in_expense_kg", netto_in_expense_kg );
			
			let amount_per_kg = ( ExpenseElement.amount_eur ? ( parseFloat( ExpenseElement.amount_eur ) / netto_in_expense_kg ) : 0 );
			
			console.log( "amount_per_kg", amount_per_kg );
			
			let amount_per_expense = 0;
			
			let loadings_numbers = [ ];
			
			for(
				let i2 in this.state.loadingsSelected
			){
			
				if( !loadings.includes( this.state.loadingsSelected[ i2 ].value.toString( ) ) ){
				
					continue;
				
				}
				
				let load = this.state.loadingsObject[ this.state.loadingsSelected[ i2 ].value.toString( ) ];
				
				if( load ){
				
					loadings_numbers.push( load.loading_number );
				
					amount_per_expense += ( ( load.net_material_qty ) ? ( load.net_material_qty * amount_per_kg ) : 0 );

				}

			}
			
			console.log( "amount per expense", amount_per_expense );
			
			
			let ExpenseType = "";
			
			for(
				let i3 in this.state.expense_types
			){
			
				if( this.state.expense_types[ i3 ].id.toString( ) == ExpenseElement.expense_type.toString( ) ){
					
					ExpenseType = this.state.expense_types[ i3 ][ "name_" + this.state.locale ];
					
				}
			
			}
			
			let IncludeLoading = false;
			
			for(
				let i2 in this.state.loadingsSelected
			){
	
				if( !loadings.includes( this.state.loadingsSelected[ i2 ].value.toString( ) ) ){
				
					continue;
				
				}
				
				IncludeLoading = true;
				
				break;
				
			}
			
			if( IncludeLoading == false ){
			
				continue;
			
			}
				
			let Am = amount_per_expense;
			
			ExpensesTotal += Am;
			
			let Am2 = ( Am * this.state.rates[ 3 ] ).toFixed( 2 ) + " Kč"+ " | " + Am.toFixed( 2 ) + " €";
			
			ExpensesArray.push( { type: ExpenseType, datetime: ExpenseElement.datetime.substr( 0, 10 ), label: loadings_numbers.join( ", " ), amount: Am2 } );
		
		}
		
		let Total =  ( ExpensesTotal * this.state.rates[ 3 ] ).toFixed( 2 ) + " Kč"+ " | " + ExpensesTotal.toFixed( 2 ) + " €";
		
		return { Array: ExpensesArray, Total: Total };

	}
	
	HandleChangeDatetime( Ev ){
	
		console.log( "HandleChangeDatetime", Ev );

		this.setState( {
			datetime: Ev.format( "DD.MM.YYYY hh:mm:ss" ),
			datetime_server: Ev.format( "YYYY-MM-DD hh:mm:ss" )
		} );
	
	}
	
	HandleChangeProvision( Ev ){
	
		console.log( "HandleChangeProvision", Ev );
	
		this.setState( {
			provision: parseFloat( Ev.target.value )
		} );
	
	}
	
	HandleSubmit( event ) {
	
		this.setState( { disabled: true } );
		
		this.setState( { spinner: 'inline' } );
	
        event.preventDefault( );
		
		let Ids = [ ];
		
		for(
			let i in this.state.loadingsSelected
		){
		
			Ids.push( this.state.loadingsSelected[ i ].value );
			
		}
		
		this.props.onSet( {
			ids: JSON.stringify( Ids ),
			provision: this.state.provision,
			datetime: this.state.datetime_server
		} );
		
    }
	
	onRowSelected() {
        if (this.gridApi.getSelectedNodes().length > 0) {
            this.setState({rowSelected: true});
        } else {
            this.setState({rowSelected: false});
        }
    }
	
	getRowNodeId = (data) => {
        return data.id;
    };
	
	getContextMenuItems = (params) => {

        let result = [

            'copy',
            'paste',
            'export',


        ];

        return result;
    };
	
	onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            var columnSettingsName = 'LoadingsFinalCalculationColumns';
            SaveState(columnSettingsName, params.columnApi, params.api);
        }
    }
	
	onFilterChanged( params ) {
		
		console.log( "onFilterChanged" );
		
        if( typeof( this.gridApi ) != 'undefined' ) {
			
            const statusBarComponent = this.gridApi.getStatusPanel('statusCountKey');
			
            let componentInstance = statusBarComponent;
			
            if( ( typeof( statusBarComponent ) !== 'undefined' ) && statusBarComponent.getFrameworkComponentInstance) {
				
                componentInstance = statusBarComponent.getFrameworkComponentInstance( );
				
            }

            if( componentInstance ) {
				
                componentInstance.forceUpdate( );
				
            }

        }

        this.onColumnsStateChanged( params );
		
    }

    render() {
	
		let FormatLoadingsArray = this.FormatLoadings( );
		
		let FormatLoadingsTable = null;
		
		let ApprisalPrice = 0;
		
		let ApprisalCount = 0;
										
		if( FormatLoadingsArray.length != 0 ){
		
			let FormatLoadingsRows = FormatLoadingsArray.map( function( el ){
										
				let loading_number = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.loading_number );
				
				let company = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.company );
				
				
				
				console.log( this.state.appraisals );
				
				let index = -1;
				
				for(
					let i in this.state.appraisals
				){
				
					if( this.state.appraisals[ i ].name == company ){
					
						index = i;
						
						break;
					
					}
				
				}
				
				let imagesCellContent = '';
				
				if( index != -1 ){
				
					let images = JSON.parse( this.state.appraisals[ index ].images );
					
					console.log( images, images.map );
			
					imagesCellContent = <>
						{
							images.map((item) => {
								return <img
									style={{
										width: '50px',
										height: '50px',
										border: '1px #ee solid',
										cursor: 'pointer',
										borderRadius: '4px',
										margin: '6px'
									}}
									onClick={() => {
										//if (!fullImage) {
											HttpService.post(UrlService.apiDomain() + 'api/attachments/by_path', {
												path: item.image_path
											}).then(response => {
												let image = "data:image/jpg;base64," + response.data.image;
												//setFullImage(image);
												$('.appraisals_modal').show()
												$('#appraisals_lightbox_img').attr( 'src', image );
											})
										//} else {
										//	$('.appraisals_modal').show()
										//	$('#appraisals_lightbox_img').attr('src', fullImage);
										//}
									}}
									src={`data:image/png;base64,${item.small_image}`}
									alt={'Image'}
								/>
							} )
						}
					</>
				
				} else {
				
					imagesCellContent = '-';
				
				}
		
				let imagesCell = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, imagesCellContent );
				
				let priceCell = '';
				
				if( index != -1 ){
				
					let price = this.state.appraisals[ index ].price + ' €/t';
					
					ApprisalPrice = ApprisalPrice + parseFloat( this.state.appraisals[ index ].price );
					
					ApprisalCount = ApprisalCount + 1;
				
					let priceCell  = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, price );
		
				}
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, loading_number, company, imagesCell, priceCell ) 
			
			}.bind( this ) );
									
			FormatLoadingsTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatLoadingsRows );
								
		} else {
		
			FormatLoadingsTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}

		//----------------------------------------------------
		
		let FormatWeightArray = this.FormatWeight( );
		
		let FormatWeightTable = null;
		
		let WeightTotal = 0;
										
		if( FormatWeightArray.length != 0 ){
		
			let FormatWeightRows = FormatWeightArray.map( function( el ){
										
				let material = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.material );
				
				let weight = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.weight + "t" );
				
				WeightTotal = WeightTotal + parseFloat( el.weight );
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, material, weight ) 
			
			} );
			
			FormatWeightTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatWeightRows );
								
		} else {
		
			FormatWeightTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}
		
		//----------------------------------------------------
		
		let FormatProcessedMaterialArray = this.FormatProcessedMaterial( );
		
		let FormatProcessedMaterialTable = null;
										
		if( FormatProcessedMaterialArray.length != 0 ){
		
			let FormatProcessedMaterialRows = FormatProcessedMaterialArray.map( function( el ){
										
				let material = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.material );
				
				let weight = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.weight + "t" );
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, material, weight ) 
			
			} );
			
			FormatProcessedMaterialTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatProcessedMaterialRows );
								
		} else {
		
			FormatProcessedMaterialTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}
		
		//----------------------------------------------------
		
		let FormatNotProcessedMaterialArray = this.FormatNotProcessedMaterial( );
		
		let FormatNotProcessedMaterialTable = null;
										
		if( FormatNotProcessedMaterialArray.length != 0 ){
		
			let FormatNotProcessedMaterialRows = FormatNotProcessedMaterialArray.map( function( el ){
										
				let loading_number = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.loading_number );
				
				let weight = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.weight + "t" );
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, loading_number, weight ) 
			
			} );
			
			FormatNotProcessedMaterialTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatNotProcessedMaterialRows );
								
		} else {
		
			FormatNotProcessedMaterialTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}
		
		//----------------------------------------------------
		
		let FormatExpensesResult = this.FormatExpenses( );
		
		let FormatExpensesArray = FormatExpensesResult.Array;
		
		let FormatExpensesTable = null;
										
		if( FormatExpensesArray.length != 0 ){
		
			let FormatExpensesRows = FormatExpensesArray.map( function( el ){
			
				let type = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.type );
				
				let label = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.label );
				
				let datetime = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.datetime );
				
				let amount = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.amount );
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, type, datetime, amount, label ) 
			
			} );
			
			FormatExpensesTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatExpensesRows );
		
		} else {
		
			FormatExpensesTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}
		
		//----------------------------------------------------
		
		let FormatExpensesLoadingsResult = this.FormatExpensesLoadings( );
		
		let FormatExpensesLoadingsArray = FormatExpensesLoadingsResult.Array;
		
		let FormatExpensesLoadingsTable = null;
										
		if( FormatExpensesLoadingsArray.length != 0 ){
		
			let FormatExpensesLoadingsRows = FormatExpensesLoadingsArray.map( function( el ){
			
				let type = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.type );
				
				let label = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.label );
				
				let datetime = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.datetime );
				
				let amount = React.createElement( 'div', {style: {display: "table-cell", border: "1px solid black", padding: "4px" }}, el.amount );
		
				return React.createElement( 'div', {style: {display: "table-row", border: "1px solid black" }}, type, datetime, amount, label ) 
			
			} );
			
			FormatExpensesLoadingsTable = React.createElement( 'div', {style: {display: "table", border: "1px solid black" }}, ...FormatExpensesLoadingsRows );
		
		} else {
		
			FormatExpensesLoadingsTable = React.createElement( 'div', {style: {display: "block" }}, '-' );
		
		}
		
		//----------------------------------------------------
		
		let Calc = this.FormatCalc( );
		
		let CashExpensesNakup = this.FormatCashExpensesNakup( );
		
		let PricePerT = ( Calc.weight == 0 ) ? 0 : ( Calc.amount + CashExpensesNakup ) / Calc.weight;
		
		let ApprisalPricePerT = ( ApprisalCount == 0 ) ? 0 : ApprisalPrice / ApprisalCount;
			
		let ManagerPricePerT = ( ApprisalPricePerT == 0 || PricePerT == 0 ) ? 0 : ( ApprisalPricePerT - PricePerT );
		
		//----------------------------------------------------
		
		return (
			<>
				<div className="appraisals_modal" onClick={() => {
                    $('.appraisals_modal').hide()
                }}>
                    <div className="appraisals_modal-content" onClick={() => {
                        $('.appraisals_modal').hide()
                    }}>
                        <img src="" id="appraisals_lightbox_img" onClick={() => {
                            $('.appraisals_modal').hide()
                        }}/>
                    </div>
                </div>
				<div className="container" style={{width: '100%', maxWidth: '100%', overflowY: 'auto', height: ( window.innerHeight - 400 ) + "px" }}>
					<div className="container" style={{width: '100%', maxWidth: '100%'}}>
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Top manager')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{this.FormatTopManager( )}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Manager')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{this.FormatManager( )}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Loadings')}:</label>
							</div>
							<div className="col-8" style={{marginRight: '10px'}}>
								{
									FormatLoadingsTable
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Weight netto')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ 
									FormatWeightTable
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Production results')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ 
									FormatProcessedMaterialTable
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Not processed material')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ 
									FormatNotProcessedMaterialTable
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Invoice pay')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ Calc.amount.toFixed( 2 ) + " (Bez DPH) Kč" + " | " + Calc.amount_eur.toFixed( 2 ) + " €" }
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Transportation pay')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ Calc.transportation_amount.toFixed( 2 ) + " (Bez DPH) Kč" + " | " + Calc.transportation_amount_eur.toFixed( 2 ) + " €" }
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Cash expenses pay')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{this.FormatCashExpenses( )}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Expenses')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{
									FormatExpensesTable
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Expenses total')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ FormatExpensesResult.Total }
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Loadings expenses')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ 
									FormatExpensesLoadingsTable 
								}
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Loadings xpenses total')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ FormatExpensesLoadingsResult.Total }
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Top manager provision')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ ( WeightTotal * 50 ).toFixed( 2 ) + " €" }
							</div>
							
						</div>
						<hr/ >
						<div className="row">
							<div className="col-3" style={{marginRight: '10px'}}>
								<label htmlFor="description">{this.props.t('Manager provision')}:</label>
							</div>
							<div className="col-7" style={{marginRight: '10px'}}>
								{ ( WeightTotal * 100 + ( 0.25 * ManagerPricePerT ) ).toFixed( 2 ) + " €" }
							</div>
							
						</div>
						<hr/ >
					</div>
				</div>
			</>
        );
    }
}

LoadingsFinalCalculationPlain.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingsFinalCalculationPlain);

