import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class InvoiceOtherItemsAdd extends Component<Props> {
    state = { name_cs: "", name_en: "",name_de: "",name_fr: "",name_it: "",name_es: "",name_ru: "",name_cn:""};
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name_cs, name_en,name_de, name_fr, name_it, name_es, name_ru,name_cn} = this.state;
        this.props.onAdd({name_cs, name_en,name_de, name_fr, name_it, name_es, name_ru,name_cn});
    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
            this.setState({name_cs: "", name_en: "",name_de: "",name_fr: "",name_it: "",name_es: "",name_ru: "",name_cn:""});
        }

    }

    render() {

        const {name_cs, name_en,name_de, name_fr, name_it, name_es, name_ru,name_cn} = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('German name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_de}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_de: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('French name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_fr}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_fr: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">


                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Italian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_it}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_it: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Spanish name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_es}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_es: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Russian name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_ru}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_ru: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Chinese name')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={name_cn}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cn: event.target.value })
                                    }
                                />
                            </div>
                        </div>


                    </div>



                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
InvoiceOtherItemsAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(InvoiceOtherItemsAdd);

