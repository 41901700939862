"use strict";


import UIStateT from "../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";

import UIOptionT from "../../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLabelT from "../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";


import UIComponentBaseConfigT from "../../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";


import TranslatorT from "../../../../../../../../Interface/Translator/TranslatorT.mjs";



import GTSUIComponentFormPrimaryLineElementT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineElementT.mjs";

import GTSUIComponentFormPrimaryLineT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/Line/GTSUIComponentFormPrimaryLineT.mjs";

import GTSUIComponentFormPrimaryT from "../../../../../../../../Interface/GTS/UI/Component/Form/Primary/GTSUIComponentFormPrimaryT.mjs";


import GTSUIComponentSelectT from "../../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";

import GTSUIComponentBooleanT from "../../../../../../../../Interface/GTS/UI/Component/Boolean/GTSUIComponentBooleanT.mjs";

import GTSUIComponentTextT from "../../../../../../../../Interface/GTS/UI/Component/Text/GTSUIComponentTextT.mjs";

import GTSUIComponentDoubleT from "../../../../../../../../Interface/GTS/UI/Component/Double/GTSUIComponentDoubleT.mjs";

import GTSUIComponentDateT from "../../../../../../../../Interface/GTS/UI/Component/Date/GTSUIComponentDateT.mjs";

import GTSUIComponentSelectTextT from "../../../../../../../../Interface/GTS/UI/Component/SelectText/GTSUIComponentSelectTextT.mjs";


const GTSUIModelSaleFormAddPrimaryGeneralFormT = class GTSUIModelSaleFormAddPrimaryGeneralFormT extends GTSUIComponentFormPrimaryT {
	
	#T = null;
	
	#UIComponentLabelStateMapDefault = null;
	
	//1
	
	#NeedOutinvoice = null;
	#NeedSupplylist = null;
	
	//Lines
	
	#Line1 = null;
	
	
	constructor( 
		Locale
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"20px",
			
			new UIStateMapT( [
				
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "95%" )
					] )
				)
				
			] )
		);
		
		super( [
			
		] );
		
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		
		this.#UIComponentLabelStateMapDefault = new UIStateMapT( [
				
			new UIStateT(
				"Default",
				"UIComponentLabelT",
				new UIStyleMapT( [
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "font-weight", "700" ),
					new UIStyleT( "font-size", "16px" ),
					new UIStyleT( "margin-bottom", "0.5rem" ),
					new UIStyleT( "white-space", "nowrap" ),
					new UIStyleT( "overflow", "hidden" )
				] )
			)
			
		] );
		
		this.#Line1Add(
			
		);
		
	}
	
	IsValidTotalGet(
	
	){
	
		let IsValid = true;
		
		//1
		
		if( this.#NeedOutinvoice.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		if( this.#NeedSupplylist.IsValidGet( ) === false ){

			IsValid = false;
		
		}
		
		
		return IsValid;
	
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		//1
		
		JSONData[ "need_outinvoice" ] = this.#NeedOutinvoice.ValueGet( );
		
		JSONData[ "need_supplylist" ] = this.#NeedSupplylist.ValueGet( );
		
		return JSONData;
		
	}
	
	NeedOutinvoiceGet(
	
	){
		
		return this.#NeedOutinvoice;
		
	}
	
	NeedSupplylistGet(
	
	){
		
		return this.#NeedSupplylist;
		
	}
	
	#Line1Add( 
		
	){
		
		//NeedOutinvoice
		
		let NeedOutinvoiceLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Create invoice" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let NeedOutinvoiceComponent = new GTSUIComponentBooleanT(
			true,
			null
		);
		
		let NeedOutinvoiceLineElement = new GTSUIComponentFormPrimaryLineElementT(
			NeedOutinvoiceLabel,
			NeedOutinvoiceComponent,
			"15%"
		);
		
		//NeedSupplylist
		
		let NeedSupplylistLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Create supplylist" ),
				this.#UIComponentLabelStateMapDefault.Copy( )
			)
		);
		
		let NeedSupplylistComponent = new GTSUIComponentBooleanT(
			true,
			null
		);
		
		let NeedSupplylistLineElement = new GTSUIComponentFormPrimaryLineElementT(
			NeedSupplylistLabel,
			NeedSupplylistComponent,
			"15%"
		);
		
		//Line
		
		let GTSUIComponentFormPrimaryLine = new GTSUIComponentFormPrimaryLineT( [
			NeedOutinvoiceLineElement,
			NeedSupplylistLineElement
		] );
		
		super.LineSet(
			0,
			GTSUIComponentFormPrimaryLine
		);
		
		//Shortcuts
		
		this.#NeedOutinvoice = NeedOutinvoiceComponent;
		
		this.#NeedSupplylist = NeedSupplylistComponent;
		
	}
	
	#OnNeedOutinvoiceChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.OnNeedOutinvoiceChange",  Value, IsValid );
		
		
		if( super.EventExists( "NeedOutinvoiceChange" ) === true ){
			
			let EventFn = super.EventGet( "NeedOutinvoiceChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		this.#OnChange(
			Value,
			IsValid
		);
		
	}
	
	#OnNeedSupplylistChange(
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelSaleFormAddMainT.OnNeedSupplylistChange",  Value, IsValid );
		
		
		if( super.EventExists( "NeedSupplylistChange" ) === true ){
			
			let EventFn = super.EventGet( "NeedSupplylistChange" );
			
			EventFn( Value, IsValid );
			
		}
		
		this.#OnChange(
			Value,
			IsValid
		);
		
	}
	
	Start( ){
		
		//1
		
		this.#NeedOutinvoice.Start( );
		
		this.#NeedSupplylist.Start( );
		
	}
	
	#OnChange( 
		Value,
		IsValid
	){
		
		console.log( "GTSUIModelGeneralFormAddMainT.OnValueChange",  Value, IsValid );
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let IsValid = this.IsValidTotalGet( );
			
			let Value = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Change" );
			
			EventFn( Value, IsValid );
			
		}
		
	}
	
	Listen(
		OnNeedOutinvoiceChangeFn,
		OnNeedSupplylistChangeFn,
		OnChangeFn
	){
		
		super.EventSet( "NeedOutinvoiceChange", OnNeedOutinvoiceChangeFn );
		
		super.EventSet( "NeedSupplylistChange", OnNeedSupplylistChangeFn );
		
		super.EventSet( "Change", OnChangeFn );
		
		//1
		
		this.#NeedOutinvoice.Listen(
			this.#OnNeedOutinvoiceChange.bind( this )
		);
		
		this.#NeedSupplylist.Listen(
			this.#OnNeedSupplylistChange.bind( this )
		);
		
	}
	
};


export default GTSUIModelSaleFormAddPrimaryGeneralFormT;