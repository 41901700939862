import { translate } from 'react-polyglot';

import PropTypes from 'prop-types';

import { Component } from "react";


const InputMultiselectBase = class InputMultiselectBase extends Component { 
	
	#Input = null;
	#Select = null;
	
	#Field = null;
	#Disabled = null;
	#OnChangeCb = null;
	#OptionsObject = null;
	#OptionsFiltered = null;
	#OptionsFilter = null;
	
	#Values = null;
	
	#InputValue = null;
	
	#RefCb = null;
	
	#Placeholder = null;
	
	#ValuesOut = null;

	constructor( props ){
		
		super( props );
		
		this.#Input = null;
		this.#Select = null;
		
		this.#Field = props.field;
		this.#Disabled = props.disabled;
		this.#OnChangeCb = props.on_change_cb;
		this.#OptionsObject = props.options_object;
		this.#OptionsFilter = props.options_filter;
		
		
		this.#Placeholder = props.placeholder;
		
		this.#ValuesOut = props.values_out;
		
		this.#Filter( );
		
		this.#Values = new Map( );
		
		this.#InputValue = "";
		
		this.#RefCb = props.ref_cb;
		
	}
	
	componentDidMount( ) {
		
		if( this.#RefCb ){
		
			this.#RefCb( { Input: this.#Input, Select: this.#Select } );
		
		}

    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "InputMultiselectBase.componentDidUpdate", this.props, prevProps, this.state, prevState );
		
		
		if( this.props.values !== this.#Values ){
			
			this.#Values = this.props.values;
			
		
			this.#UpdateInput( );
		
			this.#UpdateSelectChecked( );
			
			this.#OnChangeCb( 
				this.#Field, 
				"multiselect", 
				this.#Values
			);
			
		}
		
		if( this.props.disabled !== this.#Disabled ){
			
			this.#Disabled = this.props.disabled;
			
			
			this.#Input.disabled = this.#Disabled;
			
		}
		
	}
	
	componentWillUnmount( ){
		
	}
	
	#Filter( ){
		
		let FilteredOptions = null;
		
		if( this.#OptionsFilter ){
		
			FilteredOptions = this.#OptionsFilter( this.#ValuesOut, this.#OptionsObject );
		
		} else {
			
			FilteredOptions = this.#OptionsObject;
			
		}
		
		this.#OptionsFiltered = FilteredOptions;
		
	}
	
	#UpdateInput( ){
		
		this.#Input.value = this.#InputValue;
		
		this.#Input.disabled = this.#Disabled;
		
		console.log( this.#Values );
		
		this.#Input.placeholder = this.props.t( "Selected" ) + ":" + " " + this.#Values.size;
		
	}
	
	#UpdateSelectChecked( ){
		
		let Children = this.#Select.children;
		
		
		for(
			let I = 0;
			I < Children.length;
			I++
		){
			
			let Child = Children[ I ];
			
			if( Child.value === 0 ){
				
				continue;
				
			}
		
			if( this.#Values.has( Children[ I ].value.toString( ) ) === true ){
							
				Child.className = "FastSelectChecked";
						
			} else {
				
				Child.className = "FastSelectUnchecked";
				
			}
			
		}
		
		
		let HasUnchecked = false;
		
		for(
			let I of this.#OptionsFiltered.keys( )
		){
			
			let OptionFilteredValue = this.#OptionsFiltered.get( I ).value;
			
			if( this.#Values.has( OptionFilteredValue.toString( ) ) === false ){
				
				HasUnchecked = true;
				
			}
			
		}
		
		if( HasUnchecked === true ){
			
			Children[ 0 ].className = "FastSelectUnchecked";
			
		} else {
			
			Children[ 0 ].className = "FastSelectChecked";
			
		}
		
	}
	
	#UpdateSelectVisible( ){
		
		this.#Input.value = this.#InputValue;
		
		
		
		let Children = this.#Select.children;
		
		
		for(
			let I = 0;
			I < Children.length;
			I++
		){
			
			let Child = Children[ I ];
			
			
			if( Child.value === 0 ){
				
				Child.style.display = "block";
				
				continue;
				
			}
			
		
			if( this.#InputValue === "" ){
				
				if( this.#OptionsFiltered.has( Child.value.toString( ) ) === false ){
					
					if( Child.style.display !== "none" ){
						
						Child.style.display = "none";
					
					}
					
				} else {
				
					if( Child.style.display === "none" ){
					
						Child.style.display = "block";
						
					}
				
				}
				
			} else {
				
				if( this.#OptionsFiltered.has( Child.value.toString( ) ) === false ){
					
					if( Child.style.display !== "none" ){
						
						Child.style.display = "none";
					
					}
					
				} else {
			
					if( this.#OptionsObject.get( Child.value.toString( ) ).label.toLowerCase( ).indexOf( this.#InputValue.toLowerCase( ) ) === -1 ){
					
						if( Child.style.display !== "none" ){
					
							Child.style.display = "none";
							
						}
						
					} else {
						
						if( Child.style.display === "none" ){
					
							Child.style.display = "block";
							
						}
						
					}
				
				}
			
			}
			
		}
		
	}
	
	#SelectInputOnChange( Value ){
		
		console.log( "SelectInputOnChange", Value );
		
		
		this.#InputValue = Value;
		
		
		this.#UpdateSelectVisible( );
		
	}
	
	#SelectOptionOnClick( Ev ){
		
		console.log( "li", Ev.target.value, Ev.target.innerText, this );
							
							
		Ev.preventDefault( );
		
		
		let Value = Ev.target.value.toString( );
		
		let Label =  Ev.target.innerText;
		
		
		
		if( !this.#Values.has( Value ) ){
			
			this.#Values.set( Value.toString( ), { value: Value, label: Label } );
			
		} else {
			
			this.#Values.delete( Value.toString( ) );
			
		}
		
		
		this.#UpdateSelectChecked( );
		
		this.#UpdateInput( );	
		
	}
	
	#SelectOptionOnClickAll( Ev ){
		
		console.log( "li", Ev.target.value, Ev.target.innerText, this );
							
							
		Ev.preventDefault( );
		
		
		//let Value = Ev.target.value.toString( );
		
		//let Label =  this.props.t( "All" );
		
		//this.#Values.set( I.toString( ), { value: Value, label: Label } );
		
		
		if( this.#OptionsFiltered.size === this.#Values.size ){
			
			for( let I of this.#OptionsFiltered.keys( ) ){
				
				this.#Values.delete( I.toString( ) );
				
			}
			
		} else {
		
			for( let I of this.#OptionsFiltered.keys( ) ){
				
				this.#Values.set( I.toString( ), this.#OptionsFiltered.get( I ) );
				
			}
		
		}
		
		/*if( !this.#Values.has( Value ) ){
			
			this.#Values.set( Value.toString( ), { value: Value, label: Label } );
			
		} else {
			
			this.#Values.delete( Value.toString( ) );
			
		}*/
		
		
		this.#UpdateSelectChecked( );
		
		this.#UpdateInput( );	
		
	}
	
	#SelectInputOnFocus( ){
		
		console.log( "SelectInputOnFocus" );
		
		
		this.#Filter( );
		
		this.#UpdateSelectVisible( );
		
		this.#Select.style.display = "block";
		
	}
	
	#SelectOptionListOnMouseOver( ){
		
	}
	
	#SelectOptionListOnMouseOut( ){
		
	}
	
	#DivOnMouseOver( ){
		
		console.log( "DivOnMouseOver" );
		
	}
	
	#DivOnMouseOut( ){
		
		console.log( "DivOnMouseOut" );
		
		
		this.#InputValue = "";
		
		this.#Select.style.display = "none"; 
		
		this.#Input.blur( );
		
		this.#Input.value = "";
		
		this.#SelectInputOnChange( "" );
		
		this.#OnChangeCb( 
			this.#Field, 
			"multiselect", 
			this.#Values
		);
		
	}
	

	#MakeItems( ){
	
		let Result = [ ];
		
			
		Result.push(
		
			<li
				style = {{ "display": "block" }}
				className = { "FastSelectUnchecked" }
				key={ "0" } 
				value={ "0" }
				onClick = {
					( Ev ) => {
						
						this.#SelectOptionOnClickAll( Ev );
						
					}
				}
			>
				{ this.props.t( "Select all" ) }
			</li>
			
		);
		
		
		for(
			let I of this.#OptionsObject.keys( )
		){
			
			let Item = this.#OptionsObject.get( I );
			
			Result.push(
			
				<li
					className = { "FastSelectUnchecked" }
					selected={ this.#Values.has( Item.value ) }
					key={ Item.value } 
					value={ Item.value }
					onClick = {
						( Ev ) => {
							
							this.#SelectOptionOnClick( Ev );
							
						}
					}
				>
					{ Item.label }
				</li>
				
			);
			
		}
		
		return Result;
		
	}
	
	render( ){
	
		return (
			<div
				style = { { "position": "relative", "width": "100%" } }
				onMouseEnter={ 
					( ) => { 
						
						this.#DivOnMouseOver( );
						
					} 
				}
				onMouseLeave={ 
					( ) => { 
					
						this.#DivOnMouseOut( );
						
					} 
				}
			>
				<input
					type = "text"
					ref = { ( Ref ) => { this.#Input = Ref; } }
					disabled = { this.#Disabled }
					className = { "form-control" + " " + "FastSelectInput" }
					onFocus= { 
						( ) => { 
						
							this.#SelectInputOnFocus( );
							
						} 
					}
					onChange = { 
						( Ev ) => {
							
							this.#SelectInputOnChange( Ev.target.value );
						
						}
					}
				/>
				<ul
					style = { { 
						cursor: "pointer",
						display: "none", 
						position: "absolute", 
						width: "100%",
						zIndex: 10000, 
						height: "400px", 
						overflowY: "scroll",
						overflowX: "hidden"
					} }
					ref = { ( Ref ) => { this.#Select = Ref; } }
					className = { "form-control" }
					multiple
					onMouseEnter={ 
						( ) => { 
							
							this.#SelectOptionListOnMouseOver( );
							
						} 
					}
					onMouseLeave={ 
						( ) => { 
						
							this.#SelectOptionListOnMouseOut( );
							
						} 
					}
				>
					{
						this.#MakeItems( )
					}
				</ul>
			</div>
								
		);
		
	};
	
};


InputMultiselectBase.propTypes = {
	
    t: PropTypes.func.isRequired
	
};


export default translate( )( InputMultiselectBase );

