import { ColDef } from "ag-grid-community";


import InputMultiselectBaseParser from "../../inputs/Multiselect/Base/InputMultiselectBaseParser";
import InputMultiselectBaseSerializer from "../../inputs/Multiselect/Base/InputMultiselectBaseSerializer";

import Input from "../../inputs/Input";


const ColumnSingleMultiselectBase = class ColumnSingleMultiselectBase implements ColDef {

	headerName = null;
		
	field = null;
		
	options = null;
		
	editable = false;
			
	cellEditor = 'ComponentMultiselectBase';
	
	cellEditorPopup = true;
		
	cellEditorPopupPosition = 'under';
	
	splitter = null;
	
	joiner = null;
	
	
	constructor(
		Name,
		Field,
		OptionsObject,
		Splitter,
		Joiner,
		Editable,
		ModelSchema,
		Meta,
		ValuesOut
		//Filter
	){
		
		this.headerName = Name;
		
		this.field = Field;
		
		this.editable = Editable;
		
		this.options = OptionsObject;
			
		this.cellEditor = 'ComponentMultiselectBase';
		
		this.splitter = Splitter;
		
		this.joiner = Joiner;
	
	
	
		this.cellEditorParams = {
			field: this.field,
			disabled: !this.editable,
			options_object: this.options,
			options_filter: Meta.OptionsFilter,
			placeholder: Meta.Placeholder,
			values_out: ValuesOut
		};
		
		this.valueGetter = function( params ) {
			
			console.log( "valueGetter", params );
			
			return InputMultiselectBaseParser( params.data[ this.field ], this.options, this.splitter );
			
		}.bind( this );
	
		this.valueFormatter = function( params ){
			
			console.log( "valueFormatter", params );
			
			
			let Data = InputMultiselectBaseParser( params.data[ this.field ], this.options, this.splitter );
			
			return [ ...Data.values( ) ].join( this.joiner );
			
		}.bind( this );
	
		this.valueParser = function( params ){
			
			console.log( "valueParser", params );
			
		}.bind( this );
	
		this.valueSetter = function( params ){
			
			console.log( "valueSetter", params );
			
			
			let IsValid = Input.Check( Field, Meta, params.newValue, ModelSchema, params.data );
			
			
			if( IsValid === false ){
				
				params.data[ this.field ] = InputMultiselectBaseSerializer( params.oldValue, this.options, this.splitter );
				
			} else {
				
				params.data[ this.field ] = InputMultiselectBaseSerializer( params.newValue, this.options, this.splitter );
				
			}
			
			
			return IsValid;
			
		}.bind( this );
		
	}
		
};


export default ColumnSingleMultiselectBase;