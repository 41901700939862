import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import Expense from "../../components/expenses";


import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
	onUpdateContinent: (data) => void;
	onUpdateCountry: (data) => void;
	onUpdateYear: (data) => void;
	onUpdateMonth: (data) => void;
}


class ExpensesPage extends Component<Props> {

	constructor( props ){
		
		super( props );
		
		this.state = {
			Year: null,
			Month: null,
			Continent: null,
			Country: null
		};
		
	}

    render() {


        return (
            <div className="wrapper">

                <Layout
					onUpdateContinent={(e)=>{ console.log("expense update continent", e); this.setState( { Continent: e.Continent } ); } }
					onUpdateCountry={(e)=>{ console.log("expense update country", e); this.setState( { Country: e.Country } ); } }
					onUpdateYear={(e)=>{ console.log("expense update year", e); this.setState( { Year: e.Year } ) } } 
					onUpdateMonth={(e)=>{ console.log("expense update month", e); this.setState( { Month: e.Month } ) } }
				>
                    <Content hideTitle={true} title="Loadings">
                        <Expense 
							id="0" 
							opts={this.props.opts} 
							year={ this.state.Year } 
							month={ this.state.Month } 
							continent = { this.state.Continent }
							country = { this.state.Country }
							fromSpedition={false}
						/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

ExpensesPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate()(ExpensesPage);

