import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Select from 'react-select';
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

interface Props {
    onAdd: (data) => void;
}

class SupplylistAdd extends Component<Props> {

    constructor(props) {
        super(props)
        this.state = {
            outinvoices:[],
            product_types:[this.blankOption],
            spz1: "",
            spz2: "",
            driver: "",
            driver_value: "",//this.blankOption,
            allow_submit:false,
            outinvoice_value:this.blankOption,
            outinvoice:'',
            items: [{palette_number:'', qty:'', qty_gross:'', product_type:'', product_type_value:{value: "", label:this.props.t('Choose')}}]

        };

    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {
            HttpService.get(UrlService.apiDomain()+'api/supplylists_get_outinvoices')
                .then(res => {



                    const optionsArray = [];
                    res.data.map((e)=>{
                        optionsArray.push({value: e.id, label:e.name});
                    });

                    this.setState({outinvoices:optionsArray});

                });



            this.setState({
                outinvoices:[],
                product_types:[this.blankOption],
                spz1: "",
                spz2: "",
                driver: "",
                driver_value: "",//this.blankOption,
                outinvoice_value:this.blankOption,
                outinvoice:'',
                allow_submit:false,
                items: [{palette_number:'', qty:'', qty_gross:'', product_type:'', product_type_value:{value: "", label:this.props.t('Choose')}}]

            });
            this.get_product_types();

        }

    }

    blankOption={value: "", label:this.props.t('Choose')};

    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { spz1,spz2,outinvoice, driver, items} = this.state;
        const invoice_id=this.props.invoice_id;
        const sale_ids=this.props.sale_ids;
        this.props.onAdd({spz1,spz2,outinvoice, driver, items,sale_ids, invoice_id});
    }

    get_product_types(outinvoice_number) {
        var invoice_id=this.props.invoice_id;
        if (this.state.outinvoice!='') invoice_id=this.state.outinvoice;
        if (outinvoice_number) invoice_id=outinvoice_number;


        const data={sale_ids:this.props.sale_ids, invoice_id:invoice_id}
        HttpService.post(UrlService.apiDomain()+'api/supplylists_get_product_types',data)
            .then(res => {


                var product_types=[this.blankOption];

                var locale = 'cs';
                if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

                JSON.parse(localStorage.getItem('product_types')).map((e)=>{
                    if (res.data.product_types.includes(e.id)) product_types.push({value: e.id, label:e['name_'+locale]});

                });


                this.setState({
                    product_types:product_types,
                }, ()=>{this.allow_submit();});
            })
            .catch((error) => {
                console.log(error);
            })
    }


    handleChange(event, type, inputType) {

		console.log(event, type, inputType);

        var stateValue={};


        if (inputType==='select') {
            stateValue[type]=event.value;
            stateValue[type+'_value']=event;


        }


        if (inputType==='text') {
            stateValue[type]=event.target.value;


        }


        if (inputType==='date') {

            if (typeof event==="object") {
                stateValue[type]=event.format("DD.MM.YYYY");
            } else {
                stateValue[type]=event;
            }
        }

        this.setState(stateValue, ()=>{this.allow_submit();});



        if (type==='outinvoice') {
            this.get_product_types(event.value);
        }


    }



    allow_submit() {

        var allow_submit=true;




        this.state.items.map((e)=>{
            if (e.qty<=0 || e.qty=='' || e.qty_gross<=0 || e.qty_gross=='' || e.palette_number=='' || e.product_type=='') allow_submit=false;
        });

        this.setState({allow_submit:allow_submit});
    }

    handleItemOnChange(event, i, type) {

        if (type==='product_type') {
            var items = this.state.items;
            items[i][type]=event.value;
            items[i][type+'_value']=event;
            this.setState({items:items});
            this.allow_submit();
        }


        else if (type==='qty' || type==='qty_gross'){
            if (event.target.value=='') event.target.value=0;
            var items = this.state.items;
            items[i][type]=event.target.value;
            this.setState({items:items});

            this.allow_submit();
        }


        else if (type==='palette_number'){
            var items = this.state.items;
            items[i][type]=event.target.value;
            this.setState({items:items});

            this.allow_submit();
        }



    }
    render() {

        const {  spz1, spz2, outinvoices, items, product_types} = this.state;

        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        const drivers = [{value: "", label:this.props.t('Choose')}];

        JSON.parse(localStorage.getItem("drivers")).map((e)=>{
            drivers.push({value: e.id, label:e.name});
        })

var display='block';
if (this.props.from_sale=='yes') display='none';

        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">

                        <div className="col-3" style={{display:display}}>
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Invoice')}</label>
                                <Select
                                    name="outinvoice"
                                    onChange={(event) => this.handleChange(event,'outinvoice', 'select')}
                                    value={this.state.outinvoice_value}

                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={outinvoices} />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Truck plate 1')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={spz1}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) => this.handleChange(event,'spz1', 'text')}
                                />
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Truck plate 2')}</label>
                                <input
                                    type="text"

                                    className="form-control"
                                    value={spz2}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) => this.handleChange(event,'spz2', 'text')}
                                />
                            </div>
                        </div>


                        <div className="col-3" >
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Driver')}</label>
								<input
                                    type="text"

                                    className="form-control"
                                    value={this.state.driver}
                                    placeholder={this.props.t('Enter the value')}
                                    onChange={(event) => this.handleChange( event, 'driver', 'text')}
                                />
                                {/*<Select
                                    name="driver"
                                    onChange={(event) => this.handleChange(event,'driver', 'select')}
                                    value={this.state.driver_value}
                                    required
                                    placeholder={this.props.t('Select')}
                                    classNamePrefix="select"
                                    options={drivers} />*/}
                            </div>
                        </div>



                    </div>


                    {
                        this.state.items.map((item, i) => (
                            <div className="row">


                                <div className="col-3">
                                    <div className="form-group">

                                        <Select key={'product_type_'+i}
                                                name="product_type"

                                                onChange={(event) => this.handleItemOnChange(event, i, 'product_type')}
                                                value={item.product_type_value}
                                                required
                                                placeholder={this.props.t('Select')}
                                                classNamePrefix="select"
                                                options={product_types} />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="form-group">

                                        <input
                                            type="text"
                                            required
                                            pattern="[.0-9]*"
                                            className="form-control"
                                            value={item.qty}
                                            placeholder={this.props.t('Net kg')}
                                            onChange={(event) =>
                                                this.handleItemOnChange(event,i,'qty')
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            required
                                            pattern="[.0-9]*"
                                            className="form-control"
                                            value={item.qty_gross}
                                            placeholder={this.props.t('Gross kg')}
                                            onChange={(event) =>
                                                this.handleItemOnChange(event,i,'qty_gross')
                                            }
                                        />
                                    </div>
                                </div>



                                <div className="col-3">
                                    <div className="form-group">

                                        <input
                                            type="text"
                                            required
                                            className="form-control"
                                            value={item.palette_number}
                                            placeholder={this.props.t('Palette number')}
                                            onChange={(event) =>
                                                this.handleItemOnChange(event,i,'palette_number')
                                            }
                                        />
                                    </div>
                                </div>

                            </div>
                        ))
                    }



                </div>

                <div className="row">

                    <div className="col-6" >
                    <button disabled={!this.state.allow_submit} className="btn btn-success">{this.props.t('Save')}</button>
                    </div>
                <div className="col-3" >
                    <label htmlFor="description">{this.props.t('Add item')}</label>
                    <div className="form-group">

                        <div className="btn-group">
                            <button className="btn btn-primary"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        var items=this.state.items;
                                        items.push({palette_number:'', qty:'', qty_gross:'', product_type:'', product_type_value:{value: "", label:this.props.t('Choose')}});
                                        this.setState({items:items});
                                        this.allow_submit();
                                    }}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary"
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        var items=this.state.items;
                                        items.pop();
                                        this.setState({items:items});
                                        this.allow_submit();
                                    }}
                                    disabled={this.state.items.length===1 ? true : false}><i className="fa fa-minus"></i></button>
                        </div>
                    </div>
                </div>

                </div>


            </form>
        );
    }
}
SupplylistAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(SupplylistAdd);

