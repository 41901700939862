"use strict";


import UIStyleT from "../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../Type/UI/Style/UIStyleMapT.mjs";


import UIStateT from "../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../Type/UI/State/UIStateMapT.mjs";


import UIOptionT from "../../../../../../../Type/UI/Option/UIOptionT.mjs";

import UIOptionMapT from "../../../../../../../Type/UI/Option/UIOptionMapT.mjs";


import UIElementT from "../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutVT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentTextT from "../../../../../../../Interface/UI/Component/Text/UIComponentTextT.mjs";

import UIComponentTextDropDownT from "../../../../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentImageT from "../../../../../../../Interface/UI/Component/Image/UIComponentImageT.mjs";

import UIComponentCheckListT from "../../../../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";

import UIComponentSelectT from "../../../../../../../Interface/UI/Component/Select/UIComponentSelectT.mjs";

import UIComponentButtonT from "../../../../../../../Interface/UI/Component/Button/UIComponentButtonT.mjs";

import UIComponentLabelT from "../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentBaseConfigT from "../../../../../../../Type/UI/Component/Base/UIComponentBaseConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentTextConfigT from "../../../../../../../Type/UI/Component/Text/UIComponentTextConfigT.mjs";

import UIComponentTextDropDownConfigT from "../../../../../../../Type/UI/Component/TextDropDown/UIComponentTextDropDownConfigT.mjs";

import UIComponentImageConfigT from "../../../../../../../Type/UI/Component/Image/UIComponentImageConfigT.mjs";

import UIComponentCheckListConfigT from "../../../../../../../Type/UI/Component/CheckList/UIComponentCheckListConfigT.mjs";

import UIComponentSelectConfigT from "../../../../../../../Type/UI/Component/Select/UIComponentSelectConfigT.mjs";

import UIComponentButtonConfigT from "../../../../../../../Type/UI/Component/Button/UIComponentButtonConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";


import TranslatorT from "../../../../../../../Interface/Translator/TranslatorT.mjs";


import GTSUIComponentSelectT from "../../../../../../../Interface/GTS/UI/Component/Select/GTSUIComponentSelectT.mjs";

import GTSUIModelOutinvoiceNumberT from "../../../../../../../Interface/GTS/UI/Model/Outinvoice/Number/GTSUIModelOutinvoiceNumberT.mjs";


import GTSUIModelOutinvoiceFormAddMainT from "./Main/GTSUIModelOutinvoiceFormAddMainT.mjs"; //TODO MainForm

import GTSUIModelOutinvoiceFormAddItemFormT from "./Item/GTSUIModelOutinvoiceFormAddItemFormT.mjs";



const GTSUIModelOutinvoiceFormAddT = class GTSUIModelOutinvoiceFormAddT extends UIComponentLayoutVT {
	
	#T = null;
	
	#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm = null;
	#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd = null;
	#GTSUIModelOutinvoiceFormAddSecondaryItemForm = null;
	
	#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate = null;
	#GTSUIModelOutinvoiceFormAddSecondaryButtonReset = null;
	
	constructor( 
		Locale,
		
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre,
	
		UIOptionMapCompany,
		CompanyDefault,
		
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToMethodDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContract,
		ContractDefault,
		
		ReceiptNumberDefault,
		
		ReceiptDateDefault,
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault,
		
		
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault,
		
		
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault,
		
		UIOptionMapAdditionalText,
		AdditionalTextDefault,
	
		ExcVATDefault,
		VATDefault,
		IncVATDefault,
		
		IsProductDefault,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault,
		
		ContractNumberModelOptionMap,
		OutinvoiceModelOptionMap,
		
		OutinvoicePrepareJSONValue,
		AdditionalTextModelOptionMap
	){
		
		let UIComponentLayoutVConfig = new UIComponentLayoutVConfigT(
			"nowrap",
			"flex-start",
			"stretch",
			"stretch",
			"0",
			
			new UIStateMapT( [
				new UIStateT(
					"Default",
					"UIComponentLayoutVT", //Class
		
					new UIStyleMapT( [ //UIStyleMap
						new UIStyleT( "width", "100%" )
					] )
				)
			] )
		);
		
		super(
			new UIElementT( ),
			UIComponentLayoutVConfig
		);
		
		this.#T = TranslatorT.Import( Locale.toUpperCase( ) );
		
		this.#InitMain(
			Locale,
			
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			UIOptionMapCompany,
			CompanyDefault,
		
			UIOptionMapPaymentMethod,
			PaymentMethodDefault,
			
			UIOptionMapPayTo,
			PayToMethodDefault,
		
			UIOptionMapCurrency,
			CurrencyDefault,
		
			UIOptionMapContract,
			ContractDefault,
			
			ReceiptNumberDefault,
			
			ReceiptDateDefault,
			IssueDateDefault,
			DueDateDefault,
			TaxableDateDefault,
			PaidDateDefault,
			
			ConstantSymbolDefault,
			SpecificSymbolDefault,
			NoteDefault,
			
			UIOptionMapOutinvoiceType,
			OutinvoiceTypeDefault,
			
			UIOptionMapPreInvoice,
			PreInvoiceDefault,
			
			UIOptionMapPostInvoice,
			PostInvoiceDefault,
			
			UIOptionMapAdditionalText,
			AdditionalTextDefault,
		
			ExcVATDefault,
			VATDefault,
			IncVATDefault,
			
			ContractNumberModelOptionMap,
			OutinvoiceModelOptionMap,
			OutinvoicePrepareJSONValue,
			AdditionalTextModelOptionMap
		);
		
		this.#InitButton(
			Locale,
			
			UIOptionMapDescription,
			DescriptionDefault,
			
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		this.#InitItems(
			Locale,
			
			IsProductDefault,
			
			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
			
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		//Buttons
		
		this.#ButtonCreateAndResetInit(
			Locale
		);
		
	}
	
	#InitMain( 
		Locale,
		
		GTSUIOutinvoiceNumberValue,
		GTSUIOutinvoiceNumberValuePre,
		
		UIOptionMapCompany,
		CompanyDefault,
	
		UIOptionMapPaymentMethod,
		PaymentMethodDefault,
		
		UIOptionMapPayTo,
		PayToMethodDefault,
	
		UIOptionMapCurrency,
		CurrencyDefault,
		
		UIOptionMapContract,
		ContractDefault,
		
		ReceiptNumberDefault,
		
		ReceiptDateDefault,
		IssueDateDefault,
		DueDateDefault,
		TaxableDateDefault,
		PaidDateDefault,
		
		ConstantSymbolDefault,
		SpecificSymbolDefault,
		NoteDefault,
		
		UIOptionMapOutinvoiceType,
		OutinvoiceTypeDefault,
		
		UIOptionMapPreInvoice,
		PreInvoiceDefault,
		
		UIOptionMapPostInvoice,
		PostInvoiceDefault,
		
		UIOptionMapAdditionalText,
		AdditionalTextDefault,
	
		ExcVATDefault,
		VATDefault,
		IncVATDefault,
		
		ContractNumberModelOptionMap,
		OutinvoiceModelOptionMap,
		OutinvoicePrepareJSONValue,
		AdditionalTextModelOptionMap
	){
		
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
				
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm = new GTSUIModelOutinvoiceFormAddMainT(
			Locale,
		
			GTSUIOutinvoiceNumberValue,
			GTSUIOutinvoiceNumberValuePre,
			
			UIOptionMapCompany,
			CompanyDefault,
		
			UIOptionMapPaymentMethod,
			PaymentMethodDefault,
			
			UIOptionMapPayTo,
			PayToMethodDefault,
		
			UIOptionMapCurrency,
			CurrencyDefault,
		
			UIOptionMapContract,
			ContractDefault,
			
			ReceiptNumberDefault,
			
			ReceiptDateDefault,
			IssueDateDefault,
			DueDateDefault,
			TaxableDateDefault,
			PaidDateDefault,
			
			ConstantSymbolDefault,
			SpecificSymbolDefault,
			NoteDefault,
			
			UIOptionMapOutinvoiceType,
			OutinvoiceTypeDefault,
			
			UIOptionMapPreInvoice,
			PreInvoiceDefault,
			
			UIOptionMapPostInvoice,
			PostInvoiceDefault,
			
			UIOptionMapAdditionalText,
			AdditionalTextDefault,
		
			ExcVATDefault,
			VATDefault,
			IncVATDefault,
			
			ContractNumberModelOptionMap,
			OutinvoiceModelOptionMap,
			OutinvoicePrepareJSONValue,
			AdditionalTextModelOptionMap
		);
		
		//this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.Update( );
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm 
		);
		
		//UIComponentLayoutElement.Update( );
		
		
		super.ElementSet( 0, UIComponentLayoutElement );
		
	}
	
	#InitButton(

	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"+",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "27px" )
						] )
					)
				] ),
				null
			)
		);
		
		//UIComponentLabel.Update( );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "40px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd.ChildSet( 0, UIComponentLabel );
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd 
		);
		
		super.ElementSet( 1, UIComponentLayoutElement );
		
	}
	
	#InitItems(
		Locale,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
		
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault
	){
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
						
						] )
					)
				
				] ),
			
				null //Id
				
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm = new GTSUIModelOutinvoiceFormAddItemFormT(
			Locale,

			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
			
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault
		);
		
		//this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Update( );
		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm 
		);
		
		//UIComponentLayoutElement.Update( );
		
		
		super.ElementSet( 2, UIComponentLayoutElement );
		
		
	}
	
	#ButtonCreateAndResetInit(
		Locale
	){
		
		//Create
		
		let UIComponentLayoutElement = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] ),
			
				null //Id
			)
		);
		
		let UIComponentLabel = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				this.#T.Translate( "Save" ),
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "20px" )
						] )
					)
				] )
			)
		);
		
		//UIComponentLabel.Update( );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "green" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightgreen" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkgreen" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		//Reset
		
		let UIComponentLayoutElementReset = new UIComponentLayoutElementT(
			new UIElementT( ),
			new UIComponentLayoutElementConfigT(
				"0",
				"0",
				"auto",
				"auto",
				
				new UIStateMapT( [ 
				
					new UIStateT(
						"Default",
						"UIComponentLayoutElementT", //ClassName
						new UIStyleMapT( [
							new UIStyleT( "margin", "10px" )
						] )
					)
					
				] )
			)
		);
		
		let UIComponentLabelReset = new UIComponentLabelT(
			new UIElementT( ),
			new UIComponentLabelConfigT(
				"Reset",
				new UIStateMapT( [
					new UIStateT(
						"Default",
						"UIComponentLabelT",
						new UIStyleMapT( [
							new UIStyleT( "color", "white" ),
							new UIStyleT( "text-align", "center" ),
							new UIStyleT( "font-size", "20px" )
						] )
					)
				] )
			)
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonReset = new UIComponentButtonT(
			new UIElementT( ),
			new UIComponentButtonConfigT( 
				true,
				new UIStateMapT( [ 
				
					new UIStateT( 
						"Active",
						"UIComponentButtonT Active",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "blue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Inactive",
						"UIComponentButtonT Inctive",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "grey" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "default" )
						] )
					),
					
					new UIStateT( 
						"Hovered",
						"UIComponentButtonT Hovered",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "lightblue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					),
					
					new UIStateT( 
						"Pressed",
						"UIComponentButtonT Pressed",
						new UIStyleMapT( [
							new UIStyleT( "background-color", "darkblue" ),
							new UIStyleT( "width", "80px" ),
							new UIStyleT( "height", "40px" ),
							new UIStyleT( "line-height", "40px" ),
							new UIStyleT( "border-radius", "4px" ),
							new UIStyleT( "cursor", "pointer" )
						] )
					)
					
				] )
			)
		);
		
		//Layout
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate.ChildSet( 0, UIComponentLabel );
		

		
		UIComponentLayoutElement.DataSet( 
			this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate 
		);
		
		super.ElementSet( 3, UIComponentLayoutElement );
		
	}
	
	Start(
	
	){
		
		this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.Start( );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd.Start( );
	
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Start( );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate.Start( );
		
		
		super.Start( );
	
	}
	
	SetProduct( Index, Type, Code ){
		
		let Item = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.LineGet( Index );
		
		Item.ProductSet( Type, Code );
		
	}
	
	#OnMainChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnMainChange", Values, IsValid );
		
		
		let IsValidItems = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.IsValidTotalGet( );
		
		let ValuesItems = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.ValueGet( );
		
		
		if( super.EventExists( "Change" ) === true ){
			
			let IsValid = this.IsValidTotalGet( );
			
			let Value = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Change" );
			
			
			let IsValidTotal = IsValid;
			
			if( IsValidItems === false ){
				
				IsValidTotal = false;
				
			}
			
			EventFn( 
				{
					"main": Values,
					"items": ValuesItems
				},
				IsValidTotal
			);
			
		}
		
	}
	
	#OnItemsChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnItemsChange", Values, IsValid );
		
		
		let IsValidMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IsValidTotalGet( );
		
		let ValuesMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ValueGet( );
		
		
		let VATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( );
		
		let VATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.VATTotal( );
		
		VATMain.ValueSet( VATValue, false );
		
		
		let ExcVATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ExcVATGet( );
		
		let ExcVATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.ExcVATTotal( );
		
		ExcVATMain.ValueSet( ExcVATValue, false );
		
		
		let IncVATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IncVATGet( );
		
		let IncVATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.IncVATTotal( );
		
		IncVATMain.ValueSet( IncVATValue, false );
		
		
		
		if( super.EventExists( "Change" ) === true ){
		
			let EventFn = super.EventGet( "Change" );
			
			let IsValidTotal = IsValid;
			
			if( IsValidMain === false ){
				
				IsValidTotal = false;
				
			}
			
			EventFn( 
				{
					"main": ValuesMain,
					"items": Values
				},
				IsValidTotal
			);
		
		}

		
	}
	
	ItemFormReset(

	){
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Clear( );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.LineAdd(

		);
		
	}
	
	Clear( ){
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Clear( );
		
	}
	
	LineAdd( ){
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.LineAdd(

		);
		
	}
	
	/*Listen( 
		OnChangeFn
	){
		
		super.EventSet( "Change", OnChangeFn );
		
		this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.Listen(
			this.#OnMainChange.bind( this )
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Listen( 
			this.#OnItemsChange.bind( this )
		);
		
	}*/
	
	#OnOutinvoiceFormTransferredVATChange(
		Value
	){
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.TransferredVATToggle( Value );
		
		if( Value === false ){
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( ).ValueSet( this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.VATTotal( ) );
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IncVATGet( ).ValueSet( this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ExcVATGet( ).ValueGet( ) + this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.VATTotal( ) );
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( ).StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "block" ) ] ) );
			
		} else {
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( ).ValueSet( 0, false );
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IncVATGet( ).ValueSet( this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ExcVATGet( ).ValueGet( ) );
			
			this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( ).StyleMapSet( new UIStyleMapT( [ new UIStyleT( "display", "none" ) ] ) );
			
		}
		
	}
	
	#OnItemButtonAddClick(  ){
				
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnItemButtonAddClick" );
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.LineAdd(
			false,
			null
		);
				
	}
	
	#OnButtonCreateClick(
	
	){
		
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnButtonCreateClick" );
		
		
		if( super.EventExists( "Accept" ) === true ){
			
			let IsValidTotal = this.IsValidTotalGet( );
			
			let ValuesTotal = this.ValueGet( ); 
			
			let EventFn = super.EventGet( "Accept" );
			
			EventFn( 
				ValuesTotal,
				IsValidTotal
			);
			
		}
		
	}
	
	#OnChange(
		Values,
		IsValid
	){
		
		console.log( 
			"GTSUIModelOutinvoiceFormAddT.#OnChange", 
			Values, 
			IsValid 
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate.ActiveSet( this.IsValidTotalGet( ) );
		
	}
	
	#OnOutinvoiceFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnOutinvoiceFormChange", Values, IsValid );
		
		this.#OnChange( 
			Values, 
			IsValid
		);
		
	}
	
	#OnItemFormChange(
		Values,
		IsValid
	){
		
		console.log( "GTSUIModelOutinvoiceFormAddT.#OnItemFormChange", Values, IsValid );
		
		
		let IsValidMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IsValidTotalGet( );
		
		let ValuesMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ValueGet( );
		
		
		let VATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.VATGet( );
		
		let VATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.VATTotal( );
		
		VATMain.ValueSet( VATValue, false );
		
		
		let ExcVATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ExcVATGet( );
		
		let ExcVATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.ExcVATTotal( );
		
		ExcVATMain.ValueSet( ExcVATValue, false );
		
		
		let IncVATMain = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IncVATGet( );
		
		let IncVATValue = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.IncVATTotal( );
		
		IncVATMain.ValueSet( IncVATValue, false );
		
		
		
		this.#OnChange( 
			Values, 
			IsValid
		);

		
	}
	
	Listen( 
		OnAcceptFn,
		OnClearFn
	){
		
		super.EventSet( "Accept", OnAcceptFn );
		
		super.EventSet( "Clear", OnClearFn );
		
		
		this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.Listen(
			this.#OnOutinvoiceFormTransferredVATChange.bind( this ),
			this.#OnOutinvoiceFormChange.bind( this )
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemButtonAdd.Listen(
			this.#OnItemButtonAddClick.bind( this )
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.Listen( 
			this.#OnItemFormChange.bind( this )
		);
		
		this.#GTSUIModelOutinvoiceFormAddSecondaryButtonCreate.Listen(
			this.#OnButtonCreateClick.bind( this )
		);
		
	}
	
	IsValidTotalGet(
	
	){
		
		let IsValid = true;
		
		if( this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IsValidTotalGet( ) === false ){
			
			IsValid = false;
			
		}
		
		if( this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.IsValidTotalGet( ) === false ){
			
			IsValid = false;
			
		}
		
		
		return IsValid;
		
	}
	
	IsValidGet( 
	
	){
		
		let JSONData = { };
		
		JSONData[ "main" ] = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.IsValidGet( );
		
		JSONData[ "items" ] = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.IsValidGet( );
		
		return JSONData;
		
	}
	
	ValueGet(
	
	){
		
		let JSONData = { };
		
		JSONData[ "outinvoice" ] = this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.ValueGet( );
		
		JSONData[ "items" ] = this.#GTSUIModelOutinvoiceFormAddSecondaryItemForm.ValueGet( );
		
		return JSONData;
		
	}
	
	OutinvoicePrepareJSONValueSet(
		PrepareJSONValue
	){
		
		this.#GTSUIModelOutinvoiceFormAddPrimaryOutinvoiceForm.PrepareJSONValueSet( PrepareJSONValue );
		
	}
	
	Render( 
	
	){
		
		return super.Render( );
		
	}
	
	
};


export default GTSUIModelOutinvoiceFormAddT;