import React from 'react'
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
//import Autocompletemy from '../google_autocomplete';
import Geocode from "react-geocode";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
Geocode.setApiKey("AIzaSyApcfdTTsrNPaQcWWo3T9iLWbUervbxpKE");
Geocode.enableDebug();

interface Props {
    onSelect: (data) => void;
}

class Map extends React.Component{
    constructor( props ){
        super( props );
        this.state = {
            search_string:'',
            containerHeight:'40px',
            mapHeight:'0%',
            country_id:'',
            country_code:'',
            address: '',
            city: '',
            area: '',
            state: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            }
        }


    }
    /**
     * Get the current address from the default map position and set those values in the state
     */
    componentDidMount() {


        Geocode.fromLatLng( this.state.mapPosition.lat , this.state.mapPosition.lng ).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray =  response.results[0].address_components,
                    city = this.getCity( addressArray ),
                    area = this.getArea( addressArray ),
                    state = this.getState( addressArray );







                this.setState( {
                    address: ( address ) ? address : '',
                    area: ( area ) ? area : '',
                    city: ( city ) ? city : '',
                    state: ( state ) ? state : '',
                } )
            },
            error => {
                console.error(error);
            }
        );
    };

    componentDidUpdate(prevProps) {

        if (
            prevProps.center.lat !== this.props.center.lat ||
            prevProps.center.search_string !== this.props.center.search_string
        ) {


            this.setState({
                mapPosition: {
                    lat: this.props.center.lat,
                    lng: this.props.center.lng
                },
                markerPosition: {
                    lat: this.props.center.lat,
                    lng: this.props.center.lng
                },
                address: this.props.search_string
            });
        }
    }


    /**
     * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
     *
     * @param nextProps
     * @param nextState
     * @return {boolean}
     */
    shouldComponentUpdate( nextProps, nextState ){

        if (
            nextProps.country_id !== this.props.country_id ||
            nextProps.show_map_container !== this.props.show_map_container ||

            this.state.markerPosition.lat !== nextState.markerPosition.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state
        ) {

            return true
        }
        else if ( this.props.center.lat !== nextProps.center.lat ){


            return true;
        }

        else if ( this.props.center.lat === nextProps.center.lat ){
            return false
        }
    }
    /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getCity = ( addressArray ) => {
        let city = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0] && ('postal_town' === addressArray[ i ].types[0] ||  'administrative_area_level_2' === addressArray[ i ].types[0] ||  'locality' === addressArray[ i ].types[0])) {
                city = addressArray[ i ].long_name;
                return city;
            }
        }
    };
    /**
     * Get the area and set the area input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getArea = ( addressArray ) => {
        let area = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            if ( addressArray[ i ].types[0]  ) {
                for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                    if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
                        area = addressArray[ i ].long_name;
                        return area;
                    }
                }
            }
        }
    };
    /**
     * Get the address and set the address input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
    getState = ( addressArray ) => {
        let state = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
                    state = addressArray[ i ].long_name;
                    return state;
                }
            }
        }
    };

    getHouse = ( addressArray ) => {
        let house = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'street_number' === addressArray[ i ].types[0] ) {
                    house = addressArray[ i ].long_name;
                    return house;
                }
            }
        }
    };

    getStreet = ( addressArray ) => {
        let street = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'route' === addressArray[ i ].types[0] ) {
                    street = addressArray[ i ].long_name;
                    return street;
                }
            }
        }
    };

    getZip = ( addressArray ) => {
        let zip = '';
        for( let i = 0; i < addressArray.length; i++ ) {
            for( let i = 0; i < addressArray.length; i++ ) {
                if ( addressArray[ i ].types[0] && 'postal_code' === addressArray[ i ].types[0] ) {
                    zip = addressArray[ i ].long_name;
                    return zip;
                }
            }
        }
    };
    /**
     * And function for city,state and address input
     * @param event
     */
    onChange = ( event ) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * This Event triggers when the marker window is closed
     *
     * @param event
     */
    onInfoWindowClose = ( event ) => {
    };
    /**
     * When the user types an address in the search box
     * @param place
     */
    onPlaceSelected = ( place ) => {
        console.log(place);
        if (typeof place.address_components !== 'undefined') {

            var address = place.formatted_address ? place.formatted_address : '',
                addressArray = place.address_components,
                name = place.name,
                city = this.getCity(addressArray),
                area = this.getArea(addressArray),
                house = this.getHouse(addressArray),
                street = this.getStreet(addressArray),
                zip = this.getZip(addressArray),
                state = this.getState(addressArray),
                latValue = place.geometry.location.lat(),
                lngValue = place.geometry.location.lng();
// Set these values in the state.
            address = ( address ) ? address : '';
            name = ( name ) ? name : '';
            city = ( city ) ? city : '';
            area = ( area ) ? area : '';
            house = ( house ) ? house : '';
            street = ( street ) ? street : '';
            zip = ( zip ) ? zip : '';
            state = ( state ) ? state : '';
            latValue = ( latValue ) ? latValue : '';
            lngValue = ( lngValue ) ? lngValue : '';

            this.props.onSelect({ address, city, area, house, street, zip, state, latValue, lngValue, name});


            this.setState({

                address: ( address ) ? address : '',
                area: ( area ) ? area : '',
                city: ( city ) ? city : '',
                state: ( state ) ? state : '',
                markerPosition: {
                    lat: latValue,
                    lng: lngValue
                },
                mapPosition: {
                    lat: latValue,
                    lng: lngValue
                },
            });

        }
    };
    /**
     * When the marker is dragged you get the lat and long using the functions available from event object.
     * Use geocode to get the address, city, area and state from the lat and lng positions.
     * And then set those values in the state.
     *
     * @param event
     */
    onMarkerDragEnd = ( event ) => {

        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng(),
            addressArray = [];
        Geocode.fromLatLng( newLat , newLng ).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray =  response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    house = this.getHouse(addressArray),
                    street = this.getStreet(addressArray),
                    zip = this.getZip(addressArray),
                    latValue = newLat,
                    lngValue = newLng,
                    state = this.getState(addressArray);
                this.props.onSelect({ address, city, area, house, street, zip, state, latValue, lngValue});

                this.setState( {
                    address: ( address ) ? address : '',
                    area: ( area ) ? area : '',
                    city: ( city ) ? city : '',
                    state: ( state ) ? state : '',
                    markerPosition:{lat:newLat, lng:newLng}
                } )
            },
            error => {
                console.error(error);
            }
        );
    };
    render(){

var containerHeight=this.props.height;
var mapHeight='100%';

        if (this.props.show_map_container===false) {

             containerHeight='40px';
             mapHeight='0%';
        }
var search_string=this.props.t('Search address');
if (this.props.search_string!='') search_string=this.props.search_string;
let country_code=JSON.parse(localStorage.getItem('countries_'+this.props.country_id)).code2;


        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <div>

                        <GoogleMap google={this.props.google}
                                   defaultZoom={this.props.zoom}
                                   defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                        >
                            <Autocomplete

                                placeholder={search_string}
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    paddingLeft: '16px',
                                    marginTop: '2px',
                                    marginBottom: this.props.height
                                }}
                                types="error_for_multiple_types"
                                componentRestrictions={{ country: country_code }}
                                fields={['ALL']}
                                onPlaceSelected={ this.onPlaceSelected }
                                onKeyPress={(event)=>{
                                    if(event.key === 'Enter') {
                                        event.preventDefault();

                                        return false;
                                    }
                                }}

                            />

                            {/* For Auto complete Search Box */}

                            {/*Marker*/}
                            <Marker google={this.props.google}
                                    name={'Dolores park'}
                                    draggable={true}
                                    onDragEnd={ this.onMarkerDragEnd }
                                    position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                            />
                            <Marker />
                            {/* InfoWindow on top of marker */}
                            <InfoWindow
                                onClose={this.onInfoWindowClose}
                                position={{ lat: ( this.state.markerPosition.lat + 0.0018 ), lng: this.state.markerPosition.lng }}
                            >
                                <div>
                                    <span style={{ padding: 0, margin: 0 }}>{ this.state.address }</span>
                                </div>
                            </InfoWindow>
                        </GoogleMap>
                    </div>
                )
            )
        );
        let map;
        if( this.props.center.lat !== undefined ) {

            var locale = 'cs';
            if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
            let mapurl="https://maps.googleapis.com/maps/api/js?language="+locale+"&key=AIzaSyApcfdTTsrNPaQcWWo3T9iLWbUervbxpKE&libraries=places";

            map = <div>
                <AsyncMap
                    googleMapURL={mapurl}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: containerHeight }} />
                    }
                    mapElement={
                        <div style={{ height: mapHeight }} />
                    }
                />


            </div>
        } else {
            map = <div style={{height: this.props.height}} />
        }
        return( map )
    }
}

Map.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Map);