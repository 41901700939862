"use strict";


import APIModelInsertResponseAbstractT from "./_/APIModelInsertResponseAbstractT.mjs";

import APIFieldValueMapT from "../../../FieldValue/APIFieldValueMapT.mjs";

import APIModelNameT from "../../Name/APIModelNameT.mjs";

import APIModelInsertResponseChildrenListT from "./Children/APIModelInsertResponseChildrenListT.mjs";


const APIModelInsertResponseT = class APIModelInsertResponseT extends APIModelInsertResponseAbstractT {
	
	#ModelName = null;
	
	#Data = null;
	
	#ChildrenList = null;
	
	constructor(
		ModelName,
		
		Data,
		
		ChildrenList
	){
		
		if( ( ModelName instanceof APIModelNameT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( Data instanceof APIFieldValueMapT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		if( ( ChildrenList instanceof APIModelInsertResponseChildrenListT ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		
		super( );
		
		
		this.#ModelName = ModelName;
		
		this.#Data = Data;
		
		this.#ChildrenList = ChildrenList;
		
	}
	
	ModelName( 
	
	){
		
		return this.#ModelName;
		
	}
	
	Data(
	
	){
		
		return this.#Data;
		
	}
	
	ChildrenList(
	
	){
		
		return this.#ChildrenList;
		
	}
	
	static FromJSON(
		JSONData
	){
		
		let APIModelKeyList = [ ];
		
		let APIModelInsertResponseList = [ ];
		
		let JSONChildrenList = JSONData[ "ChildrenList" ];
		
		for(
			let I = 0;
			I < JSONChildrenList.length;
			I++
		){
			
			APIModelInsertResponseList.push(
				APIModelInsertResponseT.FromJSON(
					JSONChildrenList[ I ]
				)
			);
			
		}
		
		let ChildrenList = new APIModelInsertResponseChildrenListT(
			APIModelInsertResponseList
		);

		
		return new APIModelInsertResponseT(
			APIModelNameT.FromJSON( JSONData[ "ModelName" ] ),
			APIFieldValueMapT.FromJSON( JSONData[ "Data" ] ),
			ChildrenList
		);
		
	}
	
	ToJSON(
	
	){
	
		let JSONData = {
			
		};
		
		let ChildrenList = [
			
		];
		
		for(
			let I = 0;
			I < this.#ChildrenList.Count( );
			I++
		){
			
			ChildrenList.push( ( this.#ChildrenList.Get( I ) ).ToJSON( ) );
			
		}
		
		JSONData[ "ModelName" ] = this.#ModelName.ToJSON( );
		
		JSONData[ "Data" ] = this.#Data.ToJSON( );
		
		JSONData[ "ChildrenList" ] = ChildrenList;
		
		return JSONData;
	
	}
	
};


export default APIModelInsertResponseT;