import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Map from "../map";
import {isNullOrUndefined} from "util";




interface Props {
    onAdd: (data) => void;
}

class Address extends Component<Props> {


    constructor(props) {
        super(props)

        this.state = {

            rownode: "",
            address_model_name: "",
            id: "",
            state: "",
            city: "",
            zip: "",
            street: "",
            house: "",
            address: "",
            lattitude:"",
            longitude:"",
            allow_submit: false,

        };

    }
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {

if (this.props.type==='address') {
    this.setState({
        rownode: this.props.rownode,
        address_model_name: this.props.address_model_name,
        id: this.props.id,
        state: (this.props.rownode.data.state) ? this.props.rownode.data.state : '',
        city: (this.props.rownode.data.city) ? this.props.rownode.data.city : '',
        zip: (this.props.rownode.data.zip) ? this.props.rownode.data.zip : '',
        street: (this.props.rownode.data.street) ? this.props.rownode.data.street : '',
        house: (this.props.rownode.data.house) ? this.props.rownode.data.house : '',
        address: (this.props.rownode.data.address) ? this.props.rownode.data.address : '',
        lattitude: (this.props.rownode.data.lattitude) ? this.props.rownode.data.lattitude : '',
        longitude: (this.props.rownode.data.longitude) ? this.props.rownode.data.longitude : '',

        allow_submit: false
    });
}

            if (this.props.type==='loading_address') {
                this.setState({
                    rownode: this.props.rownode,
                    address_model_name: this.props.address_model_name,
                    id: this.props.id,
                    state: '',
                    city: '',
                    zip:  '',
                    street: '',
                    house: '',
                    address: (this.props.rownode.data.loading_address) ? this.props.rownode.data.loading_address : '',
                    lattitude: (this.props.rownode.data.loading_lat) ? this.props.rownode.data.loading_lat : '',
                    longitude: (this.props.rownode.data.loading_lng) ? this.props.rownode.data.loading_lng : '',

                    allow_submit: false
                });
                console.log(this.state);
            }


        }
    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { rownode, address_model_name, id, state, city, zip, street, house, address, lattitude, longitude } = this.state;
        const loading_address=address;
        const loading_lat=lattitude;
        const loading_lng=longitude;
        const address_type=this.props.type;
        if (this.props.type==='address') this.props.onAdd({ rownode, address_type, address_model_name, id, state, city, zip, street, house, address, lattitude, longitude });
        if (this.props.type==='loading_address') this.props.onAdd({ rownode, address_type, address_model_name, id, loading_address, loading_lat, loading_lng });
    }

    handleAddressSelect(data) {

        var allowed_state=[];
        var allowed_city=[];
        var allowed_zip=[];
        JSON.parse(localStorage.getItem('my_users_meta')).map((e)=>{
            if (e.name=='companies/columns-state') allowed_state=e.value.split('//');
            if (e.name=='companies/columns-city') allowed_city=e.value.split('//');
            if (e.name=='companies/columns-zip') allowed_zip=e.value.split('//');

        });
        if (
            typeof data.address!=='undefined' && data.address!=null && data.address!='' &&
            typeof data.latValue!=='undefined' && data.latValue!=null && data.latValue!='' &&
            typeof data.lngValue!=='undefined' && data.lngValue!=null && data.v!=''

        ) {
            if (
                (data.state==='' || allowed_state.includes(data.state) || allowed_state.length==0 || (allowed_state.length==1 && allowed_state[0]=='')) &&
                (data.city==='' || allowed_city.includes(data.city) || allowed_city.length==0 || (allowed_city.length==1 && allowed_city[0]=='')) &&
                (typeof data.zip === "undefined" || data.zip==='' || allowed_zip.includes(data.zip) || allowed_zip.length==0 || (allowed_zip.length==1 && allowed_zip[0]==''))

            ) {
                console.log(data);
                this.setState({
                    address: data.address,
                    state: data.state,
                    city: data.city,
                    street: data.street,
                    house: data.house,
                    zip: data.zip,
                    lattitude: data.latValue,
                    longitude: data.lngValue,
                });

                var editable = JSON.parse(localStorage.getItem('my_roles_meta')).find(refData => refData['name'] == 'companies-editable').value.split('//');

                if (editable.includes('address'))  this.setState({
                    allow_submit: true
                });

            } else {
                alert(this.props.t("You don't have permissions for this address: ")+data.address);
            }
        }
    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');






        const { state, city, zip, street, house, address, lattitude, longitude } = this.state;

        let country_id=1;
        let lat_center=50.079936;
        let lng_center=14.4275543;
        if (lattitude!==undefined && lattitude!==null && lattitude!=='') lat_center=parseFloat(lattitude);
        if (longitude!==undefined && longitude!==null && longitude!=='') lng_center=parseFloat(longitude);


        if (this.props.rownode!==undefined && this.props.rownode!==null && this.props.rownode.data!==undefined) {
            country_id=this.props.rownode.data.country;

        }
        return (
            <div style={{'height':'550px', 'overflow':'hidden'}}>
                <form onSubmit={(event) => this.handleFormSubmit(event)}>

                    <div className="container">

                        <div className="row">
                            <div className="col-12">

                                <Map
                                    search_string={this.state.address}
                                    google={this.props.google}
                                    center={{lat: lat_center, lng: lng_center}}
                                    country_id={country_id}
                                    show_map_container={'true'}
                                    onSelect={(data) => this.handleAddressSelect(data)}
                                    height='260px'
                                    zoom={15}
                                />



                            </div>

                        </div>



                        <div className="row" style={{paddingTop:"45px"}}>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Address')}</label>
                                    <input
                                        type="text"
                                        required
                                        readOnly="readOnly"
                                        className="form-control"
                                        value={address}

                                        onChange={(event) =>
                                            this.setState({ address: event.target.value })
                                        }
                                    />
                                </div>
                            </div>


                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('State')}</label>
                                    <input
                                        type="text"

                                        readOnly="readOnly"
                                        className="form-control"
                                        value={state}

                                        onChange={(event) =>
                                            this.setState({state: event.target.value})
                                        }
                                    />
                                </div>
                            </div>


                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('City')}</label>
                                    <input
                                        type="text"

                                        readOnly="readOnly"
                                        className="form-control"
                                        value={city}

                                        onChange={(event) =>
                                            this.setState({city: event.target.value})
                                        }
                                    />
                                </div>
                            </div>








                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('ZIP')}</label>
                                    <input
                                        type="text"
                                        readOnly="readOnly"
                                        className="form-control"
                                        value={zip}

                                        onChange={(event) =>
                                            this.setState({zip: event.target.value})
                                        }
                                    />
                                </div>
                            </div>




                        </div>


                        <div className="row">

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Street')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={street}
                                        readOnly="readOnly"

                                        onChange={(event) =>
                                            this.setState({street: event.target.value})
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('House nr.')}</label>
                                    <input
                                        type="text"

                                        readOnly="readOnly"
                                        className="form-control"
                                        value={house}

                                        onChange={(event) =>
                                            this.setState({house: event.target.value})
                                        }
                                    />
                                </div>
                            </div>


                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Lattitude')}</label>
                                    <input
                                        type="text"
                                        required
                                        readOnly="readOnly"
                                        className="form-control"
                                        value={lattitude}

                                        onChange={(event) =>
                                            this.setState({lattitude: event.target.value})
                                        }
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Longitude')}</label>
                                    <input
                                        type="text"
                                        required
                                        readOnly="readOnly"
                                        className="form-control"
                                        value={longitude}

                                        onChange={(event) =>
                                            this.setState({longitude: event.target.value})
                                        }
                                    />
                                </div>
                            </div>


                        </div>


                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="form-group">
                                <button className="btn btn-success" disabled={this.state.allow_submit ? false : true}>{this.props.t('Save')}</button>
                            </div></div></div>
                </form>
            </div>
        );
    }
}
Address.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Address);

