"use strict";


const APIModelSchemaResponseAbstractT = class APIModelSchemaResponseAbstractT {
	
	constructor(
		
	){
		
	}
	
	ElementMap(
	
	){
		
		throw new Error( "Abstract" );
	
	}
	
	ModelOptionMap(
	
	){
		
		throw new Error( "Abstract" );
	
	}
	
	CustomOptionMap(
	
	){
		
		throw new Error( "Abstract" );
	
	}
	
	ChildrenMap(
	
	){
		
		throw new Error( "Abstract" );
	
	}
	
};


export default APIModelSchemaResponseAbstractT;