import React, { Component } from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import FileRenderer from "../../aggrid/FileRenderer.jsx";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import $ from 'jquery';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import {LoadState, SaveState} from "../../services/GridStateService";
class Wiretransfers extends Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },

            modules: AllCommunityModules,
            localeText: loctext,

            rowdata: null,
            columnDefs: [

                {
                    headerName: this.props.t('Total amount'),
                    field: 'total_amount',
                    cellRenderer: "FileRenderer",

        cellRendererParams:{
            type: 'wiretransfer'
        }
                },


                {
                    headerName: this.props.t('Created at'),
                    field: 'created_at',
                    filter: 'agDateColumnFilter',


                    comparator: function(date1, date2) {

                    function _monthToNum(date) {

                        if (date === undefined || date === null || date === '') {
                            return null;
                        }

                        var date_arr1=date.split(' ');
                        var date_arr=date_arr1[0].split('.');

                        var yearNumber = parseInt(date_arr[2]);
                        var monthNumber = parseInt(date_arr[1]);
                        var dayNumber = parseInt(date_arr[0]);

                        var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
                        // 29/08/2004 => 20040829
                        //console.log(result);
                        return result;
                    }



                    var date1Number = _monthToNum(date1);
                    var date2Number = _monthToNum(date2);



                    if (date1Number === null && date2Number === null) {
                        return 0;
                    }
                    if (date1Number === null) {
                        return -1;
                    }
                    if (date2Number === null) {
                        return 1;
                    }

                    return date1Number - date2Number;
                },

               filterParams: {
                    // provide comparator function
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        const dateAsString = cellValue;

                        if (dateAsString == null || dateAsString=='') {
                            return -1;
                        }

                        // In the example application, dates are stored as dd/mm/yyyy
                        // We create a Date object for comparison against the filter date
                        const parts=dateAsString.split(' ');
                        const dateParts = parts[0].split('.');
                        const day = Number(dateParts[0]);
                        const month = Number(dateParts[1]) - 1;
                        const year = Number(dateParts[2]);
                        const timeParts=parts[1].split(":");
                        const hour=timeParts[0];
                        const minute=timeParts[1];

                        const cellDate = new Date(year, month, day);

                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        }
                        return 0;
                    }
                },
                    sort:'desc',
                    valueGetter:function(params) {
                        function addZero(i) {
                            if (i < 10) {
                                i = "0" + i;
                            }
                            return i;
                        }
                        if (params.data.created_at===null || params.data.created_at===0 || params.data.created_at==='') return '';
                        var  date = new Date(params.data.created_at);
                        var month=date.getMonth();
                        var year=date.getFullYear();
                        var day=date.getDate();
                        var hour=addZero(date.getHours());
                        var min=addZero(date.getMinutes());
                        month++;
                        return day+'.'+month+'.'+year+' '+hour+':'+min;

                    },


                },

                {
                    headerName: this.props.t('Payment type'),
                    field: 'payment_type',
                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
                        var values= JSON.parse(JSON.parse(localStorage.getItem('columns_159')).data);
                        if (params.data.payment_type === null || params.data.payment_type === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.payment_type))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

                {
                    headerName: this.props.t('Currency'),
                    field: 'currency',
                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

                        var values= JSON.parse(JSON.parse(localStorage.getItem('columns_108')).data);
                        if (params.data.currency === null || params.data.currency === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.currency))['name_' + locale];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },

                {
                    headerName: this.props.t('Paid from'),
                    field: 'pay_from',
                    valueGetter: function (params) {
                        var locale = 'cs';
                        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
                        var values= JSON.parse(localStorage.getItem('bank_accounts'));
                        if (params.data.pay_from === null || params.data.pay_from === '') return '';
                        try {
                            return values.find(refData => String(refData.id) === String(params.data.pay_from))['account_name'];
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    }
                },




                {
                    headerName: this.props.t('Transfers count'),
                    field: 'transfers_count',
                },

                {
                    headerName: this.props.t('Companies'),
                    field: 'companies',
                },

                {
                    headerName: this.props.t('Note'),
                    field: 'note',
                    editable: true
                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth:150,
                editable: false,
                resizable: true,
                sortable: true,

            },
            frameworkComponents: {
                DeleteRenderer: DeleteRenderer,
                FileRenderer:FileRenderer,
            }
        };
    }


    onCellCValueChanged = (event) => {
        event.data.field=event.colDef.field;
        HttpService.put(UrlService.apiDomain()+'api/wiretransfers/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');

                event.node.setData(res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/wiretransfers')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });

                setTimeout(() => {
                    LoadState('WireTransfers', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('invoices.pay_history_delete')) {
            items.push(  {
                headerName: this.props.t('Delete'),
                cellRenderer: "DeleteRenderer",

                cellRendererParams: {
                    gridType: "wiretransfers",
                },
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        LoadState('WireTransfers', params.columnApi, params.api);
    };

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('WireTransfers', params.columnApi, params.api);
        }
    }

    getRowNodeId=(data) => {
        return data.id;
    };

    render() {

        return (
            <div className="wrapper">

                <Layout>
                    <Content title={this.props.t('Wire transfers')}>

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight':'9px'}}>

                            </div>
                        </div>

                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">


                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}

                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                                onCellValueChanged={this.onCellCValueChanged}
                                getRowNodeId = {this.getRowNodeId}

                            />
                        </div>

                    </Content>
                </Layout>
            </div>
        );
    }
}


Wiretransfers.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Wiretransfers);

