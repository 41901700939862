import { ColDef } from "ag-grid-community";


import InputSelectBaseParser from "../../inputs/Select/Base/InputSelectBaseParser";
import InputSelectBaseSerializer from "../../inputs/Select/Base/InputSelectBaseSerializer";

import Input from "../../inputs/Input";


const ColumnSingleSelectBase = class ColumnSingleSelectBase implements ColDef {

	headerName = null;
		
	field = null;
		
	options = null;
		
	editable = false;
	
	filter = "agSetColumnFilter";
			
	cellEditor = 'ComponentSelectBase';
	
	cellEditorPopup = true;
		
	cellEditorPopupPosition = 'under';
	
	
	comparator = function( valueA, valueB ) {
		
		console.log( valueA, valueB );
		
		if( ( valueA === null ) && ( valueB === null ) ){
				
			return 0;
			
		}
		
		if( valueA === null ){
				
			return -1;
			
		}
		
		if( valueB === null ){
			
			return 1;
			
		}
		
		return valueA.label.toLowerCase( ).localeCompare( valueB.label.toLowerCase( ) );
		
	};
	
	filterValueGetter = function( params ) {
		
		console.log( "filterValueGetter", params );
		
		let Value = InputSelectBaseParser( params.data[ this.field ], this.options );
		
		return Value.label;
		
	}.bind( this );
	
	filterParams = {
		
		/*valueFormatter: function( params ){
			
			console.log( params.column.colDef, params.column.colDef.valueFormatter( params ) );
			
			
			return params.column.colDef.valueFormatter( params );
			
		}*/
		
		comparator: function( valueA, valueB ) {
		
			console.log( valueA, valueB );
			
			if( ( valueA === null ) && ( valueB === null ) ){
					
				return 0;
				
			}
			
			if( valueA === null ){
					
				return -1;
				
			}
			
			if( valueB === null ){
				
				return 1;
				
			}
			
			return valueA.toLowerCase( ).localeCompare( valueB.toLowerCase( ) );
			
		}
		
	};
	

	constructor(
		Name,
		Field,
		OptionsObject,
		Editable,
		ModelSchema,
		Meta,
		ValuesOut
	){
		
		this.headerName = Name;
		
		this.field = Field;
		
		this.options = OptionsObject;
		
		this.editable = Editable;
		
		
		console.log( Field );
		
		this.cellEditorParams = {
			field: this.field,
			disabled: !this.editable,
			options_object: this.options,
			options_filter: Meta.OptionsFilter,
			placeholder: Meta.Placeholder,
			values_out: ValuesOut
		};
		
		this.valueGetter = function( params ) {
		
			console.log( "valueGetter", this.field, params );
			
			return InputSelectBaseParser( params.data[ this.field ], this.options );
			
		}.bind( this );
			
		this.valueFormatter = function( params ){
			
			console.log( "valueFormatter", params );
			
			
			let Data = InputSelectBaseParser( params.data[ this.field ], this.options );
			
			let Str = null;
			
			if( Data === null ){
				
				Str = "";
				
			} else {
				
				Str = Data.label;
				
			}
			
			return Str;
			
		}.bind( this );
			
		this.valueParser = function( params ){
				
			console.log( "valueParser", params );
				
		}.bind( this );
			
		this.valueSetter = function( params ){
			
			console.log( "valueSetter", params );
			
			
			let IsValid = Input.Check( Field, Meta, params.newValue, ModelSchema, params.data );
			
			
			if( IsValid === false ){
				
				params.data[ this.field ] = InputSelectBaseSerializer( params.oldValue, this.options, this.splitter );
				
			} else {
				
				params.data[ this.field ] = InputSelectBaseSerializer( params.newValue, this.options, this.splitter );
				
			}
			
			
			return IsValid;
			
		}.bind( this );
			
	}
	
};


export default ColumnSingleSelectBase;