import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import {AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import Supplylists from "../../components/supplylists";

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import SellRenderer from "../../aggrid/SellRenderer.jsx";
import SellProduct from "./sell_product.tsx";
import ProductAdd from "./add_product.tsx";
import SendToGalv from "./sendtogalv.tsx";
import {LoadState, SaveState} from "../../services/GridStateService";

class Products extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale === 'cs') loctext = LocaleCs;
        if (locale === 'en') loctext = LocaleEn;


        super(props)
        this.state = {
            isColumnStateLoaded: false,
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,
            now: Date.now(),
            showSellProductType: null,
            showSellAvailableTypes: [],
            showSell: false,
            showModal: false,
            sellHeader: this.props.t('Sell') + ' ',


            showSupplyLists: false,
            showSupplyListsInvoiceId: '',
            showSupplyListsSaleIds: '',


            showSendToGalv: false,
            sendToGalveader: this.props.t('Sell') + ' ',
            showSendToGalvProductType: null,

            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Product type'),
                    field: 'product_type',
                    editable: false,
                    suppressMenu: true,
                    valueGetter: function (params) {
                        var values = JSON.parse(localStorage.getItem('product_types'));
                        if (params.data.product_type === null || params.data.product_type === '') return '';
                        try {
                            let name = values.find(refData => String(refData.id) === String(params.data.product_type))['name_' + locale];
                            if(params.data?.loading === true){
                                name += " - Na cestě";
                            }
                            return name;
                        } catch (err) {
                            console.log(values);
                            console.log(err);
                            return '';
                        }
                    },
					sort: 'asc'


                },

                {
                    headerName: this.props.t('Quantity'),
                    field: 'qty',
                    editable: false,
                    suppressMenu: true,
                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,
                filter: true,
                sortable: true,
                suppressMovableColumns: true,

                suppressMenu: false,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer,
                SellRenderer: SellRenderer,

            }
        };
    }


    openSell(product_type) {

        var pt = '';
        var locale = 'cs';
        if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
        var productTypesData = JSON.parse(localStorage.getItem('product_types'));

        for (var i = 0; i < productTypesData.length; i++) {
            if (productTypesData[i].id === product_type) {
                pt = productTypesData[i]['name_' + locale];
                break;
            }
        }

        var sellheader = this.props.t('Sell') + ' ' + pt;

        var available_types = [];
        this.gridApi.forEachNode((rowNode, index) => {
            if (typeof rowNode.data !== 'undefined' && !available_types.includes(rowNode.data.product_type)) available_types.push(rowNode.data.product_type);
        });

        this.setState({
            sellHeader: sellheader,
            now: Date.now(),
            showSell: true,
            showSellProductType: product_type,
            showSellAvailableTypes: available_types
        });
    }


    openSendToGalv(product_type) {

        var pt = '';
        var locale = 'cs';
        if (localStorage.getItem('locale') !== null) locale = localStorage.getItem('locale');
        var productTypesData = JSON.parse(localStorage.getItem('product_types'));

        for (var i = 0; i < productTypesData.length; i++) {
            if (productTypesData[i].id === product_type) {
                pt = productTypesData[i]['name_' + locale];
                break;
            }
        }

        var sendToGalvHeader = this.props.t('Send to galvanisation') + ' ' + pt;
        var available_types = [];
        this.gridApi.forEachNode((rowNode, index) => {
            if (typeof rowNode.data !== 'undefined' && !available_types.includes(rowNode.data.product_type)) available_types.push(rowNode.data.product_type);
        });
        this.setState({
            sendToGalvHeader: sendToGalvHeader,
            now: Date.now(),
            showSendToGalv: true,
            showSendToGalvProductType: product_type,
            showSellAvailableTypes: available_types
        });
    }

    componentDidMount() {


        var url = UrlService.apiDomain() + 'api/products';

        HttpService.get(url)
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
                setTimeout(() => {
                    LoadState('Products', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 200);
            })
            .catch((error) => {
                console.log(error);
            });

    }


    handleMinus(product_type, qty) {


        var url = UrlService.apiDomain() + 'api/products_minus/' + product_type + '/' + qty;

        HttpService.get(url)
            .then(res => {

                var rownode = this.gridApi.getRowNode(res.data.entry.id);
                if (rownode) {
                    rownode.setData(res.data.entry);
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;


        let items = this.gridApi.getColumnDefs();

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('sales.create')) {
            items.push({
                headerName: this.props.t('Actions'),
                cellRenderer: "SellRenderer",

                cellRendererParams: {
                    openSell: this.openSell.bind(this),
                    sendToGalv: this.openSendToGalv.bind(this),
                    handleMinus: this.handleMinus.bind(this)
                },
                suppressMenu: true,
            });
        }


        // this.setState({items});
        this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(items);

        LoadState('Products', params.columnApi, params.api);
    };

    async handleSendToGalv(data) {

        HttpService.post(UrlService.apiDomain() + 'api/send_to_galvanisation', data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (typeof res.data.alert !== 'undefined' && res.data.alert != '') {

                    alert(res.data.alert);
                } else {
                    console.log('Successfully updated');
                    this.setState({showSendToGalv: false});

                    if (res.data.create_supplylist) {
                        this.setState({
                            showSupplyLists: true,
                            showSupplyListsInvoiceId: '',
                            showSupplyListsSaleIds: res.data.sale_ids,
                            now: Date.now()
                        });

                    }

                    res.data.entry.map((e) => {
                        var rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                    });


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }

    async handleSell() {
		
		this.setState({showSell: false});

        /*HttpService.post(UrlService.apiDomain() + 'api/sales', data)
            .then(res => {
                if (res.data === null) alert("Chyba připojení k serveru");
                if (typeof res.data.alert !== 'undefined' && res.data.alert != '') {

                    alert(res.data.alert);
                } else {
                    console.log('Successfully updated');

                    this.setState({showSell: false});

                    if (res.data.create_supplylist) {
                        this.setState({
                            showSupplyLists: true,
                            showSupplyListsInvoiceId: res.data.outinvoice_id,
                            showSupplyListsSaleIds: res.data.sale_ids,
                            now: Date.now()
                        });

                    }


                    res.data.entry.map((e) => {
                        var rownode = this.gridApi.getRowNode(e.id);
                        if (rownode) {
                            rownode.setData(e);
                        }
                    })


                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });*/


    }

    getRowNodeId = (data) => {
        return data.id;
    };

    async handleAdd(data) {


        HttpService.post(UrlService.apiDomain() + 'api/products', data)
            .then(res => {

                if (res.data.result == 'failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});


                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }

    onColumnsStateChanged(params) {
        if(this.state.isColumnStateLoaded) {
            SaveState('Products', params.columnApi, params.api);
        }
    }
	
	getRowClass( Params ) {
									
		console.log( Params.data.id, Params );
		
		if ( Params.data.id >= 1000000 ) {
			
			return "rag-green";
			
		}
		
	}

    render(
	
	) {

        return (
            <div className="wrapper">

                <Modal
                    title={this.props.t('Add new')}
                    visibility={this.state.showModal}
                    size="lg"
                    onClose={() => this.setState({showModal: !this.state.showModal})}
                >
                    <ProductAdd tmp={this.state.now} onAdd={(data) => this.handleAdd(data)}/>
                </Modal>
                <Modal
                    title={this.state.sellHeader}
                    visibility={this.state.showSell}
                    size="xxl"
                    onClose={() => this.setState({showSell: !this.state.showSell})}
                >
                    <SellProduct tmp={this.state.now} available_product_types={this.state.showSellAvailableTypes}
                                 product_type={this.state.showSellProductType}
                                 onSell={() => this.handleSell()}/>
                </Modal>

                <Modal
                    title={this.props.t('Supply lists')}
                    visibility={this.state.showSupplyLists}
                    size="lg"
                    onClose={() => this.setState({showSupplyLists: !this.state.showSupplyLists})}
                >
                    <Supplylists tmp={this.state.now} from_sale="yes" invoice_id={this.state.showSupplyListsInvoiceId}
                                 sale_ids={this.state.showSupplyListsSaleIds}/>
                </Modal>


                <Modal
                    title={this.state.sendToGalvHeader}
                    visibility={this.state.showSendToGalv}
                    size="lg"
                    onClose={() => this.setState({showSendToGalv: !this.state.showSendToGalv})}
                >
                    <SendToGalv tmp={ ( new Date() ).getTime( ) } available_product_types={this.state.showSellAvailableTypes}
                                product_type={this.state.showSendToGalvProductType}
                                onSell={(data) => this.handleSendToGalv(data)}/>
                </Modal>

                <Layout>
                    <Content hideTitle={true} title="Products">

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => this.gridApi.exportDataAsExcel()}
                                >
                                    {this.props.t('Excel')} <i className="fa  fa-file-excel"></i>
                                </button>
                            </div>


                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.setState({now: Date.now(), showModal: true})
                                }}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>


                        </div>

                        <div style={{'height': '800px', width: '100%'}} id="productsGrid" className="ag-theme-alpine">

                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                onCellClicked={this.onCellClicked}
                                frameworkComponents={this.state.frameworkComponents}
                                suppressContextMenu={true}
								getRowClass = { this.getRowClass }
                                localeText={this.state.localeText}
                                getRowNodeId={this.getRowNodeId}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>


        );
    }
}

Products.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Products);

