import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class PermissionAdd extends Component<Props> {
    state = { name_en: "", name_cs:"", name: "" };
    componentDidMount() {

    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name_cs, name_en, name } = this.state;
        this.props.onAdd({ name_cs, name_en, name });
    }
    render() {
        const { name_cs, name_en, name } = this.state;
        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="date">{this.props.t('Permission')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name}
                                    placeholder={this.props.t('Enter the ')+this.props.t('Permission')}
                                    onChange={(event) =>
                                        this.setState({ name: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                    </div></div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
PermissionAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(PermissionAdd);

