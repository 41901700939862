

const ColumnSingleDatetime = function(
	Name,
	Field
){
		
	let Column = { };
	
	Column.headerName = Name;
	
	Column.field = Field;

	Column.filter = 'agDateColumnFilter';
	
	Column.cellEditor = 'DateTimePickerSeconds';

		Column.comparator = function (date1, date2) {

			function _monthToNum(date) {

				if (date === undefined || date === null || date === '') {
					return null;
				}

				var date_arr1 = date.split(' ');
				var date_arr = date_arr1[0].split('.');

				var yearNumber = parseInt(date_arr[2]);
				var monthNumber = parseInt(date_arr[1]);
				var dayNumber = parseInt(date_arr[0]);

				var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
				// 29/08/2004 => 20040829
				//console.log(result);
				return result;
			}


			var date1Number = _monthToNum(date1);
			var date2Number = _monthToNum(date2);


			if (date1Number === null && date2Number === null) {
				return 0;
			}
			if (date1Number === null) {
				return -1;
			}
			if (date2Number === null) {
				return 1;
			}

			return date1Number - date2Number;
		}
			

		Column.filterParams = {
			filterOptions: [
				'empty',
				{
					displayKey: 'blank',
					displayName: '(Prázdno)',
					test: function (filterValue, cellValue) {
						return cellValue == undefined;
					},
					hideFilterInput: true,
				},
				{
					displayKey: 'notBlank',
					displayName: '(Ne prázdno)',
					test: function (filterValue, cellValue) {
						return cellValue != undefined;
					},
					hideFilterInput: true,
				},
				'equals',
				'notEqual',
				'lessThan',
				'lessThanOrEqual',
				'greaterThan',
				'greaterThanOrEqual',
				'inRange'
			],
			includeBlanksInEquals: true,
			comparator: (filterLocalDateAtMidnight, cellValue) => {
				const dateAsString = cellValue;

				if (dateAsString == null || dateAsString == '') {
					return -1;
				}

				// In the example application, dates are stored as dd/mm/yyyy
				// We create a Date object for comparison against the filter date
				const parts = dateAsString.split(' ');
				const dateParts = parts[0].split('.');
				const day = Number(dateParts[0]);
				const month = Number(dateParts[1]) - 1;
				const year = Number(dateParts[2]);
				const timeParts = parts[1].split(":");
				const hour = timeParts[0];
				const minute = timeParts[1];

				const cellDate = new Date(year, month, day);

				if (cellDate < filterLocalDateAtMidnight) {
					return -1;
				} else if (cellDate > filterLocalDateAtMidnight) {
					return 1;
				}
				return 0;
			}
		}


		Column.valueSetter = function ( params ) {
			var d = new Date(Date.parse(params.newValue));
			
					
				params.data[ Field ] = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + " "
					+ ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
					
				return true;
					
				
			
		};

		Column.valueGetter = function ( params ) {
			
			
				
				if (params.data[Field] == null || params.data[Field] == '') return null;
				var d = new Date(params.data[Field]);
					
				return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth() + 1)).slice(-2) + "." +
					d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ( "0" + d.getSeconds() ).slice(-2);
				
				
			
        };
		
		return Column;
		
	};
	
export default ColumnSingleDatetime;