"use strict";


import UIElementT from "../../../../../../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../../../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../../../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";


import UIComponentLayoutVConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../../../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../../../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../../../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../../../../../../Type/UI/State/UIStateMapT.mjs";

import UIStyleT from "../../../../../../../../../Type/UI/Style/UIStyleT.mjs";

import UIStyleMapT from "../../../../../../../../../Type/UI/Style/UIStyleMapT.mjs";



import GTSUIModelSaleFormAddItemFormLineT from "./GTSUIModelSaleFormAddItemFormLineT.mjs";

import GTSUIComponentFormSecondaryLineListT from "../../../../../../../../../Interface/GTS/UI/Component/Form/Secondary/Line/GTSUIComponentFormSecondaryLineListT.mjs";



const GTSUIModelSaleFormAddItemLineListT = class GTSUIModelSaleFormAddItemLineListT extends GTSUIComponentFormSecondaryLineListT {
	
	constructor( 
		Locale
	){
		
		super( [
			
		] );
		
	}
	
	#OnRemove(
		RemoveElement
	){

		console.log( "GTSUIModelSaleFormAddItemListT.#OnRemove", RemoveElement );
		
		
		let Index = -1;
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
		
			if( super.LineGet( I ) === RemoveElement ){
				
				Index = I;
				
				break;
				
			}

		}
		
		this.LineRemove( Index );
		
		
		this.#OnChangeInner( );
		
	}
	
	#OnChange(
		JSONValue,
		ItemIsValid,
		JSONValueIsValid
	){
		
		console.log(
			JSONValue,
			ItemIsValid,
			JSONValueIsValid
		);
		
		this.#OnChangeInner( );
		
	}
	
	IsValidTotalGet(
	
	){
		
		let IsValidTotal = true;

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			if( IsValidElement === false ){
				
				IsValidTotal = IsValidElement;
				
			}
			
		}
		
		return IsValidTotal;
			
	}
	
	IsValidGet(
	
	){
		
		let IsValid = [ ];

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			let IsValidElement = ItemListElement.IsValidGet( );
			
			IsValid.push( IsValidElement );
			
		}
		
		return IsValid;
		
	}
	
	NeedOutinvoiceToggle( 
		NeedOutinvoice
	){
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			ItemListElement.NeedOutinvoiceToggle(
				NeedOutinvoice
			);
			
		}
		
	}
	
	NeedSupplylistToggle( 
		NeedSupplylist
	){
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			ItemListElement.NeedSupplylistToggle(
				NeedSupplylist
			);
			
		}
		
	}
	
	TransferredVATToggle( 
		TransferredVAT
	){
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			ItemListElement.TransferredVATToggle(
				TransferredVAT
			);
			
		}
		
	}
	
	ValueGet(
	
	){
		
		let Values = [ ];

		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
			
			let ItemListElement = super.LineGet( I );
			
			Values.push( ItemListElement.ValueGet( ) );
			
		}
		
		return Values;
		
	}
	
	#OnChangeInner(
		
	){
		
		let Values = this.ValueGet( );
		
		let IsValid = this.IsValidGet( );
		
		let IsValidTotal = this.IsValidTotalGet( );
		
		if( super.EventExists( "ItemListChange" ) === true ){
			
			let EventFn = super.EventGet( "ItemListChange" );
			
			EventFn( Values, IsValidTotal, IsValid );
			
		}
		
	}
	
	LineAdd( 
		Locale,
		
		UIOptionMapProductType,
		ProductTypeDefault,
		
		UIOptionMapDescription,
		DescriptionDefault,
	
		UIOptionMapUnit,
		UnitDefault,
		
		UIOptionMapVATRate,
		VATRateDefault,
		
		TransferredVAT,
		NeedOutinvoice,
		NeedSupplylist,
		
		IsProduct
	){
		
		let GTSUIModelSaleFormAddItemFormLine = new GTSUIModelSaleFormAddItemFormLineT(
			Locale,
			
			UIOptionMapProductType,
			ProductTypeDefault,
			
			UIOptionMapDescription,
			DescriptionDefault,
	
			UIOptionMapUnit,
			UnitDefault,
			
			UIOptionMapVATRate,
			VATRateDefault,
			
			TransferredVAT,
			NeedOutinvoice,
			NeedSupplylist,
			
			IsProduct
		);
		
		
		super.LineSet( 
			super.LineCount( ),
			GTSUIModelSaleFormAddItemFormLine
		);
		
		
		GTSUIModelSaleFormAddItemFormLine.Start( );
		
		GTSUIModelSaleFormAddItemFormLine.Listen(
			this.#OnRemove.bind( this ),
			this.#OnChange.bind( this )
		);
		
		this.#OnChangeInner( );
	
	}
	
	LineGet(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		return super.LineGet(
			Index
		);
		
	}
	
	LineRemove(
		Index
	){
		
		if( typeof( Index ) !== "number" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.LineRemove( Index );
		
	}
	
	Clear( ){
		
		for(
			let I = 0;
			I < super.LineCount( );
			I++
		){
		
			this.LineRemove( 0 );

		}
		
	}
	
	Listen(
		OnChangeFn
	){
		
		if( typeof( OnChangeFn ) !== "function" ){

			throw new Error( "Argument" );
		
		}
		
		
		super.EventSet( "ItemListChange", OnChangeFn );
		
	}
	
};


export default GTSUIModelSaleFormAddItemLineListT;