"use strict";


import UIOptionT from "./UIOptionT.mjs";


const UIOptionMapT = class UIOptionMapT {
	
	#Map = null;
	
	constructor(
		UIOptionList
	){
		
		if( ( UIOptionList instanceof Array ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		for(
			let I = 0;
			I < UIOptionList.length;
			I++
		){
			
			if( ( UIOptionList[ I ] instanceof UIOptionT ) === false ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
		}
		
		
		this.#Map = new Map( );
		
		
		for(
			let I = 0;
			I < UIOptionList.length;
			I++
		){
			
			this.Set( UIOptionList[ I ] );
			
		}
		
	}
	
	Keys( ){
	
		return this.#Map.keys( );
	
	}
	
	Set(
		UIOption
	){
		
		if( ( UIOption instanceof UIOptionT ) === false ){
			
			throw new Error( "Invalid argument type" );
			
		}
		

		this.#Map.set( UIOption.KeyGet( ), UIOption );
		
	}
	
	Exists(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.has( Key );
		
	}
	
	Get(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		return this.#Map.get( Key );
		
	}
	
	Remove(
		Key
	){
		
		if( typeof( Key ) !== "string" ){
			
			throw new Error( "Invalid argument type" );
			
		}
		
		
		this.#Map.delete( Key );
		
	}
	
	Copy( 
	
	){
		
		return new UIOptionMapT( [
			...this.#Map.values( )
		] );
		
	}
	
	Sort( 
		SortFn
	){
		
		let Entries = [ ...this.#Map.values( ) ];
		
		Entries.sort( SortFn );
		
		return new UIOptionMapT(
			Entries
		);
		
	}
				
};


export default UIOptionMapT;