"use strict";


import EN from "../../_/EN.json";

import CS from "../../_/CS.json";

import TranslatorMapT from "../../Type/Translator/TranslatorMapT.mjs";

import TranslatorUnitT from "../../Type/Translator/TranslatorUnitT.mjs";


const TranslatorImporterT = class TranslatorImporterT {
	
	static Import( 
		Lang
	){
	
		let ResultObject = null;
	
		if( Lang === "EN" ){
		
			ResultObject = EN;
		
		} else if( Lang === "CS" ){
		
			ResultObject = CS;
		
		} else {
		
			throw new Error( "Invalid language" );
		
		}
		
		
		let TranslatorList = [ ];
		
		for(
			let I in ResultObject
		){
		
			TranslatorList.push( new TranslatorUnitT( I, ResultObject[ I ] ) );
		
		}
		
		return new TranslatorMapT( TranslatorList );
	
	}

};


export default TranslatorImporterT;