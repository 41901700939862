import React from "react";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import {AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../aggrid/CheckboxRenderer.jsx";
import FileRenderer from "../../aggrid/FileRenderer.jsx";
import Communication from "../common/communication";
import Modal from "../../components/common/modal";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import $ from 'jquery';

class UploadTab  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!==null) locale=localStorage.getItem('locale');


        super(props)
        this.state = {};
    }

    handleUpload(type) {
        console.log(type);
        const fileInput = document.querySelector('#'+type);
        var files=fileInput.files;

        if (files.length===0) {
            alert(this.props.t('Please choose the file.'));
        } else {

            var model='loadings';
            if (this.props.type==='outinvoice_single') model='outinvoices';

            const formData = new FormData();
            formData.append('file1', files[0]);
            HttpService.post(UrlService.apiDomain()+'api/attachments/'+model+'/upload/'+type+'/'+this.props.loading_number, formData)
                .then(res => {
                    if (res.data===null) {
                        alert("File import error");
                        return false;
                    }
                    if (res.data.result==='failed') {
                        alert(res.data.message);
                        console.log(res);

                    } else {
                        if (res.data.message==='success') {
                            alert(this.props.t('Files uploaded successfully'));
                            this.setState({tmp:Date.now()});
                            console.log('Successfully updated');

                        } else {
                            alert("File import error");
                        }


                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    componentDidUpdate(prevProps) {

        $('#invoice').val('');
        $('#weighting').val('');
        $('#other').val('');
    }
    render() {

        var model='loadings';
        if (this.props.type==='outinvoice_single') model='outinvoices';

        return (


            <div className="container">
                {(model == 'outinvoices') &&
                <div className="row">


                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="description">{this.props.t('invoice')}</label>
                        </div>
                    </div>
                    <div className="col-6">
                        <input style={{lineHeight: '19px'}} id="invoice" name="invoice" type="file"/>
                    </div>
                    <div className="col-3">
                        <button
                            className="btn btn-default btn-sm delete-ingrid-but"
                            onClick={() => this.handleUpload('invoice')}
                        >{this.props.t('Upload')}
                        </button>
                    </div>
                </div>
                }

                {(model == 'loadings') &&
                    <>
						<div className="row">


							<div className="col-3">
								<div className="form-group">
									<label htmlFor="description">{this.props.t('weighting')}</label>
								</div>
							</div>
							<div className="col-6">
								<input style={{lineHeight:'19px'}} id="weighting" name="weighting" type="file" />
							</div>
							<div className="col-3">
								<button
									className="btn btn-default btn-sm delete-ingrid-but"
									onClick={() => this.handleUpload('weighting')}
								>{this.props.t('Upload')}
								</button>
							</div>
						</div>
						
						<div className="row">


							<div className="col-3">
								<div className="form-group">
									<label htmlFor="description">{this.props.t('CMR')}</label>
								</div>
							</div>
							<div className="col-6">
								<input style={{lineHeight:'19px'}} id="cmr" name="cmr" type="file" />
							</div>
							<div className="col-3">
								<button
									className="btn btn-default btn-sm delete-ingrid-but"
									onClick={() => this.handleUpload('cmr')}
								>{this.props.t('Upload')}
								</button>
							</div>
						</div>

						<div className="row">


							<div className="col-3">
								<div className="form-group">
									<label htmlFor="description">{this.props.t('other')}</label>
								</div>
							</div>
							<div className="col-6">
								<input style={{lineHeight:'19px'}} id="other" name="other" type="file" />
							</div>
							<div className="col-3">
								<button
									className="btn btn-default btn-sm delete-ingrid-but"
									onClick={() => this.handleUpload('other')}
								>{this.props.t('Upload')}
								</button>
							</div>
						</div>
					</>
                }
            </div>


        );
    }
}

                UploadTab.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(UploadTab);

