import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import OutinvoiceItemName from "../../components/outinvoice_item_names";


import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';

interface Props {
  onUpdateYear: (data) => void;
  onUpdateMonth: (data) => void;
}

class OutinvoiceItemNamesPage extends Component<Props> {

	constructor( props ){
		
		super( props );
		
		this.state = {
			Year: null,
			Month: null
		};
		
	}

    render() {
        return (
            <div className="wrapper">
                <Layout 
					onUpdateYear={(e)=>{ console.log("invoice update year", e); this.setState( { Year: e.Year } ) } } 
					onUpdateMonth={(e)=>{ console.log("invoice update month", e); this.setState( { Month: e.Month } ) } }
				>
                    <Content hideTitle={true} title="Invoices">
                        <OutinvoiceItemName id="0" opts={this.props.opts} year={ this.state.Year } month={ this.state.Month }/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

OutinvoiceItemNamesPage.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(OutinvoiceItemNamesPage);

