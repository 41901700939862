"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";

import ComponentSelectTextConfigT from "../../../Type/Component/SelectText/ComponentSelectTextConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT,
	ComponentTextT as ComponentTextT,
	ComponentCheckListT as ComponentCheckListT,
	ComponentT as ComponentT
} from "../ComponentInternalT.mjs";

import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentSelectTextT = class ComponentSelectTextT extends ComponentBaseT {

	#ComponentSelectTextConfig = null;
	
	#ComponentText = null;
	#ComponentCheckList = null;
	
	#InputFocusCb = null;
	#InputBlurCb = null;
	#InputChangeCb = null;
	
	#Value = null;
	
	
	constructor(
		GUIElement,
		ComponentSelectTextConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( ComponentSelectTextConfig instanceof ComponentSelectTextConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentSelectTextConfig.Copy( )
		);
		
		
		this.#ComponentSelectTextConfig = ComponentSelectTextConfig.Copy( );
		
		
		let ComponentCheckListConfig = ComponentSelectTextConfig.ComponentCheckListConfigGet( ).Copy( );
		
		ComponentCheckListConfig.StyleSet( "display", "none" );
		
		this.#ComponentText = new ComponentTextT(
			new GUIElementT( "input" ),
			ComponentSelectTextConfig.ComponentTextConfigGet( )
		);
		
		this.#ComponentCheckList = new ComponentCheckListT( 
			new GUIElementT( "ul" ), 
			ComponentCheckListConfig,
			false,
			false
		);
		
	
		
		
		this.ChildAppend( 
			new ComponentT(
				"Text",
				this.#ComponentText
			)
		);
		
		this.ChildAppend( 
			new ComponentT(
				"CheckList",
				this.#ComponentCheckList
			)
		);
	
		
		
		

		
		
	}
	
	Update( ){
		
		super.Update( );
		
	}
	
	Prepare( PrepareMap ){
		
		console.log( "ComponentSelectTextT.Prepare", PrepareMap );
		
		if( PrepareMap !== null ){
			
			let Name = this.#ComponentSelectTextConfig.NameGet( );
		
			if( PrepareMap.has( Name ) === true ){
				
				let PrepareFn = PrepareMap.get( Name );
				
				PrepareFn( this ); //TODO
				
			}
		
		}
		
	}
	
	OptionMapSet( OptionMap ){
		
		this.#ComponentCheckList.OptionMapSet( OptionMap );		
		
	}
	
	FilterSet( FilterFn ){
	
		this.#ComponentCheckList.FilterSet( FilterFn );		
	
	}
	
	#CheckListElementOnClick( Ev ){
		
		Ev.preventDefault( );
		
	}
	
	#CheckListElementOnMouseEnter( Ev ){
		
		//ComponentTyped.StyleSet( "background-color", "grey" );
		
	}
	
	#CheckListElementOnMouseLeave( Ev ){
		
		//ComponentTyped.StyleSet( "background-color", "grey" );
		
	}
	
	#ComponentTextEvent( EventName, Name, Value ){
		
		console.log( EventName, Name, Value );
		
	}
	
	/*#ComponentCheckListEvent( EventName, Name, Value ){
		
		if( EventName === "Click" ){
			
			let ComponentCheckListConfig = this.#ComponentSelectTextConfig.ComponentCheckListConfigGet( );
			
			let OptionMap = ComponentCheckListConfig.OptionMapGet( );
			
			this.#ComponentCheckList.Element( ).StyleSet( "display", "none" );
			
			this.#ComponentText.ValueSet( OptionMap.get( Value.toString( ) ).label ); //TODO
			
		} else if( EventName === "Filter" ){
			
			let EventCb = this.#EventCb;
			
			return EventCb( EventName, Name, Value );
			
		}
		
		console.log( EventName, Name, Value );
		
	}*/
	
	#ComponentTextOnClick( ){
		
		this.#ComponentCheckList.Filter( );
		
		this.#ComponentCheckList.StyleSet( "display", "block" );
		
	}
	
	#OnMouseLeave( ){
		
		//this.#ComponentText.Blur( );
		
		this.#ComponentCheckList.StyleSet( "display", "none" );
		
	}
	
	StyleMapSet( 
		StyleMap
	){
		
		this.#ComponentText.StyleMapSet( 
			StyleMap
		);
		
	}
	
	Listen( 
		EventBucket
	){
		
		console.log( "ComponentSelectTextT.Listen", EventBucket );
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		if( EventBucket !== null ){
		
			let Name = this.#ComponentSelectTextConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
		
				if( EventMap !== null ){
					
					if( EventMap.IsExists( "InputFocus" ) ){
						
						this.#InputFocusCb = EventMap.Get( "InputFocus" );
						
					}
					
					if( EventMap.IsExists( "InputBlur" ) ){
						
						this.#InputBlurCb = EventMap.Get( "InputBlur" );
						
					}
					
					if( EventMap.IsExists( "InputChange" ) ){
						
						this.#InputChangeCb = EventMap.Get( "InputChange" );
						
					}
					
					/*if( EventMap.IsExists( "InputClick" ) ){
						
						this.#InputClickCb = EventMap.Get( "InputClick" );
						
					}*/

				}
				
				/*if( EventMap.IsExists( "ValueChange" ) ){
					
					this.#RowAddCb = EventMap.Get( "ValueChange" );
					
				}*/
				
			}
			
			this.#ComponentCheckList.ListenParent(
				new EventMapT( [
					new EventT( 
						"Change",
						function( Key, Value, Selected ){
							
							console.log( "ComponentSelectTextT.#OnChange", Key, Value, Selected );
							
							
							
							//if( Name === null ){
							
								Name = this.#ComponentSelectTextConfig.NameGet( );
							
							//}
								
							this.#ComponentText.ValueSet( Value );
							
							this.#ComponentCheckList.StyleSet( "display", "none" );
							
							this.#InputChangeCb( Name, Value );
							
						}.bind( this )
					)
				] )
			);
			
		}
		
		
		this.EventAppend( 
			"mouseleave", 
			this.#OnMouseLeave.bind( this ) 
		);
		
		
		this.ListenChildren( );
		
	}
	
	ListenChildren( ){
		
		this.#ComponentText.ListenParent(
			new EventMapT( [
				new EventT(
					"InputClick",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputClick", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectTextConfig.NameGet( );
							
						}
						
						this.#ComponentCheckList.StyleSet( "display", "block" );
						
					}.bind( this )
				),
				new EventT(
					"InputFocus",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputFocus", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectTextConfig.NameGet( );
							
						}
						
						this.#InputFocusCb( Name );
						
					}.bind( this )
				),
				new EventT(
					"InputBlur",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputBlur", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectTextConfig.NameGet( );
							
						}
						
						this.#InputBlurCb( Name );
						
					}.bind( this )
				),
				new EventT(
					"InputChange",
					function( Name, Value ){
						
						console.log( "ComponentSelectTextT.#OnInputChange", Name, Value );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectTextConfig.NameGet( );
							
						}
						
						this.#InputChangeCb( Name, Value );
						
					}.bind( this )
				)
			] )
		);
		
	}
	
	ValueCheck( ){
		
		return this.#ComponentText.ValueCheck( );
		
	}
	
	ValueGet( ){
		
		return this.#ComponentText.ValueGet( );
		
	}
	
	CheckListValueGet( ){
	
		return this.#ComponentCheckList.ValueGet( );
	
	}
	
	ValueSet( Value ){
		
		if( typeof( Value ) !== "string" ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		return this.#ComponentText.ValueSet( Value );
		
	}
	
	Filter( ){
		
		return this.#ComponentCheckList.Filter( );
		
	}
	
};


export default ComponentSelectTextT;