"use strict";


import GUIElementT from "../../../Infrastructure/GUI/Element/GUIElementT.mjs";


import ComponentSelectConfigT from "../../../Type/Component/Select/ComponentSelectConfigT.mjs";


import {
	ComponentBaseT as ComponentBaseT,
	ComponentTextT as ComponentTextT,
	ComponentCheckListT as ComponentCheckListT,
	ComponentT as ComponentT
} from "../ComponentInternalT.mjs";

import EventT from "../../Event/EventT.mjs";
import EventMapT from "../../Event/EventMapT.mjs";
import EventObjectT from "../../Event/EventObjectT.mjs";
import EventBucketT from "../../Event/EventBucketT.mjs";


const ComponentSelectT = class ComponentSelectT extends ComponentBaseT {

	#ComponentSelectConfig = null;
	
	#Value = null;
	
	#ComponentText = null;
	#ComponentCheckList = null;
	
	#InputFocusCb = null;
	#InputBlurCb = null;
	#InputChangeCb = null;
	
	
	constructor(
		GUIElement,
		ComponentSelectConfig
	){
		
		if( ( GUIElement instanceof GUIElementT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
	
		if( ( ComponentSelectConfig instanceof ComponentSelectConfigT ) === false ){
		
			throw new Error( "Invalid argument type" );
		
		}
		
		
		super(
			GUIElement,
			ComponentSelectConfig
		);
		
		let ComponentCheckListConfig = ComponentSelectConfig.ComponentCheckListConfigGet( ).Copy( ); //TODO Copy
		
		ComponentCheckListConfig.StyleSet( "display", "none" );
		
		this.#ComponentText = new ComponentTextT(
			new GUIElementT( "input" ),
			ComponentSelectConfig.ComponentTextConfigGet( ).Copy( )
		);
		
		this.#ComponentCheckList = new ComponentCheckListT( 
			new GUIElementT( "ul" ), 
			ComponentCheckListConfig,
			false,
			true
		);
		
	
		
		
		this.ChildAppend( 
			new ComponentT(
				"Text",
				this.#ComponentText
			)
		);
		
		this.ChildAppend( 
			new ComponentT(
				"CheckList",
				this.#ComponentCheckList
			)
		);
		
		
		this.#ComponentSelectConfig = ComponentSelectConfig;
		
	}
	
	StyleMapSet( 
		StyleMap
	){
		
		this.#ComponentText.StyleMapSet( 
			StyleMap
		);
		
	}
	
	Listen( 
		EventBucket
	){
		
		console.log( "ComponentSelectTextT.Listen", EventBucket );
		
		if( EventBucket !== null ){
			
			if( ( EventBucket instanceof EventBucketT ) === false ){
		
				throw new Error( "Invalid argument type" );
		
			}
			
		}
		
		if( EventBucket !== null ){
		
			let Name = this.#ComponentSelectConfig.NameGet( );
			
			if( EventBucket.IsExists( Name ) === true ){
				
				let EventMap = EventBucket.Get( Name );
		
				if( EventMap !== null ){
					
					if( EventMap.IsExists( "InputFocus" ) ){
						
						this.#InputFocusCb = EventMap.Get( "InputFocus" );
						
					}
					
					if( EventMap.IsExists( "InputBlur" ) ){
						
						this.#InputBlurCb = EventMap.Get( "InputBlur" );
						
					}
					
					if( EventMap.IsExists( "InputChange" ) ){
						
						this.#InputChangeCb = EventMap.Get( "InputChange" );
						
					}
					
					/*if( EventMap.IsExists( "InputClick" ) ){
						
						this.#InputClickCb = EventMap.Get( "InputClick" );
						
					}*/

				}
				
				/*if( EventMap.IsExists( "ValueChange" ) ){
					
					this.#RowAddCb = EventMap.Get( "ValueChange" );
					
				}*/
				
			}
			
			this.#ComponentCheckList.ListenParent(
				new EventMapT( [
					new EventT( 
						"Change",
						function( Key, Value, Selected ){
							
							console.log( "ComponentSelectT.#OnChange", Key, Value, Selected );
							
							if( Key === 0 ){
								
								let TextConfig = this.#ComponentSelectConfig.ComponentTextConfigGet( );
								
								let Value = TextConfig.PlaceholderGet( );
								
								this.#ComponentText.PlaceholderSet( Value );
								
							} else {
								
								this.#ComponentText.PlaceholderSet( Value );
							
							}
							
							this.#ComponentCheckList.StyleSet( "display", "none" );
							
							let Name = this.#ComponentSelectConfig.NameGet( );
							
							if( Key === 0 ){
							
								this.#Value = null;
							
							} else {
								
								this.#Value = Key;
								
							}
							
							this.#InputChangeCb( Name, Key );
							
						}.bind( this )
					)
				] )
			);
			
		}
		
		
		this.EventAppend( 
			"mouseleave", 
			this.#OnMouseLeave.bind( this ) 
		);
		
		
		this.ListenChildren( );
		
	}
	
	ListenChildren( ){
		
		this.#ComponentText.ListenParent(
			new EventMapT( [
				new EventT(
					"InputClick",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputClick", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectConfig.NameGet( );
							
						}
						
						this.#ComponentCheckList.FilterVisible( "" ); //TODO After blur or click
						
						this.#ComponentCheckList.StyleSet( "display", "block" );
						
					}.bind( this )
				),
				new EventT(
					"InputFocus",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputFocus", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectConfig.NameGet( );
							
						}
						
						this.#InputFocusCb( Name );
						
					}.bind( this )
				),
				new EventT(
					"InputBlur",
					function( Name ){
						
						console.log( "ComponentSelectTextT.#OnInputBlur", Name );
						
						if( Name === null ){
							
							Name = this.#ComponentSelectConfig.NameGet( );
							
						}
						
						this.#ComponentText.ValueSet( "" );
						
						//this.#ComponentCheckList.FilterVisible( "" ); //TODO OnStart
						
						this.#InputBlurCb( Name );
						
					}.bind( this )
				),
				new EventT(
					"InputChange",
					function( Name, Value ){
						
						console.log( "ComponentSelectT.#OnInputChange", Name, Value );
						
						this.#ComponentCheckList.FilterVisible( Value );
						
						/*if( Name === null ){
							
							Name = this.#ComponentSelectConfig.NameGet( );
							
						}*/
						
						//this.#InputChangeCb( Name, Value );
						
					}.bind( this )
				)
			] )
		);
		
	}
	
	Update( ){
		
		super.Update( );
		
	}
	
	Prepare( PrepareMap ){
		
		if( PrepareMap !== null ){
			
			let Name = this.#ComponentSelectConfig.NameGet( );
		
			if( PrepareMap.has( Name ) === true ){
				
				let PrepareFn = PrepareMap.get( Name );
				
				PrepareFn( this ); //TODO Component
				
			}
		
		}
		
	}
	
	OptionMapSet( OptionMap ){
		
		this.#ComponentCheckList.OptionMapSet( OptionMap );		
		
	}
	
	FilterSet( FilterFn ){
	
		this.#ComponentCheckList.FilterSet( FilterFn );		
	
	}
	
	ValueCheck( ){
		
		console.log( "ComponentSelectT.ValueCheck" );
		
		
		
		return ( this.#Value !== null );
		
	}
	
	ValueGet( ){
		
		return this.#ComponentCheckList.ValueGet( );
		
	}
	
	ValueSet( Value ){
	
		let V = null;
		
		if( Value !== null ){
		
			if( typeof( Value ) !== "string" ){
			
				throw new Error( "Invalid argument type" );
			
			}
			
			V = parseInt( Value );
		
		}
		
		let OptionMap = this.#ComponentCheckList.OptionMapGet( );
		
		this.#ComponentText.PlaceholderSet( OptionMap.get( ( ( Value === null ) ? "0" : Value.toString( ) ) ).label );
		
		return this.#ComponentCheckList.ValueSet( V );
		
	}
	
	#ComponentTextEvent( EventName, Name, Value ){
		
		console.log( EventName, Name, Value );
		
		if( EventName === "Change" ){
			
			//this.#ComponentCheckList.Filter( );
			
			//this.#UpdateSelectVisible( Value );
			
		}
		
	}
	
	#ComponentCheckListEvent( EventName, Name, Value ){
		
		/*if( EventName === "Click" ){
			
			let ComponentCheckListConfig = this.#ComponentSelectConfig.ComponentCheckListConfigGet( );
			
			let OptionMap = ComponentCheckListConfig.OptionMapGet( );
			
			this.#ComponentCheckList.Element( ).StyleSet( "display", "none" );
			
			this.#ComponentText.PlaceholderSet( OptionMap.get( Value.toString( ) ).label ); //TODO
			
		}
		
		console.log( EventName, Name, Value );*/
		
	}
	
	#ComponentTextOnClick( ){
		
		this.#ComponentCheckList.Filter( );
		
		this.#ComponentCheckList.Element( ).StyleSet( "display", "block" );
		
	}
	
	#OnMouseLeave( ){
		
		this.#ComponentCheckList.StyleSet( "display", "none" );
		
	}
	
	/*#Filter( ){
		
		let FilteredOptions = null;
		
		if( this.#OptionsFilter ){
		
			FilteredOptions = this.#OptionsFilter( this.#ValuesOut, this.#OptionsObject, this.#ValuesOutParent );
		
		} else {
			
			FilteredOptions = this.#OptionsObject;
			
		}
		
		this.#OptionsFiltered = FilteredOptions;
		
	}*/
	
	/*#UpdateSelectChecked( ){
		
		let Children = this.#Select.children;
		
		for(
			let I = 0;
			I < Children.length;
			I++
		){
			
			let Child = Children[ I ];
		
			if( ( this.#Value !== null ) && ( this.#Value.value.toString( ) === Child.value.toString( ) ) ){
							
				Child.className = "FastSelectSelected";
						
			} else {
				
				Child.className = "FastSelectUnselected";
				
			}
			
		}
		
	}*/
	
	/*#SelectOnChange( Value ){
		
		console.log( "SelectOnChange", Value );
		
		
		let MatchDefaultValue = this.#MatchDefault( Value );
		
		console.log( "Default", MatchDefaultValue );
		
		if( MatchDefaultValue === null ){
			
			this.#.value = "";
			
			this.#Value = "";
			
			return;
			
		}
		
		
		let MatchValue = this.#Match( MatchDefaultValue );
		
		console.log( this.#RegExpMatch, MatchValue );
		
		if( MatchValue === null ){
			
			this.#.value = "";
			
			this.#Value = "";
			
			return;
			
		}
		
		
		this.#.value = MatchValue;
		
		this.#Value = this.#Parse( MatchValue );
		
			
		this.#OnChangeCb( 
			this.#Field, 
			"select_text", 
			this.#Value
		);
		
		
		let ValidationValue = this.#Validation( MatchValue );
		
		if( ValidationValue !== null ){
			
			this.#Set( ValidationValue );
			
			this.#OnChangeCb( 
				this.#Field, 
				"text", 
				this.#Value,
				this.#
			);
			
		}
		
	}
	
	#SelectOptionOnClick( Ev ){
		
		console.log( "li", Ev.target.value, Ev.target.innerText, this );
							
							
		Ev.preventDefault( );
		
		
		let Value = Ev.target.value.toString( );
		
		let Label = ( Value === "0" ) ? "" : Ev.target.innerText;
		
		
		this.#SelectOnChange( Label );
		
		this.#Select.style.display = "none"; 
		
		this.#.focus( );
		
	}
	
	#SelectOnClick( ){
		
		console.log( "SelectOnClick" );
		
		
		this.#Select.style.display = "block";
		
	}
	
	#SelectOnFocus( ){
		
		console.log( "SelectOnFocus" );
		
		this.#Filter( );
		
		let Count = this.#UpdateSelectVisible( );
		
		//this.#Select.style.display = "block";
		
	}
	
	#MatchDefault( ValueString ){
		
		let ValueMatch = ValueString.match( new RegExp( "^.{0,4096}", "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	
	#Match( ValueString ){
		
		if( this.#RegExpMatch === null ){
			
			return ValueString;
			
		}
		
		let ValueMatch = ValueString.match( new RegExp( this.#RegExpMatch, "g" ) );
		
		if( !ValueMatch ){
			
			return null;
			
		}
		
		return ValueMatch[ 0 ];
		
	}
	

	
	#Parse( ValueString ){
		
		if( ValueString === "" ){
			
			return "";
			
		}
		
		return ValueString;
		
	}*/
	
	RequiredSet( ){
		
	}
	
};


export default ComponentSelectT;