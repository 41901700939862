"use strict";


import APIFieldT from "./APIFieldT.mjs";


const APIFieldListT = class APIFieldListT {

	#List = null;
	
	static FromJSON(
		JSONData
	){
		
		if( ( JSONData instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < JSONData.length;
			I++
		){
			
			if( typeof( JSONData[ I ] ) !== "string" ){
			
				throw new Error( "Invalid value" );
			
			}
			
		}
		
		
		let APIFieldArray = new Array( JSONData.length );
		
		for(
			let I = 0;
			I < JSONData.length;
			I++
		){
			
			APIFieldArray[ I ] = APIFieldT.FromJSON( JSONData[ I ] );
			
		}

		
		return new APIFieldListT(
			APIFieldArray
		);
		
	}
	
	constructor(
		Values
	){
		
		if( ( Values instanceof Array ) === false ){
			
			throw new Error( "Invalid argument" );
			
		}
		
		for(
			let I = 0;
			I < Values.length;
			I++
		){
			
			if( ( Values[ I ] instanceof APIFieldT ) === false ){
			
				throw new Error( "Invalid argument" );
			
			}
			
		}
		
		
		this.#List = Values.slice( 0 );
		
	}
	
	Get(
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid value" );
		
		}
		
		
		if( Index >= this.#List.length ){
			
			throw new Error( "Invalid index" );
			
		}
		
		return this.#List[ Index ];
		
	}
	
	Set( 
		Index,
		APIField
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid value" );
		
		}
		
		if( Index >= this.#List.length ){
			
			throw new Error( "Invalid index" );
			
		}
		
		if( ( APIField instanceof APIFieldT ) === false ){
			
			throw new Error( "Invalid argument" );
		
		}
		
		
		this.#List[ Index ] = APIField;
		
	}
	
	Remove( 
		Index
	){
		
		if( typeof( Index ) !== "number" ){
			
			throw new Error( "Invalid value" );
		
		}
		
		if( Index >= this.#List.length ){
			
			throw new Error( "Invalid index" );
			
		}
		
		
		this.#List.splice( Index, 1 );
		
	}
	
	Size( 
	
	){
		
		return this.#List.length;
		
	}
	
	ToJSON( ){
			
		let JSONData = [ ];
		
		
		for(
			let I = 0;
			I < this.#List.length;
			I++
		){
			
			let APIField = this.#List[ I ];
			
			JSONData.push( APIField.ToJSON( ) );
			
		}
		
			
		return JSONData;
		
	}
	
};


export default APIFieldListT;