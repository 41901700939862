"use strict";


import UIElementT from "../../../../Infrastructure/UI/Element/UIElementT.mjs";


import UIComponentLayoutVT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutVT.mjs";

import UIComponentLayoutElementT from "../../../../Interface/UI/Component/Layout/UIComponentLayoutElementT.mjs";

import UIComponentLabelT from "../../../../Interface/UI/Component/Label/UIComponentLabelT.mjs";

import UIComponentTextDropDownT from "../../../../Interface/UI/Component/TextDropDown/UIComponentTextDropDownT.mjs";

import UIComponentCheckListT from "../../../../Interface/UI/Component/CheckList/UIComponentCheckListT.mjs";


import UIComponentLayoutVConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutVConfigT.mjs";

import UIComponentLayoutElementConfigT from "../../../../Type/UI/Component/Layout/UIComponentLayoutElementConfigT.mjs";

import UIComponentLabelConfigT from "../../../../Type/UI/Component/Label/UIComponentLabelConfigT.mjs";

import UIStateT from "../../../../Type/UI/State/UIStateT.mjs";

import UIStateMapT from "../../../../Type/UI/State/UIStateMapT.mjs";


const UIComponentSelectT = class UIComponentSelectT extends UIComponentLayoutVT {
	
	#UIComponentLayoutElementTextDropDown = null;
	#UIComponentLayoutElementCheckList = null;
	
	#UIComponentTextDropDown = null;
	#UIComponentCheckList = null;
	
	#CheckListVisible = false;
	
	#MayBlur = true;
	
	
	constructor( 
		UIElement,
		UIComponentSelectConfig
	){
		
		super(
			new UIElementT( ),
			UIComponentSelectConfig
		);
		
		
		this.#UIComponentLayoutElementTextDropDown = new UIComponentLayoutElementT(
			new UIElementT( ),
			UIComponentSelectConfig.LayoutElementConfigTextDropDownGet( )
		);
		
		this.#UIComponentLayoutElementCheckList = new UIComponentLayoutElementT(
			new UIElementT( ),
			UIComponentSelectConfig.LayoutElementConfigCheckListGet( )
		);
		
		
		this.#UIComponentTextDropDown = new UIComponentTextDropDownT(
			new UIElementT( ),
			UIComponentSelectConfig.TextDropDownConfigGet( )
		);
		
		this.#UIComponentLayoutElementTextDropDown.DataSet( 
			this.#UIComponentTextDropDown
		);
		
		this.#UIComponentCheckList = new UIComponentCheckListT(
			new UIElementT( ),
			UIComponentSelectConfig.CheckListConfigGet( )
		);
		
		this.#UIComponentLayoutElementCheckList.DataSet( 
			this.#UIComponentCheckList 
		);
		
		
		this.ElementSet( 0, this.#UIComponentLayoutElementTextDropDown );
		
		this.ElementSet( 1, this.#UIComponentLayoutElementCheckList );
		
		
		let UIStateMap = UIComponentSelectConfig.StateMapGet( );
		
		
		let IsValid = this.#UIComponentCheckList.IsValidGet( );
			
		if( IsValid === true ){
			
			console.log( "Here" );
		
			if( UIStateMap.Exists( "Valid" ) === true ){
			
				let UIState = UIStateMap.Get( "Valid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
		
		} else {
			
			console.log( "Here", UIStateMap.Exists( "Invalid" ) );
			
			if( UIStateMap.Exists( "Invalid" ) === true ){
			
				let UIState = UIStateMap.Get( "Invalid" );
				
				super.ClassNameSet( UIState.ClassNameGet( ) );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
	}
	
	OptionMapSet(
		UIOptionMap
	){
		
		this.#UIComponentCheckList.OptionMapSet(
			UIOptionMap
		);
		
	}
	
	#CheckListHide( 
	
	){
		
		console.log( "UIComponentSelectT.#CheckListHide" );
		
		
		this.#CheckListVisible = false;
		
			
		if( this.EventExists( "CheckListHide" ) === true ) {
			
			let EventFn = this.EventGet( "CheckListHide" );
		
			EventFn(
			
			);
		
		}
	
		
	}
	
	#CheckListShow(
	
	){
			
		console.log( "UIComponentSelectT.#CheckListShow" );
		
			
		this.#CheckListVisible = true;
		
			
		if( this.EventExists( "CheckListShow" ) === true ) {
			
			let EventFn = this.EventGet( "CheckListShow" );
		
			EventFn(
			
			);
		
		}
			
	}
	
	/*#TextDropDownOnChange(
		Value,
		IsValid
	){
		
		console.log( "UIComponentSelectT.#TextDropDownOnChange", Value, IsValid );
		
		
		if( this.EventExists( "TextDropDownChange" ) === true ) {
			
			let EventFn = this.EventGet( "TextDropDownChange" );
		
			EventFn(
				Valid,
			);
		
		}
		
	}*/
	
	#CheckListOnChange(
		Value,
		Label,
		IsValid
	){
		
		console.log( "UIComponentSelectT.#CheckListOnChange", Value, Label, IsValid );
		
		
		let ResultValue = null;
		
		if( Value.length === 0 ){
			
			//TODO Set placeholder vyberte
			
		} else if( Value.length === 1 ){
			
			console.log( Value, Label, IsValid );
			
			this.#UIComponentTextDropDown.ValueSet( Label[ 0 ], false );
			
			this.#TextDropDownOnBlur( );
			
		} else {
			
			throw new Error( "Value" );
			
		}
		
		
		let EventFn = super.EventGet( "SelectChange" );
		
		EventFn( this.ValueGet( ), this.IsValidGet( ) );
		
		//this.#UIComponentTextDropDown.Focus( ); //TODO Focus func
		
		
	}
	
	ValueSet(
		Value,
		NeedFocus //TODO
	){

		this.#UIComponentCheckList.ValueSet(
			( Value === null ) ? [ ] : [ Value ]
		);
		
		
		let OptionMap = this.#UIComponentCheckList.OptionMapGet( );
		
		
		this.#UIComponentTextDropDown.ValueSet( Value !== null ? OptionMap.Get( Value ).ValueGet( ) : "", false );
		
	}
	
	#TextDropDownOnFocus(
	
	){
		
		console.log( "UIComponentSelectT.#TextDropDownOnFocus" );
		
		this.#CheckListShow(  );
		
		this.#UIComponentTextDropDown.ValueSet( "" );
		
		
		let UIComponentSelectConfig = super.ConfigGet( );
		
		let UIStateMap = UIComponentSelectConfig.StateMapGet( );
		
		
		let IsValid = this.#UIComponentCheckList.IsValidGet( );
		
		
		if( IsValid === true ){
		
			if( UIStateMap.Exists( "ValidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "ValidFocused" );
				
				let UIStyleMap = UIState.StyleMapGet( );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
		
		} else {
			
			if( UIStateMap.Exists( "InvalidFocused" ) === true ){
			
				let UIState = UIStateMap.Get( "InvalidFocused" );
				
				let UIStyleMap = UIState.StyleMapGet( );
				
				super.StyleMapSet( UIState.StyleMapGet( ) );
			
			}
			
		}
		
	}
	
	#TextDropDownOnBlur( 
	
	){
		
		console.log( "UIComponentSelectT.#TextDropDownOnBlur" );
		
		if( this.#MayBlur === true ){
		
			this.#CheckListHide(  );
			
			
			let UIComponentSelectConfig = super.ConfigGet( );
		
			let UIStateMap = UIComponentSelectConfig.StateMapGet( );
			
			
			let IsValid = this.#UIComponentCheckList.IsValidGet( );
			
			
			if( IsValid === true ){
				
				console.log( "Here" );
			
				if( UIStateMap.Exists( "Valid" ) === true ){
				
					let UIState = UIStateMap.Get( "Valid" );
					
					super.ClassNameSet( UIState.ClassNameGet( ) );
					
					super.StyleMapSet( UIState.StyleMapGet( ) );
				
				}
			
			} else {
				
				console.log( "Here", UIStateMap.Exists( "Invalid" ) );
				
				if( UIStateMap.Exists( "Invalid" ) === true ){
				
					let UIState = UIStateMap.Get( "Invalid" );
					
					super.ClassNameSet( UIState.ClassNameGet( ) );
					
					super.StyleMapSet( UIState.StyleMapGet( ) );
				
				}
				
			}
			
			let Label = this.#UIComponentCheckList.LabelGet( );
			
			console.log( Label );
		
			if( Label.length === 1 ){
				
				this.#TextDropDownPlaceholderHide( );
			
				this.#UIComponentTextDropDown.ValueSet( Label[ 0 ], false );
			
			} else if( Label.length === 0 ){
				
				this.#TextDropDownPlaceholderShow( );
				
				this.#UIComponentTextDropDown.ValueSet( "", false );
				
			} else {
				
				throw new Error( "Value" );
				
			}
			
			if( this.EventExists( "SelectTextDropDownBlur" ) === true ) {
			
				let EventFn = this.EventGet( "SelectTextDropDownBlur" );
		
				EventFn(
			
				);
		
			}
		
		} else {
			
			//this.#UIComponentTextDropDown.Focus( ); //TODO Focus
			
		}
		
	}
	
	IsValidGet(
	
	){
			
		return this.#UIComponentCheckList.IsValidGet( );
			
	}
	
	ValueGet( 
	
	){
		
		let Value = this.#UIComponentCheckList.ValueGet( );
		
		let ResultValue = null;
		
		if( Value.length === 0 ){
			
		} else if( Value.length === 1 ){
			
			ResultValue = Value[ 0 ];
			
		} else {
			
			throw new Error( "Value" );
			
		}
		
		return ResultValue;
		
	}
	
	#OnMouseDown( 
		Ev
	) {
		
		console.log( "UIComponentSelectT.#OnMouseDown" );
		
		
		this.#MayBlur = false;
	
	}
	
	#OnMouseUp( 
		Ev
	) {
		
		console.log( "UIComponentSelectT.#OnMouseUp" );
		
		
		this.#MayBlur = true;
		
		this.#UIComponentTextDropDown.Blur( );
	
	}
	
	#OnClick(
		Ev
	){
		
		
		console.log( "UIComponentSelectT.#OnClick" );
		
		
		this.#TextDropDownPlaceholderHide( );

		this.#UIComponentTextDropDown.Focus( );
		
	}
	
	TextDropDownGet(
	
	){
			
		return this.#UIComponentTextDropDown;
			
	}
	
	CheckListGet(
	
	){
			
		return this.#UIComponentCheckList;
			
	}
	
	Start( 
		
	){
		
		console.log( "UIComponentTextDropDownT.Start" );
		
		
		this.#UIComponentTextDropDown.Start( 
			this.#TextDropDownOnFocus.bind( this ),
			this.#TextDropDownOnBlur.bind( this )
		);
		
		this.#UIComponentCheckList.Start( );
		
		super.EventSet(
			"click",
			this.#OnClick.bind( this )
		);
		
		super.EventSet(
			"mousedown",
			this.#OnMouseDown.bind( this )
		);
		
		super.EventSet(
			"mouseup",
			this.#OnMouseUp.bind( this )
		);
		
	}
	
	Stop(
	
	){
		
		console.log( "UIComponentTextDropDownT.Stop" );
		
		
		this.#UIComponentTextDropDown.Stop( );
		
		this.#UIComponentCheckList.Stop( );
		
		super.EventRemove(
			"click"
		);
		
		super.EventRemove(
			"mousedown"
		);
		
		super.EventRemove(
			"mouseup"
		);
		
		
		super.Stop( );
		
	}
	
	#TextDropDownPlaceholderShow(
	
	){
		
		console.log( "UIComponentSelectT.#TextDropDownPlaceholderShow" );
		
		
		if( this.EventExists( "SelectPlaceholderShow" ) === true ) {
			
			let EventFn = this.EventGet( "SelectPlaceholderShow" );
		
			EventFn(
			
			);
		
		}
		
	}
	
	#TextDropDownPlaceholderHide(
	
	){
		
		console.log( "UIComponentSelectT.#TextDropDownPlaceholderHide" );
		
		
		if( this.EventExists( "SelectPlaceholderHide" ) === true ) {
			
			let EventFn = this.EventGet( "SelectPlaceholderHide" );
		
			EventFn(
			
			);
		
		}
		
	}
	
	Listen(
		OnChangeFn,
		OnPlaceholderShow,
		OnPlaceholderHide,
		OnCheckListShow,
		OnCheckListHide,
		OnTextDropDownChange,
		OnTextDropDownBlur
	){
		
		if( typeof( OnChangeFn ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnPlaceholderShow ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnPlaceholderHide ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnCheckListShow ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnCheckListHide ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnTextDropDownChange ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( OnTextDropDownBlur ) !== "function" ){
			
			throw new Error( "Argument" );
			
		}
			
			
		
		
		this.#UIComponentTextDropDown.Listen( 
			OnTextDropDownChange,
			this.#TextDropDownPlaceholderShow.bind( this ),
			this.#TextDropDownPlaceholderHide.bind( this )
		);
		
		this.#UIComponentCheckList.Listen( 
			this.#CheckListOnChange.bind( this )
		);
		
		super.EventSet( "SelectChange", OnChangeFn );
		
		super.EventSet( "CheckListShow", OnCheckListShow );
		
		super.EventSet( "CheckListHide", OnCheckListHide );
		
		super.EventSet( "SelectPlaceholderShow", OnPlaceholderShow );
		
		super.EventSet( "SelectPlaceholderHide", OnPlaceholderHide );
		
		super.EventSet( "SelectTextDropDownBlur", OnTextDropDownBlur )
		
		
		let Label = this.#UIComponentCheckList.LabelGet( );
		
		if( Label.length === 1 ){
			
			this.#UIComponentTextDropDown.ValueSet( Label[ 0 ], false );
		
			this.#TextDropDownPlaceholderHide( );
		
		} else {
			
			this.#UIComponentTextDropDown.ValueSet( "", false );
			
			this.#TextDropDownPlaceholderShow( );
			
		}
		
	}
	
	LabelGet( ){
		
		let Label = this.#UIComponentCheckList.LabelGet( );
		
		if( Label.length === 1 ){
		
			return ( this.#UIComponentCheckList.LabelGet( ) )[ 0 ];
			
		} else {
			
			return null;
			
		}
		
	}
	
	Unlisten( 
	
	){
		
		super.EventRemove( "CheckListShow" );
		
		super.EventRemove( "CheckListHide" );
		
		this.#UIComponentTextDropDown.Unlisten( );
		
		this.#UIComponentCheckList.Unlisten( );
		
	}
	
	PlaceholderSet( Show ){
		
		this.#UIComponentTextDropDown.PlaceholderSet( Show );
		
	}
	
};


export default UIComponentSelectT;