import React, { Component } from "react";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../../components/common/modal";
import DriversAdd from "./add.tsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";
import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';

import SessionStorageService from "../../../services/SessionStorageService";

class Drivers extends Component {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        var product_types_types=JSON.parse(localStorage.getItem('product_types_types'));
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        const customComparator = (valueA, valueB) => {
            if (valueA!=null && valueB!=null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };

        super(props)
        this.state = {
            showModal: false,
            now: Date.now(),
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: [

                {
                    headerName: this.props.t('Name'),
                    field: 'name',
                    editable: true,

                    filter: 'agTextColumnFilter',
                    sort: 'asc',
                    comparator: customComparator
                },
                {
                    headerName: this.props.t('Address'),
                    field: 'address',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },


                {
                    headerName: this.props.t('Passport number'),
                    field: 'passport',
                    editable: true,

                    filter: 'agTextColumnFilter',

                    comparator: customComparator
                },


                {headerName:this.props.t('Delete'),cellRenderer:"DeleteRenderer",cellRendererParams: {
                    gridType: "drivers"
                },
                    suppressMenu: true,},
            ],
            defaultColDef: {
                flex: 1,
                minWidth: 200,
                editable: false,
                resizable: false,
                suppressMovableColumns:true,
                sortable: true,
                menuTabs: ['filterMenuTab'],
                suppressContextMenu:true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,
                DeleteRenderer: DeleteRenderer
            }
        };
    }

    componentDidMount() {
        HttpService.get(UrlService.apiDomain()+'api/drivers')
            .then(res => {
                this.setState({
                    rowdata: res.data
                });
            })
            .catch((error) => {
                console.log(error);
            })
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#myGrid').style.height = '';
    };

    onCellCValueChanged = (event) => {

        HttpService.put(UrlService.apiDomain()+'api/drivers/'+event.data.id, event.data)
            .then(res => {
                console.log('Successfully updated');
                SessionStorageService.update('drivers', res.data.entry);
            })
            .catch((error) => {
                console.log(error);
            })


    }

    async handleAdd(data) {

        if (data.active!=1) data.active=0;
        HttpService.post(UrlService.apiDomain()+'api/drivers', data)
            .then(res => {

                if (res.data.result=='failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});
                    let r = this.gridApi.updateRowData({
                        add: [res.data.entry]
                    });

                    this.gridApi.flashCells({rowNodes: [r.add[0]]});
                    SessionStorageService.add('drivers', res.data.entry);

                }

            })
            .catch((error) => {
                console.log(error);
                alert(error);
            });


    }


    render() {

        return (
            <div className="wrapper">
                <Layout>
                    <Content title={this.props.t('Drivers')}>
                        <div className="tool-buttons">
                            <button
                                className="btn btn-success btn-sm"
                                onClick={() => {
                                    this.setState({ now: Date.now(), showModal: true })
                                }}
                            >
                                {this.props.t('Add new')} <i className="fa fa-plus"></i>
                            </button>


                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({ showModal: !this.state.showModal })}
                        >
                            <DriversAdd tmp={this.state.now} onAdd={(data) => this.handleAdd(data)} />
                        </Modal>
                        <div style={{ 'height': '100%', width:'100%' }} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                frameworkComponents={this.state.frameworkComponents}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                suppressContextMenu={true}
                                localeText={this.state.localeText}
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Drivers.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Drivers);

