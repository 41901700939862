import React, { Component } from "react";
import ReactDOM from "react-dom";
import Layout from "../../../components/common/layout";
import Content from "../../../components/common/content";
import UrlService from "../../../services/UrlService";
import HttpService from "../../../services/HttpService";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import CheckboxRenderer from "../../../aggrid/CheckboxRenderer.jsx";
import DeleteRenderer from "../../../aggrid/DeleteRenderer.jsx";

import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import LocaleEn from "../../../aggrid/locale.en.js";
import LocaleCs from "../../../aggrid/locale.cs.js";
import SessionStorageService from "../../../services/SessionStorageService";
class CompanyCardSettings  extends React.Component {

    constructor(props) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var loctext;
        if (locale=='cs') loctext=LocaleCs;
        if (locale=='en') loctext=LocaleEn;
        super(props)
        this.state = {
            style: {
                width: '100%',
                height: '100%'
            },
            modules: AllCommunityModules,

            localeText: loctext,
            rowdata: null,
            columnDefs: [
                {
                    headerName: this.props.t('Field'),
                    field: 'field',
                    hide:true,
                    editable:false,

                },
                {
                    headerName: this.props.t('Field'),
                    field: 'name_'+locale,
                    rowDrag: true,
                    editable:false,

                },
                {
                    headerName: this.props.t('Show'),
                    field: 'show',
                    editable:true,
                    cellRenderer: "CheckboxRenderer",
                    suppressMenu: true,
                },


            ],
            defaultColDef: {
                flex: 1,
                minWidth: 150,
                editable: false,
                resizable: true,

                sortable: false,
                suppressMovableColumns:true,

                suppressMenu: true,
            },
            frameworkComponents: {
                CheckboxRenderer: CheckboxRenderer,

            }
        };
    }





    componentDidMount(prevProps) {

                HttpService.get(UrlService.apiDomain() + 'api/user/company_card_settings')
                    .then(res => {
                        this.setState({
                            rowdata: res.data
                        });

                    })
                    .catch((error) => {
                        console.log(error);
                    });

    }



    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setDomLayout('autoHeight');
        document.querySelector('#companyCardSettingsGrid').style.height = '';
    };


    onCellCValueChanged = (event) => {
        HttpService.put(UrlService.apiDomain()+'api/user/company_card_settings', event.data)
            .then(res => {
                console.log('Successfully updated');


            })
            .catch((error) => {
                console.log(error);
            })


    }

    onRowDragMove = (event) => {
        var movingNode = event.node;
        var overNode = event.overNode;
        var rowNeedsToMove = movingNode !== overNode;
        if (rowNeedsToMove) {
            var movingData = movingNode.data;
            var overData = overNode.data;
            var store=this.state.rowdata;
            var fromIndex = store.indexOf(movingData);
            var toIndex = store.indexOf(overData);
            var newStore = store.slice();
            moveInArray(newStore, fromIndex, toIndex);
            this.state.rowdata = newStore;
            this.gridApi.setRowData(newStore);

            var order_data=[];
            var pos=0;
            newStore.map((e)=>{
                order_data.push({'field':e.field, 'order':pos});
                pos++;
            });
            HttpService.post(UrlService.apiDomain()+'api/user/company_card_settings_order', order_data)
                .then(res => {

                })
                .catch((error) => {
                    console.log(error);
                });
            this.gridApi.clearFocusedCell();
        }
        function moveInArray(arr, fromIndex, toIndex) {
            var element = arr[fromIndex];
            arr.splice(fromIndex, 1);
            arr.splice(toIndex, 0, element);
        }
    };

    getRowNodeId = (data) => {
        //console.log(data);
        if (typeof data !== 'undefined') {
            return data.id;
        }

        else {
            var id=0;
            this.gridApi.forEachNode((rowNode, index) => {
                console.log(rowNode.data);
                if(typeof rowNode.data!=='undefined' && rowNode.data.id>=id) id=rowNode.data.id;
            });
            console.log(id);
            return id;
        }
    };

    render() {


        return (


            <div style={{ 'height': '100%', width:'100%' }} id="companyCardSettingsGrid" className="ag-theme-alpine">

                <AgGridReact
                    modules={this.state.modules}
                    rowData={this.state.rowdata}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.onCellCValueChanged}
                    frameworkComponents={this.state.frameworkComponents}
                    suppressContextMenu={true}
                    localeText={this.state.localeText}

                    rowDragManaged={true}
                    immutableData={true}
                    animateRows={true}
                    getRowNodeId={this.getRowNodeId}
                    onRowDragMove={this.onRowDragMove.bind(this)}
                />
            </div>


        );
    }
}

CompanyCardSettings.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(CompanyCardSettings);

