import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
interface Props {
    onAdd: (data) => void;
}

class ColumnAdd extends Component<Props> {
    state = { name_cs:"", name_en:"", field:"", permissions:0, column_type:"", data_array:"none" };
    componentDidUpdate(prevProps) {

        if (prevProps.id !== this.props.id) {

            this.setState({name_cs: "", name_en: "", field: "", permissions: 0, column_type: "", data_array: "none"});
        }
    }
    handleFormSubmit(event) {
        event.preventDefault();
        const { name_cs, name_en, field, permissions, column_type, data_array } = this.state;
        this.props.onAdd({ name_cs, name_en, field, permissions, column_type, data_array });
    }

    MakeItem (X) {

        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        return <option key={X.id} value={X.id}>{X['name_'+locale]}</option>;
    };

    render() {
        const { name_cs, name_en, field, permissions, column_type, data_array } = this.state;
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');
        var data_arrays=JSON.parse(localStorage.getItem('data_arrays'));
        var column_types=JSON.parse(localStorage.getItem('column_types'));


        return (
            <form onSubmit={(event) => this.handleFormSubmit(event)}>
                <div className="container">
                    <div className="row">

                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('ID')}</label>
                                <input
                                    type="text"
                                    required
                                    placeholder={this.props.t('Lowercase letters only')}
                                    className="form-control"
                                    value={field}

                                    onChange={(event) =>
                                        this.setState({ field: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('Czech name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_cs}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_cs: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="description">{this.props.t('English name')}</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    value={name_en}
                                    placeholder={this.props.t('Enter the name')}
                                    onChange={(event) =>
                                        this.setState({ name_en: event.target.value })
                                    }
                                />
                            </div>
                        </div>

                    </div></div>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="description">{this.props.t('Column type')}</label>
                            <select
                                value={column_type}
                                required
                                className="form-control"
                                onChange={(event) =>
                                    this.setState({ column_type: event.target.value })
                                }><option value="">{this.props.t('Choose')}</option>{column_types.map(this.MakeItem)}</select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="description">{this.props.t('Data array')}</label>
                            <select

                                value={data_array}
                                className="form-control"
                                onChange={(event) =>
                                    this.setState({ data_array: event.target.value })
                                }>{data_arrays.map(this.MakeItem)}</select>

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="date">{this.props.t('Permissions')}</label>
                            <input
                                type="checkbox"
                                className="form-control"
                                value="1"
                                checked={this.state.permissions}
                                onChange={(event) => this.setState({ permissions: !this.state.permissions })}
                            />
                        </div>
                    </div>
                </div>
                <button className="btn btn-success">{this.props.t('Save')}</button>
            </form>
        );
    }
}
ColumnAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(ColumnAdd);

