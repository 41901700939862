
const InputSelectTextBaseSerializer = function( Value ){
	
	if( Value === null ){
		
		return null;
		
	}
	
	return Value.toString( );
		
};


export default InputSelectTextBaseSerializer;