"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStateMapT from "../../State/UIStateMapT.mjs";


const UIComponentLabelConfigT = class UIComponentLabelConfigT extends UIComponentBaseConfigT {

	#Label = null;
	
	constructor(
		Label = "",
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.LabelSet( Label );
		
	}
	
	LabelGet(
	
	){
			
		return this.#Label;
			
	}
	
	LabelSet(
		Label
	){
		
		if( typeof( Label ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
	
		
		this.#Label = Label;
			
	}
	
	Copy(
	
	){
		
		return new UIComponentLabelConfigT(
		
			this.LabelGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentLabelConfigT;