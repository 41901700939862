
import Cell from "./Cell";


const Row = function(
	Cells
) {
	
	return (
	
		<div className="row">
			
			{ Cells }

		</div>
		
	);
	
};


export default Row;

