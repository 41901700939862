"use strict";


const HTTPClientCookieT = class HTTPClientCookieT {
	
	#Name = null;
	#Value = null;
	
	constructor( 
		Name,
		Value
	){
		
		this.#Name = Name;
		
		this.#Value = Value;
		
	}
	
	static Parse(
		HTTPCookieNameValue
	){
		
		console.log( "HTTPCookieNameValue", HTTPCookieNameValue );
		
		let CookieName = null;
		
		let CookieValue = null;
		
		let CookieNameValueParts = HTTPCookieNameValue.trim( ).split( "=" );
			
		CookieName = decodeURIComponent( CookieNameValueParts[ 0 ].trim( ) );
		
		CookieValue = decodeURIComponent( CookieNameValueParts[ 1 ].trim( ) );
		
	
		return new HTTPClientCookieT( 
			CookieName,
			CookieValue
		);
	
	}
	
	Name( 
	
	){
		
		return this.#Name;
		
	}
	
	Value( 
	
	){
		
		return this.#Value;
		
	}
	
	Serialize( 
	
	){
		
		return encodeURIComponent( this.#Name ) + "=" + encodeURIComponent( this.#Value );
		
	}
	
};


export default HTTPClientCookieT;
