"use strict";


import UIComponentBaseConfigT from "../Base/UIComponentBaseConfigT.mjs";

import UIStyleMapT from "../../Style/UIStyleMapT.mjs";

import UIComponentPlaceholderConfigT from "../Placeholder/UIComponentPlaceholderConfigT.mjs";

import UIComponentTextDataConfigT from "../TextData/UIComponentTextDataConfigT.mjs";


const UIComponentTextConfigT = class UIComponentTextConfigT extends UIComponentBaseConfigT {
	
	#PlaceholderConfig = null;
	#TextDataConfig = null;
	
	constructor(
		UIComponentPlaceholderConfig,
		UIComponentTextDataConfig,
		
		UIStateMap = null
	){
		
		super(
			UIStateMap
		);
		
		
		this.PlaceholderConfigSet( UIComponentPlaceholderConfig );
		
		this.TextDataConfigSet( UIComponentTextDataConfig );
		
	}
	
	PlaceholderConfigSet( 
		PlaceholderConfig
	){
		
		if( PlaceholderConfig !== null ){
		
			if( ( PlaceholderConfig instanceof UIComponentPlaceholderConfigT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		
		this.#PlaceholderConfig = PlaceholderConfig;
		
	}
	
	PlaceholderConfigGet( 
	
	){
		
		return this.#PlaceholderConfig;
		
	}
	
	TextDataConfigSet( 
		TextDataConfig
	){
		
		if( TextDataConfig !== null ){
		
			if( ( TextDataConfig instanceof UIComponentTextDataConfigT ) === false ){
				
				throw new Error( "Argument" );
				
			}
			
		}
		
		
		this.#TextDataConfig = TextDataConfig;
		
	}
	
	TextDataConfigGet( 
	
	){
		
		return this.#TextDataConfig;
		
	}
	
	Copy(
	
	){
		
		return new UIComponentTextConfigT(
			
			this.PlaceholderConfigGet( ),
			this.TextDataConfigGet( ),
			
			super.StateMapGet( )
			
		);
		
	}
				
};


export default UIComponentTextConfigT;