import React, { Component } from "react";
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import 'moment/locale/cs';
import Select from 'react-select'
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";
import Map from "../../components/common/map";

import * as Nominatim from 'nominatim-browser';


interface Props {
    onAdd: (data) => void;
}

class LoadingAdd extends Component<Props> {

    select_cols=['type', 'country', 'continent'];



    constructor(props) {
        super(props)

        this.state = {

          materials:[{material_units_qty:'', loading_qty:'', product_type:'', material_units_description:'', product_type_value:{value: "", label:this.props.t('Choose')}, material_units_description_value:{value: "", label:this.props.t('Choose')}}],
            company: "",
            company_name: "",
            loading_address: "",
            loading_date: "",
            allow_submit: false,
        };

    }

    componentDidUpdate(prevProps) {

        if (prevProps.tmp !== this.props.tmp) {


            this.setState({
                materials:[{material_units_qty:'', loading_qty:'', product_type:'', material_units_description:'', product_type_value:{value: "", label:this.props.t('Choose')}, material_units_description_value:{value: "", label:this.props.t('Choose')}}],
                company: (this.props.company_id) ? this.props.company_id : '',
                company_name: (this.props.company_name) ? this.props.company_name : '',
                loading_address: (this.props.loading_address) ? this.props.loading_address : '',
                loading_date:  "",
                allow_submit:false,
            });


        }
    }
    handleFormSubmit(event) {
        event.preventDefault();

        const { loading_date, materials} = this.state;
        const company = this.props.company_id;

      this.props.onAdd({ company, loading_date, materials });

    }

    allow_submit() {

        var allow_submit=true;
        if (!this.state.loading_date) allow_submit=false;

        this.state.materials.map((e)=>{

            if (!e.product_type || !e.loading_qty) allow_submit=false;
            return true;
        });
        this.setState({allow_submit:allow_submit});
    }

    handleMaterialOnChange(event, i, type) {
        
        if (type==='product_type' || type==='material_units_description') {
            var materials = this.state.materials;
            materials[i][type]=event.value;
            materials[i][type+'_value']=event;
            this.setState({materials:materials});
            this.allow_submit();
        } else if (type==='loading_qty' || type==='material_units_qty'){
            var materials = this.state.materials;
            materials[i][type]=event.target.value;
            this.setState({materials:materials});
            this.allow_submit();
        } else if (type==='loading_date') {

            if (typeof event==="object") {
                console.log("nnn");
                this.setState({loading_date: event.format("DD.MM.YYYY HH:mm")});

                this.allow_submit();
            } else {
                this.setState({loading_date: event});
                this.setState({allow_submit:false});

            }
        }

    }

    render() {
        var locale = 'cs';
        if (localStorage.getItem('locale')!=null) locale=localStorage.getItem('locale');

        const { loading_date} = this.state;


        const optionsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(localStorage.getItem('product_types')).map((e)=>{
            optionsArray.push({value: e.id, label:e['name_'+locale]});
        });

        const materialUnitsArray = [{value: "", label:this.props.t('Choose')}];
        JSON.parse(JSON.parse(localStorage.getItem('columns_120')).data).map((e)=>{
            materialUnitsArray.push({value: e.id, label:e['name_'+locale]});
        });

        return (
            <div style={{'height':'500px', 'overflowY':this.state.overflowY, 'overflowX':'hidden'}}>
                <form onSubmit={(event) => this.handleFormSubmit(event)}>

                    <div className="container">
                        <div className="row">


                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Loading date')}</label>
                                    <Datetime
                                        locale={locale}
                                        dateFormat="DD.MM.YYYY"
                                        timeFormat="HH:mm"
                                        value={loading_date}

                                        onChange={(event) => this.handleMaterialOnChange(event, null, 'loading_date')}
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Company')}</label>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        value={this.state.company_name}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="description">{this.props.t('Loading address')}</label>
                                    <input
                                        type="text"
                                        disabled={true}
                                        className="form-control"
                                        value={this.state.loading_address}
                                    />
                                </div>
                            </div>

                            <div className="col-3">
                            </div>


                        </div>

                        {
                            this.state.materials.map((material, i) => (
                                <div className="row" key={i}>
                                    <div className="col-3">
                                        <div className="form-group">
                                            <label htmlFor="description">{this.props.t('Material type')}</label>
                                            <Select key={'product_type_'+i}
                                                name="product_type"
                                                onChange={(event) => this.handleMaterialOnChange(event, i, 'product_type')}
                                                value={material.product_type_value}
                                                required
                                                placeholder={this.props.t('Select')}
                                                classNamePrefix="select"
                                                options={optionsArray} />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group">
                                            <label htmlFor="description">{this.props.t('Approx. qty.')}</label>
                                            <input key={'loading_qty_'+i}
                                                type="text"
                                                pattern="[0-9]*"
                                                className="form-control"
                                                value={material.loading_qty}
                                                placeholder={this.props.t('Enter the value')}
                                                onChange={(event) => this.handleMaterialOnChange(event, i, 'loading_qty')}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-3">
                                        <div className="form-group">
                                            <label htmlFor="description">{this.props.t('Container type')}</label>
                                            <Select key={'material_units_description_'+i}
                                                    name="material_units_description"
                                                    onChange={(event) => this.handleMaterialOnChange(event, i, 'material_units_description')}
                                                    value={material.material_units_description_value}
                                                    required
                                                    placeholder={this.props.t('Select')}
                                                    classNamePrefix="select"
                                                    options={materialUnitsArray} />
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="form-group">
                                            <label htmlFor="description">{this.props.t('Container number')}</label>
                                            <input key={'material_units_qty_'+i}
                                                   type="text"
                                                   pattern="[0-9]*"
                                                   className="form-control"
                                                   value={material.material_units_qty}
                                                   placeholder={this.props.t('Enter the value')}
                                                   onChange={(event) => this.handleMaterialOnChange(event, i, 'material_units_qty')}
                                            />
                                        </div>
                                    </div>

                                </div>
                            ))
                        }



                    </div>

                    <div className="row">
                        <div className="col-2">
                            <div className="form-group">
                                <button className="btn btn-success" disabled={this.state.allow_submit ? false : true}>{this.props.t('Save')}</button>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="form-group">
                                <div className="btn-group">
                                <button className="btn btn-primary"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            var materials=this.state.materials;
                                            materials.push({material_units_qty:'', loading_qty:'', product_type:'', material_units_description:'', product_type_value:{value: "", label:this.props.t('Choose')}, material_units_description_value:{value: "", label:this.props.t('Choose')}})
                                            this.setState({materials:materials});
                                            this.allow_submit();
                                        }}
                                       ><i className="fa fa-plus"></i></button>
                                <button className="btn btn-primary"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                        var materials=this.state.materials;
                                            materials.pop();
                                            this.setState({materials:materials});
                                            this.allow_submit();
                                        }}
                                        disabled={this.state.materials.length===1 ? true : false}><i className="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
LoadingAdd.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(LoadingAdd);

